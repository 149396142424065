import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import PhoneInput from '../../sections/PhoneInput';
import Loading from '../Loading';

const Step0 = (props) => {
  const {
    edu,
    setEdu,
    phones,
    phoneSet,
    phoneDelete,
    setRegion,
    getCitiesList,
    setCity,
    region,
    getOptions,
    region_list,
    cityRegion,
    cities_list,
    customStyles,
    setPhones,
    disabled,
    city,
    loading,
    submitTest,
    fileChangeApproved,
    fileChangeOrg,
    fileChangeMethod,
    file_nameApproved,
    file_nameOrg,
    file_nameMethod,
    FILE_URL,
    address,
    setAddress,
    errorEdu,
    setErrorEdu,
  } = props;
  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest}>
      <h6 className='my-4'>{t('edu_institution.reference')}</h6>

      {errorEdu.name ||
      errorEdu.edu_institution_type ||
      errorEdu.file_nameApproved ||
      errorEdu.file_nameOrg ||
      errorEdu.file_nameMethod ||
      errorEdu.region ||
      errorEdu.address ||
      errorEdu.mail_address ||
      errorEdu.phones ? (
        <div className='inputError'>{t('edu_institution.main_err')}</div>
      ) : null}
      <input type='hidden' name='id' value={_.get(edu, 'id', '')} />
      <input type='hidden' name='status' value={0} />
      <div className='row'>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.name')}</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({ ...edu, name: e.target.value });
                setErrorEdu({ ...errorEdu, name: false });
              }}
              type='text'
              name='name'
              value={edu.name === null ? '' : edu.name}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.name')}
              style={errorEdu.name ? { borderColor: '#f00' } : {}}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.edu_institution_type')}</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({ ...edu, edu_institution_type: e.target.value });
                setErrorEdu({ ...errorEdu, edu_institution_type: false });
              }}
              type='text'
              name='edu_institution_type'
              value={
                edu.edu_institution_type === null
                  ? ''
                  : edu.edu_institution_type
              }
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.edu_institution_type')}
              style={
                errorEdu.edu_institution_type ? { borderColor: '#f00' } : {}
              }
            />
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              Ta’lim tashkilotining tasdiqlangan guvohnomasi (.pdf .png .jpg
              .jpeg yuklash)
            </label>

            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.file_nameApproved ? { borderColor: '#f00' } : {}}
            >
              {file_nameApproved && file_nameApproved !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameApproved}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileChangeApproved(e);
                  setErrorEdu({ ...errorEdu, file_nameApproved: false });
                }}
                accept='.pdf,.png,.jpg,.jpeg'
              />
              <div>{t('edu_institution.file')}</div>
            </label>

            {edu.certificate ? (
              <a
                href={FILE_URL + edu.certificate}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>Ta’lim tashkilotining Ustavi (.pdf yuklash)</label>

            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.file_nameOrg ? { borderColor: '#f00' } : {}}
            >
              {file_nameOrg && file_nameOrg !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameOrg}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileChangeOrg(e);
                  setErrorEdu({ ...errorEdu, file_nameOrg: false });
                }}
                accept='.pdf'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.statutes ? (
              <a
                href={FILE_URL + edu.statutes}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ marginRight: '10px' }}>
                Ilmiy-metodik kengash (.pdf yuklash)
              </span>
              <a
                href={'/docs/Samarqand ilmiy kengash.pdf'}
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye2')}
              </a>
            </label>
            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.file_nameMethod ? { borderColor: '#f00' } : {}}
            >
              {file_nameMethod && file_nameMethod !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameMethod}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileChangeMethod(e);
                  setErrorEdu({ ...errorEdu, file_nameMethod: false });
                }}
                accept='.pdf'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.council ? (
              <a
                href={FILE_URL + edu.council}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Ilmiy-metodik kengash
          faoliyati yo‘lga qo‘yilganligi va uning tarkibi bo’yicha ta'lim ta'lim
          tashkiloti rahbarining <span style={{ fontWeight: 800 }}>imzosi</span>{' '}
          va
          <span style={{ fontWeight: 800 }}> muhri </span>
          bilan tasdiqlangan hujjat yuklanadi.
        </div>

        {/* <div className='col-12'>
    <div className='form-group'>
      <label for=''>
        {t('edu_institution.inn')}
      </label>
      <input
        disabled={disabled}
        type='text'
        name='inn'
        value={inn}
        className='form-control edu_institution_input'
        placeholder={t('edu_institution.inn')}
        style={
          error.inn ? { borderColor: '#f00' } : {}
        }
        onClick={() =>
          setError({ ...error, inn: false })
        }
        onChange={(e) => {
          setInn(
            e.target.value.length < 10 &&
              checkNumber(e.target.value)
              ? e.target.value
              : inn
          );
        }}
      />
    </div>
  </div> */}
        {/* <div className="col-12">
                    <div className="form-group">
                        <label for="">
                            {t('edu_institution.mail_address')}
                        </label>
                        <input
                            disabled={disabled}
                            type="text" name="mail_address" defaultValue={_.get(edu, 'mail_address', '')}
                            className="form-control edu_institution_input"
                            placeholder={t('edu_institution.mail_address')}
                            style={error.mail_address?{borderColor: '#f00'}:{}}
                            onClick={()=> setError({...error, mail_address: false})}
                        />
                    </div>
                </div> */}
        {/* <div className='col-12'>
        <div className='form-group'>
          <label for=''>
            {t('edu_institution.desc')}
          </label>
          <textarea
            className='form-control edu_institution_input'
            disabled={disabled}
            style={
              error.desc
                ? { borderColor: '#f00' }
                : {}
            }
            onClick={() =>
              setError({ ...error, desc: false })
            }
            name='desc'
            defaultValue={_.get(edu, 'desc', '')}
            placeholder={t('edu_institution.desc')}
          ></textarea>
        </div>
      </div> */}
      </div>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.mail_address')}</h6>
        </div>
        <div className='col-md-6 col-xl-4'>
          <div className='form-group'>
            <label>{t('edu_institution.region')}</label>
            <div>
              <Select
                value={region === null ? '' : region}
                isDisabled={disabled}
                onChange={(select) => {
                  setRegion(select);
                  getCitiesList(select.value);
                  setCity(null);
                  setAddress('');
                  setErrorEdu({
                    ...errorEdu,
                    region: false,
                  });
                }}
                options={getOptions(_.get(region_list, 'data', []))}
                placeholder={t('edu_institution.region')}
                style={errorEdu.region ? { borderColor: '#f00' } : {}}
              />
            </div>
          </div>
        </div>
        {_.get(region, 'value', false) ? (
          <>
            <div className='col-md-6 col-xl-4'>
              <div
                className='form-group'
                onClick={() => getCitiesList(_.get(region, 'value', 0))}
              >
                <label>{t('edu_institution.city')}</label>
                <div>
                  <Select
                    value={city === null ? '' : city}
                    isDisabled={disabled}
                    onChange={(select) => {
                      setCity(select);
                      setAddress('');
                      setErrorEdu({
                        ...errorEdu,
                        city: false,
                      });
                    }}
                    options={
                      cityRegion === _.get(region, 'value', false)
                        ? getOptions(_.get(cities_list, 'data', []))
                        : [
                            {
                              value: 'blue',
                              label: t('chat.loading'),
                              isDisabled: true,
                            },
                          ]
                    }
                    placeholder={t('edu_institution.city')}
                    style={errorEdu.city ? { borderColor: '#f00' } : {}}
                  />
                </div>
              </div>
            </div>
            {_.get(city, 'value', false) ? (
              <div className='col-12'>
                <div className='form-group'>
                  <label for=''>{t('edu_institution.address')}</label>
                  <textarea
                    className='form-control edu_institution_input'
                    disabled={disabled}
                    value={address === null ? '' : address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setErrorEdu({
                        ...errorEdu,
                        address: false,
                      });
                    }}
                    name='address'
                    placeholder={t('edu_institution.address')}
                    style={errorEdu.address ? { borderColor: '#f00' } : {}}
                  ></textarea>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.mail')}</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({ ...edu, mail_address: e.target.value });
                setErrorEdu({ ...errorEdu, mail_address: false });
              }}
              type='text'
              name='mail_address'
              value={edu.mail_address === null ? '' : edu.mail_address}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.mail')}
              style={errorEdu.mail_address ? { borderColor: '#f00' } : {}}
            />
          </div>
        </div>
        <div className='col-12 mb-2'>
          <div className='form-group m-0'>
            <label for=''>{t('edu_institution.phone')}</label>
            <div className='row'>
              {phones.map((item, index) => (
                <div key={index} className='col-md-6 col-xl-4 mb-2'>
                  <PhoneInput
                    value={item === null ? '' : item}
                    disabledInput={disabled}
                    inputClass={
                      errorEdu.phones && item.length !== 12
                        ? 'form-control edu_institution_input borderRed'
                        : 'form-control edu_institution_input'
                    }
                    onClickInput={() => {
                      setErrorEdu({
                        ...errorEdu,
                        phones: false,
                      });
                    }}
                    setPhone={(phone) => phoneSet(index, phone)}
                  />

                  {/* {disabled ? null : ( */}
                  <button
                    className='btn btn-primary btn-sm edu_phone_close'
                    type='button'
                    onClick={() => phoneDelete(index)}
                  >
                    <span class='icon icon-close'></span>
                  </button>
                  {/* )} */}
                </div>
              ))}
              {/* {disabled ? null : ( */}
              <div className='col-md-6 col-xl-4 mb-2'>
                <button
                  className='btn btn-primary btn-sm'
                  type='button'
                  onClick={() => setPhones([...phones, ''])}
                >
                  <span class='icon icon-plus'></span>{' '}
                  {t('edu_institution.add_phone')}
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>

      {/* {disabled ? null : ( */}
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            <button className='btn btn-primary btn-sm' type='submit'>
              Keyingi
            </button>
          </>
        )}
      </>
      {/* )} */}
    </form>
  );
};

export default Step0;
