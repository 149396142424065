import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from './Input';
import Radio from './Radio';
import Boolean from './Boolean';
import Checkbox from './Checkbox';
import Select from './Select';
import Rating from './Rating';
import Sort from './Sort';
import MultipleItput from './MultipleItput';
import MatrixCheck from './MatrixCheck';
import MatrixRadio from './MatrixRadio';
import Result from './Result';
import _ from 'lodash';
import { getLanguage } from '../../../helpers/language';
import NoData from '../NoData';
import Loading from '../Loading';

const Survey = props => {
	const { t } = useTranslation();
	const { data, set_result, setResultServey } = props;
	const [surveys, setSurveys] = useState([]);
	const [survey, setSurvey] = useState({});
	const [active, setActive] = useState(0);
	const [results, setResults] = useState([]);
	const lan = getLanguage();
	useEffect(() => {
		const l = _.get(data, 'surveys', []);
		setSurveys(l);
		const first = l.length ? l[0] : {};
		setSurvey(first);
	}, [data]);
	const next_survey = result => {
		surveyChange(result);
		if (data.last_id !== result.id) {
			let i = active + 1;
			while (i <= data.last_id) {
				const s = surveys[i];
				if (_.get(s, 'logic', []).length > 0) {
					let is_logic = false;
					s.logic.forEach(logic => {
						results.forEach(r => {
							if (logic.survey === r.id) {
								if (logic.survey_type === 'input' && r.value) is_logic = true;
								else if (logic.survey_type === 'input' && r.value) is_logic = true;
								else if (
									logic.survey_type === 'radiogroup' &&
									(logic.choice.item_id
										? r.value === logic.choice.item_id ||
										  (r.value.id === logic.choice.item_id && r.value.write)
										: r.value)
								)
									is_logic = true;
								else if (
									logic.survey_type === 'checkboxgroup' &&
									(logic.choice.item_id
										? r.value.includes(logic.choice.item_id) ||
										  _.get(
												_.filter(_.get(result, 'value', []), i => {
													return i.id === logic.choice.item_id;
												}),
												'[0].write',
												'',
										  )
										: r.value)
								)
									is_logic = true;
								else if (
									logic.survey_type === 'select' &&
									(logic.choice.item_id
										? r.value === logic.choice.item_id || r.value.includes(logic.choice.item_id)
										: r.value)
								)
									is_logic = true;
								else if (
									logic.survey_type === 'rating' &&
									(logic.choice.item_id ? r.value === logic.choice.item_id : r.value)
								)
									is_logic = true;
								else if (
									logic.survey_type === 'boolean' &&
									(logic.choice.item_id ? r.value === logic.choice.item_id : r.value)
								)
									is_logic = true;
								else if (
									logic.survey_type === 'sorttable' &&
									r.value[logic.choice.sort_id - 1] === logic.choice.item_id
								)
									is_logic = true;
								else if (logic.survey_type === 'multiple_input' && r.value[logic.choice.item_id])
									is_logic = true;
								else if (
									logic.survey_type === 'matrix_radio' &&
									r.value[logic.choice.item_id] === logic.choice.col_id
								)
									is_logic = true;
								else if (
									logic.survey_type === 'matrix_checkbox' &&
									r.value[logic.choice.item_id].includes(logic.choice.col_id)
								)
									is_logic = true;
							}
						});
					});
					if (is_logic) {
						setActive(i);
						setSurvey(s);
						break;
					} else i++;
				} else {
					setActive(i);
					setSurvey(s);
					break;
				}
				if (data.last_id <= i) {
					if (set_result) {
						setResultServey(results);
						setSurvey({ type: 'loading' });
					} else {
						setSurvey({ type: 'result' });
						setActive(data.last_id);
					}
					break;
				}
			}
		} else {
			if (set_result) {
				setResultServey(results);
				setSurvey({ type: 'loading' });
			} else setSurvey({ type: 'result' });
		}
	};
	const prev_survey = id => {
		let r_list = [];
		results.forEach(item => {
			if (item.id !== id) {
				r_list.push(item);
			}
		});
		if (r_list.length) {
			const last_survey = r_list[r_list.length - 1];
			surveys.forEach((item, index) => {
				if (item.id === last_survey.id) {
					setSurvey(item);
					setActive(index);
				}
			});
			setResults(r_list);
		}
	};
	const surveyChange = d => {
		let r_list = [];
		let has_r = true;
		results.forEach(item => {
			if (item.id === d.id) {
				r_list.push(d);
				has_r = false;
			} else {
				r_list.push(item);
			}
		});
		if (has_r) {
			r_list.push(d);
		}
		setResults(r_list);
		// console.log('d====>', d, 'nstring_d====>', JSON.stringify(d), '\nr=====>', r_list, '\nstring_r', JSON.stringify(r_list) );
	};
	const getResult = id => {
		let r = {};
		results.forEach(item => {
			if (item.id === id) {
				r = item;
			}
		});
		return r;
	};
	return (
		<>
			{surveys.length ? (
				<div>
					{survey.type === 'input' ? (
						<Input
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'radiogroup' ? (
						<Radio
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'checkboxgroup' ? (
						<Checkbox
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'boolean' ? (
						<Boolean
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'select' ? (
						<Select
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'rating' ? (
						<Rating
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'sorttable' ? (
						<Sort
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'multiple_input' ? (
						<MultipleItput
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'matrix_checkbox' ? (
						<MatrixCheck
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'matrix_radio' ? (
						<MatrixRadio
							survey={survey}
							result={getResult(survey.id)}
							next_survey={r => next_survey(r)}
							prev_survey={i => prev_survey(i)}
							surveyChange={d => surveyChange({ ...d, type: survey.type })}
							lan={lan}
						/>
					) : survey.type === 'result' ? (
						<Result
							reSet={() => {
								setActive(0);
								setResults([]);
								setSurvey(surveys.length ? surveys[0] : {});
							}}
						/>
					) : survey.type === 'loading' ? (
						<Loading />
					) : null}
				</div>
			) : (
				<NoData />
			)}
		</>
	);
};
export default Survey;
