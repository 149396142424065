import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/sortable';
import  "jquery-ui-touch-punch";

window.jQuery = $;

const Sort = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('');
    const submitHandler = event => {
		event.preventDefault();
        if( _.get(survey, 'isRequired', false) &&   !_.get(result, 'value', '') ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', ''), type: survey.type });
        }
    }
    useEffect(()=>{
        $(".group-items").sortable({
            items: "> div",
            connectWith: '.group-items',
            handle: ".move",
            placeholder: "survey-placeholder",
            containment: "parent",
            tolerance: "pointer",
            cursor: "move",
            opacity: 0.9,
            revert: 100,
            delay: 50,
            update: (e)=>{
                let l = [];
                e.target.childNodes.forEach(item=>{
                    if(item.id!=='') l.push( parseInt(item.id) );
                });
                surveyChange( { id: survey.id, value: l } );

            }
        });
    });
    return(
        <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.sort') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            <div  className="group-items">
                                {_.get(result, 'value', []).length===_.get(survey, 'choices', []).length?
                                _.get(result, 'value', []).map((value, value_index)=>(<>
                                    {_.get(survey, 'choices', []).map((item, index)=>(<>
                                    {value===item.id?
                                        <div className="group-item survey-sort my-2" key={value_index} id={item.id}>
                                            <span className="move icon icon-hamburger mx-3"></span>
                                            <div className="clmn-1"  >
                                                {_.get(item, `label_${lan}`, '')} 
                                            </div>
                                        </div>
                                    :null}
                                    </>))}
                                </>))
                                :_.get(survey, 'choices', []).map((item, index)=>(<>
                                    <div className="group-item survey-sort my-2" key={index} id={item.id}>
                                        <span className="move icon icon-hamburger mx-3"></span>
                                        <div className="clmn-1"  >
                                            {_.get(item, `label_${lan}`, '')} 
                                        </div>
                                    </div>
                                </>))}
                            </div>
                            
                            <div style={{ color: 'red' }}>{error}</div> 
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default Sort;