 
import { useState, useEffect } from "react";
import Layout from "./Layout"
import DashSideBar from "./sections/DashSideBar"
import Navigation from "./sections/Navigation"
import { useTranslation } from 'react-i18next';
import SelectReact from 'react-select';
import { getInstance } from "../helpers/httpClient";
import Loading from "./sections/Loading";
import {get} from 'lodash'; 
import { IMG_URL } from "../helpers/api";
import NoData from "./sections/NoData";


const GroupAddUser = (props) => {
	const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [users, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [mloading, setMLoading] = useState(false);
    const [uloading, setULoading] = useState(0);
    useEffect(()=>{
      getUserList(`/api/v1/manager/users?group_id=${props.match.params.id}`);
    }, [])
    const getUserList = (url, more=false) => {
		if(more) setMLoading(true);
        else setLoading(true)
        getInstance().get(url)
        .then(res=>{
            const data = get(res, 'data.data', {});
			if(more) setUser({...data, data: [...get(users, 'data', []), ...get(data, 'data', [])]});
			else setUser(data);
        }).catch(()=>{ }).finally(()=>{setLoading(false); setMLoading(false)})
    } 
    const onSearch = e => {
		e.preventDefault();
		getUserList(`/api/v1/manager/users?group_id=${props.match.params.id}${search?('&search='+search):''}`);
    }
	const AddUser = (uid) => {
		setULoading(uid)
        getInstance().post('/api/v1/manager/groups/add-user', {
			id: parseInt(props.match.params.id),
			user_id: uid
		})
        .then(res=>{
            let l=[];
			get(users, 'data', []).forEach(item=>{
				if(item?.id===uid) l.push({...item, groups_count: 1});
				else  l.push(item);
			})
			setUser({...users, data: l});
        }).catch(()=>{ }).finally(()=>{setULoading(0)})
	}
	const RmUser = (uid) => {
		setULoading(uid)
        getInstance().post('/api/v1/manager/groups/delete-user', {
			id: parseInt(props.match.params.id),
			user_id: uid
		})
        .then(res=>{
            let l=[];
			get(users, 'data', []).forEach(item=>{
				if(item?.id===uid) l.push({...item, groups_count: 0});
				else  l.push(item);
			})
			setUser({...users, data: l});
        }).catch(()=>{ }).finally(()=>{setULoading(0)})
	}
    return(
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('groups.add_user')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                        {
                          title: t('groups.title'),
                          link: '/dashboard/groups',
                        },
                        {
                          title: t('groups.edit'),
                          link: '/dashboard/groups/edit/'+props.match.params.id,
                        },
                      ]}
                    />
                    <div className="dashboard-title">
                        <h5 className="mb-3">{t('groups.add_user')}</h5>                      
                    </div>
                    <div className="card card--shadow">
                      <form onSubmit={onSearch} className="form-group m-0 p-2">
                            <label>{t('groups.user_search')}</label>
                            <div className="space_between" style={{flexWrap: 'inherit'}}> 
                              <input type='text' className="form-control" name="search"  style={{borderRadius: '8px 0 0 8px'}}
                                  value={search}
                                  onChange={e=>{setSearch(e.target.value)}}
                                  placeholder={t('groups.user_search')} />
                              <input type='submit' value={t('groups.user_search_b')} className='btn btn-primary'
                                	style={{borderRadius: '0 8px 8px 0'}} />
                            </div>
                      </form>
                    </div>
					{loading?<Loading/>:<>
					{get(users, 'data', []).length?<>
						{get(users, 'data', []).map((item, index)=>(
							<div key={index} className="card card--shadow mb-2 p-2 ">
								<div className="space_between"   style={{ flexWrap: 'inherit'}}>
									<div className="space_between">
										<img src={IMG_URL+item?.avatar} className='gr_user_img'
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = '/images/user/user.png';
											}}
										/> 
										<b>{item?.name}</b>
									</div>
									<div  className="space_between" style={{minWidth: 35}}>
										{uloading===item?.id?<Loading size={4}/>:
										item.groups_count===0?
										<button className="btn btn-sm btn-primary"
											onClick={()=>AddUser(item?.id)}
										>{t('groups.u_add')}</button>
										:<button className="btn btn-sm btn-danger"
											onClick={()=>RmUser(item?.id)}
										>{t('groups.u_rm')}</button>
										}
									</div>
								</div>
							</div>
						)) }
						{get(users, 'next_page_url', false)?
						<div style={{textAlign: 'center'}}>
							{mloading?<Loading/>
							:<button className="btn btn-primary btn-sm"
								onClick={()=>{getUserList(get(users, 'next_page_url', ''), true)}}
							>
								{t('groups.more')} 
							</button>}
						</div>:''}
					</>:<NoData/>}
					</>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
}
export default GroupAddUser;