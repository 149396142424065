import { useState, useEffect } from "react";
import Layout from "./Layout"
import DashSideBar from "./sections/DashSideBar"
import Navigation from "./sections/Navigation"
import { useTranslation } from 'react-i18next';
import { getInstance } from "../helpers/httpClient";
import Loading from "./sections/Loading";
import _ from 'lodash';
import { IMG_URL } from "../helpers/api";

const EduOfflineCheckPage = (props) => {
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(true);
  const [num, setNum] = useState('');
  const [offline, setOffline] = useState({});
  const [numErr, setNumErr] = useState({});
  useEffect(()=>{
    checkSertifikat();
  }, []);
  const checkSertifikat = () => {
      setCheckLoading(true);
      getInstance().get('/api/v1/offline-education/user-certificate')
      .then(res=>{
          const data = _.get(res, 'data.data', {});
          setOffline(data);
          setCheckLoading(false);
      }).catch(err=>{
        setCheckLoading(false);
      })
  }
  const submitNum = (e) => {
        e.preventDefault();
        setLoading(true);
        if(num&&num.length===10){
            getInstance().post('/api/v1/offline-education/accept-certificate', {
                number: num
            }).then(res=>{
                const data = _.get(res, 'data', {});
                if(data.status===1){
                  setOffline(_.get(data, 'data', {}));
                }else{
                  setNumErr({err_msg: _.get(data, 'msg', '')});
                }
                setLoading(false);
            }).catch(err=>{
                setLoading(false);
                setNumErr({common: true});
            })
        }else{
            setNumErr({num: true});
            setLoading(false);
        }
  }
  const checkNumber = (v) => v.length===0 || (/^-?\d+$/.test(v) && parseInt(v)>=0 && v.length<11) ;
  return (
    <>
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('offline_check.title')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                      ]}
                    />
                    <div className="dashboard-title">
                        <h5 className="mb-3">{t('offline_check.title')}</h5>
                        <div className="">
                            <div className="row">
                              {checkLoading?<Loading/>:<>
                                <div className="col-md-6">
                                    <div className="card card--shadow p-3">
                                        <div><b>{t('offline_check.subtitle1')}</b></div>
                                        <br/>
                                        {offline.image?
                                        <div>{t('offline_check.text3')}</div>
                                        :<div>
                                            {t('offline_check.text1')}
                                            <br/>
                                            {t('offline_check.text2')}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card card--shadow p-3">
                                        {offline.image?<div>
                                          <img src={IMG_URL+offline.image} alt='Sertifikat' style={{width: '100%'}}/>
                                        </div>
                                        :<>
                                        <b>{t('offline_check.subtitle2')}</b>
                                        <br/>
                                        <div>
                                            <form onSubmit={submitNum}>
                                                <div className="form-group">
                                                    <label for=""> {t('offline_check.num')} *</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={e=>{if(checkNumber(e.target.value)){setNum(e.target.value); setNumErr(false);}}}
                                                        value={num}
                                                    />
                                                    {numErr.num?<span style={{ color: 'red' }}>{t('offline_check.err')}</span>:''}
                                                    {numErr.common?<span style={{ color: 'red' }}>{t('offline_check.common_err')}</span>:''}
                                                    {numErr.err_msg?<span style={{ color: 'red' }}>{numErr.err_msg}</span>:''}
                                                </div>
                                                <div>
                                                    {loading?<Loading/>:
                                                    <button type='submit' className="btn btn-primary btn-sm">{t('offline_check.send')}</button>}
                                                </div>
                                            </form>
                                        </div></>}
                                    </div>
                                </div>
                                </>}
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default EduOfflineCheckPage;
