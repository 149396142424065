const  Map = {
    name: "Andijon viloyati",
    slug: 'andijan_region',
    viewBox: "0 0 402 416",
    map_data: [
        {
            city_name: "Sardoba tumani",
            slug: 'sardoba-tumani',
            data: "M17.8751 346.534L100.375 348.408L154.75 334.974C156.625 316.333 159.125 277.801 154.125 272.802C149.125 267.803 143.708 249.892 141.625 241.56L46.6253 209.695L3.50015 308.419L2.25008 315.292L1 322.79L2.87531 335.911L4.125 339.66L6.00031 343.409L7.87523 344.659L17.8751 346.534Z",
            textX: 20,
            textY: 70
        },
        {
            city_name: "Xovos tumani",
            slug: 'xovos-tumani',
            data: "M154.125 272.802C149.125 267.804 143.708 249.892 141.625 241.561C146.625 234.063 162.458 228.023 169.75 225.94C177.042 231.147 192.25 242.186 194.75 244.685C197.875 247.809 206.003 254.057 209.128 257.182C212.253 260.306 226 279.051 238.5 285.299C251 291.547 244.75 282.175 244.75 279.051C244.75 276.551 251 269.678 254.125 266.554L285.375 282.175C291.625 285.299 301 303.419 307.25 307.168C312.25 310.168 332.25 318.832 341.625 322.789L364.128 320.289L364.752 327.162L359.752 345.907L357.878 352.781L346.002 346.532L337.877 345.283L279.127 354.655L271.628 357.779L259.128 364.652L250.377 365.902L229.753 362.153L221.628 362.778L201.002 368.401V367.777L196.628 359.654L197.877 355.28L199.127 352.156L204.127 334.036L206.003 330.911L207.252 328.412L209.128 325.913L211.003 322.164L210.377 321.539H209.128L154.75 334.973C156.625 316.332 159.125 277.801 154.125 272.802Z",
            textX: 48,
            textY: 71
        },
        {
            city_name: "Oqoltin tumani",
            slug: 'oqoltin-tumani',
            data: "M141.63 241.561L46.6299 209.695L45.3726 204.07H42.2476L35.3723 205.945L29.7476 207.195L27.8726 205.32L26.6226 202.821L29.1223 184.701L29.7476 173.454L30.3724 170.954L34.1226 168.455L39.1226 167.83L59.1226 164.081L63.4973 159.083L64.7474 139.713L92.8724 154.709L135.372 177.203L136.622 174.703L140.997 170.329L176 188.45C174.958 194.698 173.5 207.82 176 210.319C178.5 212.818 195.792 225.94 204.125 232.188L254.125 266.554C251 269.678 244.75 276.551 244.75 279.051C244.75 282.175 251 291.547 238.5 285.299C226 279.051 212.253 260.306 209.128 257.182C206.003 254.057 197.875 247.809 194.75 244.685C192.25 242.186 177.042 231.147 169.75 225.94C162.458 228.023 146.63 234.063 141.63 241.561Z",
            textX: 28,
            textY: 49
        },
        {
            city_name: "Mirzaobod tumani",
            slug: 'mirzaobod-tumani',
            data: "M151.615 162.832L149.115 167.206L140.99 170.328L175.993 188.449C174.951 194.697 173.493 207.819 175.993 210.318C178.493 212.817 195.784 225.939 204.118 232.187L254.12 266.554L285.37 282.174L260.375 222.815C255.167 224.898 244.75 228.439 244.75 225.939C244.75 222.815 238.5 216.567 241.625 207.194C244.125 199.696 251 204.07 254.125 207.194L241.625 159.083H192.865L190.99 165.331L182.865 170.33L173.49 168.456L159.115 161.582L154.74 160.957L151.615 162.832Z",
            textX: 53,
            textY: 46
        },
        {
            city_name: "Boyovut tumani",
            slug: 'boyovut-tumani',
            data: "M341.626 322.79L364.128 320.29L361.628 316.541L356.628 303.42L364.128 259.681L365.378 255.933L367.253 251.559C367.253 243.436 347.026 237.188 341.626 235.313C337.306 233.813 288.466 233.022 264.586 232.814L285.376 282.175C291.626 285.3 301.001 303.419 307.251 307.169C312.251 310.168 332.251 318.833 341.626 322.79Z",
            textX: 80,
            textY: 64
        },
        {
            city_name: "Guliston tumani",
            slug: 'guliston-tumani',
            data: "M358.505 179.078L356.005 177.204C355.005 181.203 340.761 178.87 333.765 177.204C332.218 178.87 329.75 183.451 332.25 188.45C335.375 194.698 326 197.822 319.75 194.698C313.5 191.574 307.25 182.202 304.125 182.827C301 183.452 291.625 185.326 285.375 185.326C279.125 185.326 269.75 179.077 263.5 172.829C258.5 167.83 246.831 161.582 241.621 159.083L254.121 207.194C250.996 204.07 244.121 199.696 241.621 207.194C238.496 216.567 244.746 222.815 244.746 225.939C244.746 228.439 255.163 224.898 260.371 222.815L264.586 232.814C288.466 233.022 337.306 233.813 341.626 235.313C347.026 237.188 367.253 243.436 367.253 251.559L369.13 247.81L374.755 240.936L376.005 237.188L375.38 232.814L374.13 228.44L373.505 224.691L371.005 221.567L367.255 217.818L366.63 214.069L373.505 210.32L370.38 207.82L366.63 206.571L362.88 205.946L358.505 206.571L362.88 189.7L366.005 182.827L375.38 178.453V175.329L372.88 172.205L369.755 170.33L366.005 172.205L362.255 176.579L358.505 179.078Z",
            textX: 82,
            textY: 52
        },
        {
            city_name: "Guliston shahri",
            slug: 'guliston-shahri',
            data: "M269.75 225.94C267.25 225.94 268.708 211.36 269.75 204.071L276 200.946L285.375 204.071C285.375 207.195 286 213.443 288.5 213.443C291 213.443 297.875 215.526 301 216.567L294.75 225.94H269.75Z",
            textX: 25,
            textY: 22
        },
        {
            city_name: "Guliston shahri",
            slug: 'guliston-shahri',
            data: "M72.6584 103.646C67.391 103.646 70.4636 72.9279 72.6584 57.569L85.8266 50.9866L105.579 57.569C105.579 64.1514 106.896 77.3162 112.163 77.3162C117.431 77.3162 131.916 81.7045 138.5 83.8986L125.332 103.646H72.6584Z",
            textX: 25,
            textY: 22
        },
        {
            city_name: "Sayxunobod tumani",
            slug: 'sayxunobod-tumani',
            data: "M316.622 134.716L310.998 130.967C309.998 132.217 294.75 132.215 291.625 129.091C288.5 125.967 282.25 116.595 279.125 110.346C276 104.097 269.75 95.3512 266.625 94.7258C263.5 94.1004 257.25 95.3512 254.125 97.85C251 100.349 251 107.223 247.875 118.47C245.375 127.468 238.5 120.969 235.375 116.595L241.625 159.084C246.835 161.583 258.504 167.831 263.504 172.83C269.754 179.078 279.129 185.327 285.379 185.327C291.629 185.327 301.004 183.453 304.129 182.828C307.254 182.202 313.504 191.575 319.754 194.699C326.004 197.823 335.379 194.699 332.254 188.451C329.754 183.452 332.222 178.87 333.769 177.204C340.765 178.871 355.009 181.203 356.009 177.204V173.521L361.622 167.832L363.497 164.083L361.622 159.709L354.748 154.086L350.372 142.214L344.122 141.589L328.497 144.088L334.123 134.716L335.998 129.717L332.247 127.843L326.623 129.717L321.623 132.841L316.622 134.716Z",
            textX: 75,
            textY: 38
        },
        {
            city_name: "Sirdaryo tumani",
            slug: 'sirdaryo-tumani',
            data: "M241.618 159.083L192.872 159.083L185.998 155.334L194.747 140.963L195.373 137.838L192.248 132.215L190.373 126.591L188.497 120.343L189.123 114.095L179.748 112.22L176.623 110.346L173.498 106.597L170.997 97.2242L167.248 57.235L168.498 49.7371L172.872 45.3632L175.372 40.3646L175.997 35.3658L174.748 24.7438L175.372 19.745L176.623 20.3699L180.372 21.6196L181.622 22.8692L185.998 11.6222L195.997 4.74904L203.497 1L222.247 17.8706L224.748 22.2443L225.997 27.2431L226.623 38.49L227.873 42.239L229.748 43.4888L232.873 40.9893H234.123L235.372 44.1134L236.622 52.2364L242.248 49.7371L250.372 51.6115L258.497 54.7357L264.123 59.1096L267.248 63.4833L270.997 71.6062L274.122 74.7304L276.623 76.6048L285.998 81.6034L290.373 84.7276L292.248 87.2271L295.997 95.3498V97.2242L295.373 102.848L295.997 105.347L298.498 107.222L300.997 107.846L303.497 107.222L305.998 107.846L310.998 112.845L314.747 118.469L315.997 124.717L310.998 130.965C309.998 132.215 294.75 132.215 291.625 129.091C288.5 125.967 282.25 116.595 279.125 110.346C276 104.097 269.75 95.3511 266.625 94.7257C263.5 94.1002 257.25 95.3511 254.125 97.8499C251 100.349 251 107.223 247.875 118.47C245.375 127.468 238.5 120.969 235.375 116.595L241.618 159.083Z",
            textX: 54,
            textY: 20
        },
        {
            city_name: "Yangiyer shahri",
            slug: 'yangiyer-shahri',
            data: "M269.75 294.671L276 288.423C279.125 288.423 285.375 304.044 285.375 307.168C285.375 310.292 282.25 313.416 279.125 316.541C276.625 319.04 263.5 315.499 257.25 313.416V294.671H269.75Z",
            textX: 37,
            textY: 93
        },
        {
            city_name: "Yangiyer shahri",
            slug: 'yangiyer-shahri',
            data: "M147.528 364.792L161.417 350.906C168.361 350.906 182.25 385.619 182.25 392.562C182.25 399.505 175.306 406.447 168.361 413.39C162.806 418.944 133.639 411.075 119.75 406.447V364.792H147.528Z",
            textX: 37,
            textY: 93
        },
        {
            city_name: "Shirin shahri",
            slug: 'shirin-shahri',
            data: "M360.375 319.665H338.5L326 310.292L329.125 300.92L354.125 304.044L360.375 319.665Z",
            textX: 90,
            textY: 13
        },
        {
            city_name: "Shirin shahri",
            slug: 'shirin-shahri',
            data: "M401 73.7079H345.318L313.5 49.8507L321.455 25.9934L385.091 33.9458L401 73.7079Z",
            textX: 90,
            textY: 13
        }
    ]
};
export default Map;