import React, {useState, useEffect}  from 'react';
import { connect } from "react-redux";
import {forgotValidateSMS, forgotPassword} from "../../actions";
import Loading from './Loading';
import Timer from "react-compound-timer";
import { useTranslation } from 'react-i18next';
// import history from '../../history';

const  ForgotValidateSms = (props) => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [loading, setLoading] = useState(false);
    const [commonError, setCommonError] = useState('');
    const [resetSms, setResetSms] = useState(false);
    const {forgot_validate_sms} = props;
    useEffect(() => {
        if(forgot_validate_sms.status === 'success'){
            props.actState(5);
            // history.push('/dashboard/profile');
            setLoading(false);            
        }
        if(forgot_validate_sms.status === 'started'){
            setLoading(true);
        }else{
            setLoading(false);
            if(forgot_validate_sms.status === 'error'){
                setCommonError(t("validate_sms.err"));
            }
        }
    }, [forgot_validate_sms]);

    const onSubmit = event => {
		event.preventDefault();
        let c=true, phone_c=true;
        if(code>99999 || code<10000 ){
            setCodeError(t('validate_sms.code_e'));
            c=false;
        }else{
            setCodeError('');
            c=true;
        }
        const phone = sessionStorage.getItem('phone');
        if(phone&&phone.length!==12){
            setCommonError('Error');
            phone_c=false;
        }else{
            setCommonError('');
            phone_c=true;
        }
        if(c&&phone_c){
            props.forgotValidateSMS({
                    login: phone,
                    smscode: parseInt(code)
                }
            )
        }
    }
    const handleOnChange = (value) => {
        if(value.length <= 5){
            setCode( parseInt(value) );
        }else{
            setCode( parseInt(value.slice(0, 5)) );
        }
    }
    const resetSmsCode = () => {
        const phone = sessionStorage.getItem('phone');
        setResetSms(false);
        props.forgotPassword({login: phone });
    }
    return (<>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('validate_sms.title')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon icon-close"></span>
                    </button>
                </div>
                <div className="modal-body">
                <form onSubmit={onSubmit} noValidate>
                    {resetSms?<> 
                        <p>{ t('validate_sms.resetSms') }</p>
                        {loading?<Loading />:
                        <button type="button" className="btn btn-primary w-100"
                            onClick={()=>resetSmsCode()}
                        >
                            {t('validate_sms.btn')}
                        </button>
                        }
                    </>:<>
                        <div className="form-group mb-3">
                            <label for="">{t('validate_sms.code')}</label>
                            <div className="password-field-wrapper">
                                <input type="number" name="code" onChange={(e)=>handleOnChange(e.target.value)} className="form-control" 
                                    placeholder={t('validate_sms.code')}
                                    onClick={()=>{setCommonError(''); setCodeError('')}}
                                    value={code}
                                />
                                <div style={{color: 'red'}}>{codeError}</div>
                                <div style={{color: 'red'}}>{commonError}</div>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <p>
                            <Timer
                                initialTime={180000}
                                direction="backward"
                                checkpoints={[
                                    {
                                        time: 0,
                                        callback: () => setResetSms(true),
                                    }]}
                            >
                                {() => (
                                    <>
                                        <Timer.Minutes /> : <Timer.Seconds />
                                    </>
                                )}
                            </Timer>
                            </p>
                        </div>

                        {loading?<Loading />:
                        <button type="submit" className="btn btn-primary w-100">
                            {t('validate_sms.btn')}
                        </button>
                        }
                    </>}
                    <div className="form-group mt-4 mb-5">
                        <p style={{color: '#26CAAC', cursor: 'pointer'}}
                            onClick={()=>props.actState(0)}
                        >{t('validate_sms.sign_in')}</p>
                        {/* <p style={{color: '#26CAAC', cursor: 'pointer'}}
                            onClick={()=>props.actState(1)}
                        >{t('validate_sms.sign_up')}</p> */}
                    </div>
                </form>
                </div>
            </div>

  </>);
}
export default  connect(
    (state) => ({
        forgot_validate_sms: state.forgot_validate_sms
    }),
    (dispatch) => ({
        forgotValidateSMS: (data)=>dispatch(forgotValidateSMS(data)),
        forgotPassword: (data)=>dispatch(forgotPassword(data)),
    })
)(ForgotValidateSms); 