import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import { getInstance } from '../helpers/httpClient';
import history from '../history';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../actions';

const DashProfileErp = () => {
	const { t } = useTranslation();
    const profile = useSelector(state => state.profile);
    console.log('profile=>', profile);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProfile()); 
    }, []);
    useEffect(() => {   
        if(profile.erp_id) history.push('/dashboard/profile');     
    }, [profile]);
    const [date, setDate] = useState('');
    const [seria, setSeria] = useState('');
    const [num, setNum] = useState('');
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const submitHandler = event => {
		event.preventDefault();
        setLoading(true);
        setErrors({});
        const d = date.substr(8,2)+'.'+date.substr(5,2)+'.'+date.substr(0,4);
        let valid = {}, v=true;
        if(d.length!==10){
            v=false;
            valid = {...valid, date: true};
        }
        if(seria.length!==2){
            v=false;
            valid = {...valid, seria: true};
        }
        if(num.length!==7){
            v=false;
            valid = {...valid, num: true};
        }
        if(v){
            getInstance().get(`/api/v1/erp/get-user-data?documentnumber=${num}&documentseries=${seria}&dateofbirth=${d}`)
            .then(res => {
                setData(res.data); 
                setLoading(false);
                if(_.get(res, 'data.status', 0)===1){
                    dispatch({
                        type: 'PROFILE',
                        payload: _.get(res, 'data.user', {}),
                    });
                    history.push('/dashboard/profile');
                }else{
                    setErrors({no_data: true});
                }
            })
            .catch(e => {console.log(e);  setLoading(false);});
        }else{
            setErrors(valid);
            setLoading(false);
        }
    };
    const changeSeria = v => { if(/^[A-Za-z]{0,2}$/.test(v)) setSeria(v.toUpperCase()); }
    const changeNum = v => {  if(/^[0-9]{0,7}$/.test(v)) setNum(v); }
    return (
		<>
			<Layout>
				<main className="main">
					<section className="section section-dashboard py-0">
						<div className="container">
							<div className="row">
								<DashSideBar />
								<div className="col-md-12 col-lg-9 col-xl-10">
									<div className="dashboard-right">
										<Navigation
											is_dash={true}
											is_btn={true}
											navs={[{ link: '/dashboard/profile', title: t('profile.title') }]}
											active={t('erp.title_nav')}
										/>
										<div className="dashboard-title">
											<h5>{t('erp.title')}</h5>
										</div>
                                        <form onSubmit={submitHandler}>
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <h6 className="mb-4">{t('erp.subtitle')}</h6>
                                                    <div className="row">
                                                        <div className="col-12 col-xl-4">
                                                            <div className="form-group">
                                                                <label for="">
                                                                    {t('erp.date')}
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name="lastname"
                                                                    format="dd-mm-yyyy"
                                                                    onChange={e => setDate(e.target.value)}
                                                                    onClick={() => setErrors({...errors, date: false})}
                                                                    value={date}
                                                                    style={errors.date?{border: '1px solid red'}:{}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-xl-4">
                                                            <div className="form-group">
                                                                <label for="">
                                                                    {t('erp.pass_seria')}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="lastname"
                                                                    placeholder="AA"
                                                                    onChange={e => changeSeria(e.target.value)}
                                                                    onClick={() => setErrors({...errors, seria: false})}
                                                                    value={seria}
                                                                    style={errors.seria?{border: '1px solid red'}:{}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-xl-4">
                                                            <div className="form-group">
                                                                <label for="">
                                                                    {t('erp.pass_num')}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="lastname"
                                                                    placeholder="0000000"
                                                                    onChange={e => changeNum(e.target.value)}
                                                                    onClick={() => setErrors({...errors, num: false})}
                                                                    value={num}
                                                                    style={errors.num?{border: '1px solid red'}:{}}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.no_data?
                                                        <div className="col-12"  style={{marginBottom: 20}}> 
                                                            <h5 style={{ color: 'red', fontSize: 15 }}>
                                                                {_.get(data, 'msg', t('erp.error'))}
                                                            </h5>
                                                        </div>:''}
                                                        <div className="col-12 col-xl-4">
                                                            <div style={{marginBottom: 25}}>
                                                                {loading?<Loading size={5}/>:
                                                                <button
                                                                    className="btn btn-primary btn-sm"
                                                                    type="submit">
                                                                    {t('erp.save')}
                                                                </button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default DashProfileErp;