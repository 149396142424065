import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, withHighcharts } from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import _ from 'lodash';
ChartModuleMore(Highcharts);

const ChartCircle = (props) => {
    const { t } = useTranslation();
    let points = _.get(props, 'data', []);

    const chart = {
        plotBorderWidth: null,
        plotShadow: false
     }
    const tooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}"><b>{point.percentage:.1f}%</b><br/>'
    }
     const plotOptions = {
        pie: {
           shadow: false,
           center: ['50%', '50%'],
           size:'100%',
           innerSize: '50%' ,
           showInLegend: true,
           dataLabels: {
                enabled: true,
                distance: -50,
                pointFormat: '<b>{point.percentage:.1f}%</b>',
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            }          
        }
     }
     const series = [{
        type: 'pie',
        name: "O'qilgan",
        data: points
     }]
     const legend = {
      accessibility:{
          enabled:false,
      },
      keyboardNavigation:{
          enabled:true
          }
  }
return (
      <div style={{width: '100%'}}>
        <HighchartsChart  
          chart={chart}
          series={ series}
          plotOptions = {plotOptions}
          tooltip={ tooltip}
          legend={legend}
        >
        </HighchartsChart>      
      </div>
    ); 
}
export default withHighcharts(ChartCircle, Highcharts);