import React, { useEffect} from 'react';
import { getCourse, getCourseSections } from "../actions";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import CourseSideBar from "./sections/CourseSideBar";
import CourseReview from "./sections/CourseReview";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import CourseBtns from './sections/CoutceBtns';

const DashCourseReviewPage = (props) => {
    const { t } = useTranslation();
    const {course} = props;
    let slug = props.match.params.slug?props.match.params.slug:'';
    useEffect(()=>{
        props.getCourse(slug);
        props.getCourseSections(slug);
        slug = props.match.params.slug?props.match.params.slug:'';
    },[props.match.params.slug]);
    return (<Layout>
			<CheckRole roles={ [ roles.teacher, roles.user ] } />
      <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('course_view.review')}
                                navs={[
                                    {
                                        title: t('navigation.account'),
                                        link: '/dashboard'
                                    },
                                    {
                                        title: t('dash_side_bar.course'),
                                        link: "/dashboard/courses/list/1"
                                    },
                                    {
                                        title: _.get(course, 'data.name'),
                                        link: "/dashboard/courses/view/"+slug
                                    }
                                ]}
                            />
                            <div className="dashboard-title">
                                <h3>{course.data&&course.data.name?course.data.name:''}</h3>
                            </div>
                            <div className="has-right-buttons">
                                <div className="has-right-buttons__right">
                                <CourseBtns  course={course}  slug={slug} dash={true}/>

                                </div>
                                <div className="has-right-buttons__left">
                                    <CourseReview slug={slug} course={course}/>
                                </div>
                            </div>
                            <CourseSideBar  slug={slug}  />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug)=>dispatch(getCourse(slug)),
        getCourseSections: (slug)=>dispatch(getCourseSections(slug)),
    })
)(DashCourseReviewPage);