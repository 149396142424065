 
import { useState, useEffect } from "react";
import Layout from "./Layout"
import DashSideBar from "./sections/DashSideBar"
import Navigation from "./sections/Navigation"
import { useTranslation } from 'react-i18next';
import SelectReact from 'react-select';
import { getInstance } from "../helpers/httpClient";
import Loading from "./sections/Loading";
import {get} from 'lodash'
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { IMG_URL } from "../helpers/api";
import NoData from "./sections/NoData";


const GroupEdite = (props) => {
	const { t } = useTranslation();
    const [obj, setObj] = useState({status: 0});
    const [err, setErr] = useState({});
    const [cats, setCats] = useState([]);
    const [sCats, setSCats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uloading, setULoading] = useState(0);
    const [floading, setFLoading] = useState(true);
    const [users, setUser] = useState([]);
    useEffect(()=>{
        getGroup();
        getCatList();
    }, [props.match.params.id])
    const getCatList = () => {
        getInstance().get('/api/v1/manager/categories')
        .then(res=>{
            const data = get(res, 'data.data', []);
            let l = [];
            data.forEach(item => {
                l.push({
                    label: get(item, 'name'),
                    value: get(item, 'id')
                })
            });
            setCats(l);
        }).catch(()=>{setCats([])})
    }
    const getGroup = () => {
      setFLoading(true);
      getInstance().get('/api/v1/manager/groups/'+props.match.params.id)
        .then(res=>{
            const data = get(res, 'data.data', []);
            let l = []
            get(data, 'categories', []).forEach(item => {
                l.push({
                    label: get(item, 'name'),
                    value: get(item, 'id')
                })
            });
            setSCats(l);
            setObj(data);
            setUser(get(data, 'users', []))
        }).catch(()=>{ }).finally(()=>{setFLoading(false)})
    }
    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        let errr = {}, t = true;
        const cl = sCats.map(i=>i.value);

        if(!obj.name){ errr = {...errr, name: true}; t=false; }
        if(!obj.desc){ errr = {...errr, desc: true}; t=false; }
        if(!obj.start_date){ errr = {...errr, start_date: true}; t=false; }
        if(!obj.end_date){ errr = {...errr, end_date: true}; t=false; }
        if(cl.length===0){  errr = {...errr, categories: true}; t=false; } 
        if(t){
            getInstance().post('/api/v1/manager/groups/update', {...obj, categories: cl}  )
            .then(res=>{
                setObj(get(res, 'data.data'))
            }).catch(()=>{}).finally(()=>{setLoading(false)})
        }else{setErr(errr); setLoading(false)}

    }
    const RmUser = (uid) => {
		setULoading(uid)
        getInstance().post('/api/v1/manager/groups/delete-user', {
			id: parseInt(props.match.params.id),
			user_id: uid
		})
        .then(res=>{
            let l=[];
			users.forEach(item=>{ if(item?.id!==uid) l.push(item); })
			setUser(l);
        }).catch(()=>{ }).finally(()=>{setULoading(0)})
	}
    return(
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('groups.edit')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                        {
                          title: t('groups.title'),
                          link: '/dashboard/groups',
                        },
                      ]}
                    />
                    <div className="dashboard-title">
                        <h5 className="mb-3">{t('groups.edit')}</h5>                      
                    </div>
                    <div className="card card--shadow">
                      {floading?<Loading/>:
                        <form onSubmit={onSubmit} className="form-group m-0 p-2">
                            <label>* {t('groups.name')}</label>
                            <input type='text' className="form-control" name="name" 
                                value={get(obj, 'name', '')}
                                onChange={e=>{setObj({...obj, name: e.target.value}); setErr({...err, name: false})}}
                                placeholder={t('groups.name_e')} />
                            {err?.name?<div className="input_error">{t('groups.name_e')}</div>:''}
                            
                            <label>* {t('groups.categories')}</label>
                            <div className="mb-2">
                                <SelectReact
                                    value={ sCats }
                                    onChange={(select)=>{ setSCats(select); setErr({...err, categories: false}) }}
                                    options={cats}
                                    isMulti ={true} 
                                    placeholder = {t('groups.categories_e')}
                                    className="custom-react-select"
                                />
                                {err?.categories?<div className="input_error">{t('groups.categories_e')}</div>:''}
                            </div>

                            <div className="setting__buttons form-group my-2" style={{alignItems: 'center', justifyContent: 'left'}}>
                               
                                <label className="switch mx-2">
                                    <input type="checkbox" checked={get(obj, 'status', 0)?true:false}
                                        onChange={(e)=>{ 
                                            setObj({...obj, status: get(obj, 'status', 0)?0:1});  
                                            setErr({...err, status: false}) 
                                        }}
                                    />
                                    {/* {_.get(result, 'value', '')?null:
                                    <style jsx>{`
                                        .switch .slider:before{
                                            left: 15px
                                        }
                                    `}</style>} */}
                                    <span className="slider round"></span>
                                </label>
                                { t('groups.status') }
                            </div>

                            <label>* {t('groups.start_date')}</label>
                            <input type='datetime-local' className="form-control" name="start_date"
                                onChange={e=>{setObj({...obj, start_date: e.target.value}); setErr({...err, start_date: false})}}
                                value={get(obj, 'start_date', '')}  />
                            {err?.start_date?<div className="input_error">{t('groups.start_date_e')}</div>:''}

                            <label>* {t('groups.end_date')}</label>
                            <input type='datetime-local' className="form-control" name="end_date" 
                                onChange={e=>{setObj({...obj, end_date: e.target.value}); setErr({...err, end_date: false})}}
                                value={get(obj, 'end_date', '')} />
                            {err?.end_date?<div className="input_error">{t('groups.end_date_e')}</div>:''}

                            <label>* {t('groups.desc')}</label>
                            <input type='text' className="form-control" name="name" 
                                value={get(obj, 'desc', '')}
                                onChange={e=>{setObj({...obj, desc: e.target.value}); setErr({...err, desc: false})}}
                                placeholder={t('groups.desc_e')} />
                            {err?.desc?<div className="input_error">{t('groups.desc_e')}</div>:''}

                            <div className="mt-2">
                                {loading?<Loading size={5}/>:
                                <input className="btn btn-primary btn-sm" value={t('groups.save')} type='submit' />}
                            </div>
                            
                        </form>}
                    </div>
                    {floading?'':<>
                        <div className="dashboard-title dashboard-title--has-buttons">
                            <h5 className="mb-3">
                                {t('groups.users')} 
                            </h5>
                            <div class="buttons butons--target">
                                <Link to={`/dashboard/groups/edit/${props.match.params.id}/add-users`} 
                                    className="btn btn-primary btn-sm">
                                        <span class="icon icon-plus mr-1"></span>
                                    {t('groups.add')}
                                </Link>
                            </div>
                        </div>
                        {users.length?<>
						{users.map((item, index)=>(
							<div key={index} className="card card--shadow mb-2 p-2 ">
								<div className="space_between"   style={{ flexWrap: 'inherit'}}>
									<div className="space_between">
										<img src={IMG_URL+item?.avatar} className='gr_user_img'
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = '/images/user/user.png';
											}}
										/> 
										<b>{item?.name}</b>
									</div>
									<div  className="space_between" style={{minWidth: 35}}>
										{uloading===item?.id?<Loading size={4}/>
                                        :<button className="btn btn-sm btn-danger"
											onClick={()=>RmUser(item?.id)}
										>{t('groups.u_rm')}</button>
										}
									</div>
								</div>
							</div>
						)) } 
					</>:<NoData/>}
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
}
export default GroupEdite;