import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../../helpers/httpClient';
import Loading from './Loading';
import _ from 'lodash';
import Moment from 'react-moment';
import { FILE_URL, IMG_URL } from '../../helpers/api';
import NoData from './NoData';

const  EduInstitutionStatusLogModal = props => {
    const { t } = useTranslation();
    const [eduLoading, setEduLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const {edu} = props;
    useEffect(() => {
        if(edu.id!==0) getEduLogs();
    }, [edu]);
    const getEduLogs = () => {
        setEduLoading(true);
        getInstance()
        .get('/api/v1/edu-institution/logs?edu_institution_id='+edu.id)
        .then(response => {
            if (response.status === 200) {
                try {
                    const { data } = response;
                    // window.$('#modalEduInstitutionStatusLog').modal('hide');
                    setLogs(_.get(data, 'data', []));
                    setEduLoading(false);
                } catch {
                    setEduLoading(false);
                }
            }
        })
        .catch(error => {  setEduLoading(false); });
    }

    return (
    <div className="modal fade" id="modalEduInstitutionStatusLog" tabindex="-1" role="dialog" aria-labelledby="modalEduInstitutionStatusLogTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('edu_list.edu_check_history')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon icon-close"></span>
                    </button>
                </div>
                <div className="modal-body">
                    {eduLoading?<Loading/>:
                    logs.length?logs.map((item,index)=>{
                        const log = JSON.parse(item.data);
                        return(
                        <div key={index} className="mb-4">
                            <div className='edu_log_user'>
                                <img src={IMG_URL+_.get(item, 'user.avatar', '')} alt="education" className="edu_log_img"
                                    onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = '/images/user/user.png';
                                    }}
                                />
                                <span>
                                    <div style={{fontWeight: 500, color: '#0D2E69'}}>
                                        {`${_.get(item, 'user.firstname', '')} ${_.get(item, 'user.lastname', '')} ${_.get(item, 'user.middlename', '')}`}
                                    </div>
                                    <div>
                                        <Moment format="HH:mm DD-MM-YYYY">
                                            {item.created_at}
                                        </Moment>
                                    </div>
                                </span>
                            </div>
                            <div>
                                { parseInt(_.get(log, 'status', 0))===0?<div className="edu_status edu_status_new">{t('edu_list.no_check')}</div>
                                : parseInt(_.get(log, 'status', 0))===1?<div className="edu_status">{t('edu_list.succes')}</div>
                                : parseInt(_.get(log, 'status', 0))===2?<div className="edu_status edu_status_check">{t('edu_list.check')}</div>
                                : parseInt(_.get(log, 'status', 0))===3?<div className="edu_status edu_status_faild">{t('edu_list.failed')}</div>:null}
                            </div>
                            {_.get(log, 'file', '')?
                                    <a href={ FILE_URL+_.get(log, 'file', '') } className='edu_status btn-info mt-1' 
                                      style={{color: '#fff', borderColor: '#3C8E9D'}} >
                                        {t('edu_list.pratokol')}
                                    </a>
                                    :''}  
                            <div>{_.get(log, 'desc', '')}</div>
                        </div>
                    )}):<NoData text={t('edu_list.no_data_status')}/>}
                </div>
            </div>
        </div>
    </div>
    );
}
export default EduInstitutionStatusLogModal;