import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	deleteFile,
	getTeacherCategorys,
	getTeacherLanguages,
	postTeacherCourse,
} from '../../actions';
import { IMG_URL } from '../../helpers/api'; 
import Loading from './Loading'; 
const CopyrightCreateOrUpdate = props => {
	const { teacher_category, teacher_lan, data } = props;
	const { t } = useTranslation();
	const [name_e, setNameE] = useState('');
	const [price, setPrice] = useState(0);
	const [price_e, setPriceE] = useState('');
	const [is_paid, setIsPaid] = useState(false); 
	const [minut_e, setMinutE] = useState('');
	const [loading, setLoading] = useState(false);
	const [img, setImg] = useState('');
	const [next_page, setNextPage] = useState(''); 
	const [minut, setMinut] = useState(0);
	const [category_id, setCategoryId] = useState(0);
	const [category_e, setCategoryE] = useState('');
	const [type, setType] = useState('upgrade'); 
	const [lan, setLan] = useState(1);  
	const [status, setStatus] = useState(0);
	useEffect(() => {
		props.getTeacherLanguages(); 
		props.getTeacherCategorys( );
	}, []);
	useEffect(() => {
		if (props.teacher_change_course_status.status === 'success') {
			setLoading(false);
			window.scrollTo(0, 0);
		}
		if (props.teacher_change_course_status.status === 'error') {
			setLoading(false);
			window.scrollTo(0, 0);
		}
	}, [props.teacher_change_course_status]);
	useEffect(() => {
		if (_.get(data, 'image')) { setImg(IMG_URL + _.get(data, 'image')); }
	}, [props.teacher_change_course]);
	useEffect(() => {
		setPrice(_.get(props.data, 'price'));
		setIsPaid(_.get(props.data, 'type') === 'paid' ? true : false); 
		setMinut(parseInt(_.get(data, 'hours', 0)));
		setCategoryId(parseInt(_.get(data, 'category_id', 0)));
		setLan(_.get(data, 'language_id', 1));
		setType(_.get(data, 'type', 'upgrade')); 
		setStatus(_.get(data, 'status', 0));
		 
	}, [props.data]);
 
	 
	 
	const onChangeCredit = value => {
		const test = new RegExp(/^[0-9.]{0,6}$/).test(value);
		if (test) {
			setMinut(value);
		} 
	};
	const submitHandler = event => {
		event.preventDefault();
		setLoading(true);
		let formData = new FormData(event.target);
		let minut = formData.get('minut');
		const name = formData.get('name');
		// const status = formData.get('status');
		// console.log(status);
		let n = true,
			c = true,
			m = true;
		if (name === '') {
			n = false;
			setNameE(t('create_course.name_e'));
		} else {
			setNameE('');
		} 
		if (!minut) {
			m = false;
			minut = 0;
			setMinutE(t('create_course.m_e'));
		} else {
			setMinutE('');
			//credit
			formData.append('hours', parseInt(minut));
		} 
		if ( parseInt(category_id)<1) {
			c = false; 
			setCategoryE(t('create_course.category'));
		} else {
			setCategoryE('');
		}
		 
		if (n && c && m) {
			props.postTeacherCourse(
				formData,
				props.data.id ? props.data.id : null,
				next_page
			);
		} else {
			setLoading(false);
		}
	};
	const changeImages = event => {
		const images = event.target.files;
		if (images[0]) {
			const imgUrl = URL.createObjectURL(images[0]);
			setImg(imgUrl);
		}
	};
	const changeLan = value => {
		setLan(parseInt(value));
	}; 
	const changeType = value => {
		// props.getTeacherCategorys(value, position, lan);
		setIsPaid(value === 'paid' ? true : false);
		setPrice(value === 'paid' ? price : 0);
		setCategoryId(0); 
		setType(value);
	};
	const deleteImage = async () => {
		const id = _.get(props.data, 'id');
		await props.deleteFile({ id: id, type: 'course', field: ['image'] });
		setImg('');
	};
	// console.log(data);
	return (
		<>
			<div className="row row-dashboard-sidebar">
				<div
					className="col-12"
					style={{ flex: '0 0 100%', maxWidth: '100%' }}
				>
					<form onSubmit={submitHandler}>
						<div className="card card--shadow mb-5">
							<div className="card-body">
								<h6 className="mb-4"> {t('create_course.title')} </h6>
								<div className="form-group">
									<label> {t('create_course.name')} *</label>
									<input
										type="text"
										className="form-control"
										name="name"
										onClick={() => setNameE('')}
										defaultValue={_.get(data, 'name')}
									/>
									<span style={{ color: 'red' }}>{name_e}</span>
								</div>
								<div className="form-group">
									<label> {t('create_course.status')} </label>
									<div
										className="setting__buttons mb-2"
										style={{ justifyContent: 'left' }}
									>
										<label className="switch">
											<input
												type="checkbox"
												name="status"
												checked={status}
												value={status}
												onChange={e => {
													setStatus(
														e.target.value === '1' ? 0 : 1
													);
													console.log(e.target.value);
												}}
											/>
											<span className="slider round"></span>
										</label>
									</div>
								</div>
								<div className="form-group">
									<label> {t('create_course.description')} </label>
									<textarea
										className="form-control"
										rows="6"
										name="description"
										defaultValue={_.get(data, 'description')}
									></textarea>
								</div>
								<div className="form-group">
									<label>
										{' '}
										{t('create_course.image')} (.jpg, .jpeg, .png){' '}
									</label>
									<div className="custom-file">
										<input
											type="file"
											name="image"
											style={{
												border: '1px solid',
												width: 120,
												height: 40,
											}}
											accept=".jpg, .jpeg, .png"
											onChange={event => changeImages(event)}
										/>
										<div style={{ cursor: 'pointer' }}>
											{' '}
											{t('create_course.image_btn')}{' '}
										</div>
										{img ? (
											<button
												className="btn btn-sm"
												onClick={deleteImage}
												type="button"
												style={{
													background: 'red',
													color: 'white',
													marginLeft: 10,
												}}
											>
												<span
													className="icon icon-remove"
													style={{ marginRight: 5 }}
												></span>
												<span>
													{t('create_course.delete-image')}
												</span>
											</button>
										) : null}
									</div>
									<img
										style={{
											height: 150,
											width: 150,
											objectFit: 'cover',
											borderRadius: 5,
											margin: 10,
										}}
										onError={e => {
											e.target.onerror = null;
											e.target.src = '/images/courses/no-image.jpg';
										}}
										src={img}
										alt="Course"
									/>
								</div>
								<div className="fit-content-form-group">
									<div className="form-group">
										<label> {t('create_course.credit_l')}</label>
										<div className="row  hours-target">
											<div className="col-6 col-xl-4">
												<input
													type="text"
													className="form-control "
													name="minut"
													onClick={() => setMinutE('')}
													onChange={event =>
														onChangeCredit(event.target.value)
													}
													value={minut}
												/>{' '}
												{' ' + t('create_course.credit')}
												<span style={{ color: 'red' }}>
													{minut_e}
												</span>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label> {t('create_course.language')} </label>
										<select
											className="form-control custom-select"
											name="language_id"
											onChange={e =>
												changeLan(parseInt(e.target.value))
											}
											value={lan}
										>
											{teacher_lan?.data
												? teacher_lan.data.map((lan, index) => (
														<option value={lan.id} key={index}>
															{lan.name}
														</option>
												  ))
												: null}
										</select>
									</div>
									<div className="form-group">
										<label> {t('create_course.type')} </label>
										<select
											className="form-control custom-select"
											name="type"
											value={type}
											onChange={event =>
												changeType(event.target.value)
											}
										>
											<option selected value="upgrade">
												{t('create_course.upgrade')}
											</option>
											<option value="information"> 
												{t('create_course.information')} 
												
											</option>
											<option value="paid"> 
												{t('create_course.paid')} 
											</option> 
										</select>
									</div>  
									{is_paid ? (
										<div className="form-group">
											<label> {t('create_course.price')} </label>
											<input
												type="number"
												className="form-control"
												name="price"
												onClick={() => setPriceE('')}
												onChange={event =>
													setPrice(event.target.value)
												}
												value={price}
												style={{ maxWidth: 200, display: 'inline' }}
											/>{' '}
											{t('create_course.sum')}
											<span style={{ color: 'red' }}>{price_e}</span>
										</div>
									) : null}
								</div> 
								<div className="fit-content-form-group">
									<div className="form-group">
										<label>{t('create_course.category')}</label>
										<select
											className="form-control custom-select"
											name="category_id"
											value={category_id}
											onChange={event => {
												setCategoryId(
													parseInt(event.target.value)
												);
												setCategoryE('');
											}}
										>
											<option value={0} selected>
												{t('create_course.cat')}
											</option>
											{teacher_category
												? teacher_category?.map((cat, index) => (
														<option value={cat.id} key={index}>
															{cat.name}
														</option>
													))
												: null}
										</select>
										<span style={{ color: 'red' }}>
											{category_e}
										</span>
									</div>
								</div>  
							</div>
						</div>
						<div className="test__buttons mb-5">
							{loading ? (
								<Loading />
							) : (
								<div className="dashboard-buttons">
									<button
										type="submit"
										className="btn btn-primary mr-2"
										onClick={() => setNextPage('')}
									>
										{t('create_course.save')}
									</button>
									<button
										type="submit"
										className="btn btn-primary ml-2"
										onClick={() =>
											setNextPage(
												'/dashboard/copyrights/course-items/' +
													(props.data.id ? props.data.id : '')
											)
										}
									>
										{props.is_create
											? t('create_course.save_etap')
											: t('create_course.save_material')}
									</button>
								</div>
							)}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getTeacherLanguages: () => dispatch(getTeacherLanguages()),
		getTeacherCategorys: (type='', user_position='', lan='') =>
			dispatch(getTeacherCategorys(type, user_position, lan)),
		postTeacherCourse: (data, id, next_page) =>
			dispatch(postTeacherCourse(data, id, next_page)),
		deleteFile: data => dispatch(deleteFile(data)),
	})
)(CopyrightCreateOrUpdate);
