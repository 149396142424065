import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getWebinar, getChatMessages, getChatMarkall, getProfile } from '../actions';
import Layout from './Layout';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import { FILE_URL, IMG_URL } from '../helpers/api';
import ChatBody from "./sections/ChatBody";
import {getToken} from "../helpers/tokenStorge";
import {WS_HOST} from "../helpers/api";

const WebinarLivePage = props => {
	const { t } = useTranslation();
	const { webinar_data, profile } = props;
	const [loading, setLoading] = useState(true);
    
    const set_WebSocketMessage = (id, message__data) =>{
        props.setWebSocketMessage(id, message__data);
        
        // console.log(  message__data);
    }
    useEffect(() => {
        const ws = new WebSocket("wss://"+WS_HOST+"/ws/messages/?token="+getToken() );
        // if (ws.readyState !== WebSocket.OPEN) console.log('readyState', ws.readyState);
        
        ws.onopen = () => {
            console.log('connected', ws.readyState);
        }
        ws.onmessage = evt => {
            const message_data = JSON.parse(evt.data);
            const message = _.get(message_data, 'message', {});
            const conversation_id = _.get(message, 'conversation_id', 0);
            console.log('======>', conversation_id, message);
            set_WebSocketMessage(conversation_id, message);
        }
        // ws.onclose = () => {
        //     console.log('disconnected');
        // }
        
        return () => {
            ws.close();
		}

    // }, [lan]);
    }, []);
	useEffect(() => {
		if (props.webinar_data_status.status === 'started') {
			setLoading(true);
		}
		if (props.webinar_data_status.status === 'success') {
			setLoading(false);
			props.getChatMessages(_.get(webinar_data, 'chat_id', 0));
		}
		if (props.webinar_data_status.status === 'error') {
			setLoading(false);
		}
		document.title = _.get(webinar_data, 'title', t('webinars.title'));
		return ()=>{
            props.getChatMarkall(_.get(webinar_data, 'chat_id', 0));
        }
	}, [props.webinar_data_status.status]);
	useEffect(() => {
		props.getProfile();
		props.getWebinar(props.match.params.slug);
        return ()=>{
            props.chat_open(0);
        }
	}, [props.match.params.id]);
	const opts = {
		height: '400',
		width: '100%',
		playerVars: {
		  // https://developers.google.com/youtube/player_parameters
		  autoplay: 1,
		  color: '#26CAAC',
		  showinfo: 0
		},
	  };
	const _onReady = (event) => {
	console.log(event);
	// access to player in all event handlers via event.target
	// event.target.pauseVideo();
	}
	return (
		<Layout>
			<main className="main">
				<section className="section section-breadcrumbs">
					<div className="container">
						<Navigation
							active={_.get(webinar_data, 'title')}
							navs={[
								{
									title: t('webinar_view.title'),
									link: '/webinars/list/1',
								},
							]}
						/>
					</div>
				</section>
				<section className="section py-3">
					<div className="container">
						{loading ? (
							<Loading />
						) : webinar_data && webinar_data.id ? (
								<div className="row">
									<div className="col-lg-7 col-xl-8">
										<div className="card" style={{background: '#f7f7f7'}}>
											<div className="card-body">
												<div className="youtube-container">
													<iframe id="yyyyyy" 
														// src="https://www.youtube.com/embed/ReQowXB5rLI"
														src={"https://www.youtube.com/embed/"+_.get(webinar_data, 'live_url', '')}
														title="YouTube video player" 
														frameborder="0" 
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
														allowfullscreen='1'>
													</iframe>
												</div>
												<h3 className="mt-3"><span className="icon icon-webinar"></span> {_.get(webinar_data, 'title')} </h3>
											</div>
										</div>
									</div>
									<div className="col-lg-5 col-xl-4">
										<div className="card" style={{background: '#f7f7f7'}}>
											<div className="card-body">
												<h4 className="mb-2"> <span className="icon icon-chat"></span> Chat</h4>
												<div className="chat__main bg-white">
													{_.get(webinar_data, 'chat_id', false)?
														<ChatBody 
															chat_id = {_.get(webinar_data, 'chat_id', 0)}
														/>
													:<NoData text="Chat yo'q" />}
												</div>
											</div>
										</div>
									</div>
									<div className="col-12">
										<div className="card" style={{background: '#f7f7f7'}}>
											<div className="card-body">
												<p>{_.get(webinar_data, 'description')}</p>
												{_.get(webinar_data, 'presentation') ? (
													<a
														href={FILE_URL + _.get(webinar_data, 'presentation')}
														className="btn btn-primary ml-auto mt-2"
														target="blank">
														{t('course_view.view-webinar')}
													</a>
												) : null}
											</div>
										</div>
									</div>

								</div>
						) : (
							<NoData text={t('webinar_view.no_data')} />
						)}
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getWebinar: id => dispatch(getWebinar(id)),
        getChatMessages: (chat_id)=>dispatch(getChatMessages(chat_id)),
        getProfile: ()=>dispatch(getProfile()),
        getChatMarkall: (chat_id)=>dispatch(getChatMarkall(chat_id)),
        setWebSocketMessage: (t_id, m)=>dispatch({ type: 'SET_MESSAGE_SOCKET', payload: t_id, message: m, live: true }), 
        chat_open: (chat_id)=>dispatch({type: "OPEN_CHAR", id: chat_id})
	}),
)(WebinarLivePage); 