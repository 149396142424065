import {useEffect} from 'react';
import styles from './Message.module.css';

const Message = (props) => {
	useEffect(()=>{
		if(!props.message.lasting){
			setTimeout(()=>props.close_message(props.message.time) , 5000);
		}
	},[]);
	return (
		<div className={styles.con+' '+props.message.type}>
			<span dangerouslySetInnerHTML={{ __html: props.message.text}} />
			<span className={styles.fix}>
				<span aria-hidden="true" onClick={()=>props.close_message(props.message.time)}>&times;</span>
			</span>
		</div>
	);
}
export default Message;