import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { courseBuy } from '../../actions';
import { IMG_URL } from '../../helpers/api';
import { getInstance } from '../../helpers/httpClient';
import { issetToken } from '../../helpers/tokenStorge';
import CertificateModal from './CertificateModal';
import Loading from './Loading';
import PriceFormat from './PriceFormat';
window.jQuery = $;

const CourseBody = props => {
	const { t } = useTranslation();
	const { data } = props.course;
	const {course} = props;
	const [loading, setLoading] = useState(false);
	const [buy_show, setBuyShow] = useState(true);
    const [targetLoading, setTargetLoading] = useState(false);
	const [dateC, setDateC] = useState('');
	const [user, setUser] = useState({ price: 0 });
	const [balance_loading, setBalaceLoading] = useState(true);
	const [pay, setPay] = useState(false);
	const [pay_e, setPayE] = useState('');
	const [amount, setAmount] = useState('');
	const [clickAmount, setClickAmount] = useState('');
	const [clickError, setClickError] = useState(false);
	useEffect(() => {
		if (props.course_buy_status.status === 'started') {
			setLoading(true);
		}
		if (props.course_buy_status.status === 'success') {
			setLoading(false);
			setBuyShow(false);
			window.$('#modalPaidCourse').modal('hide');
		}
		if (props.course_buy_status.status === 'error') {
			setLoading(false);
		}
	}, [props.course_buy_status]);
	const buy_paid_course = () => {
		setBalaceLoading(true);
		setPay(false);
		let date_completion = new Date();
		date_completion.setDate(date_completion.getDate() + 30);
		setDateC(<Moment format="DD/MM/YYYY">{date_completion}</Moment>);
		get_balance();
	};
	const get_balance = () => {
		getInstance()
			.get('/api/v1/profil/balance')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setUser(data);
						setBalaceLoading(false);
					} catch {
						setBalaceLoading(false);
					}
				}
			})
			.catch(error => setBalaceLoading(false));
	};
	useEffect(() => {
		if (/*data.active_buys_count===0 &&*/ window.location.search === '?pay=1') {
			setBalaceLoading(true);
			window.$('#modalPaidCourse').modal('show');
			get_balance();
			let date_completion = new Date();
			date_completion.setDate(date_completion.getDate() + 30);
			setDateC(<Moment format="DD/MM/YYYY">{date_completion}</Moment>);
		}
	}, []);
	const payClick = e => {
		e.preventDefault();
		if (parseInt(clickAmount) > 999) {
			window.open(
				`https://my.click.uz/services/pay?service_id=19570&merchant_id=1&amount=${clickAmount}&transaction_param=${_.get(
					user,
					'user.id',
					0,
				)}`,
				'_blank',
			);
		} else {
			setClickError(true);
		}
	};
	const onChangePay = value => {
		setPayE('');
		const v = parseInt(value);
		if (Number.isInteger(v) && v >= 0) {
			setAmount(v);
		} else {
			setAmount('');
		}
	};
    const getTargetCertificate = () => {
        setTargetLoading(true);
        getInstance()
			.post('/api/v1/courses/set-certificate-target', {course_id: _.get(course, 'data.id', 0)})
			.then(response => {
                props.target_set_sertificate({
                    type: 'TARGET_SET_CERTIFICATE',
                    payload: _.get(response, 'data.certificat', null)
                })
                setTargetLoading(false);
                window.$('#modalCertificate_1').modal('show');
			})
			.catch(error => setTargetLoading(false) );
    }
	return (
		<>
			<div className="course-single__content">
				{/* <h3 className="mb-4">{data.name}</h3> */}
				<div className="webinar-details">
					<div className="row justify-content-center">
						{_.get(course, 'data.type', '')==='target'&&_.get(course, 'data.course_complate', false)?<>
						<div className='col-12'>
							<h4>{t("target_course.target_cer")}</h4>
							{_.get(course, 'data.targer_certificat.image', '')?<>
								<button className="btn btn-primary btn-sm"
									onClick={()=>{window.$('#modalCertificate_1').modal('show')}}
								>
									{t("target_course.view")}
								</button>
								<br/>
								<CertificateModal certificate={_.get(course, 'data.targer_certificat', {})}/>
							</>:<>
								<p>{t("target_course.text")}</p>
								{targetLoading?<Loading/>:
								<button className="btn btn-primary btn-sm"
									onClick={getTargetCertificate}
								>{t("target_course.get")}</button>}
								<br/>
							</>}
						</div>
						
						</>:""}
						<div className="col-12">
							<div className="row">
								<div className="col-md-6 webinar-col-2 order-md-2">
									<div className="webinar-details__card">
										<img
											src={IMG_URL + _.get(data, 'image')}
											alt="course"
											onError={e => {
												e.target.onerror = null;
												e.target.src = '/images/courses/course.jpg';
											}}
										/>
										<div className="webinar-details__card-caption">
											<div>
												<p className="course__rating">
													<div
														className={
															_.get(data, 'reviews_avg_rating')
																? 'rating rating-' +
																  (
																		Number.parseFloat(
																			_.get(data, 'reviews_avg_rating'),
																		) * 2
																  ).toFixed()
																: 'rating rating-0'
														}></div>
													<span style={{ color: '#000' }}>
														{_.get(data, 'reviews_avg_rating')
															? Number.parseFloat(
																	_.get(data, 'reviews_avg_rating'),
															  ).toFixed(1)
															: null}
													</span>
												</p>
												<p>
													<span className="icon icon-books"></span>
													{_.get(data, 'category.name')}
												</p>
												<p>
													<span className="icon icon-video"></span>
													{_.get(data, 'language.name')}
												</p>
												<p>
													<span className="icon icon-users"></span>{' '}
													{t('course_view.buys_count')}{' '}
													{_.get(data, 'buys_count')}
												</p>
												<p>
													<span className="icon icon-clock"></span> {data.hours}{' '}
													{t('create_course.credit')}
													{/* { parseInt(data.hours/60) } {t('course_view.h')} { parseInt(data.hours)%60 } {t('course_view.m')} */}
												</p>
												<p>
													<PriceFormat data={data} />
												</p>
											</div>
											{data.active_buys_count === 0 ? (
												<>
													{loading ? (
														<Loading size={6} />
													) : issetToken() ? (
														data.type === 'paid' ? (
															<button
																className="btn btn-primary"
																data-toggle="modal"
																data-target="#modalPaidCourse"
																onClick={() => buy_paid_course()}>
																{t('course_view.btn_purchase')}
															</button>
														) : (
															<button
																className="btn btn-primary"
																onClick={() => {
																	setLoading(true);
																	props.courseBuy({ course_id: data.id });
																}}>
																{t('course_view.btn')}
															</button>
														)
													) : (
														<button
															className="btn btn-primary"
															data-toggle="modal"
															data-target="#modalLogin">
															{t('course_view.btn')}
														</button>
													)}
												</>
											) : null}
										</div>
									</div>
								</div>
								<div className="col-md-6 webinar-col-1 order-md-1">
									<div className="webinar-details__description">
										<p>{data.description}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modalPaidCourse"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalPaidCourseTitle"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							{/* <h5 className="modal-title">{t('course_view.btn_purchase')}</h5> */}
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close">
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="card mb-5">
										<div className="card-body">
											<h3 className="mb-4">{t('course_view.course')}:</h3>
											<ul className="requalification__payment-details">
												<li>
													<div>{t('course_view.cat')}</div>
													<div style={{ textAlign: 'right', paddingLeft: 10 }}>
														{_.get(data, 'category.name')}
													</div>
												</li>
												<li>
													<div>{t('course_view.name')}</div>
													<div style={{ textAlign: 'right', paddingLeft: 10 }}>
														{_.get(data, 'name')}{' '}
													</div>
												</li>
												<li>
													<div>{t('course_view.lan')}</div>
													<div>{_.get(data, 'language.name')}</div>
												</li>
												<li>
													<div>{t('course_view.end')}</div>
													<div style={{ textAlign: 'right', paddingLeft: 10 }}>
														{dateC}
													</div>
												</li>
												<li>
													<div>{t('course_view.price')}</div>
													<div style={{ textAlign: 'right', paddingLeft: 10 }}>
														{_.get(data, 'price', 0)
															.toFixed(1)
															.replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
															.replace('.0', '')}{' '}
														{t('one_page.sum')}
													</div>
												</li>
											</ul>
											<ul className="requalification__payment-details">
												<li>
													{balance_loading ? (
														<Loading size={6} />
													) : (
														<>
															<div>{t('course_view.balance')}</div>
															<div
																style={{ textAlign: 'right', paddingLeft: 10 }}>
																{parseFloat(user.price)
																	.toFixed(1)
																	.replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
																	.replace('.0', '')}{' '}
																{t('one_page.sum')}
															</div>
														</>
													)}
												</li>
											</ul>
										</div>
									</div>
								</div>
								{pay ? (
									<>
										<form className="row" onSubmit={payClick}>
											<div className="col-12">
												<div className="form-group">
													<input
														className="form-control"
														type="number"
														value={clickAmount}
														onFocus={() => setClickError(false)}
														onChange={e => {
															setClickAmount(e.target.value);
															setClickError(false);
														}}
													/>
												</div>
												{clickError ? (
													<p style={{ color: 'red' }}>
														{t('course_view.pay_error')}
													</p>
												) : null}
											</div>
											<div className="col-6">
												<button
													type="button"
													className="btn btn-lighter btn-sm w-100"
													data-dismiss="modal">
													{t('course_view.cencel')}
												</button>
											</div>
											<div className="col-6">
												<button
													type="submit"
													className="btn btn-primary btn-sm w-100">
													{t('course_view.pay')}
												</button>
											</div>
										</form>
										{/* <form
										method="POST"
										action="https://checkout.paycom.uz/"
										className="row">
										<input type="hidden" name="lang" value="ru" />
										<input type="hidden" name="callback_timeout" value="0" />
										<input
											type="hidden"
											name="merchant"
											value="5eb464c06420f3660dc7e72a"
										/>
										<input
											type="hidden"
											name="amount"
											id="amount"
											value={amount + '00'}
										/>
										<input
											type="hidden"
											name="account[id_transaction]"
											value={_.get(user, 'user.id', 0)}
										/>
										<input
											type="hidden"
											name="callback"
											value={
												window.location.origin +
												window.location.pathname +
												'?pay=1'
											}
										/>
										<input
											type="hidden"
											name="description"
											value="Пополнение счета на сайте Onlinedu.uz"
										/>
										<input type="hidden" name="detail" value="" />
										<div className="col-12">
											<div className="form-group">
												<label for=""> {t('course_view.pay_price')} </label>
												<input
													type="text"
													className="form-control"
													name="amount_summa"
													id="amount_summa"
													placeholder={t('course_view.pay_price')}
													onChange={e => onChangePay(parseInt(e.target.value))}
													value={amount}
												/>
												<span style={{ color: 'red' }}>{pay_e}</span>
											</div>
										</div>
										<div className="col-6">
											<button
												type="button"
												className="btn btn-lighter btn-sm w-100"
												data-dismiss="modal">
												{t('course_view.cencel')}
											</button>
										</div>
										<div className="col-6">
											{amount && amount >= 1000 && amount <= 10000000 ? (
												<input
													type="submit"
													value={t('course_view.pay')}
													className="btn btn-primary btn-sm w-100"
												/>
											) : (
												<button
													type="button"
													className="btn btn-primary btn-sm w-100"
													onClick={() => setPayE(t('course_view.pay_error'))}>
													{t('course_view.pay')}
												</button>
											)}
										</div>
									</form> */}
									</>
								) : balance_loading ? null : (
									<>
										<div className="col-6">
											<button
												type="button"
												className="btn btn-lighter btn-sm w-100"
												data-dismiss="modal">
												{t('course_view.cencel')}
											</button>
										</div>
										{_.get(data, 'price', 0) <= user.price ? (
											<div className="col-6">
												<button
													type="button"
													className="btn btn-primary btn-sm w-100"
													onClick={() => {
														setLoading(true);
														props.courseBuy({ course_id: data.id });
													}}>
													{t('course_view.buy')}
												</button>
											</div>
										) : (
											<div className="col-6">
												<button
													type="button"
													className="btn btn-primary btn-sm w-100"
													onClick={() => setPay(true)}>
													{t('course_view.pay_balance')}
												</button>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		courseBuy: data => dispatch(courseBuy(data)),
		target_set_sertificate: data => dispatch(data)
	}),
)(CourseBody);
