import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import PasswordInput from "./PasswordInput";
import {forgotNewPassword} from "../../actions";
import Loading from './Loading';
import $ from 'jquery';
import history from '../../history';
import { useTranslation } from 'react-i18next';

const  ForgotNewPassword = (props) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');    
    const [r_password, setRPassword] = useState('');
    const [r_passwordError, setRPasswordError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [commonError, setCommonError] = useState('');
    const {forgot_new_password} = props;
    useEffect(() => {
        if(forgot_new_password.status === 'success'){
            // $('#modalLogin').hide();
            // $('.modal-backdrop').hide();
            // $( "body" ).removeClass( "modal-open" );
            // history.push('/dashboard/profile');
            props.actState(0);
        }
        if(forgot_new_password.status === 'started'){
            setLoading(true);
        }else{
            setLoading(false);
            if(forgot_new_password.status === 'error'){
                setCommonError(forgot_new_password.error_msg);
            }
        }

    }, [forgot_new_password]);

    const onSubmit = event => {
		event.preventDefault();
        let  pass=true, phone_c=true, rpass=true, r=true ;
        const phone = sessionStorage.getItem('phone');
        if(phone&&phone.length!==12){
            setCommonError('Error');
            phone_c=false;
        }else{
            setCommonError('');
            phone_c=true;
        }
        if(password.length<8){
            setPasswordError(t('sign_up.password_e'));
            pass=false;
        }else{
            setPasswordError('');
            pass=true;
        }
        if(r_password.length<8){
            setRPasswordError(t('sign_up.r_password_e'));
            rpass=false;
        }else{
            setRPasswordError('');
            rpass=true;
        }
        if(pass&&rpass){
            if(password!==r_password){
                setPasswordError(t('sign_up.password_e2'));
                r = false;
            }else{
                setPasswordError('');
                r=true;
            }
        }
        if(pass&&phone_c&&rpass&&r){
            props.forgotNewPassword({
                    login: phone,
                    password: password,
                    confirm_password: r_password
                }
            )
        }
    }
    return (<>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('forgot.new_pass')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon icon-close"></span>
                    </button>
                </div>
                <div className="modal-body">
                <form onSubmit={onSubmit} noValidate>
                    <div className="form-group mb-3">
                        <label for="">{t('sign_up.password')}</label> 
                        <div className="password-field-wrapper">
                            <PasswordInput
                                className="form-control"
                                setPassword={(p)=>setPassword(p)}
                                onClickInput={()=>{setPasswordError(""); setCommonError('');} }
                            />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label for="">{t('sign_up.r_password')}</label>
                        <div className="password-field-wrapper">
                            <PasswordInput
                                className="form-control"
                                setPassword={(p)=>setRPassword(p)}
                                onClickInput={()=>{setRPasswordError(""); setCommonError('');} }
                            />
                            <div style={{color: 'red'}}>{commonError}</div>
                            <div style={{color: 'red'}}>{passwordError}</div>
                            <div style={{color: 'red'}}>{r_passwordError}</div>
                        </div>
                    </div>
                    {loading?<Loading />:
                    <button type="submit" className="btn btn-primary w-100">
                        {t('sign_in.btn')}
                    </button>
                    }
                    <div className="form-group mt-4 mb-5">
                        <p style={{color: '#26CAAC', cursor: 'pointer'}}
                            onClick={()=>props.actState(0)}
                        >{t('validate_sms.sign_in')}</p>
                        {/* <p style={{color: '#26CAAC', cursor: 'pointer'}}
                            onClick={()=>props.actState(1)}
                        >{t('validate_sms.sign_up')}</p> */}
                    </div>
                </form>
                </div>
            </div>
  </>);
}
export default connect(
    (state) => ({
        forgot_new_password: state.forgot_new_password
    }),
    (dispatch) => ({
        forgotNewPassword: (data)=>dispatch(forgotNewPassword(data))
    })
)(ForgotNewPassword);