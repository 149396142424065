import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getProfile, getUnreadCountNotification } from '../../actions'; 
import ExcelReadyModal from './ExcelReadyModal'; 
import LogOut from './LogOut';
import Warning from './Warning';
window.jQuery = $;
const DashSideBar = props => {
	const { t, i18n } = useTranslation();
	const { unread_count, profile_status, profile } = props;
	const role_cashe = localStorage.getItem('role');
	const [role, setRole] = useState(role_cashe); 
	useEffect(() => { 
		const role_c = parseInt(localStorage.getItem('role'));
		setRole(role_c); 
	}, []);
	useEffect(() => {
		props.getProfile();
	}, [i18n.language]); 
	useEffect(() => {
		const role_c = parseInt(localStorage.getItem('role'));
		setRole(role_c);
	}, [profile_status]);
	return (
		<>
			<div className="col-md-12 col-lg-3 col-xl-2">
				<div
					className="dashboard-left left-sidebar-menu"
					style={{ minHeight: 550 }}
				>
					<div className="dashboard-left__inner left-sidebar-menu__inner">
						<ul className="dashboard-menu">
							{role === 3 ? (
								<>
									<li>
										<NavLink
											to="/dashboard/profile"
											activeClassName="active"
										>
											<span className="icon icon-user-o"></span>
											{t('dash_side_bar.profile')}
										</NavLink>
									</li>   
									<li>
										<NavLink
											to="/dashboard/copyrights"
											activeClassName="active"
										>
											<span className="icon icon-video"></span>
											{t('dash_side_bar.copyright')}
										</NavLink>
									</li>
									<li>
										<NavLink
											to="/dashboard/exams"
											activeClassName="active"
										>
											<span className="icon icon-paper"></span>
											{t('exams.title')} 
										</NavLink>
									</li>  
									<li>
										<NavLink
											to="/dashboard/notification"
											activeClassName="active"
										>
											<span className="icon icon-bell"></span>
											{t('dash_side_bar.notification')}{' '}
											{unread_count.count ? (
												<span className="notification_count">
													{unread_count.count}
												</span>
											) : null}
										</NavLink>
									</li>
									<li>
										<NavLink
											to="/dashboard/settings"
											activeClassName="active"
										>
											<span className="icon icon-settings"></span>
											{t('dash_side_bar.setting')}
										</NavLink>
									</li> 
									 
								</>
							) :  
								<>
									<li>
										<NavLink
											to="/dashboard/profile"
											activeClassName="active"
										>
											<span className="icon icon-user-o"></span>
											{t('dash_side_bar.profile')}
										</NavLink>
									</li> 
								</>
							  }
							<li>
								<strong data-toggle="modal" data-target="#modalLogout">
									<span className="icon icon-exit"></span>
									{t('dash_side_bar.log_out')}
								</strong>
							</li>
							<br />
							<br />
							<br />
						</ul>
					</div>
				</div>
			</div>
			<LogOut /> 
			{profile.id && profile.is_teacher ? (
				<ExcelReadyModal unread_count={_.get(unread_count, 'excel', [])} />
			) : null}
			<Warning />
			{/* <TechnitStop/> */}
		</>
	);
};
export default connect(
	state => ({
		unread_count: state.unread_count,
		profile_status: state.profile_status,
		profile: state.profile,
	}),
	dispatch => ({
		getUnreadCountNotification: () => dispatch(getUnreadCountNotification()),
		getProfile: () => dispatch(getProfile()),
		setWebSocketMessage: (t_id, m) =>
			dispatch({ type: 'SET_MESSAGE_SOCKET', payload: t_id, message: m }),
		clearSurvey: () => dispatch({ type: 'CLEAR_SURVEY' }),
		set_user_education: () => dispatch({ type: 'SET_USER_EDU' }),
	})
)(DashSideBar);
