import { setToken } from '../helpers/tokenStorge';
import history from '../history';

const SetTokenComp = props =>{
    const token = props?.match?.params?.token;
    console.log(props, token);
    if(token){
        setToken(token, true);
        history.push('/dashboard/profile');
    }else{
        history.push('/');
    }
    return(<>SetTokenComp</>)
}
export default SetTokenComp