import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { getCourse, getCourseSections } from "../actions";
import _ from 'lodash';
import Layout from './Layout';
import Navigation from "./sections/Navigation";
import CourseReview from "./sections/CourseReview";
import CourseSideBarLeft from "./sections/CourseSideBarLeft";
import { useTranslation } from 'react-i18next';
import CourseBtns from './sections/CoutceBtns';

const CourseReviewPage = (props) => {
    const { t } = useTranslation();
    const { course } = props;
    let slug = props.match.params.slug ? props.match.params.slug : '';
    useEffect(() => {
        props.getCourse(slug);
        props.getCourseSections(slug);
        slug = props.match.params.slug ? props.match.params.slug : '';
		document.title = _.get(course, 'data.name', t('courses.title'));
    }, [props.match.params.slug]);
    return (<Layout>
        <main className="main">
            <section className="section section-breadcrumbs">
                <div className="container">
                    <Navigation
                        is_btn={true}
                        active={t('course_view.review')}
                        navs={[
                            {
                                title: _.get(course, 'data.category.name'),
                                link: "/courses/1?category=" + _.get(course, 'data.category.id')
                            },
                            {
                                title: _.get(course, 'data.name'),
                                link: "/dashboard/courses/view/" + slug
                            }
                        ]}
                    />
                </div>
            </section>
            <section className="section section-course pt-3">
                <div className="container">

                    <h3 className="mb-4">{course.data && course.data.name ? course.data.name : ''}</h3>

                    <div className="row">
                        <div className="col-lg-3">
                            <CourseSideBarLeft slug={slug} />
                        </div>
                        <div className="col-lg-9 course_btns_target">
                            <div className="has-right-buttons__right">
                            <CourseBtns  course={course}  slug={slug} dash={false}/>

                            </div>
                            <CourseReview slug={slug} course={course} />
                        </div>
                    </div>
                </div>
            </section>

        </main>
    </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug) => dispatch(getCourse(slug)),
        getCourseSections: (slug) => dispatch(getCourseSections(slug)),
    })
)(CourseReviewPage);