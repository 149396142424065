import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Radio = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('')
    const submitHandler = event => {
		event.preventDefault();

        if( _.get(survey, 'isRequired', false) &&   !_.get(result, 'value', '') ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', ''), type: survey.type });
        }
    }
    return(
            <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.radio') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group  m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            <ul className="test__item-answers">
                                {_.filter( _.get(survey, 'choices', []), (i)=>{return !i.is_write}).map((item, index)=>(
                                    <li key={index}>
                                        <label className="radio-custom">
                                            <input type="radio" name='input' value={item.id} 
                                                checked={ _.get(result, 'value', '') === item.id }
                                                onChange={e=>{surveyChange( {id: survey.id, value: parseInt( e.target.value ) } );
                                                    setError('');
                                                }}
                                            />
                                            {_.get(item, 'label_'+lan, '')}
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                            {_.filter( _.get(survey, 'choices', []), (i)=>{return i.is_write}).map((item, index)=>(<div key={index}>
                                <label style={{fontSize: 18, marginTop: 10}}> 
                                    {_.get(item, `label_${lan}`, '')} 
                                </label>
                                <input
                                    type='text'
                                    className="form-control"
                                    name="input"
                                    value={_.get(result, 'value.write', '')}
                                    onChange={e=>surveyChange( {id: survey.id, value: e.target.value?{ id: item.id, write: e.target.value }:null } )}
                                    onFocus={()=>setError('')}
                                />
                            </div>))}
                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default Radio;