import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChartColumn from './ChartColumn';
import ChartDoughnut from './ChartDoughnut';
import Loading from './Loading';
import NoData from './NoData';

const TotalStatisticsPupil = props => {
	const { statistic_user, statistic } = props;
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();
	useEffect(() => {
		setLoading(false);
	}, []);
	const total_hours = statistic.total_hours;
	const user_hours = statistic.user_hours;
	return (
		<>
			<div className="dashboard-title">
				<h5>{t('statistics.title')}</h5>
			</div>
			{loading ? (
				<Loading />
			) : statistic.data ? (
				<>
					<div className="row statistics-top">
						<div className="col-md-4">
							<div className="card card--shadow">
								<div className="card-body">
									<h3>
										{total_hours} {t('create_course.credit')}
									</h3>
									<p>{t('statistics.text1')}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card card--shadow">
								<div className="card-body">
									<h3>
										{user_hours} {t('create_course.credit')}
									</h3>
									<p>{t('statistics.text2')}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card card--shadow">
								<div className="card-body">
									<h3>
										{total_hours > user_hours ? total_hours - user_hours : 0}{' '}
										{t('create_course.credit')}
									</h3>
									<p>{t('statistics.text3')}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row statistics-graph">
						{statistic_user.length > 0 ? (
							<div className="col-12 col-md-8">
								<div className="card">
									<div className="card-body">
										<div className="statistics-graph__info">
											<p>{t('statistics.text4')}</p>
										</div>
										<div className="statistics-graph__chart">
											<ChartColumn data={statistic_user} />
										</div>
									</div>
								</div>
							</div>
						) : null}
						<div className="col-12 col-md-4">
							<div className="card">
								<div className="card-body mb-5">
									<div className="statistics-graph__info mb-7">
										<p>{t('statistics.text5')}</p>
										<small>{t('statistics.text6')}</small>
									</div>
									<div className="statistics-graph__chart">
										<ChartDoughnut data={statistic.data} total_hours={statistic.total_hours} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<NoData text={t('statistics.not-statistics')} />
			)}
		</>
	);
};
export default TotalStatisticsPupil;
