import React, {useState, useEffect} from 'react';
import { getNotifications, markNotification } from "../actions";
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import { useTranslation } from 'react-i18next';
import Pagination from './sections/Pagination';
import NoData from './sections/NoData';
import { Link } from 'react-router-dom';
import Loading from './sections/Loading';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import { getInstance } from '../helpers/httpClient';


const DashNotificationPage = (props) => {
    const {notifications_status, notifications} = props;
    const page = props.match.params.page;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(0);
    useEffect(()=>{
        props.getNotifications(page);
    },[page]);
    useEffect(()=>{       
        if(notifications_status.status === 'started' ){
            setLoading(true);
        }
        if(notifications_status.status === 'success'){
            setLoading(false);
           
        }
        if(notifications_status.status === 'error'){
            setLoading(false);
        }
       
    },[notifications_status]);
    let list_show = [];
    const metodistNotification = (id) => {
        props.malkNotificationMetodist(id);
        getInstance()
        .get('/api/v1/notification-detail?id='+id);
    }
    const viewNotification = (item) => {
        if(open!==item.id){
            setOpen(item.id);
            if(item.is_read !==1){
                if(item.type!=='metodist')
                    props.markNotification(item.id);
                else metodistNotification(item.id);
            }
        }else setOpen(0);
    }
  return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher, roles.manager, roles.admin ] } />
      <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('notifications.title')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    }
                                ]}
                            />
                            <div className="dashboard-title">
                                <h5>{t('notifications.title')}</h5>
                            </div>
                            
                            {loading?
                                <Loading/>
                            :<>
                            {notifications.data&&notifications.data.length>0?<>
                                <div className="container">
                                <div className="row mt-6">
                                    {notifications.data.map((notification, index)=>{
                                        const date = new Date(notification.created_at).toLocaleDateString()
                                        if(!list_show.includes(date)){
                                            list_show.push(date);  
                                            return(
                                                <div className="card notification-card col-12" key={index}>
                                                    <div className="card-header">{date}</div>
                                                    <div className="card-body">
                                                        {notifications.data.map((item, index_item)=>{
                                                            const date_item = new Date(item.created_at).toLocaleDateString()
                                                            if(date_item===date){
                                                                return(<>
                                                                    <div className="notification" key={index_item}>
                                                                        <span className="icon icon-bell" style={item.status===0?{color: '#26CAAC'}:{}} ></span>
                                                                        <p className="mr-2">
                                                                            <div style={item.is_read===0?{ color: '#26caac', cursor: 'pointer' }:{color: '#999', cursor: 'pointer'}}
                                                                                onClick={()=>viewNotification(item)}
                                                                            >
                                                                                {item.title} -  <strong> {item.description} </strong>
                                                                            </div>
                                                                        </p>
                                                                        {open===item.id?
                                                                        <span className="icon__button" style={{margin: '0 0 0 auto'}} onClick={()=>setOpen(0)}> 
                                                                            <span className="icon icon-arrow-up" style={{margin: 0}}></span>
                                                                        </span>
                                                                        :<span className="icon__button" style={{margin: '0 0 0 auto'}} onClick={()=>viewNotification(item)}> 
                                                                            <span className="icon icon-arrow-down-1" style={{margin: 0}}></span>
                                                                        </span>}
                                                                    </div>
                                                                    {open===item.id?<div style={{margin: '0 0 30px 30px'}}>
                                                                        {item.detail}
                                                                        <div>
                                                                            {item.type!=='metodist'?
                                                                            <a  href={item.url} target="_blank"  
                                                                                className="btn btn-primary btn-sm mt-2" 
                                                                                style={{color: '#fff'}}
                                                                            > 
                                                                                <span className="icon icon-link" style={{margin: 0}}></span> Ko'rish
                                                                            </a>
                                                                            :<Link to={"/dashboard/chat/"+item.chat_id} className="btn btn-primary btn-sm mt-2" style={{color: '#fff'}}> 
                                                                                <span className="icon icon-chat" style={{margin: 0}}></span> Chat
                                                                            </Link>}
                                                                        </div>
                                                                    </div>:null}
                                                                </>);
                                                            }
                                                         }) }
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                </div>
                                <Pagination
                                    count={20}
                                    total={notifications.total?notifications.total:20}
                                    active={ parseInt(page) }
                                    link="/dashboard/notification/"
                                />
                                </>
                                :<div className="row">
                                    <div className="col-12">
                                        <NoData
                                            text={t('notifications.no_data')}
                                        />
                                    </div>
                                </div>
                                }
                            </>}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>


  </Layout>);
}
export default connect(
    (state) => ({
        notifications: state.notifications,
        notifications_status: state.notifications_status
    }),
    (dispatch) => ({
        getNotifications: (page) => dispatch(getNotifications(page)),
        markNotification: (id) => dispatch(markNotification(id)),
        malkNotificationMetodist: (id) => dispatch({type: 'NOTIFICATION_MARK', payload: id})
    })
)(DashNotificationPage);