import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { sendChatMessages, getChatMessagesMore } from '../../actions';
import Loading from './Loading';
import { IMG_URL } from '../../helpers/api';
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

const CourseBody = props => {
	const { t } = useTranslation();
	const { chat_message_list, chat_id, chat_message_send_status, profile } = props;
	const [body, setBody] = useState('');
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState('text');
	const [file, setFile] = useState(null);
	const [file_name, setFileName] = useState('');
	const data = chat_message_list.data ? chat_message_list.data : [];
	
	useEffect(() => {
		let chat = document.getElementById('chat__body_target');
		chat.scrollTop = chat.scrollHeight;
	}, []);
	useEffect(() => {
		if (chat_message_send_status.status === 'started') {
			setLoading(true);
		}
		if (chat_message_send_status.status === 'success') {
			setLoading(false);
			setBody('');
			setType('text');
			setFile(null);
			setFileName('');
			let chat = document.getElementById('chat__body_target');
			chat.scrollTop = chat.scrollHeight;
		}
		if (chat_message_send_status.status === 'error') {
			setLoading(false);
		}
	}, [chat_message_send_status, chat_message_list]);

	const sendMessage = event => {
		event.preventDefault();
		if (type === 'text' && body !== '') {
			setLoading(true);
			props.sendChatMessages(chat_id, { message: body, type: type }, 4===_.get(profile, 'role_id', -1)?_.get(profile, 'id', 0):0 );
		}
		if (type === 'file' && file !== null) {
			let formData = new FormData();
			formData.append('file', file);
			formData.append('type', type);
			props.sendChatMessages(chat_id, formData, 4===_.get(profile, 'role_id', -1)?_.get(profile, 'id', 0):0);
		}
	};
	const changeFile = event => {
		const images = event.target.files;
		if (images[0]) {
			setBody('');
			setType('file');
			setFile(images[0]);
			setFileName(images[0].name);
		} else {
			setBody('');
			setType('text');
			setFile(null);
			setFileName('');
		}
	};
	return (
		<>
			<div className="chat__body scroll-hidden"  id="chat__body_target"
				style={{ minHeight: 300, overflowY: 'scroll', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse', }}
			>
				<InfiniteScroll
					dataLength={data.length}
					next={()=>{props.getChatMessagesMore(chat_message_list.next_page_url?chat_message_list.next_page_url:'');}}
					style={{ display: 'flex', flexDirection: 'column-reverse' }} 
					inverse={true} 
					hasMore={chat_message_list.next_page_url?true:false}
					loader={<div style={{margin: '16px 0 0 16px'}}>{t('chat.loading')}</div>}
					scrollableTarget="chat__body_target"
				>
					{data.map((message, index) => {
						// const message = data[data_len-index]
						return (
							<>
								{ 
								(_.get(message, 'participation.messageable_id', 0)===1 && 4 === _.get(profile, 'role_id', -1) ) 
								||
								( _.get(message, 'participation.messageable_id', 0) === _.get(profile, 'id', -1) )
								 ? (
									<div className="chat__body-message chat__body-message--owner" key={index}>
										<div className="chat__body-message-body">
											<p style={{wordBreak: 'break-word'}}>
												{message.type === 'file' ? (
													<>
														<img
															src="/images/icons/empty.svg"
															alt="file"
															style={{ width: 50, marginRight: 10 }}
															onError={e => {
																e.target.onerror = null;
																e.target.src =
																	'/images/user/user.png';
															}}
														/>
														<a href={IMG_URL + message.body} target="blank">
															{t('chat.download')}
														</a>
													</>
												) : (
													message.body
												)}
											</p>
											<span>
												<Moment format="HH:mm DD.MM.YYYY">
													{_.get(message, 'created_at')}
												</Moment>
												<i
													className={
														message.read_at || 1 ? 'icon icon-double-check' : 'icon icon-check'
													}></i>
											</span>
										</div>
									</div>
								) : (
									<div className="chat__body-message" key={index}>
										<div className="chat__body-message-image">
											<img
												src={ IMG_URL + _.get(message, 'participation.user.avatar', '')}
												alt="Sendor"
												onError={e => {
													e.target.onerror = null;
													e.target.src =
														'/images/user/user.png';
												}}
											/>
										</div>
										<div className="chat__body-message-body">
											<strong style={{ color: '#7B1BF4' }}>
											{_.get(message, 'participation.user.firstname')} {_.get(message, 'participation.user.lastname')}
											</strong>
											<p  style={{wordBreak: 'break-word'}}>
												{message.type === 'file' ? (
													<>
														<img
															src="/images/icons/empty.svg"
															alt="file"
															style={{ width: 50, marginRight: 10 }}
															onError={e => {
																e.target.onerror = null;
																e.target.src =
																	'/images/user/user.png';
															}}
														/>
														<a href={IMG_URL + message.body} target="blank">
															{t('chat.download')}
														</a>
													</>
												) : (
													message.body
												)}
											</p>
											<span>
												<Moment format="HH:mm DD.MM.YYYY">
													{_.get(message, 'created_at')}
												</Moment>
											</span>
										</div>
									</div>
									
								)}
							</>
						);
					})}
				</InfiniteScroll>
			</div>
			<div>
				<form onSubmit={sendMessage} className="chat__footer">
					<div className="chat__footer-file">
						<div className="custom-file">
							<input
								type="file"
								name="file"
								className="form-control"
								id=""
								onChange={event => {
									changeFile(event);
									setType('file');
								}}
							/>
							<div></div>
						</div>
					</div>
					<div className="chat__footer-message">
						{type === 'text' ? (
							<input
								type="text"
								name="message"
								placeholder={t('chat.message')}
								value={body}
								onChange={e => {
									setBody(e.target.value);
									setType('text');
								}}
							/>
						) : (
							<>
								<input
									type="text"
									name="file_name"
									disabled={true}
									value={file_name}
									style={{ background: '#ccc' }}
								/>
								<button
									type="button"
									className="btn"
									onClick={() => {
										setBody('');
										setType('text');
										setFile(null);
										setFileName('');
									}}>
									<span className="icon icon-close"></span>
								</button>{' '}
							</>
						)}
					{/* <button type="button" className="btn">
                    	<span className="icon icon-smile"></span>
               		</button> */}
					</div>
					<div className="chat__footer-send">
						{loading ? (
							<div style={{ width: 46 }}>
								{' '}
								<Loading size={6} />{' '}
							</div>
						) : (
							<button type="submit" className="btn btn-primary" style={{width: 46, minWidth: 46}}>
								{/* <strong>{t('chat.send')}</strong> */}
								<span className="icon icon-send"></span>
							</button>
						)}
					</div>
				</form>
			</div>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		sendChatMessages: (chat_id, data, manager_id) => dispatch(sendChatMessages(chat_id, data, manager_id)),
		getChatMessagesMore: next => dispatch(getChatMessagesMore(next)),
	}),
)(CourseBody);
