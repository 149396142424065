import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Loading from '../Loading';

const Step2 = (props) => {
  const {
    disabled,
    FILE_URL,
    edu,
    submitTest3,
    filePdfShow,
    setFilePdfShow,
    file_namePlan,
    file_nameProgram,
    file_nameProvision,
    file_nameInfoRes,
    fileChangePlan,
    fileChangeProgram,
    fileChangeProvision,
    fileChangeInfoRes,
    setFileOrg,
    loading,
    setStep,
    step,
    setEdu,
    setErrorEdu,
    errorEdu,
  } = props;

  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest3}>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>O’quv-metodik ta’minoti</h6>

          {errorEdu.filePlan ||
          errorEdu.fileProgram ||
          errorEdu.fileProvision ||
          errorEdu.fileInfoRes ? (
            <div className='inputError'>{t('edu_institution.main_err')}</div>
          ) : null}
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ marginRight: '10px' }}>
                Ishchi o‘quv rejalar (Arxivlangan holatda - .zip, .rar, .7zip)
              </span>
              <a
                href={"/docs/Ishchi o'quv reja va dastur.rar"}
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye2')}
              </a>
            </label>
            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.filePlan ? { borderColor: '#f00' } : {}}
            >
              {file_namePlan && file_namePlan !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_namePlan}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileChangePlan(e);
                  setErrorEdu({ ...errorEdu, filePlan: false });
                }}
                accept='.zip, .rar, .7zip'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.plan ? (
              <a
                href={FILE_URL + edu.plan}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ marginRight: '10px' }}>
                Ishchi o‘quv dasturlar (Arxivlangan holatda - .zip, .rar, .7zip)
              </span>
              <a
                href={
                  "/docs/Барча_педагогик_тоифалар_uchun_namunaviy_o'quv_dasturi.pdf"
                }
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye2')}
              </a>
            </label>

            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.fileProgram ? { borderColor: '#f00' } : {}}
            >
              {file_nameProgram && file_nameProgram !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameProgram}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileChangeProgram(e);
                  setErrorEdu({ ...errorEdu, fileProgram: false });
                }}
                accept='.zip, .rar, .7zip'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.program ? (
              <a
                href={FILE_URL + edu.program}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>

        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Malaka oshirish
          kurslarining Ishchi o'quv reja va dasturlari ta'lim tashkiloti
          rahbarining <span style={{ fontWeight: 800 }}>imzosi</span> va
          <span style={{ fontWeight: 800 }}> muhri </span>
          bilan tasdiqlangan bo'lishi kerak.
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ marginRight: '10px' }}>
                O‘quv-uslubiy ta’minot (ma’ruza matnlari, amaliy mashg‘ulot
                ishlanmalari, taqdimotlari, nazorat topshiriqlari) (Arxivlangan
                holatda - .zip, .rar, .7zip)
              </span>
              <a
                href={'/public/docs/O‘quv-uslubiy ta’minot.zip'}
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye2')}
              </a>
            </label>

            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.fileProvision ? { borderColor: '#f00' } : {}}
            >
              {file_nameProvision && file_nameProvision !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameProvision}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileChangeProvision(e);
                  setErrorEdu({ ...errorEdu, fileProvision: false });
                }}
                accept='.zip, .rar, .7zip'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.provision ? (
              <a
                href={FILE_URL + edu.provision}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ marginRight: '10px' }}>
                Axborot resurs markazining mavjudligi (Mavjud/Mavjud emas “.pdf
                yuklash”)
              </span>
              <a
                href={'/docs/АРМ фонди маълумот.pdf'}
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye2')}
              </a>
            </label>

            <div
              className='setting__buttons mb-4'
              style={{ justifyContent: 'flex-start' }}
            >
              <label className='switch-text'>
                {disabled === true ? (
                  <div className='disabledSwitchDiv' />
                ) : null}
                <input
                  disabled={disabled}
                  type='checkbox'
                  checked={filePdfShow === 0 ? true : false}
                />
                <span className='slider'></span>
                <div
                  className='switch-labels'
                  onClick={() => {
                    setFilePdfShow(filePdfShow === 1 ? 0 : 1);
                  }}
                >
                  <span className='switch-label'>Mavjud</span>
                  <span className='switch-label'>Mavjud emas</span>
                </div>
              </label>
            </div>
            {filePdfShow === 1 ? (
              <>
                <label
                  className='edu_institution_input edu_institution_file mb-3'
                  style={errorEdu.fileInfoRes ? { borderColor: '#f00' } : {}}
                >
                  {file_nameInfoRes && file_nameInfoRes !== FILE_URL ? (
                    <b style={{ fontSize: 16 }}>{file_nameInfoRes}</b>
                  ) : (
                    <img
                      src='/images/user/upload.png'
                      alt='education'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/user/upload.png';
                      }}
                    />
                  )}
                  <input
                    type='file'
                    name='file'
                    alt='education file'
                    hidden
                    disabled={disabled}
                    onChange={(e) => {
                      fileChangeInfoRes(e);
                      setErrorEdu({ ...errorEdu, fileInfoRes: false });
                    }}
                    accept='.pdf'
                  />
                  <div>{t('edu_institution.file')}</div>
                </label>

                {edu.information_resource ? (
                  <a
                    href={FILE_URL + edu.information_resource}
                    className='btn btn-primary btn-sm mb-3'
                    target='_blank'
                  >
                    <span class='icon icon-eye'></span>{' '}
                    {t('edu_institution.eye')}
                  </a>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {disabled ? null : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <button
                className='btn btn-primary btn-sm'
                style={{ marginRight: '15px' }}
                onClick={() => {
                  setStep(step - 1);
                  window.scroll(0, 0);
                }}
              >
                Oldingi
              </button>
              <button className='btn btn-primary btn-sm' type='submit'>
                Keyingi
              </button>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default Step2;
