import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import { getCitiesList, getRegionList } from '../actions';
import { IMG_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import NavigationLocation from './sections/Navigation';
import Card from './sections/ProfileCourseCard';
import ResultPupilView from './sections/ResultPupilView';
import TotalStatisticsPupil from './sections/ManagerUserStatistics';
import CertificateModal from './sections/CertificateModal';
window.jQuery = $;

SwiperCore.use([Mousewheel, Pagination]);

const ManagerPupilProfile = props => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [diagnostics, setDiagnostics] = useState([]);
	const { getRegionList, getCitiesList, region_list, cities_list } = props;
	const [categories, setCategories] = useState([]);
	const [userHours, setUserHours] = useState([]);
	const [userPosition, setUserPosition] = useState([
		{
			id: '',
			name: '',
		},
	]);
	const [certificate, setCertificate] = useState({});
	const [courses, setCourses] = useState([]);
	const [certificates, setCertificates] = useState([]);
	const [userInfo, setUserInfo] = useState({
		lastname: '',
		firstname: '',
		name: '',
		avatar: '',
		identification_number: '',
		education: [
			{
				end_year: '',
				faculty: '',
				id: '',
				image: '',
				level: '',
				name: '',
				start_year: '',
				user_id: '',
			},
		],
		education_level: '',
		education_year: '',
		education_place: '',
		work_place: '',
		position_type: {
			name: '',
			id: '',
			type:{
				name: ''
			}
		},
		work_places: [
			{
				address: '',
				end_year: '',
				id: '',
				image: '',
				level: '',
				start_year: '',
				user_id: '',
			},
		],
		bio: '',
	});
	const [viewCertificate, setViewCertificate] = useState({
		image: '',
	});
	const {
		lastname,
		firstname,
		middlename,
		gender,
		birth_date,
		avatar,
		passport_number,
		region_id,
		city_id,
		position_id,
		work_place,
		education_level,
		education_year,
		education_place,
		identification_number,
		bio,
		name,
		education,
		work_places,
		position_type,
	} = userInfo;
	useEffect(() => {
		setLoading(false);
		getRegionList();
		const { id } = props.match.params;
		getInstance()
			.get('/api/v1/profil/get?user_id=' + id)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					setUserInfo(data);
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/profil/user-position')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setUserPosition(data);
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get(`/api/v1/courses?buyed=1&user_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					if (data) {
						setCourses(data);
					} else {
						setCourses([]);
					}
				} else {
					setCourses([]);
				}
			})
			.catch(error => setCourses([]));
		getInstance()
			.get(`/api/v1/courses/get-certificates?user_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					if (data) {
						setCertificates(data);
					}
				} else {
					setCertificates([]);
				}
			})
			.catch(error => setCertificates([]));
		getInstance()
			.get(`/api/v1/dashboard/user-hours?user_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					if (data) {
						setUserHours(data);
					}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get(`/api/v1/dashboard/categories?user_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response;
					if (data) {
						setCategories(data);
					}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get(`/api/v1/course/diagnostics/result?user_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					if (data) {
						setDiagnostics(data);
					}
				}
			})
			.catch(error => console.log(error));
	}, []);
	useEffect(() => {
		getCitiesList(region_id);
	}, [region_id]);
	return (
		<Layout>
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<NavigationLocation
										is_dash={true}
										is_btn={true}
										active={`${lastname} ${firstname} ${middlename}`}
										navs={[
											{
												link: '/manager/pupils',
												title: t('m_pupils.pupils'),
											},
										]}
									/>
									<div className="dashboard-title">
										<h5>{`${lastname} ${firstname} ${middlename}`}</h5>
									</div>
									{loading ? (
										<Loading />
									) : (
										<>
											<div className="card">
												<div className="card-body">
													<div className="row">
														<div className="p-3" id="card-first-info">
															<div
																style={{
																	display: 'flex',
																	width: '100%',
																}}>
																<img
																	style={{
																		height: 100,
																		width: 100,
																		objectFit: 'cover',
																		borderRadius: '50%',
																	}}
																	onError={e => {
																		e.target.onerror = null;
																		e.target.src = '/images/user/user.png';
																	}}
																	src={IMG_URL + avatar}
																	alt="online-edu"
																/>
																<div
																	style={{
																		marginLeft: 15,
																		display: 'flex',
																		justifyContent: 'space-evenly',
																		flexDirection: 'column',
																	}}>
																	<h3
																		style={{ fontSize: 20 }}
																		className="profile-first-last-name">
																		{`${lastname?lastname:''} ${firstname?firstname:''} ${middlename?middlename:''}`} 
																	</h3>
																	<h3
																		style={{
																			fontSize: 18,
																			color: '#898DA6',
																		}}>
																		<span
																			className="icon icon-briefcase"
																			style={{
																				marginRight: 8,
																			}}></span>
																		<span> {position_type?position_type.type.name:''}  ({position_type?position_type.name:''})</span>
																	</h3>
																</div>
															</div>
														</div>
														<div className="p-3 row">
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.phone')}
																</h4>
																<p style={{ fontSize: 14 }}>+{name}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.birth_date')}
																</h4>
																<p style={{ fontSize: 14 }}>{birth_date}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.gender')}
																</h4>
																<p style={{ fontSize: 14 }}>
																	{gender === 'man'
																		? t('profile.gender_m')
																		: t('profile.gender_w')}
																</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.passport_number')}
																</h4>
																<p style={{ fontSize: 14 }}>{passport_number}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.region')}
																</h4>
																<p style={{ fontSize: 14 }}>
																	{region_list.data ? region_list.data.map(item => (
																			<>
																				{item.id === region_id
																					? item.name
																					: null}
																			</>
																		))
																	: null}
																</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.city')}
																</h4>
																<p style={{ fontSize: 14 }}>
																	{cities_list.data ? cities_list.data.map((item, index) => (
																			<span key={index}>
																				{item.id === city_id
																					? item.name
																					: null}
																			</span>
																		))
																	: null}
																</p>
															</div>
															<div className="col-12 mt-3">
																<h4
																	style={{ fontSize: 20 }}
																	className="profile-data-title">
																	{t('profile.work')}
																</h4>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.work_place')}
																</h4>
																<p style={{ fontSize: 14 }}>{work_place}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.work_position')}
																</h4>
																<p style={{ fontSize: 14 }}>{position_type?position_type.name:''}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.identification_number')}
																</h4>
																<p style={{ fontSize: 14 }}>{identification_number}</p>
															</div>
															<div className="col-12  mt-3">
																<h4
																	style={{ fontSize: 20 }}
																	className="profile-data-title">
																	{t('profile.education')}
																</h4>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.education_place')}
																</h4>
																<p style={{ fontSize: 14 }}>{education_place}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.education_level')}
																</h4>
																<p style={{ fontSize: 14 }}>{education_level}</p>
															</div>
															<div className="col-md-4">
																<h4 className="profile-data-title">
																	{t('profile.education_year')}
																</h4>
																<p style={{ fontSize: 14 }}>{education_year}</p>
															</div>
															<div className="col-12  mt-3">
																<h4
																	style={{ fontSize: 20 }}
																	className="profile-data-title">
																	{t('profile-page.biography')}
																</h4>
															</div>
															<div className="col-12">
																<p style={{ fontSize: 14 }}>
																	{bio ? bio : t('profile.not-entered')}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="card">
												<div className="card-body">
													<div className="row p-3">
														<div
															style={{
																display: 'flex',
																width: '100%',
																justifyContent: 'space-between',
																alignItems: 'center',
																marginBottom: 20,
															}}>
															<h4
																style={{
																	fontSize: 18,
																	fontWeight: 700,
																	margin: 0,
																}}>
																{t('profile-page.education')}
															</h4>
														</div>
														<div style={{ width: '100%' }}>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																	width: '100%',
																}}>
																<div style={{ display: 'flex' }}>
																	<img
																		src="/images/user/education.png"
																		style={{
																			height: 64,
																			width: 64,
																			objectFit: 'cover',
																		}}
																		alt="online education"
																	/>
																	<div style={{ marginLeft: 15 }}>
																		<h4 style={{ fontSize: 17 }}>
																			{education_place}
																		</h4>
																		<p
																			style={{
																				fontSize: 15,
																				marginBottom: 0,
																			}}>
																			{education_level}
																		</p>
																		<p style={{ fontSize: 15 }}>{education_year}</p>
																	</div>
																</div>
															</div>
															{education.map((item, index) => {
																const {
																	name,
																	faculty,
																	end_year,
																	start_year,
																	image,
																} = item;
																return (
																	<div
																		key={index}
																		style={{
																			display: 'flex',
																			justifyContent: 'space-between',
																			alignItems: 'center',
																			width: '100%',
																		}}>
																		<div style={{ display: 'flex' }}>
																			<img
																				src={IMG_URL + image}
																				onError={e => {
																					e.target.onerror = null;
																					e.target.src = '/images/user/education.png';
																				}}
																				style={{
																					height: 64,
																					width: 64,
																					objectFit: 'cover',
																				}}
																				alt="online education"
																			/>
																			<div style={{ marginLeft: 15 }}>
																				<h4 style={{ fontSize: 17 }}>{name}</h4>
																				<p
																					style={{
																						fontSize: 15,
																						marginBottom: 0,
																					}}>
																					{faculty}
																				</p>
																				<p style={{ fontSize: 15 }}>
																					<span>
																						{start_year
																							? start_year
																									.toString()
																									.replace(/-/g, '.')
																							: null}
																						{' - '}
																					</span>
																					<span>
																						{end_year
																							? end_year
																									.toString()
																									.replace(/-/g, '.')
																							: null}
																					</span>
																				</p>
																			</div>
																		</div>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>
											<div className="card">
												<div className="card-body">
													<div className="row p-3">
														<div
															style={{
																display: 'flex',
																width: '100%',
																justifyContent: 'space-between',
																alignItems: 'center',
																marginBottom: 20,
															}}>
															<h4
																style={{
																	fontSize: 18,
																	fontWeight: 700,
																	margin: 0,
																}}>
																{t('profile-page.work-place')}
															</h4>
														</div>
														<div style={{ width: '100%' }}>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'space-between',
																	marginBottom: 20,
																}}>
																<div style={{ display: 'flex' }}>
																	<img
																		src="/images/user/school.png"
																		style={{
																			height: 64,
																			width: 64,
																			objectFit: 'cover',
																		}}
																		alt="online school"
																	/>
																	<div style={{ marginLeft: 15 }}>
																		<h4 style={{ fontSize: 17 }}>{work_place}</h4>
																		<p
																			style={{
																				fontSize: 15,
																				marginBottom: 0,
																			}}>
																			{position_type ? position_type.name : ''}
																		</p>
																	</div>
																</div>
															</div>
															{work_places.map((item, index) => {
																const {
																	address,
																	end_year,
																	start_year,
																	level,
																	image,
																} = item;
																return (
																	<div
																		key={index}
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'space-between',
																			width: '100%',
																			marginBottom: 20,
																		}}>
																		<div style={{ display: 'flex' }}>
																			<img
																				src={IMG_URL + image}
																				onError={e => {
																					e.target.onerror = null;
																					e.target.src =
																						'/images/user/school.png';
																				}}
																				style={{
																					height: 64,
																					width: 64,
																					objectFit: 'cover',
																				}}
																				alt="edu online"
																			/>
																			<div style={{ marginLeft: 15 }}>
																				<h4 style={{ fontSize: 17 }}>
																					{address}
																				</h4>
																				<p
																					style={{
																						fontSize: 15,
																						marginBottom: 0,
																					}}>
																					{userPosition.map(item => {
																						if (item.id == level) {
																							return item.name;
																						}
																					})}
																				</p>
																				<p style={{ fontSize: 15 }}>
																					<span>
																						{start_year
																							? start_year
																									.toString()
																									.replace(/-/g, '.')
																							: null}
																						{' - '}
																					</span>
																					<span>
																						{end_year
																							? end_year
																									.toString()
																									.replace(/-/g, '.')
																							: null}
																					</span>
																				</p>
																			</div>
																		</div>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>
											<TotalStatisticsPupil statistic_user={userHours} statistic={categories} />
											{diagnostics && diagnostics.length > 0 ? (
												<>
													<ResultPupilView data={diagnostics} />
												</>
											) : null}
											<div className="card p-5">
												<div
													style={{
														display: 'flex',
														width: '100%',
														marginBottom: 20,
													}}>
													<h4
														style={{
															fontSize: 18,
															fontWeight: 700,
														}}>
														{t('profile-page.courses')}
													</h4>
												</div>
												<div style={{ width: '100%' }}>
													{courses.length ? (
														<Swiper
															spaceBetween={0}
															scrollbar={{
																draggable: true,
																mousewheel: false,
															}}
															slidesPerView={'auto'}
															pagination={{
																dynamicBullets: true,
																clickable: true,
															}}
															direction={'horizontal'}
															mousewheel={false}
															style={{ marginBottom: 10, height: 320 }}>
															{courses.map((item, index) => {
																return (
																	<SwiperSlide style={{ width: 260 }} key={index}>
																		<Card link={true} item={item} user_id={props.match.params.id} />
																	</SwiperSlide>
																);
															})}
														</Swiper>
													) : (
														<div
															style={{
																display: 'flex',
																justifyContent: 'center',
																width: '100%',
															}}>
															<p>{t('profile-page.not-courses')}</p>
														</div>
													)}
												</div>
											</div>
											<div className="card">
												<div className="card-body">
													<div className="row p-3">
														<div
															style={{
																display: 'flex',
																width: '100%',
															}}>
															<h4
																style={{
																	fontSize: 18,
																	fontWeight: 700,
																	marginBottom: 20,
																	paddingBottom: 0,
																}}>
																{t('profile-page.certificates')}
															</h4>
														</div>
														<div style={{ width: '100%' }}>
															{certificates.length ? (
																<Swiper
																	spaceBetween={0}
																	scrollbar={{
																		draggable: true,
																		mousewheel: false,
																	}}
																	slidesPerView={'auto'}
																	pagination={{
																		dynamicBullets: true,
																		clickable: true,
																	}}
																	direction={'horizontal'}
																	mousewheel={false}
																	style={{ marginBottom: '20px' }}>
																	{certificates.map((item, index) => {
																		return (
																			<SwiperSlide
																				style={{
																					width: 266,
																					height: 375,
																					display: 'flex',
																					justifyContent: 'center',
																				}}
																				key={index}>
																				<div
																					onClick={() =>{
																							setCertificate(item);
																						}
																					}
																					data-toggle="modal"
																					data-target="#modalCertificate_1"
																					style={{
																						margin: 20,
																						boxShadow:
																							'0px 8px 24px rgba(13, 46, 105, 0.1)',
																					}}>
																					
																					<img
																						alt="onlinedu_certificate"
																						src={
																							IMG_URL + item.image
																						}
																						onError={e => {
																							e.target.onerror = null;
																							e.target.src = '/images/no-certificate.png';
																						}}
																						style={{
																							width: 236,
																							height: 334,
																							objectFit: 'cover',
																						}}
																					/>
																				</div>
																			</SwiperSlide>
																		);
																	})}
																</Swiper>
															) : (
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		width: '100%',
																	}}>
																	<p>{t('profile-page.not-certificates')}</p>
																</div>
															)}
														</div>
														<CertificateModal  certificate={ certificate }/>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
				<style jsx>{`
					.profile-first-last-name {
						font-size: 36px;
						font-weight: 500;
					}
					.swiper-scrollbar {
						background: transparent;
					}
					#card-first-info {
						display: flex;
						width: 100%;
						justify-content: center;
					}
					#profile-edit-link {
						height: 40px;
						font-weight: 500;
					}
					.swiper-pagination-bullet {
						width: 16px;
						height: 16px;
					}
					.swiper-pagination-bullet-active-main {
						background: #26caac;
					}
					.swiper-button-prev,
					.swiper-button-next {
						transform: scale(0.7);
					}
					@media screen and (max-width: 1200px) {
						#card-first-info {
							flex-direction: column;
						}
						#profile-edit-link {
							margin-top: 15px;
						}
					}
					@media screen and (max-width: 768px) {
						.profile-first-last-name {
							font-size: 24px;
						}
					}
				`}</style>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		cities_list: state.cities_list,
		region_list: state.region_list,
	}),
	dispatch => ({
		getRegionList: () => dispatch(getRegionList()),
		getCitiesList: id => dispatch(getCitiesList(id)),
	}),
)(ManagerPupilProfile);
