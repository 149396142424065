import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, withHighcharts } from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const Matrix = (props) => {
    const { t } = useTranslation();
    const {row, cols, result, lan} = props;
    let total_user = 0;
    cols.forEach(col => {
        const r = _.get( result, row.id, {} );
        total_user += _.get( r, col.id, 0 );
    });
    let d = [];
    cols.forEach(col => {
        const r = _.get( result, row.id, {} );
        const c = _.get( r, col.id, 0 );
        d = [...d, [ _.get(col, 'col_'+lan, ''), c/total_user*100 ] ];
    });

    const chart = {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: '#F7F7F7',
        borderRadius: 7
    }
    const title = {
        text: _.get(row, 'row_'+lan, ''),
        align: 'center',
        verticalAlign: 'middle',
        y: 130
    }
    const tooltip = {
        pointFormat: '{series.name} <b>{point.percentage:.1f}%</b>'
    }
    const accessibility = {
        point: {
            valueSuffix: '%'
        }
    }
    const plotOptions = {
        pie: {
            dataLabels: {
                enabled: true,
                distance: -50,
                pointFormat: '<b>{point.percentage:.1f}%</b>',
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%'
        },
        series: {
            states: {
                hover: {
                    enabled: false
                },
                inactive: {
                    opacity: 1
                }
            }
        }

    }
    const series = [{
        type: 'pie',
        name: '',
        innerSize: '50%',
        data: d
    }]
return (
      <div style={{width: '100%'}}>
        <HighchartsChart  
          chart={chart}
          series={ series}
          title={title}
          tooltip={tooltip}
          accessibility={accessibility}
          plotOptions = {plotOptions}
        >
        </HighchartsChart>
      </div>
    );
}
export default withHighcharts(Matrix, Highcharts);
