import React from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";


function DashPaymentrPage() {
  return (<Layout>
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                            />
                             <div className="dashboard-title">
                            <h5>Оплата</h5>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="mb-3">Выберите категорию для переквалификации:</h6>
                                        <div className="form-group mb-6">
                                            <label for="">Категория</label>
                                            <select id="" className="form-control custom-select">
                                                <option>Маркетинг</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <h6 className="mb-3">Выберите способ оплаты:</h6>
                                        <div className="requalification__payment">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="requalification__custom-radio">
                                                        <input type="radio" name="payment" checked/>
                                                        <div className="requalification__payment-type">
                                                            <img src="/images/payment-systems/click.png" alt="Click"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="requalification__custom-radio">
                                                        <input type="radio" name="payment"/>
                                                        <div className="requalification__payment-type">
                                                            <img src="/images/payment-systems/payme.png" alt="Payme"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card mb-5">
                                    <div className="card-body">
                                        <h3 className="mb-4">Итого:</h3>
                                        <ul className="requalification__payment-details">
                                            <li>
                                                <div>Категория</div>
                                                <div>Маркетинг</div>
                                            </li>
                                            <li>
                                                <div>Количество часов</div>
                                                <div>500 часов</div>
                                            </li>
                                            <li>
                                                <div>Количество курсов</div>
                                                <div>8</div>
                                            </li>
                                            <li>
                                                <div>Дата завершения</div>
                                                <div>12.10.2021</div>
                                            </li>
                                            <li>
                                                <div>Итого к оплате:</div>
                                                <div>154 500 сум</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary float-right">Оплатить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

  </Layout>);
}
export default DashPaymentrPage;