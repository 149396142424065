import React, { useState } from 'react';
import ChartSpider from './ChartSpider';
import ChartColumnH from './ChartColumnH';
import { useTranslation } from 'react-i18next';

const ResultPupil = props => {
	const { t } = useTranslation();
	const { data, exercise } = props;
	const [chart, setChart] = useState(0);
	return (
		<div class="card">
			<div class="card-body">
				<div className="row">
					<div className="col-12 col-md-5 d-flex " style={{ alignItems: 'center' }}>
						<div>
							{data.map((cat, index) => {
								const user_score = parseInt(cat.category.user_score ? cat.category.user_score : 0);
								let user_percent = parseInt((user_score / cat.category.total_score) * 100);
								user_percent = user_percent > 100 ? 100 : user_percent;
								return (
									<div className="result_pupil_item" key={index}>
										{index + 1}. {cat.name}{' '}
										<span style={{ color: 'red' }}>
											{cat.is_diagnostic ? cat.percent + '%' : t('profile-page.test_not_solved')}
										</span> / <span style={{ color: '#26CAAC' }}>{user_percent ? user_percent : 0}%</span>
									</div>
								);
							})}
						</div>
					</div>
					<div className="col-12 col-md-7">
						<div>
							<div className="setting__buttons">
								<label className="switch-text">
									<input type="checkbox" checked={chart === 0 ? false : true} />
									<span className="slider"></span>
									<div className="switch-labels">
										<span className="switch-label" onClick={() => setChart(0)}>
											<img style={{ width: 20 }} src="/images/spider.svg" alt="spider" />
										</span>
										<span className="switch-label" onClick={() => setChart(1)}>
											<img style={{ width: 20 }} src="/images/bar.svg" alt="spider" />
										</span>
									</div>
								</label>
							</div>
						</div>
						<div className="d-flex" style={{ alignItems: 'center' }}>
							{chart ? <ChartColumnH data={data} exercise={exercise} /> : <ChartSpider data={data} exercise={exercise} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ResultPupil;
