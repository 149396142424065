import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Loading from "./Loading";
import { connect } from 'react-redux';
import { updateLessonAnswerDragWords } from '../../actions';
import _ from 'lodash';

const TestAnswerDragWords = props =>{
	const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [answers, setAnswers] = useState('');
    const [answer_e, setAnswerE] = useState('');
    const [score, setScore] = useState('0');
    const [score_e, setScoreE] = useState('');
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [detail, setDetail] = useState('');
    const [detailE, setDetailE] = useState('');
    const [drag_e, setDrag_e] = useState('');
    const {updateLessonAnswerDragWords, update_lesson_answer_status, question_id, question} = props;
    
    useEffect(()=>{
        let d = [], s=_.get(question, 'details', '');
        _.get(question, 'answers', []).forEach(item=>{
            if(_.get(item, 'details', '') === 'wrong') d.push(_.get(item, 'answer', ''));
            if(_.get(item, 'details', '').substring(0, 4) === 'word') 
                s = s.replace("["+_.get(item, 'details', '')+"]", 
                    '<span class="___Highlighter_drag">' +_.get(item, 'answer', '') +'</span>');
        });
        setDetails(d);
        setScore(_.get(question, 'score', '0'));
        setAnswers(s);
        if(question_id && edit) document.getElementById("___Highlighter_target_"+question_id).innerHTML = s;
    },[question, edit]);
    useEffect(()=>{
        if(update_lesson_answer_status.status === 'success'){ setLoading(false); setEdit(false); }
        if(update_lesson_answer_status.status === 'error') { setLoading(false);  } 
    },[update_lesson_answer_status]);
    useEffect(()=>{
        if(question_id && edit) document.getElementById("___Highlighter_target_"+question_id).innerHTML = answers;
    }, [edit]);

    const addDetail = v => {
        setDetailE("");
        if(v.trim()){
            let is_has = true;
            details.forEach( item=>{
                if(item.trim()===v.trim()) is_has = false;
            });
            if(is_has){
                setDetails([...details, v]);
                setDetail('');
            }else {
                setDetailE( t('diagnostics.detail') );
            }
        }
    }
    const delDetail = v => {
        if(v){
            let dl = [];
            details.forEach((d)=>{
                if(d!==v) dl.push(d);
            });
            setDetails(dl);
        }
    }
    const onKeyDownHandle = (e) =>{
        if(e.keyCode===13){
            addDetail(e.target.value);
        }
        if(e.keyCode===8 && !e.target.value){
            let dl = [];
            details.forEach((d, index)=>{
                if(index!==details.length-1) dl.push(d);
                setDetail('');
            });
            setDetails(dl);
        }
    }
    const saveAnswer = () => {
        setLoading(true);
        const target = document.getElementById("___Highlighter_target_"+question_id);
        let question_text = '', answers_d = [], i=1, is_check = true;
        target.childNodes.forEach( (element) => {
            if(element.localName==="span"&&element.className === '___Highlighter_drag'){
                question_text += '[word'+i+']';
                answers_d.push({ tag: "word"+i, answer: element.textContent.trim() });
                i++;
            }else question_text += element.textContent;
        });

        if(!answers_d.length){
            setAnswerE( t('diagnostics.mark_e') );
            is_check = false;
        }
        if(!score){
            setScoreE( t('diagnostics.score_e') );
            is_check = false;
        }
        if(is_check){
            updateLessonAnswerDragWords({
                question_id: question_id,
                question_text: question_text,
                answers: JSON.stringify(answers_d),
                score: score,
                wrongwords: details.length?details.toString():''
            })
        }else{
            setLoading(false);
        }
    }
    const markDrag = id => {
        if (window.getSelection) {
            const selection = window.getSelection().getRangeAt(0);
            const start =  selection.startOffset, end = selection.endOffset;
            if( selection.startContainer.parentElement.id === "___Highlighter_target_"+id 
                && selection.endContainer.parentElement.id  === "___Highlighter_target_"+id 
                && start !== end && selection.commonAncestorContainer.childNodes.length===0 ){
                    const target = document.getElementById("___Highlighter_target_"+id);
                    let html = '';
                    target.childNodes.forEach( (element) => {
                        if(element===selection.commonAncestorContainer){
                            html += element.textContent.substring(0,start)
                                +'<span class="___Highlighter_drag">'
                                +(element.textContent.substring(start, end).trim()?element.textContent.substring(start, end).trim():'\&nbsp;')
                                +'</span>\&nbsp;'
                                +element.textContent.substring(end, element.textContent.length);
                        }else html += element.outerHTML?element.outerHTML:element.textContent;
                    });
                    setAnswers(html);
                    target.innerHTML = html;
            }else{ setDrag_e(t('diagnostics.drag_e')); }
        }
    }
    const removeDrag = id => {
        const target = document.getElementById("___Highlighter_target_"+id);
        let html = '';
        target.childNodes.forEach( (element) => {
            html += element.textContent;
        });
        target.innerHTML = html;
    }
    const onInputHandle = e => {
        setDrag_e(''); 
        setAnswerE('');
        const target =  e.target;
        let html = '', is_enter = false;
        target.childNodes.forEach( (element) => {
            if(element.tagName === "DIV") is_enter = true;
        });
        if( is_enter ) {
            target.childNodes.forEach( (element) => {
                if(element.tagName === "SPAN"){
                    html += element.outerHTML
                }else{
                    if(element.tagName === "DIV"){
                        html += '\n' + element.innerHTML
                    }else{
                        html += element.textContent
                    }
                }
            } );
            target.innerHTML = html;
        }
    }
    

    useEffect(()=>{
        const sss = document.querySelector("#___Highlighter_target_"+question_id);
        if( sss ){
            sss.addEventListener("paste", function(e) {
                e.preventDefault();
                // var text = e.clipboardData.getData("text/plain");
                // document.execCommand("insertHTML", false, text);

                const text = (e.clipboardData)
                    ? (e.originalEvent || e).clipboardData.getData('text/plain')
                    // For IE
                    : (window.clipboardData ? window.clipboardData.getData('Text') : '');
                
                if (document.queryCommandSupported('insertText')) {
                    document.execCommand('insertText', false, text);
                } else {
                    // Insert text at the current position of caret
                    const range = document.getSelection().getRangeAt(0);
                    range.deleteContents();

                    const textNode = document.createTextNode(text);
                    range.insertNode(textNode);
                    range.selectNodeContents(textNode);
                    range.collapse(false);

                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            });
            sss.addEventListener('keypress', (e) => {
                if (e.which === 13) {
                    e.preventDefault();
                    document.execCommand("insertHTML", false, '\n ');
                }
            });
        }
    }, [edit]);

    return(<>
        <div className="card card--shadow ml-5 mt-1 mb-0">
            <div className="card-body card-body-diagnostics">
                <div className="row">
                    <div className="col-12 dig-title">
                        <div style={{fontSize: 14}}>{ t('diagnostics.question') }</div>
                        {edit?
                            <span className="icon icon-close" onClick={()=>setEdit(false)}></span>
                        :
                            <span className="icon icon-edit dig-answer-edit" onClick={()=>setEdit(true)}></span>
                        }
                    </div>
                    {edit?<>
                        <div className="col-12 mb-1">
                            <label style={{margin: 0}}> { t('diagnostics.g_details') }</label>
                            <div> 
                                <span className="btn btn-lighter mb-1 mr-1 btn-sm"
                                    onClick={()=> markDrag( question_id )}
                                >{ t('diagnostics.mark') } </span>
                                <span className="btn btn-lighter mb-1 btn-sm"
                                    onClick={()=>removeDrag( question_id )}
                                >{ t('diagnostics.delete') }</span>
                            </div>
                            <div style={{color: 'red', fontSize: 12}}>{drag_e}</div>
                            <div id={"___Highlighter_target_"+question_id} 
                                className="form-group mb-1 answer_grouping ___Highlighter_target" 
                                contentEditable={true} 
                                onInput={e=>{onInputHandle(e)}}
                                onClick={()=>{setDrag_e(''); setAnswerE('');}}
                            >
                            </div> 
                            <div style={{color: 'red'}}>{answer_e}</div>
                        </div>
                        <div  className="col-12">
                            <label style={{margin: 0}}>{ t('diagnostics.wrong') }</label>
                            <label style={{margin: 0}}>
                            <div  className="form-group mb-1 answer_grouping">
                                {details.map((item, index_item)=>(<>
                                    {item!==''?
                                    <span className="a_g_item" key={index_item}>
                                        {item}
                                        <span className="icon icon-close a_g_item_close" onClick={()=>delDetail(item)}></span>
                                    </span> :''}
                                </>))}
                                <input type="text" 
                                    className='a_g_input'
                                    size={detail.length?detail.length+1:2}
                                    value={detail?detail:''}
                                    onChange={e=>{ setDetail( e.target.value ); setDetailE("");} }
                                    onBlur={e=>{
                                        addDetail(e.target.value)
                                    }}
                                    onKeyDown={e=>{ onKeyDownHandle( e) } }
                                />
                            </div>
                            <div style={{color: 'red'}}>{detailE}</div>
                            </label>
                        </div>
                        <div className="col-6">
                            <div className="form-group mb-1">
                                <label for=""> { t('diagnostics.score') } 
                                    <input type="number" className="form-control"  style={{width: 150}} 
                                        value={score}
                                        onChange={(event)=>{
                                            setScore( event.target.value);
                                            setScoreE('');
                                        }}
                                    />
                                    <div style={{color: 'red'}}>{score_e}</div>
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            {loading?<Loading/>:
                            <b className="btn btn-primary btn-sm"  
                                onClick={()=>saveAnswer()} 
                                style={{float: 'right', margin: 10}}>
                                { t('diagnostics.save') }
                            </b>}
                        </div>
                     </>: <>
                        <div className="col-12 dig-title " style={{margin: '5px 0'}}>
                            <div className="test-items-target"  style={{width: '100%'}}>
                                <div dangerouslySetInnerHTML={{ __html: answers }}
                                className="form-group mb-1  ___Highlighter_target show_highlighter">
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{fontSize: 14}}>{ t('diagnostics.wrong') }</div>
                        <div className="col-12 dig-title " style={{margin: '5px 0'}}>
                            <div className="test-items-target"  style={{width: '100%', minHeight: 25}}>
                                <div>
                                    {details.map((item, index_item)=>(<>
                                        {item!==''?<span className="a_g_item" key={index_item}>{item}</span>:''}
                                    </>))}
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    </>)
}
export default connect(
	state => ({
		update_lesson_answer_status: state.update_lesson_answer_status,
	}),
	dispatch => ({
		updateLessonAnswerDragWords: (data) => dispatch(updateLessonAnswerDragWords(data)),
	}),
)(TestAnswerDragWords);


