import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const SorttableLogic = props => {
	const { t } = useTranslation();
	const { survey, logic_item, logic_survey, setLogicList } = props;
	const changeLogic = (id, name, value) => {
		let l = [];
		survey.logic.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, choice: { [name]: value } });
			} else {
				l.push(item);
			}
		});
		setLogicList(l);
	};
	const changeLogicIN = (id, name, value) => {
		let l = [];
		survey.logic.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, choice: { ...item.choice, [name]: value } });
			} else {
				l.push(item);
			}
		});
		setLogicList(l);
	};
	return (
		<>
			<label for="">{t('survey_c.select-answer')}</label>
			<select
				className="form-control"
				style={{
					background: 'white',
					width: '100%',
				}}
				name="inputType"
				value={_.get(logic_item, 'choice.item_id', 0)}
				onChange={e => {
					changeLogic(logic_item.id, 'item_id', parseInt(e.target.value));
				}}>
				{logic_survey.choices
					? logic_survey.choices.map((item, index) => (
							<option value="text" key={index} value={item.id}>
								{index + 1}-{t('survey_c.item')} | {item.label_uz} | {item.label_ru}
							</option>
					  ))
					: null}
			</select>
			{_.get(logic_item, 'choice.item_id', 0) ? (
				<>
					<label for=""> {t('survey_c.choice_sort')} </label>
					<select
						className="form-control custom-select"
						name="inputType"
						value={_.get(logic_item, 'choice.sort_id', 0)}
						onChange={e => {
							changeLogicIN(logic_item.id, 'sort_id', parseInt(e.target.value));
						}}>
						{(() => {
							let l = [];
							for (let y = 1; y <= _.get(logic_survey, 'choices', []).length; y++) {
								l.push(
									<option value="text" key={y} value={y}>
										{t('survey_c.sort')} - {y}
									</option>,
								);
							}
							return l;
						})()}
					</select>
				</>
			) : null}
		</>
	);
};
export default SorttableLogic;
