import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CourseBtns = (props) => {
    const {course, slug, dash} = props;
    const { t } = useTranslation();

    return(
        <ul>
            <li>
                <Link
                    to={'/dashboard/courses/view/' + slug}
                    className="btn popout-button">
                    <span className="icon icon-books"></span>
                    <strong>{t('course_view.course')}</strong>
                </Link>
            </li>
            {dash?
            <li>
                <a className="btn popout-button right-sidebar-menu-btn">
                    <span className="icon icon-list-dots"></span>
                    <strong>{t('course_view.section')}</strong>
                </a>
            </li>:''}
            { _.get(course, 'data.active_buys_count', false)?<>
            <li>
                {/* <Link to={"/dashboard/courses/view/"+slug+"/chat"} className="btn popout-button"> */}
                <Link
                    to={'/dashboard/chat/' + _.get(course, 'data.chat_id', 0)}
                    className="btn popout-button">
                    <span className="icon icon-chat"></span>
                    <strong>{t('course_view.chat')}</strong>
                </Link>
            </li>
            <li>
                <Link to={"/dashboard/courses/review/"+slug} className="btn popout-button">
                    <span className="icon icon-star"></span>
                    <strong>{t('course_view.review')}</strong>
                </Link>
            </li></>:''}
        </ul>
    )
}
export default CourseBtns;