import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, withHighcharts } from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import _ from 'lodash';
ChartModuleMore(Highcharts);

const RadioOrCheckbox = (props) => {
    const { t } = useTranslation();
    const {survey, result, lan} = props;
    let points = [];
    let total_user = 0;
    survey.choices.forEach(item => {
        total_user += parseInt( _.get(result, item.id, 0 ) );
    });
    total_user += parseInt( _.get(result, 'no_result', 0 ) );
    survey.choices.forEach(item => {
        const p = parseInt(_.get(result, item.id, 0 )?_.get(result, item.id, 0 ):0)/total_user*100;
        if(p>0){
            points.push({ name: _.get(item, 'label_'+lan), y: p, selected: true,  });
        }
    });
    const no_result = parseInt(_.get(result, 'no_result', 0 )?_.get(result, 'no_result', 0 ):0)/total_user*100;
    if(no_result>0){
        points.push({ name: t('survey_r.not_specified'), y: no_result, selected: true,  });
    }

    const chart = {
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: '#F7F7F7',
        borderRadius: 7
     }
    const tooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}"><b>{point.percentage:.1f}%</b><br/>'
    }
     const plotOptions = {
        pie: {
           shadow: false,
           center: ['50%', '50%'],
           size:'100%',
           innerSize: '50%' ,
           dataLabels: {
                enabled: true,
                distance: -50,
                pointFormat: '<b>{point.percentage:.1f}%</b>',
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            }          
        }
     }
     const series = [{
        type: 'pie',
        name: "O'qilgan",
        data: points
     }]

return (
      <div style={{width: '100%'}}>
      
        <HighchartsChart  
          chart={chart}
          series={ series}
          plotOptions = {plotOptions}
          tooltip={ tooltip}
        >
        </HighchartsChart>
            
      </div>
    );
  
}

export default withHighcharts(RadioOrCheckbox, Highcharts);