import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import WebinarCard from './sections/WebinarCard';
import Pagination from './sections/Pagination';
import { getMyWebinars } from "../actions";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const DashWebinarsPage = (props) => {
    const [status, setStatus] = useState(1);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const convertLink = () => {
        let str = '?';
        if(status){
            str +='status='+status+'&';
        }
        return str;
    }
    useEffect(()=>{
        const search = props.location.search; 
        const params = new URLSearchParams(search);
        const status_p = params.get('status');
        setStatus(status_p);
        let str = '&';
        if(status_p){
            str +='status='+status_p+'&';
        }
        props.getMyWebinars( props.match.params.page?props.match.params.page:1, str );
    },[props.match.params.page, props.location.search]);
    useEffect(()=>{
        if(props.my_webinars_status.status === 'started'){
            setLoading(true)
        }
        if(props.my_webinars_status.status === 'success'){
            setLoading(false)
        }
        if(props.my_webinars_status.status === 'error'){
            setLoading(false)
        }
    },[props.my_webinars_status]);
    const {my_webinars, } = props;
    
  return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('my_webinar.title')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    }
                                ]}
                            />
                           <div className="dashboard-title">
                                <h5>
                                    {t('my_webinar.title')}
                                </h5>
                            </div>
                            {loading?
                                <Loading/>
                            :<>
                                {my_webinars.data&&my_webinars.data.length>0?<>
                                <div className="row courses-dashboard"> 
                                    {my_webinars.data.map((webinar,index)=>(
                                        <WebinarCard webinar={webinar} key={index}/> 
                                    ))} 
                                </div>
                                <Pagination
                                    total={my_webinars.total?my_webinars.total:6}
                                    active={ parseInt(props.match.params.page) }
                                    link="/dashboard/webinars/list/"
                                    search_params={convertLink()}
                                />
                                </>
                                :
                                    <NoData
                                        text={t('my_webinar.no_data')}
                                    />
                                }
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getMyWebinars: (page, search) => dispatch(getMyWebinars(page, search)),
    })
)(DashWebinarsPage);