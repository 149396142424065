import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { issetToken } from '../../helpers/tokenStorge';

const Footer = props => {
	const { teacher_category } = props;
	const { t } = useTranslation();
	return (
		<>
			<footer className='footer'>
				{/* <img
					src='/images/vazirlik.png'
					className='tasix shadow bg-white rounded'
					alt='onlinedu'
				/> */}
				<div className='footer__top'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-3 footer__column'>
								<div className='footer__logo'>
									<Link to='/'>
										<img
											src='/images/assets/logo.png'
											alt='OnlineEducation'
											style={{ width: '100%' }}
										/>
									</Link>
								</div>
								{/* <br/>
								<div className="footer__logo">
									<Link to="/">
										<img src="/images/vazirlik.png" alt="Logo" style={{ width: '100%' }} alt="onlinedu" />
									</Link>
								</div> */}
							</div> 
							{issetToken()?
							<div className='col-md-4 col-lg-3 footer__column'>
								<strong>{t('footer.profile')}</strong>
								<ul>
									<li>
										<Link to={'/dashboard/profile'}>
											{t('footer.footer_link_5')}
										</Link>
									</li>
									<li>
										<Link to={'/dashboard/settings'}>
											{t('dash_side_bar.setting')}
										</Link>
									</li>
									<li>
										<Link to={'/dashboard/notification'}>
											{t('notifications.title')}
										</Link>
									</li>
								</ul>
							</div>:<div className='col-md-4 col-lg-3 footer__column'></div>}
							<div className='col-md-8 col-lg-6 footer__column'>
								<strong>{t('footer.support')}</strong>
								<ul>
									<li>
										<Link to={'/'}>{t('footer.footer_link_12')}</Link>
									</li>
									<li>
										<Link to={'/'}>{t('footer.footer_link_9')}</Link>
									</li>
									<li>
										<Link to={'/'}>{t('footer.footer_link_10')}</Link>
										{/* <a
											target='_blank'
											rel='noreferrer'
											href={'https://t.me/+dcbeoS6DQvhmOTU6'}
										>
											{t('footer.footer_link_10')}
										</a> */}
									</li> 
									<li>
										<Link to={'/faq/1'}>
											{t('footer.footer_link_11')}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='footer__bottom'>
					<div className='container'>
						<div className='copyright'>
							<p>
								{t('footer.copyright')}{' '}
								<span
									// data-toggle='modal'
									// data-target='#modalCertificate_site'
									style={{ color: '#26CAAC', cursor: 'pointer' }}
								>
									{t('footer.copyright_text')}
								</span>{' '}
							</p>
						</div>
					</div>
				</div>
			</footer>
			<div
				className='modal certificate__modal fade'
				id='modalCertificate_site'
				tabindex='-1'
				role='dialog'
				aria-labelledby='modalCertificate_siteTitle'
				aria-hidden='true'
			>
				<button
					type='button'
					className='close'
					data-dismiss='modal'
					aria-label='Close'
				>
					<span className='icon icon-close'></span>
				</button>
				<div
					className='modal-dialog modal-xl modal-dialog-centered'
					role='document'
				>
					<div className='modal-content'>
						<img src='/images/patent.jpg' alt='Online Edu Certificate' />
					</div>
				</div>
			</div>
		</>
	);
};
export default connect(state => ({
	teacher_category: state.teacher_category,
}))(Footer);
