import React, {useEffect} from 'react';
import JoditTextShow from "./JoditTextShow";
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import  "jquery-ui-touch-punch";
import _ from 'lodash';

window.jQuery = $;

const TestResultWordDrags = (props) => {
    const { question, number, answers_user } = props;

    const get_user_ansver = item => {
        const answer_id = parseInt( _.get(answers_user, item, '0') );
        let a = '', is_chack = true;
        
        _.get(question, 'vanswers', []).forEach(element => {
            if(element.id===answer_id){a = element.answer; is_chack =  element.details === 'wrong'? false: true }
        });
        return (
            <div className="test-drag-drop drop container_word1  ui-droppable" 
                style={is_chack?{borderColor: '#26CAAC', color: '#26CAAC'}:{borderColor: 'red', color: 'red'}}>
                <div className="propval">
                    {a}
                </div>
            </div>
        )
    }

    const formatDrag = () => {
        let word = 1,  w_list = [], details = _.get(question, 'details', ''), is_has = true;
        while(is_has){
            const i = details.indexOf("[word"+word+"]");
            if(i>=0){
                w_list.push( details.slice(0, i) );
                w_list.push( "word"+word );
                details = details.slice( i+("[word"+word+"]").length );
                word++;
            }else{
                is_has=false;
                w_list.push( details );
            }
        }
        return (<>{w_list.map( item=>(<>{
            item.substring(0,4)==="word"? get_user_ansver(item) : item
        }</>))}</>);
    }
    return (<>
        <div className="test__item card" id={"test_"+question.id}>
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ '<b>'+(number+1)+'.</b> '+question.question } />
                </div>
                <div className="test test--singleoption">
                    <div className="test__body">
                        <div className="ttype--fill_drop">
                            { formatDrag() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default TestResultWordDrags;
