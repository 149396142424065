import React from 'react';
import _ from 'lodash';
import { IMG_URL } from "../../helpers/api";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const  WebinarCard = (props) => {
    const { t } = useTranslation();
    const {webinar} = props;

    // const  startdate = new Date( _.get(webinar,'startdate') );
    // const  duration = parseInt( _.get(webinar,'duration') );
    // const  now = new Date(  );
    // const duration_date = new Date(startdate.getTime() + duration*60000);
    // let is_duration = true;
    // let is_early = true;
    const webinar_url = window.location.origin+"/webinars/view/"+_.get(webinar,'slug');
    // if(duration_date<now){
    //     is_duration = false;
    // }
    // if(startdate<now){
    //     is_early = false;
    // }


    // console.log(startdate); 
    // console.log(duration_date);
    // console.log(now);
    // if(now<startdate){
    //     console.log('k');
    //     console.log(startdate-now);
    // }else{
    //     console.log('kichik');
    // }


    return (<>
        <div className="col-md-6 col-xl-4">
            <div className="webinar">
                <div className="webinar__header">
                    <img src={IMG_URL+_.get(webinar,'image')}  alt="Webinar"
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = '/images/webinars/webinar.jpeg';
                        }}
                    />
                    <div className="webinar__info">
                        <span className="badge">
                            <span className="icon icon-video"></span>
                            {_.get(webinar,'startdate')}
                        </span>
                    </div>
                </div>
                <div className="webinar__body">
                    <strong>{_.get(webinar,'title')}</strong> 
                </div>
                <div className="webinar__footer">
                    <button type="button" className="btn btn-whiter" onClick={()=>{
                            var inp =document.createElement('input');
                            document.body.appendChild(inp);
                            inp.value = webinar_url;
                            inp.select();
                            document.execCommand('copy',false);
                            inp.remove();
                        }}>
                        <span className="icon icon-link"></span>{t('my_webinar.copy')}
                    </button>
                    <Link to={"/webinars/view/"+_.get(webinar,'slug')} className="btn btn-secondary-light">
                        <span className="icon icon-eye"></span>{t('my_webinar.view')}
                    </Link>
                </div>
                {props.children}
            </div>
        </div>

    </>);
}
export default WebinarCard;