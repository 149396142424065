import React, { useState , useEffect } from 'react';
import {IMG_URL} from '../helpers/api'
import Pagination from './sections/Pagination';
import Navigation from "./sections/Navigation";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getTeacherList } from "../actions";
import Loading from "./sections/Loading";
import NoData from "./sections/NoData";
import Layout from './Layout';

const ProfessorsPage = (props) => {
    const {teacher_list_status, teacher_list} = props;
    const page = props.match.params.page;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        props.getTeacherList(page);
		document.title = t('teacher_list.title');

    },[page]);
    useEffect(()=>{       
        if(teacher_list_status.status === 'started' ){
            setLoading(true);
        }
        if(teacher_list_status.status === 'success'){
            setLoading(false);
           
        }
        if(teacher_list_status.status === 'error'){
            setLoading(false);
        }
       
    },[teacher_list_status]);
    return (<Layout>
        <main className="main" style={{minHeight: 610}}>

            <section className="section section-breadcrumbs">
                <div className="container">
                    <Navigation
                        active={t('teacher_list.title')}
                    />
                </div>
            </section>

            <section className="section pt-3">
                <div className="container">
                    <h3 className="mb-4">{t('teacher_list.title')}</h3>
                        {loading?
                            <Loading/>
                        :<>
                        {teacher_list.data&&teacher_list.data.length>0?<>
                        <div className="row mt-6">
                            {teacher_list.data.map((teacher, index)=>(
                                <div className="col-sm-6 col-md-4 col-lg-3 mb-8" key={index}>
                                    <span href="#" className="teacher">
                                        <img 
                                            src={ IMG_URL + teacher.image }
                                            alt={"T"+index}
                                            onError={e => {
                                                e.target.onerror = null;
                                                e.target.src = '/images/user/user.png';
                                            }}
                                        />
                                        <strong>{teacher.name}</strong>
                                        <p>{teacher.position}</p>
                                    </span>
                                </div>
                            ))}
                        </div>
                        <Pagination
                            total={teacher_list.total?teacher_list.total:12}
                            count={12}
                            active={ parseInt(page) }
                            link="/professors/"
                        />
                        </>
                        :<div className="row">
                            <div className="col-12">
                                <NoData
                                    text={t('teacher_list.no_data')}
                                />
                            </div>
                        </div>
                        }
                    </>}
                </div>
            </section>

            </main>


  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherList: (page) => dispatch(getTeacherList(page))
    })
)(ProfessorsPage);