import React, {useState, useEffect} from 'react';
import { getTeacherLesson } from "../actions";
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
// import { Link } from 'react-router-dom';
import Loading from './sections/Loading';
import TeacherLessonWebinar from "./sections/TeacherLessonWebinar";
import LessonDelete from './sections/LessonDelete';
import { useTranslation } from 'react-i18next';
import history from "../history";
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";


const DashTeacherLessonWebinar = (props) => {
    const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const { teacher_lessons_status, teacher_lesson_data } = props;
    const [del_lesson, setDelLesson] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    
    useEffect(() => {
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            setLoading(false);
            if(!teacher_lesson_data.id){
                history.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
            }
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status, teacher_lesson_data]);
    
    return (<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
      <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            
                        <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={teacher_lesson_data.name}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('author_page.course'),
                                        'link': '/dashboard/copyrights'
                                    },
                                    {
                                        'title': t('course_items.items'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id
                                    },
                                    {
                                        'title': t('teacher_lesson.lesson'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
                                    }
                                ]}
                            />
                            {loading?<Loading/>:<>
                            <div className="dashboard-title">
                                <h5>{t('teacher_lesson.edit')}</h5>
                            </div>
                            <div className="card card--shadow">
                                <div className="card-body">
                                    <TeacherLessonWebinar course_id={course_id}
                                        setDelLesson={(data)=>setDelLesson(data)}
                                    />
                                </div>
                            </div> </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
    <LessonDelete del_lesson={del_lesson}/>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
        
    })
)(DashTeacherLessonWebinar);