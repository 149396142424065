import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MatrixRadio = props => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
	const [error_item, setErrorItem] = useState({});
	const [error, setError] = useState('');
	const submitHandler = event => {
		event.preventDefault();
		if (_.get(survey, 'isRequired', false)) {
			let c = true;
			let e_l = {};
			_.get(survey, 'rows', []).forEach(item => {
				if (!_.get(result, 'value.' + [item.id], '')) {
					c = false;
					e_l = { ...e_l, [item.id]: true };
				}
			});
			if (c) next_survey({ id: survey.id, value: _.get(result, 'value', []), type: survey.type });
			else {
				setError(_.get(survey, `error_${lan}`, ''));
				setErrorItem(e_l);
			}
		} else {
			next_survey({ id: survey.id, value: _.get(result, 'value', []), type: survey.type });
		}
	};
	return (
		<form onSubmit={submitHandler}>
			<h4 className="my-5" style={{ color: '#898DA6', fontSize: 18 }}>
				{' '}
				<span className="icon icon-question"></span> {t('survey_i.matrix_radio')}{' '}
			</h4>
			<div className="card">
				<div className="card-body">
					<div className="form-group m-0">
						<label
							style={{
								fontSize: 18,
								color: '#2b2b2b',
								fontFamily: 'Cera Pro',
								fontStyle: 'normal',
								fontWeight: 500,
								lineHeight: '150%',
							}}>
							{_.get(survey, `name_${lan}`, '')} {_.get(survey, 'isRequired', false) ? ' *' : ''}
						</label>
						<div style={{ overflowY: 'auto' }}>
							<table className="table table-striped mt-3 survey-table">
								<tr>
									<td style={{ height: 56 }}>
										<p
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												color: '#0D2E69',
												height: 56,
												margin: 0,
												padding: 0,
												fontSize: 16,
											}}>
											#
										</p>
									</td>
									{_.get(survey, 'cols', []).map((col, index) => (
										<td key={index} style={{ height: 56 }}>
											<p
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													color: '#0D2E69',
													height: 56,
													margin: 0,
													padding: 0,
													fontSize: 16,
												}}>
												{_.get(col, `col_${lan}`, '')}
											</p>
										</td>
									))}
								</tr>
								{_.get(survey, 'rows', []).map((row, index_row) => (
									<tr style={{ background: index_row % 2 ? '' : '#f6f6f8' }} key={index_row}>
										<td style={{ height: 56 }}>
											<p
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													color: '#0D2E69',
													height: 56,
													margin: 0,
													padding: 0,
													fontSize: 16,
												}}>
												{_.get(row, `row_${lan}`, '')}
											</p>
										</td>
										{_.get(survey, 'cols', []).map((col, index) => (
											<td
												key={index}
												style={{
													background: _.get(error_item, row.id, false) ? '#ffc0c0' : '',
													height: 56,
												}}>
												<p
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														height: 56,
														margin: 0,
														padding: 0,
														color: '#0D2E69',
														fontSize: 16,
													}}>
													<label className="radio-custom" style={{ height: 22, width: 22 }}>
														<input
															type="radio"
															name={`radio${row.id}`}
															checked={
																_.get(result, 'value.' + row.id, 0) === col.id
																	? true
																	: false
															}
															onChange={() => {
																setError('');
																setErrorItem({ ...error_item, [row.id]: false });
																surveyChange({
																	id: survey.id,
																	value: {
																		..._.get(result, 'value', {}),
																		[row.id]: col.id,
																	},
																});
															}}
														/>
														<span
															className="checkmark"
															style={{ height: 22, width: 22 }}></span>
													</label>
												</p>
											</td>
										))}
									</tr>
								))}
							</table>
						</div>
						<div style={{ color: 'red' }}>{error}</div>
					</div>
				</div>
			</div>
			<div>
				<span
					className="btn btn-sm btn-secondary-light mr-2 mb-5"
					style={{ color: '#898DA6' }}
					onClick={() => prev_survey(survey.id)}>
					{t('survey_i.prev')}
				</span>
				<button className="btn btn-primary btn-sm  mb-5" style={{ float: 'right' }}>
					{t('survey_i.next')}
				</button>
			</div>
		</form>
	);
};
export default MatrixRadio;
