import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import { getCourses, getTeacherList } from '../actions';
import { IMG_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { issetToken } from '../helpers/tokenStorge';
import Layout from './Layout';
import CertificateModal from './sections/CertificateModal';
import CourseCard from './sections/CourseCard';
import CourseFooterDefault from './sections/CourseFooterDefault';
import Loading from './sections/Loading';
import ScrollToTopOnMount from './sections/ScrollToTopOnMount';
window.jQuery = $;

SwiperCore.use([Navigation, Autoplay]);

const HomePage = (props) => {
  const {profile, courses_list, courses_loading, teacher_list_status, teacher_list } =  props;
  const { t } = useTranslation();
  // const [count, setCount] = useState(0);
  const [t_loading, setTLoading] = useState(true);
  const [certificate_id, setCertificateId] = useState('');
  const [certificate, setCertificate] = useState({});
  const [certificate_e, setCertificateE] = useState('');
  const [certificate_l, setCertificateL] = useState(false);
  const [user_count, setUserCount] = useState(1000);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getInstance()
      .get('/api/v1/reviews')
      .then((response) => {
        const { data } = response;
        // console.log(data);
        if (Array.isArray(data)) {
          setReviews(data);
        }
      })
      .catch((e) => console.log(e)); 
    props.getCourses();
    props.getTeacherList(5);
    getInstance()
      .get('/api/v1/admin/user_count')
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            setUserCount(data.user_count);
          } catch {}
        }
      })
      .catch((error) => console.log(error));

    const telegram = localStorage.getItem('telegram');
    if (!telegram) {
      window.$('#TelegramModal').modal('show');
      localStorage.setItem('telegram', true);
    }
  }, []);
  useEffect(() => {
    // Teachers slider
    if (teacher_list_status.status === 'started') {
      setTLoading(true);
    }
    if (teacher_list_status.status === 'success') {
      setTLoading(false);
    }
    if (teacher_list_status.status === 'error') {
      setTLoading(false);
    }
  }, [teacher_list_status]);

  const check_certificate = (e) => {
    e.preventDefault();
    setCertificateL(true);
    let formData = new FormData(e.target);
    let id = formData.get('id');

    if (id.length === 13) {
      const i = id.substring(6, 13);
      let j = 6;
      for (let k = 6; k > 0; k--) {
        if (i[k - 1] === '0' && i[k] !== '0') {
          j = k;
        }
      }
      if (i[0] !== '0') {
        j = 0;
      }
      getInstance()
        .get('/api/v1/courses/user-check-certificate?id=' + i.substring(j, 7))
        .then((response) => {
          setCertificateL(false);
          if (response.status === 200) {
            try {
              const { data, status } = response.data;
              if (status) {
                setCertificate(data);
                window.$('#modalCertificate_1').modal('show');
              } else {
                setCertificateE(t('one_page.certificate_not'));
              }
            } catch {}
          }
        })
        .catch((error) => console.log(error));
    } else {
      setCertificateE(t('one_page.certificate_e'));
      setCertificateL(false);
    }
  };
  const certificateChange = (v) => {
    if (v.length > 3) {
      if (v.substring(0, 3) === 'MO-') {
        const test = new RegExp(/^[0-9]{0,2}$/).test(v.substring(3, 5));
        if (test) {
          if (v.length == 5) {
            if (certificate_id.length === 4) {
              setCertificateId(v + '-');
            } else {
              setCertificateId(v.substring(0, 4));
            }
          } else {
            const test1 = new RegExp(/^[0-9]{0,7}$/).test(v.substring(6, 13));
            if (test1 && v.length < 14) {
              setCertificateId(v);
            }
          }
        }
      } else {
        setCertificateId('MO-');
      }
    } else {
      setCertificateId('MO-');
    }
  };
  return (
    <Layout>
      <ScrollToTopOnMount />
      <main className='main'>
        <section className='section section-intro'>
          <div className='container'>
            <div className='section-intro__inner'>
              <div className='row align-items-center'>
                <div className='col mb-5'>
                  <h1>{t('one_page.intro_title')}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('one_page.intro_text'),
                    }}
                  />
                  {issetToken() ? (
                    <Link
                      to={profile?.is_teacher?'/dashboard/qualification':'/dashboard/profile'}
                      className='btn btn-primary'
                    >
                      {t('one_page.intro_btn')} 
                    </Link>
                  ) : (
                    <button
                      className='btn btn-primary'
                      data-toggle='modal'
                      data-target='#modalLogin'
                    >
                      {t('one_page.intro_btn')}
                    </button>
                  )}
                </div>
                <div className='col'>
                  <img src='images/intro_w.png' alt='Online-Education' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section section-learners'>
          <div className='container'>
            <div className='title'>
              <h3
                dangerouslySetInnerHTML={{
                  __html:
                    '<span>{</span>' + user_count + t('one_page.learners'),
                }}
              />
            </div>
            <div className='scroll scroll--horizontall'>
              <div className='scroll__inner'>
                <Swiper
                  spaceBetween={0}
                  direction={'horizontal'}
                  autoplay={{
                    delay: 1000,
                  }}
                  loop={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    576: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://www.unesco.org/en'
                      className='partners-home'
                      target='_blank'
                    >
                      <img
                        src='/images/unesco 1.png'
                        alt='unesco'
                        style={{ borderRadius: 10 }}
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://uzedu.uz'
                      className='partners-home'
                      target='_blank'
                    >
                      <img
                        src='images/p1.png'
                        alt='uzedu'
                        style={{ borderRadius: 10 }}
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://www.unicef.org/'
                      className='partners-home'
                      target='_blank'
                    >
                      <img
                        src='images/p6.png'
                        alt='unicef'
                        style={{ borderRadius: 10 }}
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://edu.uz'
                      className='partners-home'
                      target='_blank'
                    >
                      <img src='images/p2.png' alt='edu' />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://mdo.uz'
                      className='partners-home'
                      target='_blank'
                    >
                      <img src='images/p3.png' alt='mdo' />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://tdi.uz'
                      className='partners-home'
                      target='_blank'
                    >
                      <img src='images/p5.png' alt='tdi' />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href='https://my.gov.uz'
                      className='partners-home'
                      target='_blank'
                    >
                      <img src='images/p4.png' alt='my.gov' />
                    </a>
                  </SwiperSlide>
                </Swiper>
                <div></div>
              </div>
            </div>
            <style jsx={true}>{`
              .partners-home {
                border-radius: 8px;
                background: #ffffff;
              }
            `}</style>
          </div>
        </section>
        {reviews.length ? (
          <section className='section pb-0'>
            <div className='container'>
              <div className='title'>
                <h2>{t('one_page.review_title')}</h2>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{ maxWidth: 768 }}>{t('one_page.review_target')}</p>
                </div>
              </div>
              <Swiper
                className='review-target'
                spaceBetween={0}
                navigation
                direction={'horizontal'}
                autoplay={{
                  delay: 2000,
                }}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  1200: {
                    slidesPerView: 1,
                  },
                }}
              >
                {reviews.map((review, index) => {
                  const { comment, image, name, position } = review;
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className='review-card'>
                        <div className='review-card-left'>
                          <img
                            src={IMG_URL + image}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/images/user/user.png';
                            }}
                            alt={position}
                          />
                          <h3 className='review-name'>{name}</h3>
                          <h4 className='review-job'>{position}</h4>
                        </div>
                        <div className='review-card-right'>
                          <p>{comment}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <style jsx={true}>{`
                .review-target .swiper-wrapper {
                  align-items: center;
                }
                .review-card {
                  width: 970px;
                  border-radius: 24px;
                  max-width: 970px;
                  padding: 5%;
                  display: flex;
                  background: rgba(137, 141, 166, 0.08);
                  position: relative;
                  margin: 0 5px;
                  background-image: url('/images/quote.svg');
                  background-repeat: no-repeat;
                  background-position: top 32px right 32px;
                }
                .review-card-left {
                  width: 200px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                }
                .review-card-left img {
                  width: 120px;
                  height: 120px;
                  border-radius: 120px;
                  object-fit: cover;
                  margin: 0 0 10px 0;
                }
                .review-card-right {
                  width: calc(100% - 200px);
                  display: flex;
                  align-items: center;
                }
                .review-card-right p {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 160%;
                  color: #0d2e69;
                  text-align: left;
                  padding: 0 10px;
                }
                .review-name {
                  font-size: 18px;
                  padding: 0 10px;
                  text-align: center;
                }
                .review-job {
                  margin: 10px 0 0 0;
                  font-size: 16px;
                  padding: 0 10px;
                  text-align: center;
                }
                @media screen and (max-width: 991px) {
                  .review-card {
                    width: 100%;
                  }
                }
                @media screen and (max-width: 768px) {
                  .review-card {
                    flex-direction: column;
                  }
                  .review-card-right {
                    width: 100%;
                    margin: 20px 0 0 0;
                  }

                  .review-card-right p {
                    text-align: center;
                  }
                  .review-card-left {
                    width: 100%;
                  }
                }
              `}</style>
            </div>
          </section>
        ) : null}
        <section className='section section-features'>
          <div className='container'>
            <div className='title'>
              <h2>{t('one_page.features')}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('one_page.features_text'),
                }}
              />
            </div>
            <div className='features'>
              <div className='row'>
                <div className='col-md-6 col-xl-3 mb-5 mb-xl-0'>
                  <div className='feature'>
                    <span className='icon icon-webgrid'></span>
                    <strong>{t('one_page.feature1')}</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('one_page.feature1_text'),
                      }}
                    />
                  </div>
                </div>
                <div className='col-md-6 col-xl-3 mb-5 mb-xl-0'>
                  <div className='feature'>
                    <span className='icon icon-qualification'></span>
                    <strong>{t('one_page.feature2')}</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('one_page.feature2_text'),
                      }}
                    />
                  </div>
                </div>
                <div className='col-md-6 col-xl-3 mb-5 mb-xl-0'>
                  <div className='feature'>
                    <span className='icon icon-video'></span>
                    <strong>{t('one_page.feature4')}</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('one_page.feature3_text'),
                      }}
                    />
                  </div>
                </div>
                <div className='col-md-6 col-xl-3'>
                  <div className='feature'>
                    <span className='icon icon-list-dots '></span>
                    <strong>{t('one_page.feature4')}</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('one_page.feature4_text'),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section section-check-certificate'>
          <div className='container'>
            <div className='check-certificate'>
              <div className='title'>
                <h2>{t('one_page.check_certificate_title')}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('one_page.check_certificate_text'),
                  }}
                />
              </div>
              <div className='check-certificate__form'>
                {certificate_l ? (
                  <Loading />
                ) : (
                  <form
                    enctype='multipart/form-data'
                    onSubmit={check_certificate}
                  >
                    <input
                      value={certificate_id}
                      name='id'
                      type='text'
                      className='form-control'
                      placeholder={t('one_page.check_certificate_input')}
                      onChange={(e) => {
                        setCertificateE('');
                        certificateChange(e.target.value);
                      }}
                      onClick={(e) => certificateChange(e.target.value)}
                    />

                    <button type='submit' className='btn btn-primary'>
                      {t('one_page.check_certificate_btn')}
                    </button>
                  </form>
                )}
                <div style={{ color: 'red', textAlign: 'center' }}>
                  {certificate_e}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section section-courses'>
          <div className='container'>
            {courses_loading ? (
              <Loading />
            ) : courses_list &&
              courses_list.data &&
              courses_list.data.length > 0 ? (
              <>
                <div className='title'>
                  <h2>{t('one_page.courses_title')}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('one_page.courses-description'),
                    }}
                  ></p>
                </div>
                <div className='row'>
                  {courses_list.data.map((course, index) => (
                    <CourseCard key={index} course={course} avg_rating={true}>
                      <CourseFooterDefault course={course} />
                    </CourseCard>
                  ))}
                </div>
                <div className='btn-container btn-container--center'>
                  <Link to='/courses' className='btn btn-primary'>
                    {t('one_page.courses_all')}
                  </Link>
                </div>
              </>
            ) : null}
          </div>
        </section>
        <section
          className='section section-call-to-action'
          style={{ backgroundImage: "url('images/call-to-action.jpg')" }}
        >
          <div className='container'>
            <div className='section-call-to-action__inner'>
              <h1>{t('one_page.action_title')}</h1>
              <p
                dangerouslySetInnerHTML={{ __html: t('one_page.action_text') }}
              />
              <div className='cta-participants'>
                <ul>
                  <li>
                    <img src='images/teachers/4.jpg' alt='edu' />
                  </li>
                  <li>
                    <img src='images/teachers/1.jpg' alt='onlinedu' />
                  </li>
                  <li>
                    <img src='images/teachers/2.jpg' alt='onlineedu' />
                  </li>
                  <li>
                    <span>{t('one_page.action_count')}</span>
                  </li>
                </ul>
                <strong
                  dangerouslySetInnerHTML={{
                    __html: t('one_page.action_text2'),
                  }}
                />
              </div>
              <Link to='/webinars' className='btn btn-primary'>
                {t('one_page.action_btn')}
              </Link>
            </div>
          </div>
        </section>

        <section className='section section-teachers'>
          <div className='container'>
            {t_loading ? (
              <Loading />
            ) : (
              <>
                <div className='title'>
                  <h2>{t('one_page.teachers_title')}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('one_page.teachers_text'),
                    }}
                  />
                </div>
                {teacher_list.data ? (
                  <div>
                    <Swiper
                      // spaceBetween={10}
                      navigation
                      direction={'horizontal'}
                      autoplay={{
                        delay: 1000,
                      }}
                      loop={true}
                      style={{ marginBottom: '20px' }}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        576: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        1200: {
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {teacher_list.data.map((teacher, index) => (
                        <SwiperSlide key={index}>
                          <div className='item flex__target'>
                            <div className='teacher'>
                              <img
                                src={IMG_URL + teacher.image}
                                alt={'teacher ' + index}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/images/user/user.png';
                                }}
                              />
                              <strong>{teacher.name}</strong>
                              <p>{teacher.position}</p>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                ) : null}
                {teacher_list &&
                teacher_list.data &&
                teacher_list.data.length > 0 ? (
                  <div className='btn-container btn-container--center'>
                    <br />
                    <Link to='/professors' className='btn btn-primary'>
                      {t('one_page.teachers_all')}
                    </Link>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <style jsx>{`
            .swiper-button-prev,
            .swiper-button-next {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              background: #898da6;
            }
            .swiper-button-prev:hover,
            .swiper-button-next:hover {
              background: #26caac;
            }
            .swiper-button-next::before {
              content: '';
              width: 3px;
              position: absolute;
              left: 18px;
              top: 16px;
              transform: rotate(45deg);
              height: 10px;
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;
              background: white;
            }
            .swiper-button-next::after {
              content: '';
              left: 18px;
              top: 10px;
              transform: rotate(-45deg);
              position: absolute;
              width: 3px;
              height: 10px;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              background: white;
            }
            .swiper-button-prev::before {
              content: '';
              width: 3px;
              position: absolute;
              left: 16px;
              top: 16px;
              transform: rotate(-45deg);
              height: 10px;
              background: white;
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;
            }
            .swiper-button-prev::after {
              content: '';
              left: 16px;
              top: 10px;
              transform: rotate(45deg);
              position: absolute;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              width: 3px;
              height: 10px;
              background: white;
            }
          `}</style>
        </section>
        {/* <section className="section section-check-certificate" style={{paddingBottom: 90}}>
					<div className="container">
						<div className="check-certificate">
							<div className="title">
								<h2>{t('one_page.edu_institution_title')}</h2>
								<p dangerouslySetInnerHTML={{ __html: t('one_page.edu_institution_text') }} />
							</div>
							<div style={{textAlign: 'center'}}>
								<Link to="/edu-institution" className="btn btn-primary">
									{t('one_page.edu_institution_btn')}
								</Link>
								
							</div>
						</div>
					</div>
				</section> */}
      </main>
      <CertificateModal certificate={certificate} />
      {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#TelegramModal">
				Launch demo modal
			</button> */}
      <div
        class='modal fade'
        id='TelegramModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='TelegramModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span className='icon icon-close'></span>
              </button>
            </div>
            <div
              class='modal-body pb-1'
              style={{ display: 'flex', paddingLeft: 40, paddingRight: 50 }}
            >
              <img
                src='/images/telegram.png'
                alt='onlinedu avloniyuz '
                style={{ width: 100, height: 100, marginRight: 30 }}
              />
              <div style={{ paddingTop: 15, fontSize: 18 }}>
                {t('one_page.telegram_text')}
              </div>
            </div>
            <div class='modal-footer p-0'>
              <a
                class='btn btn-primary btn-sm'
                style={{ marginTop: 5 }}
                href='https://t.me/avloniyuz'
                target='_blank'
              >
                {t('one_page.telegram_btn')}{' '}
                <span className='icon icon-send'></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default connect(
  (state) => ({
    ...state,
  }),
  (dispatch) => ({
    getCourses: () => dispatch(getCourses()),
    getTeacherList: (per_page) => dispatch(getTeacherList(1, per_page)),
  })
)(HomePage);
