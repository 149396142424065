import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
ReactDOM.render(
	<React.StrictMode>
		<i18n>
			<App />
		</i18n>
	</React.StrictMode>,
	document.getElementById('root'),
);

reportWebVitals();
