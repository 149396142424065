import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { API_URL, FILE_URL } from '../../helpers/api';
import { getInstance, getNotAuthInstance } from '../../helpers/httpClient';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import { getToken } from '../../helpers/tokenStorge';
import * as m3u8Parser from 'm3u8-parser';
import 'videojs-progress-marker/lib';
import 'videojs-progress-marker/lib/style/';
import LessonVideoTestSingle from './LessonVideoTestSingle';
import LessonVideoTestMultiple from './LessonVideoTestMultiple';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import axios from 'axios';

require('@silvermine/videojs-quality-selector')(videojs);

const usePlayer = ({ src, type, markers, controls, autoplay }) => {
	const options = {
		poster: '/images/courses/course.jpg',
		withCredentials: true,
		fill: true,
		fluid: true,
		preload: 'auto',
		html5: {
			hls: {
				enableLowInitialPlaylist: false,
				overrideNative: true,
				smoothQualityChange: true,
			},
		},
	};
	const videoRef = useRef(null);
	const [player, setPlayer] = useState(null);
	useEffect(() => {
		videojs.Hls.xhr.beforeRequest = options => {
			// var headers = options.headers || {};
			// headers['Authorization'] = `Bearer  ${getToken()}`;
			// options.headers = headers;
			return options;
		};
		let vjsPlayer = videojs(videoRef.current, {
			...options,
			controls,
			controlBar: {
				liveDisplay: true,
				pictureInPictureToggle: false,
			},
			autoplay,
			sources: src,
		});
		vjsPlayer.controlBar.addChild('QualitySelector');
		vjsPlayer.markers({
			markerStyle: {
				position: 'absolute',
				width: '9px',
				height: '9px',
				'border-radius': '40%',
				'background-color': '#26caac',
				top: '-3px',
				'margin-left': '-4.3px !important',
			},
			markerTip: { display: false },
			breakOverlay: { display: false },
			markers: markers,
		});
		// vjsPlayer.ready(()=>{
		// 	console.log('=>', vjsPlayer.currentTime());
		// });
		setPlayer(vjsPlayer);
		return () => {
			if (player !== null) player.dispose();
		};
	}, []);
	useEffect(() => {
		if (player !== null) player.src({ src });
	}, [src]);
	return videoRef;
};
const VideoPlayerBody = ({
	src,
	type,
	video_question,
	section,
	lesson_id,
	controls,
	autoplay,
	duration_video,
	getCourseSections,
	getCourse,
}) => {
	const { t } = useTranslation();
	let markers = [];
	video_question.forEach(item => {
		if (!item.is_completed) markers.push({ time: item.time - 2 });
	});
	const playerRef = usePlayer({ src, type, markers, controls, autoplay });
	const [active, setActive] = useState(0);
	const [perform_test, setPerform_test] = useState(false);
	const [avtiveTest, setAvtiveTest] = useState(0);
	const [answersList, setAnswersList] = useState({});
	const [answersError, setAnswersError] = useState('');
	const [testResultShow, setTestResultShow] = useState(false);
	const [testResult, setTestResult] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentTime, setCurrentTime] = useState(null);
	const [complete, setComplete] = useState(false);
	useEffect(() => {
		if (playerRef) {
			const player = videojs(playerRef.current, playerRef, () => {
				player.on('timeupdate', () => {
					setCurrentTime(player.currentTime());
				});
			});
		}
	}, []);
	useEffect(() => {
		if (playerRef) {
			if (
				Math.floor(currentTime) ===
				_.get(
					_.filter(video_question, o => {
						return !o.is_completed;
					}),
					'[' + active + '].time',
					duration_video + 1
				)
			) {
				const player = videojs(playerRef.current, playerRef, () => {
					player.pause();
					setPerform_test(true);
				});
			}
			if (
				Math.floor(currentTime) >
				_.get(
					_.filter(video_question, o => {
						return !o.is_completed;
					}),
					'[' + active + '].time',
					duration_video + 1
				)
			) {
				const player = videojs(playerRef.current, playerRef, () => {
					player.currentTime(
						_.get(
							_.filter(video_question, o => {
								return !o.is_completed;
							}),
							'[' + (active - 1) + '].time',
							0
						)
					);
				});
			}
		}
	}, [currentTime]);

	const completeTest = () => {
		setLoading(true);
		const qlist = _.filter(video_question, o => {
			return !o.is_completed;
		});
		const question = _.get(
			qlist,
			'[' + active + '].vquestions[' + avtiveTest + ']',
			{}
		);
		let is_end = 0;
		if (
			qlist.length - 1 === active &&
			_.get(qlist, '[' + active + '].vquestions', []).length - 1 ===
				avtiveTest
		) {
			is_end = 1;
		}
		getInstance()
			.post(`${API_URL}/api/v1/course/test-check-video`, {
				section_slug: section,
				lesson_id: lesson_id,
				answers: {
					[question.id]: _.get(answersList, question.id, 0),
				},
				is_end: is_end,
			})
			.then(response => {
				if (response.status === 200) {
					const { data } = response;
					setTestResultShow(true);
					setLoading(false);
					setTestResult(_.get(data, 'lesson'));
					if (is_end === 1) {
						getCourseSections();
						getCourse();
					}
				}
			})
			.catch(error => setLoading(false));
	};
	const nextQuestion = () => {
		if (
			_.get(
				_.filter(video_question, o => {
					return !o.is_completed;
				}),
				'[' + active + '].vquestions',
				[]
			).length >
			avtiveTest + 1
		) {
			setAvtiveTest(avtiveTest + 1);
			setTestResultShow(false);
		} else setComplete(true);
	};
	const next = () => {
		if (playerRef) {
			const player = videojs(playerRef.current, playerRef);
			if (_.get(testResult, 'vquestions.is_completed', false)) {
				setActive(active + 1);
				player.currentTime(
					_.get(
						_.filter(video_question, o => {
							return !o.is_completed;
						}),
						'[' + active + '].time',
						0
					)
				);
				player.play();
			} else {
				player.currentTime(
					_.get(
						_.filter(video_question, o => {
							return !o.is_completed;
						}),
						'[' + (active - 1) + '].time',
						0
					)
				);
				player.play();
			}
			setPerform_test(false);
			setAnswersList({});
			setAvtiveTest(0);
			setTestResultShow(false);
			setTestResult(false);
			setComplete(false);
		}
	};
	return (
		<>
			<div className="video mb-4">
				<div data-vjs-player>
					<video ref={playerRef} className="video-js" />
					{perform_test ? (
						<div
							style={{
								position: 'absolute',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								opacity: '.95',
								top: 0,
								height: '100%',
								width: '100%',
								padding: 20,
								background: 'rgba(50,50,50,.5)',
								zIndex: 1,
							}}
						>
							<div
								className="card"
								style={{
									maxHeight: '100%',
									overflowY: 'scroll',
									minWidth: '50%',
								}}
							>
								<div className="card-body p-0">
									{complete ? (
										_.get(
											testResult,
											'vquestions.is_completed',
											false
										) ? (
											<>
												<div
													style={{
														color: '#000',
														fontSize: 18,
														padding: 25,
													}}
												>
													<h3>{t('video_player.continue')}</h3>
													<div>
														{t('video_player.continue_title')}
													</div>
												</div>
												<div>
													<button
														style={{
															fontSize: 14,
															background: '#26caac',
														}}
														className="btn btn-primary btn-sm ml-5 mb-5"
														onClick={() => {
															next();
														}}
													>
														{t('video_player.continue_btn')}
													</button>
												</div>
											</>
										) : (
											<>
												<div
													style={{
														color: '#000',
														fontSize: 18,
														padding: 25,
													}}
												>
													<h3>{t('video_player.again')}</h3>
													<div>
														{t('video_player.again_title')}
													</div>
												</div>
												<div>
													<button
														style={{
															fontSize: 14,
															background: '#26caac',
														}}
														className="btn btn-primary btn-sm ml-5 mb-5"
														onClick={() => {
															next();
														}}
													>
														{t('video_player.again_btn')}
													</button>
												</div>
											</>
										)
									) : (
										<>
											{_.get(
												_.filter(video_question, o => {
													return !o.is_completed;
												}),
												'[' +
													active +
													'].vquestions[' +
													avtiveTest +
													'].type',
												'single'
											) === 'single' ? (
												<LessonVideoTestSingle
													question={_.get(
														_.filter(video_question, o => {
															return !o.is_completed;
														}),
														'[' +
															active +
															'].vquestions[' +
															avtiveTest +
															']',
														{}
													)}
													number={avtiveTest}
													answersList={answersList}
													setAnswersList={l => setAnswersList(l)}
													testResultShow={testResultShow}
													testResult={testResult}
												/>
											) : (
												<LessonVideoTestMultiple
													question={_.get(
														_.filter(video_question, o => {
															return !o.is_completed;
														}),
														'[' +
															active +
															'].vquestions[' +
															avtiveTest +
															']',
														{}
													)}
													number={avtiveTest}
													answersList={answersList}
													setAnswersList={l => setAnswersList(l)}
													testResultShow={testResultShow}
													testResult={testResult}
												/>
											)}
											<div style={{ color: 'red' }}>
												{answersError}
											</div>
											<div>
												{testResultShow ? (
													<button
														style={{
															fontSize: 14,
															background: '#26caac',
														}}
														className="btn btn-primary btn-sm ml-5 mb-5"
														onClick={() => nextQuestion()}
													>
														{t('video_player.next')}
													</button>
												) : loading ? (
													<Loading />
												) : (
													<button
														style={{
															fontSize: 14,
															background: '#26caac',
														}}
														className="btn btn-primary btn-sm ml-5 mb-5"
														onClick={() => {
															completeTest();
														}}
													>
														{t('video_player.submit')}
													</button>
												)}
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};
VideoPlayerBody.propTypes = {
	src: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	video_question: PropTypes.array,
	section: PropTypes.string,
	lesson_id: PropTypes.number,
	controls: PropTypes.bool,
	autoplay: PropTypes.bool,
	duration_video: PropTypes.number,
	getCourse: PropTypes.func,
};
VideoPlayerBody.defaultProps = {
	controls: true,
	autoplay: false,
	video_question: [],
	section: '',
	lesson_id: 0,
	duration_video: 0,
};
const VideoPlayer = props => {
	const {
		course_lesson,
		section,
		getCourseSections,
		getSection = false,
		getCourse,
	} = props;
	const id = _.get(course_lesson, 'data.id');
	const is_enc = _.get(course_lesson, 'data.is_enc');
	const file = _.get(course_lesson, 'data.file');
	const video_question = _.sortBy( _.get(course_lesson, 'data.vquestions', []), [ o => { return o.time; } ] );
	const duration_video = _.get(course_lesson, 'data.duration_video', 0);
	const [src, setSrc] = useState([]);
	useEffect(() => {
		if (is_enc === 1) {
			getInstance()
				.get(`${API_URL}/api/v1/lesson/getVideoNew/${id}`)
				.then(response => {
					if (response.status === 200) { 
						getM3u8(response?.data?.url?.playlist??'');
					}
				})
				.catch(error => console.log(error));
		} else {
			setSrc([
				{
					src: FILE_URL + file,
					type: 'video/mp4',
					label: '720p',
				},
			]);
		}
	}, []);
	const getM3u8 = (playlist='') => {
		axios.get(playlist)
			.then(response => {
				if (response.status === 200) {
					const { data } = response;
					let parser = new m3u8Parser.Parser();
					parser.push(data);
					parser.end();
					let parsedManifest = parser.manifest;
					let s = [];
					const ts_url = playlist.substring(0, playlist.length-13);
					parsedManifest.playlists.map(item =>
						s.push({
							src: `${ts_url}${item.uri}`,
							type: 'application/x-mpegURL',
							label: item.attributes.NAME + 'p',
						})
					);
					setSrc(s);
				}
			})
			.catch(error => console.log(error));
	}
	return (
		<>
			{src.length > 0 ? (
				<VideoPlayerBody
					src={src}
					type={'application/x-mpegURL'}
					video_question={video_question}
					section={section}
					lesson_id={id}
					duration_video={duration_video}
					getCourseSections={() => {
						if (getSection) {
							getCourseSections();
						}
					}}
					getCourse={getCourse}
				/>
			) : null}
		</>
	);
};
export default VideoPlayer;
