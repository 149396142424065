import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { getCourse, getCourseSections } from "../actions";
import Loading from "./sections/Loading";
import Layout from './Layout';
import Navigation from "./sections/Navigation";
import CourseBody from "./sections/CourseBody";
import NoData from "./sections/NoData";
import CourseSideBarLeft from "./sections/CourseSideBarLeft";
import { useTranslation } from 'react-i18next';
import CourseBtns from './sections/CoutceBtns';

const CoursesPage = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    let slug = props.match.params.slug ? props.match.params.slug : '';
    useEffect(() => {
        props.getCourse(slug);
        props.getCourseSections(slug);
        slug = props.match.params.slug ? props.match.params.slug : '';
		document.title = _.get(course, 'data.name', t('courses.title'));

    }, [props.match.params.slug]);
    useEffect(() => {
        // if(props.course_buy_status.status === 'started'){
        //     setLoading(true)
        // }
        if (props.course_buy_status.status === 'success') {
            props.getCourseSections(slug);
        }
        // if(props.course_buy_status.status === 'error'){
        //     setLoading(false)
        // }
    }, [props.course_buy_status]);
    useEffect(() => {
        if (props.course_status.status === 'started') {
            setLoading(true)
        }
        if (props.course_status.status === 'success') {
            setLoading(false)
        }
    }, [props.course_status]);

    const { course } = props;
    return (<Layout>
        <main className="main">
            <section className="section section-breadcrumbs">
                <div className="container">
                    <Navigation
                        is_btn={true}
                        active={_.get(course, 'data.name')}
                        navs={[
                            {
                                title: _.get(course, 'data.category.name'),
                                link: "/courses/1?category=" + _.get(course, 'data.category.id')
                            }
                        ]}
                    />
                </div>
            </section>
            <section className="section section-course pt-3">
                <div className="container">

                    <h3 className="mb-4">{course.data && course.data.name ? course.data.name : ''}</h3>

                    <div className="row">
                        <div className="col-lg-3">
                            <CourseSideBarLeft slug={slug}/>
                        </div>
                        <div className="col-lg-9 course_btns_target">
                            <div className="has-right-buttons__right">
                                <CourseBtns  course={course}  slug={slug} dash={false}/>
                            </div>
                            {loading ? <Loading /> : course.data ? <>
                                <CourseBody course={course} />
                            </> : <NoData text={t('course_view.no_data')} />}
                        </div>
                    </div>
                </div>
            </section>

        </main>
    </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug) => dispatch(getCourse(slug)),
        getCourseSections: (slug) => dispatch(getCourseSections(slug)),
    })
)(CoursesPage);