import _, { get, has, isObject } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	getCitiesList,
	getPupils,
	getRegionList,
	getTeacherLanguages,
} from '../actions';
import { FILE_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import { getToken } from '../helpers/tokenStorge';
import history from '../history';
import Layout from './Layout';
import CertificateModal from './sections/CertificateModal';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import ExcelExportModal from './sections/ExcelExportModal';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import Pagination from './sections/Pagination';

const ManagerPupils = props => {
	const { t } = useTranslation();
	const {
		pupils,
		pupils_status,
		region_list,
		cities_list,
		teacher_lan,
		excelReady,
	} = props;
	const [loadExcel, setLoadExcel] = useState({});
	const [isSchool, setIsSchool] = useState();
	const [readyExcel, setReadyExcel] = useState({});
	const [certificate, setCertificate] = useState({});
	const [exporting, setExporting] = useState(false);
	const [loading, setLoading] = useState(true);
	const [search_loading, setSearchLoading] = useState(false);
	const [position_Type, setPosition_Type] = useState([]);
	const [schools, setSchools] = useState([]);
	const [schoolTypes, setSchoolTypes] = useState([]);
	const [eduGroups, seteduGroups] = useState([]);
	const [edus, setEdus] = useState([]);
	const [userPosition, setUserPosition] = useState([
		{
			id: '',
			name: '',
			hours: '',
			created_at: '',
		},
	]);

	const { page } = props.match.params;
	const [search, setSearch] = useState({
		year: '',
		region: '',
		city: '',
		school: '',
		status: '',
		name: '',
		full_name: '',
		created_at: '',
		last_online_at_from: '',
		last_online_at_to: '',
		certificate_date_to: '',
		certificate_date_from: '',
		hours_count: '',
		position_type_id: '',
		position_id: '',
		certificate_count: '',
		language: '',
		certificate_id: '',
		is_specialized: '',
		school_type: '',
		number: '',
		edu_type: '',
		edu_institution_id: '',
	});
	const [is_Search, setIs_Search] = useState(2);

	const {
		is_specialized,
		school_type,
		year,
		certificate_id,
		region,
		city,
		school,
		language,
		status,
		name,
		full_name,
		created_at,
		hours_count,
		position_id,
		position_type_id,
		certificate_count,
		last_online_at_to,
		last_online_at_from,
		certificate_date_to,
		certificate_date_from,
		number,
		edu_type,
		edu_institution_id,
	} = search;
	const params = `${status ? '&status=' + status : ''}${
		year ? '&year=' + year : ''
	}${certificate_id ? '&certificate_id=' + certificate_id : ''}${
		school ? '&school=' + school : ''
	}${is_specialized ? '&is_specialized=' + is_specialized : ''}${
		school_type ? '&school_type=' + school_type : ''
	}${language ? '&language=' + language : ''}${
		region ? '&region=' + region : ''
	}${city ? '&city=' + city : ''}${name ? '&name=' + name : ''}${
		full_name ? '&full_name=' + full_name : ''
	}${created_at ? '&created_at=' + created_at : ''}${
		hours_count ? '&hours_count=' + hours_count : ''
	}${position_type_id ? '&position_type_id=' + position_type_id : ''}${
		position_id ? '&position_id=' + position_id : ''
	}${certificate_count ? '&certificate_count=' + certificate_count : ''}${
		last_online_at_from ? '&last_online_at_from=' + last_online_at_from : ''
	}${last_online_at_to ? '&last_online_at_to=' + last_online_at_to : ''}${
		certificate_date_from
			? '&certificate_date_from=' + certificate_date_from
			: ''
	}${
		certificate_date_to ? '&certificate_date_to=' + certificate_date_to : ''
	}${number ? '&number=' + number : ''}${
		edu_type ? '&edu_type=' + edu_type : ''
	}${edu_institution_id ? '&edu_institution_id=' + edu_institution_id : ''}`;
	let auto_time = useRef(null);

	useEffect(() => {
		setSearchLoading(true);
		props.getPupils(
			page ? page : 1,
			window.location.search ? window.location.search : '?'
		);
		if (window.location.search) {
			const obj = JSON.parse(
				'{"' +
					decodeURI(window.location.search.substring(1))
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}'
			);
			setSearch({ ...search, ...obj });
			if (obj.region) props.getCitiesList(obj.region);
			if (obj.city) getSchools(obj.region, obj.city);
		}
		return () => {
			clearTimeout(auto_time.current);
		};
	}, [window.location.search, page]);
	useEffect(() => {
		if (is_Search !== 2)
			history.push(`/manager/pupils/list/1?${params.substring(1)}`);
	}, [is_Search]);
	useEffect(() => {
		if (pupils_status.status === 'started') {
			setLoading(true);
		}
		if (pupils_status.status === 'success') {
			window.$('#modalFilter').modal('hide');
			setLoading(false);
			setSearchLoading(false);
		}
		if (pupils_status.status === 'error') {
			setLoading(false);
		}
	}, [pupils_status]);
	const changeParams = (e, child_search = {}, is_loading = true) => {
		const { value, name } = e.target;
		clearTimeout(auto_time.current);
		if (is_loading) {
			setSearchLoading(true);
			setSearch({
				...search,
				...child_search,
				[name]: value,
			});
			setIs_Search(!is_Search);
		} else {
			setSearchLoading(true);
			setSearch({
				...search,
				...child_search,
				[name]: value,
			});
			auto_time.current = setTimeout(() => {
				setIs_Search(!is_Search);
			}, 1500);
		}
	};
	const modalFilter = e => {
		const { value, name } = e.target;
		setSearch({
			...search,
			[name]: value,
		});
	};

	useEffect(() => {
		props.getRegionList();
		props.getTeacherLanguages();
		getInstance()
			.get('/api/v1/profil/position-type')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setPosition_Type(data);
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/profil/user-position')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setUserPosition(data);
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/school-type')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response;
						setSchoolTypes(_.get(data, 'data', []));
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/edu-institution?per_page=200')
			.then(response => {
				if (response?.status === 200) {
					try {
						setEdus(_.get(response, 'data.data.data', []));
					} catch {}
				}
			})
			.catch(error => console.log(error));
	}, []);
	const getSchools = (r_id, c_id) => {
		getInstance()
			.get(`/api/v1/school?region_id=${r_id}&city_id=${c_id}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setSchools(data ? data : []);
					} catch {}
				}
			})
			.catch(error => {});
	};
	const resetFilter = () => {
		setSearchLoading(true);
		setSearch({
			year: '',
			region: '',
			city: '',
			school: '',
			status: '',
			full_name: '',
			language: '',
			name: '',
			created_at: '',
			last_online_at_from: '',
			last_online_at_to: '',
			hours_count: '',
			position_id: '',
			position_type_id: '',
			certificate_date_to: '',
			certificate_date_from: '',
			certificate_id: '',
			is_specialized: '',
			school_type: '',
		});
		history.push(`/manager/pupils/list/1`);
	};
	const language_l = window.localStorage.getItem('language') || 'uz';
	const getLoadExcel = obj => {
		setLoadExcel(obj);
		setExporting(true);
		localStorage.setItem('excelExport', JSON.stringify(obj));
		const intervalId = setInterval(() => {
			if (has(obj, 'data.file')) {
				getInstance()
					.get('/api/v1/users/export/check/?id=' + get(obj, 'data.id', ''))
					.then(res => {
						if (get(res, 'data.data.status') === 2) {
							setLoadExcel({ ...loadExcel, loading: false });
							window.$('#modalExcelReady').modal('show');
							excelReady(obj?.data);
							setExporting(false);
							clearInterval(intervalId);
							const local = localStorage.getItem('excelExport');
							if (local) {
								localStorage.removeItem('excelExport');
							}
						}
					})
					.catch(e => console.log(e));
			}
		}, 5000);
	};
	useEffect(() => {
		const local = JSON.parse(localStorage.getItem('excelExport'));
		if (isObject(local)) {
			getLoadExcel(local);
		} else {
			setExporting(false);
		}
	}, []);
	const getEduGruops = edu_id => {
		getInstance()
			.get(`/api/v1/edu-institutions/groups?eduinstitution_id=${edu_id}`)
			.then(response => {
				if (response?.status === 200) {
					seteduGroups(_.get(response, 'data.data.data', []));
				}
			})
			.catch(error => {});
	};

	let findIsSchoolID = {};
	position_Type.forEach(item => {
		if (item.id === Number(position_type_id)) {
			findIsSchoolID = {
				...item,
				is_School: item.is_school,
			};
		}
	});

	return (
		<Layout>
			<CheckRole roles={[roles.manager]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('m_pupils.pupils')}
									/>
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{t('m_pupils.pupils')}</h5>
										<div class="buttons butons--target">
											{exporting ? (
												<span
													className="btn btn-sm font-weight-medium"
													style={{
														background:
															'rgba(137, 141, 166, 0.5)',
														color: '#ffffff',
													}}
												>
													Yuklanmoqda...
												</span>
											) : (
												<button
													data-toggle="modal"
													data-target="#modalExcelExportpupils"
													className="btn btn-white btn-sm font-weight-medium"
												>
													<img
														alt=""
														src="/images/teachers/excel.png"
														style={{ width: 25 }}
													/>
												</button>
											)}
											<button
												className="btn btn-white  btn-sm font-weight-medium"
												onClick={resetFilter}
											>
												{t('m_pupils.reset')}
											</button>
											<button
												data-toggle="modal"
												data-target="#modalFilter"
												className="btn btn-white btn-sm font-weight-medium"
											>
												{t('m_pupils.filter')}
												<span class="icon icon-filter fs-100 ml-1"></span>
											</button>
										</div>
									</div>
									{loading ? (
										<Loading size={7} />
									) : (
										<>
											<div className="card card--shadow">
												<div className="card-body p-3">
													<div className="scroll scroll--horizontall">
														<table className="table table-bordered table--style-1 manager-teachers-table">
															<thead>
																<tr>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.full_name'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t('m_pupils.phone')}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t('m_pupils.visit')}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.last_visit'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t('m_pupils.status')}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.completed'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.language'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.position_type'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.position'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t('m_pupils.region')}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t('m_pupils.city')}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t('m_pupils.school')}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.certificate'
																			)}
																		</div>
																	</th>
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.type_of_training'
																			)}
																		</div>
																	</th>
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.certificate_id'
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.certificate_date'
																			)}
																		</div>
																	</th>
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.offline_training_inst'
																			)}
																		</div>
																	</th>
																	{/* additionalll */}
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.ref_number'
																			)}
																		</div>
																	</th>
																	{/* additionalll */}
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner">
																			{t(
																				'm_pupils.reference_link'
																			)}
																		</div>
																	</th>
																	{/* additionalll */}
																</tr>
															</thead>

															<thead>
																<tr>
																	<th>
																		<div
																			className="cell-inner cell-search"
																			style={{
																				minWidth: 200,
																			}}
																		>
																			<div className="cell-search__form">
																				<label for="search_1">
																					<span className="icon icon-search"></span>
																				</label>
																				<input
																					type="text"
																					id="search_1"
																					name="full_name"
																					value={full_name}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false
																						)
																					}
																					placeholder={t(
																						'm_pupils.search'
																					)}
																				/>
																			</div>
																		</div>
																	</th>

																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<label for="search_4">
																					<span className="icon icon-search"></span>
																				</label>
																				<input
																					type="text"
																					id="search_4"
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false
																						)
																					}
																					name="name"
																					value={name}
																					placeholder={t(
																						'm_pupils.search'
																					)}
																					style={{
																						width: 110,
																						paddingRight: 0,
																					}}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<input
																					type="date"
																					style={{
																						width: 120,
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false
																						)
																					}
																					name="created_at"
																					value={
																						created_at
																					}
																					placeholder={t(
																						'm_pupils.search'
																					)}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<button
																					data-toggle="modal"
																					data-target="#modalFilter"
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}
																				>
																					{t(
																						'm_pupils.filter'
																					)}
																					<span class="icon icon-filter fs-100 ml-1"></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name="status"
																					value={status}
																					onChange={e =>
																						changeParams(
																							e
																						)
																					}
																				>
																					<option
																						value={''}
																					>
																						{t(
																							'm_pupils.all'
																						)}
																					</option>
																					<option
																						value={1}
																					>
																						{t(
																							'm_pupils.active'
																						)}
																					</option>
																					<option
																						value={0}
																					>
																						{t(
																							'm_pupils.ne_active'
																						)}
																					</option>
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<label for="search_1">
																					<span className="icon icon-search"></span>
																				</label>
																				<input
																					type="number"
																					style={{
																						height: 30,
																						width: 100,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																						paddingLeft: 30,
																					}}
																					id="search_8"
																					value={
																						hours_count
																					}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false
																						)
																					}
																					name="hours_count"
																					placeholder={t(
																						'm_pupils.search'
																					)}
																				/>
																			</div>
																		</div>
																	</th>

																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name="language"
																					value={language}
																					onChange={e =>
																						changeParams(
																							e
																						)
																					}
																				>
																					<option
																						value={''}
																					>
																						{t(
																							'm_pupils.all'
																						)}
																					</option>
																					{teacher_lan.data
																						? teacher_lan.data.map(
																								(
																									lan,
																									index
																								) => (
																									<option
																										value={
																											lan.id
																										}
																										key={
																											index
																										}
																									>
																										{
																											lan.name
																										}
																									</option>
																								)
																						  )
																						: null}
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e => {
																						changeParams(
																							e,
																							{
																								position_id:
																									'',
																							}
																						);
																					}}
																					value={
																						position_type_id
																					}
																					name="position_type_id"
																				>
																					<option
																						selected
																						value=""
																					>
																						{t(
																							'm_pupils.all'
																						)}
																					</option>
																					{position_Type.map(
																						(
																							type,
																							index
																						) => (
																							<option
																								value={
																									type.id
																								}
																								key={
																									index
																								}
																							>
																								{
																									type.name
																								}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				{position_type_id ? (
																					<select
																						style={{
																							height: 30,
																							border:
																								'none',
																							outline:
																								'none',
																							background:
																								'#fbfbfc',
																							maxWidth: 150,
																						}}
																						onChange={e => {
																							changeParams(
																								e
																							);
																						}}
																						name="position_id"
																						value={
																							position_id
																						}
																					>
																						<option
																							value={''}
																							selected
																						>
																							{t(
																								'm_pupils.all'
																							)}
																						</option>
																						{_.filter(
																							userPosition,
																							i => {
																								return (
																									parseInt(
																										position_type_id
																									) ===
																									i.type_id
																								);
																							}
																						).map(
																							(
																								item,
																								index
																							) => (
																								<option
																									key={
																										index
																									}
																									value={
																										item.id
																									}
																								>
																									{
																										item.name
																									}
																								</option>
																							)
																						)}
																					</select>
																				) : (
																					<button
																						data-toggle="modal"
																						data-target="#modalFilter"
																						style={{
																							border: 0,
																							borderRadius: 3,
																							marginLeft: 10,
																						}}
																					>
																						{t(
																							'm_pupils.filter'
																						)}
																						<span class="icon icon-filter fs-100 ml-1"></span>
																					</button>
																				)}
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e => {
																						changeParams(
																							e,
																							{
																								city: '',
																								school:
																									'',
																							}
																						);
																						props.getCitiesList(
																							e.target
																								.value
																						);
																					}}
																					name="region"
																					value={region}
																				>
																					<option
																						value={''}
																						selected
																					>
																						{t(
																							'm_pupils.region_all'
																						)}
																					</option>
																					{region_list.data
																						? region_list.data.map(
																								(
																									region,
																									index
																								) => (
																									<option
																										key={
																											index
																										}
																										value={
																											region.id
																										}
																									>
																										{
																											region.name
																										}
																									</option>
																								)
																						  )
																						: null}
																				</select>
																			</div>
																		</div>
																	</th>

																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				{region ? (
																					<select
																						style={{
																							height: 30,
																							border:
																								'none',
																							outline:
																								'none',
																							background:
																								'#fbfbfc',
																						}}
																						onChange={e => {
																							changeParams(
																								e,
																								{
																									school:
																										'',
																								}
																							);
																							getSchools(
																								region,
																								e.target
																									.value
																							);
																						}}
																						name="city"
																						value={city}
																					>
																						<option
																							value={''}
																							selected
																						>
																							{t(
																								'm_pupils.city_check'
																							)}
																						</option>
																						{cities_list.data
																							? cities_list.data.map(
																									(
																										item,
																										index
																									) => (
																										<option
																											key={
																												index
																											}
																											value={
																												item.id
																											}
																										>
																											{
																												item.name
																											}
																										</option>
																									)
																							  )
																							: null}
																					</select>
																				) : (
																					<button
																						data-toggle="modal"
																						data-target="#modalFilter"
																						style={{
																							border: 0,
																							borderRadius: 3,
																							marginLeft: 10,
																						}}
																					>
																						{t(
																							'm_pupils.filter'
																						)}
																						<span class="icon icon-filter fs-100 ml-1"></span>
																					</button>
																				)}
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			{city &&
																			findIsSchoolID.is_School ==
																				1 ? (
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e => {
																						changeParams(
																							e
																						);
																					}}
																					name="school"
																					value={school}
																				>
																					<option
																						value={''}
																						selected
																					>
																						{t(
																							'm_pupils.all'
																						)}
																					</option>
																					{schools
																						? schools.map(
																								(
																									item,
																									index
																								) => (
																									<option
																										key={
																											index
																										}
																										value={
																											item.id
																										}
																									>
																										{
																											item.name
																										}
																									</option>
																								)
																						  )
																						: null}
																				</select>
																			) : (
																				<button
																					data-toggle="modal"
																					data-target="#modalFilter"
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}
																				>
																					{t(
																						'm_pupils.filter'
																					)}
																					<span class="icon icon-filter fs-100 ml-1"></span>
																				</button>
																			)}
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name="certificate_count"
																					value={
																						certificate_count
																					}
																					onChange={e =>
																						changeParams(
																							e
																						)
																					}
																				>
																					<option
																						value={''}
																					>
																						{t(
																							'm_pupils.all'
																						)}
																					</option>
																					<option
																						value={1}
																					>
																						{t(
																							'm_pupils.has'
																						)}
																					</option>
																					<option
																						value={0}
																					>
																						{t(
																							'm_pupils.no_has'
																						)}
																					</option>
																				</select>
																			</div>
																		</div>
																	</th>
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name="edu_type"
																					value={edu_type}
																					onChange={e =>
																						changeParams(
																							e
																						)
																					}
																				>
																					<option
																						value={3}
																					>
																						{t(
																							'm_pupils.select_all_Offline_online'
																						)}
																					</option>
																					<option
																						value={2}
																					>
																						{t(
																							'm_pupils.select_offline'
																						)}
																					</option>
																					<option
																						value={1}
																					>
																						{t(
																							'm_pupils.select_online'
																						)}
																					</option>
																				</select>
																			</div>
																		</div>
																	</th>
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<label for="search_1">
																					<span className="icon icon-search"></span>
																				</label>
																				<input
																					type="number"
																					id="search_15"
																					name="certificate_id"
																					style={{
																						height: 30,
																						width: 100,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																						paddingLeft: 30,
																					}}
																					value={
																						certificate_id
																					}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false
																						)
																					}
																					placeholder={t(
																						'm_pupils.search'
																					)}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<button
																					data-toggle="modal"
																					data-target="#modalFilter"
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}
																				>
																					{t(
																						'm_pupils.filter'
																					)}
																					<span class="icon icon-filter fs-100 ml-1"></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<select
																					style={{
																						width: '100%',
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name="edu_institution_id"
																					value={
																						edu_institution_id
																					}
																					onChange={e => {
																						changeParams(
																							e
																						);
																						getEduGruops(
																							e.target
																								.value
																						);
																					}}
																				>
																					<option
																						value={''}
																						selected
																					>
																						{t(
																							'm_pupils.all'
																						)}
																					</option>
																					{edus.map(
																						(
																							item,
																							index
																						) => (
																							<option
																								key={
																									index
																								}
																								value={
																									item.id
																								}
																							>
																								{
																									item.name
																								}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																		</div>
																	</th>
																	{/* additionalll */}
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<label for="search_1">
																					<span className="icon icon-search"></span>
																				</label>
																				<input
																					type="number"
																					id="search_15"
																					name="number"
																					style={{
																						height: 30,
																						width: '100%',
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																						paddingLeft: 30,
																					}}
																					value={number}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false
																						)
																					}
																					placeholder={t(
																						'm_pupils.search'
																					)}
																				/>
																			</div>
																		</div>
																	</th>
																	{/* additionalll */}
																	{/* additionalll */}
																	<th>
																		<div className="cell-inner cell-search">
																			<div className="cell-search__form">
																				<button
																					data-toggle="modal"
																					data-target="#modalFilter"
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}
																				>
																					{t(
																						'm_pupils.filter'
																					)}
																					<span class="icon icon-filter fs-100 ml-1"></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	{/* additionalll */}
																</tr>
															</thead>
															{search_loading ? null : (
																<>
																	{pupils.data &&
																	pupils.data.length > 0 ? (
																		<tbody>
																			{pupils.data.map(
																				(pupil, index) => (
																					<tr key={index}>
																						<td>
																							<Link
																								to={
																									'/manager/pupils/view/' +
																									pupil.id
																								}
																								className="cell-inner"
																								style={{
																									width: 225,
																									whiteSpace:
																										'normal',
																								}}
																							>
																								{pupil.full_name
																									? pupil.full_name
																									: 'User Id ' +
																									  pupil.id}
																							</Link>
																						</td>

																						<td>
																							<div className="cell-inner">
																								{
																									pupil.name
																								}
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								<Moment format="HH:mm DD.MM.YYYY">
																									{
																										pupil.created_at
																									}
																								</Moment>
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								<Moment format="HH:mm DD.MM.YYYY">
																									{
																										pupil.last_online_at
																									}
																								</Moment>
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								{pupil.status ===
																								1
																									? t(
																											'm_pupils.active'
																									  )
																									: t(
																											'm_pupils.ne_active'
																									  )}
																							</div>
																						</td>
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									minWidth: 100,
																								}}
																							>
																								{pupil.hours_count
																									? pupil.hours_count
																									: 0}
																								{t(
																									'm_pupils.h'
																								)}
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								{pupil.language
																									? pupil
																											.language
																											.name
																									: ''}
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								{pupil.position_type
																									? pupil
																											.position_type
																											.type
																											.name
																									: ''}
																							</div>
																						</td>
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									minWidth: 150,
																								}}
																							>
																								{pupil.position_type
																									? pupil
																											.position_type
																											.name
																									: ''}
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								{pupil.region
																									? pupil
																											.region
																											.name
																									: ''}
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								{pupil.city
																									? pupil
																											.city
																											.name
																									: ''}
																							</div>
																						</td>
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									minWidth: 110,
																								}}
																							>
																								{pupil.school
																									? pupil
																											.school
																											.name
																									: ''}
																							</div>
																						</td>
																						<td>
																							<div className="cell-inner">
																								{pupil.certificate &&
																								pupil
																									.certificate
																									.length &&
																								pupil
																									.certificate[0] &&
																								pupil
																									.certificate[0]
																									.is_view ===
																									1 ? (
																									<button
																										type="button"
																										className="btn btn-primary btn-xs"
																										onClick={() =>
																											setCertificate(
																												pupil
																													.certificate[0]
																											)
																										}
																										data-toggle="modal"
																										data-target="#modalCertificate_1"
																									>
																										{t(
																											'm_pupils.certificate'
																										)}
																									</button>
																								) : (
																									<button
																										type="button"
																										className="btn btn-secondary btn-xs"
																									>
																										{t(
																											'm_pupils.certificate'
																										)}
																									</button>
																								)}
																							</div>
																						</td>
																						{/* additionalll */}
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									whiteSpace:
																										'nowrap',
																								}}
																							>
																								{pupil.certificate &&
																								pupil
																									.certificate
																									.length >
																									0 ? (
																									<>
																										{pupil.certificate.map(
																											(
																												item,
																												index
																											) => {
																												return (
																													<React.Fragment
																														key={
																															index
																														}
																													>
																														{item?.reference_id ===
																														null
																															? ' Online '
																															: ' Offline '}
																													</React.Fragment>
																												);
																											}
																										)}
																									</>
																								) : null}
																							</div>
																						</td>
																						{/* additionalll */}
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									whiteSpace:
																										'nowrap',
																								}}
																							>
																								{_.get(
																									pupil,
																									'certificate[0].id',
																									false
																								)
																									? 'UTK-' +
																									  _.get(
																											pupil,
																											'region.code',
																											''
																									  ) +
																									  '-' +
																									  '0000000'.substr(
																											_.get(
																												pupil,
																												'certificate[0].id',
																												''
																											).toString()
																												.length
																									  ) +
																									  _.get(
																											pupil,
																											'certificate[0].id',
																											''
																									  )
																									: ''}
																							</div>
																						</td>
																						<td>
																							{pupil.certificate &&
																							pupil
																								.certificate
																								.length &&
																							pupil
																								.certificate[0] &&
																							pupil
																								.certificate[0]
																								.is_view ===
																								1 ? (
																								<div className="cell-inner">
																									<Moment format="HH:mm DD.MM.YYYY">
																										{
																											pupil
																												.certificate[0]
																												.created_at
																										}
																									</Moment>
																								</div>
																							) : (
																								''
																							)}
																						</td>
																						{/* additionalll */}
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									whiteSpace:
																										'nowrap',
																								}}
																							>
																								{pupil.offline_user &&
																								pupil
																									.offline_user
																									.length >
																									0 ? (
																									<>
																										{pupil.offline_user.map(
																											(
																												item,
																												index
																											) => {
																												return (
																													<React.Fragment
																														key={
																															index
																														}
																													>
																														{
																															item
																																?.group_education
																																?.edu_institution
																																?.name
																														}{' '}
																													</React.Fragment>
																												);
																											}
																										)}
																									</>
																								) : null}
																							</div>
																						</td>
																						{/* additionalll */}
																						{/* additionalll */}
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									whiteSpace:
																										'nowrap',
																								}}
																							>
																								{pupil.offline_user &&
																								pupil
																									.offline_user
																									.length >
																									0 ? (
																									<>
																										{pupil.offline_user.map(
																											(
																												item,
																												index
																											) => {
																												return (
																													<React.Fragment
																														key={
																															index
																														}
																													>
																														{
																															item
																																?.reference
																																?.number
																														}{' '}
																													</React.Fragment>
																												);
																											}
																										)}
																									</>
																								) : null}
																							</div>
																						</td>
																						{/* additionalll */}
																						{/* additionalll */}
																						<td>
																							<div
																								className="cell-inner"
																								style={{
																									whiteSpace:
																										'nowrap',
																								}}
																							>
																								{pupil.offline_user &&
																								pupil
																									.offline_user
																									.length >
																									0 ? (
																									<>
																										{pupil.offline_user.map(
																											(
																												item,
																												index
																											) => {
																												return (
																													<React.Fragment
																														key={
																															index
																														}
																													>
																														<a
																															href={
																																FILE_URL +
																																item
																																	?.reference
																																	?.pdf
																															}
																															target="_blank"
																															className="btn btn-primary btn-xs mb-1"
																														>
																															{t(
																																'm_pupils.reference_link'
																															)}
																														</a>{' '}
																													</React.Fragment>
																												);
																											}
																										)}
																									</>
																								) : null}
																							</div>
																						</td>
																						{/* additionalll */}
																					</tr>
																				)
																			)}
																		</tbody>
																	) : null}{' '}
																</>
															)}
														</table>
													</div>
													{search_loading ? (
														<div>
															<Loading size={7} />
														</div>
													) : pupils.data &&
													  pupils.data.length > 0 ? null : (
														<>
															<div
																style={{
																	width: '100%',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<NoData
																	text={t('m_pupils.no_data')}
																/>
															</div>
														</>
													)}
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-6 p-0">
													<div
														className="mt-4"
														style={{ textAlign: 'center' }}
													>
														{(page - 1) * 20 + 1} -{' '}
														{page * 20 > pupils.total
															? pupils.total
															: page * 20}{' '}
														/ {t('m_pupils.all_p')}{' '}
														{pupils.total ? pupils.total : 0}{' '}
														{t('m_pupils.ta')}
													</div>
												</div>
												<div className="col-12 col-md-6 p-0">
													<Pagination
														total={
															pupils.total ? pupils.total : 20
														}
														count={20}
														active={parseInt(page)}
														link="/manager/pupils/list/"
														search_params={
															'?' + params.substring(1)
														}
													/>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div
				className="modal fade"
				id="modalFilter"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalFilterTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{t('m_pupils.filter')}</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group mb-3">
								<label for="">{t('m_pupils.year')}</label>
								<select
									name="year"
									className="form-control custom-select"
									onChange={e => modalFilter(e)}
									value={year}
								>
									<option value={''}>{t('m_pupils.all')}</option>
									{(function () {
										let l = [];
										for (
											let y = parseInt(new Date().getFullYear());
											y >= 2020;
											y--
										) {
											l.push(
												<option value={y} key={y}>
													{y} {t('m_teacher.year')}
												</option>
											);
										}
										return l;
									})()}
								</select>
							</div>
							<div className="form-group mb-3">
								<label for="">{t('m_pupils.position_type')}</label>
								<select
									value={position_type_id}
									name="position_type_id"
									className="form-control custom-select"
									onChange={e => {
										setSearch({
											...search,
											position_type_id: e.target.value,
											position_id: '',
										});
									}}
								>
									<option selected value="">
										{t('m_pupils.all')}
									</option>
									{position_Type.map((type, index) => (
										<option value={type.id} key={index}>
											{type.name}
										</option>
									))}
								</select>
							</div>
							{position_type_id ? (
								<div className="form-group mb-3">
									<label for="">{t('m_pupils.position')}</label>
									<select
										value={position_id}
										name="position_id"
										className="form-control custom-select"
										onChange={e => modalFilter(e)}
									>
										<option selected value="">
											{t('m_pupils.all')}
										</option>
										{_.filter(userPosition, i => {
											return (
												parseInt(position_type_id) === i.type_id
											);
										}).map((item, index) => (
											<option value={item.id} key={index}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							) : null}
							<div className="form-group mb-5">
								<label for="">{t('m_pupils.region')}</label>
								<select
									className="form-control custom-select"
									onChange={e => {
										setSearch({
											...search,
											region: e.target.value,
											city: '',
											school: '',
										});
										props.getCitiesList(e.target.value);
									}}
									name="region"
									value={region}
								>
									<option value={''} selected>
										{t('m_pupils.region_check')}
									</option>
									{region_list.data
										? region_list.data.map((region, index) => (
												<option key={index} value={region.id}>
													{region.name}
												</option>
										  ))
										: null}
								</select>
							</div>
							{region ? (
								<>
									<div className="form-group mb-5">
										<label for="">{t('m_pupils.city')}</label>
										<select
											className="form-control custom-select"
											onChange={e => {
												setSearch({
													...search,
													city: e.target.value,
													1: '',
												});
												getSchools(region, e.target.value);
											}}
											name="city"
											value={city}
										>
											<option value={''} selected>
												{t('m_pupils.city_check')}
											</option>
											{cities_list.data
												? cities_list.data.map((item, index) => (
														<option key={index} value={item.id}>
															{item.name}
														</option>
												  ))
												: null}
										</select>
									</div>
								</>
							) : null}

							{_.isEmpty(findIsSchoolID) ? null : (
								<>
									{findIsSchoolID.is_School == 1 ? (
										<div className="form-group mb-5">
											<label for="">
												{t('m_pupils.is_specialized')}
											</label>
											<select
												className="form-control custom-select"
												onChange={e => {
													setSearch({
														...search,
														is_specialized: e.target.value,
														school_type: '',
													});
												}}
												name="is_specialized"
												value={is_specialized}
											>
												<option value={''} selected>
													{t('m_pupils.all')}
												</option>
												<option value="1">
													{t('m_pupils.specialized')}
												</option>
												<option value="0">
													{t('m_pupils.no_specialized')}
												</option>
											</select>
										</div>
									) : null}
								</>
							)}

							{is_specialized === '1' ? (
								<div className="form-group mb-5">
									<label for="">{t('m_pupils.school_type')}</label>
									<select
										className="form-control custom-select"
										onChange={e => {
											setSearch({
												...search,
												school_type: e.target.value,
											});
										}}
										name="school_type"
										value={school_type}
									>
										<option value={''} selected>
											{t('m_pupils.all')}
										</option>
										{schoolTypes.map((item, index) => (
											<option key={index} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							) : null}
							{city && findIsSchoolID.is_School == 1 ? (
								<div className="form-group mb-5">
									<label for="">{t('m_pupils.school')}</label>
									<select
										className="form-control custom-select"
										onChange={e => modalFilter(e)}
										name="school"
										value={school}
									>
										<option value={''} selected>
											{t('m_pupils.all')}
										</option>
										{schools
											? schools.map((item, index) => (
													<option key={index} value={item.id}>
														{item.name}
													</option>
											  ))
											: null}
									</select>
								</div>
							) : null}

							<div className="form-group mb-3">
								<label for="">{t('m_pupils.last_visit')}</label>
								<input
									type="date"
									className="form-control w-50 d-inline mb-1"
									onChange={e => modalFilter(e)}
									name="last_online_at_from"
									value={last_online_at_from}
								/>{' '}
								<span>{t('m_pupils.to')}</span>
								<input
									type="date"
									className="form-control w-50 d-inline"
									onChange={e => modalFilter(e)}
									name="last_online_at_to"
									value={last_online_at_to}
								/>{' '}
								<span>{t('m_pupils.from')}</span>
							</div>
							<div className="form-group mb-3">
								<label for="">{t('m_pupils.certificate_date')}</label>
								<input
									type="date"
									className="form-control w-50 d-inline mb-1"
									onChange={e => modalFilter(e)}
									name="certificate_date_from"
									value={certificate_date_from}
								/>{' '}
								<span>{t('m_pupils.to')}</span>
								<input
									type="date"
									className="form-control w-50 d-inline"
									onChange={e => modalFilter(e)}
									name="certificate_date_to"
									value={certificate_date_to}
								/>{' '}
								<span>{t('m_pupils.from')}</span>
							</div>
							{search_loading ? (
								<div>
									<Loading size={7} />
								</div>
							) : (
								<button
									type="button"
									className="btn btn-primary w-100"
									onClick={() => setIs_Search(!is_Search)}
								>
									{t('m_pupils.filter')}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<CertificateModal certificate={certificate} />
			<ExcelExportModal
				get_type="excel_api"
				getLoadExcel={getLoadExcel}
				parent="pupils"
				link={
					'/api/v1/users/export/export_new' +
					window.location.search +
					(window.location.search ? '&token=' : '?token=') +
					getToken() +
					'&lan=' +
					language_l
				}
				cols={[
					{
						name: 'full_name',
						title: t('m_pupils.full_name'),
					},
					{
						name: 'name',
						title: t('m_pupils.phone'),
					},
					{
						name: 'created_at',
						title: t('m_pupils.visit'),
					},
					{
						name: 'last_online_at',
						title: t('m_pupils.last_visit'),
					},
					{
						name: 'status',
						title: t('m_pupils.status'),
					},
					{
						name: 'hours_count',
						title: t('m_pupils.completed'),
					},
					{
						name: 'language',
						title: t('m_pupils.language'),
					},
					{
						name: 'position_type',
						title: t('m_pupils.position_type'),
					},
					{
						name: 'position',
						title: t('m_pupils.position'),
					},
					{
						name: 'region',
						title: t('m_pupils.region'),
					},
					{
						name: 'city',
						title: t('m_pupils.city'),
					},
					{
						name: 'school',
						title: t('m_pupils.school'),
					},
					{
						name: 'certificate_id',
						title: t('m_pupils.certificate_id'),
					},
					{
						name: 'certificate_date',
						title: t('m_pupils.certificate_date'),
					},
				]}
			/>
		</Layout>
	);
};
export default connect(
	state => ({
		pupils: state.pupils,
		pupils_status: state.pupils_status,
		region_list: state.region_list,
		cities_list: state.cities_list,
		teacher_lan: state.teacher_lan,
	}),
	dispatch => ({
		getTeacherLanguages: () => dispatch(getTeacherLanguages()),
		getPupils: (page, search_p) => dispatch(getPupils(page, search_p)),
		getRegionList: () => dispatch(getRegionList()),
		getCitiesList: id => dispatch(getCitiesList(id)),
		excelReady: obj => dispatch({ type: 'EXCEL_READY', payload: obj }),
	})
)(ManagerPupils);
