import React from 'react';
import { useTranslation } from 'react-i18next';

const AddItem = props => {
	const { t } = useTranslation();
	const { last_id, surveys, setLastId, setSurveys } = props;
	const survey_types = [
		{
			name: t('survey.input'),
			element: {
				type: 'input',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				inputType: 'text',
			},
		},
		{
			name: t('survey.radiogroup'),
			element: {
				type: 'radiogroup',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				choices: [
					{
						id: 1,
						label_uz: 'item_uz_1',
						label_ru: 'item_ru_1',
					},
					{
						id: 2,
						label_uz: 'item_uz_2',
						label_ru: 'item_ru_2',
					},
					{
						id: 3,
						label_uz: 'item_uz_3',
						label_ru: 'item_ru_3',
					},
				],
			},
		},
		{
			name: t('survey.checkboxgroup'),
			element: {
				type: 'checkboxgroup',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				choices: [
					{
						id: 1,
						label_uz: 'item_uz_1',
						label_ru: 'item_ru_1',
					},
					{
						id: 2,
						label_uz: 'item_uz_2',
						label_ru: 'item_ru_2',
					},
					{
						id: 3,
						label_uz: 'item_uz_3',
						label_ru: 'item_ru_3',
					},
				],
			},
		},
		{
			name: t('survey.select_t'),
			element: {
				type: 'select',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				selectType: 'single',
				choices: [
					{
						id: 1,
						label_uz: 'item_uz_1',
						label_ru: 'item_ru_1',
					},
					{
						id: 2,
						label_uz: 'item_uz_2',
						label_ru: 'item_ru_2',
					},
					{
						id: 3,
						label_uz: 'item_uz_3',
						label_ru: 'item_ru_3',
					},
				],
			},
		},
		{
			name: t('survey.rating'),
			element: {
				type: 'rating',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				rateMax: 5,
			},
		},
		{
			name: t('survey.sorttable'),
			element: {
				type: 'sorttable',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				choices: [
					{
						id: 1,
						label_uz: 'item_uz_1',
						label_ru: 'item_ru_1',
					},
					{
						id: 2,
						label_uz: 'item_uz_2',
						label_ru: 'item_ru_2',
					},
					{
						id: 3,
						label_uz: 'item_uz_3',
						label_ru: 'item_ru_3',
					},
				],
			},
		},
		{
			name: t('survey.boolean'),
			element: {
				type: 'boolean',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
			},
		},
		{
			name: t('survey.matrix_radio'),
			element: {
				type: 'matrix_radio',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				cols: [
					{
						id: 1,
						col_uz: 'col_uz_1',
						col_ru: 'col_ru_1',
					},
					{
						id: 2,
						col_uz: 'col_uz_2',
						col_ru: 'col_ru_2',
					},
				],
				rows: [
					{
						id: 1,
						row_uz: 'row_uz_1',
						row_ru: 'row_ru_1',
					},
					{
						id: 2,
						row_uz: 'row_uz_2',
						row_ru: 'row_ru_2',
					},
					{
						id: 3,
						row_uz: 'row_uz_3',
						row_ru: 'row_ru_3',
					},
				],
			},
		},
		{
			name: t('survey.matrix_checkbox'),
			element: {
				type: 'matrix_checkbox',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				cols: [
					{
						id: 1,
						col_uz: 'col_uz_1',
						col_ru: 'col_ru_1',
					},
					{
						id: 2,
						col_uz: 'col_uz_2',
						col_ru: 'col_ru_2',
					},
				],
				rows: [
					{
						id: 1,
						row_uz: 'row_uz_1',
						row_ru: 'row_ru_1',
					},
					{
						id: 2,
						row_uz: 'row_uz_2',
						row_ru: 'row_ru_2',
					},
					{
						id: 3,
						row_uz: 'row_uz_3',
						row_ru: 'row_ru_3',
					},
				],
			},
		},
		{
			name: t('survey.multiple_input'),
			element: {
				type: 'multiple_input',
				name_uz: '',
				name_ru: '',
				logic: [],
				error_uz: '',
				error_ru: '',
				isRequired: true,
				items: [
					{
						id: 1,
						item_uz: 'item_uz_1',
						item_ru: 'item_ru_1',
						inputType: 'text',
					},
					{
						id: 2,
						item_uz: 'item_uz_2',
						item_ru: 'item_ru_2',
						inputType: 'text',
					},
				],
				inputType: 'text',
			},
		},
	];
	return (
		<div className="right-sidebar-menu" style={{ background: '#f4f4f4' }}>
			<h5 className="mb-3">{t('survey.add')}</h5>
			<div className="course-single__menu">
				{/* <h6>name</h6> */}
				<ul>
					{survey_types.map((item, index) => (
						<li
							key={index}
							onClick={() => {
								setSurveys([...surveys, { ...item.element, id: last_id + 1 }]);
								setLastId(last_id + 1);
							}}>
							<strong>
								<img
									src={`/images/survey/${item.element.type}.png`}
									alt="survey"
									style={{ width: 20, height: 20, marginRight: 10 }}
								/>
								{item.name}
							</strong>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
export default AddItem;
