import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getDiagnosticTest } from '../actions';
import history from '../history';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import Pagination from './sections/Pagination';
import DiagnosticsTestQuestionChange from './sections/DiagnosticsTestQuestionChange';
import DiagnosticsTestQuestionAdd from './sections/DiagnosticsTestQuestionAdd';
import DiagnosticsTestAnswerChange from './sections/DiagnosticsTestAnswerChange';
import DiagnosticsTestExplanChange from './sections/DiagnosticsTestExplanChange';
import DiagnosticsTestAnswerAdd from './sections/DiagnosticsTestAnswerAdd';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const ManagerDiagnosticsTest = props => { 
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [language, setLanguage] = useState('');
	const [category_id, setCategoryId] = useState('');
	const [add, setAdd] = useState(false);
	const {diagnostics_test_status, diagnostics_test} = props;
	const { page } = props.match.params;
	const [positin_list, setPositin_list] = useState([]);
	
	useEffect(()=>{
		const search_D = props?.location?.search; 
        const params = new URLSearchParams(search_D);
		console.log('params==>', params, search_D);
        const lan = params.get('lan');
        const category = params.get('category');
		const positions =  params.get('positions').split(",");
		setPositin_list(positions);
		// console.log('positions===>', positions);
		if(lan&&category&&positions.length){
			setLanguage(lan);
			setCategoryId(category);
			props.getDiagnosticTest(category, lan, page, positions);
		}else{
			history.push('/manager/diagnostics');
		}
	},[page]);

	useEffect(() => {
        if(diagnostics_test_status.status === 'success'){
            setLoading(false);
        }
        if(diagnostics_test_status.status === 'error'){
            setLoading(false);
        }
    }, [diagnostics_test_status]);

	useEffect(()=>{
		props.beginDiagnosticStatus();
	},[diagnostics_test]);	

	return (
		<Layout>
			<CheckRole roles={ [ roles.manager ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation is_dash={true} is_btn={true} 
										active={t('dash_side_bar.diagnostics-test')} 
										navs={[
											{
												'title': t('diagnostics.title'),
												'link': '/manager/diagnostics'
											}
										]}
									/>
									<div className="dashboard-title dashboard-title--has-buttons">
										<h5>{t('dash_side_bar.diagnostics-test')}</h5>
										<div className="buttons">
											<span
												className="btn btn-primary  btn-sm font-weight-medium"
												onClick={()=>setAdd(true)}
                                            >
												<span className="icon icon-plus" style={{color: '#fff'}} ></span> { t('diagnostics.add') }
											</span>
											{/* <span
												data-toggle="modal"
												data-target="#modalFilter"
												className="btn btn-white btn-sm font-weight-medium">
												{t('m_pupils.filter')}
												<span className="icon icon-filter fs-100 ml-1"></span>
											</span> */}
										</div>
									</div>
									<div style={add?{}:{display: 'none'}}>
										<DiagnosticsTestQuestionAdd
											language={language}
											category_id={category_id}
											close={()=>setAdd(false)}
											positions={positin_list}
										/>
									</div>
									<div>
									{loading ? (
										<Loading />
									) : diagnostics_test.data&&diagnostics_test.data.length>0?
										<>
											{
												diagnostics_test.data.map((question, index)=>(
													<div className="card card--shadow ">
            											<div className="card-body" style={{margin: 0, padding: 0}}>
														<DiagnosticsTestQuestionChange question_data={question} index={index+(page-1)*8 }/>
														<div>
														{
															question.answers.map((answer, index_a)=>(
																<div key={index_a} >
																	<DiagnosticsTestAnswerChange answer_data={answer} />
																</div>
															))
														}
														</div>
														<div>
															<DiagnosticsTestAnswerAdd question_id={ question.id } />
															<DiagnosticsTestExplanChange question_data={question}/>
															
														</div>
													</div></div>
												))
											}
											<div className="row">
												<div className="col-12 col-md-6 p-0">
													<div className="mt-4" style={{textAlign: 'center'}}> 
														{(page-1)*20+1} - {page*8>diagnostics_test.total?diagnostics_test.total:page*8} / {t('m_pupils.all_p')} {diagnostics_test.total ? diagnostics_test.total : 0} {t('m_pupils.ta')}
													</div>
												</div>
												<div className="col-12 col-md-6 p-0">
													<Pagination
														total={diagnostics_test.total}
														count={8}
														active={parseInt(page)}
														link="/manager/diagnostics/test/"
														search_params={props?.location?.search}
													/>
												</div>
											</div>
											
										</>
										:<NoData text={ t('diagnostics.no_data') } />			
									}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div
				className="modal fade"
				id="modalFilter"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalFilterTitle"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{t('m_pupils.filter')}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group mb-3">
								<label for="">{t('m_pupils.status')}</label>
								<select
									name="status"
									className="form-control custom-select"
                                    >
									<option selected value="">
										{t('m_pupils.all')}
									</option>
									<option value={1}>{t('m_pupils.active')}</option>
									<option value={0}>{t('m_pupils.ne_active')}</option>
								</select>
							</div>
							<div className="form-group mb-3">
								<label for="">{t('m_pupils.year')}</label>
								<select
									name="year"
									className="form-control custom-select"
								>
									<option value={''}>{t('m_pupils.all')}</option>
									<option value={2022}>2022{t('m_pupils.y')}</option>
									<option value={2021}>2021{t('m_pupils.y')}</option>
									<option value={2020}>2020{t('m_pupils.y')}</option>
								</select>
							</div>
							<button type="button" className="btn btn-primary w-100">
								{t('m_pupils.filter')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default connect(
	state => ({
		diagnostics_test_status: state.diagnostics_test_status,
		diagnostics_test: state.diagnostics_test,
	}),
	dispatch => ({
		getDiagnosticTest: (cat, lan, page_list, positions) => dispatch(getDiagnosticTest(cat, lan, page_list, positions)),
		beginDiagnosticStatus: ()=>dispatch({type: 'DIAGNOSTIC_BEGIN_STATUS'})
	}),
)(ManagerDiagnosticsTest);
