import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import { getInstance } from '../helpers/httpClient';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const ManagerCourseResults = props => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const { slug, name, id } = props.match.params;
	const [results, setResults] = useState([]);
	useEffect(() => {
		getInstance()
			.get(`/api/v1/course/${slug}/results?user_id=${id}`)
			.then(response => {
				const { status, data } = response.data;
				if (status) {
					if (data) {
						setResults(data);
					} else {
						setResults([]);
					}
				} else {
					setResults([]);
				}
			})
			.catch(error => setResults([]));
		setLoading(false);
	}, []);
	return (
		<>
			<Layout>
			<CheckRole roles={ [ roles.manager ] } />
				<main className="main">
					<section className="section section-dashboard py-0">
						<div className="container">
							<div className="row">
								<DashSideBar />
								<div className="col-md-12 col-lg-9 col-xl-10">
									<div className="dashboard-right">
										<Navigation
											is_dash={true}
											is_btn={true}
											navs={[{ link: '/dashboard/profile', title: t('profile-course.title') }]}
											active={name}
										/>
										<div className="dashboard-title">
											<h5>{name}</h5>
										</div>
										{loading ? (
											<Loading />
										) : (
											<div className="card" style={{ background: 'transparent' }}>
												<div className="card-body" style={{ padding: 0 }}>
													<div className="row" style={{ margin: 0 }}>
														{results.length ? (
															results.map((item, index) => {
																const { section_name, score, passings } = item;
																return (
																	<div
																		className="col-12 col-md-6"
																		key={index}
																		style={{
																			height: 150,
																			padding: 15,
																		}}>
																		<div
																			style={{
																				background: 'white',
																				padding: 15,
																				borderRadius: 12,
																				height: 122,
																				boxShadow:
																					'0px 7.2973px 29.1892px rgba(13, 46, 105, 0.05)',
																			}}>
																			<h3
																				style={{
																					color: '#0D2E69',
																					fontSize: 16,
																					height: 50,
																				}}>
																				{section_name}
																			</h3>
																			{passings ? (
																				<div
																					style={{
																						height: 40,
																						display: 'flex',
																						justifyContent: 'center',
																						alignItems: 'center',
																					}}>
																					<div style={{ width: '100%' }}>
																						<h3
																							style={{
																								fontSize: 17,
																								textAlign: 'right',
																							}}>
																							{parseInt(
																								(passings.score /
																									score) *
																									100,
																							)}
																							/100
																						</h3>
																						<div
																							style={{
																								background:
																									'rgba(137, 141, 166, 0.2)',
																								height: 8,
																								width: '100%',
																								borderRadius: 4,
																								overflow: 'hidden',
																							}}>
																							<div
																								style={{
																									background:
																										'#26CAAC',
																									height: 8,
																									borderRadius: 4,
																									width: `${parseInt(
																										(passings.score /
																											score) *
																											100,
																									)}%`,
																								}}></div>
																						</div>
																					</div>
																				</div>
																			) : (
																				<h3 style={{ fontSize: 16 }}>
																					{t('profile-course.not-tasks')}
																				</h3>
																			)}
																		</div>
																	</div>
																);
															})
														) : (
															<div style={{ width: '100%' }}>
																<h3
																	style={{
																		fontWeight: 700,
																		textAlign: 'center',
																	}}>
																	{t('profile-course.not-course')}
																</h3>
															</div>
														)}
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</Layout>
		</>
	);
};
export default ManagerCourseResults;
