import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import { API_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { Link } from 'react-router-dom';
import { getToken } from '../helpers/tokenStorge';
import ExcelExportModal from './sections/ExcelExportModal';
import _ from 'lodash';

const ManagerTeachers = props => {
	const {  } = props;
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [year, setYear] = useState( parseInt( new Date().getFullYear() ) );
	const [month, setMonth] = useState(  parseInt( new Date().getMonth() )+1 );

	useEffect(()=>{
		getData('')
	}, []);
	const filterData = () => {
		window.$('#modalFilter').modal('hide');
		getData( `?year=${year}&month=${month}` );
	}
	const getData = filter => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/teacher/result${filter}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					if (data) {
						setData(data);
						setLoading(false);
					}
				} else {
					 setLoading(false);
				}
			})
			.catch(error => { setLoading(false); });
	}
	const excel_cols = [
		{
			name: "user_name",
			title: t('m_teacher.teacher')
		},
		{
			name: "active_course",
			title: t('m_teacher.active')
		},
		{
			name: "no_active_course",
			title: t('m_teacher.passive')
		},
		{
			name: "all_course",
			title: t('m_teacher.course_count')+" ("+t('m_teacher.all')+")"
		},
		{
			name: "open_webinar",
			title: t('m_teacher.open_webinar')
		},
		{
			name: "active_video",
			title: t('m_teacher.video')+" ("+t('m_pupils.active')+")"
		},
		{
			name: "active_test",
			title: t('m_teacher.test')+" ("+t('m_pupils.active')+")"
		},
		{
			name: "active_text",
			title: t('m_teacher.text')+" ("+t('m_pupils.active')+")"
		},
		{
			name: "active_webinar",
			title: t('m_teacher.webinar')+" ("+t('m_pupils.active')+")"
		},
		{
			name: "no_active_video",
			title: t('m_teacher.video')+" ("+t('m_pupils.ne_active')+")"
		},
		{
			name: "no_active_test",
			title: t('m_teacher.test')+" ("+t('m_pupils.ne_active')+")"
		},
		{
			name: "no_active_text",
			title: t('m_teacher.text')+" ("+t('m_pupils.ne_active')+")"
		},
		{
			name: "no_active_webinar",
			title: t('m_teacher.webinar')+" ("+t('m_pupils.ne_active')+")"
		},
		{
			name: "all_video",
			title: t('m_teacher.video')+" ("+t('m_teacher.all')+")"
		},
		{
			name: "all_test",
			title: t('m_teacher.test')+" ("+t('m_teacher.all')+")"
		},
		{
			name: "all_text",
			title: t('m_teacher.text')+" ("+t('m_teacher.all')+")"
		},
		{
			name: "all_webinar",
			title: t('m_teacher.webinar')+" ("+t('m_teacher.all')+")"
		}
	];
	return (
		<Layout>
			<CheckRole roles={ [ roles.manager ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation is_dash={true} is_btn={true} active={t('m_teacher.title')} />
									<div className="dashboard-title dashboard-title--has-buttons">
										<h5>{t('m_teacher.title')}</h5>
										<div className="buttons butons--target">
											<button 
												data-toggle="modal"
												data-target="#modalExcelExportTeacherYear"
												className="btn btn-white btn-sm font-weight-medium">
												{t('m_pupils.excel_export')} {t('m_teacher.excel_year')}
											</button>
											
											<button 
												data-toggle="modal"
												data-target="#modalExcelExportTeacherMonth"
												className="btn btn-white btn-sm font-weight-medium">
												{t('m_pupils.excel_export')} {t('m_teacher.excel_month')}
											</button>
											
											{/* <button
												className="btn btn-white btn-sm font-weight-medium"
												onClick={resetFilter}>
												{t('m_courses.reset')}
											</button> */}
											<button
												data-toggle="modal"
												data-target="#modalFilter"
												className="btn btn-white btn-sm font-weight-medium">
												{t('m_pupils.filter')}
												<span class="icon icon-filter fs-100 ml-1"></span>
											</button>
										</div>
									</div>
									{loading? (
										<Loading />
									) : (
										<>
											<div className="card card--shadow">
												<div className="card-body p-3">
													<div className="scroll scroll--horizontall">
														{data.length?
														<table className="table table-bordered table--style-1 manager-teachers-table">
															<thead>
																<tr>
																	<th  rowspan="2" style={{minWidth: 'unset'}}><div className="cell-inner">#</div></th>
																	<th rowspan="2">
																		<div className="cell-inner">
																			{t('m_teacher.teacher')}
																		</div>
																	</th>
																	<th colspan="3">
																		<div className="cell-inner">
																		{t('m_teacher.course_count')}
																		</div>
																	</th>
																	<th rowspan="2">
																		<div className="cell-inner">
																		{t('m_teacher.open_webinar')}
																		</div>
																	</th>
																	<th colspan="4">
																		<div className="cell-inner">
																		{t('m_teacher.active')}
																		</div>
																	</th>
																	<th colspan="4">
																		<div className="cell-inner">
																		{t('m_teacher.passive')}
																		</div>
																	</th>
																	<th colspan="4">
																		<div className="cell-inner">
																		{t('m_teacher.all')}
																		</div>
																	</th>
																</tr>
																<tr>
																	<th  style={{minWidth: 'unset'}}><div className="cell-inner">{t('m_teacher.active')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.passive')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.all')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.text')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.video')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.test')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.webinar')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.text')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.video')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.test')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.webinar')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.text')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.video')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.test')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.webinar')}</div></th>
																</tr>
															</thead>
															<tbody>
																{data.map((teacher, index)=>(
																	<tr key={index}>
																		<td>
																			<div className="cell-inner">
																				{index+1}.
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner">
																				{_.get(teacher, 'user_name', '')}
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner">
																			{_.get(teacher, 'active_course', 0)}  {t('m_teacher.ta')}
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner">
																			{_.get(teacher, 'no_active_course', 0)}  {t('m_teacher.ta')}
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner">
																			{ parseInt(_.get(teacher, 'active_course', 0))+parseInt(_.get(teacher, 'no_active_course', 0)) }  {t('m_teacher.ta')}
																			</div>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/open-webinars/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'open_webinar', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=3&status=1&year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'active_text', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=1&status=1&year=${year}&month=${month}`} className="cell-inner" style={{whiteSpace: 'nowrap'}}>
																			{ parseInt(parseInt(_.get(teacher, 'active_video', 0))/3600)} {t('m_teacher.hours')} { parseInt(parseInt(_.get(teacher, 'active_video', 0))/60)%60} {t('m_teacher.minute')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=2&status=1&year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'active_test', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=4&status=1&year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'active_webinar', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=3&status=0&year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'no_active_text', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=1&status=0&year=${year}&month=${month}`} className="cell-inner" style={{whiteSpace: 'nowrap'}}>
																			{ parseInt(parseInt(_.get(teacher, 'no_active_video', 0))/3600)} {t('m_teacher.hours')} { parseInt(parseInt(_.get(teacher, 'no_active_video', 0))/60)%60} {t('m_teacher.minute')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=2&status=0&year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'no_active_test', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=4&status=0&year=${year}&month=${month}`} className="cell-inner">
																			{_.get(teacher, 'no_active_webinar', 0)}  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=3&year=${year}&month=${month}`} className="cell-inner">
																			{ parseInt( _.get(teacher, 'active_text', 0) ) + parseInt( _.get(teacher, 'no_active_text', 0) )  }  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=1&year=${year}&month=${month}`} className="cell-inner" style={{whiteSpace: 'nowrap'}}>
																			{ parseInt(parseInt( parseInt( _.get(teacher, 'active_video', 0) )/60 + parseInt( _.get(teacher, 'no_active_video', 0) )/60 ) / 60) }  {t('m_teacher.hours')} { parseInt(parseInt( _.get(teacher, 'active_video', 0) )/60 + parseInt( _.get(teacher, 'no_active_video', 0) )/60 ) % 60 }  {t('m_teacher.minute')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=2&year=${year}&month=${month}`} className="cell-inner">
																			{ parseInt( _.get(teacher, 'active_test', 0) ) + parseInt( _.get(teacher, 'no_active_test', 0) ) }  {t('m_teacher.ta')}
																			</Link>
																		</td>
																		<td>
																			<Link to={`/manager/teachers/lessons/${_.get(teacher, 'user_id', 0)}/${_.get(teacher, 'user_name', '')}?lesson_type=4&year=${year}&month=${month}`} className="cell-inner">
																			{ parseInt( _.get(teacher, 'active_webinar', 0) ) + parseInt( _.get(teacher, 'no_active_webinar', 0) ) } {t('m_teacher.ta')}
																			</Link>
																		</td>
																	</tr>
																))}
																<tr>
																	<td colspan="2"><div className="cell-inner"><b>{t('m_teacher.all')}:</b></div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_course', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'no_active_course', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_course', 0))+parseInt(_.get(o, 'no_active_course', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'open_webinar', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_text', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td style={{whiteSpace: 'nowrap'}}><div className="cell-inner">{ parseInt(_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_video', 0))/60 })/60) }   {t('m_teacher.hours')} {parseInt( _.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_video', 0))/60 }) )%60 }   {t('m_teacher.minute')} </div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_test', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_webinar', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'no_active_text', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td style={{whiteSpace: 'nowrap'}}><div className="cell-inner">{ parseInt(_.sumBy(data, (o) => { return  parseInt(_.get(o, 'no_active_video', 0))/60 })/60) }   {t('m_teacher.hours')} {parseInt( _.sumBy(data, (o) => { return  parseInt(_.get(o, 'no_active_video', 0))/60 }) )%60 }   {t('m_teacher.minute')} </div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'no_active_test', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'no_active_webinar', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_text', 0))+parseInt(_.get(o, 'no_active_text', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td style={{whiteSpace: 'nowrap'}}><div className="cell-inner">{ parseInt(_.sumBy(data, (o) => { return  (parseInt(_.get(o, 'active_video', 0))+parseInt(_.get(o, 'no_active_video', 0)))/60 })/60) }   {t('m_teacher.hours')} {parseInt( _.sumBy(data, (o) => { return  (parseInt(_.get(o, 'active_video', 0))+parseInt(_.get(o, 'no_active_video', 0)))/60 }) )%60 }   {t('m_teacher.minute')} </div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_test', 0))+parseInt(_.get(o, 'no_active_test', 0)) })}   {t('m_teacher.ta')}</div></td>
																	<td><div className="cell-inner">{_.sumBy(data, (o) => { return  parseInt(_.get(o, 'active_webinar', 0))+parseInt(_.get(o, 'no_active_webinar', 0)) })}   {t('m_teacher.ta')}</div></td>
																	
																</tr>
															</tbody>
														</table>
														:<NoData text={t('m_teacher.no_data')} />}
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="modal fade" id="modalFilter" tabindex="-1" role="dialog" aria-labelledby="modalFilterTitle"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{t('m_pupils.filter')}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">

							<div className="form-group mb-3">
								<label for="">{t('m_teacher.year')}</label>
								<select
									name="status"
									className="form-control custom-select"
									value={year}
									onChange={e=>setYear(parseInt(e.target.value))}
								>
									{(function () {
										let l = [];
										for (
											let y = parseInt(
												new Date().getFullYear(),
											);
											y >= 2020;
											y--
										) {
											l.push(
												<option value={y} key={y}>
													{y} {t('m_teacher.year')}
												</option>
											);
										}
										return l;
									})()}
								</select>
							</div>

							<div className="form-group mb-3">
								<label for="">{t('m_teacher.month')}</label>
								<select
									name="status"
									className="form-control custom-select"
									value={month}
									onChange={e=>setMonth(parseInt(e.target.value))}
								>
									<option value={1}>{t('m_teacher.month_1')}</option>
									<option value={2}>{t('m_teacher.month_2')}</option>
									<option value={3}>{t('m_teacher.month_3')}</option>
									<option value={4}>{t('m_teacher.month_4')}</option>
									<option value={5}>{t('m_teacher.month_5')}</option>
									<option value={6}>{t('m_teacher.month_6')}</option>
									<option value={7}>{t('m_teacher.month_7')}</option>
									<option value={8}>{t('m_teacher.month_8')}</option>
									<option value={9}>{t('m_teacher.month_9')}</option>
									<option value={10}>{t('m_teacher.month_10')}</option>
									<option value={11}>{t('m_teacher.month_11')}</option>
									<option value={12}>{t('m_teacher.month_12')}</option>
								</select>
							</div>
							
							<button type="button" className="btn btn-primary w-100" onClick={()=>filterData()} >
								{t('m_pupils.filter')}
							</button>
						</div>
					</div>
				</div>
			</div>
			<ExcelExportModal 
				parent="TeacherYear"
				link={ API_URL + '/api/v1/teacher/result/export?year='+year+'&token='+getToken() } 
				cols={excel_cols}
			/>
			<ExcelExportModal 
				parent="TeacherMonth"
				link={ API_URL + '/api/v1/teacher/result/export?year='+year+'&month='+month+'&token='+getToken() }
				cols={excel_cols}
			/>
		</Layout>
	);
};
export default ManagerTeachers;
