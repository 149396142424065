import React, {useState} from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import Survey from './survey-items/Survey';
import { getInstance } from '../../helpers/httpClient';
import { useTranslation } from 'react-i18next';
import { getCourse } from "../../actions";


const LessonSurvey = (props) => {
	const { t } = useTranslation();
    const {course, course_slug, course_lesson, section, getCourseSections} = props;
    const survey = _.get(course_lesson, 'data.survey', '[]')
    const survey_data = JSON.parse( _.get(survey, 'settings', '[]') );
    const last_id = survey_data.length?survey_data[survey_data.length-1].id:0;
    const [complete, setComplete] = useState(false);
    const setResultServey = (d) => { 
        getInstance()
        .post('/api/v1/set-survey-result', {
            survey_id: survey.id, 
            result: JSON.stringify(d),
            section_slug: section,
            lesson_id: _.get(course_lesson, 'data.id', '')
        })
        .then(response => {
            if (response.status === 200) {
                setComplete(true);
                getCourseSections();
                if(course?.data?.type??''==='target'){
                    props.getCourse(course_slug);
                }
            }
        })
        .catch(error => {  } );
    }
    return (
    <div className="container p-0">
        <div className="webinar-details">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 order-md-1">
                            <div className="webinar-details__description">
                                <div>
                                    { (_.get(course_lesson, 'data.survey_passings.result', null)===null && (!complete) )?
                                    <Survey
                                        data={ {
                                            surveys: survey_data,
                                            last_id: last_id,
                                        } }
                                        set_result = {true}
                                        setResultServey={d=>setResultServey(d)}
                                    />
                                    :<div className="card card--shodow">
                                        <div className="card-body">
                                            <div style={{textAlign: 'center', fontSize: 96, color: '#26caac' }}> <span class="icon icon-check-o"></span> </div>
                                            <div style={{textAlign: 'center', fontSize: 18}}>
                                                {complete?t('course_view.survey_completed'):t('course_view.survey_done')}
                                                <br/>
                                                {t('course_view.survey_next_lesson')}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug)=>dispatch(getCourse(slug))
    })
)(LessonSurvey);