const  Map = {
    name: "Farg'ona viloyati",
    slug: 'fargana_region',
    viewBox: "0 0 402 260",
    map_data: [
        {
            city_name: "Beshariq tumani",
            slug: 'beshariq-tumani',
            data: "M60.0164 178.354L68.2131 177.698V169.83C68.2131 167.208 66.5738 159.996 66.5738 155.078C66.5738 150.161 73.1311 151.8 73.1311 145.243C73.1311 138.687 73.1311 135.409 76.4098 132.13C79.6885 128.852 79.6885 126.885 81.3279 120.329C82.9672 113.772 76.4098 113.772 74.7705 107.543C73.1311 101.315 71.4918 101.315 68.2131 96.0695C64.9344 90.8243 61.6557 88.5296 58.377 85.2513C55.7541 82.6287 47.8853 82.6287 44.2787 82.9565L41.9836 85.2513L39.0329 86.5626L36.41 87.2182L33.7869 88.5296L31.164 91.1522L26.5739 99.02L24.6066 101.315L22.6395 102.298L9.52471 105.249L3.9509 108.855L1 113.772L1.32799 119.017L1.65578 120.329L1.98377 120.984L4.27869 124.59L4.60668 124.918L6.24602 126.885L6.9018 127.869L4.27869 136.72L13.787 140.982L17.7214 141.965L21.9837 141.637L25.5902 140.654L29.1968 140.326L33.4591 141.965L38.3771 150.161L41 161.963L44.2787 172.453L52.1477 178.026L60.0164 178.354Z",
            textX: 10,
            textY: 48
        },
        {
            city_name: "Furqat tumani",
            slug: 'furqat-tumani',
            data: "M58.3744 85.2512C55.7515 82.6285 47.8827 82.6285 44.2761 82.9563L51.1615 74.7604L62.6369 65.2534C64.7333 65.5155 68.3208 70.608 69.8525 73.1215C70.9454 72.0288 74.1147 69.5154 78.0492 68.2041C82.9672 66.565 84.6066 73.1215 84.6066 78.0389C84.6066 82.9563 78.0492 79.6781 74.7705 82.9563C71.4918 86.2346 86.6781 85.2512 90.6347 85.2512C94.5914 85.2512 96.082 87.8737 97.7213 89.5128C99.3606 91.152 94.4426 96.0694 96.082 96.0694C97.7213 96.0694 99.3606 96.0694 102.639 97.7085C105.918 99.3476 110.836 105.904 110.836 109.182C110.836 112.461 107.557 109.182 105.918 107.543C104.279 105.904 99.3606 112.461 99.3606 114.1C99.3606 115.739 96.082 125.574 87.8852 130.491C81.3279 134.425 77.501 133.223 76.4072 132.13C79.6859 128.852 79.6859 126.885 81.3253 120.329C82.9646 113.772 76.4072 113.772 74.7679 107.543C73.1285 101.315 71.4892 101.315 68.2105 96.0694C64.9318 90.8241 61.6531 88.5294 58.3744 85.2512Z",
            textX: 22,
            textY: 41
        },
        {
            city_name: "Dang'ara tumani",
            slug: 'dangara-tumani',
            data: "M96.087 96.0695C97.7263 96.0695 99.3657 96.0695 102.644 97.7087C105.921 97.7088 112.803 97.709 114.115 97.7089C115.754 97.7087 120.672 96.0697 122.311 94.4306C123.951 92.7915 129.202 92.7915 126.576 86.2349C123.951 79.6784 125.59 76.4001 123.951 71.4827C122.311 66.5654 119.363 57.714 119.363 56.7306C119.363 55.7471 117.398 51.8132 120.675 51.8132C123.951 51.8132 125.59 50.174 127.23 50.174C128.541 50.174 127.341 42.0876 126.576 38.0444L120.675 37.3888L119.363 36.0775L117.724 35.094L114.773 33.7827H110.838L101.986 41.9784L99.3631 42.3062L94.1172 41.6505L91.4943 41.9784L88.5434 43.6175L86.2483 45.9123L84.609 48.2071L82.6418 50.8297L77.7238 53.7801L74.7729 55.7471L74.1173 56.7306L73.7893 58.0418L73.1335 59.0254L69.8549 59.681L68.2155 60.3367L65.5926 62.6314L62.6419 65.2536C64.7383 65.5156 68.3258 70.6082 69.8575 73.1217C70.9504 72.0289 74.1198 69.5156 78.0542 68.2043C82.9722 66.5652 84.6116 73.1217 84.6116 78.0391C84.6116 82.9565 78.0542 79.6782 74.7755 82.9565C71.4968 86.2347 86.6831 85.2513 90.6398 85.2513C94.5964 85.2513 96.087 87.8739 97.7263 89.513C99.3657 91.1521 94.4476 96.0695 96.087 96.0695Z",
            textX: 25,
            textY: 24
        },
        {
            city_name: "Qo'qon shahar",
            slug: 'qoqon-shahri',
            data: "M122.309 94.4305C120.67 96.0696 115.752 97.7086 114.112 97.7088H108.698C108.069 97.7088 106.631 97.3809 105.918 96.0694C105.026 94.4302 105.918 91.1522 105.918 89.5129C105.918 87.874 107.558 84.5949 109.197 81.3175C110.836 78.0392 114.112 76.4001 114.77 74.7609C115.297 73.4496 121.73 76.3999 124.88 78.039C125 80.3491 125.221 82.8569 126.574 86.2349C129.199 92.7914 123.948 92.7914 122.309 94.4305Z",
            textX: 15,
            textY: 8
        },
        {
            city_name: "Qo'qon shahar",
            slug: 'qoqon-shahri',
            data: "M69.651 28.0984C67.4294 30.3197 60.7647 32.5407 58.5431 32.541H51.206C50.3531 32.5409 48.4054 32.0966 47.4387 30.3194C46.2303 28.0979 47.4387 23.6558 47.4387 21.4342C47.4387 19.2132 49.6592 14.7728 51.8802 10.3313L51.8819 10.328C54.1034 5.88544 58.5431 3.66414 59.435 1.44285C60.1486 -0.334183 68.866 3.66396 73.1356 5.88517C73.2985 9.01573 73.5975 12.4142 75.4306 16.9919C78.9886 25.8771 71.8726 25.8771 69.651 28.0984Z",
            textX: 15,
            textY: 8
        },
        {
            city_name: "O'zbekiston tumani",
            slug: 'ozbekiston-tumani',
            data: "M73.1312 145.244C73.1312 138.687 73.1312 135.409 76.4099 132.13C77.5036 133.223 81.3305 134.425 87.8879 130.491C96.0846 125.574 99.3633 115.739 99.3633 114.1C99.3633 112.461 104.281 105.904 105.921 107.543C107.56 109.183 110.839 112.461 110.839 109.183C110.839 105.904 105.924 99.3476 102.646 97.7085L114.116 97.7087C115.756 97.7085 120.674 96.0696 122.313 94.4304C122.859 96.0696 124.279 100.987 125.59 107.543C127.23 115.739 126.507 119.017 127.23 125.574C127.952 132.13 125.59 133.77 127.23 137.048C128.869 140.326 133.459 145.244 133.787 146.883C134.049 148.194 134.115 155.734 134.115 159.34L122.967 161.307L116.082 168.192L112.148 167.864L95.4264 169.503L68.2131 177.698V169.83C68.2131 167.208 66.5738 159.996 66.5738 155.078C66.5738 150.161 73.1312 151.8 73.1312 145.244Z",
            textX: 26,
            textY: 58
        },
        {
            city_name: "Uchkoʻprik tumani",
            slug: 'uchkoprik-tumani',
            data: "M126.574 86.235C129.2 92.7915 123.949 92.7915 122.309 94.4306C122.855 96.0698 124.277 100.987 125.589 107.544C127.228 115.739 126.505 119.018 127.228 125.574C127.951 132.131 125.589 133.77 127.228 137.048C128.867 140.326 133.457 145.244 133.785 146.883C135.972 146.883 140.344 146.227 140.344 143.605C140.344 140.982 138.158 137.048 137.066 135.409L141.984 130.492C143.623 128.852 146.902 128.852 146.902 125.574C146.902 122.296 141.984 122.296 140.344 119.018C138.705 115.739 145.262 107.544 146.902 104.265C148.541 100.987 153.459 102.626 150.18 91.1524C146.902 79.6784 145.262 79.6784 141.984 78.0393C138.705 76.4002 135.426 78.0393 133.787 73.1219C132.148 68.2045 133.787 66.5654 132.148 63.2871C130.836 60.6645 128.321 53.4523 127.227 50.1741C125.588 50.1741 123.949 51.8132 120.672 51.8132C117.396 51.8132 119.361 55.7472 119.361 56.7306C119.361 57.714 122.309 66.5654 123.949 71.4828C125.588 76.4002 123.949 79.6784 126.574 86.235Z",
            textX: 34,
            textY: 38
        },
        {
            city_name: "Buvayda tumani",
            slug: 'buvayda-tumani',
            data: "M126.576 38.0444C127.341 42.0876 128.541 50.174 127.23 50.174C128.323 53.4523 130.84 60.6643 132.152 63.2869C133.791 66.5652 132.152 68.2043 133.791 73.1217C135.431 78.0391 138.709 76.4 141.988 78.0391C145.267 79.6782 146.906 79.6782 150.185 91.1521C152.37 91.1521 156.74 91.48 156.74 92.7913C156.74 94.4304 155.101 97.7087 158.379 96.0695C161.658 94.4304 164.283 94.4304 164.283 92.7913C164.283 91.1521 163.297 86.2347 164.937 84.5956C166.576 82.9565 166.576 79.6782 169.855 79.6782C173.133 79.6782 173.787 78.9756 174.773 78.0391C175.562 77.2899 175.759 74.6672 175.759 73.4495L173.136 72.7938L164.283 67.8765L160.349 64.5982L158.382 63.9426L154.775 63.2869L152.808 62.6312L151.496 61.9756L145.923 56.0747L144.611 55.4191L137.07 54.7634L136.087 53.4521L148.218 37.0608L126.576 38.0444Z",
            textX: 38,
            textY: 28
        },
        {
            city_name: "Bog'dod tumani",
            slug: 'bagdod-tumani',
            data: "M156.742 92.791C156.742 91.4797 152.372 91.1518 150.187 91.1518C153.465 102.626 148.541 100.987 146.902 104.265C145.262 107.543 138.705 115.739 140.344 119.017C141.984 122.296 146.902 122.296 146.902 125.574C146.902 128.852 143.623 128.852 141.984 130.491L137.066 135.409C138.158 137.048 140.344 140.982 140.344 143.604C142.53 141.419 147.885 136.392 151.82 133.77C156.738 130.491 162.305 132.13 163.295 127.213C164.285 122.296 161.664 115.739 164.939 117.378C168.213 119.017 170.515 119.835 173.138 122.296C175.761 124.757 179.688 120.657 179.688 117.378C179.688 114.1 179.688 110.822 184.607 110.822C188.541 110.822 193.896 109.729 196.082 109.183C193.35 103.719 187.885 92.1356 187.885 89.513C187.885 86.2347 183.309 75.4159 175.761 73.4492C175.761 74.6669 175.564 77.2895 174.775 78.0388C173.789 78.9753 173.135 79.6779 169.857 79.6779C166.578 79.6779 166.578 82.9562 164.939 84.5953C163.299 86.2344 164.285 91.1518 164.285 92.791C164.285 94.4301 161.66 94.4301 158.381 96.0692C155.103 97.7084 156.742 94.4301 156.742 92.791Z",
            textX: 41,
            textY: 41
        },
        {
            city_name: "Oltiariq tumani",
            slug: 'oltiariq-tumani',
            data: "M187.887 89.5125C187.887 92.1352 193.352 103.718 196.084 109.182C197.286 112.46 200.28 120.001 202.639 123.935C205.589 128.852 204.279 130.491 202.313 133.769C200.347 137.048 193.788 141.965 187.887 141.965C183.167 141.965 176.958 146.117 174.444 148.194L176.083 150.816L178.05 153.111L180.673 154.422L183.952 154.75L189.854 154.095L193.788 154.422L199.69 159.995L208.214 162.618C207.995 161.197 207.45 158.225 207.019 157.701C206.482 157.045 208.214 149.521 219.033 150.161C229.851 150.8 230.508 146.882 233.787 143.604C237.066 140.326 241.984 138.687 245.262 135.408C248.541 132.13 246.902 130.491 246.902 125.574C246.902 120.656 241.984 123.935 237.066 119.017C232.148 114.1 232.148 112.461 230.508 109.182C228.869 105.904 225.59 105.904 222.311 104.265C219.033 102.626 215.754 102.626 217.393 99.3475L222.311 89.5125C223.951 86.2341 222.311 84.5953 219.033 82.9562C215.754 81.3171 210.836 81.3171 210.836 76.3997C210.836 72.4658 202.531 58.1507 198.378 51.4849L192.477 60.9918L180.018 72.7936L175.763 73.4488C183.311 75.4154 187.887 86.2343 187.887 89.5125Z",
            textX: 55,
            textY: 51
        },
        {
            city_name: "Rishton tumani",
            slug: 'rishton-tumani',
            data: "M172.474 146.883L174.441 148.194C176.956 146.118 183.167 141.965 187.887 141.965C193.788 141.965 200.347 137.048 202.313 133.77C204.279 130.492 205.589 128.852 202.639 123.935C200.28 120.001 197.284 112.461 196.082 109.183C193.896 109.729 188.541 110.822 184.607 110.822C179.689 110.822 179.689 114.1 179.689 117.378C179.689 120.657 175.761 124.757 173.138 122.296C170.515 119.835 168.213 119.017 164.939 117.378C161.664 115.739 164.285 122.296 163.295 127.213C162.305 132.13 156.738 130.491 151.82 133.77C147.885 136.392 142.531 141.419 140.346 143.604C140.346 146.227 135.973 146.883 133.787 146.883C134.049 148.194 134.113 155.734 134.113 159.34L156.408 162.618L162.31 161.963L164.933 157.701L166.572 152.128L169.851 147.538L172.474 146.883Z",
            textX: 45,
            textY: 51
        },
        {
            city_name: "Quva tumani",
            slug: 'quva-tumani',
            data: "M315.755 75.7449L306.575 72.7944C305.81 73.7781 304.279 76.1386 304.279 77.7118C304.279 79.6783 304.279 84.269 302.639 85.2519C301 86.2348 296.082 86.5636 296.082 88.8579C296.082 91.1522 298.708 98.3949 297.395 100.511C301.877 97.9377 311.164 93.1117 312.475 94.423C314.115 96.0621 316.082 96.7467 318.377 100.502C320.672 104.258 322.312 107.536 325.59 107.536H334.439C337.607 108.629 344.408 111.478 346.247 114.101L349.526 113.445L358.051 113.117L357.723 111.806L356.083 105.905L355.755 102.627L357.067 93.4476V88.8579L356.739 87.5466L354.116 85.2519L344.28 82.3014L341.985 81.9736L340.674 80.9901L337.067 75.4171L334.444 73.4501L332.477 75.0893L330.509 77.7118L327.231 78.0397L315.755 75.7449Z",
            textX: 84,
            textY: 37
        },
        {
            city_name: "Quvasoy shahar",
            slug: 'quvasoy-shahri',
            data: "M325.585 128.524C328.298 130.151 329.609 130.002 330.163 129.43L330.504 128.524L331.816 123.935L333.455 121.312L346.242 114.1C344.403 111.478 337.607 108.637 334.439 107.544H325.59C322.312 107.544 320.672 104.266 318.377 100.51C316.082 96.7544 314.115 96.0699 312.475 94.4307C311.164 93.1194 301.872 97.9372 297.39 100.51C296.077 102.626 292.798 109.182 291.159 109.182C289.847 109.182 285.37 114.646 283.295 117.378C285.699 119.563 291.294 123.934 294.437 123.934C298.367 123.934 296.077 119.017 299.355 114.1C302.634 109.182 306.246 115.739 306.574 117.378C306.901 119.017 314.11 119.017 318.377 119.017C321.791 119.017 323.512 121.203 323.946 122.295C322.853 123.388 321.651 126.164 325.585 128.524Z",
            textX: 77,
            textY: 41
        },
        {
            city_name: "Toshloq tumani",
            slug: 'toshloq-tumani',
            data: "M271.822 115.739C273.4 114.427 280.13 116.286 283.298 117.378C285.372 114.646 289.847 109.183 291.158 109.183C292.798 109.183 296.076 102.626 297.389 100.511C298.702 98.395 296.076 91.1523 296.076 88.858C296.076 86.5637 300.994 86.235 302.634 85.252C304.273 84.2691 304.273 79.6784 304.273 77.7119C304.273 76.1388 305.804 73.7782 306.569 72.7946L301.322 73.1219L297.388 73.7776L292.798 73.1219L291.158 72.4662L289.519 71.4828C288.316 71.4828 285.649 72.4662 284.604 76.4002C283.298 81.3176 282.965 87.8741 279.686 86.235C276.407 84.5958 274.888 71.4828 270.73 71.4828C266.571 71.4828 263.295 70.1719 263.295 77.7119V97.7089C263.295 102.626 263.295 105.905 264.934 109.183C266.246 111.805 270.073 114.646 271.822 115.739Z",
            textX: 70,
            textY: 38
        },
        {
            city_name: "Qo'shtepa tumani",
            slug: 'qoshtepa-tumani',
            data: "M264.935 120.657C267.53 117.378 269.853 117.379 271.825 115.739C270.076 114.647 266.243 111.805 264.932 109.183C263.292 105.904 263.292 102.626 263.292 97.7088V77.7119C262.746 77.7119 260.997 78.1052 258.374 79.6783C255.096 81.6448 256.735 81.3175 253.456 76.3998C250.178 71.4821 251.817 69.8435 248.538 71.4827C245.26 73.1218 246.895 81.3175 243.62 82.9563C240.345 84.5951 238.702 79.6783 240.342 74.7609C241.981 69.8435 232.145 76.4001 225.588 77.7119C220.342 78.7613 213.563 77.2744 210.83 76.3998C210.83 81.3172 215.748 81.3172 219.026 82.9563C222.305 84.5954 223.944 86.2342 222.305 89.5126L217.387 99.3476C215.748 102.626 219.026 102.626 222.305 104.265C225.584 105.904 228.862 105.904 230.502 109.182C232.141 112.461 232.141 114.1 237.059 119.017C241.977 123.935 246.899 120.657 246.899 125.574C248.208 126.885 259.468 122.842 264.935 120.657Z",
            textX: 60,
            textY: 37
        },
        {
            city_name: "Marg'ilon shahar",
            slug: 'margilon-shahri',
            data: "M264.941 120.649C267.536 117.37 269.859 117.371 271.832 115.731C270.082 114.638 266.25 111.797 264.938 109.175C264.281 107.861 263.888 106.548 263.652 105.129C261.894 104.841 258.049 104.593 256.738 105.904C255.098 107.543 248.541 104.265 250.18 109.183C251.82 114.1 253.459 120.641 255.098 120.649C256.41 120.655 258.418 122.078 259.258 122.789C261.379 122.036 263.393 121.268 264.941 120.649Z",
            textX: 72,
            textY: 7
        },
        {
            city_name: "Marg'ilon shahar",
            slug: 'margilon-shahri',
            data: "M300.104 29.2775C304.762 23.3913 308.934 23.3919 312.475 20.4482C309.334 18.4863 302.453 13.3847 300.098 8.67575C298.919 6.31757 298.212 3.95938 297.788 1.41193C294.631 0.894815 287.728 0.449217 285.374 2.80371C282.43 5.74683 270.656 -0.139406 273.6 8.68994C276.543 17.5193 279.487 29.2633 282.43 29.2775C284.785 29.2889 288.391 31.8449 289.899 33.1214C293.707 31.7687 297.323 30.3893 300.104 29.2775Z",
            textX: 72,
            textY: 7
        },
        {
            city_name: "Yozyovon tumani",
            slug: 'yozyovon-tumani',
            data: "M284.612 76.4001C285.657 72.4662 288.331 71.483 289.534 71.483L281.993 60.9925L280.353 58.0421L278.714 56.403L276.419 55.0917L266.583 51.8134L263.632 51.4856L261.337 51.8134L260.353 52.4691L258.714 52.1413L256.091 51.4856L250.845 48.5352H248.878L247.894 49.5186L247.566 53.7804L246.583 53.4526L244.616 50.5022L240.681 42.6343L234.78 34.1108L219.042 39.3561L202.32 48.5352L199.361 51.4856C203.513 58.1514 210.846 72.4659 210.846 76.3998C213.58 77.2745 220.349 78.7614 225.595 77.712C232.153 76.4002 241.989 69.8437 240.349 74.7611C238.71 79.6785 240.353 84.5953 243.628 82.9564C246.903 81.3176 245.267 73.122 248.546 71.4828C251.825 69.8437 250.185 71.4822 253.464 76.3999C256.743 81.3176 255.104 81.6449 258.382 79.6785C261.005 78.1053 262.756 77.7119 263.303 77.7119C263.303 70.1719 266.579 71.4827 270.738 71.4827C274.896 71.4827 276.415 84.5958 279.694 86.2349C282.972 87.8741 283.305 81.3175 284.612 76.4001Z",
            textX: 58,
            textY: 24
        },
        {
            city_name: "So'x tumani",
            slug: 'sox-tumani',
            data: "M156.406 217.038L157.717 217.366L158.701 216.71L160.668 215.071L161.98 214.416L166.57 213.104L169.193 213.76L170.504 216.055L169.193 219.989L160.34 226.217L157.39 229.168L157.717 235.724L166.242 244.576L165.914 252.443L163.619 255.394L160.996 256.377H154.767L145.586 259L142.963 258.672L140.668 256.377L140.34 253.755L139.357 251.788L135.75 251.46L133.455 253.099L130.832 255.722L128.209 257.361L125.914 256.049L125.586 249.821L133.783 237.036L132.799 229.496L130.504 227.856L125.586 226.873L122.963 225.562L120.996 222.283L116.078 212.121L115.095 207.859L115.422 203.925L116.406 199.336L119.685 191.796L125.586 188.517L129.849 191.14L133.127 197.041L135.75 203.925L139.029 208.187L144.275 211.465L154.767 216.055L155.422 216.71L156.406 217.038Z",
            textX: 36,
            textY: 90
        },
        {
            city_name: "Farg'ona tumani",
            slug: 'fargona-tumani',
            data: "M245.259 135.409C248.538 132.13 246.899 130.491 246.899 125.574C248.207 126.885 259.468 122.842 264.934 120.657C267.529 117.378 269.852 117.379 271.825 115.739C273.403 114.428 280.133 116.286 283.3 117.379C285.705 119.564 291.299 123.935 294.443 123.935C298.372 123.935 296.082 119.018 299.361 114.1C302.639 109.183 306.251 115.739 306.579 117.379C306.907 119.018 314.115 119.018 318.382 119.018C321.796 119.018 323.517 121.203 323.951 122.296C322.858 123.389 321.656 126.164 325.59 128.524C328.303 130.152 329.615 130.002 330.168 129.43L327.89 135.409L322.972 140.654L306.251 148.85L305.267 152.456L306.579 156.39L310.185 158.684L315.759 161.307L318.382 163.274L319.366 165.241L318.054 167.864L315.431 168.519L312.48 168.191L310.185 167.208L308.874 166.88H307.562L305.267 167.536L298.054 166.88L290.513 165.241L283.3 165.569L277.399 171.47L274.12 179.337L271.825 182.944L268.874 185.894L258.71 191.795L256.087 192.451L252.153 190.811L251.169 186.878V176.715L249.858 171.797L247.235 166.224L243.628 163.274L240.349 166.224L239.038 171.142L238.71 175.404L237.071 178.354L232.481 178.682L223.3 177.043L219.038 175.076L215.104 172.781L211.497 169.175L209.857 165.569L208.218 162.618C207.999 161.198 207.447 158.225 207.016 157.701C206.478 157.045 208.211 149.521 219.03 150.161C229.848 150.801 230.505 146.883 233.784 143.604C237.062 140.326 241.98 138.687 245.259 135.409Z",
            textX: 66,
            textY: 57
        },
        {
            city_name: "Farg'ona tumani",
            slug: 'fargona-tumani',
            data: "M268.211 251.787L265.26 252.442L260.67 250.803L256.735 249.164L252.801 246.214L250.833 242.608L254.112 238.674H258.702L261.981 237.035L268.866 231.789L273.784 230.15L274.44 232.773L270.178 248.836L268.211 251.787Z",
            textX: 66,
            textY: 57
        },
        {
            city_name: "Farg'ona shahar",
            slug: 'fargona-shahri',
            data: "M278.049 123.935C275.426 118.69 280.235 117.378 282.967 117.378C283.514 117.378 285.262 118.034 287.885 120.657C290.508 123.279 294.443 123.935 296.082 123.935L297.721 119.018L302.639 120.657C303.732 122.842 305.918 127.869 305.918 130.491C305.918 133.77 301 135.409 297.721 137.048C294.443 138.687 289.525 135.409 284.607 135.409C279.689 135.409 281.328 130.491 278.049 123.935Z",
            textX: 93,
            textY: 69
        },
        {
            city_name: "Farg'ona shahar",
            slug: 'fargona-shahri',
            data: "M343.547 166.956C338.139 156.143 348.053 153.439 353.685 153.439C354.812 153.439 358.417 154.791 363.824 160.198C369.232 165.604 377.343 166.956 380.722 166.956L384.102 156.819L394.241 160.198C396.494 164.703 401 175.066 401 180.473C401 187.231 390.861 190.61 384.102 193.99C377.343 197.369 367.204 190.61 357.065 190.61C346.926 190.61 350.306 180.473 343.547 166.956Z",
            textX: 93,
            textY: 69
        }
    ]
};
export default Map;