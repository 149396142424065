import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUpgredeCategorys } from '../actions';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import history from '../history';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import RecommendedCourses from './sections/RecommendedCourses';
import ResultPupil from './sections/ResultPupil';
import { IMG_URL, FILE_URL } from '../helpers/api';

const DashQExercisePage = props => {
	const { upgrade_categories, upgrade_categories_status, profile } = props;
	const user_hours = upgrade_categories.user_hours ? upgrade_categories.user_hours : 0;
	const total_percent = parseInt((user_hours / upgrade_categories.total_hours) * 100);
	const [loading, setLoading] = useState(true);
	const [diagnostics, setDiagnostics] = useState({});
	const [chartLoading, setChartLoading] = useState(true);
	const [certificate, setCertificate] = useState({});
	const [first, setFirst] = useState(false);
	const { t } = useTranslation();
	useEffect(() => {
		if(profile.id&&!profile.language_id){
			history.push('/dashboard/exercise/choice-lan');
		}
	}, [profile]);
	useEffect(() => {
		getInstance()
			.get(`/api/v1/courses/get-certificate`)
			.then(response => {
				setCertificate(response.data.data?response.data.data:{});
			})
			.catch(error => {
				console.log(error);
			});
		getInstance()
			.get('/api/v1/course/exercise/result')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						if (!data || data.length === 0) {
							history.push('/dashboard/exercise/choice-lan');
						} else {
							setDiagnostics(response.data);
							setChartLoading(false);
						}
					} catch {
						history.push('/dashboard/exercise/choice-lan');
					}
				}
			})
			.catch(error => {
				console.log(error);
				history.push('/dashboard/exercise/choice-lan');
			});
		

		props.getUpgredeCategorys();
	}, []);
	useEffect(() => {
		if (upgrade_categories_status.status === 'started') {
			setLoading(true);
		}
		if (upgrade_categories_status.status === 'success') {
			setLoading(false);
		}
		if (upgrade_categories_status.status === 'error') {
			setLoading(false);
		}
	}, [upgrade_categories_status]);

	const setCertificateUser = () => {
		getInstance()
			.post(`/api/v1/courses/set_certificate`, {id: certificate.id})
			.then(response => {
				setCertificate(response.data.data?response.data.data:certificate);
				setFirst(true);
			})
			.catch(error => {
				console.log(error);
			});
	}

	return (
		<Layout>
			<CheckRole roles={[roles.user, roles.teacher]} />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('exercise.exercise')}
										navs={[
											{
												title: t('navigation.account'),
												link: '/dashboard',
											},
										]}
									/>
									<div className="dashboard-title">
										<h5>{t('exercise.exercise')}</h5>
									</div>
									{certificate.is_view?<div className="row">
											<div className="col-12 col-lg-6">
												<div className="card card--shadow ">
													<div className="card-body">
														{first?<p>{t('qualification.text1')}</p>:null}
														<p>{t('qualification.text2')} {new Date().getFullYear()}{t('qualification.text3')}</p>
														<p>{t('qualification.text4')} {new Date().getFullYear()+1}{t('qualification.text5')}</p>
													</div>
												</div>
												<div className="card card--shadow  mb-3" >
													<div className="card-body download-certificate">
														{t('qualification.download_text')}
														<a href={ FILE_URL + certificate.pdf } target="_blank"
															className="btn btn-primary btn-sm" >
															<span className="icon icon-certificate mr-1"></span>
															{t('qualification.download')}
														</a>
													</div>
												</div>
											</div>
											<div className="col-12 col-lg-6">
												<div className="card card--shadow qualification-progress">
													<div className="card-body">
														<img src={IMG_URL+certificate.image}  alt="certificate" className="w-100"
															onError={e => {
																e.target.onerror = null;
																e.target.src = '/images/no-certificate.png';
															}}
														/>
													</div>
												</div>
											</div>
										</div>:
										<div className="row">
											{loading ? (
												<Loading />
											) : (
												<>
													<div className="col-12">
														{chartLoading?<div className="card card--shadow">
															<div className="card-body"> <Loading/> </div>
														</div>:diagnostics.data && diagnostics.data.length > 0 ? <>
															<ResultPupil data={diagnostics.data} exercise={true} />
														</> : null}
														{/* <div className="card card--shadow qualification-progress">
															<div className="card-body">
																<div className="row">
																	<div className="col  col-12 col-sm mb-1">
																		<div className="d-md-flex justify-content-between text-secondary mb-2">
																			<div className="pr-md-6">
																				{t('qualification.completed')}{' '}
																				{parseInt(
																					upgrade_categories.user_hours / 60,
																				)}{' '}
																				{t('qualification.hours')}{' '}
																				{parseInt(upgrade_categories.user_hours) %
																					60}{' '}
																				{t('qualification.minutes')} (
																				{total_percent}%)
																				{t('qualification.completed')}{' '}
																				{upgrade_categories.user_hours}{' '}
																				{t('create_course.credit')} ({total_percent}
																				%)
																			</div>
																			{total_percent >= 100 ? null : (
																				<div>
																					{t('qualification.all')}{' '}
																					{parseInt(
																						upgrade_categories.total_hours / 60,
																					)}{' '}
																					{t('qualification.hours')}{' '}
																					{parseInt(
																						upgrade_categories.total_hours,
																					) % 60}{' '}
																					{t('qualification.minutes')}
																					{t('qualification.all')}{' '}
																					{parseInt(
																						upgrade_categories.total_hours,
																					)}{' '}
																					{t('create_course.credit')}
																				</div>
																			)}
																		</div>
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				style={{ width: total_percent + '%' }}
																				aria-valuenow={total_percent}
																				aria-valuemin="0"
																				aria-valuemax="100"></div>
																		</div>
																	</div>
																	{total_percent >= 100 ? (
																		<div className="col col-12  col-sm">
																			<button className="btn btn-primary btn-sm" onClick={()=>setCertificateUser()}>
																				<span className="icon icon-paper  mr-1"></span>
																				{t('qualification.get_certificate')}
																			</button>
																		</div>
																	) : null}
																</div>
															</div>
														</div> */}
													</div>
													{upgrade_categories.data && upgrade_categories.data.length > 0 ? (
														upgrade_categories.data.map((cat, index) => {
															const user_hours_count = cat.user_hours_count ? cat.user_hours_count : 0;
															const percent = parseInt((user_hours_count / cat.hours) * 100);
															const circle_percent = parseInt(
																(195 *
																	(100 - (percent > 100 ? 100 : percent ? percent : 0))) /
																	100,
															);
															return (
																<div className="col-md-6 col-xl-4" key={index}>
																	<div className="card card--shadow qualification-type">
																		<div className="card-body p-3">
																			<div className="d-flex align-items-center">
																				<div className="circle-progress-bar">
																					<div className="text">
																						{percent ? percent : 0}%
																					</div>
																					<svg className="progress-circle">
																						<circle
																							className="bg"
																							cx="34"
																							cy="34"
																							r="31"></circle>
																						<circle
																							className="progress"
																							cx="34"
																							cy="34"
																							r="31"
																							data-percent={percent}
																							style={{
																								strokeDashoffset: circle_percent,
																							}}></circle>
																					</svg>
																				</div>
																				<div className="qualification-type__caption">
																					<Link to={ '/dashboard/exercise/'+cat.slug+'/1' }>
																						{cat.name}
																					</Link>
																					{/* <p> */}
																						{/* {parseInt(cat.hours / 60)}{' '}
																						{t('qualification.hours')}{' '} */}
																						{/* {t('qualification.item_text')}
																						{cat.hours
																							? cat.hours
																							: 0}
																						{t('qualification.credit')}
																					</p> */}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															);
														})
													) : (
														<NoData text={t('qualification.no_data')} />
													)}
													<div className="col-12">
														{diagnostics.data && diagnostics.data.length > 0 ? (
															<RecommendedCourses data={diagnostics.data} />
														) : null}
													</div>
												</>
											)}
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		// getUpgredeCategorys: () => dispatch(getUpgredeCategorys( )),
		getUpgredeCategorys: () => dispatch(getUpgredeCategorys('training')),
	}),
)(DashQExercisePage);
