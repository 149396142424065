import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, withHighcharts } from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const Select = (props) => {
    const { t } = useTranslation();

    const {survey, result, lan} = props;
    let cat = [];
    let points = [];
    let total_user = 0;
    survey.choices.forEach(item => {
        total_user += parseInt( _.get(result, item.id, 0 ) );
    });
    total_user += parseInt( _.get(result, 'no_result', 0 ) );
    survey.choices.forEach(item => {
        const p = parseInt(_.get(result, item.id, 0 )?_.get(result, item.id, 0 ):0)/total_user*100;
        if(p>0){
            points.push( p );
            cat.push( _.get(item, 'label_'+lan, '') );
        }
    });
    const no_result = parseInt(_.get(result, 'no_result', 0 )?_.get(result, 'no_result', 0 ):0)/total_user*100;
    if(no_result>0){
        points.push( no_result );
        cat.push(  t('survey_r.not_specified')  );
    }

    const chart = {
        marginRight: 0,
        backgroundColor: '#F7F7F7',
        borderRadius: 7,
        // margin: 100
    }
    const xAxis = {
        categories: cat
    }
    const yAxis = [{
    		id: "y_axis_0",
        title: {
            text: "",
        }
    }]
    const  plotOptions = {
        column: {
          borderRadius: 5,
          dataLabels: {
            enabled: true,
            format: '{y:.1f} %'
          },
        }
      }
    const series = [ {
        type: 'column',
        data: points,
        name: "O'qilgan",
        yAxis: "y_axis_0"
    }]
    const chartooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}"> <b>{point.y:.1f} %</b><br/>'
    }
    const chartpane = {
        size: '100%'
    }

return (
      <div style={{width: '100%'}}>
        <HighchartsChart  
          chart={chart}
          series={ series}
          yAxis={yAxis}
          xAxis = {xAxis}
          plotOptions = {plotOptions}
          tooltip={ chartooltip}
          pane = {chartpane}
        >
        </HighchartsChart>
      </div>
    );
}

export default withHighcharts(Select, Highcharts);
