import React from 'react';
import { IMG_URL, FILE_URL } from '../../helpers/api';
import { useTranslation } from 'react-i18next';

const CertificateModal = props => {
    const {certificate} = props;
	const { t } = useTranslation();

    return(
        <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
            {certificate.pdf ?
            <a href={ FILE_URL + certificate.pdf } target="_blank"
                className="close download" >
                <span className="icon icon-certificate mr-1"></span>
                {t('qualification.download')}
            </a>:null}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span className="icon icon-close"></span>
            </button>
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <img src={IMG_URL+certificate.image} alt="Online Certificate"/>
                </div>
            </div>
        </div>
    )
}
export default CertificateModal;