import React, {useState, useEffect} from 'react';
import NoData from "./NoData";
import ChartColumnHCourseReview from "./ChartColumnHCourseReview";
import Loading from "./Loading";
import {getInstance, getMore} from '../../helpers/httpClient';
import {IMG_URL} from '../../helpers/api';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

const CourseReview = props => {
    const { t } = useTranslation();
    const { slug, is_add_block } = props;
    const [loading, setLoading] = useState(true);
    const [loading_s, setLoading_s] = useState(true);
    const [loading_set, setLoading_set] = useState(false);
    const [loading_more, setLoading_more] = useState(false);
    const [loading_chart, setLoading_chart] = useState(true);
    const [reviews, setReviews] = useState({});
    const [review_statistic, setReview_statistic] = useState({});
    const [edit, setEdit] = useState(true);
    const [star, setStar] = useState(5);
    const [star_e, setStar_e] = useState(false);
    const [comment, setComment] = useState('');
    const [comment_e, setComment_e] = useState(false);
    useEffect(() => {
        setLoading_s(true);
        setLoading(true);
        getReviewStatistic();
        getReviews();
    }, []);
    const getReviews = () => {
		getInstance()
        .get(`/api/v1/course/${slug}/review-get?per_page=6`)
        .then(response => {
            if (response.status === 200) {
                try {
                    const { data } = response.data;
                    setReviews(data);
                    setLoading(false);
                } catch {
                    setLoading(false);
                }
            }
        })
        .catch(error => { setLoading(false); } );
	}
    const getReviewStatistic = () => {
        setLoading_chart(true);
		getInstance()
			.get(`/api/v1/course/${slug}/review-statistics`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response;
                        setReview_statistic(data);
                        setStar(parseInt(_.get(data, 'user_review.rating', 5)));
                        setComment(_.get(data, 'user_review.comment', ''));
                        setLoading_s(false);
                        setLoading_chart(false);
					} catch {
                        setLoading_chart(false);
						setLoading_s(false);
					}
				}
			})
			.catch(error => { setLoading_s(false); setLoading_chart(false);} );
	}
    const postReviews = () => {
        setLoading_set(true);
        let s = true, c = true;
        if(star<1&&star>5) s = false;
        if(!comment) c = false;
        if(s&&c){
		    getInstance()
			.post(`/api/v1/course/review`,{
                rating: star,
                comment: comment,
                slug: slug
            })
			.then(response => {
				if (response.status === 200) {
					try {
                        setLoading_set(false);
                        setEdit(false);
                        getReviewStatistic();
                        getReviews();
					} catch {
						setLoading_set(false);
					}
				}
			})
			.catch(error => { setLoading_set(false); } );
        }else{
            setStar_e(!s);
            setComment_e(!c);
            setLoading_set(false);
        }
	}
    const moreReviews = (more_url) => {
        setLoading_more(true);
		getMore()
			.get(more_url)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
                        setReviews({...data, data: [..._.get(reviews, 'data', []), ...data.data ]});
                        setLoading_more(false);
					} catch {
						setLoading_more(false);
					}
				}
			})
			.catch(error => { setLoading_more(false); } );
    }
    return (
        <div className="row cource_review_target">
            {loading_s?<Loading/>:<>
            {_.get(review_statistic, 'review_count', false)?
            <div className="col-12">
                <div className="card card--shadow">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-5 " style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                                <div>
                                    <div style={{fontSize: 80}}>{ parseFloat(_.defaultTo(_.get(review_statistic, 'avg.reting', 0), 0)).toFixed(1) }</div>
                                    <div className="course__rating" style={{justifyContent: 'center'}}>
                                        <div style={{width: 120}} className={"rating rating-"+(Number.parseFloat(_.get(review_statistic, 'avg.reting', 0))*2).toFixed()}></div>
                                        <span style={{color: "rgb(0, 0, 0)"}}></span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20}}>
                                        <span className="icon icon-users mr-1" ></span>: {_.get(review_statistic, 'review_count', 0)} {t('course_view.ta')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                {loading_chart?<div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',}}><Loading/></div>
                                :<ChartColumnHCourseReview data={_.get(review_statistic, 'data', [] )} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>:null}
            <div className="col-12 mb-2">
                <h4>
                    {t('course_view.user_review')}
                    { is_add_block? null : edit?null:
                    <button className="btn btn-primary btn-sm" style={{float: 'right'}} onClick={()=>setEdit(true)}>
                        {_.get(review_statistic, 'user_review.id', false)?<>
                            <span className="icon icon-edit mr-1" ></span>  {t('course_view.update_review')}
                        </>:<>
                            <span className="icon icon-plus mr-1" ></span>  {t('course_view.add_review')}
                        </>}
                    </button>}
                </h4>
            </div>
            <div className="col-12">
                { is_add_block? null : edit?
                <div className="card card--shadow">
                    <div className="card-body">
                        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20}}>
                            <h4 style={{fontSize: 24, fontWeight: 700, margin: 0 }}>{t('course_view.add_review')} </h4>
                            <button className="btn btn-sm btn-secondary-light" style={{fontWeight: 500, padding: '10px 15px'}} onClick={()=>setEdit(false)}> 
                                <span class="icon icon-close"></span> 
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="">
                                        {t('course_view.rating')}
                                    </label>
                                    <div className='mb-2'>
                                    {star>=1?<img src='/images/icons/star-solid.svg' alt='' style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(1)}/>:
                                             <img src='/images/icons/star-solid2.svg' alt=''style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(1)}/>}
                                    {star>=2?<img src='/images/icons/star-solid.svg' alt='' style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(2)}/>:
                                            <img src='/images/icons/star-solid2.svg' alt=''style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(2)}/>}
                                    {star>=3?<img src='/images/icons/star-solid.svg' alt='' style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(3)}/>:
                                            <img src='/images/icons/star-solid2.svg' alt=''style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(3)}/>}
                                    {star>=4?<img src='/images/icons/star-solid.svg' alt='' style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(4)}/>:
                                            <img src='/images/icons/star-solid2.svg' alt=''style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(4)}/>}
                                    {star>=5?<img src='/images/icons/star-solid.svg' alt='' style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(5)}/>:
                                            <img src='/images/icons/star-solid2.svg' alt=''style={{width: 30, cursor: 'pointer'}} onClick={()=>setStar(5)}/>}
                                    </div>
                                    {star_e?<h5 style={{ color: 'red', fontSize: 15 }}>
                                        {t('course_view.rating_e')}
                                    </h5>:null}
                                    <label for="">
                                        {t('course_view.review_write')}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        name="work_place"
                                        value={comment}
                                        onChange={e=>{setComment(e.target.value); setComment_e(false);}}
                                    ></textarea>
                                    {comment_e?<h5 style={{ color: 'red', fontSize: 15 }}>
                                        {t('course_view.review_write_r')}
                                    </h5>:null}
                                    {loading_set?<span style={{width: 100, float: 'right'}}><Loading/></span>
                                    :<button className="btn btn-primary btn-sm mt-2" style={{float: 'right'}} onClick={()=>postReviews()} > 
                                        {t('course_view.review_save')}
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :null}
                {loading?<Loading/>:_.get(reviews, 'data', []).map((item, index)=>(
                    <div className="card card--shadow" key={index}>
                        <div className="card-body">
                            <div className="count_target m-0">
                                <span className="count_icon" style={{width: 70, height: 70, borderRadius: '50%' }}>
                                    <img src={IMG_URL + _.get(item, 'user.avatar') } alt="review" style={{width: 70, height: 70, borderRadius: '50%' }} 
                                        onError={e => {
                                            e.target.onerror = null;
                                            e.target.src = '/images/user/user.png';
                                        }}
                                    />
                                </span>
                                <span className="conut_text">
                                    <div>
                                        {_.get(item, 'user.firstname', '')+' '+_.get(item, 'user.lastname', '')}
                                    </div>
                                    <strong>
                                        <span className="course__rating" style={{margin: '9px 0'}}>
                                            <div style={{width: 120}} className={ "rating rating-"+(_.get(item, 'rating', '')*2) }></div>
                                            <span style={{color: "rgb(0, 0, 0)"}}></span>
                                        </span>
                                    </strong>
                                    <strong><Moment format="HH:mm DD.MM.YYYY">{_.get(item, 'updated_at', '')}</Moment></strong>
                                </span>
                            </div>
                            <div className="mt-1 ml-1">{_.get(item, 'comment', '')}</div>
                        </div>
                    </div>
                ))}
                {_.get(reviews, 'data', []).length?null:
                <div className="card card--shadow">
                    <div className="card-body"> 
                        <NoData text={t('course_view.review_no_data')}/>
                    </div>
                </div>}
                {_.get(reviews, 'next_page_url', false)?
                <div style={{textAlign: 'center'}}>
                    {loading_more?<Loading/>
                    :<button className="btn btn-primary btn-sm " 
                        onClick={()=>moreReviews(_.get(reviews, 'next_page_url', '')+'&per_page='+_.get(reviews, 'per_page', '') )} > 
                        {t('course_view.review_more')}
                    </button>}
                </div>:null}
            </div>
            </>}
        </div>
    )
}
export default CourseReview;