import React, {useState, useEffect} from 'react';
import { deleteVideoQuestionTime, getTeacherLesson } from "../actions";
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import Loading from './sections/Loading';
import history from "../history";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import NoData from './sections/NoData';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const DashTeacherLessonVideoQuestions = (props) => {
    const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const { teacher_lessons_status, teacher_lesson_data, delete_question_time_status } = props;
    const [loading, setLoading] = useState(true);
    const video_question =  _.sortBy( _.uniqBy(_.get(teacher_lesson_data, 'questions', []), 'time'), o=>{return o.time} );
    const [deleteLoading, setDeleteLoading] = useState(0);
    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    useEffect(() => {        
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            setLoading(false);
            if(!teacher_lesson_data.id){
                history.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
            }
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status, teacher_lesson_data]);

    useEffect(() => {        
        if(delete_question_time_status.status === 'started'){
            setDeleteLoading(true);
        }
        if(delete_question_time_status.status === 'success'){
            setDeleteLoading(false);
        }
        if(delete_question_time_status.status === 'error'){
            setDeleteLoading(false);
        }
    }, [delete_question_time_status]);

    const deleteTime = (time, time_id) => {
        setDeleteLoading(time_id);
        props.deleteVideoQuestionTime({
            lesson_id: teacher_lesson_data.id,
            time: time
        })
    }
    return (<Layout>
	<CheckRole roles={ [ roles.teacher ] } />
    <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('teacher_lesson.questions')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('author_page.course'),
                                        'link': '/dashboard/copyrights'
                                    },
                                    {
                                        'title': t('course_items.items'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id
                                    },
                                    {
                                        'title': t('teacher_lesson.lesson'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
                                    },
                                    {
                                        'title': teacher_lesson_data.name,
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson/video'
                                    }
                                ]}
                            />
                            {loading?<Loading/>:<>
                            <div className="dashboard-title">
                                <h5>{t('teacher_lesson.questions')}</h5>
                            </div>
                            {video_question.length?<>
                            <div className="row" >
                                {video_question.map((item, index)=>(
                                <div className="col-md-6 col-xl-4" key={index}>
                                    <div className="card card--shadow m-0 mb-2" >
                                        <div className="card-body">
                                            <div>
                                                <b style={{fontSize: 24}}>{ parseInt(item.time/60)}:{item.time%60}</b>
                                                <span style={{float: 'right'}}>
                                                    <span className="button" 
                                                        onClick={()=>deleteTime(item.time, item.id)}
                                                        style={{
                                                            color: '#898DA6',
                                                            margin: '5px',
                                                            cursor: 'pointer',
                                                            float: 'right',
                                                            background: 'rgba(137, 141, 166, 0.1)',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: 32,
                                                            height: 32,
                                                            borderRadius: 8,
                                                        }}
                                                        data-toggle="modal" data-target="#modalDeleteLesson"
                                                        >
                                                            {deleteLoading===item.id?<Loading size={4} /> :<span className="icon icon-remove"></span>}
                                                    </span>
                                                    <Link className="button" 
                                                        to={`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/video/questions/update/${item.time}`}
                                                        style={{
                                                            color: '#898DA6',
                                                            margin: '5px',
                                                            cursor: 'pointer',
                                                            float: 'right',
                                                            background: 'rgba(137, 141, 166, 0.1)',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: 32,
                                                            height: 32,
                                                            borderRadius: 8,
                                                        }}
                                                            data-toggle="modal" data-target="#modalDeleteLesson"
                                                            // onClick={()=>setEditTime(false)}
                                                        ><span className="icon icon-edit"></span>
                                                    </Link>
                                                </span>
                                            </div>
                                            <div>{t('teacher_lesson.question_time')}</div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-12" >
                                    <Link className="btn btn-primary btn-sm mt-2"
                                        to={`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/video/questions/create`}
                                    ><span className="icon icon-plus"></span>{t('teacher_lesson.add_question_time')}</Link>
                                </div>
                            </div>
                            </>:
                            <NoData text={<>
                                <div>{t('teacher_lesson.no_question_time')}</div>
                                <div>
                                    <Link className="btn btn-primary btn-sm mt-2"
                                        to={`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson/video/questions/create`}
                                    ><span className="icon icon-plus"></span>{t('teacher_lesson.add_question_time')}</Link>
                                </div>
                            </>} />}
                             </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>    
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
        deleteVideoQuestionTime: data => dispatch(deleteVideoQuestionTime(data))
    })
)(DashTeacherLessonVideoQuestions);