import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Timer from 'react-compound-timer';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getProfile, getWebinar } from '../actions';
import { FILE_URL, IMG_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { getToken, issetToken } from '../helpers/tokenStorge';
import Layout from './Layout';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';


const WebinarPage = props => {
	const { t } = useTranslation();
	const { webinar_data, profile, getProfile } = props;
	const [loading, setLoading] = useState(true);
	const [user_name, setUserName] = useState('');
	const [user_name_e, setUserNameE] = useState('');
	const [end_limit, setEndLimit] = useState(false);
	const [rtmp_url, setRtmp_url] = useState('');
	const [rtmp_code, setRtmp_code] = useState('');
	const [rtmp_url_e, setRtmp_url_e] = useState('');
	const [rtmp_code_e, setRtmp_code_e] = useState('');
	const [live_loading, setLive_loading] = useState(false);
	const [live_stop_e, setLive_stop_e] = useState('');
	const [is_live, setIs_live] = useState(0);
	useEffect(() => {
		if (props.webinar_data_status.status === 'started') {
			setLoading(true);
		}
		if (props.webinar_data_status.status === 'success') {
			setLoading(false);
			setIs_live(_.get(webinar_data, 'is_live', 0));
		}
		if (props.webinar_data_status.status === 'error') {
			setLoading(false);
		}
		document.title = _.get(webinar_data, 'title', t('webinars.title'));
	}, [props.webinar_data_status.status]);
	useEffect(() => {
		props.getWebinar(props.match.params.slug);
	}, [props.match.params.id]);

	const startdate = new Date(_.get(webinar_data, 'startdate'));
	const duration = parseInt(_.get(webinar_data, 'duration'));
	const now = new Date();
	const duration_date = new Date(startdate.getTime() + duration * 60000);
	let is_duration = true;
	let is_early = true;
	const webinar_url =
		window.location.origin + '/webinars/view/' + _.get(webinar_data, 'slug');
	if (duration_date < now) {
		is_duration = false;
	}
	if (startdate < now) {
		is_early = false;
	}
	const limit = startdate - now;
	useEffect(() => {
		if (issetToken())
			getProfile();
			

	}, []);
	const joinWebinar = () => {
		if (user_name.length > 2) {
			window.$('#modalWebinarJoin').modal('hide');
			setUserName('');
			window.open(
				_.get(webinar_data, 'join_url') + '?name=' + user_name,
				'_blank',
			);
		} else setUserNameE(t('webinar_view.user_name_error'));
	};
	const liveWebinar = () => {
		setLive_loading(true);
		let is_val = true;
		if (!rtmp_url) {
			setRtmp_url_e(t('webinar_view.rtmp_url_e'));
			is_val = false;
		}
		if (!rtmp_code) {
			setRtmp_code_e(t('webinar_view.rtmp_code_e'));
			is_val = false;
		}
		if (is_val) {
			getInstance()
				.post(`/api/v1/course/webinar/live`, {
					meeting_id: _.get(webinar_data, 'id'),
					rtmp_url: rtmp_url,
					code: rtmp_code,
				})
				.then(response => {
					if (response.status === 200) {
						try {
							const { success } = response.data;
							setLive_loading(false);
							if (success === 1) {
								window.$('#modalWebinarLive').modal('hide');
								setIs_live(1);
								setRtmp_url('');
								setRtmp_code('');
								window.$('#modalWebinarLiveUserUrl').modal('show');
							} else setRtmp_code_e(t('webinar_view.rtmp_err'));
						} catch {
							setLive_loading(false);
							setRtmp_code_e(t('webinar_view.rtmp_err'));
						}
					}
				})
				.catch(error => {
					setLive_loading(false);
					setRtmp_code_e(t('webinar_view.rtmp_err'));
				});
		} else {
			setLive_loading(false);
		}
	};
	const liveWebinarUserUrl = () => {
		setLive_loading(true);
		let is_val = true;
		if (!rtmp_url) {
			setRtmp_url_e(t('webinar_view.rtmp_url_e'));
			is_val = false;
		}
		if (is_val) {
			getInstance()
				.post(`/api/v1/course/webinar/live-url-set`, {
					meeting_id: _.get(webinar_data, 'id'),
					live_url: rtmp_url,
				})
				.then(response => {
					if (response.status === 200) {
						try {
							const { success } = response.data;
							setLive_loading(false);
							if (success === 1) {
								window.$('#modalWebinarLiveUserUrl').modal('hide');
								setIs_live(1);
								setRtmp_url('');
							} else setRtmp_code_e(t('webinar_view.rtmp_err'));
						} catch {
							setLive_loading(false);
							setRtmp_code_e(t('webinar_view.rtmp_err'));
						}
					}
				})
				.catch(error => {
					setLive_loading(false);
					setRtmp_code_e(t('webinar_view.rtmp_err'));
				});
		} else {
			setLive_loading(false);
		}
	};
	const liveStopWebinar = () => {
		setLive_loading(true);
		getInstance()
			.post(`/api/v1/course/webinar/live-close`, {
				meeting_id: _.get(webinar_data, 'id'),
			})
			.then(response => {
				if (response.status === 200) {
					try {
						const { success } = response.data;
						setLive_loading(false);
						if (success === 1) {
							setIs_live(0);
							window.$('#modalWebinarLiveStop').modal('hide');
						} else setLive_stop_e(t('webinar_view.rtmp_stop_err'));
					} catch {
						setLive_loading(false);
						setLive_stop_e(t('webinar_view.rtmp_stop_err'));
					}
				}
			})
			.catch(error => {
				setLive_loading(false);
				setLive_stop_e(t('webinar_view.rtmp_stop_err'));
			});
	};
	return (
		<Layout>
			<main className="main">
				<section className="section section-breadcrumbs">
					<div className="container">
						<Navigation
							active={_.get(webinar_data, 'title')}
							navs={[
								{
									title: t('webinar_view.title'),
									link: '/webinars/list/1',
								},
							]}
						/>
					</div>
				</section>
				<section className="section pt-3">
					<div className="container">
						{loading ? (
							<Loading />
						) : webinar_data && webinar_data.id ? (
							<>
								<h3 className="mb-4">{_.get(webinar_data, 'title')} </h3>
								<div className="webinar-details">
									<div className="row justify-content-center">
										<div className="col-12">
											<div className="row">
												<div className="col-md-5 webinar-col-2 order-md-2">
													<div className="webinar-details__card">
														<img
															src={IMG_URL + _.get(webinar_data, 'image')}
															alt="Webinar"
															onError={e => {
																e.target.onerror = null;
																e.target.src = '/images/webinars/webinar.jpeg';
															}}
														/>
														<div className="webinar-details__card-caption">
															<div>
																<p>
																	<span className="icon icon-clock"></span>
																	{_.get(webinar_data, 'startdate')}
																</p>
																<p>
																	<span class="icon icon-video"></span>
																	{parseInt(
																		_.get(webinar_data, 'duration') / 60,
																	)}{' '}
																	{t('course_view.h')}{' '}
																	{parseInt(_.get(webinar_data, 'duration')) %
																		60}{' '}
																	{t('course_view.m')}
																</p>
															</div>
															<button
																type="button"
																className="btn btn-secondary-light"
																onClick={() => {
																	var inp = document.createElement('input');
																	document.body.appendChild(inp);
																	inp.value = is_early
																		? webinar_url
																		: is_duration
																			? _.get(webinar_data, 'join_url') +
																			'?token=' +
																			getToken()
																			: _.get(webinar_data, 'record_url')
																				? _.get(webinar_data, 'record_url')
																				: webinar_url;
																	inp.select();
																	document.execCommand('copy', false);
																	inp.remove();
																}}>
																<span className="icon icon-link"></span>
																{t('webinar_view.copy')}
															</button>
															{limit <= 0 || end_limit ? (
																is_duration ? (
																	<>
																		{getToken() ? (
																			<a
																				href={
																					_.get(webinar_data, 'join_url') +
																					'?token=' +
																					getToken()
																				}
																				target="blank"
																				className="btn btn-primary">
																				<span className="icon icon-play"></span>
																				{t('webinar_view.join')}
																			</a>
																		) : (
																			<button
																				className="btn btn-primary"
																				data-toggle="modal"
																				data-target="#modalWebinarJoin">
																				<span className="icon icon-play"></span>
																				{t('webinar_view.join')}
																			</button>
																		)}
																		{_.get(profile, 'id', 0) ===
																			_.get(webinar_data, 'author_id', -1) ? (
																			<>
																				{is_live !== 1 ? (
																					<button
																						className="btn btn-primary"
																						data-toggle="modal"
																						data-target="#modalWebinarLive">
																						<span className="icon icon-webinar"></span>
																						{t('webinar_view.live')}
																					</button>
																				) : (
																					<button
																						className="btn btn-primary"
																						data-toggle="modal"
																						data-target="#modalWebinarLiveStop">
																						<span className="icon icon-webinar"></span>
																						{t('webinar_view.live_stop')}
																					</button>
																				)}
																			</>
																		) : null}
																	</>
																) : (
																	<a
																		href={_.get(webinar_data, 'record_url')}
																		target="blank"
																		className="btn btn-primary">
																		<span className="icon icon-play"></span>
																		{t('webinar_view.join')}
																	</a>
																)
															) : (
																<button className="btn btn-primary">
																	<Timer
																		initialTime={limit}
																		direction="backward"
																		checkpoints={[
																			{
																				time: 0,
																				callback: () => setEndLimit(true),
																			},
																		]}>
																		{() => (
																			<>
																				<Timer.Days /> : <Timer.Hours /> :{' '}
																				<Timer.Minutes /> : <Timer.Seconds />
																			</>
																		)}
																	</Timer>
																</button>
															)}
															{props.profile.id === webinar_data.author_id && (
																<a
																	href={`/webinars/view/${props.match.params.slug}/users`}
																	className="btn btn-primary">
																	Foydalanuvchilar
																</a>
															)}
														</div>
													</div>
												</div>
												<div className="col-md-7 webinar-col-1 order-md-1">
													<div className="webinar-details__description">
														<p>{_.get(webinar_data, 'description')}</p>
														{_.get(webinar_data, 'presentation') ? (
															<a
																href={
																	FILE_URL + _.get(webinar_data, 'presentation')
																}
																className="btn btn-primary ml-auto mt-2"
																target="blank">
																{t('course_view.view-webinar')}
															</a>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<NoData text={t('webinar_view.no_data')} />
						)}
					</div>
				</section>
			</main>
			<div
				class="modal fade"
				id="modalWebinarJoin"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalWebinarJoinTitle"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">{t('webinar_view.join_title')}</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span class="icon icon-close"></span>
							</button>
						</div>
						<div class="modal-body">
							<div className="form-group mb-2">
								<label for="">{t('webinar_view.user_name')}</label>
								<input
									type="text"
									className="form-control"
									name="name"
									value={user_name}
									onChange={e => {
										setUserName(e.target.value);
										setUserNameE('');
									}}
								/>
								<div style={{ color: 'red' }}>
									{user_name_e ? user_name_e : <br />}
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<button
										type="button"
										class="btn btn-lighter btn-sm w-100"
										data-dismiss="modal">
										{t('webinar_view.cancel')}
									</button>
								</div>
								<div class="col-6">
									<button
										type="button"
										class="btn btn-primary btn-sm w-100"
										onClick={() => joinWebinar()}>
										{t('webinar_view.webinar_join')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal fade"
				id="modalWebinarLive"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalWebinarLiveTitle"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">{t('webinar_view.live_title')}</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span class="icon icon-close"></span>
							</button>
						</div>
						<div class="modal-body">
							<div className="form-group mb-2">
								<label for="">{t('webinar_view.rtmp_url')}</label>
								<input
									type="text"
									className="form-control"
									name="rtmp_url"
									value={rtmp_url}
									onChange={e => {
										setRtmp_url(e.target.value);
										setRtmp_url_e('');
									}}
								/>
								<div style={{ color: 'red' }}>
									{rtmp_url_e ? rtmp_url_e : <br />}
								</div>
							</div>
							<div className="form-group mb-2">
								<label for="">{t('webinar_view.rtmp_code')}</label>
								<input
									type="text"
									className="form-control"
									name="rtmp_code"
									value={rtmp_code}
									onChange={e => {
										setRtmp_code(e.target.value);
										setRtmp_code_e('');
									}}
								/>
								<div style={{ color: 'red' }}>
									{rtmp_code_e ? rtmp_code_e : <br />}
								</div>
							</div>
							<div class="row pb-5">
								<div class="col-6">
									<button
										type="button"
										class="btn btn-lighter btn-sm w-100"
										data-dismiss="modal">
										{t('webinar_view.cancel')}
									</button>
								</div>
								<div class="col-6">
									{live_loading ? (
										<Loading size={5} />
									) : (
										<button
											type="button"
											class="btn btn-primary btn-sm w-100"
											onClick={() => liveWebinar()}>
											{t('webinar_view.live_btn')}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal fade"
				id="modalWebinarLiveUserUrl"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalWebinarLiveUserUrlTitle"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">
								{t('webinar_view.live_title_user_url')}
							</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span class="icon icon-close"></span>
							</button>
						</div>
						<div class="modal-body">
							<div className="form-group mb-2">
								<label for="">{t('webinar_view.rtmp_url')}</label>
								<input
									type="text"
									className="form-control"
									name="rtmp_url"
									value={rtmp_url}
									onChange={e => {
										setRtmp_url(e.target.value);
										setRtmp_url_e('');
									}}
								/>
								<div style={{ color: 'red' }}>
									{rtmp_url_e ? rtmp_url_e : <br />}
								</div>
							</div>
							<div class="row pb-5">
								<div class="col-6">
									<button
										type="button"
										class="btn btn-lighter btn-sm w-100"
										data-dismiss="modal">
										{t('webinar_view.cancel')}
									</button>
								</div>
								<div class="col-6">
									{live_loading ? (
										<Loading size={5} />
									) : (
										<button
											type="button"
											class="btn btn-primary btn-sm w-100"
											onClick={() => liveWebinarUserUrl()}>
											{t('webinar_view.live_btn')}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal fade"
				id="modalWebinarLiveStop"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalWebinarLiveStopTitle"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">{t('webinar_view.live_stop_title')}</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span class="icon icon-close"></span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div className="col-12">
									<div style={{ color: 'red' }}>
										{live_stop_e ? live_stop_e : <br />}
									</div>
								</div>
								<div class="col-6">
									<button
										type="button"
										class="btn btn-lighter btn-sm w-100"
										data-dismiss="modal">
										{t('webinar_view.cancel')}
									</button>
								</div>
								<div class="col-6">
									{live_loading ? (
										<Loading size={5} />
									) : (
										<button
											type="button"
											class="btn btn-primary btn-sm w-100"
											onClick={() => liveStopWebinar()}>
											{t('webinar_view.live_stop_btn')}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getWebinar: id => dispatch(getWebinar(id)),
		getProfile: () => dispatch(getProfile()),
	}),
)(WebinarPage);
