import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, withHighcharts } from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import _ from 'lodash';
ChartModuleMore(Highcharts);

const Bool = (props) => {
    const { t } = useTranslation();
    const {result} = props;
    let points = [];
    let total_user = _.get(result, 'on', 0) + _.get(result, 'off', 0) + _.get(result, 'no_result', 0);

    points.push({ name:  t('survey_r.on') , y: _.get(result, 'on', 0)/total_user*100 , selected: true, color: '#86E1FB' });
    points.push({ name:  t('survey_r.off') , y: _.get(result, 'off', 0)/total_user*100 , selected: true, color: '#7CB5EC' });

    const no_result = parseInt(_.get(result, 'no_result', 0 )?_.get(result, 'no_result', 0 ):0)/total_user*100;
    if(no_result>0){
        points.push({ name:  t('survey_r.not_specified'), y: no_result, selected: true, color: '#3999FB' });
    }
    const chart = {
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: '#F7F7F7',
        borderRadius: 7
     }
    const tooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}"><b>{point.percentage:.1f}%</b><br/>'
    }
     const plotOptions = {
        pie: {
           shadow: false,
           center: ['50%', '50%'],
           size:'100%',
           innerSize: '50%' ,
           
           dataLabels: {
                enabled: true,
                distance: -50,
                pointFormat: '<b>{point.percentage:.1f}%</b>',
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            }           
        }
     }
     const series = [{
        type: 'pie',
        name: "",
        data: points
     }]
return (
      <div style={{width: '100%'}}>
        <HighchartsChart  
          chart={chart}
          series={ series}
          plotOptions = {plotOptions}
          tooltip={ tooltip}
        >
        </HighchartsChart>
      </div>
    );
}
export default withHighcharts(Bool, Highcharts);