import React from 'react';
import JoditTextShow from "./JoditTextShow";
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui-touch-punch';
import _ from 'lodash';

window.jQuery = $;

const TestResultGrouping = props => {
    
    const { question, number, answers_user } = props;
    
    return (<>
        <div id={"test_"+question.id} className="test__item card">
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ '<b>'+(number+1)+'.</b> '+question.question }/>
                </div>
                <div className="test__body">
                    <div className="ttype--fill_group">
                        <div className="row">
                            {_.get(question, 'vanswers', []).map((item, index)=>(
                            <div className="col" key={index}>
                                <h4>{item.answer}</h4>
                                <div className={"test-drag-drop drop container_"+item.id+" ui-droppable"}>
                                    { JSON.parse( _.get(answers_user, item.id, '[]') ).map((u_answer, index_a)=>(
                                    <strong key={index_a} 
                                        style={ _.get(item, 'details', '').split(',').includes(u_answer)?{color: '#26CAAC', borderColor: '#26CAAC'}:{color: 'red', borderColor: 'red'}}
                                        className={"dragdrop"+number+" ui-draggable ui-draggable-handle"} >
                                        {u_answer}
                                    </strong>
                                    ))}
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="fill_group__drag">
                        <div className={"test-drag-drop drop container_"+question.id+" ui-droppable"}>
                        {_.get(question, 'grouping_items', []).map((item, index)=>(
                            <strong className={"dragdrop"+number+" ui-draggable ui-draggable-handle"} key={index} data-value={item}>{item}</strong>
                        ))}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </>);
}
export default TestResultGrouping;