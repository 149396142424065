import React, {useEffect} from 'react';
import JoditTextShow from "./JoditTextShow";
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import  "jquery-ui-touch-punch";
import _ from 'lodash';

window.jQuery = $;

const LessonTestGrouping = props => {
    const { question, number } = props;
    useEffect(()=>{
        $(function() {
            $( '#test_'+question.id+' .dragdrop'+number ).draggable({
                revert: "invalid", 
                containment: "document",
                helper: "clone",
                cursor: "move",
                scroll: true
            });

           $("#test_"+question.id+"  .drop").droppable({
                accept: ".dragdrop"+number,
                drop: function( event, ui ) {
                   
                    var val = [];
                    var val1=[];
                    ui.draggable.parent().find('strong').each(function (item) {
                        if($(this).data('value')!==ui.draggable.data('value')){
                            val1.push( $(this).data('value') );
                        }
                    });
                    ui.draggable.parent().find('input').val( JSON.stringify(val1) );

                    ui.draggable.appendTo( $(this) );

                    $(this).find('strong').each(function (item) {
                        val.push( $(this).data('value') );
                    });

                    $(this).find('input').val( JSON.stringify(val) );
                }
            });
        });
    }, []);
    return (
        <div id={"test_"+question.id} className="test__item card">
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ '<b>'+(number+1)+'.</b> '+question.question }/>
                </div>
                <div className="test__body">
                    <div className="ttype--fill_group">
                        <div className="row">
                            {_.get(question, 'vanswers', []).map((item, index_d)=>(
                            <div className="col" key={index_d}>
                                <h4>{item.answer}</h4>
                                <div className={"test-drag-drop drop container_"+item.id+" ui-droppable"}>
                                    <input type="hidden" name={"answers["+question.id+"]["+item.id+"]"} value="[]"/>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    <div className="fill_group__drag">
                        <div className={"test-drag-drop drop container_"+question.id+" ui-droppable"}>
                        {_.get(question, 'grouping_items', []).map((item, index)=>(
                            <strong className={"dragdrop"+number+" ui-draggable ui-draggable-handle"} 
                                key={index} data-value={item}>
                                    {item}
                            </strong>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LessonTestGrouping;