import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import { getCourse, getCourseSections, getCourseLesson } from "../actions";
import { Link } from 'react-router-dom';
import CourseSideBar from "./sections/CourseSideBar";
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import Layout from './Layout';
import TestResult from "./sections/TestResult";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import CourseBtns from './sections/CoutceBtns';
import LessonExchange from './sections/LessonExchange';

const DashLessonTestResult = (props) => {
    const { t } = useTranslation();
    const {course, course_lesson, test_check, /* course_lesson_status*/} = props;
    // const [loading, setLoading] = useState(true);
    let slug = props.match.params.slug?props.match.params.slug:'';
    let section = props.match.params.section?props.match.params.section:'';
    let lesson_id = props.match.params.lesson_id?props.match.params.lesson_id:'';
    useEffect(()=>{
        props.getCourseLesson(slug,section,lesson_id);
        props.getCourse(slug);
        props.getCourseSections(slug, section);
        slug = props.match.params.slug?props.match.params.slug:'';
    },[props.match.params.slug, props.match.params.section, props.match.params.lesson_id]);
   

    return(<Layout>
        {/* <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar />
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                            />
                            <TestResult test_check={test_check}/>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main> */}

			<CheckRole roles={ [ roles.teacher, roles.user ] } />
        <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={_.get(course_lesson, 'data.name')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        title: t('dash_side_bar.course'),
                                        link: "/dashboard/courses/list/1"
                                    },
                                    {
                                        title: _.get(course, 'data.name'),
                                        link: "/dashboard/courses/view/"+_.get(course, 'data.slug')
                                    }
                                ]}
                            />
                            <div className="dashboard-title">
                                <h3>{course.data&&course.data.name?course.data.name:''}</h3>
                            </div>
                            <div className="has-right-buttons">
                                <div className="has-right-buttons__right">
                                <CourseBtns  course={course}  slug={slug} dash={true}/>

                                </div>
                                <div className="has-right-buttons__left">
                                    <TestResult test_check={test_check}  course_lesson={course_lesson}/>
                                    <LessonExchange is_dash={true} params={_.get(props, 'match.params', {})}/>
                                </div>
                            </div>
                            <CourseSideBar  slug={slug}  section={section} />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
    </Layout>
    )
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug)=>dispatch(getCourse(slug)),
        getCourseSections: (slug, section)=>dispatch(getCourseSections(slug, section)),
        getCourseLesson: (course_slug, section_slug, lesson_id)=>dispatch(getCourseLesson(course_slug, section_slug, lesson_id))
    })
)(DashLessonTestResult);