import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logic from './Logic/Logic';

const BooleanCreator = props => {
	const { t } = useTranslation();
	const [language, setLanguage] = useState(false);
	const { survey, surveys, number, setSurveyItem, deleteSurvey } = props;
	return (
		<div className="test__item card mt-3">
			<div className="card-body row">
				<div className="col-12">
					<h5 style={{ display: 'inline' }}>
						{number}-{t('survey_c.question')} | {t('survey_c.id')}: {survey.id}
					</h5>
					<span
						className="button"
						style={{
							color: '#f00',
							margin: 'auto 10px',
							cursor: 'pointer',
							background: 'rgba(137, 141, 166, 0.1)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							float: 'right',
							width: 32,
							height: 32,
							borderRadius: 8,
						}}
						onClick={() => deleteSurvey()}>
						<span className="icon icon-remove"></span>
					</span>
					<span
						className="button"
						style={{
							color: '#898DA6',
							margin: 'auto 10px',
							cursor: 'pointer',
							float: 'right',
							background: 'rgba(137, 141, 166, 0.1)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: 32,
							height: 32,
							borderRadius: 8,
						}}
						onClick={() => props.setSurveyTest(survey)}
						data-toggle="modal"
						data-target="#modalSurveyTest">
						<span className="icon icon-eye"></span>
					</span>
				</div>
				<div className="col-12">
					<h5>
						{t('survey_c.question-type')}
						{t('survey_c.true-or-false')}
					</h5>
				</div>
				<div className="col-12" style={{ margin: '20px 0' }}>
					<div className="setting__buttons " style={{ justifyContent: 'right', flexDirection: 'column' }}>
						<label>{t('survey_c.isRequired')}</label>
						<div style={{ display: 'flex', margin: '5px 0 0 0 ' }}>
							<label className="checkbox-custom" style={{ margin: '0 10px 0 0 ' }}>
								<input
									type="checkbox"
									checked={survey.isRequired}
									name="input"
									onChange={e => {
										setSurveyItem({ ...survey, isRequired: e.target.checked });
									}}
								/>
								<span className="checkmark" style={{ width: 24, height: 24 }}></span>
							</label>
							<span style={{ color: '#0D2E69', fontWeight: 500, fontSize: 14 }}>
								{t('survey_c.required')}
							</span>
						</div>
					</div>
				</div>
				<div className="col-12">
					<button
						className="btn btn-sm btn-light"
						onClick={() => setLanguage(false)}
						style={{
							borderRadius: '8px 8px 0 0',
							color: '#0D2E69',
							border: 'none',
							fontWeight: 500,
							background: language ? 'white' : 'rgba(137, 141, 166, 0.08)',
						}}>
						{t('survey_c.uzbek')}
					</button>
					<button
						className="btn btn-sm btn-light"
						onClick={() => setLanguage(true)}
						style={{
							borderRadius: '8px 8px 0 0',
							color: '#0D2E69',
							border: 'none',
							fontWeight: 500,
							background: language ? 'rgba(137, 141, 166, 0.08)' : 'white',
						}}>
						{t('survey_c.russian')}
					</button>
					<div
						className="form-group p-5"
						style={{
							background: 'rgba(137, 141, 166, 0.08)',
							borderRadius: language ? 8 : '0 8px 8px 8px',
						}}>
						<p style={{ fontWeight: 500, fontSize: 12 }}>
							{t('survey_c.question-name')}
							{survey.isRequired ? <b>* </b> : null}
						</p>
						{language ? (
							<input
								type="text"
								value={survey.name_ru}
								style={{
									background: '#FFFFFF',
									height: 40,
									borderRadius: 8,
									width: '100%',
									border: 'none',
									padding: '0 0 0 16px',
								}}
								onChange={e => {
									setSurveyItem({ ...survey, name_ru: e.target.value });
								}}
							/>
						) : (
							<input
								type="text"
								value={survey.name_uz}
								style={{
									background: '#FFFFFF',
									height: 40,
									borderRadius: 8,
									width: '100%',
									border: 'none',
									padding: '0 0 0 16px',
								}}
								onChange={e => {
									setSurveyItem({ ...survey, name_uz: e.target.value });
								}}
							/>
						)}
						{survey.isRequired ? (
							<>
								<p style={{ fontWeight: 500, fontSize: 12, margin: '10px 0' }}>Текст ошибки</p>
								{language ? (
									<input
										type="text"
										style={{
											height: 40,
											width: '100%',
											border: 'none',
											borderRadius: 8,
											padding: '0 0 0 16px',
										}}
										value={survey.error_ru}
										onChange={e => {
											setSurveyItem({ ...survey, error_ru: e.target.value });
										}}
									/>
								) : (
									<input
										type="text"
										style={{
											height: 40,
											width: '100%',
											border: 'none',
											borderRadius: 8,
											padding: '0 0 0 16px',
										}}
										value={survey.error_uz}
										onChange={e => {
											setSurveyItem({ ...survey, error_uz: e.target.value });
										}}
									/>
								)}
							</>
						) : null}
					</div>
				</div>
				<div className="col-12">
					<Logic
						survey={survey}
						surveys={surveys}
						setLogicList={logic => setSurveyItem({ ...survey, logic: logic })}
					/>
				</div>
			</div>
		</div>
	);
};
export default BooleanCreator;
