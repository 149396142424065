import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCourse, getCourseLesson, getCourseSections } from '../actions';
import Layout from './Layout';
import CourseSideBarLeft from './sections/CourseSideBarLeft';
import LessonVideo from './sections/LessonVideo';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import CourseBtns from './sections/CoutceBtns';
import LessonExchange from './sections/LessonExchange';
import WaitLesson from './sections/WaitLesson';

const LessonVideoPage = props => {
	const { t } = useTranslation();
	const { course, course_lesson, course_lesson_status } = props;
	const [loading, setLoading] = useState(true);
	let slug = props.match.params.slug ? props.match.params.slug : '';
	let section = props.match.params.section ? props.match.params.section : '';
	let lesson_id = props.match.params.lesson_id ? props.match.params.lesson_id : '';
	useEffect(() => {
		props.getCourseLesson(slug, section, lesson_id);
		props.getCourse(slug);
		props.getCourseSections(slug, section);
		slug = props.match.params.slug ? props.match.params.slug : '';
		document.title = _.get(course, 'data.name', t('courses.title'));

	}, [props.match.params.slug, props.match.params.section, props.match.params.lesson_id]);
	useEffect(() => {
		if (course_lesson_status.status === 'started') {
			setLoading(true);
		}
		if (course_lesson_status.status === 'success') {
			setLoading(false);
			props.getCourseSections(slug, section);
		}
		if (course_lesson_status.status === 'error') {
			setLoading(false);
		}
	}, [course_lesson_status]);

	return (
		<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
			<main class="main">
				<section class="section section-breadcrumbs">
					<div class="container">
						<Navigation
							is_btn={true}
							active={_.get(course_lesson, 'data.name')}
							navs={[ 
								{
									title: _.get(course, 'data.name'),
									link: '/courses/view/' + _.get(course, 'data.slug'),
								},
							]}
						/>
					</div>
				</section>
				<section class="section section-course pt-3">
					<div class="container">
						<h3 class="mb-4">{course.data && course.data.name ? course.data.name : ''}</h3>
						<div class="row">
							<div class="col-lg-3">
								<CourseSideBarLeft slug={slug} section={section}/>
							</div>
							<div className="col-lg-9 course_btns_target">
								<div className="has-right-buttons__right">
								<CourseBtns course={course}  slug={slug} dash={false}/>

								</div>
								<WaitLesson section={section}>
								{loading ? (
									<Loading />
								) : course_lesson.data  && course_lesson_status.status !== 'error' ? (
									<>
										<LessonVideo section={section} slug={slug}/>
									</>
								) : (
									<NoData text={t('course_view.not-course')} />
								)}</WaitLesson>
								{loading?'':<LessonExchange params={_.get(props, 'match.params', {})}/>}

							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getCourse: slug => dispatch(getCourse(slug)),
		getCourseSections: (slug, section) => dispatch(getCourseSections(slug, section)),
		getCourseLesson: (course_slug, section_slug, lesson_id) =>
			dispatch(getCourseLesson(course_slug, section_slug, lesson_id)),
	}),
)(LessonVideoPage);
