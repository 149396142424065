//--Api

// **api server 
export const API_HOST = '45.91.203.187:8017';
export const WS_HOST = '45.91.203.187:8017';
export const API_URL = `http://${API_HOST}`;
export const IMG_URL = `http://${API_HOST}/storage/`;
export const FILE_URL = `http://${API_HOST}/storage/`;
export const OFFLINE_EDU_URL = `https://offline.onlinedu.uz`;
// export const OFFLINE_EDU_URL = `http://localhost:3001`;

// **api server for testing

// export const API_HOST = "api.qtlms.uz";
// export const WS_HOST = "chat.qtlms.uz";
// export const API_URL = `https://${API_HOST}`;
// // export const IMG_URL = `https://${API_HOST}/storage/`;
// // export const FILE_URL = `https://${API_HOST}/storage/`;
// export const API_HOST_REMOTE = 'api.onlinedu.uz';
// export const IMG_URL = `https://${API_HOST_REMOTE}/storage/`;
// export const FILE_URL = `https://${API_HOST_REMOTE}/storage/`;
// export const OFFLINE_EDU_URL = `https://offline.qtlms.uz`;
