import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PhoneInput from "./PhoneInput";
import { forgotPassword } from "../../actions";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [commonError, setCommonError] = useState("");
  const { forgot_status, forgot } = props;

  useEffect(() => {
    if (forgot.success === 1) {
      props.actState(4);
      setLoading(false);
    }
    if (forgot.is_telegram) {
      props.actState(7);
      setLoading(false);
    }
    if (forgot_status.status === "started") {
      setLoading(true);
    } else {
      setLoading(false);
      if (forgot_status.status === "error") {
        setCommonError(forgot.error_msg);
      }
    }
  }, [forgot_status, forgot]);

  const onSubmit = (event) => {
    event.preventDefault();
    let p = true;
    if (phone.length !== 12) {
      setPhoneError(t("sign_in.phone_e"));
      p = false;
    } else {
      setPhoneError("");
      p = true;
    }
    if (p) {
      props.forgotPassword({
        login: phone,
      });
    }
  };
  return (
    <>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>{t("forgot.forgot")}</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'>
            <span className='icon icon-close'></span>
          </button>
        </div>
        <div className='modal-body'>
          <form onSubmit={onSubmit} noValidate>
            <div className='form-group mb-3'>
              <label for=''>{t("sign_in.phone")}</label>
              <PhoneInput
                            inputClass="form-control"
                onClickInput={() => {
                  setPhoneError("");
                  setCommonError("");
                }}
                setPhone={(phone) => setPhone(phone)}
              />
            </div>
            <div className='form-group mt-4 mb-5'>
              <div style={{ color: "red" }}>{commonError}</div>
              <div style={{ color: "red" }}>{phoneError}</div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <button type='submit' className='btn btn-primary w-100'>
                {t("sign_in.btn")}
              </button>
            )}
            <div className='form-group mt-4 mb-5'>
              <p
                style={{ color: "#26CAAC", cursor: "pointer" }}
                onClick={() => props.actState(0)}>
                {t("validate_sms.sign_in")}
              </p>
              {/* <p
                style={{ color: "#26CAAC", cursor: "pointer" }}
                onClick={() => props.actState(1)}>
                {t("validate_sms.sign_up")}
              </p> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default connect(
  (state) => ({
    forgot: state.forgot,
    forgot_status: state.forgot_status,
  }),
  (dispatch) => ({
    forgotPassword: (data) => dispatch(forgotPassword(data)),
  })
)(ForgotPassword);
