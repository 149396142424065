import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Loading from '../Loading';

const Step4 = (props) => {
  const {
    disabled,
    setStep,
    step,
    FILE_URL,
    edu,
    fileTeacherData,
    file_nameFileTeacher_data,
    fileNoteBook,
    file_nameFileNote_book,
    submitTest5,
    note,
    setNote,
    loading,
    setEdu,
    setErrorEdu,
    errorEdu,
  } = props;

  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest5}>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>
            Pedagog kadrlarning ilmiy-pedagogik salohiyati
          </h6>
          {errorEdu.teacher_data || errorEdu.note_book ? (
            <div className='inputError'>{t('edu_institution.main_err')}</div>
          ) : null}
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ display: 'block' }}>
                O’qituvchilar tarkibi bo’yicha ma’lumot (.pdf yuklash)
              </span>
              <a
                href={'/docs/Профессор_ўқитувчилар.pdf'}
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{
                  display: 'inline-block',
                  marginTop: '10px',
                  marginRight: '10px',
                }}
                target='_blank'
              >
                {t('edu_institution.eye3')}
              </a>
              <a
                href={
                  '/docs/НАМУНА_Профессор_ўқитувчилар_тўғрисида_маълумот_2.xlsx'
                }
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye4')}
              </a>
            </label>

            <label
              className='edu_institution_input edu_institution_file mb-3'
              style={errorEdu.teacher_data ? { borderColor: '#f00' } : {}}
            >
              {file_nameFileTeacher_data &&
              file_nameFileTeacher_data !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameFileTeacher_data}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileTeacherData(e);
                  setErrorEdu({ ...errorEdu, teacher_data: false });
                }}
                accept='.pdf'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.teacher_data ? (
              <a
                href={FILE_URL + edu.teacher_data}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Kurslarda dars
          beradigan o‘qituvchilar kamida magistr darajasiga ega bo‘lishi va
          o'qituvchilar tarkibi bo'yicha ma'lumot ta'lim tashkiloti rahbarining{' '}
          <span style={{ fontWeight: 800 }}>imzosi</span> va{' '}
          <span style={{ fontWeight: 800 }}>muhri</span> bilan tasdiqlangan
          bo'lishi kerak.
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              Qayd etish kitobi (Mavjud/Mavjud emas) (.pdf yuklash)
            </label>

            <div
              className='setting__buttons mb-4'
              style={{ justifyContent: 'flex-start' }}
            >
              <label className='switch-text'>
                {disabled === true ? (
                  <div className='disabledSwitchDiv' />
                ) : null}
                <input
                  disabled={disabled}
                  type='checkbox'
                  checked={edu.note === 0 ? true : false}
                />
                <span className='slider'></span>
                <div className='switch-labels'>
                  <span
                    className='switch-label'
                    onClick={() => setEdu({ ...edu, note: 1 })}
                  >
                    Mavjud
                  </span>
                  <span
                    className='switch-label'
                    onClick={() => setEdu({ ...edu, note: 0 })}
                  >
                    Mavjud emas
                  </span>
                </div>
              </label>
            </div>

            {edu.note === 1 ? (
              <>
                <label
                  className='edu_institution_input edu_institution_file mb-3'
                  style={errorEdu.note_book ? { borderColor: '#f00' } : {}}
                >
                  {file_nameFileNote_book &&
                  file_nameFileNote_book !== FILE_URL ? (
                    <b style={{ fontSize: 16 }}>{file_nameFileNote_book}</b>
                  ) : (
                    <img
                      src='/images/user/upload.png'
                      alt='education'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/user/upload.png';
                      }}
                    />
                  )}
                  <input
                    type='file'
                    name='file'
                    alt='education file'
                    hidden
                    disabled={disabled}
                    onChange={(e) => {
                      fileNoteBook(e);
                      setErrorEdu({ ...errorEdu, note_book: false });
                    }}
                    accept='.pdf'
                  />
                  <div>{t('edu_institution.file')}</div>
                </label>
                {edu.note_book ? (
                  <a
                    href={FILE_URL + edu.note_book}
                    className='btn btn-primary btn-sm mb-3'
                    target='_blank'
                  >
                    <span class='icon icon-eye'></span>{' '}
                    {t('edu_institution.eye')}
                  </a>
                ) : null}
                <div
                  className=''
                  style={{
                    color: '#e60000',
                    fontSize: 14,
                    marginBottom: '20px',
                  }}
                >
                  <span style={{ fontWeight: 800 }}>Izoh:</span> Ma’lumotnomalar
                  va sertifikatlarning berilishini qayd etish kitobining
                  elektron varianti .pdf shaklda yuklanadi.
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {disabled ? null : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <button
                className='btn btn-primary btn-sm'
                style={{ marginRight: '15px' }}
                onClick={() => {
                  setStep(step - 1);
                  window.scroll(0, 0);
                }}
              >
                Oldingi
              </button>
              <button className='btn btn-primary btn-sm' type='submit'>
                Keyingi
              </button>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default Step4;
