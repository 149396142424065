import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts
} from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const ChartDoughnut = (props) => {
    const { t } = useTranslation();
    const {data, total_hours} = props;
        let points = [];
        let total_hours_user = 0;
        data.forEach(item => {
            total_hours_user += parseInt( item.user_hours_count?item.user_hours_count:0 );
        });
        points.push({ name: t('statistics.remaining_hours'), y: parseInt(total_hours-total_hours_user)/total_hours*100, selected: true, color: "#ff0000" });
        data.forEach(item => {
            const p = parseInt(item.user_hours_count?item.user_hours_count:0)/total_hours*100;
            if(p>0){
                points.push({ name: item.name, y: p, selected: true,  });
            }
        });

    const chart = {
        plotBorderWidth: null,
        plotShadow: false
     }
    const tooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}"><b>{point.percentage:.1f}%</b><br/>'
    }
     const plotOptions = {
        pie: {
           shadow: false,
           center: ['50%', '50%'],
           size:'100%',
           innerSize: '60%' ,
           dataLabels: {
                enabled: false
           },           
        }
     }
     const series = [{
        type: 'pie',
        name: "O'qilgan",
        data: points
     }]

return (
      <div style={{width: '100%'}}>
      
        <HighchartsChart  
          chart={chart}
          series={ series}
          plotOptions = {plotOptions}
          tooltip={ tooltip}
        >
        </HighchartsChart>
            
      </div>
    );
  
}

export default withHighcharts(ChartDoughnut, Highcharts);

