import React, {useState, useEffect} from 'react';
import { updatePassword } from "../../actions";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import PasswordInput from "./PasswordInput";
import Loading from "./Loading";
import $ from 'jquery';

const PasswordUpdate = (props) => {
    const { t } = useTranslation();
    const { update_password, update_password_status } = props;
    const [old_password, setOldPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [new_password_confirm, setnewPasswordConfirm] = useState('');
    const [old_password_e, setOldPasswordError] = useState('');
    const [new_password_e, setNewPasswordError] = useState('');
    const [new_password_confirm_e, setNewPasswordConfirmError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(update_password_status.status === 'success' && update_password.success === 1 ){
            $('#modalChangePassword').hide();
            $('.modal-backdrop').hide();
            $( "body" ).removeClass( "modal-open" );
            setOldPassword('');
            setNewPassword('');
            setnewPasswordConfirm('');
            setLoading(false);
        }
        if(update_password_status.status === 'error'){
            setLoading(false);
            setOldPasswordError(update_password.msg);
        }
    },[update_password_status])

    const setPassword = () => {
        let o=true, n=true, c=true;
        if(old_password === "" ){
            o=false;
            setOldPasswordError( t('new_password.error_old_password') );
        }
        if(new_password === "" && old_password.length<8){
            n=false;
            setNewPasswordError(t('new_password.error_new_password'));
        }
        if(new_password_confirm === "" && new_password_confirm.length<8){
            c=false;
            setNewPasswordConfirmError(t('new_password.error_confirm'));
        }
        if(n&&c&&new_password !== new_password_confirm){
            n=false;
            setNewPasswordError(t('new_password.error_equality'));
        }
        if(o&&n&&c){
            setLoading(true);
            props.updatePassword(
                {
                    'old_password': old_password,
                    'new_password': new_password,
                    'new_password_confirm': new_password_confirm,
                }
            );
        }
    }
    return (
        <div className="modal fade" id="modalChangePassword" tabindex="-1" role="dialog" aria-labelledby="modalChangePasswordTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('new_password.title')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon icon-close"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-3">
                            <label for="">{t('new_password.old_pass')}</label>
                            <div className="password-field-wrapper">
                                <PasswordInput
                                    className="form-control"
                                    setPassword={(p)=>setOldPassword(p)}
                                    onClickInput={()=>{setOldPasswordError("");} }
                                />
                                <span style={{color: 'red'}}>{old_password_e}</span>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label for="">{t('new_password.new_pass')}</label>
                            <div className="password-field-wrapper">
                                <PasswordInput 
                                    className="form-control"
                                    setPassword={(p)=>setNewPassword(p)}
                                    onClickInput={()=>{setNewPasswordError("");} }
                                />
                                <span style={{color: 'red'}}>{new_password_e}</span>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label for="">{t('new_password.r_new_pass')}</label>
                            <div className="password-field-wrapper">
                                <PasswordInput
                                    className="form-control"
                                    setPassword={(p)=>setnewPasswordConfirm(p)}
                                    onClickInput={()=>{setNewPasswordConfirmError("");} }
                                />
                                <span style={{color: 'red'}}>{new_password_confirm_e}</span>
                            </div>
                        </div>
                        {loading?<Loading/>:
                        <button type="button" className="btn btn-primary w-100"
                            onClick={()=>setPassword()}
                        >
                            {t('new_password.btn')}
                        </button>}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default  connect(
    (state) => ({
        update_password: state.update_password,
        update_password_status: state.update_password_status,
    }),
    (dispatch) => ({
        updatePassword: (data)=>dispatch(updatePassword(data))
    })
)(PasswordUpdate);