import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import VideoPlayer from "./VideoPlayer";
import JoditTextShow from "./JoditTextShow";
import { getCourse, getCourseSections } from '../../actions';


const LessonVideo = (props) => {
    const {course ,course_lesson, section, slug, getCourseSections, getCourse} = props;
    const targetCourseCheckCertifacate = () => {
        if(course?.data?.type??''==='target'){
            props.getCourse(slug);
        }
    }
    return (<>
        {_.get(course_lesson, 'data.file')?
            <VideoPlayer course_lesson={course_lesson}  section={section} getSection={true}  
                 getCourse={targetCourseCheckCertifacate}
                getCourseSections={()=>getCourseSections(slug, section)}/>
        :<div className="authors-empty mt-big" style={{marginTop: 0}}>
            <div className="authors-empty__icon"></div>
            <div className="authors-empty__message">
                <p>Video yo'q</p>
            </div>    
        </div>}
        <h3 class="mb-3">{_.get(course_lesson, 'data.name')}</h3>
        <JoditTextShow text={ _.get(course_lesson, 'data.description') } />
    </>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug)=>dispatch(getCourse(slug)),
        getCourseSections: (slug, section) => dispatch(getCourseSections(slug, section))
    })
)(LessonVideo);