import React from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";


function DashWebinarPage() {
  return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                            />
                            <div className="dashboard-title">
                                <h5>Сертификаты</h5>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card--shadow certificate">
                                        <div className="card-body">
                                            <a href="javacript:;" className="certificate__image" data-toggle="modal" data-target="#modalCertificate_1">
                                                <img src="/images/certificate.png" alt="Certificate"/>
                                            </a>
                                            <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <img src="/images/certificate.png" alt="Certificate"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:;" className="certificate__title" data-toggle="modal" data-target="#modalCertificate_1">Сертификат об успешном прохождении квалификации</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card--shadow certificate">
                                        <div className="card-body">
                                            <a href="javacript:;" className="certificate__image" data-toggle="modal" data-target="#modalCertificate_1">
                                                <img src="/images/certificate.png" alt="Certificate"/>
                                            </a>
                                            <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <img src="/images/certificate.png" alt="Certificate"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:;" className="certificate__title" data-toggle="modal" data-target="#modalCertificate_1">Сертификат об успешном прохождении квалификации</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card--shadow certificate">
                                        <div className="card-body">
                                            <a href="javacript:;" className="certificate__image" data-toggle="modal" data-target="#modalCertificate_1">
                                                <img src="/images/certificate.png" alt="Certificate"/>
                                            </a>
                                            <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <img src="/images/certificate.png" alt="Certificate"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:;" className="certificate__title" data-toggle="modal" data-target="#modalCertificate_1">Сертификат об успешном прохождении квалификации</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card--shadow certificate">
                                        <div className="card-body">
                                            <a href="javacript:;" className="certificate__image" data-toggle="modal" data-target="#modalCertificate_1">
                                                <img src="/images/certificate.png" alt="Certificate"/>
                                            </a>
                                            <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <img src="/images/certificate.png" alt="Certificate"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:;" className="certificate__title" data-toggle="modal" data-target="#modalCertificate_1">Сертификат об успешном прохождении квалификации</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card--shadow certificate">
                                        <div className="card-body">
                                            <a href="javacript:;" className="certificate__image" data-toggle="modal" data-target="#modalCertificate_1">
                                                <img src="/images/certificate.png" alt="Certificate"/>
                                            </a>
                                            <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <img src="/images/certificate.png" alt="Certificate"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:;" className="certificate__title" data-toggle="modal" data-target="#modalCertificate_1">Сертификат об успешном прохождении квалификации</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card--shadow certificate">
                                        <div className="card-body">
                                            <a href="javacript:;" className="certificate__image" data-toggle="modal" data-target="#modalCertificate_1">
                                                <img src="/images/certificate.png" alt="Certificate"/>
                                            </a>
                                            <div className="modal certificate__modal fade" id="modalCertificate_1" tabindex="-1" role="dialog" aria-labelledby="modalCertificate_1Title" aria-hidden="true">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <img src="/images/certificate.png" alt="Certificate"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:;" className="certificate__title" data-toggle="modal" data-target="#modalCertificate_1">Сертификат об успешном прохождении квалификации</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>


  </Layout>);
}
export default DashWebinarPage;