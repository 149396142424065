import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts
} from 'react-jsx-highcharts';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

const ChartSpider = (props) => {
	const { t } = useTranslation();
    const {data, exercise} = props;
    let names = [];
    let percents = [];
    let diagnostics = [];
    data.map((cat)=>{
        const user_score = parseInt( cat.category.user_score?cat.category.user_score:0 );
        let user_percent = parseInt( user_score/cat.category.total_score*100 );
        user_percent = user_percent>100?100:user_percent;
        names.push(cat.name);
        percents.push(user_percent?user_percent:0);
        diagnostics.push(cat.percent?cat.percent:0);
    })
    const chartxaxis = {
        categories: names,
        tickmarkPlacement: 'on',
        lineWidth: 0
    }
    const chartseries = [
        {
            name: exercise?t('exercise.exercise_test'):t('profile-page.result-diagnostic'),
            data: diagnostics,
            pointPlacement: 'on',
            color: 'red',
        },
        {
            name: t('profile-page.result-level'),
            data: percents,
            pointPlacement: 'on',
            color: '#26CAAC'
        },

    ];
    const plotOptions = {
        solidgauge: {
        dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
        }
        }
    };
    const chartype = {
        polar: true,
        type: 'line'
    }
    //   const chartitle = {
    //       text: 'Budget vs spending',
    //       x: -80
    //   }
    const chartpane = {
        size: '80%'
    }
    const chartyaxis = {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
    }
    const chartooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}%</b><br/>'
    }
    const chartlegend = {
        accessibility:{
            enabled:false,
        },
        keyboardNavigation:{
            enabled:true
            }
    }
return (
      <div style={{width: '100%'}}>
        <HighchartsChart polar plotOptions={plotOptions} 
          chart={chartype}
          pane={chartpane}
          xAxis={ chartxaxis}
          yAxis={chartyaxis}
          tooltip={ chartooltip}
          legend={chartlegend}
          series={chartseries}
        >
        </HighchartsChart>
      </div>
    );
}
export default withHighcharts(ChartSpider, Highcharts);
