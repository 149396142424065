import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PhoneInput from '../PhoneInput';

const Input = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('')
    const submitHandler = event => {
		event.preventDefault();
		const formData = new FormData(event.target);
        const input = formData.get('input');
        if( _.get(survey, 'isRequired', false) && ( !input || ( _.get(survey, 'inputType', '')==='phone'&&input.length!==12 ) )  ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: input, type: survey.type});
        }
    }
    return(
            <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.input') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            {survey.inputType==='textarea'?
                                <textarea
                                    className="form-control"
                                    rows="4"
                                    name="input"
                                    value={_.get(result, 'value', '')}
                                    onChange={e=>{ if(e.target.value.length<=250){surveyChange( {id: survey.id, value: e.target.value} )} } }
                                    onFocus={()=>setError('')}
                                ></textarea>
                                :survey.inputType==='phone'?<>
                                <PhoneInput
                                inputClass="form-control"
                                    onClickInput={()=>setError('')}
                                    setPhone={(phone)=>surveyChange( {id: survey.id, value: phone} )}
                                    value={_.get(result, 'value', '')}
                                />
                                <input name="input" type="hidden" value={_.get(result, 'value', '')} />
                                </>:survey.inputType==='range'?<>
                                <div>
                                    {_.get(result, 'value', '')?
                                    <span style={{marginLeft: (_.get(result, 'value', 0)-2-_.get(result, 'value', 0)*0.02)+'%'}} className="survey-range">
                                        {_.get(result, 'value', 0)}
                                    </span>
                                    :<span className={error?"form-control  survey-error":"form-control"} style={{width: 100}}> 
                                        { t('survey_i.range') } 
                                    </span>}
                                </div>
                                <input 
                                    type='range'
                                    className="form-control px-0"
                                    name="input"
                                    value={_.get(result, 'value', 0)}
                                    onChange={e=>surveyChange( {id: survey.id, value: e.target.value} )}
                                    onFocus={()=>setError('')}
                                />
                                </>:<input
                                    type={_.get(survey, 'inputType', 'text')}
                                    className="form-control"
                                    name="input"
                                    value={_.get(result, 'value', '')}
                                    onChange={e=>{ if(e.target.value.length<=30){surveyChange( {id: survey.id, value: e.target.value} )} } }
                                    onFocus={()=>setError('')}
                                />}
                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default Input;