import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import JoditText from './JoditText';
import JoditTextShow from './JoditTextShow';
import Select from 'react-select';
import Loading from './Loading';
import { connect } from 'react-redux';
import { addDiagnosticQuestion, deleteDiagnosticQuestion } from '../../actions';

const DiagnosticsTestQuestionChange = props => {
	const { t } = useTranslation();
	const [edit, setEdit] = useState(false);
	const [question, setQuestion] = useState('');
	const [question_e, setQuestionE] = useState('');
	const [type, setType] = useState({ value: 'single', label: t('diagnostics.single') });
	const { index, question_data, diagnostics_question_status, diagnostics_delete_question_status } = props;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (diagnostics_question_status.status === 'success') {
			setLoading(false);
			setEdit(false);
		}
		if (diagnostics_question_status.status === 'error') {
			setLoading(false);
			// setEdit(false);
		}
	}, [diagnostics_question_status]);
	useEffect(() => {
		if (diagnostics_delete_question_status.status === 'success') {
			setLoading(false);
			setEdit(false);
		}
		if (diagnostics_delete_question_status.status === 'error') {
			setLoading(false);
			// setEdit(false);
		}
	}, [diagnostics_delete_question_status]);

	useEffect(() => {
		setQuestion(question_data.question);
		setType(
			question_data.type === 'multiple'
				? { value: 'multiple', label: t('diagnostics.multiple') }
				: { value: 'single', label: t('diagnostics.single') },
		);
	}, [question_data]);

	const onSubmit = () => {
		if (question) {
			setLoading(true);
			const data = {
				...question_data,
				question: question,
				type: type.value,
			};
			props.addDiagnosticQuestion(data);
		} else {
			setQuestionE(t('diagnostics.q_e'));
		}
	};

	return (
		<>
			<div className="card card--shadow  mb-0">
				<div className="card-body card-body-diagnostics">
					<div className="row">
						<div className="col-12 dig-title">
							<b>
								{index + 1}-{t('diagnostics.q')}
							</b>
							{edit ? (
								<span className="icon icon-close" onClick={() => setEdit(false)}></span>
							) : (
								<span className="icon icon-edit" onClick={() => setEdit(true)}></span>
							)}
						</div>

						{edit ? (
							<>
								<div className="col-12 mb-1">
									<label>{t('diagnostics.question')}</label>
									<JoditText
										value={question}
										setValue={v => setQuestion(v)}
										setValueE={e => setQuestionE(e)}
									/>
									<div style={{ color: 'red' }}>{question_e}</div>
								</div>

								<div className="col-md-6">
									<div className="form-group mb-1">
										<label>
											{t('diagnostics.type')}
											<div>
												<Select
													value={type}
													onChange={select => {
														setType(select);
													}}
													options={[
														{ value: 'single', label: t('diagnostics.single') },
														{ value: 'multiple', label: t('diagnostics.multiple') },
													]}
													placeholder={t('diagnostics.type')}
												/>
											</div>
										</label>
									</div>
								</div>
								<div className="col-md-6 btn-target">
									{loading ? (
										<Loading />
									) : (
										<>
											<button
												className="btn btn-primary mb-1 mr-1 btn-sm"
												onClick={() => onSubmit()}>
												{t('diagnostics.save')}
											</button>
											<button
												className="btn btn-lighter mb-1 btn-sm"
												onClick={() => {
													setLoading(true);
													props.deleteDiagnosticQuestion({ id: question_data.id });
												}}>
												{t('diagnostics.delete')}
											</button>
										</>
									)}
								</div>
							</>
						) : <>
							<div className="col-12 col-md-9 col-lg-10 dig-title" >
								<div className="test-items-target"  style={{width: '100%'}}> <JoditTextShow text={question ? question : '<p></p>'} /> </div>
							</div>
							<div className="col-12 col-md-3 col-lg-2 dig-title pl-0" >
								<div className="dig-items-terget">
									<div className="test-items-target mr-2 mb-2 dig-item">  {question_data.score} {t('diagnostics.ball')}</div>
									<div className="test-items-target  dig-item">  {
										type.value==='single'?t('diagnostics.single'):t('diagnostics.multiple')
									}</div>
								</div>
							</div>
						</>}
					</div>
				</div>
			</div>
		</>
	);
};
export default connect(
	state => ({
		diagnostics_question_status: state.diagnostics_question_status,
		diagnostics_delete_question_status: state.diagnostics_delete_question_status,
	}),
	dispatch => ({
		addDiagnosticQuestion: data => dispatch(addDiagnosticQuestion(data)),
		deleteDiagnosticQuestion: data => dispatch(deleteDiagnosticQuestion(data)),
	}),
)(DiagnosticsTestQuestionChange);
