import React from 'react';
import { useTranslation } from 'react-i18next';
import JoditTextShow from "./JoditTextShow";

const TestResultSingle = (props) => {
    const { t } = useTranslation();
    const { question, number } = props;
    const passings = question.passings?question.passings:{};
    const details =  passings.details?parseInt(passings.details.slice(1,-1)):0;
    // console.log(passings.details);
    //const details = 331;
    return (<>
        <div className="test__item card test__item--disabled">
            <div className="card-body">
                <div className="test__item-question">
                    <div className="d-flex">
                        <div className="mr-2">{number+1}.</div>
                        <div>
                            <JoditTextShow text={question.question} />
                        </div>
                    </div>
                </div>
                <ul className="test__item-answers">
                    {question.vanswers.map((answer, index)=>{
                        
                        return(
                            <li key={index}>
                                <label className="radio-custom">
                                    <input type="radio" name={ 'answers['+answer.question_id+']'} 
                                        checked={details===answer.id?true:false}  disabled={true}/>
                                    <p>
                                        <JoditTextShow text={answer.answer} />
                                    </p>
                                    {details===answer.id?answer.score>0?
                                        <span className="text-primary ml-3">{t('test_result.right')} {answer.score} {t('test_result.score')} </span>
                                        :<span className="text-danger-light ml-3">{t('test_result.wrong')}</span>
                                    :null}
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                    )})}
                </ul>
            </div>
        </div>
        {/* <div className="card">
            <div className="card-body py-3">
                <div className="font-weight-medium text-secondary">
                    <p className="mb-1">{t('test_result.answer_is_wrong')}</p>
                    <p className="mb-1"><span className="text-primary">Правильный ответ:</span> “B” - Более менее осмысленного текста.
                        <br/>“B” - Более менее осмысленного текста.</p>
                </div>
            </div>
        </div> */}
      </>);
    
}
export default TestResultSingle;
