import React, { useState } from 'react';
import _ from 'lodash';
import LessonTestMultiple from './LessonTestMultiple';
import LessonTestSingle from './LessonTestSingle';
import Loading from './Loading';
import { useTranslation } from 'react-i18next';

const DiagnosticTestPerformance = props => {
	const { t } = useTranslation();
	const [send_loading, setSendLoading] = useState(false);

	const { tests } = props;

	const submitHandler = event => {
		event.preventDefault();
		setSendLoading(true);
		const formData = new FormData(event.target);
		props.postTests(formData);
	};
	let number = 0;
	return (
		<>
			<form onSubmit={submitHandler} enctype="application/json">
				{tests.data.vquestions.map(questions => (
					<>
						{questions.map((question, index) => {
							return (
								<>
									{question.type === 'single' ? (
										<LessonTestSingle question={question} key={index} number={number++} />
									) : (
										<LessonTestMultiple question={question} key={index} number={number++} />
									)}
								</>
							);
						})}
					</>
				))}
				<div className="test__buttons">
					<div className="dashboard-buttons">
						{send_loading ? (
							<Loading />
						) : (
							<button type="submit" className="btn btn-primary ml-2">
								{t('diagnostics-test.test-end')}
							</button>
						)}
					</div>
				</div>
			</form>
		</>
	);
};
export default  DiagnosticTestPerformance;
