import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	getCitiesList,
	getPupilsOffline,
	getRegionList,
	getTeacherLanguages,
} from '../actions';
import { API_URL, FILE_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import { getToken } from '../helpers/tokenStorge';
import history from '../history';
import Layout from './Layout';
import CertificateModal from './sections/CertificateModal';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import ExcelExportModal from './sections/ExcelExportModal';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import Pagination from './sections/Pagination';

const ManagerPupilsOffline = props => {
	const { t } = useTranslation();
	const { pupils, pupils_status, region_list, cities_list, teacher_lan } =
		props;
	const [certificate, setCertificate] = useState({});
	const [loading, setLoading] = useState(true);
	const [search_loading, setSearchLoading] = useState(false);
	const [position_Type, setPosition_Type] = useState([]);
	const [schools, setSchools] = useState([]);
	const [schoolTypes, setSchoolTypes] = useState([]);
	const [edus, setEdus] = useState([]);
	const [eduGroups, seteduGroups] = useState([]);
	const [userPosition, setUserPosition] = useState([
		{
			id: '',
			name: '',
			hours: '',
			created_at: '',
		},
	]);
	const { page } = props.match.params;
	const [search, setSearch] = useState({
		year: '',
		region: '',
		city: '',
		school: '',
		status: '',
		username: '',
		full_name: '',
		created_at: '',
		last_online_at_from: '',
		last_online_at_to: '',
		certificate_date_to: '',
		certificate_date_from: '',
		hours_count: '',
		position_type_id: '',
		position_id: '',
		certificate_count: '',
		language: '',
		certificate_id: '',
		is_specialized: '',
		school_type: '',
		number: '',
		edu_institution_id: '',
	});
	const [is_Search, setIs_Search] = useState(2);

	const {
		is_specialized,
		school_type,
		year,
		certificate_id,
		region,
		city,
		school,
		language,
		status,
		username,
		full_name,
		created_at,
		hours_count,
		position_id,
		position_type_id,
		certificate_count,
		last_online_at_to,
		last_online_at_from,
		certificate_date_to,
		certificate_date_from,
		end_date,
		start_date,
		group_id,
		eduinstitution_id,
		number,
		edu_institution_id,
	} = search;
	const params = `${status ? '&status=' + status : ''}${
		end_date ? '&end_date=' + end_date : ''
	}${start_date ? '&start_date=' + start_date : ''}${
		group_id ? '&group_id=' + group_id : ''
	}${eduinstitution_id ? '&eduinstitution_id=' + eduinstitution_id : ''}${
		year ? '&year=' + year : ''
	}${certificate_id ? '&certificate_id=' + certificate_id : ''}${
		school ? '&school=' + school : ''
	}${is_specialized ? '&is_specialized=' + is_specialized : ''}${
		school_type ? '&school_type=' + school_type : ''
	}${language ? '&language=' + language : ''}${
		region ? '&region=' + region : ''
	}${city ? '&city=' + city : ''}${username ? '&username=' + username : ''}${
		full_name ? '&full_name=' + full_name : ''
	}${created_at ? '&created_at=' + created_at : ''}${
		hours_count ? '&hours_count=' + hours_count : ''
	}${position_type_id ? '&position_type_id=' + position_type_id : ''}${
		position_id ? '&position_id=' + position_id : ''
	}${certificate_count ? '&certificate_count=' + certificate_count : ''}${
		last_online_at_from ? '&last_online_at_from=' + last_online_at_from : ''
	}${last_online_at_to ? '&last_online_at_to=' + last_online_at_to : ''}${
		certificate_date_from
			? '&certificate_date_from=' + certificate_date_from
			: ''
	}${
		certificate_date_to ? '&certificate_date_to=' + certificate_date_to : ''
	}${number ? '&number=' + number : ''}${
		edu_institution_id ? '&edu_institution_id=' + edu_institution_id : ''
	}`;
	let auto_time = useRef(null);

	useEffect(() => {
		setSearchLoading(true);
		props.getPupilsOffline(
			page ? page : 1,
			window.location.search ? window.location.search : '?',
		);
		if (window.location.search) {
			const obj = JSON.parse(
				'{"' +
					decodeURI(window.location.search.substring(1))
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}',
			);
			setSearch({ ...search, ...obj });
			if (obj.region) props.getCitiesList(obj.region);
			if (obj.city) getSchools(obj.region, obj.city);
		}
		return () => {
			clearTimeout(auto_time.current);
		};
	}, [window.location.search, page]);
	useEffect(() => {
		if (is_Search !== 2)
			history.push(`/manager/offline-pupils/list/1?${params.substring(1)}`);
	}, [is_Search]);
	useEffect(() => {
		if (pupils_status.status === 'started') {
			setLoading(true);
		}
		if (pupils_status.status === 'success') {
			window.$('#modalFilter').modal('hide');
			setLoading(false);
			setSearchLoading(false);
		}
		if (pupils_status.status === 'error') {
			setLoading(false);
		}
	}, [pupils_status]);
	const changeParams = (e, child_search = {}, is_loading = true) => {
		const { value, name } = e.target;
		clearTimeout(auto_time.current);
		if (is_loading) {
			setSearchLoading(true);
			setSearch({
				...search,
				...child_search,
				[name]: value,
			});
			setIs_Search(!is_Search);
		} else {
			setSearchLoading(true);
			setSearch({
				...search,
				...child_search,
				[name]: value,
			});
			auto_time.current = setTimeout(() => {
				setIs_Search(!is_Search);
			}, 1500);
		}
	};
	const modalFilter = e => {
		const { value, name } = e.target;
		setSearch({ ...search, [name]: value });
	};

	useEffect(() => {
		props.getRegionList();
		props.getTeacherLanguages();
		getInstance()
			.get('/api/v1/profil/position-type')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setPosition_Type(data);
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/profil/user-position')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setUserPosition(data);
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/school-type')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response;
						setSchoolTypes(_.get(data, 'data', []));
					} catch {}
				}
			})
			.catch(error => console.log(error));
		getInstance()
			.get('/api/v1/edu-institution?per_page=200&status=1')
			.then(response => {
				if (response?.status === 200) {
					try {
						setEdus(_.get(response, 'data.data.data', []));
					} catch {}
				}
			})
			.catch(error => console.log(error));
	}, []);
	const getSchools = (r_id, c_id) => {
		getInstance()
			.get(`/api/v1/school?region_id=${r_id}&city_id=${c_id}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setSchools(data ? data : []);
					} catch {}
				}
			})
			.catch(error => {});
	};
	const getEduGruops = edu_id => {
		getInstance()
			.get(`/api/v1/edu-institutions/groups?eduinstitution_id=${edu_id}`)
			.then(response => {
				if (response?.status === 200) {
					seteduGroups(_.get(response, 'data.data.data', []));
				}
			})
			.catch(error => {});
	};
	const resetFilter = () => {
		setSearchLoading(true);
		setSearch({
			year: '',
			region: '',
			city: '',
			school: '',
			status: '',
			full_name: '',
			language: '',
			username: '',
			created_at: '',
			last_online_at_from: '',
			last_online_at_to: '',
			hours_count: '',
			position_id: '',
			position_type_id: '',
			certificate_date_to: '',
			certificate_date_from: '',
			certificate_id: '',
			is_specialized: '',
			school_type: '',
			number: '',
			edu_institution_id: '',
		});
		history.push(`/manager/offline-pupils/list/1`);
	};
	const language_l = window.localStorage.getItem('language') || 'uz';

	let findIsSchoolID = {};
	position_Type.forEach(item => {
		if (item.id === Number(position_type_id)) {
			findIsSchoolID = {
				...item,
				is_School: item.is_school,
			};
		}
	});

	return (
		<Layout>
			<CheckRole roles={[roles.manager]} />
			<main class='main'>
				<section class='section section-dashboard py-0'>
					<div class='container'>
						<div class='row'>
							<DashSideBar />
							<div class='col-md-12 col-lg-9 col-xl-10'>
								<div class='dashboard-right'>
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('m_pupils.offline')}
									/>
									<div class='dashboard-title dashboard-title--has-buttons'>
										<h5>{t('m_pupils.offline')}</h5>
										<div class='buttons butons--target'>
											<button
												data-toggle='modal'
												data-target='#modalExcelExportpupils'
												className='btn btn-white btn-sm font-weight-medium'>
												{t('m_pupils.excel_export')}
											</button>
											<button
												className='btn btn-white  btn-sm font-weight-medium'
												onClick={resetFilter}>
												{t('m_pupils.reset')}
											</button>
											<button
												data-toggle='modal'
												data-target='#modalFilter'
												className='btn btn-white btn-sm font-weight-medium'>
												{t('m_pupils.filter')}
												<span class='icon icon-filter fs-100 ml-1'></span>
											</button>
										</div>
									</div>
									{loading ? (
										<Loading size={7} />
									) : (
										<>
											<div className='card card--shadow'>
												<div className='card-body p-3'>
													<div className='scroll scroll--horizontall'>
														<table className='table table-bordered table--style-1 manager-teachers-table'>
															<thead>
																<tr>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.full_name',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t('m_pupils.phone')}
																		</div>
																	</th>
																	<th>
																		<div
																			className='cell-inner'
																			style={{ width: 500 }}>
																			{t(
																				'm_pupils.offline_edu',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.start_date',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.end_date',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t('m_pupils.visit')}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.last_visit',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t('m_pupils.status')}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.completed',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.language',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.position_type',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.position',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t('m_pupils.region')}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t('m_pupils.city')}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t('m_pupils.school')}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.certificate',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.certificate_id',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.certificate_date',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.reference',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.reference_link',
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner'>
																			{t(
																				'm_pupils.group_docs',
																			)}
																		</div>
																	</th>
																</tr>
															</thead>

															<thead>
																<tr>
																	<th>
																		<div
																			className='cell-inner cell-search'
																			style={{
																				minWidth: 200,
																			}}>
																			<div className='cell-search__form'>
																				<label for='search_1'>
																					<span className='icon icon-search'></span>
																				</label>
																				<input
																					type='text'
																					id='search_1'
																					name='full_name'
																					value={full_name}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false,
																						)
																					}
																					placeholder={t(
																						'm_pupils.search',
																					)}
																				/>
																			</div>
																		</div>
																	</th>

																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<label for='search_4'>
																					<span className='icon icon-search'></span>
																				</label>
																				<input
																					type='text'
																					id='search_4'
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false,
																						)
																					}
																					name='username'
																					value={username}
																					placeholder={t(
																						'm_pupils.search',
																					)}
																					style={{
																						width: 110,
																						paddingRight: 0,
																					}}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<select
																					style={{
																						width: '100%',
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name='edu_institution_id'
																					value={
																						edu_institution_id
																					}
																					onChange={e => {
																						changeParams(
																							e,
																						);
																						getEduGruops(
																							e.target
																								.value,
																						);
																					}}>
																					<option
																						value={''}
																						selected>
																						{t(
																							'm_pupils.all',
																						)}
																					</option>
																					{edus.map(
																						(
																							item,
																							index,
																						) => (
																							<option
																								key={
																									index
																								}
																								value={
																									item.id
																								}>
																								{
																									item.name
																								}
																							</option>
																						),
																					)}
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<input
																					type='date'
																					style={{
																						width: 120,
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false,
																						)
																					}
																					name='created_at'
																					value={
																						created_at
																					}
																					placeholder={t(
																						'm_pupils.search',
																					)}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name='status'
																					value={status}
																					onChange={e =>
																						changeParams(
																							e,
																						)
																					}>
																					<option
																						value={''}>
																						{t(
																							'm_pupils.all',
																						)}
																					</option>
																					<option
																						value={1}>
																						{t(
																							'm_pupils.active',
																						)}
																					</option>
																					<option
																						value={0}>
																						{t(
																							'm_pupils.ne_active',
																						)}
																					</option>
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<label for='search_1'>
																					<span className='icon icon-search'></span>
																				</label>
																				<input
																					type='number'
																					style={{
																						height: 30,
																						width: 100,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																						paddingLeft: 30,
																					}}
																					id='search_8'
																					value={
																						hours_count
																					}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false,
																						)
																					}
																					name='hours_count'
																					placeholder={t(
																						'm_pupils.search',
																					)}
																				/>
																			</div>
																		</div>
																	</th>

																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name='language'
																					value={language}
																					onChange={e =>
																						changeParams(
																							e,
																						)
																					}>
																					<option
																						value={''}>
																						{t(
																							'm_pupils.all',
																						)}
																					</option>
																					{teacher_lan.data
																						? teacher_lan.data.map(
																								(
																									lan,
																									index,
																								) => (
																									<option
																										value={
																											lan.id
																										}
																										key={
																											index
																										}>
																										{
																											lan.name
																										}
																									</option>
																								),
																						  )
																						: null}
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<select
																					style={{
																						height: 30,
																						width: 200,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e => {
																						changeParams(
																							e,
																							{
																								position_id:
																									'',
																							},
																						);
																					}}
																					value={
																						position_type_id
																					}
																					name='position_type_id'>
																					<option
																						selected
																						value=''>
																						{t(
																							'm_pupils.all',
																						)}
																					</option>
																					{position_Type.map(
																						(
																							type,
																							index,
																						) => (
																							<option
																								value={
																									type.id
																								}
																								key={
																									index
																								}>
																								{
																									type.name
																								}
																							</option>
																						),
																					)}
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				{position_type_id ? (
																					<select
																						style={{
																							height: 30,
																							border:
																								'none',
																							outline:
																								'none',
																							background:
																								'#fbfbfc',
																							maxWidth: 150,
																						}}
																						onChange={e => {
																							changeParams(
																								e,
																							);
																						}}
																						name='position_id'
																						value={
																							position_id
																						}>
																						<option
																							value={''}
																							selected>
																							{t(
																								'm_pupils.all',
																							)}
																						</option>
																						{_.filter(
																							userPosition,
																							i => {
																								return (
																									parseInt(
																										position_type_id,
																									) ===
																									i.type_id
																								);
																							},
																						).map(
																							(
																								item,
																								index,
																							) => (
																								<option
																									key={
																										index
																									}
																									value={
																										item.id
																									}>
																									{
																										item.name
																									}
																								</option>
																							),
																						)}
																					</select>
																				) : (
																					<button
																						data-toggle='modal'
																						data-target='#modalFilter'
																						style={{
																							border: 0,
																							borderRadius: 3,
																							marginLeft: 10,
																						}}>
																						{t(
																							'm_pupils.filter',
																						)}
																						<span class='icon icon-filter fs-100 ml-1'></span>
																					</button>
																				)}
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e => {
																						changeParams(
																							e,
																							{
																								city: '',
																								school:
																									'',
																							},
																						);
																						props.getCitiesList(
																							e.target
																								.value,
																						);
																					}}
																					name='region'
																					value={region}>
																					<option
																						value={''}
																						selected>
																						{t(
																							'm_pupils.region_all',
																						)}
																					</option>
																					{region_list.data
																						? region_list.data.map(
																								(
																									region,
																									index,
																								) => (
																									<option
																										key={
																											index
																										}
																										value={
																											region.id
																										}>
																										{
																											region.name
																										}
																									</option>
																								),
																						  )
																						: null}
																				</select>
																			</div>
																		</div>
																	</th>

																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				{region ? (
																					<select
																						style={{
																							height: 30,
																							border:
																								'none',
																							outline:
																								'none',
																							background:
																								'#fbfbfc',
																						}}
																						onChange={e => {
																							changeParams(
																								e,
																								{
																									school:
																										'',
																								},
																							);
																							getSchools(
																								region,
																								e.target
																									.value,
																							);
																						}}
																						name='city'
																						value={city}>
																						<option
																							value={''}
																							selected>
																							{t(
																								'm_pupils.city_check',
																							)}
																						</option>
																						{cities_list.data
																							? cities_list.data.map(
																									(
																										item,
																										index,
																									) => (
																										<option
																											key={
																												index
																											}
																											value={
																												item.id
																											}>
																											{
																												item.name
																											}
																										</option>
																									),
																							  )
																							: null}
																					</select>
																				) : (
																					<button
																						data-toggle='modal'
																						data-target='#modalFilter'
																						style={{
																							border: 0,
																							borderRadius: 3,
																							marginLeft: 10,
																						}}>
																						{t(
																							'm_pupils.filter',
																						)}
																						<span class='icon icon-filter fs-100 ml-1'></span>
																					</button>
																				)}
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			{city &&
																			findIsSchoolID.is_School ==
																				1 ? (
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					onChange={e => {
																						changeParams(
																							e,
																						);
																					}}
																					name='school'
																					value={school}>
																					<option
																						value={''}
																						selected>
																						{t(
																							'm_pupils.all',
																						)}
																					</option>
																					{schools
																						? schools.map(
																								(
																									item,
																									index,
																								) => (
																									<option
																										key={
																											index
																										}
																										value={
																											item.id
																										}>
																										{
																											item.name
																										}
																									</option>
																								),
																						  )
																						: null}
																				</select>
																			) : (
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			)}
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<select
																					style={{
																						height: 30,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																					}}
																					name='certificate_count'
																					value={
																						certificate_count
																					}
																					onChange={e =>
																						changeParams(
																							e,
																						)
																					}>
																					<option
																						value={''}>
																						{t(
																							'm_pupils.all',
																						)}
																					</option>
																					<option
																						value={1}>
																						{t(
																							'm_pupils.has',
																						)}
																					</option>
																					<option
																						value={0}>
																						{t(
																							'm_pupils.no_has',
																						)}
																					</option>
																				</select>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<label for='search_1'>
																					<span className='icon icon-search'></span>
																				</label>
																				<input
																					type='number'
																					id='search_15'
																					name='certificate_id'
																					style={{
																						height: 30,
																						width: 100,
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																						paddingLeft: 30,
																					}}
																					value={
																						certificate_id
																					}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false,
																						)
																					}
																					placeholder={t(
																						'm_pupils.search',
																					)}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<label for='search_1'>
																					<span className='icon icon-search'></span>
																				</label>
																				<input
																					type='number'
																					id='search_1'
																					name='number'
																					style={{
																						height: 30,
																						width: '100%',
																						border:
																							'none',
																						outline:
																							'none',
																						background:
																							'#fbfbfc',
																						paddingLeft: 30,
																					}}
																					value={number}
																					onChange={e =>
																						changeParams(
																							e,
																							{},
																							false,
																						)
																					}
																					placeholder={t(
																						'm_pupils.search',
																					)}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className='cell-inner cell-search'>
																			<div className='cell-search__form'>
																				<button
																					data-toggle='modal'
																					data-target='#modalFilter'
																					style={{
																						border: 0,
																						borderRadius: 3,
																						marginLeft: 10,
																					}}>
																					{t(
																						'm_pupils.filter',
																					)}
																					<span class='icon icon-filter fs-100 ml-1'></span>
																				</button>
																			</div>
																		</div>
																	</th>
																</tr>
															</thead>
															{search_loading ? null : (
																<>
																	{pupils.data &&
																	pupils.data.length > 0 ? (
																		<tbody>
																			{pupils.data.map(
																				(
																					pupil_offline,
																					index,
																				) => {
																					const pupil =
																						_.has(
																							pupil_offline,
																							'user_all.id',
																						)
																							? _.get(
																									pupil_offline,
																									'user_all',
																									{},
																							  )
																							: {};
																					return (
																						<tr
																							key={
																								index
																							}>
																							<td>
																								<Link
																									to={
																										'/manager/pupils/view/' +
																										pupil.id
																									}
																									className='cell-inner'
																									style={{
																										width: 225,
																										whiteSpace:
																											'normal',
																									}}>
																									{pupil.firstname ? (
																										<>
																											{
																												pupil.firstname
																											}{' '}
																											{_.get(
																												pupil,
																												'lastname',
																												'',
																											)}{' '}
																											{_.get(
																												pupil,
																												'middlename',
																												'',
																											)}
																										</>
																									) : (
																										'User Id ' +
																										pupil.id
																									)}
																								</Link>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{
																										pupil.name
																									}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{_.get(
																										pupil_offline,
																										'group_education.edu_institution.name',
																										'',
																									)}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									<Moment format='DD.MM.YYYY'>
																										{_.get(
																											pupil_offline,
																											'group_education.start_date',
																											'',
																										)}
																									</Moment>
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									<Moment format='DD.MM.YYYY'>
																										{_.get(
																											pupil_offline,
																											'group_education.end_date',
																											'',
																										)}
																									</Moment>
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									<Moment format='HH:mm DD.MM.YYYY'>
																										{
																											pupil.created_at
																										}
																									</Moment>
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									<Moment format='HH:mm DD.MM.YYYY'>
																										{
																											pupil.last_online_at
																										}
																									</Moment>
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{pupil.status ===
																									1
																										? t(
																												'm_pupils.active',
																										  )
																										: t(
																												'm_pupils.ne_active',
																										  )}
																								</div>
																							</td>
																							<td>
																								<div
																									className='cell-inner'
																									style={{
																										minWidth: 100,
																									}}>
																									{_.get(
																										pupil_offline,
																										'reference',
																										false,
																									)
																										? _.get(
																												pupil_offline,
																												'position.hours',
																												0,
																										  )
																										: 0}{' '}
																									{t(
																										'm_pupils.h',
																									)}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{pupil.language
																										? pupil
																												.language
																												.name
																										: ''}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{pupil_offline.position
																										? pupil_offline
																												.position
																												.type
																												.name
																										: ''}
																								</div>
																							</td>
																							<td>
																								<div
																									className='cell-inner'
																									style={{
																										minWidth: 150,
																									}}>
																									{pupil_offline.position
																										? pupil_offline
																												.position
																												.name
																										: ''}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{pupil.region
																										? pupil
																												.region
																												.name
																										: ''}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{pupil.city
																										? pupil
																												.city
																												.name
																										: ''}
																								</div>
																							</td>
																							<td>
																								<div
																									className='cell-inner'
																									style={{
																										minWidth: 110,
																									}}>
																									{pupil.school
																										? pupil
																												.school
																												.name
																										: ''}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{pupil_offline.reference &&
																									pupil_offline
																										.reference
																										.certificate &&
																									pupil_offline
																										.reference
																										.certificate
																										.id ? (
																										<button
																											type='button'
																											className='btn btn-primary btn-xs'
																											onClick={() =>
																												setCertificate(
																													pupil_offline
																														.reference
																														.certificate,
																												)
																											}
																											data-toggle='modal'
																											data-target='#modalCertificate_1'>
																											{t(
																												'm_pupils.certificate',
																											)}
																										</button>
																									) : (
																										<button
																											type='button'
																											className='btn btn-secondary btn-xs'>
																											{t(
																												'm_pupils.certificate',
																											)}
																										</button>
																									)}
																								</div>
																							</td>
																							<td>
																								<div
																									className='cell-inner'
																									style={{
																										whiteSpace:
																											'nowrap',
																									}}>
																									{_.get(
																										pupil_offline,
																										'reference.certificate.id',
																										false,
																									)
																										? 'UTK-' +
																										  _.get(
																												pupil,
																												'region.code',
																												'',
																										  ) +
																										  '-' +
																										  '0000000'.substr(
																												_.get(
																													pupil_offline,
																													'reference.certificate.id',
																													'',
																												).toString()
																													.length,
																										  ) +
																										  _.get(
																												pupil_offline,
																												'reference.certificate.id',
																												'',
																										  )
																										: ''}
																								</div>
																							</td>
																							<td>
																								{pupil_offline.reference &&
																								pupil_offline
																									.reference
																									.certificate &&
																								pupil_offline
																									.reference
																									.certificate
																									.id ? (
																									<div className='cell-inner'>
																										<Moment format='HH:mm DD.MM.YYYY'>
																											{
																												pupil_offline
																													.reference
																													.certificate
																													.created_at
																											}
																										</Moment>
																									</div>
																								) : (
																									''
																								)}
																							</td>
																							<td>
																								<div
																									className='cell-inner'
																									style={{
																										whiteSpace:
																											'nowrap',
																									}}>
																									{_.get(
																										pupil_offline,
																										'reference.number',
																										'*****',
																									)}
																								</div>
																							</td>
																							<td>
																								<div className='cell-inner'>
																									{_.get(
																										pupil_offline,
																										'reference.pdf',
																										'',
																									) ? (
																										<a
																											href={
																												FILE_URL +
																												_.get(
																													pupil_offline,
																													'reference.pdf',
																													'',
																												)
																											}
																											target='_blank'
																											className='btn btn-primary btn-xs'>
																											{t(
																												'm_pupils.reference_link',
																											)}
																										</a>
																									) : (
																										<button
																											type='button'
																											className='btn btn-secondary btn-xs'>
																											{t(
																												'm_pupils.reference_link',
																											)}
																										</button>
																									)}
																								</div>
																							</td>
																							<td>
																								<div
																									className='cell-inner'
																									style={{
																										flexDirection:
																											'column',
																										whiteSpace:
																											'nowrap',
																									}}>
																									{_.get(
																										pupil_offline,
																										'group_education.user_list',
																										'',
																									) ? (
																										<a
																											href={
																												FILE_URL +
																												_.get(
																													pupil_offline,
																													'group_education.user_list',
																													'',
																												)
																											}
																											target='_blank'
																											className='btn btn-primary btn-xs mb-1'>
																											{t(
																												'm_pupils.g_user_list',
																											)}
																										</a>
																									) : (
																										<button
																											type='button'
																											className='btn btn-secondary btn-xs mb-1'>
																											{t(
																												'm_pupils.g_user_list',
																											)}
																										</button>
																									)}
																									{_.get(
																										pupil_offline,
																										'group_education.successful_user_list',
																										'',
																									) ? (
																										<a
																											href={
																												FILE_URL +
																												_.get(
																													pupil_offline,
																													'group_education.successful_user_list',
																													'',
																												)
																											}
																											target='_blank'
																											className='btn btn-primary btn-xs mb-1'>
																											{t(
																												'm_pupils.g_user_list_s',
																											)}
																										</a>
																									) : (
																										<button
																											type='button'
																											className='btn btn-secondary btn-xs mb-1'>
																											{t(
																												'm_pupils.g_user_list_s',
																											)}
																										</button>
																									)}
																									{_.get(
																										pupil_offline,
																										'group_education.block_user_list',
																										'',
																									) ? (
																										<a
																											href={
																												FILE_URL +
																												_.get(
																													pupil_offline,
																													'group_education.block_user_list',
																													'',
																												)
																											}
																											target='_blank'
																											className='btn btn-primary btn-xs mb-1'>
																											{t(
																												'm_pupils.g_user_list_b',
																											)}
																										</a>
																									) : (
																										<button
																											type='button'
																											className='btn btn-secondary btn-xs mb-1'>
																											{t(
																												'm_pupils.g_user_list_b',
																											)}
																										</button>
																									)}
																								</div>
																							</td>
																						</tr>
																					);
																				},
																			)}
																		</tbody>
																	) : null}{' '}
																</>
															)}
														</table>
													</div>
													{search_loading ? (
														<div>
															<Loading size={7} />
														</div>
													) : pupils.data &&
													  pupils.data.length > 0 ? null : (
														<>
															<div
																style={{
																	width: '100%',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}>
																<NoData
																	text={t('m_pupils.no_data')}
																/>
															</div>
														</>
													)}
												</div>
											</div>
											<div className='row'>
												<div className='col-12 col-md-6 p-0'>
													<div
														className='mt-4'
														style={{ textAlign: 'center' }}>
														{(page - 1) * 20 + 1} -{' '}
														{page * 20 > pupils.total
															? pupils.total
															: page * 20}{' '}
														/ {t('m_pupils.all_p')}{' '}
														{pupils.total ? pupils.total : 0}{' '}
														{t('m_pupils.ta')}
													</div>
												</div>
												<div className='col-12 col-md-6 p-0'>
													<Pagination
														total={
															pupils.total ? pupils.total : 20
														}
														count={20}
														active={parseInt(page)}
														link='/manager/offline-pupils/list/'
														search_params={
															'?' + params.substring(1)
														}
													/>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div
				className='modal fade'
				id='modalFilter'
				tabindex='-1'
				role='dialog'
				aria-labelledby='modalFilterTitle'
				aria-hidden='true'>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>{t('m_pupils.filter')}</h5>
							<button
								type='button'
								className='close'
								data-dismiss='modal'
								aria-label='Close'>
								<span className='icon icon-close'></span>
							</button>
						</div>
						<div className='modal-body'>
							<div className='form-group mb-3'>
								<label for=''>{t('m_pupils.year')}</label>
								<select
									value={year}
									name='year'
									className='form-control custom-select'
									onChange={e => modalFilter(e)}
									value={year}>
									{/* <option value={''}>{t('m_pupils.all')}</option> */}
									{(function () {
										let l = [];
										for (
											let y = parseInt(new Date().getFullYear());
											y >= 2020;
											y--
										) {
											l.push(
												<option value={y} key={y}>
													{y} {t('m_teacher.year')}
												</option>,
											);
										}
										return l;
									})()}
								</select>
							</div>
							<div className='form-group mb-3'>
								<label for=''>{t('m_pupils.position_type')}</label>
								<select
									value={position_type_id}
									name='position_type_id'
									className='form-control custom-select'
									onChange={e =>
										setSearch({
											...search,
											position_type_id: e.target.value,
											position_id: '',
										})
									}>
									<option selected value=''>
										{t('m_pupils.all')}
									</option>
									{position_Type.map((type, index) => (
										<option value={type.id} key={index}>
											{type.name}
										</option>
									))}
								</select>
							</div>
							{position_type_id ? (
								<div className='form-group mb-3'>
									<label for=''>{t('m_pupils.position')}</label>
									<select
										value={position_id}
										name='position_id'
										className='form-control custom-select'
										onChange={e => modalFilter(e)}>
										<option selected value=''>
											{t('m_pupils.all')}
										</option>
										{_.filter(userPosition, i => {
											return (
												parseInt(position_type_id) === i.type_id
											);
										}).map((item, index) => (
											<option value={item.id} key={index}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							) : null}
							<div className='form-group mb-5'>
								<label for=''>{t('m_pupils.region')}</label>
								<select
									className='form-control custom-select'
									onChange={e => {
										setSearch({
											...search,
											region: e.target.value,
											city: '',
											school: '',
										});
										props.getCitiesList(e.target.value);
									}}
									name='region'
									value={region}>
									<option value={''} selected>
										{t('m_pupils.region_check')}
									</option>
									{region_list.data
										? region_list.data.map((region, index) => (
												<option key={index} value={region.id}>
													{region.name}
												</option>
										  ))
										: null}
								</select>
							</div>
							{region ? (
								<>
									<div className='form-group mb-5'>
										<label for=''>{t('m_pupils.city')}</label>
										<select
											className='form-control custom-select'
											onChange={e => {
												setSearch({
													...search,
													city: e.target.value,
													school: '',
												});
												getSchools(region, e.target.value);
											}}
											name='city'
											value={city}>
											<option value={''} selected>
												{t('m_pupils.city_check')}
											</option>
											{cities_list.data
												? cities_list.data.map((item, index) => (
														<option key={index} value={item.id}>
															{item.name}
														</option>
												  ))
												: null}
										</select>
									</div>
								</>
							) : null}

							{_.isEmpty(findIsSchoolID) ? null : (
								<>
									{findIsSchoolID.is_School == 1 ? (
										<div className='form-group mb-5'>
											<label for=''>
												{t('m_pupils.is_specialized')}
											</label>
											<select
												className='form-control custom-select'
												onChange={e => {
													setSearch({
														...search,
														is_specialized: e.target.value,
														school_type: '',
													});
												}}
												name='is_specialized'
												value={is_specialized}>
												<option value={''} selected>
													{t('m_pupils.all')}
												</option>
												<option value='1'>
													{t('m_pupils.specialized')}
												</option>
												<option value='0'>
													{t('m_pupils.no_specialized')}
												</option>
											</select>
										</div>
									) : null}
								</>
							)}

							{is_specialized === '1' ? (
								<div className='form-group mb-5'>
									<label for=''>{t('m_pupils.school_type')}</label>
									<select
										className='form-control custom-select'
										onChange={e => {
											setSearch({
												...search,
												school_type: e.target.value,
											});
										}}
										name='school_type'
										value={school_type}>
										<option value={''} selected>
											{t('m_pupils.all')}
										</option>
										{schoolTypes.map((item, index) => (
											<option key={index} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							) : null}

							{city && findIsSchoolID.is_School == 1 ? (
								<div className='form-group mb-5'>
									<label for=''>{t('m_pupils.school')}</label>
									<select
										className='form-control custom-select'
										onChange={e => modalFilter(e)}
										name='school'
										value={school}>
										<option value={''} selected>
											{t('m_pupils.all')}
										</option>
										{schools
											? schools.map((item, index) => (
													<option key={index} value={item.id}>
														{item.name}
													</option>
											  ))
											: null}
									</select>
								</div>
							) : null}

							<div className='form-group mb-5'>
								<label for=''>{t('m_pupils.offline_edu')}</label>
								<select
									className='form-control custom-select'
									onChange={e => {
										setSearch({
											...search,
											eduinstitution_id: e.target.value,
											group_id: '',
										});
										getEduGruops(e.target.value);
									}}
									name='eduinstitution_id'
									value={eduinstitution_id}>
									<option value={''} selected>
										{t('m_pupils.all')}
									</option>
									{edus.map((item, index) => (
										<option key={index} value={item.id}>
											{item.name}
										</option>
									))}
								</select>
							</div>
							{eduinstitution_id ? (
								<div className='form-group mb-5'>
									<label for=''>{t('m_pupils.edu_groups')}</label>
									<select
										className='form-control custom-select'
										onChange={e => modalFilter(e)}
										name='group_id'
										value={group_id}>
										<option value={''} selected>
											{t('m_pupils.all')}
										</option>
										{eduGroups
											? eduGroups.map((item, index) => (
													<option key={index} value={item.id}>
														{item.name}
													</option>
											  ))
											: null}
									</select>
								</div>
							) : null}
							<div className='form-group mb-3'>
								<label for=''>{t('m_pupils.last_visit')}</label>
								<input
									type='date'
									className='form-control w-50 d-inline mb-1'
									onChange={e => modalFilter(e)}
									name='last_online_at_from'
									value={last_online_at_from}
								/>{' '}
								<span>{t('m_pupils.to')}</span>
								<input
									type='date'
									className='form-control w-50 d-inline'
									onChange={e => modalFilter(e)}
									name='last_online_at_to'
									value={last_online_at_to}
								/>{' '}
								<span>{t('m_pupils.from')}</span>
							</div>
							<div className='form-group mb-3'>
								<label for=''>{t('m_pupils.certificate_date')}</label>
								<input
									type='date'
									className='form-control w-50 d-inline mb-1'
									onChange={e => modalFilter(e)}
									name='certificate_date_from'
									value={certificate_date_from}
								/>{' '}
								<span>{t('m_pupils.to')}</span>
								<input
									type='date'
									className='form-control w-50 d-inline'
									onChange={e => modalFilter(e)}
									name='certificate_date_to'
									value={certificate_date_to}
								/>{' '}
								<span>{t('m_pupils.from')}</span>
							</div>
							<div className='form-group mb-3'>
								<label for=''>{t('m_pupils.group_date')}</label>
								<input
									type='date'
									className='form-control w-50 d-inline mb-1'
									onChange={e => modalFilter(e)}
									name='start_date'
									value={start_date}
								/>{' '}
								<span>{t('m_pupils.to')}</span>
								<input
									type='date'
									className='form-control w-50 d-inline'
									onChange={e => modalFilter(e)}
									name='end_date'
									value={end_date}
								/>{' '}
								<span>{t('m_pupils.from')}</span>
							</div>
							{search_loading ? (
								<div>
									<Loading size={7} />
								</div>
							) : (
								<button
									type='button'
									className='btn btn-primary w-100'
									onClick={() => setIs_Search(!is_Search)}>
									{t('m_pupils.filter')}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<CertificateModal certificate={certificate} />
			<ExcelExportModal
				parent='pupils'
				link={
					API_URL +
					'/api/v1/edu-institutions/user-all/export/' +
					window.location.search +
					(window.location.search ? '&token=' : '?token=') +
					getToken() +
					'&lan=' +
					language_l
				}
				cols={[
					{
						name: 'user_all_full_name',
						title: t('m_pupils.full_name'),
					},
					{
						name: 'user_all_name',
						title: t('m_pupils.phone'),
					},
					{
						name: 'user_all_created_at',
						title: t('m_pupils.visit'),
					},
					{
						name: 'user_all_last_online_at',
						title: t('m_pupils.last_visit'),
					},
					{
						name: 'user_all_status',
						title: t('m_pupils.status'),
					},
					{
						name: 'hours_count',
						title: t('m_pupils.completed'),
					},
					{
						name: 'user_all_language',
						title: t('m_pupils.language'),
					},
					{
						name: 'user_all_position_type',
						title: t('m_pupils.position_type'),
					},
					{
						name: 'user_all_position',
						title: t('m_pupils.position'),
					},
					{
						name: 'user_all_region',
						title: t('m_pupils.region'),
					},
					{
						name: 'user_all_city',
						title: t('m_pupils.city'),
					},
					{
						name: 'user_all_school',
						title: t('m_pupils.school'),
					},
					{
						name: 'user_all_certificate_id',
						title: t('m_pupils.certificate_id'),
					},
					{
						name: 'user_all_certificate_date',
						title: t('m_pupils.certificate_date'),
					},
					{
						name: 'group_education_edu_institution_name',
						title: t('m_pupils.offline_edu'),
					},
					{
						name: 'group_education_start_date',
						title: t('m_pupils.start_date'),
					},
					{
						name: 'group_education_end_date',
						title: t('m_pupils.end_date'),
					},
					{
						name: 'reference_number',
						title: t('m_pupils.reference'),
					},
				]}
			/>
		</Layout>
	);
};
export default connect(
	state => ({
		pupils: state.pupils,
		pupils_status: state.pupils_status,
		region_list: state.region_list,
		cities_list: state.cities_list,
		teacher_lan: state.teacher_lan,
	}),
	dispatch => ({
		getTeacherLanguages: () => dispatch(getTeacherLanguages()),
		getPupilsOffline: (page, search_p) =>
			dispatch(getPupilsOffline(page, search_p)),
		getRegionList: () => dispatch(getRegionList()),
		getCitiesList: id => dispatch(getCitiesList(id)),
	}),
)(ManagerPupilsOffline);
