import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCourse, getCourseSections, getCourseLesson } from '../actions';
import Loading from './sections/Loading';
import _ from 'lodash';
import Layout from './Layout';
import Navigation from './sections/Navigation';
import LessonTest from './sections/LessonTest';
import TestResult from './sections/TestResult';
import NoData from './sections/NoData';
import CourseSideBarLeft from './sections/CourseSideBarLeft';
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import ScrollToTopOnMount from './sections/ScrollToTopOnMount';
import CourseBtns from './sections/CoutceBtns';
import LessonExchange from './sections/LessonExchange';
import WaitLesson from './sections/WaitLesson';

const LessonTestPage = props => {
	const { t } = useTranslation();
	const { course, course_lesson, course_lesson_status, test_checked } = props;
	const [loading, setLoading] = useState(true);
	let slug = props.match.params.slug ? props.match.params.slug : '';
	let section = props.match.params.section ? props.match.params.section : '';
	let lesson_id = props.match.params.lesson_id ? props.match.params.lesson_id : '';
	let [is_done, setIsDone] = useState(1);
	useEffect(() => {
		props.getCourseLesson(slug, section, lesson_id);
		props.getCourse(slug);
		props.getCourseSections(slug, section);
		slug = props.match.params.slug ? props.match.params.slug : '';
		document.title = _.get(course, 'data.name', t('courses.title'));

	}, [props.match.params.slug, props.match.params.section, props.match.params.lesson_id]);
	useEffect(() => {
		if (course_lesson_status.status === 'started') {
			setLoading(true);
		}
		if (course_lesson_status.status === 'success') {
			setLoading(false);
			props.getCourseSections(slug, section);

			if (course_lesson.data&&course_lesson.data.passings) {
				const d = new Date(course_lesson.data.passings.updated_at);
				const d1 = new Date();
				const r = d1 - d;
				// if(3600000 > r ){
				if (
					2400000 < r &&
					course_lesson.data.passings.is_completed === 0 &&
					course_lesson.data.id === parseInt(lesson_id)
				) {
					setIsDone(0);
				} else {
					setIsDone(1);
				}
			} else {
				setIsDone(0);
			}
		}
		if (course_lesson_status.status === 'error') {
			setLoading(false);
		}
	}, [course_lesson_status, course_lesson]);

	return (
		<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
			<main className="main">
				<section className="section section-breadcrumbs">
					<div className="container">
						<Navigation
							is_btn={true}
							active={_.get(course_lesson, 'data.name')}
							navs={[
								{
									title: _.get(course, 'data.name'),
									link: '/courses/view/' + _.get(course, 'data.slug'),
								},
							]}
						/>
					</div>
				</section>
				<section className="section section-course pt-3">
					<div className="container">
						<h3 class="mb-4">{course.data && course.data.name ? course.data.name : ''}</h3>
						<div className="row">
							<div className="col-lg-3">
								<CourseSideBarLeft slug={slug} section={section}/>
							</div>
							<div className="col-lg-9 course_btns_target">
								<div className="has-right-buttons__right">
								<CourseBtns course={course}  slug={slug} dash={false}/>

								</div>
								<WaitLesson section={section}>
								{loading ? (
									<Loading />
								) : course_lesson.data && course_lesson_status.status !== 'error' ? (
									<>
										<div class="course-single__content">
											<div class="test">
												{is_done === 0 ? (
													<LessonTest
														course_lesson={course_lesson}
														section_slug={section}
														course_slug={slug}
														in_dash={false}
													/>
												) : (
													<TestResult
														test_check_data={
															test_checked
																? { ...course_lesson.data }
																: { ...course_lesson.data, vquestions: [] }
														}
														setIsDone={() => setIsDone(0)}
													/>
												)}
											</div>
										</div>
									</>
								):course_lesson.msg === 'is_block' ?<>
									<ScrollToTopOnMount/>
									<div class="course-single__content">
										<div class="test">
											<div className="card">
												<div className="card-body pb-0">
													<div className="row justify-content-xl-between">
														<div className="col-12">
															<div className="d-flex mb-5">
																<span className="icon icon-paper mt-1 mr-2"></span>
																<div className="text-secondary ">
																	<strong className="font-weight-medium">{t('test_result.condition')}</strong>
																	<br />
																	{t('test_result.is_block')}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="test__buttons">
										<div className="dashboard-buttons">
											<button type="submit" className="btn btn-primary ml-2" onClick={()=>props.getCourseLesson(slug, section, lesson_id)} >
												{t('test_result.understandable')}
											</button>
										</div>
									</div>
								</>:course_lesson.msg === 'is_completed' ?<>
									<ScrollToTopOnMount/>
									<div class="course-single__content">
										<div class="test">
											<div className="card">
												<div className="card-body pb-0">
													<div className="row justify-content-xl-between">
														<div className="col-12">
															<div className="d-flex mb-5">
																<span className="icon icon-paper mt-1 mr-2"></span>
																<div className="text-secondary ">
																	<strong className="font-weight-medium">{t('test_result.condition')}</strong>
																	<br />
																	{t('test_result.is_completed')}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="test__buttons">
										<div className="dashboard-buttons">
											<button type="submit" className="btn btn-primary ml-2"  onClick={()=>props.getCourseLesson(slug, section, lesson_id)}  >
												{t('test_result.understandable')}
											</button>
										</div>
									</div>
								</>: (
									<NoData text={t('course_view.not-course')} />
								)}</WaitLesson>
								{loading?'':<LessonExchange params={_.get(props, 'match.params', {})}/>}

							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getCourse: slug => dispatch(getCourse(slug)),
		getCourseSections: (slug, section) => dispatch(getCourseSections(slug, section)),
		getCourseLesson: (course_slug, section_slug, lesson_id) =>
			dispatch(getCourseLesson(course_slug, section_slug, lesson_id)),
	}),
)(LessonTestPage);
