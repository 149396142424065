import React, { useState, useEffect } from 'react';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import Loading from './sections/Loading';
import NoData from './sections/NoData';
import Layout from './Layout';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../helpers/httpClient';
import _, { map } from 'lodash';
import { FILE_URL } from '../helpers/api';
import CheckRole from './sections/CheckRole';
import { roles } from '../helpers/roles';
import Moment from 'react-moment';
import Pagination from './sections/Pagination';
import EduInstitutionStatusModal from './sections/EduInstitutionStatusModal';
import EduInstitutionStatusLogModal from './sections/EduInstitutionStatusLogModal';

const DashEduInstitutionListPage = props => {
	const { page } = props.match.params;
	const { t } = useTranslation();
	const [edus, setEdus] = useState({});
	const [isOpen, setIsOpen] = useState(0);
	const [eduLoading, setEduLoading] = useState(true);
	const [edit_edu, setEdit_edu] = useState({ id: 0, status: 0 });
	useEffect(() => {
		getEduInstitution();
	}, [page]);

	const getEduInstitution = () => {
		setEduLoading(true);
		getInstance()
			.get('/api/v1/edu-institution?per_page=20&step=7&page=' + page)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response;
						setEdus(data.data);
						setEduLoading(false);
					} catch {
						setEduLoading(false);
					}
				}
			})
			.catch(error => {
				setEduLoading(false);
			});
	};
	const getPositionTypesList = p => {
		let pt_list = [],
			p_types = [];
		p.forEach(item => {
			pt_list.push(item.type);
		});
		_.unionBy(pt_list, 'id').forEach(item => {
			p_types.push({ id: item.id, name: item.name });
		});
		const pt = _.sortBy(p_types, [
			function (o) {
				return o.id;
			},
		]);
		return pt;
	};
	const eduSetStatus = (id, status) => {
		let l = [];
		_.get(edus, 'data', []).forEach(item => {
			if (id === item.id) l.push({ ...item, status: status });
			else l.push(item);
		});
		setEdus({ ..._.get(edus, 'data', []), data: l });
	};
	return (
		<Layout>
			<CheckRole roles={[roles.minister]} />
			<main id="edu-institution" className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('edu_list.title')}
									/>
									<div className="dashboard-title">
										<h5>{t('edu_list.title')}</h5>
									</div>
									<div className="row">
										<div className="col-12">
											{eduLoading ? (
												<Loading />
											) : (
												<>
													{_.get(edus, 'data', []).length ? (
														<>
															{_.get(edus, 'data', []).map(
																(item, index) => (
																	<div
																		className="card card--shadow"
																		key={index}
																	>
																		<div className="card-body">
																			<div
																				className="edu_label"
																				style={{
																					fontSize: 16,
																				}}
																			>
																				<b>
																					{t(
																						'edu_institution.reference'
																					)}
																				</b>
																			</div>
																			<div className="edu_label p-0">
																				{t(
																					'edu_institution.name'
																				)}
																				<div
																					onClick={() =>
																						setIsOpen(
																							isOpen ===
																								item.id
																								? 0
																								: item.id
																						)
																					}
																					className="edu_edit_btn"
																					style={{
																						float: 'right',
																					}}
																				>
																					{isOpen ===
																					item.id ? (
																						<span class="icon icon-arrow-up"></span>
																					) : (
																						<span class="icon icon-arrow-down-1"></span>
																					)}
																				</div>
																			</div>
																			<h5>{item.name}</h5>
																			<div className="row">
																				<div className="col-md-3">
																					<div className="edu_label">
																						{t(
																							'edu_institution.type'
																						)}
																					</div>
																					{item.is_online ? (
																						<div className="edu_online">
																							{t(
																								'edu_institution.online'
																							)}
																						</div>
																					) : (
																						// <div className='edu_offline'>
																						//   {t('edu_institution.offline')}
																						// </div>
																						<div className="edu_offline">
																							{t(
																								'edu_institution.offline'
																							)}
																						</div>
																					)}
																				</div>
																				<div className="col-md-5">
																					<div className="edu_label">
																						{t(
																							'edu_list.status'
																						)}
																					</div>
																					{item.status ===
																					0 ? (
																						<div className="edu_status edu_status_new">
																							{t(
																								'edu_list.no_check'
																							)}
																						</div>
																					) : item.status ===
																					  1 ? (
																						<div className="edu_status">
																							{t(
																								'edu_list.succes'
																							)}
																						</div>
																					) : item.status ===
																					  2 ? (
																						<div className="edu_status edu_status_check">
																							{t(
																								'edu_list.check'
																							)}
																						</div>
																					) : item.status ===
																					  3 ? (
																						<div className="edu_status edu_status_faild">
																							{t(
																								'edu_list.failed'
																							)}
																						</div>
																					) : null}
																					<div
																						className="edu_edit_btn"
																						data-toggle="modal"
																						data-target="#modalEduInstitutionStatus"
																						onClick={() =>
																							setEdit_edu(
																								{
																									id: item.id,
																									status:
																										item.status,
																								}
																							)
																						}
																					>
																						<span class="icon icon-edit"></span>
																					</div>
																					<div
																						className="edu_edit_btn"
																						data-toggle="modal"
																						data-target="#modalEduInstitutionStatusLog"
																						onClick={() =>
																							setEdit_edu(
																								{
																									id: item.id,
																									status:
																										item.status,
																								}
																							)
																						}
																					>
																						<span class="icon icon-info"></span>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="edu_label">
																						{t(
																							'edu_list.create_at'
																						)}
																					</div>
																					<div className="edu_date">
																						<Moment format="HH:mm DD-MM-YYYY">
																							{
																								item.edit_date
																							}
																						</Moment>
																					</div>
																				</div>
																				{isOpen ===
																				item.id ? (
																					<>
																						<div className="col-12">
																							<h6 className="my-4">
																								{t(
																									'edu_institution.reference'
																								)}
																							</h6>
																						</div>
																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.name'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.name
																									? item.name
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.edu_institution_type'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.edu_institution_type
																									? item.edu_institution_type
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<label>
																								Ta’lim
																								tashkilotining
																								tasdiqlangan
																								guvohnomasi
																								(.pdf
																								.png
																								.jpg
																								.jpeg
																								yuklash)
																							</label>

																							{item.certificate ? (
																								<a
																									href={
																										FILE_URL +
																										item.certificate
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div className="col-12">
																							<label>
																								Ta’lim
																								tashkilotining
																								Ustavi
																								(.pdf
																								yuklash)
																							</label>
																							{item.statutes ? (
																								<a
																									href={
																										FILE_URL +
																										item.statutes
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div className="col-12">
																							<label>
																								Ilmiy-metodik
																								kengash
																								(.pdf
																								yuklash)
																							</label>

																							{item.council ? (
																								<a
																									href={
																										FILE_URL +
																										item.council
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div
																							className="col-12"
																							style={{
																								color: '#e60000',
																								fontSize: 14,
																								marginBottom:
																									'20px',
																							}}
																						>
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								Izoh:
																							</span>{' '}
																							Ilmiy-metodik
																							kengash
																							faoliyati
																							yo‘lga
																							qo‘yilganligi
																							va uning
																							tarkibi
																							bo’yicha
																							ta'lim
																							ta'lim
																							tashkiloti
																							rahbarining{' '}
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								imzosi
																							</span>{' '}
																							va{' '}
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								muhri
																							</span>{' '}
																							bilan
																							tasdiqlangan
																							hujjat
																							yuklanadi.
																						</div>
																						<div className="col-12">
																							<h6 className="mb-4">
																								{t(
																									'edu_institution.mail_address'
																								)}
																							</h6>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								{t(
																									'edu_institution.region'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item
																									.region
																									?.name
																									? item
																											.region
																											?.name
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								{t(
																									'edu_institution.city'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item
																									.city
																									?.name
																									? item
																											.city
																											?.name
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.address'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.address
																									? item.address
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.mail'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.mail_address
																									? item.mail_address
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.phone'
																								)}
																							</label>
																							{_.get(
																								item,
																								'contact',
																								'[]'
																							).length >
																							0 ? (
																								JSON.parse(
																									_.get(
																										item,
																										'contact',
																										'[]'
																									)
																								).map(
																									(
																										item,
																										index
																									) => {
																										return (
																											<p
																												key={
																													index
																												}
																												style={{
																													color: '#0D2E69',
																												}}
																											>
																												{
																													item
																												}
																											</p>
																										);
																									}
																								)
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div className="col-12">
																							<h6 className="mb-4">
																								{t(
																									'edu_institution.edu'
																								)}
																							</h6>
																						</div>
																						<div className="col-12">
																							<label>
																								O‘quv-tajriba
																								maydonchalari
																								(tayanch
																								o‘quv
																								muassasalari)ning
																								mavjudligi{' '}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.experimental_playground ===
																								1
																									? 'Mavjud'
																									: 'Mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.type'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.is_online ===
																								0
																									? 'Offline'
																									: 'Offline'}
																							</p>
																						</div>
																						{item.is_online ===
																						1 ? (
																							<div className="col-12">
																								<label>
																									{t(
																										'edu_institution.type'
																									)}
																								</label>
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									{item.url
																										? item.url
																										: 'Ma`lumot mavjud emas'}
																								</p>
																							</div>
																						) : null}
																						<div className="col-12">
																							<h6 className="mb-4">
																								{t(
																									'edu_institution.address_title'
																								)}
																							</h6>
																						</div>
																						{item
																							?.address_all
																							?.length !==
																						0 ? (
																							map(
																								item.address_all,
																								(
																									item,
																									index
																								) => {
																									let {
																										address,
																										city,
																										region,
																									} = item;
																									return (
																										<React.Fragment
																											key={
																												index
																											}
																										>
																											<div className="col-12 mb-4">
																												<div
																													style={{
																														fontWeight: 500,
																														paddingRight: 50,
																													}}
																												>
																													{index +
																														1}

																													-
																													{t(
																														'edu_institution.address_all'
																													)}
																												</div>
																											</div>
																											<div className="col-md-6 col-xl-4">
																												<label>
																													{t(
																														'edu_institution.region'
																													)}
																												</label>
																												<p
																													style={{
																														color: '#0D2E69',
																													}}
																												>
																													{region.name
																														? region.name
																														: 'Ma`lumot mavjud emas'}
																												</p>
																											</div>
																											<div className="col-md-6 col-xl-4">
																												<label>
																													{t(
																														'edu_institution.city'
																													)}
																												</label>
																												<p
																													style={{
																														color: '#0D2E69',
																													}}
																												>
																													{city.name
																														? city.name
																														: 'Ma`lumot mavjud emas'}
																												</p>
																											</div>
																											<div className="col-12">
																												<label>
																													{t(
																														'edu_institution.address'
																													)}
																												</label>
																												<p
																													style={{
																														color: '#0D2E69',
																													}}
																												>
																													{address
																														? address
																														: 'Ma`lumot mavjud emas'}
																												</p>
																											</div>
																										</React.Fragment>
																									);
																								}
																							)
																						) : (
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								Ma`lumot
																								mavjud
																								emas
																							</p>
																						)}

																						<div className="col-12">
																							<label>
																								{t(
																									'edu_institution.position_type'
																								)}
																							</label>
																							{getPositionTypesList(
																								_.get(
																									item,
																									'positions',
																									[]
																								)
																							).length >
																							0 ? (
																								getPositionTypesList(
																									_.get(
																										item,
																										'positions',
																										[]
																									)
																								).map(
																									(
																										t_item,
																										t_index
																									) => (
																										<div
																											key={
																												t_index
																											}
																										>
																											<div
																												className="edu_date"
																												style={{
																													display:
																														'inline-block',
																												}}
																											>
																												{
																													t_item.name
																												}
																											</div>
																											<div>
																												{_.filter(
																													_.get(
																														item,
																														'positions',
																														[]
																													),
																													o => {
																														return (
																															o.type_id ===
																															t_item.id
																														);
																													}
																												).map(
																													(
																														p_item,
																														p_index
																													) => (
																														<div
																															className="edu_contact"
																															key={
																																p_index
																															}
																														>
																															{
																																p_item.name
																															}
																														</div>
																													)
																												)}
																											</div>
																										</div>
																									)
																								)
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>

																						<div className="col-12">
																							<h6 className="mb-4 mt-3">
																								O’quv-metodik
																								ta’minoti
																							</h6>
																						</div>

																						<div className="col-12">
																							<label>
																								Kurslarning
																								ishchi
																								o‘quv
																								rejalari,
																								ishchi
																								o‘quv
																								dasturlarining
																								tayanch
																								o‘quv
																								reja va
																								namunaviy
																								o‘quv
																								dasturlarga
																								mosligi
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.plan_compatibility ===
																								0
																									? 'Mos'
																									: 'Mos emas'}
																							</p>
																						</div>

																						<div className="col-12">
																							<label>
																								Ishchi
																								o‘quv
																								rejalar
																								(Arxivlangan
																								holatda
																								- .zip,
																								.rar,
																								.7zip)
																							</label>

																							{item.plan ? (
																								<a
																									href={
																										FILE_URL +
																										item.plan
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>

																						<div className="col-12">
																							<label>
																								Ishchi
																								o‘quv
																								dasturlar
																								(Arxivlangan
																								holatda
																								- .zip,
																								.rar,
																								.7zip)
																							</label>

																							{item.program ? (
																								<a
																									href={
																										FILE_URL +
																										item.program
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>

																						<div
																							className="col-12"
																							style={{
																								color: '#e60000',
																								fontSize: 14,
																								marginBottom:
																									'20px',
																							}}
																						>
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								Izoh:
																							</span>{' '}
																							Malaka
																							oshirish
																							kurslarining
																							Ishchi
																							o'quv reja
																							va
																							dasturlari
																							ta'lim
																							tashkiloti
																							rahbarining{' '}
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								imzosi
																							</span>{' '}
																							va
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								{' '}
																								muhri{' '}
																							</span>
																							bilan
																							tasdiqlangan
																							bo'lishi
																							kerak.
																						</div>

																						<div className="col-12">
																							<label>
																								O‘quv-uslubiy
																								ta’minot
																								(ma’ruza
																								matnlari,
																								amaliy
																								mashg‘ulot
																								ishlanmalari,
																								taqdimotlari,
																								nazorat
																								topshiriqlari)
																								(Arxivlangan
																								holatda
																								- .zip,
																								.rar,
																								.7zip)
																							</label>

																							{item.provision ? (
																								<a
																									href={
																										FILE_URL +
																										item.provision
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>

																						<div className="col-12">
																							<label>
																								Axborot
																								resurs
																								markazining
																								mavjudligi
																								(Mavjud/Mavjud
																								emas
																								“.pdf
																								yuklash”)
																							</label>
																							<>
																								{item.information_resource ? (
																									<a
																										href={
																											FILE_URL +
																											item.information_resource
																										}
																										className="btn btn-primary btn-sm mb-3"
																										target="_blank"
																									>
																										<span class="icon icon-eye"></span>{' '}
																										{t(
																											'edu_institution.eye'
																										)}
																									</a>
																								) : (
																									<p
																										style={{
																											color: '#0D2E69',
																										}}
																									>
																										Ma`lumot
																										mavjud
																										emas
																									</p>
																								)}
																							</>
																						</div>
																						<div className="col-12">
																							<h6 className="mb-4">
																								Moddiy-texnik
																								ta’minoti
																							</h6>
																							<h6 className="mb-4">
																								O‘quv
																								auditoriyalar
																							</h6>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								Soni
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.auditoriums
																									? item.auditoriums
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								Sig’imi
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.auditoriums_size
																									? item.auditoriums_size
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-12">
																							<h6 className="mb-4">
																								Amaliy
																								(laboratoriya)
																								mashg‘ulotlarni
																								o‘tkazish
																								uchun
																								maxsus
																								xonalar
																							</h6>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								Soni
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.practical
																									? item.practical
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								Sig’imi
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.practical_size
																									? item.practical_size
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>

																						<div className="col-12">
																							<label>
																								Laboratoriya
																								ishlarining
																								bajarilishi
																								uchun
																								metodik
																								ko‘rsatmalarning
																								mavjudligi
																								(Mavjud/Mavjud
																								emas ,
																								.pdf
																								yuklash)
																							</label>
																							{item.laboratory ===
																							1 ? (
																								<>
																									{item.laboratory_image ? (
																										<a
																											href={
																												FILE_URL +
																												item.laboratory_image
																											}
																											className="btn btn-primary btn-sm mb-3"
																											target="_blank"
																										>
																											<span class="icon icon-eye"></span>{' '}
																											{t(
																												'edu_institution.eye'
																											)}
																										</a>
																									) : (
																										<p
																											style={{
																												color: '#0D2E69',
																											}}
																										>
																											Ma`lumot
																											mavjud
																											emas
																										</p>
																									)}
																								</>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Mavjud
																									emas
																								</p>
																							)}
																						</div>

																						<div className="col-md-12">
																							<label>
																								Zamonaviy
																								kompyuter
																								texnikasi
																								bilan
																								ta’minlangan
																								xonalar
																								soni
																								(son
																								“ta”)
																								(.jpeg
																								.jpg
																								yuklash)
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.computer_room
																									? item.computer_room
																									: 'Ma`lumot mavjud emas'}
																							</p>

																							{item.computer_room_image ? (
																								<a
																									href={
																										FILE_URL +
																										item.computer_room_image
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div
																							className="col-12"
																							style={{
																								color: '#e60000',
																								fontSize: 14,
																								marginBottom:
																									'20px',
																							}}
																						>
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								Izoh:
																							</span>{' '}
																							Har birida
																							15 ta
																							zamonaviy
																							kompyuter
																							texnikasi
																							bilan
																							ta’minlangan
																							kamida 2 ta
																							xona
																							bo’lishi
																							kerak.
																							Xonalar
																							rasmini
																							yuklang.
																						</div>

																						<div className="col-md-12">
																							<label>
																								Zamonaviy
																								kompyuterlar
																								soni
																								(son
																								“ta”)
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.computer_size
																									? item.computer_size
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>

																						<div className="col-md-12">
																							<label>
																								Xorijiy
																								tillar
																								bo‘yicha
																								ixtisoslashtirilgan
																								o‘quv
																								xonalar
																								soni
																								(son
																								“ta”)
																								(.jpeg
																								.jpg
																								yuklash)
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.foreign_languages
																									? item.foreign_languages
																									: 'Ma`lumot mavjud emas'}
																							</p>

																							{item.foreign_languages_image ? (
																								<a
																									href={
																										FILE_URL +
																										item.foreign_languages_image
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div
																							className="col-12"
																							style={{
																								color: '#e60000',
																								fontSize: 14,
																								marginBottom:
																									'20px',
																							}}
																						>
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								Izoh:
																							</span>{' '}
																							Xorijiy
																							tillar
																							bo‘yicha
																							kurslarni
																							tashkil
																							etish uchun
																							nutq
																							(tinglab
																							tushunish,
																							o‘qib
																							tushunish,
																							yozish va
																							gapirish)
																							va til
																							ko‘nikmalarini
																							(grammatika,
																							leksika va
																							talaffuz)
																							integrallashgan
																							holda
																							egallash
																							imkoniyati
																							bo‘lgan
																							o‘quv
																							xonasi.
																							O’quv
																							xonasining
																							rasmini
																							yuklang.
																						</div>
																						<div className="col-12">
																							<h6 className="mb-4">
																								Pedagog
																								kadrlarning
																								ilmiy-pedagogik
																								salohiyati
																							</h6>
																						</div>

																						<div className="col-12">
																							<label>
																								O’qituvchilar
																								tarkibi
																								bo’yicha
																								ma’lumot
																								(.pdf
																								yuklash)
																							</label>

																							{item.teacher_data ? (
																								<a
																									href={
																										FILE_URL +
																										item.teacher_data
																									}
																									className="btn btn-primary btn-sm mb-3"
																									target="_blank"
																								>
																									<span class="icon icon-eye"></span>{' '}
																									{t(
																										'edu_institution.eye'
																									)}
																								</a>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Ma`lumot
																									mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div
																							className="col-12"
																							style={{
																								color: '#000',
																								fontSize: 14,
																								marginBottom:
																									'20px',
																							}}
																						>
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								Izoh:
																							</span>{' '}
																							Kurslarda
																							dars
																							beradigan
																							o‘qituvchilar
																							kamida
																							magistr
																							darajasiga
																							ega
																							bo‘lishi
																							kerak.
																						</div>

																						<div className="col-12">
																							<label>
																								Qayd
																								etish
																								kitobi
																								(Mavjud/Mavjud
																								emas)
																								(.pdf
																								yuklash)
																							</label>
																							{item.note ===
																							1 ? (
																								<>
																									{item.note_book ? (
																										<a
																											href={
																												FILE_URL +
																												item.note_book
																											}
																											className="btn btn-primary btn-sm mb-3"
																											target="_blank"
																										>
																											<span class="icon icon-eye"></span>{' '}
																											{t(
																												'edu_institution.eye'
																											)}
																										</a>
																									) : (
																										<p
																											style={{
																												color: '#0D2E69',
																											}}
																										>
																											Ma`lumot
																											mavjud
																											emas
																										</p>
																									)}
																								</>
																							) : (
																								<p
																									style={{
																										color: '#0D2E69',
																									}}
																								>
																									Mavjud
																									emas
																								</p>
																							)}
																						</div>
																						<div
																							className="col-12"
																							style={{
																								color: '#000',
																								fontSize: 14,
																								marginBottom:
																									'20px',
																							}}
																						>
																							<span
																								style={{
																									fontWeight: 800,
																								}}
																							>
																								Izoh:
																							</span>{' '}
																							Ma’lumotnomalar
																							va
																							sertifikatlarning
																							berilishini
																							qayd etish
																							kitobining
																							elektron
																							varianti
																							.pdf
																							shaklda
																							yuklanadi.
																						</div>
																						<div className="col-12">
																							<h6 className="mb-4">
																								{t(
																									'edu_institution.bank_info'
																								)}
																							</h6>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								{t(
																									'edu_institution.account_number'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.account_number
																									? item.account_number
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								{t(
																									'edu_institution.bank_name'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.bank_name
																									? item.bank_name
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								{t(
																									'edu_institution.mfo'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.mfo
																									? item.mfo
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-6 col-xl-4">
																							<label>
																								{t(
																									'edu_institution.bank_inn'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.bank_inn
																									? item.bank_inn
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>

																						<div className="col-12">
																							<h6 className="mb-4">
																								{t(
																									'edu_institution.owner'
																								)}
																							</h6>
																						</div>
																						<div className="col-md-12">
																							<label>
																								{t(
																									'edu_institution.owner_full_name'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.owner_full_name
																									? item.owner_full_name
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																						<div className="col-md-12">
																							<label>
																								{t(
																									'edu_institution.owner_positions'
																								)}
																							</label>
																							<p
																								style={{
																									color: '#0D2E69',
																								}}
																							>
																								{item.owner_positions
																									? item.owner_positions
																									: 'Ma`lumot mavjud emas'}
																							</p>
																						</div>
																					</>
																				) : null}
																			</div>
																		</div>
																	</div>
																)
															)}
															<div>
																<Pagination
																	total={_.get(edus, 'total')}
																	count={20}
																	active={parseInt(page)}
																	link="/dashboard/edu-institutions/"
																/>
															</div>
														</>
													) : (
														<div className="card card--shadow">
															<div className="card-body">
																<NoData
																	text={t('edu_list.no_data')}
																/>
															</div>
														</div>
													)}
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<EduInstitutionStatusModal
				edu={edit_edu}
				eduSetStatus={(id, status) => eduSetStatus(id, status)}
			/>
			<EduInstitutionStatusLogModal edu={edit_edu} />
		</Layout>
	);
};
export default DashEduInstitutionListPage;
