import _, { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import { getInstance } from '../helpers/httpClient';
import history from '../history';
import { roles } from '../helpers/roles';
import CheckRole from './sections/CheckRole';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getCitiesList, getRegionList } from '../actions';

const ManagerSchools = (props) => {
  const { t } = useTranslation();
  const { getCitiesList, getRegionList, region_list, cities_list } = props;
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(0);
  const [city, setCity] = useState(0);
  const [schools, setSchools] = useState([]);
  const [schoolsErp, setSchoolsErp] = useState([]);
  const [set_loading, setSet_loading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    getRegionList();
  }, []);
  const getSchools = (city_id) => {
    setLoading(true);
    getInstance()
      .get(`/api/v1/school?region_id=${region}&city_id=${city_id}`)
      .then((response) => {
        const { data } = response.data;
        setSchools(data ? data : []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });

    getInstance()
      .get(`/api/v1/erp/schools?region_id=${region}&city_id=${city_id}`)
      .then((res) => {
        const { data } = res;
        let l = [{ value: 0, label: t('erp_schools.erp_school') }];
        _.get(data, 'data', []).forEach((item) =>
          l.push({
            value: _.get(item, 'erp_id', 0),
            label: _.get(item, 'name', _.get(item, 'erp_id', 0)),
          })
        );
        setSchoolsErp(l);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const setErpSchool = (school_id, erp_select) => {
    let l = [];
    schools.forEach((item) => {
      if (item.id === school_id) {
        l.push({
          ...item,
          erp_id: parseInt(erp_select.value),
          erp_school: { name: erp_select.label },
        });
      } else {
        l.push(item);
      }
    });
    setSchools(l);
    setError('');
  };
  const postSchools = () => {
    setSet_loading(true);
    setError('');
    let l = [];
    schools.forEach((item) => {
      if (item.erp_id)
        l.push({
          school_id: item.id,
          erp_id: item.erp_id,
        });
    });
    getInstance()
      .post(`/api/v1/erp/school-set-erp`, {
        schools: l,
      })
      .then((response) => {
        const { data } = response.data;
        // setSchools(data ? data : []);
        setSet_loading(false);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      })
      .catch((e) => {
        setError('Error !');
        setSet_loading(false);
      });
  };

  return (
    <>
      <Layout>
        <CheckRole roles={[roles.manager]} />
        <main className='main'>
          <section className='section section-dashboard py-0'>
            <div className='container'>
              <div className='row'>
                <DashSideBar />
                <div className='col-md-12 col-lg-9 col-xl-10'>
                  <div
                    className='dashboard-right'
                    style={{ paddingBottom: 200 }}
                  >
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      navs={[
                        {
                          link: '/dashboard/profile',
                          title: t('profile.title'),
                        },
                      ]}
                      active={t('erp_schools.title_nav')}
                    />
                    <div className='dashboard-title'>
                      <h5>{t('erp_schools.title')}</h5>
                    </div>
                    <div className='card'>
                      <div className='card-body pb-0'>
                        <div className='row'>
                          <div className='col-md-6  col-xl-4'>
                            <div className='form-group'>
                              <label for=''>{t('profile.region')}</label>
                              <select
                                value={region}
                                className='form-control custom-select'
                                onChange={(e) => {
                                  setRegion(parseInt(e.target.value));
                                  getCitiesList(e.target.value);
                                }}
                              >
                                <option value={0} selected>
                                  {t('inquiry.region')}
                                </option>
                                {_.get(region_list, 'data', []).map(
                                  (item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          {region ? (
                            <div className='col-md-6  col-xl-4'>
                              <div className='form-group'>
                                <label for=''>{t('profile.city')}</label>
                                <select
                                  value={city}
                                  onChange={(e) => {
                                    setCity(e.target.value);
                                    getSchools(e.target.value);
                                  }}
                                  className='form-control custom-select'
                                >
                                  <option value={0} selected>
                                    {t('inquiry.city')}
                                  </option>
                                  {_.get(cities_list, 'data', []).map(
                                    (item, index) => (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <div className='card'>
                        <div className='card-body pb-0'>
                          <div className='row'>
                            <div className='col-12'>
                              <Loading />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : schools.length ? (
                      <div className='card'>
                        <div className='card-body'>
                          {schools.map((item, index) => (
                            <div
                              className={
                                index % 2 === 0
                                  ? 'row erp_schools1'
                                  : 'row erp_schools2'
                              }
                              key={index}
                            >
                              <div className='col-md-6 '>
                                <b style={{ color: '#0D2E69' }}>{item.name}</b>
                              </div>
                              <div className='col-md-6 '>
                                <Select
                                  value={{
                                    value: _.get(item, 'erp_id', 0),
                                    label: _.get(
                                      item,
                                      'erp_school.name',
                                      t('erp_schools.erp_school')
                                    ),
                                  }}
                                  onChange={(select) => {
                                    setErpSchool(item.id, select);
                                  }}
                                  options={schoolsErp}
                                  placeholder={t('diagnostics.type')}
                                />
                              </div>
                            </div>
                          ))}
                          <div className='row'>
                            <div className='col-12'>
                              {set_loading ? (
                                <Loading />
                              ) : (
                                <button
                                  className='btn btn-primary btn-sm mt-2'
                                  onClick={postSchools}
                                >
                                  {t('profile.save')}
                                </button>
                              )}
                              <div style={{ color: 'red' }}>{error}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};
export default connect(
  (state) => ({
    region_list: state.region_list,
    cities_list: state.cities_list,
  }),
  (dispatch) => ({
    getRegionList: () => dispatch(getRegionList()),
    getCitiesList: (id) => dispatch(getCitiesList(id)),
  })
)(ManagerSchools);
