import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	deleteFile,
	getCitiesList,
	getProfile,
	getRegionList,
	updateProfile,
} from '../actions';
import { IMG_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import history from '../history';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
// import MultiplePositions from './sections/MultiplePositions';
// import PhoneInput from './sections/PhoneInput';
const DashProfilePage = props => {
	const { t } = useTranslation();
	const {
		profile,
		region_list,
		cities_list,
		profile_status,
		profile_update_status,
		getCitiesList,
		getProfile,
		updateProfile,
		getRegionList,
		deleteFile,
	} = props;
	// {
	//   /* ##### new design for selects necessary currently in comment ##### */
	// }
	// const [addressProfile, setAddressProfile] = useState([]);
	// {
	//   /* ##### new design for selects necessary currently in comment ##### */
	// }
	const [userImage, setUserImage] = useState('');
	const [multiplePositionTypes, setMultiplePositionTypes] = useState([]);
	const [multiplePositions, setMultiplePositions] = useState({});
	const [loading, setLoading] = useState(true);
	const [save_loading, setSaveLoading] = useState(false);
	const [img, setImg] = useState('/images/courses/no-image.jpg');
	const [userInfo, setUserInfo] = useState({
		lastname: '',
		firstname: '',
		middlename: '',
		gender: '',
		birth_date: '',
		passport_number: '',
		region_id: '',
		city_id: '',
		school_id: '',
		position_id: '',
		work_place: '',
		education_level: '',
		education_place: '',
		education_year: '',
		identification_number: '',
		bio: '',
		id: '',
		is_teacher: 0,
		position_type: {},
		// teacher_type: '',
	});
	const [is_check, setIsCheck] = useState(0);
	const [erp_disable, setErp_disable] = useState(false);
	const [errors, setErrors] = useState({
		firstnameError: '',
		lastnameError: '',
		middlenameError: '',
		genderError: '',
		birth_dateError: '',
		passport_numberError: '',
		region_idError: '',
		city_idError: '',
		school_idError: '',
		positionTypeError: '',
		work_positionError: '',
		work_placeError: '',
		education_levelError: '',
		education_placeError: '',
		education_yearError: '',
		identification_numberError: '',
	});
	const {
		lastname,
		firstname,
		middlename,
		gender,
		birth_date,
		passport_number,
		region_id,
		city_id,
		position_id,
		work_place,
		education_level,
		education_year,
		education_place,
		identification_number,
		bio,
		avatar,
		id,
		is_teacher,
		position_type,
		school_id,
		teacher_type,
	} = userInfo;
	const {
		lastnameError,
		firstnameError,
		middlenameError,
		genderError,
		birth_dateError,
		passport_numberError,
		region_idError,
		city_idError,
		work_positionError,
		work_placeError,
		education_levelError,
		education_placeError,
		education_yearError,
		identification_numberError,
		school_idError,
		positionTypeError,
		teacher_typeError,
	} = errors;
	const [isLatin, setIsLatin] = useState(true);
	const regex = new RegExp(
		/^[^аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯЙйҚқҲҳЎўғҒ]*$/
	);
	const [userPosition, setUserPosition] = useState([
		{
			id: '',
			name: '',
			created_at: '',
			updated_at: '',
		},
	]);
	const [teacherTypeList, setTeacherTypeList] = useState([
		{
			id: '',
			name: '',
			created_at: '',
			updated_at: '',
		},
	]);
	const [positionUsers, setPositionUsers] = useState([]);
	const [position_types, setPositionTypes] = useState([]);
	const [removeWork, setRemoveWork] = useState(null);
	const [schools, setSchools] = useState([]);
	const isTeacher = userPosition?.find(
		({ id }) => id === parseInt(position_id)
	)?.teacher_type;
	useEffect(() => {
		// getProfile();
		getRegionList();
		getPositionType();
		getPositions(position_type.type_id);
		getTeacherType();
	}, []);
	useEffect(() => {
		if (position_type.type_id) {
			getPositionType();
			getPositions(position_type.type_id);
		}
		if (region_id && city_id) getSchools(region_id, city_id);
	}, [is_check]);
	const getPositionType = () => {
		getInstance()
			.get('/api/v1/profil/position-type')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setPositionTypes(data ? data : []);
					} catch {}
				}
			})
			.catch(error => {});
	};
	const getPositions = id => {
		getInstance()
			.get(`/api/v1/profil/user-position?type_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setUserPosition(data ? data : []);
					} catch {}
				}
			})
			.catch(error => {});
	};
	const getSchools = (r_id, id) => {
		getInstance()
			.get(`/api/v1/school?region_id=${r_id}&city_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setSchools(data ? data : []);
					} catch {}
				}
			})
			.catch(error => {});
	};
	const getTeacherType = id => {
		getInstance()
			.get(`/api/v1/profil/teacher-types`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setTeacherTypeList(data ? data : []);
					} catch {}
				}
			})
			.catch(() => {});
	};
	useEffect(() => {
		setImg(
			_.get(profile, 'avatar')
				? IMG_URL + _.get(profile, 'avatar')
				: '/images/courses/no-image.jpg'
		);
	}, [profile, userImage]);
	useEffect(() => {
		const {
			lastname,
			firstname,
			middlename,
			gender,
			birth_date,
			passport_number,
			region_id,
			city_id,
			school_id,
			work_place,
			position_id,
			education_level,
			education_place,
			education_year,
			identification_number,
			bio,
			is_teacher,
			position_type,
			id,
			teacher_type,
			position_users,
		} = profile;
		setPositionUsers(position_users);
		// let t = [],
		// 	p = {},
		// 	tl = [];
		// _.get(profile, 'position_users', []).forEach(item => {
		// 	if (!tl.includes(item.type_id)) {
		// 		tl = [...tl, item.type_id];
		// 		t = [...t, { value: item.type.id, label: item.type.name }];
		// 	}
		// });
		// setMultiplePositionTypes(t);
		// tl.forEach(item => {
		// 	let pl = [];
		// 	_.get(profile, 'position_users', []).forEach(item_p => {
		// 		if (item_p.type_id === item)
		// 			pl = [...pl, { value: item_p.id, label: item_p.name }];
		// 	});
		// 	p = { ...p, [item]: pl };
		// });
		// setMultiplePositions(p);

		if (
			!regex.test(lastname) ||
			!regex.test(firstname) ||
			!regex.test(middlename) ||
			!regex.test(work_place) ||
			!regex.test(education_place) ||
			!regex.test(education_level)
		) {
			setIsLatin(false);
		}
		if (
			lastname &&
			lastname.length &&
			firstname &&
			firstname.length &&
			middlename &&
			middlename.length &&
			gender &&
			gender.length &&
			birth_date &&
			birth_date.length &&
			passport_number &&
			passport_number.length === 9 &&
			region_id &&
			region_id.toString().length &&
			city_id &&
			city_id.toString().length &&
			// teacher_type &&
			// teacher_type.toString().length &&
			work_place &&
			work_place.length &&
			position_id &&
			position_id.toString().length &&
			education_level &&
			education_level.length &&
			education_place &&
			education_place.length &&
			education_year &&
			education_year.length &&
			identification_number &&
			identification_number.toString().length === 14
		) {
			//block_user
			if (profile.is_teacher === 1) {
				setIsCheck(1);
			} else {
				setIsCheck(2);
			}
		} else {
			setIsCheck(0);
		}

		setUserInfo({
			lastname: lastname ? lastname : '',
			firstname: firstname ? firstname : '',
			middlename: middlename ? middlename : '',
			gender: gender ? gender : '',
			birth_date: birth_date ? birth_date : '',
			passport_number: passport_number ? passport_number : '',
			region_id: region_id ? region_id : '',
			city_id: city_id ? city_id : '',
			position_id: position_id ? position_id : '',
			work_place: work_place ? work_place : '',
			education_level: education_level ? education_level : '',
			education_place: education_place ? education_place : '',
			education_year: education_year ? education_year : '',
			identification_number: identification_number
				? identification_number
				: '',
			bio: bio ? bio : '',
			id: id,
			is_teacher: is_teacher,
			position_type: position_type,
			school_id: school_id ? school_id : '',
			teacher_type: teacher_type ? teacher_type : '',
		});
	}, [profile, profile_update_status, profile_status]);
	useEffect(() => {
		const { avatar, role_id } = props.profile;
		if (role_id === 5) history.push('/dashboard/edu-institution');
		if (avatar) {
			if (avatar !== 'users/default.png') {
				setUserImage(avatar);
			}
		}
		const { region_id } = profile;
		if (region_id) {
			getCitiesList(region_id);
		}
		getPositions(_.get(profile, 'position_type.type_id', 0));
	}, [profile]);
	useEffect(() => {
		if (profile_status.status === 'started') {
			setLoading(true);
		}
		if (profile_status.status === 'success') {
			setLoading(false);
		}
		if (profile_update_status.status === 'started') {
			setSaveLoading(true);
		}
		if (
			profile_update_status.status === 'success' ||
			profile_update_status.status === 'error'
		) {
			setSaveLoading(false);
			window.scrollTo(0, 0);
		}
	}, [profile_status.status, profile_update_status.status]);
	const changeUserInfo = e => {
		const { value, name } = e.target;
		const regexp = regex.test(value);
		if (regexp) {
			setUserInfo({
				...userInfo,
				[name]: value,
			});
		}
		if (name === 'region_id') {
			getCitiesList(value);
		}
	};
	const submitHandler = async event => {
		event.preventDefault();
		if (
			lastname.length &&
			firstname.length &&
			middlename.length &&
			gender.length &&
			birth_date.length &&
			passport_number.length === 9 &&
			region_id.toString().length &&
			city_id.toString().length &&
			// teacher_type.toString().length &&
			work_place.length &&
			position_id.toString().length &&
			education_level.length &&
			education_place.length &&
			education_year.length &&
			identification_number.toString().length === 14 &&
			position_type.type_id &&
			position_type.type_id.toString().length &&
			position_type.type_id &&
			position_type.type_id.toString().length &&
			(findIsSchoolID.is_School === 1 ? school_id : true)
		) {
			setSaveLoading(true);
			let formData = new FormData(event.target);
			// for (const property in multiplePositions) {
			// 	_.get(multiplePositions, property, []).forEach(item => {
			// 		formData.append('position[]', _.get(item, `value`));
			// 	});
			// }
			// positionUsers
			// 	?.filter(
			// 		({ position_id, region_id, city_id, school_id }) =>
			// 			position_id && region_id && city_id && school_id
			// 	)
			// 	?.forEach(
			// 		(
			// 			{ position_id, teacher_type, region_id, city_id, school_id },
			// 			index
			// 		) => {
			// 			formData.append(`position[${index}][position]`, position_id);
			// 			formData.append(`position[${index}][region_id]`, region_id);
			// 			formData.append(`position[${index}][city_id]`, city_id);
			// 			formData.append(`position[${index}][school_id]`, school_id);
			// 			formData.append(
			// 				`position[${index}][teacher_type]`,
			// 				teacher_type?.id ? teacher_type?.id : 0
			// 			);
			// 		}
			// 	);
			await updateProfile(formData, {
				status: true,
				title: t('user_msg.salutation'),
				text: t('user_msg.save_profile'),
			});
			getProfile();
			setUserImage('name');
		} else {
			setErrors({
				lastnameError: lastname.length ? '' : t('profile.lastnameError'),
				firstnameError: firstname.length ? '' : t('profile.firstnameError'),
				middlenameError: middlename.length
					? ''
					: t('profile.middlenameError'),
				passport_numberError:
					passport_number.length === 9 ? '' : t('profile.passportError'),
				genderError: gender.length ? '' : t('profile.genderError'),
				birth_dateError: birth_date.length
					? ''
					: t('profile.birthdateError'),
				region_idError: region_id.toString().length
					? ''
					: t('profile.regionError'),
				city_idError: city_id.toString().length
					? ''
					: t('profile.cityError'),
				work_placeError: work_place.length
					? ''
					: t('profile.workPlaceError'),
				work_positionError: position_id.toString().length
					? ''
					: t('profile.positionError'),
				education_levelError: education_level.length
					? ''
					: t('profile.levelError'),
				education_placeError: education_place.length
					? ''
					: t('profile.placeError'),
				education_yearError: education_year.length
					? ''
					: t('profile.yearError'),
				identification_numberError:
					identification_number.toString().length === 14
						? ''
						: t('profile.identificationError'),
				school_idError: school_id.toString().length
					? ''
					: t('profile.schoolError'),
				positionTypeError:
					position_type.type_id && position_type.type_id.toString().length
						? ''
						: t('profile.position_typeError'),
				// teacher_typeError: teacher_type.toString().length
				//   ? ''
				//   : t('profile.positionError'),
			});
		}
	};
	const changePassport = e => {
		const { value } = e.target;
		const test1 = new RegExp(/^[A-Za-z]{0,2}$/).test(value.slice(0, 2));
		const test2 = new RegExp(/^[0-9]{0,7}$/).test(value.slice(2, 9));
		if (test1 && test2 && value.length < 10) {
			setUserInfo({
				...userInfo,
				passport_number: value.toString().toUpperCase(),
			});
		}
	};
	const focusInput = name => {
		setErrors({
			...errors,
			[name]: '',
		});
	};
	const onChangeIdentificationNumber = e => {
		const { value, name } = e.target;
		const re = /^(?:[ 0-9 ]{0,20})$/;
		const v = re.test(value);
		if (v && value.length < 15) {
			setUserInfo({
				...userInfo,
				[name]: value,
			});
		} else {
			if (!value) {
				setUserInfo({
					...userInfo,
					[name]: '',
				});
			}
		}
	};
	const deleteImage = async () => {
		const { id } = userInfo;
		await deleteFile({ id: id, type: 'profile', field: ['avatar'] });
		await getProfile();
		setUserImage('');
	};
	const updateInfo = () => {
		setUserInfo({
			...userInfo,
			firstname: '',
			lastname: '',
			middlename: '',
			education_level: '',
			education_place: '',
			work_place: '',
		});
	};
	const remove = e => {
		e.stopPropagation();
		e.preventDefault();
		const positions = positionUsers?.filter(
			(item, order) => removeWork !== order
		);
		setPositionUsers(positions);
		window.$('#modalRemoveWork').modal('hide');
	};
	// {
	//   /* ##### new design for selects necessary currently in comment ##### */
	// }
	// const deleteAddressProfile = (id) => {
	//   let l = [];
	//   addressProfile.forEach((item, index) => {
	//     if (index !== id) l.push(item);
	//   });
	//   setAddressProfile(l);
	// };
	// const addressProfileSet = (id, v) => {
	//   let l = [];
	//   addressProfile.forEach((item, index) => {
	//     if (index !== id) l.push(item);
	//     else l.push(v);
	//   });
	//   setAddressProfile(l);
	// };
	// {
	//   /* ##### new design for selects necessary currently in comment ##### */
	// }
	let findIsSchoolID = {};
	position_types.forEach(item => {
		if (item.id === Number(position_type?.type_id)) {
			findIsSchoolID = {
				...item,
				is_School: item.is_school,
			};
		}
	});
	return (
		<>
			<Layout>
				<main className='main'>
					<section className='section section-dashboard py-0'>
						<div className='container'>
							<div className='row'>
								<DashSideBar />
								<div className='col-md-12 col-lg-9 col-xl-10'>
									<div className='dashboard-right'>
										<Navigation
											is_dash={true}
											is_btn={true}
											navs={[
												{
													link: '/dashboard/profile',
													title: t('profile.title'),
												},
											]}
											active={t('profile.edit-title')}
										/>
										<div className='dashboard-title'>
											<h5>{t('profile.edit-title')}</h5>
										</div>
										{loading ? (
											<Loading />
										) : (
											<>
												{is_check !== 0 ? (
													<>
														<div className='card'>
															<div className='card-body'>
																<div className='row'>
																	<div className='p-3'>
																		{is_check === 1 ? (
																			<>
																				<h4
																					className='profile-data-title'
																					style={{
																						color: '#26CAAC',
																					}}
																				>
																					{t(
																						'profile.title1'
																					)}
																				</h4>
																				<p
																					style={{
																						fontSize: 14,
																					}}
																					dangerouslySetInnerHTML={{
																						__html:
																							t(
																								'profile.text1'
																							),
																					}}
																				/>
																			</>
																		) : (
																			<>
																				<h4
																					className='profile-data-title'
																					style={{
																						color: 'orange',
																					}}
																				>
																					{t(
																						'profile.title2'
																					)}
																				</h4>
																				<p
																					style={{
																						fontSize: 14,
																					}}
																					dangerouslySetInnerHTML={{
																						__html:
																							t(
																								'profile.text2'
																							),
																					}}
																				/>
																			</>
																		)}
																	</div>
																	<div
																		className='p-3'
																		id='card-first-info'
																	>
																		<div
																			style={{
																				display: 'flex',
																				width: '100%',
																			}}
																		>
																			<img
																				style={{
																					height: 100,
																					width: 100,
																					objectFit:
																						'cover',
																					borderRadius:
																						'50%',
																				}}
																				onError={e => {
																					e.target.onerror =
																						null;
																					e.target.src =
																						'/images/user/user.png';
																				}}
																				src={img}
																				alt='User Onlinr Edu'
																			/>
																			<div
																				style={{
																					marginLeft: 15,
																					display: 'flex',
																					justifyContent:
																						'space-evenly',
																					flexDirection:
																						'column',
																				}}
																			>
																				<h3 className='profile-first-last-name'>
																					<span
																						style={{
																							fontSize: 20,
																						}}
																					>
																						{firstname}{' '}
																					</span>
																					<span
																						style={{
																							fontSize: 20,
																						}}
																					>
																						{lastname}{' '}
																					</span>
																					<span
																						style={{
																							fontSize: 20,
																						}}
																					>
																						{middlename}{' '}
																					</span>
																				</h3>
																				<h3
																					style={{
																						fontSize: 18,
																						color: '#898DA6',
																					}}
																				>
																					<span
																						className='icon icon-briefcase'
																						style={{
																							marginRight: 8,
																						}}
																					></span>
																					<span>
																						{' '}
																						{position_type
																							? position_type
																									.type
																									.name
																							: null}{' '}
																						(
																						{position_type
																							? position_type.name
																							: ''}
																						)
																					</span>
																				</h3>
																			</div>
																		</div>
																		{is_check === 1 ? null : (
																			<span
																				onClick={() =>
																					setIsCheck(0)
																				}
																				style={{
																					minWidth: 275,
																				}}
																				id='profile-edit-link'
																				className='btn btn-sm btn-secondary-light'
																			>
																				<span
																					className='icon icon-edit'
																					style={{
																						marginRight: 6,
																					}}
																				></span>
																				<span>
																					{t(
																						'profile.edit_btn'
																					)}
																				</span>
																			</span>
																		)}
																	</div>

																	<div className='p-3 row'>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				*{' '}
																				{t('profile.phone')}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				+{profile.name}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.birth_date'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{birth_date}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.gender'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{gender === 'man'
																					? t(
																							'profile.gender_m'
																					  )
																					: t(
																							'profile.gender_w'
																					  )}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.passport_number'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{passport_number}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.identification_number'
																				)}
																				<span
																					className='btn-jshshir'
																					data-toggle='modal'
																					data-target='#modalJShSHIR'
																				>
																					?
																				</span>
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{
																					identification_number
																				}
																			</p>
																		</div>

																		<div className='col-12 mt-3'>
																			<h4
																				style={{
																					fontSize: 20,
																				}}
																				className='profile-data-title'
																			>
																				{t('profile.work')}
																			</h4>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.region'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{region_list.data
																					? region_list.data.map(
																							item => (
																								<>
																									{item.id ===
																									region_id
																										? item.name
																										: null}
																								</>
																							)
																					  )
																					: null}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t('profile.city')}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{cities_list.data
																					? cities_list.data.map(
																							item => (
																								<>
																									{item.id ===
																									city_id
																										? item.name
																										: null}
																								</>
																							)
																					  )
																					: null}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.work_position'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{position_type
																					? position_type
																							.type.name
																					: null}{' '}
																				(
																				{position_type
																					? position_type.name
																					: ''}
																				)
																			</p>
																		</div>
																		{position_type &&
																		findIsSchoolID.is_School ===
																			1 ? (
																			<div className='col-md-4'>
																				<h4 className='profile-data-title'>
																					*{' '}
																					{t(
																						'profile.school'
																					)}
																				</h4>
																				<p
																					style={{
																						fontSize: 14,
																					}}
																				>
																					{_.get(
																						profile,
																						'school.name',
																						''
																					)}
																				</p>
																			</div>
																		) : null}

																		{/* <div className='col-12'>
                                      <h4 className='profile-data-title'>
                                        {t('profile-page.more_position')}
                                      </h4>
                                      <p style={{ fontSize: 14 }}>
                                        {multiplePositionTypes.map(
                                          (item, index) => (
                                            <span key={index}>
                                              {item?.label}(
                                              {_.get(
                                                multiplePositions,
                                                item.value,
                                                []
                                              ).map((itep, indp) => (
                                                <span key={indp}>
                                                  {' '}
                                                  {itep?.label},{' '}
                                                </span>
                                              ))}
                                              ),{' '}
                                            </span>
                                          )
                                        )}

                                      </p>
                                    </div> */}
																		<div className='col-12'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.work_place'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{work_place}
																			</p>
																		</div>

																		<div className='col-12  mt-3'>
																			<h4
																				style={{
																					fontSize: 20,
																				}}
																				className='profile-data-title'
																			>
																				{t(
																					'profile.education'
																				)}
																			</h4>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.education_place'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{education_place}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.education_level'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{education_level}
																			</p>
																		</div>
																		<div className='col-md-4'>
																			<h4 className='profile-data-title'>
																				{t(
																					'profile.education_year'
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{education_year}
																			</p>
																		</div>
																		<div className='col-12  mt-3'>
																			<h4
																				style={{
																					fontSize: 20,
																				}}
																				className='profile-data-title'
																			>
																				{t(
																					'profile-page.biography'
																				)}
																			</h4>
																		</div>
																		<div className='col-12'>
																			<p
																				style={{
																					fontSize: 14,
																				}}
																			>
																				{bio
																					? bio
																					: 'Kiritilmagan'}
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												) : (
													<form onSubmit={submitHandler}>
														<div className='card'>
															<div className='card-body pb-0'>
																<h6 className='mb-4'>
																	{t('profile.data')}
																</h6>
																{isLatin ? null : (
																	<>
																		{/* <h6 className="mb-4" style={{ color: 'red' }}>
																			{t('profile.kiril-error')}
																		</h6> */}
																		<button
																			onClick={() => {
																				updateInfo();
																				setIsLatin(true);
																			}}
																			type='button'
																			className='btn btn-sm mb-2 btn-primary'
																			style={{
																				color: 'white',
																			}}
																		>
																			{t('profile.reenter')}
																		</button>
																	</>
																)}
																<div className='row'></div>
																<div className='row'>
																	<div className='col-6 col-xl-4'>
																		<div className='form-group'>
																			<img
																				style={{
																					height: 100,
																					width: 100,
																					objectFit:
																						'cover',
																					borderRadius:
																						'50%',
																				}}
																				onError={e => {
																					e.target.onerror =
																						null;
																					e.target.src =
																						'/images/user/user.png';
																				}}
																				src={img}
																				alt='Course'
																			/>
																		</div>
																	</div>
																	<div className='col-6 col-xl-8'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.avatar'
																				)}{' '}
																				(.jpg, .jpeg, .png)
																			</label>
																			<div className='custom-file'>
																				<input
																					type='file'
																					className='form-control'
																					name='avatar'
																					accept='.jpg, .jpeg, .png'
																				/>
																				<div>
																					{t(
																						'profile.avatar_btn'
																					)}
																				</div>
																			</div>
																			<br />
																			{img &&
																			profile.avatar !==
																				'users/default.png' ? (
																				<button
																					className='btn btn-sm mt-2'
																					onClick={
																						deleteImage
																					}
																					style={{
																						background:
																							'red',
																						color: 'white',
																						padding: 9,
																					}}
																				>
																					<span
																						className='icon icon-remove'
																						style={{
																							marginRight: 5,
																						}}
																					></span>
																					<span>
																						{t(
																							'profile.delete-image'
																						)}
																					</span>
																				</button>
																			) : null}
																		</div>
																	</div>
																</div>
																<div className='row'>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.lastname'
																				)}
																			</label>
																			<input
																				type='text'
																				className='form-control'
																				name='lastname'
																				onFocus={() =>
																					focusInput(
																						'lastnameError'
																					)
																				}
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				value={lastname}
																				disabled={
																					erp_disable
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{lastnameError}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.firstname'
																				)}
																			</label>
																			<input
																				type='text'
																				name='firstname'
																				className='form-control'
																				onFocus={() =>
																					focusInput(
																						'firstnameError'
																					)
																				}
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				value={firstname}
																				disabled={
																					erp_disable
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{firstnameError}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.middlename'
																				)}
																			</label>
																			<input
																				type='text'
																				className='form-control'
																				name='middlename'
																				onFocus={() =>
																					focusInput(
																						'middlenameError'
																					)
																				}
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				value={middlename}
																				disabled={
																					erp_disable
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{middlenameError}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.gender'
																				)}
																			</label>
																			<select
																				className='form-control custom-select'
																				name='gender'
																				onFocus={() =>
																					focusInput(
																						'genderError'
																					)
																				}
																				disabled={
																					erp_disable
																				}
																				onChange={e =>
																					changeUserInfo(e)
																				}
																			>
																				<option
																					value=''
																					selected
																				>
																					{t(
																						'profile.select-gender'
																					)}
																				</option>
																				<option
																					value='man'
																					selected={
																						gender ===
																						'man'
																							? true
																							: false
																					}
																				>
																					{t(
																						'profile.gender_m'
																					)}
																				</option>
																				<option
																					value='woman'
																					selected={
																						gender ===
																						'woman'
																							? true
																							: false
																					}
																				>
																					{t(
																						'profile.gender_w'
																					)}
																				</option>
																			</select>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{genderError}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.birth_date'
																				)}
																			</label>
																			<input
																				type='date'
																				className='form-control'
																				name='birth_date'
																				onFocus={() =>
																					focusInput(
																						'birth_dateError'
																					)
																				}
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				value={birth_date}
																				disabled={
																					erp_disable
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{birth_dateError}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				*{' '}
																				{t('profile.phone')}
																			</label>
																			<input
																				type='text'
																				className='form-control'
																				name='name'
																				disabled
																				value={_.get(
																					profile,
																					'name'
																				)}
																			/>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.passport_number'
																				)}
																			</label>
																			<input
																				type='text'
																				className='form-control'
																				placeholder='AA0123456'
																				name='passport_number'
																				onFocus={() =>
																					focusInput(
																						'passport_numberError'
																					)
																				}
																				onChange={e =>
																					changePassport(e)
																				}
																				value={
																					passport_number
																				}
																				disabled={
																					erp_disable
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{
																					passport_numberError
																				}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.identification_number'
																				)}
																				<span
																					className='btn-jshshir'
																					data-toggle='modal'
																					data-target='#modalJShSHIR'
																				>
																					?
																				</span>
																			</label>
																			<input
																				type='text'
																				className='form-control'
																				name='identification_number'
																				onChange={e =>
																					onChangeIdentificationNumber(
																						e
																					)
																				}
																				onFocus={() =>
																					focusInput(
																						'identification_numberError'
																					)
																				}
																				value={
																					identification_number
																				}
																				disabled={
																					erp_disable
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{
																					identification_numberError
																				}
																			</h5>
																		</div>
																	</div>
																</div>
																<h6 className='mt-1 mb-4'>
																	{t('profile.work')}
																</h6>
																<div className='row'>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.position_type'
																				)}
																			</label>
																			<select
																				onChange={e => {
																					setUserInfo({
																						...userInfo,
																						position_type:
																							{
																								type_id:
																									e
																										.target
																										.value,
																							},
																						position_id:
																							'',
																						school_id: '',
																					});
																					getPositions(
																						e.target.value
																					);
																				}}
																				onFocus={() =>
																					focusInput(
																						'positionTypeError'
																					)
																				}
																				name='position_type'
																				disabled={
																					erp_disable
																				}
																				value={
																					position_type &&
																					position_type.type_id
																						? position_type.type_id
																						: 0
																				}
																				className='form-control custom-select'
																			>
																				<option
																					value={0}
																					selected
																				>
																					{t(
																						'profile.position_type_text'
																					)}
																				</option>
																				{position_types
																					? position_types.map(
																							(
																								item,
																								index
																							) => (
																								<option
																									key={
																										index
																									}
																									value={
																										item.id
																									}
																								>
																									{
																										item.name
																									}
																								</option>
																							)
																					  )
																					: null}
																			</select>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{positionTypeError}
																			</h5>
																		</div>
																	</div>
																	{position_type &&
																	position_type.type_id ? (
																		<div className='col-md-6 col-xl-4'>
																			<div className='form-group'>
																				<label for=''>
																					{t(
																						'profile.work_position'
																					)}
																				</label>
																				<select
																					onChange={e =>
																						changeUserInfo(
																							e
																						)
																					}
																					onFocus={() =>
																						focusInput(
																							'work_positionError'
																						)
																					}
																					name='position_id'
																					value={
																						position_id
																					}
																					disabled={
																						erp_disable
																					}
																					className='form-control custom-select'
																				>
																					<option
																						value=''
																						selected
																					>
																						{t(
																							'profile.select-position'
																						)}
																					</option>
																					{userPosition
																						? userPosition.map(
																								(
																									item,
																									index
																								) => (
																									<option
																										key={
																											index
																										}
																										selected={
																											item.id ===
																											position_id
																												? 'selected'
																												: ''
																										}
																										value={
																											item.id
																										}
																									>
																										{
																											item.name
																										}
																									</option>
																								)
																						  )
																						: null}
																				</select>
																				<h5
																					style={{
																						color: 'red',
																						fontSize: 15,
																					}}
																				>
																					{
																						work_positionError
																					}
																				</h5>
																			</div>
																		</div>
																	) : null}
																	{position_type &&
																	position_type.type_id &&
																	isTeacher ? (
																		<div className='col-md-6 col-xl-4'>
																			<div className='form-group'>
																				<label for=''>
																					* O'qituvchi
																					turlari
																				</label>
																				<select
																					onChange={e =>
																						changeUserInfo(
																							e
																						)
																					}
																					onFocus={() =>
																						focusInput(
																							'teacher_typeError'
																						)
																					}
																					className='form-control custom-select'
																					name='teacher_type'
																					value={
																						teacher_type
																					}
																					disabled={
																						erp_disable
																					}
																				>
																					<option
																						value=''
																						selected
																					>
																						O'qituvchi
																						turlari
																					</option>
																					{teacherTypeList
																						? teacherTypeList.map(
																								(
																									item,
																									index
																								) => (
																									<option
																										key={
																											index
																										}
																										selected={
																											item.id ===
																											teacher_type
																												? 'selected'
																												: ''
																										}
																										value={
																											item.id
																										}
																									>
																										{
																											item.name
																										}
																									</option>
																								)
																						  )
																						: null}
																				</select>
																			</div>
																		</div>
																	) : null}
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.region'
																				)}
																			</label>
																			<select
																				className='form-control custom-select'
																				name='region_id'
																				value={region_id}
																				disabled={
																					erp_disable
																				}
																				onFocus={() =>
																					focusInput(
																						'region_idError'
																					)
																				}
																				onChange={e => {
																					setUserInfo({
																						...userInfo,
																						region_id:
																							e.target
																								.value,
																						city_id: '',
																						school_id: '',
																					});
																					getCitiesList(
																						e.target.value
																					);
																				}}
																			>
																				<option
																					value=''
																					selected
																					disabled
																				>
																					{t(
																						'profile.select-region'
																					)}
																				</option>
																				{region_list.data
																					? region_list.data.map(
																							(
																								item,
																								index
																							) => (
																								<option
																									key={
																										index
																									}
																									value={
																										item.id
																									}
																								>
																									{
																										item.name
																									}
																								</option>
																							)
																					  )
																					: null}
																			</select>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{region_idError}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t('profile.city')}
																			</label>
																			<select
																				value={city_id}
																				disabled={
																					erp_disable
																				}
																				onChange={e => {
																					setUserInfo({
																						...userInfo,
																						city_id:
																							e.target
																								.value,
																						school_id: '',
																					});
																					getSchools(
																						region_id,
																						e.target.value
																					);
																				}}
																				onFocus={() =>
																					focusInput(
																						'city_idError'
																					)
																				}
																				name='city_id'
																				className='form-control custom-select'
																			>
																				<option
																					value=''
																					selected
																				>
																					{t(
																						'profile.select-city'
																					)}
																				</option>
																				{cities_list.data
																					? cities_list.data.map(
																							(
																								item,
																								index
																							) => (
																								<option
																									key={
																										index
																									}
																									value={
																										item.id
																									}
																								>
																									{
																										item.name
																									}
																								</option>
																							)
																					  )
																					: null}
																			</select>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{city_idError}
																			</h5>
																		</div>
																	</div>
																	{_.isEmpty(
																		findIsSchoolID
																	) ? null : (
																		<>
																			{findIsSchoolID.is_School ===
																			1 ? (
																				<>
																					{position_type &&
																					position_type.type_id &&
																					city_id ? (
																						<div className='col-md-6 col-xl-4'>
																							<div className='form-group'>
																								<label for=''>
																									*{' '}
																									{t(
																										'profile.school'
																									)}
																								</label>
																								<select
																									value={
																										school_id
																									}
																									onChange={e =>
																										changeUserInfo(
																											e
																										)
																									}
																									onFocus={() =>
																										focusInput(
																											'school_idError'
																										)
																									}
																									name='school_id'
																									className='form-control custom-select'
																								>
																									<option
																										value=''
																										selected
																									>
																										{t(
																											'profile.school_text'
																										)}
																									</option>
																									{schools
																										? schools.map(
																												(
																													item,
																													index
																												) => (
																													<option
																														key={
																															index
																														}
																														value={
																															item.id
																														}
																													>
																														{
																															item.name
																														}
																													</option>
																												)
																										  )
																										: null}
																								</select>
																								<h5
																									style={{
																										color: 'red',
																										fontSize: 15,
																									}}
																								>
																									{
																										school_idError
																									}
																								</h5>
																							</div>
																						</div>
																					) : (
																						<div className='col-md-6 col-xl-4'></div>
																					)}
																				</>
																			) : null}
																		</>
																	)}
																	{/* {positionUsers?.map(
																		(props, index) => (
																			<ProfileTeacherType
																				{...props}
																				teacherTypeList={
																					teacherTypeList
																				}
																				userPosition={
																					userPosition
																				}
																				region_list={
																					region_list
																				}
																				position_types={
																					position_types
																				}
																				positionUsers={
																					positionUsers
																				}
																				setRemoveWork={
																					setRemoveWork
																				}
																				setPositionUsers={
																					setPositionUsers
																				}
																				index={index}
																				key={index}
																			/>
																		)
																	)}
																	<div
																		style={{ width: '100%' }}
																	>
																		<div className='col-md-6 col-xl-4'>
																			<div className='form-group'>
																				<button
																					type='button'
																					onClick={() =>
																						setPositionUsers(
																							[
																								...positionUsers,
																								{},
																							]
																						)
																					}
																					className='btn btn-primary btn-sm'
																				>
																					Lavozim qo'shish
																				</button>
																			</div>
																		</div>
																	</div> */}
																	{/* <div className='col-12 mb-4'>
                                    <MultiplePositions
                                      position_type={multiplePositionTypes}
                                      position={multiplePositions}
                                      setPosition={setMultiplePositions}
                                      setPositionType={setMultiplePositionTypes}
                                    />
                                  </div> */}
																	{/* ##### new design for selects necessary currently in comment ##### */}
																	{/* <div className='col-12'>
                                    {addressProfile.map((item, index) => (
                                      <div key={index} className='row'>
                                        <div className='col-md-6 col-xl-4'>
                                          <div className='form-group'>
                                            <label for=''>
                                              {t('profile.position_type')}
                                            </label>
                                            <select
                                              onChange={(e) => {
                                                setUserInfo({
                                                  ...userInfo,
                                                  position_type: {
                                                    type_id: e.target.value,
                                                  },
                                                  position_id: '',
                                                  school_id: '',
                                                });
                                                getPositions(e.target.value);
                                              }}
                                              onFocus={() =>
                                                focusInput('positionTypeError')
                                              }
                                              name='position_type'
                                              disabled={erp_disable}
                                              value={
                                                position_type &&
                                                position_type.type_id
                                                  ? position_type.type_id
                                                  : 0
                                              }
                                              className='form-control custom-select'
                                            >
                                              <option value={0} selected>
                                                {t(
                                                  'profile.position_type_text'
                                                )}
                                              </option>
                                              {position_types
                                                ? position_types.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item.id}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    )
                                                  )
                                                : null}
                                            </select>
                                            <h5
                                              style={{
                                                color: 'red',
                                                fontSize: 15,
                                              }}
                                            >
                                              {positionTypeError}
                                            </h5>
                                          </div>
                                        </div>
                                        {position_type &&
                                        position_type.type_id ? (
                                          <div className='col-md-6 col-xl-4'>
                                            <div className='form-group'>
                                              <label for=''>
                                                {t('profile.work_position')}
                                              </label>
                                              <select
                                                onChange={(e) =>
                                                  changeUserInfo(e)
                                                }
                                                onFocus={() =>
                                                  focusInput(
                                                    'work_positionError'
                                                  )
                                                }
                                                name='position_id'
                                                value={position_id}
                                                disabled={erp_disable}
                                                className='form-control custom-select'
                                              >
                                                <option value='' selected>
                                                  {t('profile.select-position')}
                                                </option>
                                                {userPosition
                                                  ? userPosition.map(
                                                      (item, index) => (
                                                        <option
                                                          key={index}
                                                          selected={
                                                            item.id ===
                                                            position_id
                                                              ? 'selected'
                                                              : ''
                                                          }
                                                          value={item.id}
                                                        >
                                                          {item.name}
                                                        </option>
                                                      )
                                                    )
                                                  : null}
                                              </select>
                                              <h5
                                                style={{
                                                  color: 'red',
                                                  fontSize: 15,
                                                }}
                                              >
                                                {work_positionError}
                                              </h5>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className='col-md-6 col-xl-4'></div>
                                        )}
                                        {findIsSchoolID.id === 5 ? (
                                          <div className='col-md-6 col-xl-4'>
                                            <div className='form-group'>
                                              <label for=''>
                                                * O'qituvchi turlari
                                              </label>
                                              <select
                                                onChange={(e) =>
                                                  changeUserInfo(e)
                                                }
                                                onFocus={() =>
                                                  focusInput(
                                                    'teacher_typeError'
                                                  )
                                                }
                                                className='form-control custom-select'
                                                name='teacher_type'
                                                value={teacher_type}
                                                isabled={erp_disable}
                                              >
                                                <option value='' selected>
                                                  O'qituvchi turlari
                                                </option>
                                                {teacherTypeList
                                                  ? teacherTypeList.map(
                                                      (item, index) => (
                                                        <option
                                                          key={index}
                                                          // selected={
                                                          //   item.id ===
                                                          //   position_id
                                                          //     ? 'selected'
                                                          //     : ''
                                                          // }
                                                          value={item.id}
                                                        >
                                                          {item.name}
                                                        </option>
                                                      )
                                                    )
                                                  : null}
                                              </select>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className='col-md-6 col-xl-4'>
                                          <div className='form-group'>
                                            <label for=''>
                                              {t('profile.region')}
                                            </label>
                                            <select
                                              className='form-control custom-select'
                                              name='region_id'
                                              value={region_id}
                                              disabled={erp_disable}
                                              onFocus={() =>
                                                focusInput('region_idError')
                                              }
                                              onChange={(e) => {
                                                setUserInfo({
                                                  ...userInfo,
                                                  region_id: e.target.value,
                                                  city_id: '',
                                                  school_id: '',
                                                });
                                                getCitiesList(e.target.value);
                                              }}
                                            >
                                              <option
                                                value=''
                                                selected
                                                disabled
                                              >
                                                {t('profile.select-region')}
                                              </option>
                                              {region_list.data
                                                ? region_list.data.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item.id}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    )
                                                  )
                                                : null}
                                            </select>
                                            <h5
                                              style={{
                                                color: 'red',
                                                fontSize: 15,
                                              }}
                                            >
                                              {region_idError}
                                            </h5>
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-xl-4'>
                                          <div className='form-group'>
                                            <label for=''>
                                              {t('profile.city')}
                                            </label>
                                            <select
                                              value={city_id}
                                              disabled={erp_disable}
                                              onChange={(e) => {
                                                setUserInfo({
                                                  ...userInfo,
                                                  city_id: e.target.value,
                                                  school_id: '',
                                                });
                                                getSchools(
                                                  region_id,
                                                  e.target.value
                                                );
                                              }}
                                              onFocus={() =>
                                                focusInput('city_idError')
                                              }
                                              name='city_id'
                                              className='form-control custom-select'
                                            >
                                              <option value='' selected>
                                                {t('profile.select-city')}
                                              </option>
                                              {cities_list.data
                                                ? cities_list.data.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item.id}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    )
                                                  )
                                                : null}
                                            </select>
                                            <h5
                                              style={{
                                                color: 'red',
                                                fontSize: 15,
                                              }}
                                            >
                                              {city_idError}
                                            </h5>
                                          </div>
                                        </div>

                                        {_.isEmpty(findIsSchoolID) ? null : (
                                          <>
                                            {findIsSchoolID.is_School === 1 ? (
                                              <>
                                                {position_type &&
                                                position_type.type_id &&
                                                city_id ? (
                                                  <div className='col-md-6 col-xl-4'>
                                                    <div className='form-group'>
                                                      <label for=''>
                                                        * {t('profile.school')}
                                                      </label>
                                                      <select
                                                        value={school_id}
                                                        onChange={(e) =>
                                                          changeUserInfo(e)
                                                        }
                                                        onFocus={() =>
                                                          focusInput(
                                                            'school_idError'
                                                          )
                                                        }
                                                        name='school_id'
                                                        className='form-control custom-select'
                                                      >
                                                        <option
                                                          value=''
                                                          selected
                                                        >
                                                          {t(
                                                            'profile.school_text'
                                                          )}
                                                        </option>
                                                        {schools
                                                          ? schools.map(
                                                              (item, index) => (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    item.id
                                                                  }
                                                                >
                                                                  {item.name}
                                                                </option>
                                                              )
                                                            )
                                                          : null}
                                                      </select>
                                                      <h5
                                                        style={{
                                                          color: 'red',
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        {school_idError}
                                                      </h5>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className='col-md-6 col-xl-4'></div>
                                                )}
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                        <div className='col-md-12'>
                                          <button
                                            className='btn btn-primary btn-sm mb-4'
                                            type='button'
                                            onClick={() =>
                                              deleteAddressProfile(index)
                                            }
                                          >
                                            <span class='icon icon-close'></span>
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                    <button
                                      className='btn btn-primary btn-sm mb-4'
                                      type='button'
                                      onClick={() =>
                                        setAddressProfile([
                                          ...addressProfile,
                                          '',
                                        ])
                                      }
                                    >
                                      <span class='icon icon-plus'></span>{' '}
                                      {t('edu_institution.add_phone')}
                                    </button>
                                  </div> */}

																	{/* ##### new design for selects necessary currently in comment ##### */}

																	<div className='col-12'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.work_place'
																				)}
																			</label>
																			<textarea
																				className='form-control'
																				rows='4'
																				name='work_place'
																				disabled={
																					erp_disable
																				}
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				onFocus={() =>
																					focusInput(
																						'work_placeError'
																					)
																				}
																				value={work_place}
																			></textarea>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{work_placeError}
																			</h5>
																		</div>
																	</div>
																</div>
																<h6 className='mt-1 mb-4'>
																	{t('profile.education')}
																</h6>
																<div className='row'>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.education_place'
																				)}
																			</label>
																			<input
																				type='text'
																				className='form-control'
																				name='education_place'
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				onFocus={() =>
																					focusInput(
																						'education_placeError'
																					)
																				}
																				value={
																					education_place
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{
																					education_placeError
																				}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.education_level'
																				)}
																			</label>{' '}
																			<input
																				type='text'
																				className='form-control'
																				name='education_level'
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				onFocus={() =>
																					focusInput(
																						'education_levelError'
																					)
																				}
																				value={
																					education_level
																				}
																			/>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{
																					education_levelError
																				}
																			</h5>
																		</div>
																	</div>
																	<div className='col-md-6 col-xl-4'>
																		<div className='form-group'>
																			<label for=''>
																				{t(
																					'profile.education_year'
																				)}
																			</label>

																			<select
																				name='education_year'
																				onChange={e =>
																					changeUserInfo(e)
																				}
																				onFocus={() =>
																					focusInput(
																						'education_yearError'
																					)
																				}
																				value={
																					education_year
																				}
																				className='form-control custom-select'
																			>
																				<option value=''>
																					{t(
																						'profile.education_year'
																					)}
																				</option>
																				{(function () {
																					let l = [];
																					for (
																						let y =
																							parseInt(
																								new Date().getFullYear()
																							);
																						y > 1899;
																						y--
																					) {
																						l.push(
																							<option
																								value={
																									y
																								}
																								key={y}
																							>
																								{y}
																							</option>
																						);
																					}
																					return l;
																				})()}
																			</select>
																			<h5
																				style={{
																					color: 'red',
																					fontSize: 15,
																				}}
																			>
																				{
																					education_yearError
																				}
																			</h5>
																		</div>
																	</div>
																</div>
																<h6 className='mt-1 mb-4'>
																	{t('profile.bio_title')}
																</h6>
																<div className='form-group'>
																	<label for=''>
																		{t('profile.bio')}
																	</label>
																	<textarea
																		className='form-control'
																		rows='4'
																		name='bio'
																		onChange={e =>
																			changeUserInfo(e)
																		}
																		value={bio}
																	></textarea>
																</div>
															</div>
														</div>
														{save_loading ? (
															<Loading />
														) : (
															<div className='dashboard-buttons'>
																<button
																	className='btn btn-primary px-60 ml-auto'
																	type='submit'
																>
																	{t('profile.save')}
																</button>
															</div>
														)}
													</form>
												)}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</Layout>
			<div
				class='modal fade'
				id='modalRemoveWork'
				tabindex='-1'
				role='dialog'
				aria-labelledby='modalLogoutTitle'
				aria-hidden='true'
			>
				<div class='modal-dialog modal-dialog-centered' role='document'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h5 class='modal-title'>Olib tashlash</h5>
							<button
								type='button'
								class='close'
								data-dismiss='modal'
								aria-label='Close'
							>
								<span class='icon icon-close'></span>
							</button>
						</div>
						<div class='modal-body'>
							<label for='' class='mb-6 text-center'>
								Ish joyingiz haqidagi ma'lumotni olib tashlamoqchimisiz?
							</label>
							<div class='row'>
								<div class='col-6'>
									<button
										type='button'
										class='btn btn-lighter btn-sm w-100'
										data-dismiss='modal'
									>
										{t('log_out.cancel')}
									</button>
								</div>
								<div class='col-6'>
									<button
										type='button'
										class='btn btn-danger btn-sm w-100'
										onClick={remove}
									>
										Olib tashlash
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className='modal fade'
				id='modalJShSHIR'
				tabindex='-1'
				role='dialog'
				aria-labelledby='modalJShSHIRTitle'
				aria-hidden='true'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					role='document'
					style={{ maxWidth: 1000 }}
				>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>
								{t('profile.what_jshshir')}
							</h5>
							<button
								type='button'
								className='close'
								data-dismiss='modal'
								aria-label='Close'
							>
								<span className='icon icon-close'></span>
							</button>
						</div>
						<div className='modal-body'>
							<p
								dangerouslySetInnerHTML={{
									__html: t('profile.jshshir'),
								}}
							/>
							<img
								src={`/images/jshshir-${t('profile.jshshir-img')}.jpg`}
								alt='JShShIR'
								style={{ width: '100%' }}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default connect(
	state => ({
		cities_list: state.cities_list,
		profile: state.profile,
		profile_status: state.profile_status,
		profile_update_status: state.profile_update_status,
		region_list: state.region_list,
	}),
	dispatch => ({
		deleteFile: data => dispatch(deleteFile(data)),
		getCitiesList: id => dispatch(getCitiesList(id)),
		getProfile: () => dispatch(getProfile()),
		getRegionList: () => dispatch(getRegionList()),
		updateProfile: (data, msg) => dispatch(updateProfile(data, msg)),
	})
)(DashProfilePage);
