import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import Loading from './sections/Loading';
import NavigationLocation from './sections/Navigation';
import NoData from './sections/NoData';

const ManagerCoursesReview = props => {
	const { slug, id } = props.match.params;
	const { t } = useTranslation();
	const [videos, setVideos] = useState([]);
	const [loading, setLoading] = useState(true);
	const { profile } = useSelector(state => state);
	useEffect(() => {
		getInstance()
			.get(`/api/v1/admin/courses/lesson-video?course_id=${id}`)
			.then(response => {
				const { data, status } = response.data;
				if (status === 1) {
					const videos = Array.isArray(data) ? data : [];
					setVideos(videos);
				} else {
					setVideos([]);
				}
			})
			.catch(() => setVideos([]))
			.finally(() => setLoading(false));
	}, [slug, id]);
	return (
		<Layout>
			<CheckRole roles={[roles.vazirlik]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<div class="dashboard-right">
									<NavigationLocation
										is_dash={true}
										is_btn={true}
										active="Kurs videolari"
										navs={[
											{
												link: '/manager/courses/1',
												title: t('m_courses.title'),
											},
											{
												link: `/manager/courses/review/${slug}/${id}`,
												title: t('m_review.title'),
											},
										]}
									/>
									{loading ? (
										<Loading />
									) : videos.length ? (
										<div className="row">
											<div className="col-lg-3">
												<div className="course-single__sidebar left-sidebar-menu">
													<div className="left-sidebar-menu__inner">
														<div className="course-single__menu">
															<div className="card card-shadow">
																<div className="card-body">
																	<h4 className="pb-2">Kurs videolari</h4>
																	<ul>
																		{videos.map(
																			({ name, lessons, ...rest }, index) => {
																				const lesson = Array.isArray(lessons)
																					? lessons[0]
																					: '';
																				const videoId = lesson ? lesson.id : '';
																				return (
																					<li key={index}>
																						<h5 className="pb-2">
																							<Link
																								to={`/manager/courses/review/${slug}/${id}/videos/${videoId}`}>
																								<span className="icon icon-play"></span>
																								<span>
																									{index + 1}. {name}
																								</span>
																							</Link>
																						</h5>
																					</li>
																				);
																			},
																		)}
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-9">
												<div className="card card--shadow">
													<div className="card-body">
														<NoData text="Video tanlang" />
													</div>
												</div>
											</div>
										</div>
									) : (
										<div className="card card--shadow">
											<div className="card-body">
												<NoData text="Kursda videolar yo'q" />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default ManagerCoursesReview;
