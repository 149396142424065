import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { FILE_URL } from "../../helpers/api";
import { useTranslation } from 'react-i18next';
import JoditTextShow from "./JoditTextShow";
import {getToken} from '../../helpers/tokenStorge'

const LessonText = (props) => {
    const {course_lesson} = props;
    const { t } = useTranslation();
    return (
    <div className="container">
        <h3 className="mb-4">{_.get(course_lesson, 'data.name')}</h3>
        <div className="webinar-details">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 order-md-1">
                            <div className="webinar-details__description">
                                {/* <img src="/images/webinars/webinar-2.jpg" alt="text img" /> */}
                                {/* <br/> */}
                                {/* <br/> */}
                                {/* <br/> */}
                                <JoditTextShow text={ _.get(course_lesson, 'data.content') } />
                                {_.get(course_lesson, 'data.file')?
                                    <a href={FILE_URL+_.get(course_lesson, 'data.file')+'?token='+getToken()} className="btn btn-primary ml-auto mt-2" 
                                        target="blank"
                                    >
                                        {t('course_view.text_btn')}
                                    </a>
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
    })
)(LessonText);