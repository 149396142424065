import React from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IMG_URL } from "../../helpers/api";
SwiperCore.use([Mousewheel, Pagination]);

const  RecommendedCourses = (props) => {
	const { t } = useTranslation();
    const {data} = props;
    let course_list = [];
    data.forEach( (category, index) => {
        _.get(category, 'category.courses', []).forEach((course)=>{
            if(index<3 || course_list.length<20){
                course_list.push({
                    name: course.name,
                    category: category.name,
                    slug: course.slug,
                    img: course.image
                });
            }
        })
    });
    return (<>
        <div className="card  card--shadow qualification-progress">
            <div className="card-body">
                <div className="row p-3">
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                        <h4
                            style={{
                                fontSize: 18,
                                fontWeight: 700,
                                marginBottom: 20,
                                paddingBottom: 0,
                            }}>
                            {t('profile-page.recommen-course')}
                        </h4>
                    </div>
                    <div style={{ width: '100%' }}>
                    {course_list.length ? (
                        <Swiper
                            spaceBetween={0}
                            scrollbar={{
                                draggable: true,
                                mousewheel: false,
                            }}
                            slidesPerView={'auto'}
                            pagination={{
                                dynamicBullets: true,
                                clickable: true,
                            }}
                            direction={'horizontal'}
                            mousewheel={false}
                            style={{ marginBottom: '20px' }}>
                                {course_list.map((course, index)=>(
                                    <SwiperSlide
                                        style={{
                                            width: 266,
                                            height: 350,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        key={index}>
                                        <Link to={'/dashboard/courses/view/' + course.slug}>
                                            <div
                                                style={{
                                                    width: 230,
                                                    minWidth: 230,
                                                    height: 270,
                                                    borderRadius: 12,
                                                    overflow: 'hidden',
                                                    boxShadow: '0px 8px 24px rgba(13, 46, 105, 0.08)',
                                                    margin: 15,
                                                }}>
                                                <div style={{ position: 'relative' }}>
                                                    <img
                                                        src={IMG_URL + course.img}
                                                        onError={e => {
                                                            e.target.onerror = null;
                                                            e.target.src = '/images/user/course.png';
                                                        }}
                                                        style={{ height: 190, objectFit: 'cover', width: '100%' }}
                                                    />
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            left: 12,
                                                            bottom: 10,
                                                            fontWeight: 500,
                                                            fontSize: 14,
                                                            background: '#F2994A',
                                                            padding: '3px 8px',
                                                            borderRadius: 5,
                                                            color: 'white',
                                                            marginRight: 10
                                                        }}>
                                                        {course.category}
                                                    </span>
                                                </div>
                                                <h6 style={{ fontSize: 14, padding: 10 }}>{course.name}</h6>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}>
                                <p>{t('profile-page.recommen-course-not')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        <style jsx>{`
            .swiper-scrollbar {
                background: transparent;
            }
            .swiper-pagination-bullet {
                width: 16px;
                height: 16px;
            }
            .swiper-pagination-bullet-active-main {
                background: #26caac;
            }
            .swiper-button-prev,
            .swiper-button-next {
                transform: scale(0.7);
            }
        `}</style>
    </>);
}
export default RecommendedCourses;