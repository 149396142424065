import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Loading from '../Loading';

const Step3 = (props) => {
  const {
    edu,
    disabled,
    setStep,
    step,
    setAuditoriums,
    auditoriums,
    setAuditoriums_size,
    auditoriums_size,
    setPractical,
    practical,
    setPractical_size,
    practical_size,
    setForeign_languages,
    foreign_languages,
    setComputer_room,
    computer_room,
    setComputer_size,
    computer_size,
    FILE_URL,
    file_nameFileLabaratoryImg,
    file_nameFileCompRomImg,
    file_namefFileLangImg,
    filefLabImg,
    fileCompRImg,
    filelangImg,
    checkNumber,
    submitTest4,
    loading,
    errorEdu,
    setErrorEdu,
    setEdu,
  } = props;

  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest4}>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>Moddiy-texnik ta’minoti</h6>

          {errorEdu.auditoriums ||
          errorEdu.auditoriums_size ||
          errorEdu.practical ||
          errorEdu.practical_size ||
          errorEdu.fileLabaratoryImg ||
          errorEdu.fileCompRomImg ||
          errorEdu.computer_size ||
          errorEdu.foreign_languages ||
          errorEdu.fileLangImg ? (
            <div className='inputError mb-4'>
              {t('edu_institution.main_err')}
            </div>
          ) : null}
        </div>
        <div className='col-12'>
          <h6 className='mb-4'>O‘quv auditoriyalar</h6>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label for=''>Soni</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  auditoriums:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.auditoriums,
                });
                setErrorEdu({ ...errorEdu, auditoriums: false });
              }}
              type='number'
              maxLength={'5'}
              name='auditoriums'
              value={edu.auditoriums ? edu.auditoriums : null}
              style={errorEdu.auditoriums ? { borderColor: '#f00' } : {}}
              className='form-control edu_institution_input'
              placeholder={t('soni')}
            />
          </div>
        </div>

        <div className='col-6'>
          <div className='form-group'>
            <label for=''>Sig’imi</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  auditoriums_size:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.auditoriums_size,
                });
                setErrorEdu({ ...errorEdu, auditoriums_size: false });
              }}
              type='number'
              maxLength={'5'}
              name='auditoriums_size'
              value={edu.auditoriums_size ? edu.auditoriums_size : null}
              className='form-control edu_institution_input'
              style={errorEdu.auditoriums_size ? { borderColor: '#f00' } : {}}
              placeholder={t('soni')}
            />
          </div>
        </div>

        <div className='col-12'>
          <h6 className='mb-4'>
            Amaliy (laboratoriya) mashg‘ulotlarni o‘tkazish uchun maxsus xonalar
          </h6>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label for=''>Soni</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  practical:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.practical,
                });
                setErrorEdu({ ...errorEdu, practical: false });
              }}
              type='number'
              maxLength={'5'}
              name='practical'
              value={edu.practical ? edu.practical : null}
              className='form-control edu_institution_input'
              style={errorEdu.practical ? { borderColor: '#f00' } : {}}
              placeholder={t('soni')}
            />
          </div>
        </div>

        <div className='col-6'>
          <div className='form-group'>
            <label for=''>Sig`imi</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  practical_size:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.practical_size,
                });
                setErrorEdu({ ...errorEdu, practical_size: false });
              }}
              type='number'
              maxLength={'5'}
              name='practical_size'
              value={edu.practical_size ? edu.practical_size : null}
              className='form-control edu_institution_input'
              style={errorEdu.practical_size ? { borderColor: '#f00' } : {}}
              placeholder={t('soni')}
            />
          </div>
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              <span style={{ marginRight: '10px' }}>
                Laboratoriya ishlarining bajarilishi uchun metodik
                ko‘rsatmalarning mavjudligi (Mavjud/Mavjud emas , .pdf yuklash)
              </span>
              <a
                href={
                  '/docs/Laboratoriya_ishlarining_bajarilishi_uchun_metodik_ko‘rsatma.pdf'
                }
                className='btn btn-primary btn-sm mb-3 btnEyeLink'
                style={{ display: 'inline-block', marginTop: '10px' }}
                target='_blank'
              >
                {t('edu_institution.eye2')}
              </a>
            </label>

            <div
              className='setting__buttons mb-4'
              style={{ justifyContent: 'flex-start' }}
            >
              <label className='switch-text'>
                {disabled === true ? (
                  <div className='disabledSwitchDiv' />
                ) : null}
                <input
                  disabled={disabled}
                  type='checkbox'
                  checked={edu.laboratory === 0 ? true : false}
                />
                <span className='slider'></span>
                <div className='switch-labels'>
                  <span
                    className='switch-label'
                    onClick={() => setEdu({ ...edu, laboratory: 1 })}
                  >
                    Mavjud
                  </span>
                  <span
                    className='switch-label'
                    onClick={() => setEdu({ ...edu, laboratory: 0 })}
                  >
                    Mavjud emas
                  </span>
                </div>
              </label>
            </div>
            {edu.laboratory !== 0 ? (
              <>
                <label
                  className='edu_institution_input edu_institution_file mb-3'
                  style={
                    errorEdu.fileLabaratoryImg ? { borderColor: '#f00' } : {}
                  }
                >
                  {file_nameFileLabaratoryImg &&
                  file_nameFileLabaratoryImg !== FILE_URL ? (
                    <b style={{ fontSize: 16 }}>{file_nameFileLabaratoryImg}</b>
                  ) : (
                    <img
                      src='/images/user/upload.png'
                      alt='education'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/user/upload.png';
                      }}
                    />
                  )}
                  <input
                    type='file'
                    name='file'
                    alt='education file'
                    hidden
                    disabled={disabled}
                    onChange={(e) => {
                      filefLabImg(e);
                      setErrorEdu({ ...errorEdu, fileLabaratoryImg: false });
                    }}
                    accept='.pdf'
                  />
                  <div>{t('edu_institution.file')}</div>
                </label>
                {edu.laboratory_image ? (
                  <a
                    href={FILE_URL + edu.laboratory_image}
                    className='btn btn-primary btn-sm mb-3'
                    target='_blank'
                  >
                    <span class='icon icon-eye'></span>{' '}
                    {t('edu_institution.eye')}
                  </a>
                ) : null}
                <div
                  className=''
                  style={{
                    color: '#e60000',
                    fontSize: 14,
                    marginBottom: '20px',
                  }}
                >
                  <span style={{ fontWeight: 800 }}>Izoh:</span> Laboratoriya
                  ishlarining bajarilishi bo’yicha metodik ko‘rsatma .pdf
                  shaklda yuklanadi.
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              Zamonaviy kompyuter texnikasi bilan ta’minlangan xonalar soni (son
              “ta”) (.jpeg .jpg yuklash)
            </label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  computer_room:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.computer_room,
                });
                setErrorEdu({ ...errorEdu, computer_room: false });
              }}
              type='text'
              name='computer_room'
              value={edu.computer_room ? edu.computer_room : null}
              className='form-control edu_institution_input'
              style={errorEdu.computer_room ? { borderColor: '#f00' } : {}}
              placeholder={t('soni')}
            />

            <label
              className='edu_institution_input edu_institution_file mb-3 mt-4'
              style={errorEdu.fileCompRomImg ? { borderColor: '#f00' } : {}}
            >
              {file_nameFileCompRomImg &&
              file_nameFileCompRomImg !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_nameFileCompRomImg}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  fileCompRImg(e);
                  setErrorEdu({ ...errorEdu, fileCompRomImg: false });
                }}
                accept='.jpeg, .jpg'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.computer_room_image ? (
              <a
                href={FILE_URL + edu.computer_room_image}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Har birida 15 ta
          zamonaviy kompyuter texnikasi bilan ta’minlangan kamida 2 ta xona
          bo’lishi kerak. Xonalar rasmini yuklang.
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>Zamonaviy kompyuterlar soni (son “ta”)</label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  computer_size:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.computer_size,
                });
                setErrorEdu({ ...errorEdu, computer_size: false });
              }}
              type='number'
              maxLength={'5'}
              name='computer_size'
              value={edu.computer_size ? edu.computer_size : null}
              className='form-control edu_institution_input'
              style={errorEdu.computer_size ? { borderColor: '#f00' } : {}}
              placeholder={t('soni')}
            />
          </div>
        </div>

        <div className='col-12'>
          <div className='form-group'>
            <label for=''>
              Xorijiy tillar bo‘yicha ixtisoslashtirilgan o‘quv xonalar soni
              (son “ta”) (.jpeg .jpg yuklash)
            </label>
            <input
              disabled={disabled}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  foreign_languages:
                    e.target.value.length < 5 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.foreign_languages,
                });
                setErrorEdu({ ...errorEdu, foreign_languages: false });
              }}
              type='number'
              maxLength={'5'}
              name='foreign_languages'
              value={edu.foreign_languages ? edu.foreign_languages : null}
              className='form-control edu_institution_input'
              style={errorEdu.foreign_languages ? { borderColor: '#f00' } : {}}
              placeholder={t('soni')}
            />

            <label
              className='edu_institution_input edu_institution_file mb-3 mt-4'
              style={errorEdu.fileLangImg ? { borderColor: '#f00' } : {}}
            >
              {file_namefFileLangImg && file_namefFileLangImg !== FILE_URL ? (
                <b style={{ fontSize: 16 }}>{file_namefFileLangImg}</b>
              ) : (
                <img
                  src='/images/user/upload.png'
                  alt='education'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/user/upload.png';
                  }}
                />
              )}
              <input
                type='file'
                name='file'
                alt='education file'
                hidden
                disabled={disabled}
                onChange={(e) => {
                  filelangImg(e);
                  setErrorEdu({ ...errorEdu, fileLangImg: false });
                }}
                accept='.jpeg, .jpg'
              />
              <div>{t('edu_institution.file')}</div>
            </label>
            {edu.foreign_languages_image ? (
              <a
                href={FILE_URL + edu.foreign_languages_image}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : null}
          </div>
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Xorijiy tillar bo‘yicha
          kurslarni tashkil etish uchun nutq (tinglab tushunish, o‘qib
          tushunish, yozish va gapirish) va til ko‘nikmalarini (grammatika,
          leksika va talaffuz) integrallashgan holda egallash imkoniyati bo‘lgan
          o‘quv xonasi. O’quv xonasining rasmini yuklang.
        </div>
      </div>

      {disabled ? null : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <button
                className='btn btn-primary btn-sm'
                style={{ marginRight: '15px' }}
                onClick={() => {
                  setStep(step - 1);
                  window.scroll(0, 0);
                }}
              >
                Oldingi
              </button>
              <button className='btn btn-primary btn-sm' type='submit'>
                Keyingi
              </button>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default Step3;
