import history from '../../history';
const CheckRole = props => {
	const { roles } = props;
	const role = parseInt(localStorage.getItem('role'));
	if (!roles.includes(role)) {
		history.push('/');
	}
	return null;
};
export default CheckRole;
