import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import Loading from './sections/Loading';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../helpers/httpClient';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import history from '../history';

const DashQualificationLan = props => {
	const { t } = useTranslation();
	const {profile} = props;
	const [loading, setLoading] = useState(true);
	const [lan_loading, setLanLoading] = useState(false);
	const [lans, setLans] = useState([]);

	useEffect(() => {
		getLan();
	}, []);
	useEffect(() => {
		if(profile.language_id&&profile.language_id!==0){
			history.push('/dashboard/qualification/diagnostic-test');
		}else{
			setLoading(false);
		}
	}, [profile]);
	const getLan = () => {
		getInstance()
			.get('/api/v1/languages?is_people=1')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setLans(data);
					} catch {
						history.push('/dashboard/qualification');
					}
				}
			})
			.catch(error => { } );
	}
	const submitHandler = event => {
		event.preventDefault();
		setLanLoading(true);
		const formData = new FormData(event.target);
		getInstance()
			.post(`/api/v1/profil/set-lang`, formData)
			.then(response => {
				setLanLoading(false);
				if (response.data.status === 1) {
					history.push('/dashboard/qualification/diagnostic-test');
				}
			})
			.catch(
				setLoading(false)
			);
	}
	return (
		<Layout>
			<CheckRole roles={ [ roles.teacher, roles.user ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={ t('qualification.lan_title') }
										navs={[
											{
												title: t('navigation.account'),
												link: '/dashboard',
											}
										]}
									/>
									<div className="dashboard-title">
										<h3>{ t('qualification.title') }</h3>
									</div>
									{loading?<Loading/>:
									<div className="row ">
										{/* <div className="col-12 col-md-6">
											<div className="card">
												<div className="card-body">
													<h4>{ t('qualification.lan_title') }</h4>
													<br/>
													<p  dangerouslySetInnerHTML={{ __html: t('qualification.lan_text') }}  />
												</div>
											</div>
										</div> */}
										<div className="col-12 col-md-6">
											<div className="card">
												<div className="card-body">
													<h4>{ t('qualification.lan') }</h4>
													<br/>
													<form onSubmit={submitHandler} >
														<div className="form-group">
															<label for="language_id"> { t('qualification.lan_choice') } </label>
															<select
																id="language_id"
																className="form-control custom-select"
																defaultValue={1}
																name="language_id">
																	{lans.map((lan, index) => (
																		<option value={lan.id} key={index}>
																			{lan.name}
																		</option>
																	))}
															</select>
														</div>
														<div className="test__buttons mb-5">
															{lan_loading ? (
																<Loading size={5}/>
															) : (
																<div className="dashboard-buttons">
																	<button
																		type="submit"
																		className="btn btn-sm btn-primary mr-2">
																		{ t('qualification.lan_btn') }
																	</button>
																</div>
															)}
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			
		</Layout>
	);
};
export default connect(
	state => ({
		profile: state.profile,
	})
)(DashQualificationLan);
