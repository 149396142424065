import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getCourse, testCheck } from '../../actions';
import Loading from '../sections/Loading';
import LessonTestGrouping from './LessonTestGrouping';
import LessonTestMultiple from './LessonTestMultiple';
import LessonTestSingle from './LessonTestSingle';
import LessonTestWordDrags from './LessonTestWordDrags';
const LessonText = props => {
	const { t } = useTranslation();
	const {
		course,
		course_lesson,
		section_slug,
		course_slug,
		test_check_status,
		in_dash,
	} = props;
	const [error, setError] = useState(false);
	// const [answers, setAnswers] = useState([]);
	const [send_loading, setSendLoading] = useState(false);
	useEffect(() => {
		if (test_check_status.status === 'started') {
			setSendLoading(true);
		}
		if (test_check_status.status === 'success') {
			setSendLoading(false);
			if (course?.data?.type ?? '' === 'target') {
				props.getCourse(course_slug);
			}
		}
		if (test_check_status.status === 'error') {
			setSendLoading(false);
		}
	}, [test_check_status]);
	const submitHandler = event => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const check = course_lesson?.data?.vquestions?.every(
			({ vanswers, type, id }) => {
				if (type === 'single') {
					return formData.has(`answers[${id}]`);
				} else if (type === 'multiple') {
					return formData.has(`answers[${id}][]`);
				} else if (type === 'grouping') {
					const check = vanswers?.some(({ id: a }) => {
						const value = formData.get(`answers[${id}][${a}]`);
						return value !== '[]';
					});
					return check;
				} else {
					const check = vanswers?.some((answer, index) => {
						return formData.get(`answers[${id}][word${index + 1}]`);
					});
					return check;
				}
			}
		);
		if (check) {
			setSendLoading(true);
			props.testCheck(
				formData,
				course_slug,
				section_slug,
				course_lesson.data.id,
				in_dash
			);
		} else {
			setError(true);
		}
	};
	return (
		<form onSubmit={submitHandler}>
			<input type="hidden" name="lesson_id" value={course_lesson.data.id} />
			<input type="hidden" name="section_slug" value={section_slug} />
			{course_lesson.data.vquestions.map((question, index) => (
				<div>
					{question.type === 'single' ? (
						<LessonTestSingle
							question={question}
							key={index}
							number={index}
						/>
					) : question.type === 'grouping' ? (
						<LessonTestGrouping
							question={question}
							key={index}
							number={index}
						/>
					) : question.type === 'dragwords' ? (
						<LessonTestWordDrags
							question={question}
							key={index}
							number={index}
						/>
					) : (
						<LessonTestMultiple
							question={question}
							key={index}
							number={index}
						/>
					)}
				</div>
			))}
			{error ? (
				<h4 className="text-danger">Barcha savollarga javob bermadingiz</h4>
			) : null}
			<div class="test__buttons">
				<div class="dashboard-buttons">
					{/* <button type="button" class="btn btn-lightest mr-2">Попробуйте снова</button> */}
					{send_loading ? (
						<Loading />
					) : (
						<button type="submit" class="btn btn-primary">
							{t('test_result.finish')}
						</button>
					)}
				</div>
			</div>
		</form>
	);
};
export default connect(
	state => state,
	dispatch => ({
		getCourse: slug => dispatch(getCourse(slug)),
		testCheck: (data, course_slug, section_slug, lesson_id, in_dash) =>
			dispatch(
				testCheck(data, course_slug, section_slug, lesson_id, in_dash)
			),
	})
)(LessonText);
