import React from 'react';
import _, { map } from 'lodash';
import { useTranslation } from 'react-i18next';
// import { FILE_URL } from '../../../helpers/api';

const ShowDisableEduInstCard = (props) => {
  const { edu, FILE_URL, phones, filePdfShow } = props;

  const { t } = useTranslation();
  const getPositionTypesList = (p) => {
    let pt_list = [],
      p_types = [];
    p.forEach((item) => {
      pt_list.push(item.type);
    });
    _.unionBy(pt_list, 'id').forEach((item) => {
      p_types.push({ id: item.id, name: item.name });
    });
    const pt = _.sortBy(p_types, [
      function (o) {
        return o.id;
      },
    ]);
    return pt;
  };
  return (
    <>
      <h6 className='my-4'>{t('edu_institution.reference')}</h6>
      <div className='row'>
        <div className='col-12'>
          <label>{t('edu_institution.name')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.name ? edu.name : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-12'>
          <label>{t('edu_institution.edu_institution_type')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.edu_institution_type
              ? edu.edu_institution_type
              : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-12'>
          <label>
            Ta’lim tashkilotining tasdiqlangan guvohnomasi (.pdf .png .jpg .jpeg
            yuklash)
          </label>

          {edu.certificate ? (
            <a
              href={FILE_URL + edu.certificate}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div className='col-12'>
          <label>Ta’lim tashkilotining Ustavi (.pdf yuklash)</label>
          {edu.statutes ? (
            <a
              href={FILE_URL + edu.statutes}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div className='col-12'>
          <label>Ilmiy-metodik kengash (.pdf yuklash)</label>

          {edu.council ? (
            <a
              href={FILE_URL + edu.council}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Ilmiy-metodik kengash
          faoliyati yo‘lga qo‘yilganligi va uning tarkibi bo’yicha ta'lim ta'lim
          tashkiloti rahbarining <span style={{ fontWeight: 800 }}>imzosi</span>{' '}
          va <span style={{ fontWeight: 800 }}> muhri </span>
          bilan tasdiqlangan hujjat yuklanadi.
        </div>
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.mail_address')}</h6>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>{t('edu_institution.region')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.region?.name ? edu.region?.name : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>{t('edu_institution.region')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.city?.name ? edu.city?.name : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-12'>
          <label>{t('edu_institution.address')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.address ? edu.address : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-12'>
          <label>{t('edu_institution.mail')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.mail_address ? edu.mail_address : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-12'>
          <label>{t('edu_institution.phone')}</label>
          {phones.length > 0 ? (
            phones.map((item, index) => {
              return (
                <p key={index} style={{ color: '#0D2E69' }}>
                  {item}
                </p>
              );
            })
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.edu')}</h6>
        </div>
        <div className='col-12'>
          <label>
            O‘quv-tajriba maydonchalari (tayanch o‘quv muassasalari)ning
            mavjudligi{' '}
          </label>
          <p style={{ color: '#0D2E69' }}>
            {edu.experimental_playground === 1 ? 'Mavjud' : 'Mavjud emas'}
          </p>
        </div>
        <div className='col-12'>
          <label>{t('edu_institution.type')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.is_online === 0 ? 'Offline' : 'Online'}
          </p>
        </div>
        {edu.is_online === 1 ? (
          <div className='col-12'>
            <label>{t('edu_institution.type')}</label>
            <p style={{ color: '#0D2E69' }}>
              {edu.url ? edu.url : 'Ma`lumot mavjud emas'}
            </p>
          </div>
        ) : null}
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.address_title')}</h6>
        </div>
        {edu?.address_all?.length !== 0 ? (
          map(edu.address_all, (item, index) => {
            let { address, city, region } = item;
            return (
              <React.Fragment key={index}>
                <div className='col-12 mb-4'>
                  <div
                    style={{
                      fontWeight: 500,
                      paddingRight: 50,
                    }}
                  >
                    {index + 1}-{t('edu_institution.address_all')}
                  </div>
                </div>
                <div className='col-md-6 col-xl-4'>
                  <label>{t('edu_institution.region')}</label>
                  <p style={{ color: '#0D2E69' }}>
                    {region.name ? region.name : 'Ma`lumot mavjud emas'}
                  </p>
                </div>
                <div className='col-md-6 col-xl-4'>
                  <label>{t('edu_institution.city')}</label>
                  <p style={{ color: '#0D2E69' }}>
                    {city.name ? city.name : 'Ma`lumot mavjud emas'}
                  </p>
                </div>
                <div className='col-12'>
                  <label>{t('edu_institution.address')}</label>
                  <p style={{ color: '#0D2E69' }}>
                    {address ? address : 'Ma`lumot mavjud emas'}
                  </p>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
        )}

        <div className='col-12'>
          <label>{t('edu_institution.position_type')}</label>
          {getPositionTypesList(_.get(edu, 'positions', [])).length > 0 ? (
            getPositionTypesList(_.get(edu, 'positions', [])).map(
              (t_item, t_index) => (
                <div key={t_index}>
                  <div className='edu_date' style={{ display: 'inline-block' }}>
                    {t_item.name}
                  </div>
                  <div>
                    {_.filter(_.get(edu, 'positions', []), (o) => {
                      return o.type_id === t_item.id;
                    }).map((p_item, p_index) => (
                      <div className='edu_contact' key={p_index}>
                        {p_item.name}
                      </div>
                    ))}
                  </div>
                </div>
              )
            )
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
      </div>

      <h6 className='mb-4'>O’quv-metodik ta’minoti</h6>

      <div>
        <label>
          Kurslarning ishchi o‘quv rejalari, ishchi o‘quv dasturlarining tayanch
          o‘quv reja va namunaviy o‘quv dasturlarga mosligi
        </label>
        <p style={{ color: '#0D2E69' }}>
          {edu.plan_compatibility === 0 ? 'Mos' : 'Mos emas'}
        </p>
      </div>

      <div>
        <label>
          Ishchi o‘quv rejalar (Arxivlangan holatda - .zip, .rar, .7zip)
        </label>

        {edu.plan ? (
          <a
            href={FILE_URL + edu.plan}
            className='btn btn-primary btn-sm mb-3'
            target='_blank'
          >
            <span class='icon icon-eye'></span> {t('edu_institution.eye')}
          </a>
        ) : (
          <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
        )}
      </div>

      <div>
        <label>
          Ishchi o‘quv dasturlar (Arxivlangan holatda - .zip, .rar, .7zip)
        </label>

        {edu.program ? (
          <a
            href={FILE_URL + edu.program}
            className='btn btn-primary btn-sm mb-3'
            target='_blank'
          >
            <span class='icon icon-eye'></span> {t('edu_institution.eye')}
          </a>
        ) : (
          <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
        )}
      </div>

      <div
        className=''
        style={{
          color: '#e60000',
          fontSize: 14,
          marginBottom: '20px',
        }}
      >
        <span style={{ fontWeight: 800 }}>Izoh:</span> Malaka oshirish
        kurslarining Ishchi o'quv reja va dasturlari ta'lim tashkiloti
        rahbarining <span style={{ fontWeight: 800 }}>imzosi</span> va
        <span style={{ fontWeight: 800 }}> muhri </span>
        bilan tasdiqlangan bo'lishi kerak.
      </div>

      <div>
        <label>
          O‘quv-uslubiy ta’minot (ma’ruza matnlari, amaliy mashg‘ulot
          ishlanmalari, taqdimotlari, nazorat topshiriqlari) (Arxivlangan
          holatda - .zip, .rar, .7zip)
        </label>

        {edu.provision ? (
          <a
            href={FILE_URL + edu.provision}
            className='btn btn-primary btn-sm mb-3'
            target='_blank'
          >
            <span class='icon icon-eye'></span> {t('edu_institution.eye')}
          </a>
        ) : (
          <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
        )}
      </div>

      <div>
        <label>
          Axborot resurs markazining mavjudligi (Mavjud/Mavjud emas “.pdf
          yuklash”)
        </label>
        {filePdfShow === 1 ? (
          <>
            {edu.information_resource ? (
              <a
                href={FILE_URL + edu.information_resource}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : (
              <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
            )}
          </>
        ) : (
          <p style={{ color: '#0D2E69' }}>Mavjud emas</p>
        )}
      </div>

      <h6 className='mb-4'>Moddiy-texnik ta’minoti</h6>
      <h6 className='mb-4'>O‘quv auditoriyalar</h6>
      <div className='row'>
        <div className='col-md-6 col-xl-4'>
          <label>Soni</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.auditoriums ? edu.auditoriums : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>Sig’imi</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.auditoriums_size
              ? edu.auditoriums_size
              : 'Ma`lumot mavjud emas'}
          </p>
        </div>
      </div>

      <h6 className='mb-4'>
        Amaliy (laboratoriya) mashg‘ulotlarni o‘tkazish uchun maxsus xonalar
      </h6>
      <div className='row'>
        <div className='col-md-6 col-xl-4'>
          <label>Soni</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.practical ? edu.practical : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>Sig’imi</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.practical_size ? edu.practical_size : 'Ma`lumot mavjud emas'}
          </p>
        </div>
      </div>

      <div>
        <label>
          Laboratoriya ishlarining bajarilishi uchun metodik ko‘rsatmalarning
          mavjudligi (Mavjud/Mavjud emas , .pdf yuklash)
        </label>
        {edu.laboratory === 1 ? (
          <>
            {edu.laboratory_image ? (
              <a
                href={FILE_URL + edu.laboratory_image}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : (
              <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
            )}
          </>
        ) : (
          <p style={{ color: '#0D2E69' }}>Mavjud emas</p>
        )}
      </div>

      <div
        className=''
        style={{
          color: '#e60000',
          fontSize: 14,
          marginBottom: '20px',
        }}
      >
        <span style={{ fontWeight: 800 }}>Izoh:</span> Laboratoriya ishlarining
        bajarilishi bo’yicha metodik ko‘rsatma .pdf shaklda yuklanadi.
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <label>
            Zamonaviy kompyuter texnikasi bilan ta’minlangan xonalar soni (son
            “ta”) (.jpeg .jpg yuklash)
          </label>
          <p style={{ color: '#0D2E69' }}>
            {edu.computer_room ? edu.computer_room : 'Ma`lumot mavjud emas'}
          </p>

          {edu.computer_room_image ? (
            <a
              href={FILE_URL + edu.computer_room_image}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Har birida 15 ta
          zamonaviy kompyuter texnikasi bilan ta’minlangan kamida 2 ta xona
          bo’lishi kerak. Xonalar rasmini yuklang.
        </div>

        <div className='col-md-12'>
          <label>Zamonaviy kompyuterlar soni (son “ta”)</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.computer_size ? edu.computer_size : 'Ma`lumot mavjud emas'}
          </p>
        </div>

        <div className='col-md-12'>
          <label>
            Xorijiy tillar bo‘yicha ixtisoslashtirilgan o‘quv xonalar soni (son
            “ta”) (.jpeg .jpg yuklash)
          </label>
          <p style={{ color: '#0D2E69' }}>
            {edu.foreign_languages
              ? edu.foreign_languages
              : 'Ma`lumot mavjud emas'}
          </p>

          {edu.foreign_languages_image ? (
            <a
              href={FILE_URL + edu.foreign_languages_image}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Xorijiy tillar bo‘yicha
          kurslarni tashkil etish uchun nutq (tinglab tushunish, o‘qib
          tushunish, yozish va gapirish) va til ko‘nikmalarini (grammatika,
          leksika va talaffuz) integrallashgan holda egallash imkoniyati bo‘lgan
          o‘quv xonasi. O’quv xonasining rasmini yuklang.
        </div>
      </div>

      <h6 className='mb-4'>Pedagog kadrlarning ilmiy-pedagogik salohiyati</h6>
      <div className='row'>
        <div className='col-12'>
          <label>O’qituvchilar tarkibi bo’yicha ma’lumot (.pdf yuklash)</label>

          {edu.teacher_data ? (
            <a
              href={FILE_URL + edu.teacher_data}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : (
            <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
          )}
        </div>
        <div
          className='col-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>Izoh:</span> Kurslarda dars
          beradigan o‘qituvchilar kamida magistr darajasiga ega bo‘lishi va
          o'qituvchilar tarkibi bo'yicha ma'lumot ta'lim tashkiloti rahbarining{' '}
          <span style={{ fontWeight: 800 }}>imzosi</span> va{' '}
          <span style={{ fontWeight: 800 }}>muhri</span> bilan tasdiqlangan
          bo'lishi kerak.
        </div>
      </div>

      <div>
        <label>Qayd etish kitobi (Mavjud/Mavjud emas) (.pdf yuklash)</label>
        {edu.note === 1 ? (
          <>
            {edu.note_book ? (
              <a
                href={FILE_URL + edu.note_book}
                className='btn btn-primary btn-sm mb-3'
                target='_blank'
              >
                <span class='icon icon-eye'></span> {t('edu_institution.eye')}
              </a>
            ) : (
              <p style={{ color: '#0D2E69' }}>Ma`lumot mavjud emas</p>
            )}
          </>
        ) : (
          <p style={{ color: '#0D2E69' }}>Mavjud emas</p>
        )}
      </div>
      <div
        className=''
        style={{
          color: '#e60000',
          fontSize: 14,
          marginBottom: '20px',
        }}
      >
        <span style={{ fontWeight: 800 }}>Izoh:</span> Ma’lumotnomalar va
        sertifikatlarning berilishini qayd etish kitobining elektron varianti
        .pdf shaklda yuklanadi.
      </div>

      <h6 className='mb-4'>{t('edu_institution.bank_info')}</h6>
      <div className='row'>
        <div className='col-md-6 col-xl-4'>
          <label>{t('edu_institution.account_number')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.account_number ? edu.account_number : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>{t('edu_institution.bank_name')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.bank_name ? edu.bank_name : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>{t('edu_institution.mfo')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.mfo ? edu.mfo : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-6 col-xl-4'>
          <label>{t('edu_institution.bank_inn')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.bank_inn ? edu.bank_inn : 'Ma`lumot mavjud emas'}
          </p>
        </div>
      </div>

      <h6 className='mb-4'>{t('edu_institution.owner')}</h6>
      <div className='row'>
        <div className='col-md-12'>
          <label>{t('edu_institution.owner_full_name')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.owner_full_name ? edu.owner_full_name : 'Ma`lumot mavjud emas'}
          </p>
        </div>
        <div className='col-md-12'>
          <label>{t('edu_institution.owner_positions')}</label>
          <p style={{ color: '#0D2E69' }}>
            {edu.owner_positions ? edu.owner_positions : 'Ma`lumot mavjud emas'}
          </p>
        </div>

        <div
          className='col-md-12'
          style={{
            color: '#e60000',
            fontSize: 14,
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 800 }}>ESLATMA.</span> Xalq ta’limi
          xodimlarining malakasini oshirish kurslarini tashkil etish talablari
          va shartlariga rioya qilinmagan, so‘ralgan ma’lumotlar belgilangan
          tartibda rasmiylashtirilmagan taqdirda sizning{' '}
          <span style={{ fontWeight: 800 }}>
            Arizangiz ko‘rib chiqilmasligi
          </span>{' '}
          bo‘yicha ogohlantiramiz.
        </div>
      </div>
    </>
  );
};

export default ShowDisableEduInstCard;
