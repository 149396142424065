import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { getCourse, getCourseSections, getCourseLesson  } from "../actions";
import _ from 'lodash';
import Layout from './Layout';
import Navigation from "./sections/Navigation";
import CourseSideBarLeft from "./sections/CourseSideBarLeft";
import TestResult from "./sections/TestResult";
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import LessonExchange from './sections/LessonExchange';

const LessonTestResult = (props) => {   
    const {course, course_lesson,  test_check} = props;
    // const [loading, setLoading] = useState(true);
    let slug = props.match.params.slug?props.match.params.slug:'';
    let section = props.match.params.section?props.match.params.section:'';
    let lesson_id = props.match.params.lesson_id?props.match.params.lesson_id:'';
    useEffect(()=>{
        props.getCourseLesson(slug,section,lesson_id);
        props.getCourse(slug);
        props.getCourseSections(slug, section);
        slug = props.match.params.slug?props.match.params.slug:'';

    },[props.match.params.slug, props.match.params.section, props.match.params.lesson_id]);
    

    return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
        <main className="main">
            <section className="section section-breadcrumbs">
                <div className="container">
                    <Navigation
                        is_btn={true}
                        active={_.get(course_lesson, 'data.name')}
                        navs={[ 
                            {
                                title: _.get(course, 'data.name'),
                                link: "/courses/view/"+_.get(course, 'data.slug')
                            }
                        ]}
                    />
                </div>
            </section>
            <section className="section section-course pt-3">
                <div className="container">
                    <h3 class="mb-4">{course.data&&course.data.name?course.data.name:''}</h3>
                    <div className="row">
                        <div className="col-lg-3">
                            <CourseSideBarLeft slug={slug} section={section}/>
                        </div>
                        <div className="col-lg-9 test-result-target-no-dash">
                            
                            <TestResult test_check={test_check} course_lesson={course_lesson} params={props.match.params}/>
                            <LessonExchange params={_.get(props, 'match.params', {})}/>
                        </div>
                    </div>
                </div>
            </section>

            </main>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourse: (slug)=>dispatch(getCourse(slug)),
        getCourseSections: (slug, section)=>dispatch(getCourseSections(slug, section)),
        getCourseLesson: (course_slug, section_slug, lesson_id)=>dispatch(getCourseLesson(course_slug, section_slug, lesson_id))
    })
)(LessonTestResult);