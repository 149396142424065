import Timer from 'react-compound-timer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Loading from './Loading';

const WaitLesson = (props) => {
    const {t} = useTranslation();
    const {children, section} = props;
    const {course_sections} = useSelector(state => state);
    const diff_minutes = () => {
        const dt1 = new Date();
        const dt2 = new Date(_.get(course_sections, 'last_section'));
        const diff =(dt2.getTime() - dt1.getTime()) / 60000;
        return Math.abs(diff);
    }
    const checkSection = () => {
        let r = true;
        _.get(course_sections, 'data', []).forEach(sec => {
            _.get(sec, 'childs', []).forEach(item=>{
                if(item.slug===section && item.has_permission===0) r=false;
            });
        });
        return r;
    }
    const checkPermission = () => {
        let r = false;
        _.get(course_sections, 'data', []).forEach(sec => {
            _.get(sec, 'childs', []).forEach(item=>{
                if(item.slug===section && item.has_permission===1) r=true;
            });
        });
        return r;
    }
    return(<>{course_sections.status?(checkSection()?(checkPermission()?children:null):
        <div className="card card--shadow">
            <div className="card-body">
                <div>
                    <h3><span className='icon icon-clock'></span> {t("wait_lesson.title")}</h3>
                    <div style={{ color: '#26caac', fontWeight: 'bold', fontSize: 20 }}>
                        <Timer
                            initialTime={(parseInt(_.get(course_sections, 'time', 0))-diff_minutes())*60000}
                            direction="backward"
                        >
                            {() => (<> <Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds /> </>)}
                        </Timer>
                    </div>
                    <p  style={{textAlign: 'justify'}}>
                        {t("wait_lesson.text")}
                    </p>
                </div>
            </div>
        </div>):<Loading/>}
    </>);
}
export default WaitLesson;