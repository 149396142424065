import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Messages from "./sections/messages/Messages";
import AuthModal from "./sections/AuthModal";

const Layout = (props) => {
return (<>
    <Messages/>
    <Header/>
        {props.children}
    <Footer/>
    <AuthModal/>
</>);
}
export default Layout;
