import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import Moment from 'react-moment';
import { API_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { getToken } from '../helpers/tokenStorge';
import _ from 'lodash';
import history from '../history';

const ManagerTeacherOpenWebinars = props => {
	const {  } = props;
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState({
		year: parseInt( new Date().getFullYear() ),
		month: parseInt( new Date().getMonth() )+1
	});
	const { user_id, user_name } = props.match.params;
	
	useEffect(()=>{
		const filter = getSearch();
		getData(filter);
	}, [window.location.search]);
	const filterData = () => {
		let s = '?';
		for (const [key, value] of Object.entries(search)) {
			if(value) s += key+'='+value+'&';
		}
		s = s.slice(0, -1);
		window.$('#modalFilter').modal('hide');
		history.push(`/manager/teachers/open-webinars/${user_id}/${user_name}${s}`);
	}
	const getData = (filter='') => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/teacher/result/webinars?user_id=${user_id}${filter}`)
			.then(response => {
				if (response.status === 200) {
					const { data } = response.data;
					if (data) {
						setData(data);
						setLoading(false);
					}
				} else {
					 setLoading(false);
				}
			})
			.catch(error => { setLoading(false); });
	}
	const getSearch = () => {
		let pairs = window.location.search.substring(1).split("&"),
			obj = {}, pair, i, s = '';
		for ( i in pairs ) {
			if ( pairs[i] === "" ) continue;
			pair = pairs[i].split("=");
			obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
			s += '&'+pair[0]+'='+pair[1];
		}
		setSearch({...search, ...obj});
		return s

	}
	const setFilter = e => {
		const {name, value} = e.target;
		setSearch({...search, [name]: value});
	}
	return (
		<Layout>
			<CheckRole roles={ [ roles.manager ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation is_dash={true} is_btn={true} active={user_name} 
										navs={[
											{
												link: '/manager/teachers',
												title: t('m_teacher.title'),
											},
										]}
									/>
									<div className="dashboard-title dashboard-title--has-buttons">
										<h5>{t('m_teacher.w_title')}</h5>
										<div className="buttons butons--target">
											{/* <a href={ API_URL + '/api/v1/' } 
												target="_blank"
												className="btn btn-white  btn-sm font-weight-medium">
												{t('m_pupils.excel_export')}
											</a> */}
											{/* <button
												className="btn btn-white btn-sm font-weight-medium"
												onClick={resetFilter}>
												{t('m_courses.reset')}
											</button> */}
											<button
												data-toggle="modal"
												data-target="#modalFilter"
												className="btn btn-white btn-sm font-weight-medium">
												{t('m_pupils.filter')}
												<span class="icon icon-filter fs-100 ml-1"></span>
											</button>
										</div>
									</div>
									{loading? (
										<Loading />
									) : (
										<>
											<div className="card card--shadow">
												<div className="card-body p-3">
													<div className="scroll scroll--horizontall">
														{data.length?<>
														<table className="table table-bordered manager-teachers-table">
															<thead>
																<tr>
																	{/* <th><div className="cell-inner">#</div></th> */}
																	<th><div className="cell-inner">{t('m_teacher.webinar')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.l_started')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.l_duration')}</div></th>
																	<th><div className="cell-inner">{t('m_teacher.l_create')}</div></th>
																</tr>
															</thead>
															<tbody>
																{data.map((item, index)=>(
																	<tr key={index}>
																		{/* <td>
																			<div className="cell-inner">
																				{index+1}.
																			</div>
																		</td> */}
																		<td>
																			<div className="cell-inner">
																				{_.get(item, 'title', '')}
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner">
																			{_.get(item, 'startdate', '')?
																				<Moment format="HH:mm DD/MM/YYYY">
																					{_.get(item, 'startdate', '').replace(' ', 'T')}
																				</Moment>
																			:null} 
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner"  style={{whiteSpace: 'nowrap'}}>
																			{ parseInt(parseInt(_.get(item, 'duration', 0))/60)} {t('m_teacher.hours')} { parseInt(_.get(item, 'duration', 0))%60} {t('m_teacher.minute')}
																			</div>
																		</td>
																		<td>
																			<div className="cell-inner">
																				<Moment format="HH:mm DD/MM/YYYY">
																					{_.get(item, 'created_at', '')}
																				</Moment>
																			</div>
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
														<div className="px-3"> <i>{t('m_teacher.all')}: {data.length}  {t('m_teacher.ta')}</i></div>
														</>:<NoData text={t('m_teacher.w_no_data')} />}
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<div className="modal fade" id="modalFilter" tabindex="-1" role="dialog" aria-labelledby="modalFilterTitle"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{t('m_pupils.filter')}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">

							<div className="form-group mb-3">
								<label for="">{t('m_teacher.year')}</label>
								<select
									name="year"
									className="form-control custom-select"
									value={search.year}
									onChange={e=>setFilter(e)}
								>
									{(function () {
										let l = [];
										for (
											let y = parseInt(
												new Date().getFullYear(),
											);
											y >= 2020;
											y--
										) {
											l.push(
												<option value={y} key={y}>
													{y} {t('m_teacher.year')}
												</option>
											);
										}
										return l;
									})()}
								</select>
							</div>

							<div className="form-group mb-3">
								<label for="">{t('m_teacher.month')}</label>
								<select
									name="month"
									className="form-control custom-select"
									value={search.month}
									onChange={e=>setFilter(e)}
								>
									<option value={1}>{t('m_teacher.month_1')}</option>
									<option value={2}>{t('m_teacher.month_2')}</option>
									<option value={3}>{t('m_teacher.month_3')}</option>
									<option value={4}>{t('m_teacher.month_4')}</option>
									<option value={5}>{t('m_teacher.month_5')}</option>
									<option value={6}>{t('m_teacher.month_6')}</option>
									<option value={7}>{t('m_teacher.month_7')}</option>
									<option value={8}>{t('m_teacher.month_8')}</option>
									<option value={9}>{t('m_teacher.month_9')}</option>
									<option value={10}>{t('m_teacher.month_10')}</option>
									<option value={11}>{t('m_teacher.month_11')}</option>
									<option value={12}>{t('m_teacher.month_12')}</option>
								</select>
							</div>
							<button type="button" className="btn btn-primary w-100" onClick={()=>filterData()} >
								{t('m_pupils.filter')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default ManagerTeacherOpenWebinars;
