import React from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import TeacherWebinarCreateOrUpdate from "./sections/TeacherWebinarCreateOrUpdate";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const DashCopyrightWebinarCreate = () => {
    const { t } = useTranslation();
    return (<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
        <main className="main">
            <section className="section section-dashboard py-0">
                <div className="container">
                    <div className="row">
                        <DashSideBar/>
                        <div className="col-md-12 col-lg-9 col-xl-10">
                            <div className="dashboard-right">
                                <Navigation
                                    is_dash={true}
                                    is_btn={true}
                                    active={ t('create_webinar.title') }
                                    navs={[
                                        {
                                            'title': t('navigation.account'),
                                            'link': '/dashboard'
                                        },
                                        {
                                            'title': t('create_webinar.webinar'),
                                            'link': '/dashboard/copyrights/webinars/list/1'
                                        }
                                    ]}
                                />
                                <div className="dashboard-title">
                                    <h5> { t('create_webinar.title') } </h5>
                                </div>
                                <TeacherWebinarCreateOrUpdate
                                    webinar_change_data={{}}
                                    is_create={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </Layout>);
}
export default DashCopyrightWebinarCreate;