import React, {useState, useEffect}  from 'react';
import { connect } from "react-redux";
import $ from "jquery";
import {validateSMS, signUp} from "../../actions";
import history from '../../history';
import Loading from './Loading';
import Timer from "react-compound-timer";
import { useTranslation } from 'react-i18next';

const  ValidateBotSMS = (props) => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [loading, setLoading] = useState(false);
    const [commonError, setCommonError] = useState('');
    const [resetSms, setResetSms] = useState(false);

    useEffect(() => {
        if(props.validate_sms.status === 'success'){
            props.validateEnd();
            props.actState(8);
        }
        if(props.validate_sms.status === 'started'){
            setLoading(true);
        }else{
            setLoading(false);
            if(props.validate_sms.status === 'error'){
                setCommonError(t("validate_sms.err"));
            }
        }

    }, [props.validate_sms]);
    const onSubmit = event => {
		event.preventDefault();
        let c=true, phone_c=true;
        // console.log(code, code.length);
        if(code>99999 || code<10000 ){
            setCodeError(t('validate_sms.code_e'));
            c=false;
        }else{
            setCodeError('');
            c=true;
            sessionStorage.setItem('valid_code', code);
        }
        const phone = sessionStorage.getItem('phone');
        if(phone&&phone.length!==12){
            setCommonError('Error');
            phone_c=false;
        }else{
            setCommonError('');
            phone_c=true;
        }
        if(c&&phone_c){
            props.validateSMS({
                    login: phone,
                    smscode: parseInt(code)
                }
            )
        }
    }
    const handleOnChange = (value) => {
        if(value.length <= 5){
            setCode( parseInt(value) );
        }else{
            setCode( parseInt(value.slice(0, 5)) );
        }
    }
    const resetSmsCode = () => {
        const phone = sessionStorage.getItem('phone');
        const password = sessionStorage.getItem('password');
        const confirm_password = password;
        setResetSms(false);
        props.signUp({login: phone, password: password, confirm_password: confirm_password });
    }
    const bot_url = sessionStorage.getItem('bot_url');
    return (<>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('validate_sms.title_bot')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon icon-close"></span>
                    </button>
                </div>
                <div className="modal-body">
                    <p style={{textAlign: 'center', fontWeight: 500}}>
                        {t('validate_sms.join_text')}
                        <br/>
                        <a className="btn btn-primary btn-sm" target="_blank" style={{color: '#fff'}}
                            href={"https://t.me/"+bot_url}
                        >
                            {t('validate_sms.join')}
                        </a>
                    </p>
                    <form onSubmit={onSubmit} noValidate> 
                        <div className="form-group mb-3">
                            <label for="">{t('validate_sms.code_bot')}</label>
                            <div className="password-field-wrapper">
                                <input type="number" name="code" onChange={(e)=>handleOnChange(e.target.value)} className="form-control" 
                                    placeholder={t('validate_sms.code_bot')}
                                    onClick={()=>{setCommonError(''); setCodeError('')}}
                                    value={code}
                                />
                                <span style={{color: 'red'}}>{codeError}</span>
                                <span style={{color: 'red'}}>{commonError}</span>
                            </div>
                        </div>
                        {loading?<Loading />:
                            <button type="sumbit" className="btn btn-primary w-100">
                                {t('validate_sms.btn')}
                            </button>
                        }
                        <div className="form-group mt-4 mb-5">
                            <p style={{color: '#26CAAC', cursor: 'pointer'}}
                                onClick={()=>props.actState(0)}
                            >{t('validate_sms.sign_in')}</p>
                            {/* <p style={{color: '#26CAAC', cursor: 'pointer'}}
                                onClick={()=>props.actState(1)}
                            >{t('validate_sms.sign_up')}</p> */}
                        </div>
                    </form>
                </div>
            </div>

  </>);
}
export default  connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        validateSMS: (data)=>dispatch(validateSMS(data)),
        validateEnd: ()=>dispatch({type: 'VALIDATE_SMS', payload: { status: 'end'} }),
        signUp: (data)=>dispatch(signUp(data)),
    })
)(ValidateBotSMS); 
