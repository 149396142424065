import React from 'react';
import { useTranslation } from 'react-i18next';

const PriceFormat = (props) => {
    const { t } = useTranslation();
    const {data} = props;
    const price = data.price?parseFloat(data.price):0 
    return (<>
        {price>0?price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.0','')+t('one_page.sum'):t('one_page.free')}
      </>);
}
export default PriceFormat;