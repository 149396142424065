import { isObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import ReactJSPagination from './sections/ReactJSPagination';
const SurveysResultItem = props => {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [totalItemsCount, setTotalItemsCount] = useState(1);
	const id = props.match.params.id ? props.match.params.id : 1;
	const type = props.match.params.type ? props.match.params.type : 1;
	const surveyId = props.match.params.surveyId
		? props.match.params.surveyId
		: 1;
	const [surveyResult, setSurveyResult] = useState([]);
	const getSurvey = async (page = 1) => {
		setLoading(true);
		setPage(page);
		getInstance()
			.get(
				`/api/v1/survey/get-survey-result-detail?survey_id=${id}&page=${page}&question_id=${surveyId}&type=${type}`
			)
			.then(response => {
				const total = response?.data?.survey_result?.total;
				const totalItemsCount = Math.ceil(total / 15);
				setTotalItemsCount(totalItemsCount);
				const result = response?.data?.survey_result?.data;
				const results = Array.isArray(result) ? result : [];
				setSurveyResult(results);
			})
			.catch(() => setTotalItemsCount(1))
			.finally(() => setLoading(false));
	};
	useEffect(() => {
		getSurvey();
	}, [id, surveyId, type]);
	return (
		<Layout>
			<CheckRole roles={[roles.manager, roles.teacher]} />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active="Javoblar"
										navs={[
											{
												title: t('survey.survey'),
												link: '/manager/surveys',
											},
											{
												title: t('survey_r.title'),
												link: `/manager/surveys/result/${id}`,
											},
										]}
									/>
									<div className="dashboard-title dashboard-title--has-buttons">
										<h5>Javoblar</h5>
									</div>
									<div className="card card--shadow">
										<div className="card-body p-3">
											<div className="scroll scroll--horizontall">
												<table className="table table-bordered table--style-1 manager-teachers-table">
													<thead>
														<tr>
															<th>
																<div className="cell-inner">
																	№
																</div>
															</th>
															<th>
																<div className="cell-inner">
																	Familiya
																</div>
															</th>
															<th>
																<div className="cell-inner">
																	Ism
																</div>
															</th>
															<th>
																<div className="cell-inner">
																	Javobi
																</div>
															</th>
														</tr>
													</thead>
													{loading ? (
														<Loading />
													) : surveyResult?.length ? (
														surveyResult?.map((survey, index) => {
															const answer = JSON.parse(
																survey?.result
															)?.find(({ type, id }) => {
																if (
																	(type === 'input' ||
																		type === 'radiogroup' ||
																		type ===
																			'checkboxgroup') &&
																	id === parseInt(surveyId)
																) {
																	return true;
																} else {
																	return false;
																}
															});
															const value =
																answer?.type === 'input'
																	? answer?.value
																	: answer?.type ===
																	  'radiogroup'
																	? answer?.value?.write
																	: answer?.type ===
																	  'checkboxgroup'
																	? answer?.value?.find(a =>
																			isObject(a)
																	  )?.write
																	: null;
															return (
																<tr key={index}>
																	<td>
																		<div className="cell-inner">
																			{index +
																				1 +
																				(page - 1) * 15}
																		</div>
																	</td>
																	<td>
																		<div className="cell-inner">
																			{
																				survey?.user
																					?.lastname
																			}
																		</div>
																	</td>
																	<td>
																		<div className="cell-inner">
																			{
																				survey?.user
																					?.firstname
																			}
																		</div>
																	</td>
																	<td>
																		<div className="cell-inner">
																			{value}
																		</div>
																	</td>
																</tr>
															);
														})
													) : (
														<NoData />
													)}
												</table>
											</div>
										</div>
									</div>
									{loading ? null : (
										<div>
											<ReactJSPagination
												activePage={page}
												hideFirstLastPages={true}
												onChange={getSurvey}
												totalItemsCount={totalItemsCount}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default SurveysResultItem;
