import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from './ScrollToTopOnMount';
const Navigation = props => {
	const { t, i18n } = useTranslation();
	const navs = Array.isArray(props.navs) ? props.navs : [];
	return (
		<>
			<div
				className={
					props.is_dash ? 'breadcrumbs breadcrumbs--dashboard' : 'breadcrumbs'
				}>
				{props.is_btn ? (
					<button type="button" className="btn btn-light left-sidebar-menu-btn">
						<span className="icon icon-list"></span>
					</button>
				) : null}
				<ul>
					<li>
						<Link to="/">{t('navigation.home')}</Link>
					</li>
					{navs.map((nav, index) => (
						<li key={index}>
							<Link to={nav.link}>{nav.title}</Link>
						</li>
					))}
					{props.active ? <li>{props.active}</li> : null}
				</ul>
			</div>
			<ScrollToTopOnMount />
		</>
	);
};
export default Navigation;
