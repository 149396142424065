import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const BooleanLogic = props => {
	const { t } = useTranslation();
	const { survey, logic_item, setLogicList } = props;
	const changeLogic = (id, name, value) => {
		let l = [];
		survey.logic.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, choice: { [name]: value } });
			} else {
				l.push(item);
			}
		});
		setLogicList(l);
	};
	return (
		<>
			<label for="">{t('survey_c.select-answer')}</label>
			<select
				className="form-control"
				name="inputType"
				style={{
					background: 'white',
					width: '100%',
				}}
				value={_.get(logic_item, 'choice.item_id', 0)}
				onChange={e => {
					changeLogic(logic_item.id, 'item_id', e.target.value);
				}}>
				<option selected value={''}>
					{t('survey_c.off_or_on')}
				</option>
				<option value="off">{t('survey_c.off')}</option>
				<option value="on">{t('survey_c.on')}</option>
			</select>
		</>
	);
};
export default BooleanLogic;
