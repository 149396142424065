import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BooleanLogic from './BooleanLogic';
import ChoiceLogic from './ChoiceLogic';
import MatrixLogic from './MatrixLogic';
import MultipleInputLogic from './MultipleInputLogic';
import RatingLogic from './RatingLogic';
import SorttableLogic from './SorttableLogic';

const Logic = props => {
	const { t } = useTranslation();
	const { survey, surveys, setLogicList } = props;
	const addLogic = () => {
		const last_id = survey.logic.length > 0 ? survey.logic[survey.logic.length - 1].id : 0;
		setLogicList([...survey.logic, { id: last_id + 1, survey: 0, survey_type: '', choice: {} }]);
	};
	const deleteLogic = id => {
		let l = [];
		survey.logic.forEach(item => {
			if (item.id !== id) {
				l.push(item);
			}
		});
		setLogicList(l);
	};
	const changeLogicSurvey = (id, value) => {
		let l = [];
		survey.logic.forEach(item => {
			if (item.id === id) {
				let s_type = '';
				let choice = {};
				surveys.forEach(s => {
					if (s.id === value) {
						s_type = s.type;
						if (s_type === 'sorttable') choice = { item_id: _.get(s, 'choices[0].id', 0), sort_id: 1 };
						if (s_type === 'multiple_input') choice = { item_id: _.get(s, 'items[0].id', 0) };
						if (s_type === 'matrix_radio' || 'matrix_checkbox')
							choice = { item_id: _.get(s, 'rows[0].id', 0), col_id: _.get(s, 'cols[0].id', 0) };
					}
				});
				l.push({ ...item, survey: value, survey_type: s_type, choice: choice });
			} else {
				l.push(item);
			}
		});
		setLogicList(l);
	};
	const logic_survey = id => {
		let k = {};
		surveys.forEach(item => {
			if (item.id === id) {
				k = item;
			}
		});
		return k;
	};
	return (
		<>
			{surveys[0].id !== survey.id ? (
				<>
					<p style={{ fontWeight: 500, fontSize: 16 }}>{t('survey_c.logic')}</p>
					{survey.logic.map((logic_item, l_index) => (
						<>
							<div
								className="form-group p-5"
								style={{ background: 'rgba(137, 141, 166, 0.08)', borderRadius: 8 }}
								key={l_index}>
								<label for="">
									<h6 style={{ display: 'inline' }}>
										{l_index + 1}-{t('survey_c.logic')}
									</h6>
								</label>
								<label for="">{t('survey_c.select-question')}</label>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<select
										className="form-control"
										name="inputType"
										value={logic_item.survey}
										style={{
											background: 'white',
											width: 'calc(100% - 50px',
										}}
										onChange={e => changeLogicSurvey(logic_item.id, parseInt(e.target.value))}>
										<option selected value={0}>
											{' '}
											{t('survey_c.select_c')}
										</option>
										{surveys.map((item, index) => (
											<>
												{item.id < survey.id ? (
													<option value="text" key={index} value={item.id}>
														{index + 1}-{t('survey_c.question')} | {t('survey_c.id')}:{' '}
														{item.id} | {item.name_uz} | {item.name_ru}
													</option>
												) : null}
											</>
										))}
									</select>
									<span
										className="button"
										style={{
											color: '#f00',
											height: 40,
											width: 40,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: 8,
											background: 'white',
											cursor: 'pointer',
											float: 'right',
										}}
										onClick={() => deleteLogic(logic_item.id)}>
										<span className="icon icon-remove"></span>
									</span>
								</div>
								{['radiogroup', 'checkboxgroup', 'select'].includes(logic_item.survey_type) ? (
									<ChoiceLogic
										logic_item={logic_item}
										survey={survey}
										logic_survey={logic_survey(logic_item.survey)}
										setLogicList={logic => setLogicList(logic)}
									/>
								) : logic_item.survey_type === 'rating' ? (
									<RatingLogic
										logic_item={logic_item}
										survey={survey}
										logic_survey={logic_survey(logic_item.survey)}
										setLogicList={logic => setLogicList(logic)}
									/>
								) : logic_item.survey_type === 'boolean' ? (
									<BooleanLogic
										logic_item={logic_item}
										survey={survey}
										logic_survey={logic_survey(logic_item.survey)}
										setLogicList={logic => setLogicList(logic)}
									/>
								) : logic_item.survey_type === 'sorttable' ? (
									<SorttableLogic
										logic_item={logic_item}
										survey={survey}
										logic_survey={logic_survey(logic_item.survey)}
										setLogicList={logic => setLogicList(logic)}
									/>
								) : logic_item.survey_type === 'multiple_input' ? (
									<MultipleInputLogic
										logic_item={logic_item}
										survey={survey}
										logic_survey={logic_survey(logic_item.survey)}
										setLogicList={logic => setLogicList(logic)}
									/>
								) : ['matrix_radio', 'matrix_checkbox'].includes(logic_item.survey_type) ? (
									<MatrixLogic
										logic_item={logic_item}
										survey={survey}
										logic_survey={logic_survey(logic_item.survey)}
										setLogicList={logic => setLogicList(logic)}
									/>
								) : null}
							</div>
						</>
					))}
					<div>
						<button onClick={addLogic} className="btn btn-sm mb-2" style={{ background: 'transparent' }}>
							<span className="icon icon-plus"></span>
							{t('survey_c.add_logic')}
						</button>
					</div>
				</>
			) : null}
		</>
	);
};
export default Logic;
