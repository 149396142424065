import React, {useState, useEffect} from 'react';
import ReactPhoneInput from "react-phone-input-2";
import InputMask from 'react-input-mask';

const PhoneInput = ({ value='', name='phone', inputClass='', disabledInput=false,  
                        setPhone=()=>{}, onClickInput=()=>{} }) => {
    
    const [phoneI, setPhoneI] = useState('');
    useEffect(()=>{ setPhoneI(value); },[]);
    const handleOnBlur = event =>{
        const i = event.target.value;
        const p = i.slice(1,4)
        +i.slice(6,8)
        +i.slice(10,13)
        +i.slice(14,16)
        +i.slice(17,19);
        console.log(p);
        setPhone(p);
    }
    return(
        <InputMask  
            mask='+998 (nn) nnn-nn-nn' 
            alwaysShowMask={true} 
            className={inputClass} 
            value={phoneI} 
            name={name}
            disabled={disabledInput}
            onChange={e=>{setPhoneI(e.target.value)}}
            onClick={()=>onClickInput()}
            onBlur={handleOnBlur}
            formatChars={{
                'n': '[0-9]',
                // 'a': '[A-Za-z]',
                // '*': '[+]'
            }}
        />
    );
}
export default PhoneInput

const PhoneInput2 = (props) => {
    const [phone, setPhone] = useState('');
    const [h, setH] = useState(1);
    useEffect(()=>{
        setPhone(props.value?props.value:'');
    },[]);
    const handleOnChange = (value) => {
        if( value.length >= 4 ){
            setPhone(value);
        }else{
            if(h==1){
                setPhone('+998');
                setH(2);
            }else{
                setPhone('998');
                setH(1);    
            }
        }
    };
    const handleOnClick = event => {
        props.onClickInput();
        if( event.target.value.length < 4 ){
            setPhone('998');
        }
    };
    const handleOnBlur = event =>{
        const i = event.target.value;
        const p = i.slice(1,4)
        +i.slice(6,8)
        +i.slice(10,13)
        +i.slice(14,16)
        +i.slice(17,19);
        props.setPhone(p);
    }
    return (<>
        <ReactPhoneInput
            inputExtraProps={{
                name: "phone",
                required: true,
                autoFocus: true
            }}
            country={"uz"}
            onlyCountries={["uz"]}
            onChange={handleOnChange}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            masks={{uz: '+... (..) ...-..-..' }}
            placeholder={'+998 (__) ___-__-__'}
            value={phone}
            areaCodes={{uz:['998']}}
            autocomplete="off"
            name="phone"
            disabled={props.disabledInput?props.disabledInput:false}
            inputClass={props.inputClass?props.inputClass:''}
        />
      </>);
}
// export default PhoneInput;