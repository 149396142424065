import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import CourseReview from './sections/CourseReview';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';

const ManagerCoursesReview = props => {
	const { slug, id } = props.match.params;
	const { t } = useTranslation();
	const { profile } = useSelector(state => state);
	const [loading, setLoading] = useState(true);
	const [surveys, setSurveys] = useState([]);
	useEffect(() => {
		getCourseSurveyList();
	}, []);
	const getCourseSurveyList = () => {
		setLoading(true);
		getInstance()
			.get('/api/v1/survey-course?course_id=' + id)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						const surveys = Array.isArray(data) ? data : [];
						setSurveys(surveys);
						setLoading(false);
					} catch {
						setLoading(false);
						setSurveys([]);
					}
				} else {
					setSurveys([]);
				}
			})
			.catch(() => {
				setLoading(false);
				setSurveys([]);
			});
	};
	return (
		<Layout>
			<CheckRole roles={[roles.manager, roles.vazirlik]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('m_review.title')}
										navs={[
											{
												link: '/manager/courses/1',
												title: t('m_courses.title'),
											},
										]}
									/>
									{profile.role_id === 9 ? (
										<p className="pb-2">
											<Link
												className="btn btn-primary btn-sm"
												to={`/manager/courses/review/${slug}/${id}/videos`}>
												Kurs videolari
											</Link>
										</p>
									) : null}
									<h4 className="pb-2">{t('m_review.surveys')}</h4>
									{loading ? (
										<Loading />
									) : surveys.length ? (
										surveys.map((item, index) => (
											<div className="card card--shadow" key={index}>
												<div className="card-body">
													<div
														style={{ display: 'flex', alignItems: 'center' }}>
														<h5 className="mr-2">{item.name}</h5>
														<Link
															to={`/manager/courses/review/${slug}/${id}/survey/${_.get(
																item,
																'lessons[0].survey.id',
																0,
															)}`}
															className="btn btn-primary btn-sm"
															style={{ marginLeft: 'auto' }}>
															{t('m_review.result')}
														</Link>
													</div>
												</div>
											</div>
										))
									) : (
										<div className="card card--shadow">
											<div className="card-body">
												<NoData text={t('m_review.no_data')} />
											</div>
										</div>
									)}
									<h4 className="pb-2">{t('m_review.review')}</h4>
									<CourseReview slug={slug} is_add_block={true} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default ManagerCoursesReview;
