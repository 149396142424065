import React from 'react';
import PriceFormat from "./PriceFormat";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CourseFooterDefault = (props) => {
    const { t } = useTranslation();
    const {course} = props;
    return (<>
        <div className="price-btn">
            <strong className="__price">
                <PriceFormat data={course}/> | { course.hours } {t('create_course.credit')}
                 {/* { parseInt(course.hours/60) } {t('one_page.h_')} { parseInt(course.hours)%60 } {t('one_page.m_')} */}
            </strong>
            <Link to={ (course.active_buys_count>0?'/dashboard':'')+'/courses/view/'+course.slug} className="btn btn-primary btn-sm">{t('one_page.choose')}</Link>
        </div>
      </>);
}
export default CourseFooterDefault;