import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUpgredeCategorys } from '../actions';
import { FILE_URL, IMG_URL } from '../helpers/api';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import history from '../history';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import RecommendedCourses from './sections/RecommendedCourses';
import ResultPupil from './sections/ResultPupil';

const DashQualificationPage = props => {
	const { upgrade_categories, upgrade_categories_status, profile } = props;
	const user_hours = upgrade_categories.user_hours
		? upgrade_categories.user_hours
		: 0;
	const total_percent = parseInt(
		(user_hours / upgrade_categories.total_hours) * 100
	);
	const [loading, setLoading] = useState(true);
	const [issetOffline, setIssetOffline] = useState(false);
	const [diagnostics, setDiagnostics] = useState({});
	const [chartLoading, setChartLoading] = useState(true);
	const [certificate, setCertificate] = useState({});
	const [certificateErr, setCertificateErr] = useState('');
	const [first, setFirst] = useState(false);
	const [multiP, setMultiP] = useState({});
	const { t } = useTranslation();
	useEffect(() => {
		if (profile.id && profile.is_teacher && !profile.language_id) {
			history.push('/dashboard/qualification/choice-lan');
		}
	}, [profile]);
	useEffect(() => {
		document.title = t('qualification.title');
		getInstance()
			.get(`/api/v1/courses/get-certificate`)
			.then(response => {
				setCertificate(response?.data?.data ? response.data.data : {});
				setIssetOffline(response?.data?.isset_offline);
			})
			.catch(error => {
				console.log(error);
			});
		getInstance()
			.get('/api/v1/course/diagnostics/result')
			.then(response => {
				if (response.status === 200) {
					const data = _.get(response, 'data.data', []);
					if (!data || data.length === 0) {
						history.push('/dashboard/qualification/choice-lan');
					} else {
						setDiagnostics(response.data);
						setChartLoading(false);
					}
				}
			})
			.catch(error => {
				setChartLoading(false);
				// console.log(error);
				// history.push('/dashboard/qualification/choice-lan');
			});

		props.getUpgredeCategorys();
	}, []);
	useEffect(() => {
		if (upgrade_categories_status.status === 'started') {
			setLoading(true);
		}
		if (upgrade_categories_status.status === 'success') {
			setLoading(false);
		}
		if (upgrade_categories_status.status === 'error') {
			setLoading(false);
		}
	}, [upgrade_categories_status]);

	const setCertificateUser = () => {
		getInstance()
			.post(`/api/v1/courses/set_certificate`, { id: certificate.id })
			.then(response => {
				if (response?.data?.status === 1) {
					setCertificate(
						response.data.data ? response.data.data : certificate
					);
					setFirst(true);
				} else if (response?.data?.status === -1) {
					setCertificateErr(response?.data?.msg);
					window.$('#modelSertifikatErr').modal('show');
				}
			})
			.catch(error => {
				console.log(error);
			});
	};
	const changeUserPosition = () => {
		setMultiP({ ...multiP, loading: true });
		if (multiP.value) {
			getInstance()
				.post(`/api/v1/profil/update/position`, {
					position_id: multiP.value,
				})
				.then(response => {
					history.push('/dashboard/profile');
					setMultiP({ ...multiP, loading: false });
				})
				.catch(error => {
					setMultiP({ ...multiP, loading: false, err: true });
				});
		} else {
			setMultiP({ ...multiP, loading: false, err: true });
		}
	};
	return (
		<>
			<Layout>
				<CheckRole roles={[roles.user, roles.teacher]} />
				<main className="main">
					<section className="section section-dashboard py-0">
						<div className="container">
							<div className="row">
								<DashSideBar />
								<div className="col-md-12 col-lg-9 col-xl-10">
									<div className="dashboard-right">
										<Navigation
											is_dash={true}
											is_btn={true}
											active={t('qualification.title')}
											navs={[
												{
													title: t('navigation.account'),
													link: '/dashboard',
												},
											]}
										/>
										<div className="dashboard-title">
											<h5>{t('qualification.title')}</h5>
										</div>
										{certificate.is_view ? (
											<div className="row">
												<div className="col-12 col-lg-6">
													<div className="card card--shadow ">
														<div className="card-body">
															{first ? (
																<p>
																	{t('qualification.text1')}
																</p>
															) : null}
															<p>
																{t('qualification.text2')}{' '}
																{new Date().getFullYear()}
																{t('qualification.text3')}
															</p>
															<p>
																{t('qualification.text4')}{' '}
																{new Date().getFullYear() + 1}
																{t('qualification.text5')}
															</p>
														</div>
													</div>
													<div className="card card--shadow  mb-3">
														<div className="card-body download-certificate">
															{t('qualification.download_text')}
															<a
																href={
																	FILE_URL + certificate.pdf
																}
																target="_blank"
																className="btn btn-primary btn-sm"
															>
																<span className="icon icon-certificate mr-1"></span>
																{t('qualification.download')}
															</a>
														</div>
													</div>
													{/* <div className="card card--shadow  mb-3">
														<div className="card-body download-certificate">
															{t(
																'qualification.change_position'
															)}
															<br />
															<button
																className="btn btn-primary btn-sm"
																data-toggle="modal"
																data-target="#modelMultiPostion"
															>
																<span className="icon icon-qualification mr-1"></span>
																{t(
																	'qualification.change_position_btn'
																)}
															</button>
														</div>
													</div> */}
												</div>
												<div className="col-12 col-lg-6">
													<div className="card card--shadow qualification-progress">
														<div className="card-body">
															<img
																src={
																	IMG_URL + certificate.image
																}
																alt="certificate"
																className="w-100"
																onError={e => {
																	e.target.onerror = null;
																	e.target.src =
																		'/images/no-certificate.png';
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										) : (
											<div className="row">
												{loading ? (
													<Loading />
												) : (
													<>
														<div className="col-12">
															{chartLoading ? (
																<div className="card card--shadow">
																	<div className="card-body">
																		{' '}
																		<Loading />{' '}
																	</div>
																</div>
															) : diagnostics.data &&
															  diagnostics.data.length > 0 ? (
																<>
																	<ResultPupil
																		data={diagnostics.data}
																	/>
																</>
															) : null}
															<div className="card card--shadow qualification-progress">
																<div className="card-body">
																	<div className="row">
																		<div className="col  col-12 col-sm mb-1">
																			<div className="d-md-flex justify-content-between text-secondary mb-2">
																				<div className="pr-md-6">
																					{/*{t('qualification.completed')}{' '}
																				{parseInt(
																					upgrade_categories.user_hours / 60,
																				)}{' '}
																				{t('qualification.hours')}{' '}
																				{parseInt(upgrade_categories.user_hours) %
																					60}{' '}
																				{t('qualification.minutes')} (
																				{total_percent}%) */}
																					{t(
																						'qualification.completed'
																					)}{' '}
																					{
																						upgrade_categories.user_hours
																					}{' '}
																					{t(
																						'create_course.credit'
																					)}{' '}
																					({total_percent}
																					%)
																				</div>
																				{total_percent >=
																				100 ? null : (
																					<div>
																						{/* {t('qualification.all')}{' '}
																					{parseInt(
																						upgrade_categories.total_hours / 60,
																					)}{' '}
																					{t('qualification.hours')}{' '}
																					{parseInt(
																						upgrade_categories.total_hours,
																					) % 60}{' '}
																					{t('qualification.minutes')} */}
																						{t(
																							'qualification.all'
																						)}{' '}
																						{parseInt(
																							upgrade_categories.total_hours
																						)}{' '}
																						{t(
																							'create_course.credit'
																						)} 
																					</div>
																				)}
																			</div>
																			<div className="progress">
																				<div
																					className="progress-bar"
																					role="progressbar"
																					style={{
																						width:
																							total_percent +
																							'%',
																					}}
																					aria-valuenow={
																						total_percent
																					}
																					aria-valuemin="0"
																					aria-valuemax="100"
																				></div>
																			</div>
																		</div>
																		{issetOffline ? (
																			<div className="col col-12  col-sm">
																				<Link
																					className="btn btn-primary btn-sm"
																					to ="/dashboard/offline-edu"
																				>
																					<span className="icon icon-paper  mr-1"></span>
																					{t(
																						'target_course.view'
																					)}
																				</Link>
																			</div>
																		) : total_percent >= 100 ? (
																			<div className="col col-12  col-sm">
																				<button
																					className="btn btn-primary btn-sm"
																					onClick={() =>
																						setCertificateUser()
																					}
																				>
																					<span className="icon icon-paper  mr-1"></span>
																					{t(
																						'qualification.get_certificate'
																					)}
																				</button>
																			</div>
																		) : (
																			''
																		)}
																	</div>
																</div>
															</div>
														</div>
														{upgrade_categories.data &&
														upgrade_categories.data.length > 0 ? (
															upgrade_categories.data.map(
																(cat, index) => {
																	const user_hours_count =
																		cat.user_hours_count
																			? cat.user_hours_count
																			: 0;
																	const percent = parseInt(
																		(user_hours_count /
																			cat.hours) *
																			100
																	);
																	const circle_percent =
																		parseInt(
																			(195 *
																				(100 -
																					(percent > 100
																						? 100
																						: percent
																						? percent
																						: 0))) /
																				100
																		);
																	return (
																		<div
																			className="col-md-6 col-xl-4"
																			key={index}
																		>
																			<div className="card card--shadow qualification-type">
																				<div className="card-body p-3">
																					<div className="d-flex align-items-center">
																						<div className="circle-progress-bar">
																							<div className="text">
																								{percent
																									? percent
																									: 0}
																								%
																							</div>
																							<svg className="progress-circle">
																								<circle
																									className="bg"
																									cx="34"
																									cy="34"
																									r="31"
																								></circle>
																								<circle
																									className="progress"
																									cx="34"
																									cy="34"
																									r="31"
																									data-percent={
																										percent
																									}
																									style={{
																										strokeDashoffset:
																											circle_percent,
																									}}
																								></circle>
																							</svg>
																						</div>
																						<div className="qualification-type__caption">
																							<Link
																								to={
																									'/dashboard/qualification/' +
																									cat.slug +
																									'/1'
																								}
																							>
																								{
																									cat.name
																								}
																							</Link>
																							<p>
																								{/* {parseInt(cat.hours / 60)}{' '}
																						{t('qualification.hours')}{' '} */}
																								{t(
																									'qualification.item_text'
																								)}
																								{cat.hours
																									? cat.hours
																									: 0}
																								{t(
																									'qualification.credit'
																								)}
																							</p>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	);
																}
															)
														) : (
															<NoData
																text={t(
																	'qualification.no_data'
																)}
															/>
														)}
														<div className="col-12">
															{diagnostics.data &&
															diagnostics.data.length > 0 ? (
																<RecommendedCourses
																	data={diagnostics.data}
																/>
															) : null}
														</div>
													</>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</Layout>
			<div
				className="modal fade"
				id="modelSertifikatErr"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modelSertifikatErrTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">
								{t('statistics.user_certificat')}
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">
							<p>{certificateErr}</p>
							<button
								className="btn btn-primary w-100"
								data-dismiss="modal"
								aria-label="Close"
							>
								{t('test_result.understandable')}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modelMultiPostion"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modelMultiPostionTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">
								{t('qualification.change_position_btn')}
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group mb-3">
								<label for="">{t('m_pupils.position_type')}</label>
								<select
									name="position"
									className="form-control custom-select"
									onChange={e => setMultiP({ value: e.target.value })}
								>
									<option selected value="">
										{' '}
										{t('survey.select')}{' '}
									</option>
									{_.get(profile, 'position_users', []).map(
										({ position }, index) => (
											<option
												value={position?.id}
												selected={
													multiP === position?.id ? 'selected' : ''
												}
												key={index}
											>
												{position?.name}
											</option>
										)
									)}
								</select>
								{multiP.err ? (
									<div style={{ color: 'red' }}>
										{t('profile.position_type_text')}
									</div>
								) : (
									''
								)}
							</div>
							{multiP.loading ? (
								<Loading />
							) : (
								<button
									className="btn btn-primary w-100"
									onClick={changeUserPosition}
								>
									{t('profile.save')}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getUpgredeCategorys: () => dispatch(getUpgredeCategorys()),
	})
)(DashQualificationPage);
