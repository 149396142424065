import React, {useEffect} from 'react';
import JoditTextShow from "./JoditTextShow";
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import  "jquery-ui-touch-punch";
import _ from 'lodash';

window.jQuery = $;

const LessonTestWordDrags = (props) => {
    const { question, number } = props;
    useEffect(()=>{
        $(function() {
            $( '#test_'+question.id+' .dragdrop'+number ).draggable({
                revert: "invalid", 
                containment: "document",
                helper: "clone",
                cursor: "move",
                scroll: true
            });
           $("#test_"+question.id+" .test.test--singleoption .drop").droppable({
                accept: ".dragdrop"+number,
                drop: function( event, ui ) {
                    ui.draggable.closest( '.drop' ).find( 'input' ).val( '' );
                    $(this).removeClass( 'wrong' ).find( 'input' ).val( ui.draggable.data('id') );
                    $(this).find( '.propval' ).find( '.dragdrop'+number )
                        .appendTo( $(this).closest( '.test__body' ).find( '.fill_drop__drag' ).find( '.answers' ) );
                    ui.draggable.appendTo( $(this).find( '.propval' ).empty() );
                    if( $(this).hasClass( "answers" ) ){
                        ui.draggable.appendTo( $(this) );
                    }
                }
            });
        });
    }, []);
    const formatDrag = () => {
        let word = 1, w_list = [], details = _.get(question, 'details', ''), is_has = true;
        while(is_has){
            const i = details.indexOf("[word"+word+"]");
            if(i>=0){
                w_list.push( details.slice(0, i) );
                w_list.push( "[word"+word+"]" );
                details = details.slice( i+("[word"+word+"]").length );
                word++;
            }else{
                is_has=false;
                w_list.push( details );
            }
        }
        return (<>{w_list.map((item, index)=>(<>{
            item.substring(0,5)==="[word"?
            <div className="test-drag-drop drop container_word1  ui-droppable">
                <input type="hidden" name={"answers["+question.id+"]"+item} value=""/>
                <div className="propval"></div>
            </div>: item
        }</>))}</>);
    }
    return (
        <div className="test__item card" id={"test_"+question.id}>
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ '<b>'+(number+1)+'.</b> '+question.question } />
                </div>
                <div className="test test--singleoption">
                    <div className="test__body">
                        <div className="ttype--fill_drop">
                            { formatDrag() }
                        </div>
                        <div className="fill_drop__drag">
                            <div className={"test-drag-drop drop answers container_"+question.id+" ui-droppable"}>
                                {_.get(question, 'vanswers', []).map((item, index)=>(<>
                                    {item.answer!==''?
                                    <strong key={index} 
                                        className={"dragdrop"+number+" ui-draggable ui-draggable-handle"} 
                                        data-id={item.id}>{item.answer}
                                    </strong>:''}
                                </>))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LessonTestWordDrags;
