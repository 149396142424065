import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import { getRetrainingCategorys } from '../actions';
import { connect } from 'react-redux';
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ResultPupil from './sections/ResultPupil';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const DashRetrainingPage = props => {
	const { retraining_categories, retraining_categories_status } = props;
	const user_hours = props.user_hours ? props.user_hours : 0;
	const total_percent = (user_hours / props.retraining_categories.total_hours) * 100;
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		props.getRetrainingCategorys();
	}, []);
	useEffect(() => {
		if (retraining_categories_status.status === 'started') {
			setLoading(true);
		}
		if (retraining_categories_status.status === 'success') {
			setLoading(false);
		}
		if (retraining_categories_status.status === 'error') {
			setLoading(false);
		}
	}, [retraining_categories_status]);

	return (
		<Layout>
		<CheckRole roles={ [ roles.user, roles.teacher ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('retraining.title')}
										navs={[
											{
												title: t('navigation.account'),
												link: '/dashboard',
											},
										]}
									/>
									<div className="dashboard-title">
										<h5>{t('retraining.title')}</h5>
									</div>
									<div className="row">
										{loading ? (
											<Loading />
										) : (
											<>
												<div className="col-12">
													{retraining_categories.data &&
													retraining_categories.data.length > 0 ? (
														<ResultPupil data={retraining_categories.data} />
													) : null}
													<div className="card card--shadow qualification-progress">
														<div className="card-body">
															<div className="row">
																<div className="col  col-12 col-sm mb-1">
																	<div className="d-md-flex justify-content-between text-secondary mb-2">
																		<div className="pr-md-6">
																			{t('retraining.completed')}{' '}
																			{parseInt(user_hours)}{' '}
																			{t('create_course.credit')} (
																			{total_percent ? total_percent : '0'}%)
																		</div>
																		{total_percent >= 100 ? null : (
																			<div>
																				{parseInt(
																					retraining_categories.total_hours
																						? retraining_categories.total_hours
																						: '0',
																				)}{' '}
																				{t('create_course.credit')}{' '}
																			</div>
																		)}
																	</div>
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{ width: total_percent + '%' }}
																			aria-valuenow={total_percent}
																			aria-valuemin="0"
																			aria-valuemax="100"></div>
																	</div>
																</div>
																{total_percent >= 100 ? (
																	<div className="col col-12  col-sm">
																		<button className="btn btn-primary btn-sm">
																			<span className="icon icon-user"></span>
																			{t('retraining.get_certificate')}
																		</button>
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												{retraining_categories.data && retraining_categories.data.length > 0 ? (
													retraining_categories.data.map((cat, index) => {
														const user_hours_count = cat.user_hours_count
															? cat.user_hours_count
															: 0;
														const percent = (user_hours_count / cat.hours) * 100;
														const circle_percent =
															(195 *
																(100 - (percent > 100 ? 100 : percent ? percent : 0))) /
															100;
														return (
															<div className="col-md-6 col-xl-4" key={index}>
																<div className="card card--shadow qualification-type">
																	<div className="card-body p-3">
																		<div className="d-flex align-items-center">
																			<div className="circle-progress-bar">
																				<div className="text">
																					{percent ? percent : 0}%
																				</div>
																				<svg className="progress-circle">
																					<circle
																						className="bg"
																						cx="34"
																						cy="34"
																						r="31"></circle>
																					<circle
																						className="progress"
																						cx="34"
																						cy="34"
																						r="31"
																						data-percent={percent}
																						style={{
																							strokeDashoffset: circle_percent,
																						}}></circle>
																				</svg>
																			</div>
																			<div className="qualification-type__caption">
																				<Link
																					to={
																						'/dashboard/retraining/' +
																						cat.slug +
																						'/1'
																					}>
																					{cat.name}
																				</Link>

																				<p>
																					{cat.user_hours_count
																						? parseInt(cat.user_hours_count)
																						: 0}{' '}
																					{t('qualification.item_text')}
																					{/* { parseInt(cat.hours/60) } {t('retraining.hours')} { parseInt(cat.hours)%60 }  {t('retraining.item_text')} */}
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														);
													})
												) : (
													<NoData text={t('retraining.no_data')} />
												)}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getRetrainingCategorys: () => dispatch(getRetrainingCategorys()),
	}),
)(DashRetrainingPage);
