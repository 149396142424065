import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import Pagination from './sections/Pagination';

const Surveys = props => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [surveys, setSurveys] = useState({});
	const page = props.match.params.page ? props.match.params.page : 1;

	useEffect(() => {
		getSurveys();
	}, [page]);

	const getSurveys = () => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/survey/surveys?page=${page}&per_page=20`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setSurveys(data);
						setLoading(false);
					} catch {
						setLoading(false);
						setSurveys({});
					}
				}
			})
			.catch(error => {
				setSurveys({});
				setLoading(false);
			});
	};
	const changeStatus = (id, status) => {
		let l = [];
		_.get(surveys, 'data', []).forEach(item => {
			if (item.id === id) l = [...l, { ...item, status: status === 1 ? 0 : 1 }];
			else l = [...l, item];
		});
		setSurveys({ ...surveys, data: l });

		getInstance()
			.post(`/api/v1/survey/set-status-survey`, { id: id, status: status === 1 ? 0 : 1 })
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						let l = [];
						_.get(surveys, 'data', []).forEach(item => {
							if (item.id === id)
								l = [...l, { ...item, status: _.get(data, 'status', status === 1 ? 1 : 0) }];
							else l = [...l, item];
						});
						setSurveys({ ...surveys, data: l });
					} catch {
						let l = [];
						_.get(surveys, 'data', []).forEach(item => {
							if (item.id === id) l = [...l, { ...item, status: status === 1 ? 1 : 0 }];
							else l = [...l, item];
						});
						setSurveys({ ...surveys, data: l });
					}
				}
			})
			.catch(error => {
				let l = [];
				_.get(surveys, 'data', []).forEach(item => {
					if (item.id === id) l = [...l, { ...item, status: status === 1 ? 1 : 0 }];
					else l = [...l, item];
				});
				setSurveys({ ...surveys, data: l });
			});
	};
	return (
		<Layout>
			<CheckRole roles={[roles.manager, roles.teacher]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right">
									<Navigation is_dash={true} is_btn={true} active={t('survey.survey')} />
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{t('survey.survey')}</h5>
										<div class="buttons butons--target">
											<Link
												to="/manager/surveys/create"
												className="btn btn-primary btn-sm font-weight-medium">
												{t('survey.add')}
											</Link>
										</div>
									</div>

									{loading ? (
										<Loading />
									) : (
										<>
											{_.get(surveys, 'data', []).length ? (
												_.get(surveys, 'data', []).map((survey, index) => (
													<div className="card card--shadow" key={index}>
														<div className="card-body card-body-diagnostics row p-3">
															<div className="col-12" style={{ margin: '0 0 24px 0' }}>
																<h5 style={{ display: 'inline' }}>
																	{index + 1}. {survey.name}
																</h5>
																<Link
																	to={'/manager/surveys/edit/' + survey.id}
																	className="button"
																	style={{
																		color: '#898DA6',
																		margin: 'auto 10px',
																		cursor: 'pointer',
																		width: 32,
																		height: 32,
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		borderRadius: 8,
																		background: 'rgba(137, 141, 166, 0.1)',
																		textDecoration: 'none',
																		float: 'right',
																	}}>
																	<span className="icon icon-edit"></span>
																</Link>
																<Link
																	to={'/manager/surveys/creator/' + survey.id}
																	className="button"
																	style={{
																		color: '#898DA6',
																		margin: 'auto 10px',
																		cursor: 'pointer',
																		width: 32,
																		height: 32,
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		borderRadius: 8,
																		background: 'rgba(137, 141, 166, 0.1)',
																		textDecoration: 'none',
																		float: 'right',
																	}}>
																	<span className="icon icon-question"></span>
																</Link>
																<Link
																	to={'/manager/surveys/result/' + survey.id}
																	className="button"
																	style={{
																		color: '#898DA6',
																		margin: 'auto 10px',
																		cursor: 'pointer',
																		width: 32,
																		height: 32,
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		borderRadius: 8,
																		background: 'rgba(137, 141, 166, 0.1)',
																		textDecoration: 'none',
																		float: 'right',
																	}}>
																	<span className="icon icon-grid"></span>
																</Link>
															</div>
															<div className="col-12 col-md-6">
																<b style={{ fontSize: 14, fontWeight: 500 }}>
																	{t('survey.start_time')}
																</b>
																<div
																	style={{
																		color: '#0D2E69',
																		fontWeight: 500,
																		fontSize: 18,
																	}}>
																	{survey.start_time}
																</div>
															</div>
															<div className="col-12 col-md-6">
																<b style={{ fontSize: 14, fontWeight: 500 }}>
																	{t('survey.end_time')}
																</b>
																<div
																	style={{
																		color: '#0D2E69',
																		fontWeight: 500,
																		fontSize: 18,
																	}}>
																	{survey.end_time}
																</div>
															</div>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	margin: '24px 0 0 0',
																}}
																className="col-12">
																<div
																	style={{
																		color: '#0D2E69',
																		fontWeight: 700,
																	}}>
																	{t('survey.status')}{' '}
																	{_.get(survey, 'status', 0) ? (
																		<span style={{ color: '#26CAAC' }}>
																			{t('survey.active')}
																		</span>
																	) : (
																		<span style={{ color: '#898DA6' }}>
																			{t('survey.passive')}
																		</span>
																	)}
																</div>
																<label className="switch mx-2">
																	<input
																		type="checkbox"
																		checked={_.get(survey, 'status', 0)}
																		onChange={() =>
																			changeStatus(
																				survey.id,
																				_.get(survey, 'status', 0),
																			)
																		}
																	/>
																	<span className="slider round"></span>
																</label>
															</div>
														</div>
													</div>
												))
											) : (
												<NoData text={t('survey.no_data')} />
											)}
											<Pagination
												total={_.get(surveys, 'total', 20)}
												count={20}
												active={parseInt(page)}
												link="/manager/surveys/"
											/>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default Surveys;
