import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { getCoursesPage } from "../actions";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Layout from './Layout';
import Pagination from './sections/Pagination';
import Loading from "./sections/Loading";
import CourseCard from "./sections/CourseCard";
import Navigation from "./sections/Navigation";
import NoData from "./sections/NoData";
import { useTranslation } from 'react-i18next';
import CourseFooterDefault from "./sections/CourseFooterDefault";
const CoursesPage = (props) => {
    const { t } = useTranslation();
    
    const [category, setCategory] = useState('');
    const [hours_max, setMax_hours] = useState('');
    const [hours_min, setMin_hours] = useState('');
    const [lan, setLan] = useState('');
    const [rating, setRating] = useState('');
    const [search, setSearch] = useState('');
    const convertLink = () => {
        let str = '?';
        if(lan){
            str +='lan='+lan+'&';
        }
        if(category){
            str +='category='+category+'&';
        }
        if(hours_min){
            str +='hours_min='+hours_min+'&';
        }
        if(hours_max){
            str +='hours_max='+hours_max+'&';
        }
        if(rating){
            str +='rating='+rating+'&';
        }
        if(search){
            str +='rating='+search+'&';
        }
        return str;
    }
    useEffect(()=>{
		document.title = t('courses.title');
        const search_D = props.location.search; 
        const params = new URLSearchParams(search_D);
        const category_p = params.get('category');
        const hours_max_p = params.get('hours_max'); 
        const hours_min_p = params.get('hours_min'); 
        const lan_p = params.get('lan'); 
        const rating_p = params.get('rating'); 
        const search_p = params.get('search'); 
        setCategory(category_p);
        setMax_hours(hours_max_p);
        setMin_hours(hours_min_p);
        setLan(lan_p);
        setRating(rating_p);
        setSearch(search_p);
        let str = '&';
        if(lan_p){
            str +='language_id='+lan_p+'&';
        }
        if(category_p){
            str +='category_id='+category_p+'&';
        }
        if(hours_min_p){
            str +='hours_min='+hours_min_p+'&';
        }
        if(hours_max_p){
            str +='hours_max='+hours_max_p+'&';
        }
        if(rating_p){
            str +='rating='+rating_p+'&';
        }
        if(search_p){
            str +='search='+search_p+'&';
        }
        props.getCoursesPage( props.match.params.page?props.match.params.page:1, str );
    },[props.match.params.page, props.location.search]);

    const {cat_courses_list, cat_courses_loading, teacher_category} = props;
    
    return (<Layout>
    <main className="main">
        <section className="section section-breadcrumbs">
            <div className="container">
                <Navigation
                    active={t('courses.title')}
                />
            </div>
        </section>
        <section className="section pt-3">
            <div className="container">
                <h3 className="mb-4">{t('courses.title')}</h3>
                <div className="filter">
                    <div className="row row--min align-items-end">
                        <div className="col-md-6 col-lg-3 filter__column">
                            <div className="form-group">
                                <label for="">{t('courses.categorys')}</label>
                                <select id="" className="form-control custom-select"
                                    onChange={event=>setCategory(event.target.value)}
                                    value={category}
                                >
                                    <option value="">{t('courses.all_categorys')}</option>
                                    {teacher_category.data?teacher_category.data.map((cat, index)=>(
                                        <option value={cat.id} key={index}>{ cat.name }</option>
                                    )):null}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 filter__column">
                            <div className="form-group">
                                <label for="">{t('courses.min_h')} </label>
                                <input type="number" className="filter-input" placeholder={t('courses.min_h')}
                                    onChange={event=>setMin_hours(event.target.value)}
                                    value={hours_min}
                               />
                            </div> 
                        </div>
                        <div className="col-md-6 col-lg-2 filter__column">
                            <div className="form-group">
                                <label for="">{t('courses.max_h')}</label>
                                <input type="number" className="filter-input" placeholder={t('courses.max_h')}
                                    onChange={event=>setMax_hours(event.target.value)}
                                    value={hours_max}
                               />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 filter__column">
                            <div className="form-group">
                                <label for="">{t('courses.lan')}</label>
                                <select id="" className="form-control custom-select"
                                    onChange={event=>setLan(event.target.value)}
                                    value={lan}
                               >
                                    <option value=''>{t('courses.all_lan')}</option>
                                    <option value='1'>{t('header.uz')}</option>
                                    <option value='2'>{t('header.ru')}</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-lg-2 filter__column">
                            <div className="form-group">
                                <label for="">{t('courses.rating')}</label>
                                <div className="dropdown filter-course-rating">
                                    <button className="btn btn-white btn-sm w-100 dropdown-toggle" type="button" id="dropdownFilterCourseRate" 
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        style={{fontSize: 14, fontWeight: 500}}
                                    >
                                        {rating?<img src={"/images/ratings/filter-rate-"+rating+".png"} alt="rating"/>:t('courses.all_rating')}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownFilterCourseRate">
                                        <span className="dropdown-item" onClick={()=>setRating('')} >{t('courses.all_rating')}</span>
                                        <span className="dropdown-item" onClick={()=>setRating('1')} ><img src="/images/ratings/filter-rate-1.png" alt="rating-1"/></span>
                                        <span className="dropdown-item" onClick={()=>setRating('2')} ><img src="/images/ratings/filter-rate-2.png" alt="rating-2"/></span>
                                        <span className="dropdown-item" onClick={()=>setRating('3')} ><img src="/images/ratings/filter-rate-3.png" alt="rating-3"/></span>
                                        <span className="dropdown-item" onClick={()=>setRating('4')} ><img src="/images/ratings/filter-rate-4.png" alt="rating-4"/></span>
                                        <span className="dropdown-item" onClick={()=>setRating('5')} ><img src="/images/ratings/filter-rate-5.png" alt="rating-5"/></span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6 col-lg-3 filter__column">
                            <div className="form-group mt-2">
                                <Link to={"/courses/1"+convertLink()}  className="btn btn-primary btn-sm w-100">{t('courses.btn')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {cat_courses_loading?
                    <Loading/>
                :<>
                    {cat_courses_list.data.length>0?<>
                    <div className="row"> 
                        {cat_courses_list.data.map((course,index)=>(
                            <CourseCard
                                avg_rating = {true}
                                key={index}
                                course={course}
                            >
                                <CourseFooterDefault course={course}/>
                            </CourseCard>  
                        ))} 
                    </div>
                    <Pagination
                        total={cat_courses_list.total?cat_courses_list.total:6}
                        active={ parseInt(props.match.params.page) }
                        link="/courses/"
                        search_params={convertLink()}
                    />
                    </>
                    :<div className="row">
                        <div className="col-12">
                            <NoData
                                text={t('courses.no_data')}
                            />
                        </div>
                    </div>
                    }
                </>}
            </div>
        </section>
    </main>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCoursesPage: (page, search) => dispatch(getCoursesPage(page, search))
    })
)(CoursesPage);