import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getInstance } from '../helpers/httpClient';
import Layout from './Layout';
import ExportExcel from './sections/ExportExcel';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';

const WebinarUsersPage = props => {
	const { t } = useTranslation();
	const [isExist, setIsExist] = useState(0);
	const { slug } = props.match.params;
	const history = useHistory();
	const [users, setUsers] = useState([]);
	useEffect(() => {
		getInstance()
			.get(`/api/v1/webinars/${slug}`)
			.then(response => {
				const { status, data } = response;
				const { author_id } = data;
				if (status === 200) {
					getInstance()
						.get('/api/v1/profil/get')
						.then(response => {
							const { status, data } = response.data;
							if (status === 1) {
								const { id } = data;
								if (author_id !== id) {
									history.push('/');
								}
							} else {
								history.push('/');
							}
						})
						.catch(() => history.push('/'));
				} else {
					history.push('/');
				}
			})
			.catch(e => history.push('/'));
		getInstance()
			.get(`/api/v1/course/webinar/users?slug=${props.match.params.slug}`)
			.then(response => {
				const { data, success } = response.data;
				if (success === 1) {
					const { data: allUsers } = data;
					const users = Array.isArray(allUsers) ? allUsers : [];
					setUsers(users);
					setIsExist(1);
				} else {
					setIsExist(2);
				}
			})
			.catch(() => setIsExist(2));
	}, [props.match.params.slug]);
	return (
		<Layout>
			<main className="main">
				<section className="section section-breadcrumbs">
					<div className="container">
						<Navigation
							active={"Vebinarga qo'shilgan foydalanuvchilar"}
							navs={[
								{
									title: t('webinar_view.title'),
									link: '/webinars',
								},
								{
									title: props.match.params.slug,
									link: `/webinars/view/${props.match.params.slug}`,
								},
							]}
						/>
					</div>
				</section>
				<section className="section pt-3">
					{isExist === 0 ? (
						<Loading />
					) : isExist === 1 && users.length ? (
						<>
							<div className="container">
								<h3 className="mb-4">Vebinarga qo'shilgan foydalanuvchilar</h3>
								<p>
									<button
										data-toggle="modal"
										data-target="#export-excel"
										className="btn btn-primary btn-sm font-weight-medium">
										{t('m_pupils.excel_export')}
									</button>
								</p>
							</div>
							<ExportExcel slug={slug} />
							<div className="container">
								<div className="scroll scroll--horizontall">
									<table className="table table-bordered table--style-1 manager-teachers-table">
										<thead>
											<tr>
												<th>
													<div className="cell-inner">T / r</div>
												</th>
												<th>
													<div className="cell-inner">F.I.SH</div>
												</th>
												<th>
													<div className="cell-inner">
														Vebinarga qo'shilgan sana
													</div>
												</th>
												<th>
													<div className="cell-inner">Viloyat</div>
												</th>
												<th>
													<div className="cell-inner">Tuman</div>
												</th>
											</tr>
										</thead>
										<tbody>
											{users.map(({ user, sign_date }, index) => {
												const full_name = user
													? `${user.lastname ? user.lastname : ''} ${
															user.firstname ? user.firstname : ''
													  } ${user.middlename ? user.middlename : ''}`
													: '';
												const region = user
													? user.region
														? user.region.name
														: ''
													: '';
												const city = user
													? user.city
														? user.city.name
														: ''
													: '';
												return (
													<tr>
														<td>
															<div className="cell-inner">{index + 1}</div>
														</td>
														<td>
															<div className="cell-inner">{full_name}</div>
														</td>
														<td>
															<div className="cell-inner">{sign_date}</div>
														</td>
														<td>
															<div className="cell-inner">{region}</div>
														</td>
														<td>
															<div className="cell-inner">{city}</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</>
					) : (
						<NoData text={t('webinar_view.no_data')} />
					)}
				</section>
			</main>
		</Layout>
	);
};
export default WebinarUsersPage;
