import { Link } from "react-router-dom";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getInstance } from "../../helpers/httpClient";
import { useState } from "react";
import Loading from "./Loading";
import CertificateModal from "./CertificateModal";

const LessonExchange = (props) => {
    const course_sections = useSelector(state => state.course_sections);
    const course = useSelector(state => state.course);
    const dispatch = useDispatch();
    const [targetLoading, setTargetLoading] = useState(false)
    const {params, is_dash } = props;
    const {t} = useTranslation();
    const diff_minutes = () => {
        const dt1 = new Date();
        const dt2 = new Date(_.get(course_sections, 'last_section'));
        const diff =(dt2.getTime() - dt1.getTime()) / 1000 / 60;
        return Math.abs(Math.round(diff))<parseInt(_.get(course_sections, 'time', 0));
    }
    const exchangeLink = (n=true) => {
        let  childs=[];
        _.get(course_sections, 'data', []).forEach(item => {
            _.get(item, 'childs', []).forEach(element => { childs.push(element); });
        });
        const i = _.findIndex(childs, (o) => { return o.slug === _.get(params, 'section', '~'); });
        const obj = _.get(childs, n?i+1:i-1, {});
        if(
            _.get(params, 'slug', false)&&
            _.get(obj, 'slug', false)&&
            _.get(obj, 'lessons.0.lesson_type.type', false)&&
            _.get(obj, 'lessons.0.id', false)&&
            _.get(obj, 'has_permission', 0)===1
        )
            return `/${is_dash?'dashboard/':''}courses/view/${_.get(params, 'slug', '')}/${_.get(obj, 'slug', '')}/${_.get(obj, 'lessons.0.lesson_type.type', '')}/${_.get(obj, 'lessons.0.id', '')}`;
        return null;
    }
    const getTargetCertificate = () => {
        setTargetLoading(true);
        getInstance()
			.post('/api/v1/courses/set-certificate-target', {course_id: _.get(course, 'data.id', 0)})
			.then(response => {
                dispatch({
                    type: 'TARGET_SET_CERTIFICATE',
                    payload: _.get(response, 'data.certificat', null)
                })
                setTargetLoading(false);
                window.$('#modalCertificate_1').modal('show');
			})
			.catch(error => setTargetLoading(false) );
    }
    const lastChild = () => {
        const last_section = _.last(_.get(course_sections, 'data', []));
        const last_leson = _.last(last_section?.childs??[]);
        const has_permission = _.get(last_leson, 'has_permission', 0);
        const lesson = _.get(last_leson, 'lessons.0', {});
        return parseInt(_.get(params, 'lesson_id', 0))===_.get(lesson, 'id', -1) && has_permission && (
            _.get(lesson, 'lesson_type_id', 0)===3 || 
            _.get(lesson, 'lesson_type_id', 0)===4 ||
            ( _.get(lesson, 'lesson_type_id', 0)===1 && _.get(lesson, 'questions', []).length===0 )
        )
    }
    return(
        <div>
            <div className="lesson_exchange">
                {exchangeLink(false)?<Link to={exchangeLink(false)} className="btn btn-primary btn-sm" onClick={()=>window.scrollTo(0, 0)}>{t('exchange_lesson.prev')}</Link>
                :<button className="btn btn-primary btn-sm" style={{backgroundColor: 'rgba(13, 46, 105, 0.08)', cursor: 'no-drop'}}>{t('exchange_lesson.prev')}</button>}
                {exchangeLink(true)?<Link to={exchangeLink(true)} className="btn btn-primary btn-sm"  onClick={()=>window.scrollTo(0, 0)}>{t('exchange_lesson.next')}</Link>
                :<button className="btn btn-primary btn-sm" style={{backgroundColor: 'rgba(13, 46, 105, 0.08)', cursor: 'no-drop'}}>{t('exchange_lesson.next')}</button>}
            </div>
            { (
                _.get(course, 'data.type', '')==='target' && (
                    _.get(course, 'data.course_complate', false) || lastChild()
                ) 
            )?<>
            <div>
                <br/>
                <h4>{t("target_course.target_cer")}</h4>
            
                {_.get(course, 'data.targer_certificat.image', '')?<>
                    <button className="btn btn-primary btn-sm"
                        onClick={()=>{window.$('#modalCertificate_1').modal('show')}}
                    >
                        {t("target_course.view")}
                    </button>
                    <CertificateModal certificate={_.get(course, 'data.targer_certificat', {})}/>
                </>:<>
                    <p>{t("target_course.text")}</p>
                    {targetLoading?<Loading/>:
                    <button className="btn btn-primary btn-sm"
                        onClick={getTargetCertificate}
                    >{t("target_course.get")}</button>}
                </>}
            </div>
            
            </>:""}
        </div>
    )
}
export default LessonExchange;