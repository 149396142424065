import React, {useState, useEffect} from 'react';
import { getChatMessages, getChatMarkall } from "../actions";
import _ from 'lodash';
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import ChatBar from "./sections/ChatBar";
import ChatBody from "./sections/ChatBody";
import NoData from "./sections/NoData";
import Loading from "./sections/Loading";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const  DashChat = (props) => {
    const { t } = useTranslation();
    const { chat_message_list_status} = props;
    const [loading, setLoading] = useState(true);
    const chat__id = props.match.params.id;
    

    useEffect(()=>{
        if(chat__id){
            props.getChatMessages(chat__id);
        }
        return ()=>{
            props.getChatMarkall(chat__id);
            // props.chat_open(0);
        }
    },[chat__id]);
    useEffect(()=>{
        return ()=>{
            props.chat_open(0);
        }
    },[]);
    
    useEffect(()=>{
        if(chat_message_list_status.status === 'started'){
            setLoading(true);
        }
        if(chat_message_list_status.status === 'success'){
            setLoading(false);
            // props.getChatMarkall(chat__id);
        }
        if(chat_message_list_status.status === 'error'){
            setLoading(false);
        }
    },[chat_message_list_status]);

  return (<Layout>
	<CheckRole roles={ [ roles.user, roles.teacher, roles.admin, roles.manager ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('chat.title')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    }
                                ]}
                            />
                            <div className="dashboard-title">
                            <h5>{t('chat.title')}</h5>
                        </div>
                        <div className="chat chat--has-contacts">
                            <button type="button" className="chat__contacts-btn">
                                <span className="icon icon-arrow-right"></span>
                            </button>
                            <ChatBar chat_id = { parseInt(chat__id) }/>
                            <div className="chat__main bg-white">
                                {chat__id?
                                    loading?
                                        <><br/><Loading/></>
                                        :<ChatBody 
                                            chat_id = {chat__id}
                                        />
                                :<NoData />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getChatMessages: (chat_id)=>dispatch(getChatMessages(chat_id)),
        getChatMarkall: (chat_id)=>dispatch(getChatMarkall(chat_id)),
        chat_open: (chat_id)=>dispatch({type: "OPEN_CHAR", id: chat_id})
    })
)(DashChat);
