import React from 'react';
const NoData = (props) => {
    return (<>
        <div className="authors-empty mt-big">
            <div className="authors-empty__icon"></div>
            <div className="authors-empty__message">
                <p>{props.text}</p>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
      </>);
}
export default NoData;