import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import NavigationLocation from './sections/Navigation';
import NoData from './sections/NoData';
const CertificatesResults = () => {
	const { i18n } = useTranslation();
	const { language } = i18n;
	const [loading, setLoading] = useState(true);
	const [selectedRegion, setSelectedRegion] = useState('');
	const [cities, setCities] = useState([]);
	const [city, setCity] = useState('');
	const [results, setResults] = useState({
		certificate: [],
		data: [],
		offline_successfull_user: [],
		offline_user: [],
		regions: [],
		user: [],
	});
	const {
		certificate,
		data,
		offline_successfull_user,
		offline_user,
		regions,
		user,
	} = results;
	const [date, setDate] = useState({
		from: '',
		to: '',
	});
	const { from, to } = date;
	const region = regions
		?.map(({ name: parentName, childs }) => {
			const child = childs?.map(({ name, id }) => {
				return { name, parentName, id };
			});
			return child;
		})
		.flat();
	// const regionCertificate = certificate.reduce(
	// 	(total, { counts }) => (total += counts),
	// 	0
	// );
	// const dataAllCount = data.reduce((total, { all }) => (total += all), 0);
	// const dataCount = data.reduce((total, { counts }) => (total += counts), 0);
	const getResults = async () => {
		await setLoading(true);
		getInstance()
			.get(
				`/api/v1/result-certificate?${from ? 'from=' + from + '&' : ''}${
					to ? 'to=' + to + '&' : ''
				}${selectedRegion ? 'region_id=' + selectedRegion + '&' : ''}${
					city ? 'city_id=' + city : ''
				}`
			)
			.then(response => setResults(response?.data))
			.catch(() => {
				setResults({
					certificate: [],
					data: [],
					offline_successfull_user: [],
					offline_user: [],
					regions: [],
					user: [],
				});
			})
			.finally(() => setLoading(false));
	};
	useEffect(() => {
		getResults();
	}, [date, selectedRegion, city]);
	return (
		<Layout>
			<CheckRole roles={[roles.manager]} />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<NavigationLocation
										active={'Sertifikatlar'}
										is_btn={true}
										is_dash={true}
									/>
									<div
										class="dashboard-title dashboard-title--has-buttons"
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											flexWrap: 'wrap',
										}}
									>
										<select
											className="mr-2 mt-2 mb-2 btn btn-white btn-sm font-weight-medium"
											value={selectedRegion}
											style={{ padding: '6px 24px' }}
											onChange={e => {
												const cities = regions?.find(
													({ id }) =>
														id === parseInt(e.target.value)
												)?.childs;
												setCities(cities);
												setCity('');
												setSelectedRegion(e.target.value);
											}}
										>
											<option value="">Viloyatni tanlang</option>
											{regions?.map(({ name, id }, index) => {
												return (
													<option key={index} value={id}>
														{name}
													</option>
												);
											})}
										</select>
										<select
											className="mr-2 mt-2 mb-2 btn btn-white btn-sm font-weight-medium"
											value={city}
											style={{ padding: '6px 24px' }}
											onChange={e => setCity(e.target.value)}
										>
											<option value="">Tumanni tanlang</option>
											{(selectedRegion ? cities : region)?.map(
												({ name, id }, index) => {
													return (
														<option key={index} value={id}>
															{name}
														</option>
													);
												}
											)}
										</select>
										<input
											type="date"
											className="mr-2 mt-2 mb-2 btn btn-white btn-sm font-weight-medium"
											value={from}
											style={{ padding: '7px 24px' }}
											onChange={e =>
												setDate({ from: e.target.value, to })
											}
										/>
										<input
											type="date"
											className="mr-2 mt-2 mb-2 btn btn-white btn-sm font-weight-medium"
											value={to}
											style={{ padding: '7px 24px' }}
											onChange={e =>
												setDate({ to: e.target.value, from })
											}
										/>
										<button
											onClick={() =>
												window.open(
													`https://api.onlinedu.uz/api/v1/result-certificate?export=1&lan=${language}&token=${localStorage.getItem(
														'token'
													)}${from ? '&from=' + from : ''}${
														to ? '&to=' + to + '&' : ''
													}${
														selectedRegion
															? 'region_id=' +
															  selectedRegion +
															  '&'
															: ''
													}${city ? 'city_id=' + city : ''}`,
													'_blank'
												)
											}
											className="mt-2 mb-2 mr-2 btn btn-white btn-sm font-weight-medium"
										>
											Excelga eksport qilish
										</button>
										<button
											className="mt-2 mb-2 btn btn-primary btn-sm font-weight-medium"
											onClick={() => {
												setDate({
													from: '',
													to: '',
												});
											}}
										>
											Filterni tozalash
										</button>
									</div>
									<div className="card card--shadow">
										<div className="card-body p-4">
											<div className="scroll scroll--horizontall">
												{loading ? (
													<Loading />
												) : region?.length ? (
													<table
														className="table table-bordered table--style-1 manager-teachers-table"
														style={{ textAlign: 'center' }}
													>
														<thead>
															<tr>
																<th>
																	<div className="cell-inner">
																		Viloyat
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Tuman
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Foydalanuvchilar soni
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Sertifikatlar soni
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Onlayn
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Offlayn
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Ikki va undan ko'p
																		sertifikatlar soni
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Ikki va undan ko'p
																		sertifikat olganlar soni
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Sertifikat olgan
																		foydalanuvchilar soni
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Chetlatilgan
																		foydalanuvchilar soni
																	</div>
																</th>
																<th>
																	<div className="cell-inner">
																		Offlayn o'qitilgan
																		foydalanuvchilar soni
																	</div>
																</th>
															</tr>
														</thead>
														<tbody>
															{/* <tr>
																<td>
																	<div className="cell-inner">
																		Viloyat
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		Tuman
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{user.reduce(
																			(total, { counts }) =>
																				(total += counts),
																			0
																		)}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{regionCertificate}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{certificate.reduce(
																			(
																				total,
																				{ online_count }
																			) =>
																				(total +=
																					online_count),
																			0
																		)}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{certificate.reduce(
																			(
																				total,
																				{ offline_count }
																			) =>
																				(total +=
																					offline_count),
																			0
																		)}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{dataAllCount}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{dataCount}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{regionCertificate -
																			dataAllCount +
																			dataCount}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{offline_user.reduce(
																			(total, { counts }) =>
																				(total += counts),
																			0
																		)}
																	</div>
																</td>
																<td>
																	<div className="cell-inner">
																		{offline_successfull_user.reduce(
																			(total, { counts }) =>
																				(total += counts),
																			0
																		)}
																	</div>
																</td>
															</tr> */}
															{region?.map(
																(
																	{ name, parentName, id },
																	index
																) => {
																	const userCount = user?.find(
																		({ city_id }) =>
																			city_id === id
																	)?.counts;
																	const regionCertificate =
																		certificate?.find(
																			({ city_id }) =>
																				city_id === id
																		);
																	const certificateCount =
																		data?.find(
																			({ city_id }) =>
																				city_id === id
																		);
																	const cert =
																		regionCertificate?.counts +
																		certificateCount?.counts -
																		certificateCount?.all;
																	const offline =
																		offline_user?.find(
																			({ city_id }) =>
																				city_id === id
																		)?.counts;
																	const offlineUsersCount =
																		offline_successfull_user?.find(
																			({ city_id }) =>
																				city_id === id
																		)?.counts;
																	return (
																		<tr key={index}>
																			<td>
																				<div className="cell-inner">
																					{parentName}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{name}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{userCount
																						? userCount
																						: 0}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{regionCertificate?.counts
																						? regionCertificate?.counts
																						: 0}
																				</div>
																			</td>
																			<td>
																				{regionCertificate?.online_count
																					? regionCertificate?.online_count
																					: 0}
																			</td>
																			<td>
																				<div className="cell-inner">
																					{regionCertificate?.offline_count
																						? regionCertificate?.offline_count
																						: 0}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{certificateCount?.all
																						? certificateCount?.all
																						: 0}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{certificateCount?.counts
																						? certificateCount?.counts
																						: 0}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{cert ? cert : 0}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{offline
																						? offline
																						: 0}
																				</div>
																			</td>
																			<td>
																				<div className="cell-inner">
																					{offlineUsersCount
																						? offlineUsersCount
																						: 0}
																				</div>
																			</td>
																		</tr>
																	);
																}
															)}
														</tbody>
													</table>
												) : (
													<NoData text={'Malumot yoq'} />
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default CertificatesResults;
