import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Rating = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('');
    const submitHandler = event => {
		event.preventDefault();
        if( _.get(survey, 'isRequired', false) &&   !_.get(result, 'value', '') ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', ''), type: survey.type });
        }
    }
    return(
            <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.rating') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            <div>
                                {( () => {
                                    let l = [];
                                    for (
                                        let y = 1;
                                        y <= survey.rateMax;
                                        y++
                                    ) {
                                        l.push(
                                            <span 
                                                onClick={()=>{
                                                    surveyChange( {id: survey.id, value: y} );
                                                    setError('')
                                                }}
                                                className={_.get(result, 'value', '')===y?"btn btn-primary btn-sm mb-2 ml-2":"btn btn-secondary-light btn-sm mb-2 ml-2"}
                                            >{y}</span>
                                        );
                                    }
                                    return l;
                                })()}

                            </div>
                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default Rating;