import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import { Link } from 'react-router-dom';
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import CourseCard from './sections/CourseCard';
import Pagination from './sections/Pagination';
import { getTeacherCourses, postTeacherCourse } from "../actions";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import history from '../history';
import $ from "jquery";
window.jQuery = $;


const DashCopyrights = (props) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(true);
    const [s_loading, setSLoading] = useState(0);
    const [search_loading, setSearch_loading] = useState(false);
    const { teacher_courses, } = props;
    const convertLink = () => {
        let str = '?';
        if (status)  str += 'status=' + status + '&'; 
        if (type) str += 'type=' + type + '&'; 
        return str;
    }
    useEffect(() => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const status_p = params.get('status');
        const type_p = params.get('type');
        setStatus(status_p);
        setType(type_p);
        let str = '&';
        if (status_p)   str += 'status=' + status_p + '&'; 
        if (type_p) str += 'type=' + type_p + '&'; 
        props.getTeacherCourses(props.match.params.page ? props.match.params.page : 1, str); 
        window.$('#modalAddSertificateText').modal('hide'); 
        setSearch_loading(false);
    }, [props.match.params.page, props.location.search]);
    useEffect(() => {
        if (props.teacher_courses_status.status === 'started')   setLoading(true);
        if (props.teacher_courses_status.status === 'success')   setLoading(false);
        if (props.teacher_courses_status.status === 'error')  setLoading(false); 
    }, [props.teacher_courses_status]);
    useEffect(() => {
        if (props.teacher_change_course_status.status === 'success')  setSLoading(0); 
        if (props.teacher_change_course_status.status === 'error')  setSLoading(0); 
    }, [props.teacher_change_course_status]);
    const  setIs_Search = () => {
        // $('#modalAddSertificateText').hide();
        setSearch_loading(true);
        history.push('/dashboard/copyrights/list/1'+convertLink());
    }
    return (<Layout>
        <CheckRole roles={[roles.teacher]} />
        <main className="main">
            <section className="section section-dashboard py-0">
                <div className="container">
                    <div className="row">
                        <DashSideBar />
                        <div className="col-md-12 col-lg-9 col-xl-10">
                            <div className="dashboard-right">
                                <Navigation
                                    is_dash={true}
                                    is_btn={true}
                                    active={t('author_page.course')}
                                    navs={[
                                        {
                                            'title': t('navigation.account'),
                                            'link': '/dashboard'
                                        }
                                    ]}
                                />
                                {/* <div className="dashboard-title">
                                    <h5>
                                        <Link to="/dashboard/copyrights/list/"
                                            className="btn btn-outline-primary  btn-sm font-weight-medium px-3 mr-3"
                                        >
                                            {t('author_page.course')}
                                        </Link>

                                        <Link to="/dashboard/copyrights/webinars/list/"
                                            className="btn btn-secondary-light  btn-sm font-weight-medium px-3"
                                        >
                                            {t('author_page.webinar')}
                                        </Link>
                                    </h5>
                                </div> */}
                                <div className="new-course__top-buttons">
                                    <Link to="/dashboard/copyrights/course-create" className="btn btn-primary btn-sm font-weight-medium px-3">
                                        <span className="icon icon-plus mr-2"></span>{t('author_page.add')}
                                    </Link>
                                    <div className="dropdown"> 
                                        <span className="btn btn-white btn-sm w-100 font-weight-medium px-3 " type="button"
                                             data-target="#modalAddSertificateText" data-toggle="modal">
                                            {t('m_pupils.filter')}
											<span class="icon icon-filter fs-100 ml-1"></span>
                                        </span> 
                                    </div>
                                </div>
                                {loading ?
                                    <Loading />
                                    : <>
                                        {teacher_courses.data && teacher_courses.data.length > 0 ? <>
                                            <div className="row courses-dashboard">
                                                {teacher_courses.data.map((course, index) => (
                                                    <CourseCard
                                                        key={index}
                                                        course={course}
                                                    >
                                                        <div className="course__footer-authors">
                                                            <div><span className="icon icon-users"></span>{course.buys_count}</div>
                                                            <Link to={"/dashboard/copyrights/course-update/" + course.id} className="icon icon-edit"></Link>
                                                            <Link to={"/dashboard/copyrights/course-items/" + course.id} className="icon icon-hamburger"></Link>
                                                            {s_loading === course.id ? <span className="text-center" style={{ width: 108 }}><Loading size={4} /></span> : <>
                                                                {course.status === 0 ?
                                                                    <span className="text-primary"
                                                                        onClick={() => {
                                                                            setSLoading(course.id); props.postTeacherCourse({
                                                                                status: 1,
                                                                                edit_status: 1,
                                                                                name: course.name,
                                                                                type: course.type,
                                                                                description: course.description,
                                                                                language_id: course.language_id,
                                                                                category_id: course.category_id,
                                                                                hours: course.hours,
                                                                                price: course.price
                                                                            }, course.id, true)
                                                                        }}
                                                                    >
                                                                        {t('author_page.activate')}
                                                                    </span>
                                                                    : <span className="text-light"
                                                                        onClick={() => {
                                                                            setSLoading(course.id); props.postTeacherCourse({
                                                                                status: 0,
                                                                                edit_status: 1,
                                                                                name: course.name,
                                                                                type: course.type,
                                                                                description: course.description,
                                                                                language_id: course.language_id,
                                                                                category_id: course.category_id,
                                                                                hours: course.hours,
                                                                                price: course.price,
                                                                            }, course.id, true)
                                                                        }}
                                                                    >
                                                                        {t('author_page.arxivate')}
                                                                    </span>}
                                                            </>}
                                                        </div>
                                                    </CourseCard>
                                                ))}
                                            </div>
                                            <Pagination
                                                total={teacher_courses.total ? teacher_courses.total : 6}
                                                active={parseInt(props.match.params.page)}
                                                link="/dashboard/copyrights/list/"
                                                search_params={convertLink()}
                                            />
                                        </>
                                            :
                                            <NoData
                                                text={t('author_page.no_data_c')}
                                            />
                                        }
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <div
            className="modal fade"
            id="modalAddSertificateText"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalAddSertificateTextTitle"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t('m_pupils.filter')} 
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon icon-close"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-1">
                            <label for="">{t('create_course.type')}</label>
                            <select name="position_type_id" className="form-control custom-select" 
                                value={type} onChange={e=>setType(e.target.value)}
                            >
                                <option selected value="">{t('m_pupils.all')} </option>
                                <option value="upgrade">{t('create_course.upgrade')}</option>
                                <option value="information"> {t('create_course.information')} </option>
                                <option value="paid"> {t('create_course.paid')} </option>
                            </select>
                        </div> 
                        <div className="form-group mb-2">
                            <label for="">{t('create_course.status')}</label>
                            <select  name="position_type_id" className="form-control custom-select" 
                                value={status} onChange={e=>setStatus(e.target.value)}
                            >
                                <option selected value="">{t('m_pupils.all')} </option>
                                <option value="1">{t('author_page.active')}</option>
                                <option value="0"> {t('author_page.arxive')} </option>
                            </select>
                        </div>
                        {search_loading?<div >
                            <Loading size={7} />
                        </div>
                        :<button type="button" className="btn btn-primary w-100 mb-2"
                            onClick={()=>setIs_Search()}
                        >
                            {t('m_pupils.filter')}
                        </button>}
                    </div>
                </div>
            </div>
        </div>
    </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherCourses: (page, search) => dispatch(getTeacherCourses(page, search)),
        postTeacherCourse: (data, id, fettle) => dispatch(postTeacherCourse(data, id, '', fettle, false))
    })
)(DashCopyrights);