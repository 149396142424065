import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ChoiceLogic = props => {
	const { t } = useTranslation();
	const { survey, logic_item, logic_survey, setLogicList } = props;
	const changeLogic = (id, name, value) => {
		let l = [];
		survey.logic.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, choice: { [name]: value } });
			} else {
				l.push(item);
			}
		});
		setLogicList(l);
	};
	return (
		<>
			<label for="">{t('survey_c.select-answer')}</label>
			<select
				className="form-control"
				style={{
					background: 'white',
					width: '100%',
				}}
				name="inputType"
				value={_.get(logic_item, 'choice.item_id', 0)}
				onChange={e => {
					changeLogic(logic_item.id, 'item_id', parseInt(e.target.value));
				}}>
				<option selected value={0}>
					{t('survey_c.choose_one')}
				</option>
				{logic_survey.choices
					? logic_survey.choices.map((item, index) => (
							<option value="text" key={index} value={item.id}>
								{index + 1}-{t('survey_c.item')} | {item.label_uz} | {item.label_ru}
							</option>
					  ))
					: null}
			</select>
		</>
	);
};
export default ChoiceLogic;
