import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Checkbox = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('')
    const submitHandler = event => {
		event.preventDefault();
        if( _.get(survey, 'isRequired', false) &&   _.get(result, 'value', []).length===0 ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', []), type: survey.type });
        }
    }
    return(
            <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.checkbox') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group  m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            <ul className="test__item-answers">
                                {_.filter( _.get(survey, 'choices', []), (i)=>{return !i.is_write}).map((item, index)=>(
                                    <li key={index}>
                                        <label className="checkbox-custom">
                                            <input type="checkbox" name='input[]' value={item.id} 
                                                checked={ _.get(result, 'value', []).includes(item.id) }
                                                onChange={e=>{ surveyChange( {id: survey.id, 
                                                    value:  _.get(result, 'value', []).includes( parseInt( e.target.value ) )?
                                                    _.remove(_.get(result, 'value', []),(i)=>{return i!==parseInt( e.target.value ) }):
                                                    [..._.get(result, 'value', []), parseInt( e.target.value ) ] } );
                                                    setError('');
                                                }}
                                            />
                                            {_.get(item, 'label_'+lan, '')}
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                            {_.filter( _.get(survey, 'choices', []), (i)=>{return i.is_write}).map((item, index)=>(<div key={index}>
                                <label style={{fontSize: 18, marginTop: 10}}> 
                                    {_.get(item, `label_${lan}`, '')} 
                                </label>
                                <input
                                    type='text'
                                    className="form-control"
                                    name="input"
                                    value={_.get( _.filter( _.get(result, 'value', []),  (i)=>{return i.id===item.id}),'[0].write' ,'')}
                                    onChange={e=>surveyChange( {id: survey.id, value: e.target.value?{ id: item.id, write: e.target.value }:null } )}
                                    onChange={e=>{ surveyChange( {id: survey.id, 
                                        value:  _.remove(_.get(result, 'value', []), (i)=>{return i.id===item.id }).length?
                                        e.target.value?
                                            [..._.remove(_.get(result, 'value', []), (i)=>{return i.id!==item.id }), { id: item.id, write: e.target.value }]
                                            :_.remove(_.get(result, 'value', []), (i)=>{return i.id!==item.id }):
                                        [..._.get(result, 'value', []),  e.target.value?{ id: item.id, write: e.target.value }:null ] } );
                                        setError('');
                                    }}
                                    onFocus={()=>setError('')}
                                />
                            </div>))}
                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default Checkbox;