import React, { useEffect, useState } from 'react';
import Timer from 'react-compound-timer';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import TestResultMultiple from './TestResultMultiple';
import TestResultGrouping from './TestResultGrouping';
import TestResultWordDrags from './TestResultWordDrags';
import TestResultSingle from './TestResultSingle';
import _ from 'lodash';
const TestResult = props => {
	const { t } = useTranslation();
	const { test_check_data } = props;
	const lesson = test_check_data ? test_check_data : { passings: {} };
	// const questions = lesson.vquestions ? lesson.vquestions : [];
	const questions =  [];
	const answers_user = lesson.answers_user ? lesson.answers_user : {};
	const t_score = lesson.score ? lesson.score : null;
	const u_score = lesson.passings && lesson.passings.score ? lesson.passings.score : 0;
	const [limit, setLimit] = useState(null);
	const backCourse = () => {
		props.setIsDone();
	};
	useEffect(() => {
		if (lesson.passings && lesson.passings.is_completed === 0) {
			const d = new Date(lesson.passings.updated_at);
			const d1 = new Date();
			setLimit( 2400000 - (d1-d) );
			// setLimit(10000 - (d1 - d));
		}
	}, [test_check_data]);

	return (
		<>
			<ScrollToTopOnMount />
			<div className="dashboard-title">
				<h3>{t('test_result.title')}</h3>
			</div>
			<div className="card">
				<div className="card-body pb-0">
					<div className="row justify-content-xl-between">
						<div className="col-sm-6 col-xl-auto">
							<div className="d-flex mb-5">
								<span className="icon icon-paper mt-1 mr-2"></span>
								<div className="text-secondary text-nowrap">
									<strong className="font-weight-medium">{t('test_result.condition')}</strong>
									<br />
									{lesson.passings && lesson.passings.is_completed === 1 ? (
										<small>{t('test_result.completed')}</small>
									) : (
										<small>{t('test_result.not_completed')}</small>
									)}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-auto">
							<div className="d-flex mb-5">
								<span className="icon icon-calendar mt-1 mr-2"></span>
								<div className="text-secondary text-nowrap">
									<strong className="font-weight-medium">
										{t('test_result.date_of_completion')}
									</strong>
									<br />
									<small>
										{lesson.passings && lesson.passings.updated_at ? (
											<Moment format="HH:mm DD.MM.YYYY">{lesson.passings.updated_at}</Moment>
										) : (
											t('test_result.start-attempt')
										)}
									</small>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-auto">
							<div className="d-flex mb-5">
								<span className="icon icon-star mt-1 mr-2"></span>
								<div className="text-secondary text-nowrap">
									<strong className="font-weight-medium">{t('test_result.assessment')}</strong>
									<br />
									<small>
										{t_score
											? u_score +
											  '/' +
											  t_score +
											  ' (' +
											  parseInt((u_score / t_score) * 100) +
											  '%)'
											: null}
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{lesson.passings && lesson.passings.is_completed === 1 ? (
				<>
					{questions.map((question, index) => {
						return (
							<>
								{question.type === 'single'?<TestResultSingle question={question} key={index} number={index} />
								:question.type === 'grouping'?<TestResultGrouping  answers_user={ _.get(answers_user, question.id, {} )}
									question={question} key={index}  number={index} />
								:question.type === 'dragwords'?<TestResultWordDrags answers_user={ _.get(answers_user, question.id, {} )}
									question={question} key={index}  number={index} />
								:<TestResultMultiple question={question} key={index}  number={index} />
								}
								{question.explan ? 
									<div className="card">
										<div className="card-body py-3">
											<div className="font-weight-medium text-secondary">
												<p className="mb-1">
													<span className="text-primary">{t('test_result.explan')}:</span>
													<p dangerouslySetInnerHTML={{ __html: question.explan }} />
												</p>
											</div>
										</div>
									</div>
								: null}
							</>
						);
					})}
				</>
			) : limit && limit >= 0 ? 
				<div className="card">
					<div className="card-body pb-0">
						<div className="row justify-content-xl-between">
							<div className="col-12">
								<div className="d-flex mb-5 " style={{ justifyContent: 'center' }}>
									<span className="icon icon-clock mt-1 mr-2"></span>
									<div className="text-secondary">
										<strong className="font-weight-medium">{t('test_result.text')}</strong>
										<br />
										<small>
											{limit ? (
												<Timer
													initialTime={limit}
													direction="backward"
													checkpoints={[
														{
															time: 0,
															callback: () => backCourse(),
														},
													]}>
													{() => (
														<>
															<Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds />
														</>
													)}
												</Timer>
											) : null}
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			 : 
				<div className="test__buttons">
					<div className="dashboard-buttons">
						<button type="submit" className="btn btn-primary " onClick={() => backCourse()}>
							{t('test_result.restart_test')}
						</button>
					</div>
				</div>
			}
		</>
	);
};
export default TestResult;
