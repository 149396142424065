import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import { Link } from 'react-router-dom';
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import WebinarCard from './sections/WebinarCard';
import Pagination from './sections/Pagination';
import { getTeacherWebinars } from "../actions";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const DashCopyrightsWebinars = (props) => {
    const { t } = useTranslation();
    
    const [status, setStatus] = useState(1);
    const [loading, setLoading] = useState(true);
    const [s_loading, setSLoading] = useState(0);
    const convertLink = () => {
        let str = '?';
        if(status){
            str +='status='+status+'&';
        }
        return str;
    }
    useEffect(()=>{
        const search = props.location.search; 
        const params = new URLSearchParams(search);
        const status_p = params.get('status');
        setStatus(status_p);
        let str = '&';
        if(status_p){
            str +='status='+status_p+'&';
        }
        props.getTeacherWebinars( props.match.params.page?props.match.params.page:1, str );
    },[props.match.params.page, props.location.search]);
    useEffect(()=>{
        if(props.teacher_webinar_status.status === 'started'){
            setLoading(true);
        }
        if(props.teacher_webinar_status.status === 'success'){
            setLoading(false);
        }
        if(props.teacher_webinar_status.status === 'error'){
            setLoading(false);
        }
    },[props.teacher_webinar_status]);
    const {teacher_webinar, } = props;
    
  return (<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('author_page.webinar')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    }
                                ]}
                            />
                           <div className="dashboard-title">
                                <h5>
                                    <Link to="/dashboard/copyrights/list/" 
                                         className="btn btn-secondary-light btn-sm font-weight-medium px-3 mr-3"
                                    >
                                        {t('author_page.course')}
                                    </Link>
                                    
                                    <Link to="/dashboard/copyrights/webinars/list/"  
                                         className="btn btn-outline-primary  btn-sm font-weight-medium px-3"
                                    >
                                        {t('author_page.webinar')}
                                    </Link>
                                </h5>
                            </div>
                            <div className="new-course__top-buttons">
                                <Link to="/dashboard/copyrights/webinar-create" className="btn btn-primary btn-sm font-weight-medium px-3">
                                    <span className="icon icon-plus mr-2"></span>{t('author_page.add')}
                                </Link>
                                {/* <div className="dropdown">
                                    <button className="btn btn-white btn-sm w-100 font-weight-medium px-3 dropdown-toggle" type="button" 
                                        id="dropdownActiveCourses" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {status==='1'?'Активные курсы':'Неопубликованные курсы'}
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownActiveCourses">
                                        <Link to="/dashboard/copyrights/list/1?status=1" className="dropdown-item" >Активные курсы</Link>
                                        <Link to="/dashboard/copyrights/list/1?status=0" className="dropdown-item" >Неопубликованные курсы</Link>
                                    </div>
                                </div> */}
                            </div>
                            {loading?
                                <Loading/>
                            :<>
                                {teacher_webinar.data&&teacher_webinar.data.length>0?<>
                                <div className="row courses-dashboard"> 
                                    {teacher_webinar.data.map((webinar,index)=>(
                                        <WebinarCard webinar={webinar} key={index}>
                                            <div className="webinar__footer">
                                                <Link to={'/dashboard/copyrights/webinar-update/'+webinar.slug} className="btn btn-secondary-light mt-1 w-100">
                                                    <span className="icon icon-edit"></span>{t('author_page.edit')}
                                                </Link>
                                            </div>
                                        </WebinarCard> 
                                    ))} 
                                </div>
                                <Pagination
                                    total={teacher_webinar.total?teacher_webinar.total:6}
                                    active={ parseInt(props.match.params.page) }
                                    link="/dashboard/copyrights/webinars/list/"
                                    search_params={convertLink()}
                                />
                                </>
                                :
                                    <NoData
                                        text={t('author_page.no_data_w')}
                                    />
                                }
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherWebinars: (page, search) => dispatch(getTeacherWebinars(page, search)),
    })
)(DashCopyrightsWebinars);