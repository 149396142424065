import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getMenegerTargetCourse } from '../actions';
import history from '../history';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';
import Pagination from './sections/Pagination';
import CheckRole from './sections/CheckRole';
import { roles } from '../helpers/roles';
import { API_URL } from '../helpers/api';
import { getToken } from '../helpers/tokenStorge';
import ExcelExportModal from './sections/ExcelExportModal';
import { Link } from 'react-router-dom';

const ManagerTargetCourses = props => {
	const { t } = useTranslation();
	const {
		manager_courses,
		manager_counts,
		manager_counts_status,
		manager_courses_status,
	} = props;
	const [loading, setLoading] = useState(true);
	const { page } = props.match.params;
	const [is_Search, setIs_Search] = useState(2);
	const [search_loading, setSearchLoading] = useState(false);
	const [count_loading, setCountLoading] = useState(false);
	const [search, setSearch] = useState({
		name: '',
		created_at: '',
		hours: '',
		buys_count: '',
		video_count: '',
		region: '',
		week_buys: '',
		year: '',
	});
	const {
		name,
		created_at,
		hours,
		buys_count,
		video_count,
		region,
		week_buys,
		year,
	} = search;
	const params = `${name ? '&name=' + name : ''}${
		created_at ? '&created_at=' + created_at : ''
	}${hours ? '&hours=' + hours : ''}${
		buys_count ? '&buys_count=' + buys_count : ''
	}${video_count ? '&video_count=' + video_count : ''}${
		region ? '&region=' + region : ''
	}${week_buys ? '&week_buys=' + week_buys : ''}${year ? '&year=' + year : ''}`;
	let auto_time = useRef(null);

	useEffect(() => {
		setSearchLoading(true);
		props.getMenegerTargetCourse(
			page ? page : 1,
			window.location.search ? window.location.search : '?',
		);
		if (window.location.search) {
			const obj = JSON.parse(
				'{"' +
					decodeURI(window.location.search.substring(1))
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}',
			);
			setSearch({ ...search, ...obj });
		}
		return () => {
			clearTimeout(auto_time.current);
		};
	}, [window.location.search, page]);
	useEffect(() => {
		if (is_Search !== 2)
			history.push(`/manager/target-courses/1?${params.substring(1)}`);
	}, [is_Search]);

	const changeParams = (e, is_loading = true) => {
		const { value, name } = e.target;
		clearTimeout(auto_time.current);
		if (is_loading) {
			setSearchLoading(true);
			setSearch({
				...search,
				[name]: value,
			});
			setIs_Search(!is_Search);
		} else {
			setSearchLoading(true);
			setSearch({
				...search,
				[name]: value,
			});
			auto_time.current = setTimeout(() => {
				setIs_Search(!is_Search);
			}, 1500);
		}
	};
	const resetFilter = () => {
		setSearch({
			name: '',
			created_at: '',
			hours: '',
			buys_count: '',
			video_count: '',
			region: '',
			week_buys: '',
			year: '',
		});
		history.push(`/manager/target-courses/1`);
	};
	const changeYear = year => {
		setSearch({
			...search,
			year: year,
		});
		setIs_Search(!is_Search);
	};
	useEffect(() => {
		if (manager_counts_status.status === 'started') {
			setCountLoading(true);
		}
		if (manager_counts_status.status === 'success') {
			window.$('#modalFilter').modal('hide');
			setCountLoading(false);
		}
		if (manager_counts_status.status === 'error') {
			setCountLoading(false);
		}
		if (manager_courses_status.status === 'started') {
			setSearchLoading(true);
		}
		if (manager_courses_status.status === 'success') {
			// window.$('#modalFilter').modal('hide');
			setSearchLoading(false);
			setLoading(false);
		}
		if (manager_courses_status.status === 'error') {
			setLoading(false);
			setSearchLoading(false);
		}
	}, [manager_counts_status, manager_courses_status]);
	const language = window.localStorage.getItem('language') || 'uz';

	return (
		<Layout>
			<CheckRole roles={[roles.manager, roles.vazirlik]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('target_course.target')}
									/>
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{t('target_course.target')}</h5>
										<div class="buttons butons--target">
											{/* <button
												data-toggle="modal"
												data-target="#modalExcelExportcourses"
												className="btn btn-white btn-sm font-weight-medium">
												{t('m_pupils.excel_export')}
											</button> */}
											<button
												className="btn btn-white btn-sm font-weight-medium"
												onClick={resetFilter}>
												{t('m_courses.reset')}
											</button>
											<div class="dropdown">
												<button
													class="btn btn-white btn-sm w-100 font-weight-medium px-3 dropdown-toggle"
													type="button"
													id="dropdownActiveCourses"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false">
													{t('target_course.cer_year')}
												</button>
												<div
													class="dropdown-menu dropdown-menu-right"
													aria-labelledby="dropdownActiveCourses">
													<div
														onClick={() => changeYear('')}
														class="dropdown-item"
														to="/manager/target-courses/1?year=2021">
														{t('m_courses.all')}
													</div>
													<div
														onClick={() => changeYear(2022)}
														class="dropdown-item"
														to="/manager/target-courses/1?year=2022">
														2022{t('m_courses.y')}
													</div>
													<div
														onClick={() => changeYear(2021)}
														class="dropdown-item"
														to="/manager/target-courses/1?year=2021">
														2021{t('m_courses.y')}
													</div>
													<div
														onClick={() => changeYear(2020)}
														class="dropdown-item"
														to="/manager/target-courses/1?year=2020">
														2020{t('m_courses.y')}
													</div>
												</div>
											</div>
										</div>
									</div>
									{loading ? (
										<Loading />
									) : (
										<>
											<div class="card card--shadow">
												<div class="card-body p-3">
													<div class="scroll scroll--horizontall">
														<table class="table table-bordered table--style-1 manager-teachers-table">
															<thead>
																<tr>
																	<th>
																		<div class="cell-inner">
																			{t('m_courses.col8')}
																		</div>
																	</th>
																	<th>
																		<div class="cell-inner">
																			{t('m_courses.col9')}
																		</div>
																	</th> 
																	<th>
																		<div class="cell-inner">
																			{t('m_courses.col')} {t('m_courses.col2')}
																		</div>
																	</th>
																	<th>
																		<div class="cell-inner">
																			{t('m_courses.col')} {t('m_courses.col6')}
																		</div>
																	</th>
																	<th>
																		<div class="cell-inner">
																			{t('m_courses.col')}{' '}
																			{t('m_courses.col11')}
																		</div>
																	</th>
																</tr>
																<tr>
																	<th>
																		<div class="cell-inner cell-search">
																			<div class="cell-search__form">
																				<label for="search_1">
																					<span class="icon icon-search"></span>
																				</label>
																				<input
																					type="text"
																					id="search_1"
																					name="name"
																					onChange={e => changeParams(e, false)}
																					value={name}
																					placeholder={t('m_courses.search')}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div class="cell-inner cell-search">
																			<div class="cell-search__form">
																				<input
																					type="date"
																					id="search_2"
																					style={{
																						height: 30,
																						border: 'none',
																						width: '100%',
																						outline: 'none',
																						background: '#fbfbfc',
																					}}
																					name="created_at"
																					value={created_at}
																					onChange={e => changeParams(e)}
																					placeholder={t('m_courses.search')}
																				/>
																			</div>
																		</div>
																	</th> 
																	<th>
																		<div class="cell-inner cell-search">
																			<div class="cell-search__form">
																				<input
																					type="number"
																					id="search_4"
																					name="buys_count"
																					style={{
																						height: 30,
																						width: '100%',
																						border: 'none',
																						outline: 'none',
																						background: '#fbfbfc',
																					}}
																					onChange={e => changeParams(e, false)}
																					value={buys_count}
																					placeholder={t('m_courses.search')}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div class="cell-inner cell-search">
																			<div class="cell-search__form">
																				<input
																					type="number"
																					id="search_6"
																					name="video_count"
																					style={{
																						height: 30,
																						width: '100%',
																						border: 'none',
																						outline: 'none',
																						background: '#fbfbfc',
																					}}
																					onChange={e => changeParams(e, false)}
																					value={video_count}
																					placeholder={t('m_courses.search')}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div class="cell-inner cell-search">
																			<div class="cell-search__form">
																				<input
																					type="number"
																					id="search_7"
																					name="week_buys"
																					style={{
																						height: 30,
																						width: '100%',
																						border: 'none',
																						outline: 'none',
																						background: '#fbfbfc',
																					}}
																					onChange={e => changeParams(e, false)}
																					value={week_buys}
																					placeholder={t('m_courses.search')}
																				/>
																			</div>
																		</div>
																	</th>
																</tr>
															</thead>
															{search_loading ? null : (
																<tbody>
																	{manager_courses.data &&
																	manager_courses.data.length
																		? manager_courses.data.map(
																				(course, index) => (
																					<tr key={index}>
																						<td>
																							<div
																								class="cell-inner"
																								style={{
																									width: 240,
																									whiteSpace: 'normal',
																								}}>
																								<Link
																									to={`/manager/target-courses/review/${course.slug}/${course.id}`}>
																									{course.name}
																								</Link>
																							</div>
																						</td>
																						<td>
																							<div class="cell-inner">
																								<Moment format="DD/MM/YYYY">
																									{course.created_at}
																								</Moment>
																							</div>
																						</td> 
																						<td>
																							<div class="cell-inner">
																								{course.buys_count}
																								<Link to={`/manager/target-courses/${course?.slug}/pupils/1`}
																									className='btn btn-primary btn-sm ml-2'
																									style={{padding: '5px 10px', fontSize: 14}}
																								>
																									{t('target_course.detail')}
																								</Link>
																							</div>
																						</td>
																						<td>
																							<div class="cell-inner">
																								{course.video_count}
																							</div>
																						</td>
																						<td>
																							<div class="cell-inner">
																								{course.week_buys}
																							</div>
																						</td>
																					</tr>
																				),
																		  )
																		: null}
																</tbody>
															)}
														</table>
														{search_loading ? (
															<div>
																<Loading size={7} />
															</div>
														) : manager_courses.data &&
														  manager_courses.data.length ? null : (
															<NoData text={t('m_courses.no_data')} />
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-md-6 p-0">
													<div className="mt-4" style={{ textAlign: 'center' }}>
														{(page - 1) * 20 + 1} -{' '}
														{page * 20 > manager_courses.total
															? manager_courses.total
															: page * 20}{' '}
														/ {t('m_pupils.all_p')}{' '}
														{manager_courses.total ? manager_courses.total : 0}{' '}
														{t('m_pupils.ta')}
													</div>
												</div>
												<div className="col-12 col-md-6 p-0">
													<Pagination
														total={
															manager_courses.total ? manager_courses.total : 20
														}
														count={20}
														active={parseInt(page)}
														link="/manager/target-courses/"
														search_params={'?' + params.substring(1)}
													/>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<ExcelExportModal
				parent="courses"
				link={
					API_URL +
					'/api/v1/courses/export/' +
					window.location.search + (window.location.search ? '&token=' : '?token=') + getToken() +
					'&lan=' + language
				}
				cols={[
					{
						name: 'name',
						title: t('m_courses.col8'),
					},
					{
						name: 'created_at',
						title: t('m_courses.col9'),
					}, 
					{
						name: 'buys_count',
						title: t('m_courses.col') + ' ' + t('m_courses.col2'),
					},
					{
						name: 'video_count',
						title: t('m_courses.col') + ' ' + t('m_courses.col6'),
					},
					{
						name: 'week_buys',
						title: t('m_courses.col') + ' ' + t('m_courses.col11'),
					},
				]}
			/>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getMenegerTargetCourse: (page, search) =>
			dispatch(getMenegerTargetCourse(page, search))
	}),
)(ManagerTargetCourses);
