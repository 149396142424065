import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Loading from '../Loading';

const Step5 = (props) => {
  const {
    edu,
    disabled,
    setStep,
    step,
    checkNumber,
    submitTest6,
    loading,
    errorEdu,
    setErrorEdu,
    setEdu,
  } = props;

  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest6}>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.bank_info')}</h6>
          {errorEdu.bank_name ||
          errorEdu.mfo ||
          errorEdu.bank_inn ||
          errorEdu.account_number ? (
            <div className='inputError'>{t('edu_institution.main_err')}</div>
          ) : null}
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.account_number')}</label>
            <input
              disabled={disabled}
              type='text'
              name='account_number'
              value={edu.account_number ? edu.account_number : null}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.account_number')}
              style={errorEdu.account_number ? { borderColor: '#f00' } : {}}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  account_number: checkNumber(e.target.value)
                    ? e.target.value
                    : edu.account_number,
                });
                setErrorEdu({ ...errorEdu, account_number: false });
              }}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.bank_name')}</label>
            <input
              disabled={disabled}
              type='text'
              name='bank_name'
              value={edu.bank_name ? edu.bank_name : null}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.bank_name')}
              style={errorEdu.bank_name ? { borderColor: '#f00' } : {}}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  bank_name: e.target.value,
                });
                setErrorEdu({ ...errorEdu, bank_name: false });
              }}
            />
          </div>
        </div>
        <div className='col-md-6 col-xl-4'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.mfo')}</label>
            <input
              disabled={disabled}
              type='number'
              maxLength={'6'}
              name='mfo'
              value={edu.mfo ? edu.mfo : null}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.mfo')}
              style={errorEdu.mfo ? { borderColor: '#f00' } : {}}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  mfo:
                    e.target.value.length < 6 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.mfo,
                });
                setErrorEdu({ ...errorEdu, mfo: false });
              }}
            />
          </div>
        </div>
        <div className='col-md-6 col-xl-4'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.bank_inn')}</label>
            <input
              disabled={disabled}
              type='number'
              name='bank_inn'
              value={edu.bank_inn ? edu.bank_inn : null}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.bank_inn')}
              style={errorEdu.bank_inn ? { borderColor: '#f00' } : {}}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  bank_inn:
                    e.target.value.length < 10 && checkNumber(e.target.value)
                      ? e.target.value
                      : edu.bank_inn,
                });
                setErrorEdu({ ...errorEdu, bank_inn: false });
              }}
            />
            {errorEdu.bank_inn ? (
              <div style={{ color: 'red' }}>STIR 9 ta raqamdan iborat</div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      {disabled ? null : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <button
                className='btn btn-primary btn-sm'
                style={{ marginRight: '15px' }}
                onClick={() => {
                  setStep(step - 1);
                  window.scroll(0, 0);
                }}
              >
                Oldingi
              </button>
              <button className='btn btn-primary btn-sm' type='submit'>
                Keyingi
              </button>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default Step5;
