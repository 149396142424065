import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import PhoneInput from "./PhoneInput";
import PasswordInput from "./PasswordInput";
import {signIn} from "../../actions";
import Loading from './Loading';
import $ from 'jquery';
import history from '../../history';
import { useTranslation } from 'react-i18next';

const  SignIn = (props) => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [commonError, setCommonError] = useState('');
    const [remember, setRemember] = useState(true);
    useEffect(() => {
        if(props.sign_in.status === 'success'){
            $('#modalLogin').hide();
            $('.modal-backdrop').hide();
            $( "body" ).removeClass( "modal-open" );
            props.signInEnd();
            if(props.oldToken){
                history.goBack();
            }else{
                history.push('/dashboard/profile');
            }
        }
        if(props.sign_in.status === 'started'){
            setLoading(true);
        }else{
            setLoading(false);
            if(props.sign_in.status === 'error'){
                if(props.sign_in.error_msg==='401'){
                    setCommonError(t('sign_in.401'));
                }else{
                    setCommonError(props.sign_in.error_msg);
                }
            }
        }
    }, [props.sign_in]);
    const onSubmit = event => {
		event.preventDefault();
        let p=true, pass=true;
        if(phone.length!==12){
            setPhoneError(t('sign_in.phone_e'));
            p=false;
        }else{
            setPhoneError('');
            p=true;
        }
        if(password.length<8){
            setPasswordError(t('sign_in.password_e'));
            pass=false;
        }else{
            setPasswordError('');
            pass=true;
        }
        if(p&&pass){
            props.signIn({
                    name: phone,
                    password: password
                },
                remember
            )
        }
    }
    return (<>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('sign_in.title')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon icon-close"></span>
                    </button>
                </div>
                <div className="modal-body">
                <form onSubmit={onSubmit} noValidate>
                    <div className="form-group mb-3">
                        <label for="">{t('sign_in.phone')}</label>
                        <PhoneInput
                            inputClass="form-control"
                            onClickInput={()=>{ setPhoneError(""); setCommonError('');}}
                            setPhone={(phone)=>setPhone(phone)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label for="">{t('sign_in.password')}</label>
                        <div className="password-field-wrapper">
                            <PasswordInput
                                className="form-control"
                                setPassword={(p)=>setPassword(p)}
                                onClickInput={()=>{setPasswordError(""); setCommonError('');} }
                            />
                        </div>
                    </div>
                    <div className="form-group mt-4 mb-5">
                        <label className="checkbox-custom">
                            <input type="checkbox" name="remember" value={remember} onChange={()=>setRemember(!remember)} defaultChecked={true}/>
                            <p>{t('sign_in.remember')}</p>
                            <span className="checkmark"></span>
                        </label>
                        <div style={{color: 'red'}}>{commonError}</div>
                        <div style={{color: 'red'}}>{phoneError}</div>
                        <div style={{color: 'red'}}>{passwordError}</div>
                    </div>
                    {loading?<Loading />:
                    <button type="submit" className="btn btn-primary w-100"
                    >
                        {t('sign_in.btn')}
                    </button>
                    }
                    <div className="form-group mt-4 mb-5">
                        <p style={{color: '#26CAAC', cursor: 'pointer'}}
                            onClick={()=>props.actState(3)}
                        >{t('forgot.forgot')}</p>
                        {/* <p style={{color: '#26CAAC', cursor: 'pointer'}}
                            onClick={()=>props.actState(1)}
                        >{t('sign_in.sign_up')}</p> */}
                        
                    </div>
                </form>
                </div>
            </div>
  </>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        signIn: (data, remember)=>dispatch(signIn(data, remember)),
        signInEnd: ()=>dispatch({type: 'SIGN_STATUS', payload: { status: 'end'} })
    })
)(SignIn);