import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';

const Warning = () => {
    const warning = useSelector(state => state.warning);
    const dispatch = useDispatch();
    useEffect(() => {
        if(_.get(warning, 'status', false)) window.$('#WarningModal').modal('show');
        return () => window.$('#WarningModal').modal('hide');
    }, [warning]);
    const warningClose = () => {
        dispatch({
            type: 'SET_WARNING',
            payload: {status: false}
        });
        window.$('#WarningModal').modal('hide');
    }
    return(
        <div className="modal fade" id="WarningModal" tabindex="-1" role="dialog" aria-labelledby="WarningModalLabel" 
            data-backdrop="false" style={{background: 'rgba(0,0,0, .5)'}} aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ _.get(warning, 'title', '') }</h5> 
                    </div>
                    <div className="modal-body"  style={{textAlign: 'center'}}>
                        <div className="mb-4" dangerouslySetInnerHTML={{ __html: _.get(warning, 'text', '')}} /> 
                        <button className="btn btn-primary btn-sm" onClick={warningClose} >OK</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Warning;