import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Boolean = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('');
    const submitHandler = event => {
		event.preventDefault();
        if( _.get(survey, 'isRequired', false) &&   !_.get(result, 'value', '') ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', ''), type: survey.type });
        }
    }
    return(
            <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.bool') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            <div className="setting__buttons form-group my-2" style={{alignItems: 'center', justifyContent: 'right'}}>
                                { t('survey_c.off') }
                                <label className="switch mx-2">
                                    <input type="checkbox" checked={ _.get(result, 'value', 'off')==='on'?true:false}
                                        onChange={(e)=>{
                                            surveyChange( {id: survey.id, value: _.get(result, 'value', 'off')==='off'?'on':'off'} );
                                            setError('');
                                        }}
                                    />
                                    {_.get(result, 'value', '')?null:
                                    <style jsx>{`
                                        .switch .slider:before{
                                            left: 15px
                                        }
                                    `}</style>}
                                    <span className="slider round"></span>
                                </label>
                                { t('survey_c.on') }
                            </div>

                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default Boolean;