import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../../helpers/httpClient';
import Loading from './Loading';

const  EduInstitutionStatusModal = props => {
    const { t } = useTranslation();
    const [error, setError] = useState({});
    const [eduLoading, setEduLoading] = useState(false);
    const {edu, eduSetStatus} = props;
    const onSubmit = event => {
        event.preventDefault();
        setEduLoading(true);
        const formData = new FormData(event.target);
		const status = formData.get('status');
		const desc = formData.get('desc');
        let validation = true, e_d = {};
        if(parseInt(status)<1||parseInt(status)===edu.status){ validation = false;  e_d = {...e_d, status: true}; }
        if(!desc){ validation = false;  e_d = {...e_d, desc: true};}

        if(validation){
            getInstance()
            .post('/api/v1/edu-institution/set-status', formData)
            .then(response => {
                if (response.status === 200) {
                    try {
                        const { data } = response;
                        eduSetStatus(edu.id, parseInt(status));
                        window.$('#modalEduInstitutionStatus').modal('hide');
                        setEduLoading(false);
                    } catch {
                        setEduLoading(false);
                    }
                }
            })
            .catch(error => {  setEduLoading(false); });
        }else{
            setError(e_d);
            setEduLoading(false);
        }
    }
    return (
    <div className="modal fade" id="modalEduInstitutionStatus" tabindex="-1" role="dialog" aria-labelledby="modalEduInstitutionStatusTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t('edu_list.edu_check')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="icon icon-close"></span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={edu.id} name="id"/>
                        <div className="form-group">
                            <label for=""> {t('edu_list.status')} </label>
                            <select className="form-control custom-select" name="status"
                                style={error.status?{border: '2px solid #f00'}:{}}
                                onChange={()=>setError({...error, status: false})}
                            >
                                <option value={0} selected={edu.status===0} > {t('edu_list.no_check')}</option>
                                <option value={2} selected={edu.status===2} > {t('edu_list.check')}</option>
                                <option value={1} selected={edu.status===1} > {t('edu_list.succes')}</option>
                                <option value={3} selected={edu.status===3} > {t('edu_list.failed')}</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for=""> {t('edu_list.pratokol')} (.pdf)</label>
                            <input type='file' name="file" accept=".pdf"/>
                        </div>
                        <div className="form-group">
                            <label for=""> {t('edu_list.status_desc')} </label>
                            <textarea
                                style={error.desc?{border: '2px solid #f00'}:{}}
                                className="form-control"
                                onChange={()=>setError({...error, desc: false})}
                                rows="4"
                                name="desc"
                            ></textarea>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            {eduLoading?<Loading/>:
                            <button type="submit" className="btn btn-primary btn-sm">{t('edu_list.save')}</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    );
}
export default EduInstitutionStatusModal;