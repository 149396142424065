import React from 'react';
import { useTranslation } from 'react-i18next';

const Result = (props) => {
	const { t } = useTranslation();
    return(
    <div style={{textAlign: 'center', padding: '10% 0'}}>
        <div><img style={{maxWidth: 200}} src='/images/survey/reset.svg' alt='add' /></div>
        <span className="btn btn-primary  btn-sm  mb-3"
            onClick={()=>props.reSet()}
        >
            <span className="icon icon-refresh mr-2"></span>
            <span>{ t('survey_i.restart_test') }</span>
        </span>
    </div>
    )
}
export default Result;