import React from 'react';
import JoditTextShow from "./JoditTextShow";
import _ from 'lodash';

const LessonTestSingle = (props) => {
    const { question, number, answersList, setAnswersList, testResultShow, testResult } = props;
    return (<>
        <div className="test__item card m-0" >
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ question.question } />
                </div>
                <ul className="test__item-answers">
                    {_.get(question, 'vanswers', []).map((answer, index)=>(
                        <li key={index}>
                            <label className="radio-custom">
                                <input type="radio" name={ 'answers['+answer.question_id+']'} value={answer.id} 
                                    checked={_.get(answersList, question.id, 0)===answer.id?true:false}
                                    onChange={()=>setAnswersList( {...answersList, [question.id]: answer.id} )}
                                    disabled={testResultShow}
                                />
                                <JoditTextShow text={ answer.answer } />
                                {/* {_.get(answersList, question.id, 0)===answer.id && testResultShow?
                                    <span style={_.get(testResult, 'vquestions.is_completed', false)?{color: '#26CAAC'}:{color: 'red'}}>
                                        {_.get(testResult, 'vquestions.is_completed', false)?"Tog'ri "+_.get(testResult, 'score', 0)+' ball':'Xato'}
                                    </span>:null
                                } */}
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>);
}

export default LessonTestSingle;
