import React from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";


function DashWebinarPage() {
  return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                            />
                            <div className="dashboard-title d-lg-none">
                                <h5>Концепция воспринимаемой ценности концепция воспринимаемой цен</h5>
                            </div>


                            <div className="row row-dashboard-sidebar dashboard-webinar-details">
                                <div className="col-md-8 col-lg-7 col-xl-8 order-2 order-md-1">

                                    {/* <!-- This title hidden on mobile --> */}
                                    {/* <!-- Dashboard Title block hidden with specific class on desktop ( line 122 ) --> */}
                                    <h5 className="d-none d-lg-block mb-4">Концепция воспринимаемой ценности концепция воспринимаемой цен</h5>

                                    <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия.</p>
                                    <p>По своей сути рыбатекст является альтернативой традиционному lorem ipsum, который вызывает у некторых людей недоумение при попытках прочитать рыбу текст. В отличии от lorem ipsum, текст рыба на русском языке наполнит любой макет непонятным смыслом и придаст неповторимый колорит советских времен.</p>
                                </div>
                                <div className="col-md-4 col-lg-5 col-xl-4 order-1 order-md-2">
                                    <div className="webinar-details__card">
                                        <img src="/images/webinars/webinar-1.jpg" alt="Webinar"/>
                                        <div className="webinar-details__card-caption">
                                            <div>
                                                <p><span className="icon icon-video"></span>24.12.2020 в 20:30</p>
                                                <p>Профессор Махмудов Азиз</p>
                                            </div>
                                            <button type="button" className="btn btn-secondary-light">
                                                <span className="icon icon-link"></span>Скопировать
                                            </button>
                                            <a href="#" className="btn btn-primary">
                                                <span className="icon icon-play"></span>Смотреть
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>


  </Layout>);
}
export default DashWebinarPage;