import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logic from './Logic/Logic';

const Sorttable = props => {
	const { t } = useTranslation();
	const [language, setLanguage] = useState(false);
	const [alphabet] = useState([
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
	]);
	const { survey, surveys, number, setSurveyItem, deleteSurvey } = props;
	const changeItem = (id, name, value) => {
		let l = [];
		survey.choices.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, [name]: value });
			} else {
				l.push(item);
			}
		});
		setSurveyItem({ ...survey, choices: l });
	};
	const addItem = () => {
		const last_id = survey.choices.length ? survey.choices[survey.choices.length - 1].id : 0;
		setSurveyItem({
			...survey,
			choices: [
				...survey.choices,
				{
					id: last_id + 1,
					label_uz: 'item_uz_' + (last_id + 1),
					label_ru: 'item_ru_' + (last_id + 1),
				},
			],
		});
	};
	const deleteItem = id => {
		let l = [];
		survey.choices.forEach(item => {
			if (item.id !== id) {
				l.push(item);
			}
		});
		setSurveyItem({ ...survey, choices: l });
	};
	return (
		<div className="test__item card mt-3">
			<div className="card-body row">
				<div className="col-12">
					<h5 style={{ display: 'inline' }}>
						{number}-{t('survey_c.question')} | {t('survey_c.id')}: {survey.id}
					</h5>
					<span
						className="button"
						style={{
							color: '#f00',
							margin: 'auto 10px',
							cursor: 'pointer',
							background: 'rgba(137, 141, 166, 0.1)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							float: 'right',
							width: 32,
							height: 32,
							borderRadius: 8,
						}}
						onClick={() => deleteSurvey()}>
						<span className="icon icon-remove"></span>
					</span>
					<span
						className="button"
						style={{
							color: '#898DA6',
							margin: 'auto 10px',
							cursor: 'pointer',
							float: 'right',
							background: 'rgba(137, 141, 166, 0.1)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: 32,
							height: 32,
							borderRadius: 8,
						}}
						onClick={() => props.setSurveyTest(survey)}
						data-toggle="modal"
						data-target="#modalSurveyTest">
						<span className="icon icon-eye"></span>
					</span>
				</div>
				<div className="col-12">
					<h5>
						{t('survey_c.question-type')}
						{t('survey_c.options')}
					</h5>
				</div>
				<div className="col-12 my-5 d-flex">
					<div style={{ margin: '0 20px 0 0' }}>
						<label for=""> {t('survey_c.type')} </label>
						<select
							className="form-control custom-select"
							name="inputType"
							value={survey.selectType}
							onChange={e => {
								setSurveyItem({ ...survey, selectType: e.target.value });
							}}>
							<option value="single">{t('survey_c.Single')}</option>
							<option value="multiple">{t('survey_c.Multiple')}</option>
						</select>
					</div>
					<div className="setting__buttons " style={{ justifyContent: 'right', flexDirection: 'column' }}>
						<label>{t('survey_c.isRequired')}</label>
						<div style={{ display: 'flex', margin: '5px 0 0 0 ' }}>
							<label className="checkbox-custom" style={{ margin: '0 10px 0 0 ' }}>
								<input
									type="checkbox"
									checked={survey.isRequired}
									name="input"
									onChange={e => {
										setSurveyItem({ ...survey, isRequired: e.target.checked });
									}}
								/>
								<span className="checkmark" style={{ width: 24, height: 24 }}></span>
							</label>
							<span style={{ color: '#0D2E69', fontWeight: 500, fontSize: 14 }}>
								{t('survey_c.required')}
							</span>
						</div>
					</div>
				</div>
				<div className="col-12">
					<button
						className="btn btn-sm btn-light"
						onClick={() => setLanguage(false)}
						style={{
							borderRadius: '8px 8px 0 0',
							color: '#0D2E69',
							border: 'none',
							fontWeight: 500,
							background: language ? 'white' : 'rgba(137, 141, 166, 0.08)',
						}}>
						{t('survey_c.uzbek')}
					</button>
					<button
						className="btn btn-sm btn-light"
						onClick={() => setLanguage(true)}
						style={{
							borderRadius: '8px 8px 0 0',
							color: '#0D2E69',
							border: 'none',
							fontWeight: 500,
							background: language ? 'rgba(137, 141, 166, 0.08)' : 'white',
						}}>
						{t('survey_c.russian')}
					</button>
					<div
						className="form-group p-5"
						style={{
							background: 'rgba(137, 141, 166, 0.08)',
							borderRadius: language ? 8 : '0 8px 8px 8px',
						}}>
						<p style={{ fontWeight: 500, fontSize: 12 }}>
							{t('survey_c.question-name')}
							{survey.isRequired ? <b>* </b> : null}
						</p>
						{language ? (
							<input
								type="text"
								value={survey.name_ru}
								style={{
									background: '#FFFFFF',
									height: 40,
									borderRadius: 8,
									width: '100%',
									border: 'none',
									padding: '0 0 0 16px',
								}}
								onChange={e => {
									setSurveyItem({ ...survey, name_ru: e.target.value });
								}}
							/>
						) : (
							<input
								type="text"
								value={survey.name_uz}
								style={{
									background: '#FFFFFF',
									height: 40,
									borderRadius: 8,
									width: '100%',
									border: 'none',
									padding: '0 0 0 16px',
								}}
								onChange={e => {
									setSurveyItem({ ...survey, name_uz: e.target.value });
								}}
							/>
						)}
						<p style={{ fontWeight: 500, fontSize: 12, margin: '10px 0' }}>
							{t('survey_c.question-options')}
						</p>
						<ul className="test__item-answers my-2">
							{survey.choices.map((answer, index) => (
								<li
									key={index}
									style={{
										height: 40,
										width: '100%',
										borderRadius: 8,
										background: 'white',
										display: 'flex',
										alignItems: 'center',
									}}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											width: 'calc(100% - 40px)',
											height: '100%',
										}}>
										<h6 style={{ margin: '0 15px' }}>{alphabet[index]}</h6>
										{language ? (
											<input
												type="text"
												style={{ height: '100%', width: '100%', border: 'none' }}
												value={answer.label_ru}
												onChange={e => {
													changeItem(answer.id, 'label_ru', e.target.value);
												}}
											/>
										) : (
											<input
												type="text"
												style={{ height: '100%', width: '100%', border: 'none' }}
												value={answer.label_uz}
												onChange={e => {
													changeItem(answer.id, 'label_uz', e.target.value);
												}}
											/>
										)}
									</div>
									<span
										className="button"
										style={{
											color: '#f00',
											margin: 'auto 10px',
											cursor: 'pointer',
											width: 32,
											height: 32,
											borderRadius: 8,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
										onClick={() => deleteItem(answer.id)}>
										<span className="icon icon-remove"></span>
									</span>
								</li>
							))}
						</ul>
						<div>
							<button
								onClick={addItem}
								className="btn btn-sm mb-2 secondary"
								style={{ background: 'transparent' }}>
								<span className="icon icon-plus"></span> {t('survey_c.add')}
							</button>
						</div>
						{survey.isRequired ? (
							<>
								<p style={{ fontWeight: 500, fontSize: 12, margin: '10px 0' }}>
									{t('survey_c.text-error')}
								</p>
								{language ? (
									<input
										type="text"
										style={{
											height: 40,
											width: '100%',
											border: 'none',
											borderRadius: 8,
											padding: '0 0 0 16px',
										}}
										value={survey.error_ru}
										onChange={e => {
											setSurveyItem({ ...survey, error_ru: e.target.value });
										}}
									/>
								) : (
									<input
										type="text"
										style={{
											height: 40,
											width: '100%',
											border: 'none',
											borderRadius: 8,
											padding: '0 0 0 16px',
										}}
										value={survey.error_uz}
										onChange={e => {
											setSurveyItem({ ...survey, error_uz: e.target.value });
										}}
									/>
								)}
							</>
						) : null}
					</div>
				</div>
				<div className="col-12">
					<Logic
						survey={survey}
						surveys={surveys}
						setLogicList={logic => setSurveyItem({ ...survey, logic: logic })}
					/>
				</div>
			</div>
		</div>
	);
};
export default Sorttable;
