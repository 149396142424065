import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { API_URL, FILE_URL } from "../../helpers/api";
import { getInstance } from "../../helpers/httpClient";
import PropTypes from 'prop-types';
import videojs from 'video.js';
import {getToken} from '../../helpers/tokenStorge';
import * as m3u8Parser from  'm3u8-parser';
import "videojs-progress-marker/lib";
import "videojs-progress-marker/lib/style/";
import { useTranslation } from 'react-i18next';
import history from '../../history';
import Loading from './Loading';

require('@silvermine/videojs-quality-selector')(videojs);

const usePlayer = ({ src, type, markers, controls, autoplay, }) => {
	const options = {
		// poster: "/images/courses/course.jpg",
		withCredentials: true,
		fill: true,
		fluid: true,
		preload: 'auto',
		html5: {
			hls: {
				enableLowInitialPlaylist: false,
				overrideNative: true,
				smoothQualityChange: true,
			},
		},
	};
	const videoRef = useRef(null);
  	const [player, setPlayer] = useState(null);
  	useEffect(() => {
		videojs.Hls.xhr.beforeRequest = options => {
			var headers = options.headers || {}
			headers['Authorization'] = `Bearer  ${getToken()}`
			options.headers = headers
			return options
		}
		let vjsPlayer = videojs(videoRef.current, {
			...options,
			controls: controls,
			controlBar: {
				'liveDisplay': true,
				'pictureInPictureToggle': false
			},
			autoplay: autoplay,
			sources: src
		});
		vjsPlayer.controlBar.addChild('QualitySelector');
		
		vjsPlayer.markers({
			markerStyle: {
			   'position': 'absolute',
			   'width':'9px',
			   'height': '9px',
			   'border-radius': '40%',
			   'background-color': '#26caac',
			   'top': '-3px',
			   'margin-left': '-4.3px !important'
			},
			markerTip:{
			   display: false
			},
			breakOverlay:{
			   display: false
			},
			markers: markers
		});
		// vjsPlayer.ready(()=>{
		// 	console.log('=>', vjsPlayer.currentTime());
		// });
		setPlayer(vjsPlayer);
		return () => {
			if (player !== null) {
			player.dispose();
			}
		};
  	}, []);
	useEffect(() => {
		if (player !== null) {
			player.src({ src });
		}
	}, [src]);
  	return videoRef;
};
const VideoPlayerBody = ({ src, type, video_question, section, course, lesson_id, time, controls, autoplay, is_update, updateTime, update_question_time_status, duration_video }) => {
	const { t } = useTranslation();
	let markers = [];
	video_question.forEach( (item) => {
		markers.push( {time: item.time-2,} );
	});
	const playerRef = usePlayer({ src, type, markers, controls, autoplay });
	const [currentTime, setCurrentTime] = useState(null);
	const [minut, setMinut] = useState(0);
	const [second, setSecond] = useState(0);
	const [timeError, setTimeError] = useState('');
	const [loading, setLoading] = useState(false);
	useEffect(() => {        
		if(update_question_time_status.status === 'started'){
			setLoading(true);
		}
		if(update_question_time_status.status === 'success'){
			setLoading(false);
			history.push(`/dashboard/copyrights/course-items/${course}/section/${section}/lesson/video/questions/update/${minut*60+second}`);
		}
		if(update_question_time_status.status === 'error'){
			setLoading(false);
		}
	}, [ update_question_time_status ] );

	useEffect(() => {
		if (playerRef) {
		  const player = videojs(playerRef.current, playerRef, () => {
			player.on("timeupdate", () => {
				setCurrentTime(player.currentTime());
				setMinut(parseInt(Math.floor(player.currentTime())/60));
				setSecond(Math.floor(player.currentTime())%60);
				setTimeError('');
			});
			// player.play();
			player.currentTime(time);
			// player.pause();

		  });
		}
	  }, []);
	  const onMinutChange = (e) => {
		setTimeError('');
		  const v = e.target.value;
		//   console.log(v, parseInt(v), parseInt(''), 5);
		  if( (Number.isInteger( parseInt(v) )&& parseInt(v)>=0) || v==='' ){
			  // setCurrentTime(v*60+secunt);
			  setMinut(parseInt(v));
		  }
	  }
	  const onSecondChange = (e) => {
		setTimeError('');
		const v = e.target.value;
		  if( (Number.isInteger(parseInt(v))&&parseInt(v)>=0) || v==='' ){
			  // setCurrentTime(v*60+secunt);
			  if(v==='' || parseInt(v)<60) setSecond( parseInt(v) );
			  else setSecond(59);
		  }
	  }
	  const onBlurTime = () => {
		if(duration_video>=minut*60+second){
			const player = videojs(playerRef.current, playerRef,()=>{
				player.pause();
				player.currentTime(minut*60+second);
			});
		}
	  }

	  const onSave = () => {
		let check_marker = true;
		video_question.forEach( (item) => {
			if( (item.time < currentTime && item.time+59 > currentTime) || (item.time-59 < currentTime && item.time > currentTime) ) 
				check_marker = false;
		});
		if(check_marker && duration_video>=minut*60+second){
			if(is_update) {
				updateTime(minut*60+second);
			}else 
				history.push(`/dashboard/copyrights/course-items/${course}/section/${section}/lesson/video/questions/update/${ minut*60+second }`);
			// }else history.push(`/dashboard/copyrights/course-items/${course}/section/${section}/lesson/video/questions/update/${ parseInt(currentTime) }`);
		}else{
			setTimeError(t('teacher_lesson.questions_err'));
		}
	  }
	return (<>
		<div className="video">
			<div data-vjs-player>
				<video ref={playerRef} className="video-js"  />
			</div>
		</div>
		<div className="form-group" style={{ display: 'flex', alignItems: 'center', margin: '10px 0 0 0' }}>
			<label for="" style={{display: 'inline', margin: 10 }}>{t('teacher_lesson.minute')}
				<input type="number" className="form-control " value={minut}  style={{ width: 80}}
					onChange={e=>onMinutChange(e)} onBlur={e=>onBlurTime()} onClick={e=>onBlurTime()}
				/> 
			</label>
			<label for=""  style={{display: 'inline', margin: 10 }}> {t('teacher_lesson.second')}
				<input type="number" className="form-control " value={second}  style={{ width: 80}}
					onChange={e=>onSecondChange(e)} onBlur={e=>onBlurTime()} onClick={e=>onBlurTime()}
				/>
			</label>
			{loading?<Loading/>
			:<button className="btn btn-primary btn-sm" style={{marginTop: 18}} onClick={()=>onSave()}>{t('teacher_video.save')}</button>}
		</div>
		<div style={{color: 'red'}} >{timeError}</div>
	</>);
};
VideoPlayerBody.propTypes = {
	src: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	video_question: PropTypes.array,
	section: PropTypes.string,
	course: PropTypes.string,
	lesson_id: PropTypes.number,
	time: PropTypes.number,
	controls: PropTypes.bool,
	autoplay: PropTypes.bool,
	is_update: PropTypes.bool,
	updateTime: PropTypes.func,
	update_question_time_status: PropTypes.string,
	duration_video: PropTypes.number
};
VideoPlayerBody.defaultProps = {
	controls: true,
	autoplay: false,
	video_question: [],
	section: '',
	lesson_id: 0,
	updateTime: false,
	update_question_time_status: 'started'
};

const  VideoQuestionPlayer = (props) => {
	const {course_lesson, course, section, time, updateTime, is_update, update_question_time_status }  = props;
	const id =  _.get(course_lesson, 'id');
	const is_enc =  _.get(course_lesson, 'is_enc');
	const file =  _.get(course_lesson, 'file');
	const video_question =  _.uniqBy(_.get(course_lesson, 'questions', []), 'time');
	const duration_video =  _.get(course_lesson, 'duration_video', 0);
	
	const [src, setSrc] = useState([]);
	useEffect(()=>{
		if(is_enc===1){
			getInstance()
			.get(`${API_URL}/api/v1/lesson/getVideo/${id}/playlist.m3u8`)
				.then(response => {
					if (response.status === 200) {
						const { data } = response;          
						let parser = new m3u8Parser.Parser();
						parser.push(data);
						parser.end();
						let parsedManifest = parser.manifest;
						let s = [];
						parsedManifest.playlists.map((item)=>(
							s.push({
								src: `${API_URL}/api/v1/lesson/getVideo/${id}/${item.uri}`,
								type: 'application/x-mpegURL',
								label: item.attributes.NAME + 'p'
							})
						));
						setSrc(s);
					}
				})
				.catch(error => console.log(error));
		}else{
		setSrc([{
			src: FILE_URL + file,
			type: 'video/mp4',
			label: '720p'
		}]);
		}
	},[]);
	return (<>{src.length>0?
		<VideoPlayerBody 
			src={src} 
			type={'application/x-mpegURL'} 
			video_question={video_question}
			section={section}
			course={course}
			lesson_id={id}
			time={time}
			is_update={is_update}
			updateTime={t=>{updateTime(t);}}
			update_question_time_status={update_question_time_status}
			duration_video={duration_video}
		/>:null}
	</>)
}
export default VideoQuestionPlayer;