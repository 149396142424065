import React, {useState, useEffect} from 'react';
import BukharaMap from './BukharaMap';
import KarakalpakstanMap from './KarakalpakstanMap';
import AndijanMap from './AndijanMap';
import JizzakhMap from './JizzakhMap';
import KashkadaryaMap from './KashkadaryaMap';
import NavoiyMap from './NavoiyMap';
import NamanganMap from './NamanganMap';
import SamarqandaMap from './SamarqandaMap';
import SurxondaryoMap from './SurxondaryoMap';
import SirdaryoMap from './SirdaryoMap';
import TashkentMap from './TashkentMap';
import FargonaMap from './FargonaMap';
import XorazmMap from './XorazmMap';
import TashkentCityMap from './TashkentCityMap';
import UzbMap from './UzbMap';
import _ from 'lodash';

const Map = props => {
	const { map_id, map_key, data, map_select = '', wel='', exceptClick = () => {return 0}, exceptEnter = () => {return 0}, exceptLeave = () => {return 0} } = props;
    const [show_tooltip, setShow_tooltip] = useState('');
    const [tooltip, setTooltip] = useState('');
    const [active_map, setActive_map] = useState({});
    useEffect(() => {
        let show_map = {}, new_data = [];
        if(map_id===0) show_map=UzbMap;
        if(map_id===41) show_map=BukharaMap;
        if(map_id===100) show_map=KarakalpakstanMap;
        if(map_id===233) show_map=AndijanMap;
        if(map_id===53) show_map=JizzakhMap;
        if(map_id===67) show_map=KashkadaryaMap;
        if(map_id===82) show_map=NavoiyMap;
        if(map_id===93) show_map=NamanganMap;
        if(map_id===94) show_map=SamarqandaMap;
        if(map_id===95) show_map=SurxondaryoMap;
        if(map_id===96) show_map=SirdaryoMap;
        if(map_id===97) show_map=TashkentMap;
        if(map_id===98) show_map=FargonaMap;
        if(map_id===99) show_map=XorazmMap;
        if(map_id===101) show_map=TashkentCityMap;

        if(!show_map.map_data) show_map=UzbMap;
        if(data){
            _.get(show_map, 'map_data', []).forEach(  item  => {
                const d =  _.get( data, _.findKey(data, ['slug', item.slug]), {})
                new_data.push( { ...item, ...d, value: _.get( d, 'value',  0 ) + wel } );
            });
            setActive_map( {...show_map, map_data: new_data} );
        }else{
            setActive_map( show_map );
        }
    }, [map_id]);
	// const map_key = '__As5ds5ds';
    const handleMove = (e) => {
        const clientWidth = _.get(e, 'target.parentElement.clientWidth', 0);
        const clientHeight = _.get(e, 'target.parentElement.clientHeight', 0);
        const x = e.nativeEvent.layerX;
        const y = e.nativeEvent.layerY;
        if(x<clientWidth/2){
            document.getElementById("map_tooltip_"+map_key).style.left = (x+20) + "px";
            document.getElementById("map_tooltip_"+map_key).style.right = null;

        }else{
            document.getElementById("map_tooltip_"+map_key).style.right = (clientWidth-x+10) + "px";
            document.getElementById("map_tooltip_"+map_key).style.left = null;
        }
        if(y<clientHeight/2){
            document.getElementById("map_tooltip_"+map_key).style.top = (y+20) + "px";
            document.getElementById("map_tooltip_"+map_key).style.bottom = null;

        }else{            
            document.getElementById("map_tooltip_"+map_key).style.bottom = (clientHeight-y+10) + "px";
            document.getElementById("map_tooltip_"+map_key).style.top = null;
        }
    }
	return (
        <div className="region_maps">
                <svg className="svg_target" width="100%" height="100%" viewBox={ _.get( active_map, 'viewBox', '' ) } fill="none" xmlns="http://www.w3.org/2000/svg"
                    style={{maxHeight: 452}}
                >
					{_.get(active_map, 'map_data', []).map((item, index)=>(<>
						<path id={ item.slug+map_key }
							onMouseMove={(e) => {handleMove(e); exceptEnter(item);  setShow_tooltip( item.slug+map_key ); setTooltip( item.city_name + (item.value?': '+item.value:'') ); } }
							onMouseLeave={() => {exceptLeave(); setShow_tooltip('');}}
                            onClick={()=>exceptClick(item)}
							style= { map_select===item.slug?{fill: '#4B4BFF'}:show_tooltip===(item.slug+map_key)? { fill: '#26CAAC' }:{ fill: "#EDEEF2"} } stroke="white" strokeWidth="0.8"
							d={item.data}
						/>
						<text x={item.textX+"%"} y={item.textY+"%"} dominantBaseline="middle" textAnchor="middle"
							onMouseMove={(e) => {handleMove(e); exceptEnter(item); setShow_tooltip( item.slug+map_key ); setTooltip( item.city_name + (item.value?': '+item.value:'') ); }}
							onMouseLeave={() => {exceptLeave(); setShow_tooltip('')}}
                            onClick={()=>exceptClick(item)}
						>
						    {item.value}
						</text>
					</>))}
                </svg>
            <span id={"map_tooltip_"+map_key} className="map_tooltip">
            {show_tooltip?
                <span className='map_tooltip_item' >
                    {tooltip}
                </span>
            :null}
            </span>
        </div>
	);
};
export default Map;