import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import SelectReact from 'react-select';
import _ from 'lodash';

const Select = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('');
    const submitHandler = event => {
		event.preventDefault();
        if( _.get(survey, 'isRequired', false) &&   _.get(result, 'value', []).length===0 ){
            setError( _.get(survey, `error_${lan}`, '') )
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', []), type: survey.type });
        }
    }
    let options = [];
    let values = [];
    _.get(survey, 'choices', []).forEach(item=>{
        options.push({value: item.id, label: _.get(item, `label_${lan}`, '')});
        if(_.get(result, 'value', []).includes(item.id)){
            if(_.get(survey, 'selectType', 'single')==='single'){
                values = {value: item.id, label: _.get(item, `label_${lan}`, '')};
            }else{
                values.push({value: item.id, label: _.get(item, `label_${lan}`, '')});
            }
        }
    });
    const MultipleValue = (vv) => {
        let l = [];
        vv.forEach(s=>{
            l.push(s.value)
        });
        return l;
    }
    return(
        <form onSubmit={submitHandler}>
            <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.select') } </h4>
            <div className="card">
                <div className="card-body">
                    <div className="form-group m-0">
                        <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                            {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                        </label>
                        <div>
                            <SelectReact
                                value={ values }
                                onChange={(select)=>{
                                    if(_.get(survey, 'selectType', 'single')==='single'){
                                        surveyChange( { id: survey.id, value: [ select.value ] } )
                                    }else{
                                        surveyChange( { id: survey.id, value: MultipleValue(select)  } )
                                    }
                                    setError('');
                                }}
                                options={options}
                                isMulti ={_.get(survey, 'selectType', 'single')==='single'?false:true}
                                // closeMenuOnSelect={false}
                                placeholder = { 'Tanlang' }
                                className="custom-react-select"
                            />
                        </div>
                        <div style={{ color: 'red' }}>{error}</div>
                    </div>
                </div>
            </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
        </form>
    )
}
export default Select;