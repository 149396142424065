import { useState, useEffect } from "react";
import Layout from "./Layout"
import DashSideBar from "./sections/DashSideBar"
import Navigation from "./sections/Navigation"
import { useTranslation } from 'react-i18next';
import { getInstance } from "../helpers/httpClient";
import Loading from "./sections/Loading";
import _ from 'lodash'
import Moment from "react-moment";
import { useSelector } from "react-redux";

const DashRiyeester = (props) => {
  const profile = useSelector(state => state.profile)
  const [riyesters, setRiyesters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(1);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');
  const [cites, setCites] = useState([]);
  const [city, setCity] = useState('');
  const [region_e, setRegionE] = useState('');
  const [city_e, setCityE] = useState('');
  const [edus, setEdus] = useState([]);
  const [edu, setEdu] = useState(1);
  const [edu_e, setEdu_e] = useState('');
  const [edu_l, setEdu_l] = useState(false);


  const { t } = useTranslation();


  useEffect(() => {
    getRiyester();
    getRegions();
    getEdus();
  }, [])


  const getRiyester = () => {
    setLoading(true)

    getInstance()
      .get('/api/v1/profil/choise-education')
      .then(res => {
        if (res.status === 200) {
          try {
            const { data } = res;
            setRiyesters(data);
            setType(_.get(data, 'data[0].eduinstitution.is_online', 1) === 1 ? 1 : 2)
            setRegion(_.get(data, 'data[0].eduinstitution.region.id'))
            getCites(
              _.get(data, 'data[0].eduinstitution.region.id')
            )
            setCity(
              _.get(data, 'data[0].eduinstitution.city.id')
            )
            setEdu(_.get(data, 'data[0].eduinstitution_id'), '')
            getEdus(
              _.get(data, 'data[0].eduinstitution.is_online', 1) === 1 ? 1 : 2,
              _.get(data, 'data[0].eduinstitution.region.id'),
              _.get(data, 'data[0].eduinstitution.city.id')
            )
            setLoading(false);
          } catch (error) {
            setRiyesters([]);
            setLoading(false);
          }
        }
      })
      .catch(error => {
        setRiyesters([]);
        setLoading(false);
      })
  }


  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);

    let v = true;
    if (type === 2) {
      if (!region) {
        setRegionE(t('inquiry.region'));
        v = false;
      }
      if (!city) {
        setCityE(t('inquiry.city'));
        v = false;
      }
    }
    if (edu < 1) {
      v = false;
      setEdu_e(t('select_edu.edus_e'));
    }


    if (v) {
      getInstance()
        .post(`/api/v1/profil/user-education`, { eduinstitution_id: edu, })
        .then(res => {
          setLoading(false);
          const { data } = res;
          if (data.status === 1) {
            window.$('#riyesterModal').modal('hide');
            const k = _.get(riyesters, 'data[0].date');
            let l = riyesters.data;
            l.shift();
            let e = {};
            edus.forEach(item => {
              if (edu === _.get(item, 'id', 0)) {
                e = item;
              }
            });
            const d = {
              ...riyesters, data: [{
                date: k,
                eduinstitution: e,
                eduinstitution_id: edu
              }, ...l]
            }
            setRiyesters(d);
            setLoading(false)
          }
        })
        .catch(
          setLoading(false)
        );
    } else {
      setLoading(false);
    }
  }

  const getEdus = (is_online = 1, r = 0, c = 0) => {
    setEdu_l(true);
    getInstance()
      .get(`/api/v1/edu-institution?per_page=100&status=1&position=${profile.position_id}&${is_online === 1 ? 'is_online=1' : `is_online=0&region_id=${r}&city_id=${c}`}`)
      .then(response => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
          setEdu_l(false);
          setEdus(data ? data.data : []);
          } catch {
            setEdu_l(false);

          }
        }
      })
      .catch(error => { 
          setEdu_l(false);
      });
  }
  const getRegions = () => {
    getInstance()
      .get(`/api/v1/edu-institution/regions`)
      .then(response => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            setRegions(data ? data : []);
          } catch {

          }
        }
      })
      .catch(error => { });
  }
  const getCites = (id) => {
    getInstance()
      .get(`/api/v1/edu-institution/regions?parent_id=${id}`)
      .then(response => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            setCites(data ? data : []);
          } catch {

          }
        }
      })
      .catch(error => { });
  }

  return (
    <>
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('riyester.title')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                      ]}
                    />
                    <div className="dashboard-title">
                      <h5 className="mb-3">{t('riyester.title')}</h5>
                      <p className="mb-3">
                        {t('riyester.message')}
                      </p>
                      {
                        loading ? (
                          <Loading />
                        ) : (
                          <>
                            {_.get(riyesters, 'data', []).map((item, index) => (

                              <div className="card card--shadow" key={index}>
                                <div className="card-body p-3">
                                  <div className="d-flex justify-content-between align-items-baseline h-100 w-100">
                                    <div className="mr-4">
                                      <p className=""> {`${t('riyester.year')}: `}
                                        <Moment date={item.date} format="YYYY" />
                                      </p>
                                      <p>
                                        {t('riyester.type')}: {_.get(item, 'eduinstitution.is_online', 0) === 1 ? "Online" : "Offline"}
                                      </p>
                                      <p className="">
                                        {t('riyester.prof_department')}: {_.get(item, 'eduinstitution.name')}
                                      </p>
                                      {_.get(item, 'eduinstitution.is_online', 0) === 1 ?
                                        ''
                                        :
                                        <p className="mb-0">
                                          {t('riyester.address')}: {_.get(item, 'eduinstitution.region.name')}, {_.get(item, 'eduinstitution.city.name')}
                                        </p>
                                      }
                                    </div>
                                    <div className="">
                                      {
                                        _.get(riyesters, 'update_user_education', false) && index === 0
                                          ?
                                          <button className="btn btn-sm btn-secondary-light" data-toggle="modal" data-target="#riyesterModal">
                                            <span className="icon icon-edit"></span>
                                          </button>
                                          :
                                          ""
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <div className="modal fade" id="riyesterModal" tabindex="-1" role="dialog" aria-labelledby="riyesterModalLabel" aria-hidden="true"
          data-backdrop="false" style={{ background: 'rgba(0,0,0, .5)' }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('select_edu.edu_select')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="icon icon-close"></span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <label for="region_id"> {t('select_edu.type')} </label>
                    <select
                      id="type_id"
                      className="form-control custom-select"
                      value={type}
                      onChange={(e) => {
                        setType(parseInt(e.target.value)); getEdus(parseInt(e.target.value), region, city); setRegion(''); setCity(''); setEdu('');
                      }}
                      name="type_id">
                      <option value={1}>{t('edu_institution.online')}</option>
                      <option value={2}>{t('edu_institution.offline')}</option>
                    </select>
                  </div>
                  {type === 2 ? <>
                    <div className="form-group">
                      <label for="region_id"> {t('inquiry.region')} </label>
                      <select
                        id="region_id"
                        className="form-control custom-select"
                        value={region}
                        onChange={(e) => { getCites(e.target.value); setRegion(e.target.value); setCity(''); setRegionE(''); }}
                        name="region_id">
                        <option value="" selected>{t('inquiry.region')}</option>
                        {regions.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.name}  ({item.edu_institution_address_region_count} {t('course_view.ta')})
                          </option>
                        ))}
                      </select>
                      <div style={{ color: 'red' }}>{region_e}</div>
                    </div>
                    {region ?
                      <div className="form-group">
                        <label for="city_id"> {t('inquiry.city')} </label>
                        <select
                          id="city_id"
                          className="form-control custom-select"
                          value={city}
                          onChange={(e) => { setCity(e.target.value); setCityE(''); getEdus(type, region, e.target.value); setEdu(''); }}
                          name="city_id">
                          <option value="" selected>{t('inquiry.city')}</option>
                          {cites.map((item, index) => (
                            <option value={item.id} key={index}>
                              {item.name}  ({item.edu_institution_address_city_count} {t('course_view.ta')})
                            </option>
                          ))}
                        </select>
                        <div style={{ color: 'red' }}>{city_e}</div>
                      </div> : null}
                  </> : null}
                  {(type === 1 || (type === 2 && region && city)) ?
                    <div className="form-group">
                      <label for="edu"> {t('select_edu.edus')} </label>

                      {edu_l?<p  className="text-center mb-0">{t('chat.loading')}</p>:
                        edus.length?
                        <select
                          id="edu"
                          className="form-control custom-select"
                          value={edu}
                          onChange={(e) => { setEdu(parseInt(e.target.value)); setEdu_e(''); }}
                          name="edu">
                          <option value="" selected>{t('select_edu.edus')}</option>
                          {edus.map((item, index) => (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        :
                        <p className="text-center mb-0">{t('edu_list.no_data_check')}</p>
                      }

                      <div style={{ color: 'red' }}>{edu_e}</div>
                    </div> : null}

                  {edus.length
                    ?
                    <div className="test__buttons mb-5">
                      {loading ? (
                        <Loading size={5} />
                      ) : (
                        <div className="dashboard-buttons">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary mr-2">
                            {t('qualification.lan_btn')}
                          </button>
                        </div>
                      )}
                    </div> :
                    <div className="dashboard-buttons">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary mr-2" disabled>
                        {t('qualification.lan_btn')}
                      </button>
                    </div>
                  }

                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DashRiyeester;
