import axios from 'axios';
import { API_URL } from '../helpers/api';
import { getToken } from '../helpers/tokenStorge';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import Select from 'react-select';
import history from '../history';
import Loading from './sections/Loading';
import CheckRole from './sections/CheckRole';
import { roles } from '../helpers/roles';
import { getInstance } from '../helpers/httpClient';
import _ from 'lodash';

const ManagerDiagnostics = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState({ value: 1, label: "O'zbekcha" });
  const [position, setPosition] = useState([]);
  const [category, setCategory] = useState(null);
  const [categorys, setCategorys] = useState([]);
  const [position_type_e, setPositionTypeE] = useState('');
  const [position_e, setPositionE] = useState('');
  const [categorys_e, setCategorysE] = useState('');
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [position_type, setPositionType] = useState({});
  const [position_types, setPositionTypes] = useState([]);
  const [lans, setLans] = useState([]);

  useEffect(() => {
    getLan();
    getPositionType();
  }, []);
  const getLan = () => {
    setLans([{ value: 0, label: t('diagnostics.loading') }]);
    getInstance()
      .get('/api/v1/languages')
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            let l = [];
            data.forEach((item) => {
              l.push({ value: item.id, label: item.name });
            });
            setLans(l);
            // setLans([]);
          } catch {
            history.push('/dashboard');
          }
        }
      })
      .catch((error) => {});
  };

  const onSee = () => {
    if (position_type.value)
      if (position.length)
        if (category) {
          setLoading(true);
          let l = '&positions=';
          position.forEach((item) => {
            l += item.value + ',';
          });
          l = l.slice(0, -1);
          history.push(
            `/manager/diagnostics/test/1?lan=${language.value}&category=${category.value}${l}`
          );
        } else setCategorysE(t('diagnostics.cat_e1'));
      else setPositionE(t('diagnostics.position_e'));
    else setPositionTypeE(t('diagnostics.position_type_e'));
  };

  const catOption = () => {
    let list = [];
    categorys.forEach((cat) => {
      list.push({ value: cat.id, label: cat.name });
    });
    return list;
  };

  const getPositionType = () => {
    setPositionTypes([{ id: 0, name: t('diagnostics.loading') }]);
    getInstance()
      .get('/api/v1/profil/position-type')
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            let l = [];
            if (data.length) {
              data.forEach((item) => {
                l = [...l, { value: item.id, label: item.name }];
              });
            }
            setPositionTypes(l);
          } catch {
            setPositionTypes([]);
          }
        }
      })
      .catch((error) => {
        setPositionTypes([]);
      });
  };
  const getPositions = () => {
    setPositions([{ value: 0, label: t('diagnostics.loading') }]);
    getInstance()
      .get(`/api/v1/profil/user-position`)
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            let l = [];
            if (data.length) {
              data.forEach((item) => {
                l = [
                  ...l,
                  { value: item.id, label: item.name, type_id: item.type_id },
                ];
              });
            }
            setPositions(l);
          } catch {
            setPositions([]);
          }
        }
      })
      .catch((error) => {
        setPositions([]);
      });
  };
  const getCategory = (lan = 'uz', positions_id = []) => {
    setCategorys([{ value: 0, label: t('diagnostics.loading') }]);
    let l = '';
    positions_id.forEach((item) => {
      l += 'position_id[]=' + item.value + '&';
    });
    const token = getToken();
    axios
      .create({
        baseURL: API_URL,
        headers: {
          Authorization: token ? `Bearer  ${token} ` : undefined,
          lang: lan,
        },
      })
      .get(`/api/v1/upgrade/upgrade-category?${l}`)
      .then((response) => {
        if (response.data.data) {
          setCategorys(response.data.data);
        } else {
          setCategorysE(t('diagnostics.cat_e2'));
          setCategorys([]);
        }
      })
      .catch((error) => {
        setCategorysE(t('diagnostics.cat_e2'));
        setCategorys([]);
      });
  };

  return (
    <Layout>
      <CheckRole roles={[roles.manager]} />
      <main class='main'>
        <section class='section section-dashboard py-0'>
          <div class='container'>
            <div class='row'>
              <DashSideBar />
              <div class='col-md-12 col-lg-9 col-xl-10'>
                <div class='dashboard-right'>
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t('diagnostics.title')}
                  />
                  <div class='dashboard-title dashboard-title--has-buttons'>
                    <h5>{t('diagnostics.title')}</h5>
                  </div>

                  <div className='card card--shadow' style={{ maxWidth: 500 }}>
                    <div className='card-body card-body-diagnostics'>
                      <div className='form-group mb-1'>
                        <label>
                          {t('diagnostics.lan')}
                          <div>
                            <Select
                              value={language}
                              onChange={(select) => {
                                setLanguage(select);
                                setCategorysE('');
                                setCategory(null);
                                getCategory(
                                  language.value === 2 ? 'ru' : 'uz',
                                  position
                                );
                              }}
                              options={lans}
                              // isMulti ={true}
                              // closeMenuOnSelect={false}
                              placeholder={t('diagnostics.lan')}
                            />
                          </div>
                        </label>
                      </div>
                      <div className='form-group mb-1'>
                        <label>
                          {t('diagnostics.position_type')}
                          <div>
                            <Select
                              value={position_type}
                              onChange={(select) => {
                                setPositionType(select);
                                setPosition([]);
                                setCategorysE('');
                                setCategory(null);
                                getPositions();
                                setPositionTypeE('');
                                setPositionE('');
                                // getCategory(language.value===2?'ru':'uz', select.value);
                              }}
                              options={position_types}
                              // isMulti ={true}
                              // closeMenuOnSelect={false}
                              placeholder={t('diagnostics.position_type')}
                            />
                          </div>
                          <div style={{ color: 'red' }}>{position_type_e}</div>
                        </label>
                      </div>
                      {position_type.value ? (
                        <>
                          <div className='form-group mb-1'>
                            <label>
                              {t('diagnostics.position')}
                              <div>
                                <Select
                                  value={position}
                                  onChange={(select) => {
                                    setPosition(select);
                                    setCategorysE('');
                                    setPositionE('');
                                    setCategory(null);
                                    getCategory(
                                      language.value === 2 ? 'ru' : 'uz',
                                      select
                                    );
                                  }}
                                  options={_.filter(positions, (i) => {
                                    return position_type.value === i.type_id;
                                  })}
                                  isMulti={true}
                                  // closeMenuOnSelect={false}
                                  placeholder={t('diagnostics.position')}
                                />
                              </div>
                              <div style={{ color: 'red' }}>{position_e}</div>
                            </label>
                          </div>
                          {position.length ? (
                            <>
                              <div className='form-group mb-1'>
                                <label>
                                  {t('diagnostics.category')}
                                  <div>
                                    <Select
                                      value={category}
                                      onChange={(select) => {
                                        setCategory(select);
                                        setCategorysE('');
                                      }}
                                      options={catOption()}
                                      // isMulti ={true}
                                      // closeMenuOnSelect={false}
                                      placeholder={t('diagnostics.category')}
                                    />
                                  </div>
                                  <div style={{ color: 'red' }}>
                                    {categorys_e}
                                  </div>
                                </label>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      <div>
                        {loading ? (
                          <Loading />
                        ) : (
                          <button
                            className='btn btn-primary mb-1 mr-1 btn-sm'
                            onClick={() => onSee()}
                          >
                            {t('diagnostics.btn')}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
export default ManagerDiagnostics;
