import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../helpers/api';

const ExportExcel = ({ slug }) => {
	const { t } = useTranslation();
	const [error, setError] = useState(false);
	const [columns, setColumns] = useState([
		{
			name: 'F.I.SH',
			id: 1,
			value: 'full_name',
			checked: true,
		},
		{
			name: "Vebinarga qo'shilgan sana",
			id: 2,
			value: 'sign_date',
			checked: true,
		},
		{
			name: 'Viloyat',
			id: 3,
			value: 'region',
			checked: true,
		},
		{
			name: 'Tuman',
			id: 4,
			value: 'city',
			checked: true,
		},
	]);
	const onChange = id => {
		const newColumns = columns.map(column => {
			if (column.id === id) {
				return { ...column, checked: !column.checked };
			} else {
				return column;
			}
		});
		setColumns(newColumns);
	};
	const exportExcel = () => {
		const links = columns.filter(column => column.checked);
		const token = localStorage.getItem('token');
		const baseUrl = `${API_URL}/api/v1/course/webinar/users/export?token=${token}&slug=${slug}`;
		if (links.length) {
			const url = links.reduce((total, link) => {
				total += '&fields[]=' + link.value;
				return total;
			}, baseUrl);
			window.open(url, '_blank');
			window.$('#export-excel').modal('hide');
		} else {
			setError(true);
		}
	};
	return (
		<div
			class="modal fade"
			id="export-excel"
			tabindex="-1"
			role="dialog"
			aria-labelledby={'export-excel'}
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{t('m_pupils.excel_export')}</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
							<span class="icon icon-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<label for="" class="mb-2 text-center" style={{ fontSize: 18 }}>
							{t('m_pupils.excel_text')}
						</label>
						<ul className="test__item-answers mb-5">
							{columns.map(({ checked, name, id }, index) => (
								<li key={index}>
									<label className="checkbox-custom">
										<input
											type="checkbox"
											name="excel"
											checked={checked}
											onChange={() => onChange(id)}
										/>
										{name}
										<span className="checkmark"></span>
									</label>
								</li>
							))}
						</ul>
						{error && <p>Kamida bitta ustun ko'rsatilishi kerak</p>}
						<div class="row">
							<div class="col-6">
								<button
									type="button"
									class="btn btn-lighter btn-sm w-100"
									data-dismiss="modal">
									{t('log_out.cancel')}
								</button>
							</div>
							<div class="col-6">
								<button
									type="button"
									class="btn btn-primary btn-sm w-100"
									onClick={exportExcel}>
									{t('m_pupils.excel')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ExportExcel;
