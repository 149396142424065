import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTeacherUpdateCourse } from '../actions';
import { roles } from '../helpers/roles';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import CopyrightCreateOrUpdate from './sections/CopyrightCreateOrUpdate';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';
import NoData from './sections/NoData';

const DashCopyrightUpdate = props => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (props.teacher_update_course_status.status === 'started') {
			setLoading(true);
		}
		if (props.teacher_update_course_status.status === 'success') {
			setLoading(false);
		}
	}, [props.teacher_update_course_status.status]);
	useEffect(() => {
		props.getTeacherUpdateCourse(props.match.params.id);
	}, [props.match.params.id]);
	const { teacher_update_course } = props;
	return (
		<Layout>
			<CheckRole roles={[roles.teacher]} />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('create_course.update')}
										navs={[
											{
												title: t('navigation.account'),
												link: '/dashboard',
											},
											{
												title: t('author_page.course'),
												link: '/dashboard/copyrights',
											},
										]}
									/>
									<div className="dashboard-title">
										<h5>{t('create_course.update')} </h5>
									</div>
									{loading ? (
										<Loading />
									) : teacher_update_course.data && teacher_update_course.data.id ? (
										<CopyrightCreateOrUpdate data={teacher_update_course.data} />
									) : (
										<NoData text={t('course_view.not-course')} />
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getTeacherUpdateCourse: id => dispatch(getTeacherUpdateCourse(id)),
	}),
)(DashCopyrightUpdate);
