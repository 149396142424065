import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts
} from 'react-jsx-highcharts';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const ChartColumnHCourseReview = (props) => {
    const {data} = props;
    let names = [];
    let percents = [];
    data.map((cat)=>{
        const percent = cat.percent*100;
        names = [cat.rating, ...names];
        percents = [percent?percent:0, ...percents];
    })
    
    const chart = {
      type: 'bar'
    }
    const xAxis = {
        categories: names,
        title: {
            text: ''
        }
    }
    const yAxis = {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    }
    const plotOptions = {
        bar: {
            dataLabels: {
                enabled: true,
                format: '{y:.2f} %'
            }
        },
        series: {
            borderRadius: 5
        }
    }
    const legend = {
        enabled: false
    }
    const credits = {
        enabled: false
    }
    const series = [
      {
          name: '',
          data: percents,
        //   color: '#26CAAC'
      }
    ]

return (
      <div style={{width: '100%'}}>
      
        <HighchartsChart  
          chart={chart}
          series={ series}
          yAxis={yAxis}
          xAxis = {xAxis}
          plotOptions = {plotOptions}
          legend={legend}
          credits={credits}
        >
        </HighchartsChart>
            
      </div>
    );
  
}

export default withHighcharts(ChartColumnHCourseReview, Highcharts);
