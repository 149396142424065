import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import NoData from './sections/NoData';
import Navigation from './sections/Navigation';
import DiagnosticTestResult from './sections/DiagnosticTestResult';
import DiagnosticTestPerformance from './sections/DiagnosticTestPerformance';
import Loading from './sections/Loading';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../helpers/httpClient';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import history from '../history';

const DashDExerciseTest = props => {
	const { t } = useTranslation();
	const {} = props;
	const [loading, setLoading] = useState(true);
	const [is_done, setIsDone] = useState(0);
	const [tests, setTests] = useState({});
	const [check_tests, setCheckTests] = useState({});
	const [is_chack, setIsCheck] = useState(true);

	useEffect(() => {
		getResult();
		getTests();
	}, []);
	const getTests = () => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/course/exercise/lesson`)
			.then(response => {
				setLoading(false);
				setTests(response.data);
			})
			.catch(error => {
				setLoading(false);
			});
	};
	const getResult = () => {
		setIsCheck(true);
		getInstance()
			.get('/api/v1/course/exercise/result')
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						if(data&&data.length>0){
							history.push('/dashboard/exercise');
							// setIsCheck(false);
						}else{
							setIsCheck(false);
						}
					} catch {
						history.push('/');
					}
				}
			})
			.catch(error => { 
				console.log(error) 
				setIsCheck(false);
			} );
	}
	const postTests = data => {
		getInstance()
			.post(`/api/v1/course/exercise/test-check`, data)
			.then(response => {
				if (response.data.status === 1) {
					setCheckTests(response.data);
					history.push('/dashboard/exercise');
					// setIsDone(1);
				}
			})
			.catch(error => {
				setLoading(false);
			});
	};
	const checkHasQues = (vquestions = []) =>{
		let c = false
		vquestions.forEach(ques=>{
			if(ques.length) c = true;
		})
		return c
	}
	return (
		<Layout>
			<CheckRole roles={ [ roles.teacher, roles.user ] } />
			
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('exercise.exercise_test')}
										navs={[
											{
												title: t('navigation.account'),
												link: '/dashboard',
											},
											{
												title: t('exercise.exercise'),
												link: '/dashboard/exercise',
											},
										]}
									/>
									<div className="dashboard-title">
										<h3>{t('exercise.exercise_test')}</h3>
									</div>
									<div className="has-right-buttons">
										{loading || is_chack ? (
											<Loading />
										) :checkHasQues(_.get(tests, 'data.vquestions', []))? (
											<>
												{is_done === 0 ? (
													<DiagnosticTestPerformance
														tests={tests}
														in_dash={false}
														postTests={d => postTests(d)}
													/>
												) : (
													<DiagnosticTestResult
														test_check_data={check_tests}
														setIsDone={() => setIsDone(0)}
													/>
												)}
											</>
										) : (
											<NoData text={t('exercise.no_data')} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default DashDExerciseTest;
