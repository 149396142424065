import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div class="page-not-found">
      <div class="page-not-found__inner">
          <div class="page-not-found__image">
              <img src="/images/404.png" alt="Page not found" />
          </div>
          <div class="page-not-found__caption">
              <h2>{t('404.title')}</h2>
              <p>{t('404.text')}</p>
              <Link to="/" class="btn btn-primary btn-sm">
                <span class="icon icon-home fs-100 mr-2"></span>
                {t('404.btn')}
              </Link>
          </div>
      </div>
  </div>
  );
}
export default NotFoundPage;