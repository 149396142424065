import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Select from 'react-select';
import Loading from '../Loading';
import { getInstance } from '../../../helpers/httpClient';

const Step1 = (props) => {
  const {
    edu,
    getCitiesList,
    getOptions,
    region_list,
    cityRegion,
    cities_list,
    customStyles,
    disabled,
    setStep,
    step,
    type,
    setType,
    address_all,
    removeAllAddressItem,
    addressAllSetItem,
    setAddress_all,
    position_type,
    position,
    setPositionSelect,
    positions,
    position_types,
    setPositionTypeSelect,
    submitTest2,
    loading,
    setErrorEdu,
    setEdu,
    errorEdu,
  } = props;
  const [permissions, setPermissions] = useState({});
  useEffect(() => {
    getTypePermission();
  }, []);
  const getTypePermission = () => {
    getInstance()
      .get(`/api/v1/edu-institutions/check/type`)
      .then((res) => {
        setPermissions(_.get(res, 'data.data', {}));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest2}>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.edu')}</h6>

          {errorEdu.url || errorEdu.address_all || errorEdu.position_type ? (
            <div className='inputError'>{t('edu_institution.main_err')}</div>
          ) : null}
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label>
              O‘quv-tajriba maydonchalari (tayanch o‘quv muassasalari)ning
              mavjudligi{' '}
            </label>
            <div
              className='setting__buttons'
              style={{ justifyContent: 'flex-start' }}
            >
              <label className='switch-text'>
                {disabled === true ? (
                  <div className='disabledSwitchDiv' />
                ) : null}
                <input
                  disabled={disabled}
                  type='checkbox'
                  checked={edu.experimental_playground === 0 ? true : false}
                />
                <span className='slider'></span>
                <div className='switch-labels'>
                  <span
                    className='switch-label'
                    onClick={() =>
                      setEdu({ ...edu, experimental_playground: 1 })
                    }
                  >
                    Mavjud
                  </span>
                  <span
                    className='switch-label'
                    onClick={() =>
                      setEdu({ ...edu, experimental_playground: 0 })
                    }
                  >
                    Mavjud emas
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-xl-4' style={{ display: 'none' }}>
          <div className='form-group'>
            <label>{t('edu_institution.type')}</label>
            <div>
              <Select
                isDisabled={disabled}
                defaultValue='0'
                onChange={(select) => {
                  setType(select);
                }}
                options={[
                  // {
                  //   label: t('edu_institution.online'),
                  //   value: 1,
                  //   isDisabled: _.get(permissions, 'online', 0) ? false : true,
                  // },
                  {
                    label: t('edu_institution.offline'),
                    value: 0,
                    isDisabled: _.get(permissions, 'offline', 0) ? false : true,
                  },
                ]}
                // isMulti ={true}
                // closeMenuOnSelect={false}
                placeholder={t('edu_institution.type')}
              />
            </div>
          </div>
        </div>

        {/* <div className='' style={{ display: 'none' }}>
          {type.value === 1 ? (
            <div className='col-md-6 col-xl-8'>
              <div className='form-group'>
                <label for=''>{t('edu_institution.url')}</label>
                <input
                  disabled={disabled}
                  onChange={(e) => {
                    setEdu({ ...edu, url: e.target.value });
                    setErrorEdu({ ...errorEdu, url: false });
                  }}
                  type='text'
                  name='url'
                  value={edu.url === null ? '' : edu.url}
                  className='form-control edu_institution_input'
                  placeholder={t('edu_institution.url')}
                  style={errorEdu.url ? { borderColor: '#f00' } : {}}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div> */}
      </div>
      {/* {type.value === 0 ? ( */}
      <>
        <div className='row'>
          <div className='col-12'>
            <h6 className='mb-4'>{t('edu_institution.address_title')}</h6>
          </div>
        </div>
        {address_all.map((item, index) => (
          <div key={index} className='row'>
            <div className='col-12 mb-3'>
              <div
                style={{
                  fontWeight: 500,
                  paddingRight: 50,
                }}
              >
                {index + 1}-{t('edu_institution.address_all')}
              </div>
              {disabled ? null : (
                <button
                  className='btn btn-primary btn-sm edu_phone_close'
                  type='button'
                  style={{ padding: '7px 9px' }}
                  onClick={() => removeAllAddressItem(index)}
                >
                  <span class='icon icon-close'></span>
                </button>
              )}
            </div>
            <div className='col-md-6 col-xl-4'>
              <div className='form-group'>
                <label>{t('edu_institution.region')}</label>
                <div>
                  <Select
                    value={_.get(item, 'region', null)}
                    isDisabled={disabled}
                    onChange={(select) => {
                      addressAllSetItem(select, index, 'region');
                      getCitiesList(select.value);
                      setErrorEdu({
                        ...errorEdu,
                        address_all: {
                          ...errorEdu.address_all,
                          ['region' + index]: false,
                        },
                      });
                    }}
                    options={getOptions(_.get(region_list, 'data', []))}
                    placeholder={t('edu_institution.region')}
                    styles={
                      _.get(errorEdu, 'address_all.region' + index, false)
                        ? customStyles
                        : {}
                    }
                  />
                </div>
              </div>
            </div>
            {_.get(item, 'region.value', false) ? (
              <>
                <div className='col-md-6 col-xl-4'>
                  <div
                    className='form-group'
                    onClick={() =>
                      getCitiesList(_.get(item, 'region.value', 0))
                    }
                  >
                    <label>{t('edu_institution.city')}</label>
                    <div>
                      <Select
                        value={_.get(item, `city`, null)}
                        isDisabled={disabled}
                        onChange={(select) => {
                          addressAllSetItem(select, index, 'city');
                          setErrorEdu({
                            ...errorEdu,
                            address_all: {
                              ...errorEdu.address_all,
                              ['city' + index]: false,
                            },
                          });
                        }}
                        options={
                          cityRegion === _.get(item, 'region.value', false)
                            ? getOptions(_.get(cities_list, 'data', []))
                            : [
                                {
                                  value: 'blue',
                                  label: t('chat.loading'),
                                  isDisabled: true,
                                },
                              ]
                        }
                        placeholder={t('edu_institution.city')}
                        styles={
                          _.get(errorEdu, 'address_all.city' + index, false)
                            ? customStyles
                            : {}
                        }
                      />
                    </div>
                  </div>
                </div>
                {_.get(item, 'city.value', false) ? (
                  <div className='col-12'>
                    <div className='form-group'>
                      <label for=''>{t('edu_institution.address')}</label>
                      <textarea
                        className='form-control edu_institution_input'
                        disabled={disabled}
                        style={
                          _.get(errorEdu, 'address_all.address' + index, false)
                            ? {
                                borderColor: '#f00',
                              }
                            : {}
                        }
                        value={_.get(item, 'address', '')}
                        onChange={(e) => {
                          addressAllSetItem(e.target.value, index, 'address');
                          setErrorEdu({
                            ...errorEdu,
                            address_all: {
                              ...errorEdu.address_all,
                              ['address' + index]: false,
                            },
                          });
                        }}
                        name='address_all'
                        placeholder={t('edu_institution.address')}
                      ></textarea>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        ))}
        <div className='row'>
          {disabled ? null : (
            <div className='col-12 mb-5'>
              <button
                className='btn btn-primary btn-sm'
                type='button'
                onClick={() =>
                  setAddress_all([
                    ...address_all,
                    {
                      region: {
                        value: null,
                        label: null,
                      },
                      city: {
                        value: null,
                        label: null,
                      },
                      address: '',
                    },
                  ])
                }
              >
                <span class='icon icon-plus'></span>{' '}
                {t('edu_institution.address_all_add')}
              </button>
            </div>
          )}
        </div>
      </>
      {/* ) : (
         ''
       )} */}

      <div className='row'>
        <div className='col-12 mb-5'>
          <div className='form-group mb-1'>
            <label style={{ margin: '0 0 8px 0' }}>
              {t('edu_institution.position_type')}
            </label>
            <div>
              <Select
                isDisabled={disabled}
                value={position_type === null ? '' : position_type}
                onChange={(select) => {
                  setPositionTypeSelect(select);
                  setErrorEdu({
                    ...errorEdu,
                    position_type: false,
                  });
                }}
                options={[
                  {
                    value: 0,
                    label: t('survey.all'),
                  },
                  ..._.filter(position_types, (i) => {
                    return (
                      i.offline === 1

                      // (type.value === 1 && i.online === 1) ||
                      // (type.value === 0 && i.offline === 1)
                    );
                  }),
                ]}
                isMulti={true}
                styles={errorEdu.position_type ? customStyles : {}}
                // closeMenuOnSelect={false}
                placeholder={t('survey.select')}
              />
            </div>
            <div style={{ color: 'red' }}>
              {_.get(errorEdu, 'position_type', '')}
            </div>
          </div>
          {position_type.map((ptype, index) => (
            <div className='form-group mb-5' key={index}>
              <label style={{ margin: '24px 0 8px 0' }}>{ptype.label}</label>
              <div>
                <Select
                  isDisabled={disabled}
                  value={_.get(position, ptype.value, [])}
                  onChange={(select) => {
                    setPositionSelect(select, ptype.value);
                    setErrorEdu({
                      ...errorEdu,
                      ['position_' + ptype.value]: false,
                    });
                  }}
                  options={[
                    {
                      value: 0,
                      label: t('survey.all'),
                    },
                    ..._.filter(positions, (i) => {
                      return (
                        ptype.value === i.type_id && i.offline === 1

                        // (type.value === 1 && i.online === 1) ||
                        // (type.value === 0 && i.offline === 1)
                      );
                    }),
                  ]}
                  isMulti={true}
                  styles={
                    _.get(errorEdu, 'position_' + ptype.value, false)
                      ? customStyles
                      : {}
                  }
                  placeholder={t('survey.select')}
                />
              </div>
              <div style={{ color: 'red' }}>
                {_.get(errorEdu, 'position[' + ptype.value + ']', '')}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='row'>
        {/* <div className="col-md-6">
                    <label> {t('edu_institution.upload_logo')} (.png, .jpg, .jpeg)</label>
                    <label className="edu_institution_input edu_institution_file mb-3"
                        style={error.logo?{borderColor: '#f00'}:{}}
                    >
                        <img src={img_url} alt="education" 
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = '/images/user/upload.png';
                            }}
                        />
                        <input type="file" name="logo" alt="education logo" hidden
                            disabled={disabled}
                            onChange={e=>{ imgChange(e); setError({...error, logo: false}); } } 
                            accept="image/*"
                        />
                        <div> {t('edu_institution.logo')}</div>
                    </label>
                    {edu.logo?
                    <a href={img_url} className="btn btn-primary btn-sm mb-3" target='_blank'>
                        <span class="icon icon-eye"></span> {t('edu_institution.eye')} 
                    </a>:null}
                </div> */}

        {/* <div className='col-12'>
          <h6 className='mb-4'>
            {t('edu_institution.upload_file')}
            <i
              style={{
                color: '#26CAAC',
                whiteSpace: 'nowrap',
                paddingLeft: 24,
                cursor: 'pointer',
                fontWeight: 500,
              }}
            >
              <a href='/docs/example.pdf' target='_blank'>
                {t('edu_institution.example_file')}
              </a>
            </i>
          </h6>
        </div> */}
        {/* <div className='col-12'>
          <label><b>{t('edu_institution.upload_file')}</b></label>
          <label
            className='edu_institution_input edu_institution_file mb-3'
          >
            {file_name && file_name !== FILE_URL ? (
              <b style={{ fontSize: 16 }}>{file_name}</b>
            ) : (
              <img
                src='/images/user/upload.png'
                alt='education'
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user/upload.png';
                }}
              />
            )}
            <input
              type='file'
              name='file'
              alt='education file'
              hidden
              disabled={disabled}
              onChange={(e) => {
                fileChange(e);
                setError({ ...error, file: false });
              }}
              accept='.zip,.rar,.7zip'
            />
            <div>{t('edu_institution.file')}</div>
          </label>
          {edu.file ? (
            <a
              href={file_name}
              className='btn btn-primary btn-sm mb-3'
              target='_blank'
            >
              <span class='icon icon-eye'></span> {t('edu_institution.eye')}
            </a>
          ) : null}
        </div> */}
      </div>

      {/* {disabled ? null : ( */}
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            <button
              className='btn btn-primary btn-sm'
              style={{ marginRight: '15px' }}
              onClick={() => {
                setStep(step - 1);
                window.scroll(0, 0);
              }}
            >
              Oldingi
            </button>
            <button className='btn btn-primary btn-sm' type='submit'>
              Keyingi
            </button>
          </>
        )}
      </>
      {/* )} */}
    </form>
  );
};

export default Step1;
