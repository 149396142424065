import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import Layout from './Layout';
import Navigation from "./sections/Navigation";
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import WebinarCard from './sections/WebinarCard';
import Pagination from './sections/Pagination';
import { getWebinarsList } from "../actions";
import { useTranslation } from 'react-i18next';

const WebinarsPage = (props) => {
    const { t, i18n } = useTranslation();
    const [status, setStatus] = useState(1);
    const [loading, setLoading] = useState(true);
    const convertLink = () => {
        let str = '?';
        if(status){
            str +='status='+status+'&';
        }
        return str;
    }
    useEffect(()=>{
        const search = props.location.search; 
        const params = new URLSearchParams(search);
        const status_p = params.get('status');
        setStatus(status_p);
        let str = '&';
        if(status_p){
            str +='status='+status_p+'&';
        }
        props.getWebinarsList( props.match.params.page?props.match.params.page:1, str );
        document.title = t('webinars.title');

    },[props.match.params.page, props.location.search]);
    useEffect(()=>{
        if(props.webinars_list_status.status === 'started'){
            setLoading(true)
        }
        if(props.webinars_list_status.status === 'success'){
            setLoading(false)
        }
        if(props.webinars_list_status.status === 'error'){
            setLoading(false)
        }
    },[props.webinars_list_status]);
    const {webinars_list, } = props;
  return (<Layout>
      <main className="main" style={{minHeight: 500}}>
      <section class="section section-breadcrumbs">
        <div class="container">
            <Navigation
                is_dash={false}
                is_btn={false}
                active={t('webinars.title')}
                navs={[]}
            />
        </div>
    </section>
        <section className="section  py-3">
            <div className="container">
                <h3 class="mb-4">{t('webinars.title')}</h3>
                <div className="row">
                    <div className="col-12">
                            {loading?<div>
                                <Loading/>
                            </div>
                            :<>
                                {webinars_list.data&&webinars_list.data.length>0?<>
                                <div className="row courses-dashboard"> 
                                    {webinars_list.data.map((webinar,index)=>(
                                        <WebinarCard webinar={webinar} key={index}/> 
                                    ))} 
                                </div>
                                <div className="col-12">
                                    <Pagination
                                        total={webinars_list.total?webinars_list.total:6}
                                        active={ parseInt(props.match.params.page) }
                                        link="/webinars/list/"
                                        search_params={convertLink()}
                                    />
                                    <br/>
                                    <br/>
                                </div>
                                </>
                                :
                                    <NoData
                                        text={t('webinars.no_data')}
                                    />
                                }
                            </>}
                    </div>
                </div>
            </div>
        </section>

    </main>
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getWebinarsList: (page, search) => dispatch(getWebinarsList(page, search)),
    })
)(WebinarsPage);