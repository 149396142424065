import React, { useState, useEffect } from 'react';
import { getStatisticUser, getStatistic } from '../actions';
import { connect } from 'react-redux';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import { useTranslation } from 'react-i18next';
import ChartDoughnut from './sections/ChartDoughnut';
import ChartColumn from './sections/ChartColumn';
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";


const DashStatisticsPage = props => {
	const { statistic_user, statistic, statistic_status } = props;
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		props.getStatisticUser();
		props.getStatistic();
	}, []);
	useEffect(() => {
		if (statistic_status.status === 'started') {
			setLoading(true);
		}
		if (statistic_status.status === 'success') {
			setLoading(false);
		}
		if (statistic_status.status === 'error') {
			setLoading(false);
		}
	}, [statistic_status]);

	const total_hours = parseInt((statistic.total_hours / 60).toFixed(0));
	const user_hours = parseInt((statistic.user_hours / 60).toFixed(0));

	return (
		<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
			<main className="main">
				<section className="section section-dashboard py-0">
					<div className="container">
						<div className="row">
							<DashSideBar />
							<div className="col-md-12 col-lg-9 col-xl-10">
								<div className="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={t('statistics.title')}
										navs={[
											{
												title: t('navigation.account'),
												link: '/dashboard',
											},
										]}
									/>
									<div className="dashboard-title">
										<h5>{t('statistics.title')}</h5>
									</div>
									{loading ? (
										<Loading />
									) : statistic.data ? (
										<>
											<div className="row statistics-top">
												<div className="col-md-4">
													<div className="card card--shadow">
														<div className="card-body">
															<h3>
																{total_hours} {t('statistics.hourse')}
															</h3>
															<p>{t('statistics.text1')}</p>
														</div>
													</div>
												</div>
												<div className="col-md-4">
													<div className="card card--shadow">
														<div className="card-body">
															<h3>
																{user_hours} {t('statistics.hourse')}
															</h3>
															<p>{t('statistics.text2')}</p>
														</div>
													</div>
												</div>
												<div className="col-md-4">
													<div className="card card--shadow">
														<div className="card-body">
															<h3>
																{total_hours > user_hours
																	? total_hours - user_hours
																	: 0}{' '}
																{t('statistics.hourse')}
															</h3>
															<p>{t('statistics.text3')}</p>
														</div>
													</div>
												</div>
											</div>
											<div className="row statistics-graph">
												{statistic_user.length > 0 ? (
													<div className="col-12 col-md-8">
														<div className="card">
															<div className="card-body">
																<div className="statistics-graph__info">
																	<p>{t('statistics.text4')}</p>
																</div>
																<div className="statistics-graph__chart">
																	<ChartColumn data={statistic_user} />
																</div>
															</div>
														</div>
													</div>
												) : null}
												<div className="col-12 col-md-4">
													<div className="card">
														<div className="card-body mb-5">
															<div className="statistics-graph__info mb-7">
																<p>{t('statistics.text5')}</p>
																<small>{t('statistics.text6')}</small>
															</div>
															<div className="statistics-graph__chart">
																<ChartDoughnut
																	data={statistic.data}
																	total_hours={statistic.total_hours}
																/>
																{/* <canvas id="chart_2" width="160" height="140"></canvas> */}
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									) : (
										<NoData text={t('statistics.not-statistics')} />
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default connect(
	state => ({
		...state,
	}),
	dispatch => ({
		getStatisticUser: () => dispatch(getStatisticUser()),
		getStatistic: () => dispatch(getStatistic()),
	}),
)(DashStatisticsPage);
