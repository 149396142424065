import React,{useState, useEffect} from 'react';
import Layout from './Layout';
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import { roles } from "../helpers/roles";
import Creator from './sections/survey-creator/Creator';
import { getInstance } from '../helpers/httpClient';
import Loading from "./sections/Loading";
import histoty from '../history';

const SurveyCreator = (props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [survey, setSurvey] = useState({});
	const id = props.match.params.id?props.match.params.id:1;

    useEffect(()=>{
        getSurveys(id);
    },[id]);

    const getSurveys = (i) => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/survey/survey?id=${i}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						setSurvey(data);
						setLoading(false);
					} catch {
						setLoading(false);
						setSurvey({});
					}
				}
			})
			.catch(error => { setSurvey({});
						setLoading(false);
		} );
	}
	const setSurveyJson = (d, auto_save = false) => {
		getInstance()
			.post('/api/v1/survey/update-survey', d)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
                        if(!auto_save)histoty.push('/manager/surveys');
					} catch {
						// setPositionTypes([]);
					}
				}
			})
			.catch(error => {  } );
	}
    return(
        <Layout>
			<CheckRole roles={[ roles.manager, roles.teacher]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right" style={{paddingBottom: 0}}>
									<Navigation is_dash={true} is_btn={true} active={t('survey.questions')}
                                        navs={[
											{
												'title': t('survey.survey'),
												'link': '/manager/surveys'
											}
										]} 
									/>
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{t('survey.questions')}</h5>
										
                                    </div>
									{/* {loading?<Loading/>: */}
									<Creator survey_data={survey}
										setSurveyJson={(d, auto_save)=>setSurveyJson(d, auto_save)}
										loading={loading}
									/>
									
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default SurveyCreator;