import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import JoditText from './JoditText';
import JoditTextShow from './JoditTextShow';
import Loading from './Loading';
import { connect } from 'react-redux';
import { addDiagnosticQuestion } from '../../actions';

const DiagnosticsTestExplanChange = props => {
	const { t } = useTranslation();
	const [edit, setEdit] = useState(false);
	const [explan, setExplan] = useState('');
	const [explan_e, setExplanE] = useState('');
	const {  question_data, diagnostics_question_status } = props;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (diagnostics_question_status.status === 'success') {
			setLoading(false);
			setEdit(false);
		}
		if (diagnostics_question_status.status === 'error') {
			setLoading(false);
		}
	}, [diagnostics_question_status]);
	

	useEffect(() => {
		setExplan(question_data.explan);
	}, [question_data]);

	const onSubmit = () => {
		// if (explan) {
			setLoading(true);
			const data = {
				...question_data,
				explan: explan
			};
			props.addDiagnosticQuestion(data);
		// } else {
		// 	setExplanE(t('diagnostics.explan_e'));
		// }
	};

	return (
		<>
		{question_data.explan||edit?<>
			<div className="card card--shadow mt-1 mb-0 ">
				<div className="card-body card-body-diagnostics">
					<div className="row">
						<div className="col-12 dig-title">
                            {question_data.explan?<>
                            <b>
                            { t('diagnostics.explan') }
							</b>
							{edit ? 
								<span className="icon icon-close" onClick={() => setEdit(false)}></span>
							: 
								<span className="icon icon-edit" onClick={() => setEdit(true)}></span>
							}</>:<>
                            {edit?<>
                                <b>{ t('diagnostics.explan') }</b>
                                <span className="icon icon-close" onClick={()=>setEdit(false)}></span>
                            </>:<>
                                <b className="btn btn-primary btn-sm"  onClick={()=>setEdit(true)}>
									<span className="icon icon-plus" style={{color: '#fff'}} ></span> { t('diagnostics.explan_add') }
								</b>
                            </>}
                            </>}
						</div>

						{edit ? (
							<>
								<div className="col-12 mb-1">
									<label>{t('diagnostics.explan_text')}</label>
									<JoditText
										value={explan}
										setValue={v => setExplan(v)}
										setValueE={e => setExplanE(e)}
									/>
									<div style={{ color: 'red' }}>{explan_e}</div>
								</div>

								
								<div className="col-12 btn-target">
									{loading ? (
										<Loading />
									) : (
										<>
											<button
												className="btn btn-primary mb-1 mr-1 btn-sm"
												onClick={() => onSubmit()}>
												{t('diagnostics.save')}
											</button>
										</>
									)}
								</div>
							</>
						) : question_data.explan? <>
							<div className="col-12 dig-title" >
								<div className="test-items-target"  style={{width: '100%'}}> <JoditTextShow text={question_data.explan ? question_data.explan : '<p></p>'} /> </div>
							</div>
						</>: null}
					</div>
				</div>
			</div>
			</>:<>
			<b className="btn btn-primary btn-sm"  onClick={()=>setEdit(true)} style={{float: 'right', margin: 10}}>
				<span className="icon icon-info" style={{color: '#fff'}} ></span> { t('diagnostics.explan_add') }
			</b>
		</>}
		</>
	);
};
export default connect(
	state => ({
		diagnostics_question_status: state.diagnostics_question_status
	}),
	dispatch => ({
		addDiagnosticQuestion: data => dispatch(addDiagnosticQuestion(data))
	}),
)(DiagnosticsTestExplanChange);
