import { useState, useEffect } from "react";
import Layout from "../Layout"
import DashSideBar from "../sections/DashSideBar"
import Navigation from "../sections/Navigation"
import { useTranslation } from 'react-i18next';
import { getInstance } from "../../helpers/httpClient";
import Pagination from '../sections/Pagination';
import Loading from "../sections/Loading";
import {get} from 'lodash'
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoData from "../sections/NoData";


const ExamsPage = (props) => {
	const { t } = useTranslation();
    const [exams, setexams] = useState({});
    const [loading, setLoading] = useState(false);
    const [uloading, setULoading] = useState(0);
    const [sloading, setSLoading] = useState(0);
    useEffect(()=>{
        getexams(props.match.params.page)
    }, [props.match.params.page])
    const getexams = (page) =>{
        setLoading(true)
        getInstance().get('/api/v1/manager/exams?per_page=25&page='+page)
        .then(res=>{
            setexams(get(res, 'data.data', []));
        }).catch(()=>{}).finally(()=>{setLoading(false)})
    }
    const RmGroup = (uid) => {
      setULoading(uid)
          getInstance().post('/api/v1/manager/exams/delete', {
        id:   uid
      })
          .then(res=>{
              let l=[];
        get(exams, 'data', []).forEach(item=>{
          if(item?.id!==uid)l.push(item);
        })
        setexams({...exams, data: l});
          }).catch(()=>{ }).finally(()=>{setULoading(0)})
    }
    const ChangeStatus = (obj, status) => { 
        setSLoading(obj?.id) 
        getInstance().post('/api/v1/manager/exams/update', {...obj, status: status}  )
        .then(res=>{
            const data = get(res, 'data.data', {})
            let l=[];
            get(exams, 'data', []).forEach(item=>{
              if(item?.id===data?.id) l.push(data);
              else  l.push(item);
            })
            setexams({...exams, data: l});
        }).catch(()=>{}).finally(()=>{setSLoading(0)}) 

    }
    return(
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('exams.title')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                      ]}
                    />
                    <div className="dashboard-title dashboard-title--has-buttons">
                        <h5 className="mb-3">
                            {t('exams.title')} 
                        </h5>
                        <div class="buttons butons--target">
                            <Link to='/dashboard/exams/create' className="btn btn-primary btn-sm">
                              <span class="icon icon-plus mr-1"></span>
                                {t('exams.add')}
                            </Link>
                        </div>
                    </div>
                    {loading?<Loading/>:<>
                    {get(exams, 'data', []).length>0?<>
                        {get(exams, 'data', []).map((item, index)=>(
                            <div className="raw card card--shadov p-2" key={index}>
                                <div className="space_between">
                                    <h5>{item?.name}</h5> 
                                    <div className="space_between"> 
                                        
                                        <label className="switch mx-1">
                                          {sloading===item?.id?<Loading size={4}/>:<>
                                            <input type="checkbox" checked={get(item, 'status', 0)?true:false}
                                                onChange={(e)=>{ 
                                                  ChangeStatus(item, get(item, 'status', 0)?0:1) 
                                                }}
                                            /> 
                                            <span className="slider round"></span>
                                          </>}
                                        </label>
                                        <Link to={'/dashboard/exams/edit/'+item?.id}  
                                            className="btn btn-primary btn-sm mr-1">
                                              <span class="icon icon-edit"></span>
                                        </Link>
                                        <Link to={'/dashboard/exams/'+item?.id+'/tests'}  
                                            className="btn btn-primary btn-sm mr-1">
                                              <span class="icon icon-question"></span>
                                        </Link>
                                        <span>
                                        {uloading===item?.id?<button className="btn btn-danger btn-sm"><Loading size={2.5}/></button>:
                                        <button className="btn btn-danger btn-sm"
                                          onClick={()=>RmGroup(item?.id)}
                                        >
                                          <span class="icon icon-remove"></span>
                                        </button>}</span>
                                    </div>
                                </div>
                                <div className="gr_info">
                                    <b>{t('exams.start_date')}:</b> 
                                    <Moment format="HH:mm DD-MM-YYYY">{item?.start_date}</Moment> 
                                </div>
                                <div className="gr_info">
                                    <b>{t('exams.end_date')}:</b>
                                    <Moment format="HH:mm DD-MM-YYYY">{item?.start_date}</Moment> 
                                </div>
                                <div  className="gr_info"><b>{t('exams.desc')}:</b>{item?.desc}</div>
                            </div>
                        ))}
                        <Pagination
                            total={get(exams, 'total', 20)}
                            count={25}
                            active={ parseInt(props.match.params.page) }
                            link="/dashboard/exams/"
                            // search_params={convertLink()}
                        />
                        </>:<NoData text=''/>}
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
}
export default ExamsPage;