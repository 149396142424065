import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { getInstance } from '../helpers/httpClient';
import { roles } from '../helpers/roles';
import histoty from '../history';
import Layout from './Layout';
import CheckRole from './sections/CheckRole';
import DashSideBar from './sections/DashSideBar';
import Loading from './sections/Loading';
import Navigation from './sections/Navigation';

const SurveysChange = props => {
	const { t } = useTranslation();
	const [position_types, setPositionTypes] = useState([]);
	const [position_type, setPositionType] = useState([]);
	const [positions, setPositions] = useState([]);
	const [position, setPosition] = useState({});
	const [loading, setLoading] = useState(false);
	const [loading_data, setLoadingData] = useState(true);
	const [survey_data, setSurveyData] = useState({});
	const [error, setError] = useState({});
	const id = props.match.params.id ? props.match.params.id : 0;
	useEffect(() => {
		getGroups(); 
		if (id) getSurvey(id);
		else setLoadingData(false);
	}, []);
	const getSurvey = id => {
		setLoadingData(true);
		getInstance()
			.get(`/api/v1/survey/survey?id=${id}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response.data;
						let t = [],
							p = {},
							tl = [];
						data?.positions?.forEach(item => {
							if (!tl.includes(item.type_id)) {
								tl = [...tl, item.type_id];
								t = [...t, { value: item.type.id, label: item.type.name }];
							}
						});
						setPositionType(t);
						tl.forEach(item => {
							let pl = [];
							data?.positions?.forEach(item_p => {
								if (item_p.type_id === item) pl = [...pl, { value: item_p.id, label: item_p.name }];
							});
							p = { ...p, [item]: pl };
						});
						setPosition(p);
						setSurveyData({
							...data,
							end_time: data.end_time.replace(' ', 'T'),
							start_time: data.start_time.replace(' ', 'T'),
						});
						setLoadingData(false);
					} catch {
						setSurveyData({});
					}
				}
			})
			.catch(error => {
				setSurveyData({});
			}).finally(()=>{setLoadingData(false)});
	};
	const getGroups = () => {
		getInstance()
			.get('api/v1/manager/groups?per_page=200')
			.then(response => {
				if (response.status === 200) {
					try {
						const  data  = response?.data?.data?.data??[];
						let l = [];
						if (data.length) {
							data.forEach(item => {
								l = [...l, { value: item.id, label: item.name }];
							});
						}
						setPositionTypes(l);
					} catch {
						setPositionTypes([]);
					}
				}
			})
			.catch(error => {
				setPositionTypes([]);
			});
	}; 
	const setPositionTypeSelect = s => {
		let all = false;
		s.forEach(item => {
			if (item.value === 0) all = true;
		});
		if (all) setPositionType(position_types);
		else {
			const sl = _.sortBy(s, e => {
				return e.value;
			});
			setPositionType(sl);
		}
		setError({ ...error, position_type: '' });
	}; 
	const submitHandler = event => {
		setLoading(true);
		event.preventDefault();
		let d = {},
			e = {},
			pl = [];
		if (survey_data.name) d = { ...d, name: survey_data.name };
		else e = { ...e, name: t('survey.name_e') };
		if (survey_data.start_time) d = { ...d, start_time: survey_data.start_time.replace('T', ' ') };
		else e = { ...e, start_time: t('survey.start_time_e') };
		if (survey_data.end_time) d = { ...d, end_time: survey_data.end_time.replace('T', ' ') };
		else e = { ...e, end_time: t('survey.end_time_e') };
		if (survey_data.status) d = { ...d, status: 1 };
		else d = { ...d, status: 0 };
		if (position_type?.length){
			position_type.forEach(item=>{pl.push(item.value)})
		}else{ e = { ...e, position_type: t('survey.p_type_e') }};
		 
		if (id) d = { ...d, id: parseInt(id) };
		d = {
			...d,
			group_id: pl,
			settings: survey_data.settings ? survey_data.settings : '[]',
			last_id: survey_data.last_id ? survey_data.last_id : 0,
		};
		// console.log(d, e);
		if (_.isEmpty(e)) {
			getInstance()
				.post('/api/v1/survey/update-survey', d)
				.then(response => {
					if (response.status === 200) {
						try {
							const { data } = response.data;
							setLoading(false);
							histoty.push('/manager/surveys');
						} catch {
							// setPositionTypes([]);
						}
					}
				})
				.catch(error => {
					setPositionTypes([]);
				});
		} else {
			setError(e);
			setLoading(false);
		}
	};
	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			borderBottom: '1px dotted pink',
			color: state.selectProps.menuColor,
			margin: 0,
			boxShadow: 'none',
			borderRadius: 8,
		}),
		control: provided => ({
			...provided,
			borderRadius: 8,
			minHeight: 45,
			border: 'none',
			boxShadow: 'none',
			background: 'rgba(137, 141, 166, 0.1)',
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			borderRadius: 8,
		}),
		multiValue: provided => ({
			...provided,
			background: '#898DA6',
			borderRadius: 4,
			color: '#ffffff',
			height: 30,
		}),
		multiValueLabel: provided => ({
			...provided,
			background: '#898DA6',
			borderRadius: 4,
			color: '#ffffff',
		}),
		multiValueRemove: provided => ({
			...provided,
			'background': 'transparent',
			'color': '#ffffff',
			':hover': {
				background: 'transparent',
			},
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return { ...provided, opacity, transition, background: '#898DA6' };
		},
		value: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return { ...provided, opacity, transition, background: '#898DA6' };
		},
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		dropdownIndicator: provided => ({
			...provided,
			'color': '#898DA6',
			':hover': {
				color: '#898DA6',
			},
		}),
	};
	return (
		<Layout>
			<CheckRole roles={[roles.manager, roles.teacher]} />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right">
									<Navigation
										is_dash={true}
										is_btn={true}
										active={id ? t('survey.edit') : t('survey.add')}
										navs={[
											{
												title: t('survey.survey'),
												link: '/manager/surveys',
											},
										]}
									/>
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{t('survey.survey')}</h5>
									</div>
									{loading_data ? (
										<Loading />
									) : (
										<form onSubmit={submitHandler}>
											<div className="card card--shadow" style={{ padding: '0 24px' }}>
												<div className="card-body card-body-diagnostics">
													{/* <h6>{ t('survey.survey') }</h6> */}
													<div className="form-group mb-1">
														<label style={{ margin: '24px 0 8px 0' }} for="">
															{' '}
															{t('survey.name')}{' '}
														</label>
														<textarea
															className="form-control"
															rows="3"
															style={{
																background: 'rgba(137, 141, 166, 0.1)',
																border: 'none',
																borderRadius: 8,
															}}
															name="name"
															value={_.get(survey_data, 'name', '')}
															onChange={e =>
																setSurveyData({ ...survey_data, name: e.target.value })
															}
															onFocus={() => setError({ ...error, name: '' })}></textarea>
														<div style={{ color: 'red' }}>{_.get(error, 'name', '')}</div>
													</div>
													<div className="form-group  mb-1">
														<div className="col-12 col-md-6 pl-0">
															<label style={{ margin: '24px 0 8px 0' }} for="">
																{' '}
																{t('survey.start_time')}{' '}
															</label>
															<input
																type="datetime-local"
																className="form-control"
																style={{
																	background: 'rgba(137, 141, 166, 0.1)',
																	border: 'none',
																	borderRadius: 8,
																}}
																name="start_time"
																value={_.get(survey_data, 'start_time', '')}
																onFocus={() => setError({ ...error, start_time: '' })}
																onChange={e =>
																	setSurveyData({
																		...survey_data,
																		start_time: e.target.value,
																	})
																}
															/>
															<div style={{ color: 'red' }}>
																{_.get(error, 'start_time', '')}
															</div>
														</div>
														<div className="col-12 col-md-6 pl-0">
															<label style={{ margin: '24px 0 8px 0' }} for="">
																{' '}
																{t('survey.end_time')}{' '}
															</label>
															<input
																type="datetime-local"
																className="form-control"
																style={{
																	background: 'rgba(137, 141, 166, 0.1)',
																	border: 'none',
																	borderRadius: 8,
																}}
																name="end_time"
																value={_.get(survey_data, 'end_time', '')}
																onChange={e =>
																	setSurveyData({
																		...survey_data,
																		end_time: e.target.value,
																	})
																}
																onFocus={() => setError({ ...error, end_time: '' })}
															/>
															<div style={{ color: 'red' }}>
																{_.get(error, 'end_time', '')}
															</div>
														</div>
													</div>
													<div
														className="setting__buttons form-group my-2"
														style={{ alignItems: 'center', justifyContent: 'right' }}>
														<label
															style={{ margin: '24px 0 8px 0' }}
															className="switch mx-2">
															<input
																type="checkbox"
																name="status"
																checked={_.get(survey_data, 'status', false)}
																onChange={e =>
																	setSurveyData({
																		...survey_data,
																		status: !_.get(survey_data, 'status', false),
																	})
																}
															/>
															<span className="slider round"></span>
														</label>
														{t('survey.status')}
													</div>
													<div className="form-group mb-1">
														<label style={{ margin: '24px 0 8px 0' }}>
															{t('survey.position_type')}
														</label>
														<div>
															<Select
																value={position_type}
																onChange={select => setPositionTypeSelect(select)}
																options={[
																	{ value: 0, label: t('survey.all') },
																	...position_types,
																]}
																isMulti={true}
																styles={customStyles}
																// closeMenuOnSelect={false}
																placeholder={t('survey.select')}
															/>
														</div>
														<div style={{ color: 'red' }}>
															{_.get(error, 'position_type', '')}
														</div>
													</div>
													
												</div>
											</div>
											<div>
												{loading ? (
													<Loading />
												) : (
													<button className="btn btn-primary mb-1 mr-1 btn-sm">
														{t('survey.btn')}
													</button>
												)}
											</div>
										</form>
									)}
									<br />
									<br />
									<br />
									<br />
									<br />
									<br />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
export default SurveysChange;
