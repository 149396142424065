import React, {useState, useEffect} from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import Pagination from "./sections/Pagination";
import NoData from "./sections/NoData";
import Loading from "./sections/Loading";
import {getInstance} from '../helpers/httpClient'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

const  DashPaymentrHistoryPage = (props) => {
    const [historys, setHistory] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const page = props.match.params.page;

    useEffect(()=>{
        setLoading(true);
        getInstance()
        .get(`/api/v1/user-billing?page=${page}&per_page=15`)
            .then(response => {
                setHistory(response.data);
                setLoading(false);
            })
            .catch(error => console.log(error));
        }, [page]);
  return (<Layout>
      <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('historys_pay.title')}
                                navs={[
                                    {
                                        title: t('navigation.account'),
                                        link: '/dashboard',
                                    },
                                ]}
                            />
                            <div className="dashboard-title">
                            <h5>{t('historys_pay.title')}</h5>
                        </div>
                        {loading?<Loading/>:historys.data&&historys.data.length>0?<>
                        <div className="payments">
                            {historys.data.map((item, index)=>(
                                <div className="card payment" key={index}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col payment__logo">
                                                <span>{t('historys_pay.type')}</span>
                                                <img src={`/images/payment-systems/${item.type}.png`} alt="type"/>
                                            </div>
                                            <div className="col payment__category">
                                                <span>{t('historys_pay.comment')}</span>
                                                <p>{item.comment}</p>
                                            </div>
                                            <div className="col payment__date">
                                                <span>{t('historys_pay.date')}</span>
                                                <p>
                                                    <Moment format="HH:mm DD/MM/YYYY">
                                                        {item.created_at}
                                                    </Moment>
                                                </p>
                                            </div>
                                            <div className="col payment__value">
                                                <span>{t('historys_pay.price')}</span>
                                                <p>{item.sum.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.0','')+t('one_page.sum')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Pagination
                            total={historys.total?historys.total:6}
                            count={15}
                            active={ parseInt(page) }
                            link="/dashboard/payment/history/"
                        />
                        </>:<NoData text={t('historys_pay.no_data')} />}
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

  </Layout>);
}
export default DashPaymentrHistoryPage;