import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts
} from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const ChartLine = (props) => {
    const { t } = useTranslation();
    const {data} = props;
    let cat = _.get(data, 'cat', []);
    
    const chart = {
        marginRight: 0,
    }
    const xAxis = {
        categories: cat
    }
    const yAxis = [{
    		id: "y_axis_0",
        title: {
            text: "",
        }
    }]
    const  plotOptions = {
        column: {
          borderRadius: 5
        }
      }
    const series = _.get(data, 'data', []);
    const chartooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f} '+t('statistics.ta')+'</b><br/>'
    }
    const chartpane = {
        size: '100%'
    }
    const legend = {
      accessibility:{
          enabled:false,
      },
      keyboardNavigation:{
          enabled:true
          }
  }

return (
      <div style={{width: '100%'}}>
      
        <HighchartsChart  
          chart={chart}
          series={ series}
          yAxis={yAxis}
          xAxis = {xAxis}
          plotOptions = {plotOptions}
          tooltip={ chartooltip}
          pane = {chartpane}
          legend = {legend}
        >
        </HighchartsChart>
            
      </div>
    );
  
}

export default withHighcharts(ChartLine, Highcharts);
