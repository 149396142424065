import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PhoneInput from '../PhoneInput';

const MultipleItput = (props) => {
	const { t } = useTranslation();
	const { survey, next_survey, prev_survey, lan, surveyChange, result } = props;
    const [error, setError] = useState('');
    const [error_item, setErrorItem] = useState({});
    const submitHandler = event => {
		event.preventDefault();
        if( _.get(survey, 'isRequired', false) ){
            let c = true;
            let e_l = {};
            _.get(survey, 'items', []).forEach(item => {
                if(item.inputType==='phone'){
                    if( _.get(result, 'value.'+[item.id], '').length!==12 ){
                        c = false;
                        e_l = {...e_l, [item.id]: true};
                    }
                }else{
                    if( !_.get(result, 'value.'+[item.id], '') ){
                        c = false;
                        e_l = {...e_l, [item.id]: true};
                    } 
                }
            });

            if(c) next_survey({id: survey.id, value: _.get(result, 'value', []), type: survey.type  });
            else {
                setError( _.get(survey, `error_${lan}`, '') );
                setErrorItem( e_l );
            }
        }else{
            next_survey({id: survey.id, value: _.get(result, 'value', []), type: survey.type });
        }
    }
    return(
        <form onSubmit={submitHandler}>
                <h4 className="my-5" style={{color: '#898DA6', fontSize: 18}}> <span className="icon icon-question"></span> { t('survey_i.multiple_input') } </h4>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group m-0">
                            <label style={{fontSize: 18,  color: '#2b2b2b', fontFamily: 'Cera Pro', fontStyle: 'normal', fontWeight: 500, lineHeight: '150%' }}> 
                                {_.get(survey, `name_${lan}`, '')}  {_.get(survey, 'isRequired', false)?' *': ''}
                            </label>
                            {_.get(survey, 'items', []).map((item, index)=>(<div key={index}>
                            <label>{_.get(item, `item_${lan}`, '')}</label>
                            {item.inputType==='textarea'?
                                <textarea
                                    className={_.get(error_item, item.id, false)?"form-control p-2 survey-error":" p-2 form-control"}
                                    rows="4"
                                    name="input"
                                    value={_.get(result, 'value.'+[item.id], '')}
                                    onChange={e=>{if(e.target.value.length<=250) surveyChange( {id: survey.id, value: {..._.get(result, 'value', {}), [item.id]: e.target.value } } )}}
                                    onFocus={()=>{setError(''); setErrorItem({...error_item, [item.id]: false}); }}
                                ></textarea>
                            :item.inputType==='phone'?<>
                                <PhoneInput
                                    onClickInput={()=>{setError(''); setErrorItem({...error_item, [item.id]: false}); }}
                                    setPhone={(phone)=>surveyChange( {id: survey.id, value: {..._.get(result, 'value', {}), [item.id]: phone }} )}
                                    value={_.get(result, 'value.'+[item.id], '')}
                                    inputClass={_.get(error_item, item.id, false)?"survey-error":""}
                                />
                                <input name="input" type="hidden" value={_.get(result, 'value.'+[item.id], '')} />
                            </>:item.inputType==='range'?<>
                                <div>
                                    {_.get(result, 'value.'+[item.id], '')?
                                        <span style={{marginLeft: ( parseFloat( _.get(result, 'value.'+[item.id], 0) )*0.98-1)+'%'}} 
                                            className={_.get(error_item, item.id, false)?"survey-range survey-error":"survey-range"}
                                        >
                                            {_.get(result, 'value.'+[item.id], '')}
                                        </span>
                                    :<span className={_.get(error_item, item.id, false)?"form-control  survey-error":"form-control"} style={{width: 100}}> 
                                        { t('survey_i.range') } 
                                    </span>}
                                </div>
                                <input 
                                    type='range'
                                    className="form-control px-0"
                                    name="input"
                                    value={_.get(result, 'value.'+[item.id], 0)}
                                    onChange={e=>surveyChange( {id: survey.id, value: {..._.get(result, 'value', {}), [item.id]: e.target.value } }  )}
                                    onFocus={()=>{setError(''); setErrorItem({...error_item, [item.id]: false}); }}
                                />
                            </>:<input
                                type={_.get(item, 'inputType', 'text')}
                                className={_.get(error_item, item.id, false)?"form-control  survey-error":"form-control"}
                                name="input"
                                value={_.get(result, 'value.'+[item.id], '')}
                                onChange={e=>surveyChange( {id: survey.id, value: {..._.get(result, 'value', {}), [item.id]: e.target.value } } )}
                                onFocus={()=>{setError(''); setErrorItem({...error_item, [item.id]: false}); }}
                            />}
                            </div>))}
                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="btn btn-sm btn-secondary-light mr-2 mb-5" style={{color: '#898DA6'}}
                        onClick={()=>prev_survey(survey.id)}
                    >
                        { t('survey_i.prev') }
                    </span>
                    <button className="btn btn-primary btn-sm  mb-5" style={{float: 'right'}}>
                        { t('survey_i.next') }
                    </button>
                </div>
            </form>
    )
}
export default MultipleItput;