import React, {useState ,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getInstance } from '../../helpers/httpClient';

const ExcelExportModal = props => {
    const { cols, link, parent, get_type, getLoadExcel } = props;
    const { t } = useTranslation();
    const [columns, setColumns] = useState(cols);
    const [err, setErr] = useState(false);
    const [is_disabled, setIsDisabled] = useState(false);
    useEffect(()=>{
        setColumns(cols);
        setErr(false);
    },[cols])
    const setShow = (name) => {
        let cl = [];
        const is_show = _.countBy(columns, rec => { return rec.show===true });
        columns.forEach(col=>{
            if(name===col.name && (is_show.false>1||col.show )) cl.push({...col, show: !col.show});
            else cl.push(col);
        });
        const is_d = _.countBy(cl, rec => { return rec.show===true });
        if(is_d.false<=1) setIsDisabled(true);
        else setIsDisabled(false);
        setColumns(cl);
    }
    const exportExcel = () => {
        setErr(false);
        let s = '';
        columns.forEach(c=>{
            if(!c.show) s +=  '&fields[]='+c.name;
        });
        if(get_type === 'excel_api'){
            getInstance().get(link+s).then(res=>{
                getLoadExcel({
                    data: _.get(res, 'data.data', ''),
                    loading: true
                });
                window.$("#modalExcelExport"+parent).modal('hide');
            }).catch(err=>{setErr(true)})
        }else{
            window.$("#modalExcelExport"+parent).modal('hide');
            window.open( link+s, '_blank' );
        }
    }
    return(<>
        <div class="modal fade" id={"modalExcelExport"+parent} tabindex="-1" role="dialog" aria-labelledby={"modalExcelExport"+parent+"Title"} aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{t('m_pupils.excel_export')}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span class="icon icon-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label for="" class="mb-2 text-center" style={{fontSize: 18}}>{t('m_pupils.excel_text')}</label>
                        <ul className="test__item-answers mb-5">
                            {columns.map((col, index)=>(
                                <li key={index}>
                                    <label className="checkbox-custom">
                                        <input type="checkbox" name='excel'
                                            checked={!col.show}
                                            onChange={e=>setShow(col.name)}
                                            disabled={is_disabled&&!col.show}
                                        />
                                        {col.title}
                                        <span className="checkmark"></span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-lighter btn-sm w-100"  data-dismiss="modal">{t('log_out.cancel')}</button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-primary btn-sm w-100" 
                                    onClick={()=>exportExcel()}
                                >
                                    {t('m_pupils.excel')}
                                </button>
                            </div>
                        </div>
                        <div>
                            {err?<div style={{color: 'red'}}>{t('m_pupils.excel_err')}</div>:''}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}
export default ExcelExportModal;