import React from 'react';
import JoditTextShow from "./JoditTextShow";

const LessonTestMultiple = (props) => {
    const { question, number } = props;
    return (<>
        <div className="test__item card">
            <div className="card-body">
                <div className="test__item-question">
                    <JoditTextShow text={ '<b>'+(number+1)+'.</b> '+question.question } />
                </div>
                <ul className="test__item-answers">
                    {question.vanswers.map((answer, index)=>(
                        <li key={index}>
                            <label className="checkbox-custom">
                                <input type="checkbox" name={ 'answers['+answer.question_id+'][]'}  value={answer.id}/>
                                <JoditTextShow text={ answer.answer } />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>);
}
export default LessonTestMultiple;