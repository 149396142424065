import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts
} from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const ChartColumnH = (props) => {
    const { t } = useTranslation();
    const {survey, result} = props;
    let names = [];
    let percents = [];
    let total_user = 0;
    for(let i = 1; i <= survey.rateMax; i++){
        total_user += parseInt( _.get(result, i, 0 ) );
    }
    total_user += parseInt( _.get(result, 'no_result', 0 ) );

    
    for(let i = 1; i <= survey.rateMax; i++){
        percents.push( parseInt( _.get(result, i, 0 ) ) /total_user*100  );
        names.push( i+' '+ t('survey_r.ball') );
    }

    percents.push( parseInt( _.get(result, 'no_result', 0 ) ) /total_user*100  ) ;
    names.push(  t('survey_r.not_specified')  );
    

    const chart = {
      type: 'bar',
      backgroundColor: '#F7F7F7',
      borderRadius: 7
    }
    const xAxis = {
        categories: names,
        title: {
            text: ''
        }
    }
    const yAxis = {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    }
    const tooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name} <b>{point.y:.1f} %</b><br/>'
    }
    const plotOptions = {
        bar: {
            dataLabels: {
                enabled: true,
                format: '{y:.1f} %'
            },
            borderRadius: 5
        }
    }
    const credits = {
        enabled: false
    }
    const series = [
      {
          name: '',
          data: percents,
          color: '#7CB5EC'
      }
    ]
return (
      <div style={{width: '100%'}}>
        <HighchartsChart  
          chart={chart}
          series={ series}
          yAxis={yAxis}
          xAxis = {xAxis}
          plotOptions = {plotOptions}
          tooltip={ tooltip}
          credits={credits}
        >
        </HighchartsChart>
      </div>
    );
}
export default withHighcharts(ChartColumnH, Highcharts);