import React, { useState, useEffect } from 'react';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import Loading from './sections/Loading';
import Layout from './Layout';
import { useTranslation } from 'react-i18next';
import { getRegionList } from '../actions';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { getInstance } from '../helpers/httpClient';
import _, { isEmpty } from 'lodash';
import { FILE_URL, OFFLINE_EDU_URL } from '../helpers/api';
import { getToken } from '../helpers/tokenStorge';
import Step0 from './sections/dash-edu-institution-page/Step0';
import Step1 from './sections/dash-edu-institution-page/Step1';
import Step2 from './sections/dash-edu-institution-page/Step2';
import Step3 from './sections/dash-edu-institution-page/Step3';
import Step4 from './sections/dash-edu-institution-page/Step4';
import Step5 from './sections/dash-edu-institution-page/Step5';
import Step6 from './sections/dash-edu-institution-page/Step6';
import ShowDisableEduInstCard from './sections/dash-edu-institution-page/ShowDisableEduInstCard';



const DashEduInstitutionPage = (props) => {
  const { t } = useTranslation();
  const { region_list, getRegionList } = props;
  const [edu_loading, setEduLoading] = useState(false);
  const [edu, setEdu] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [showDisable, setShowDisable] = useState(false);

  const [errorEdu, setErrorEdu] = useState({});

  // input states
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);
  const [list4, setList4] = useState([]);
  const [list5, setList5] = useState([]);
  const [list6, setList6] = useState([]);
  const [list7, setList7] = useState([]);
  const [fileApproved, setFileApproved] = useState(null);
  const [file_nameApproved, setFile_nameApproved] = useState('');
  const [fileOrg, setFileOrg] = useState(null);
  const [file_nameOrg, setFileOrg_name] = useState('');
  const [fileMethod, setFileMethod] = useState(null);
  const [file_nameMethod, setFileMethod_name] = useState('');
  const [filePlan, setFilePlan] = useState(null);
  const [file_namePlan, setFilePlan_name] = useState('');
  const [fileProgram, setFileProgram] = useState(null);
  const [file_nameProgram, setFileProgram_name] = useState('');
  const [fileProvision, setFileProvision] = useState(null);
  const [file_nameProvision, setFileProvision_name] = useState('');
  const [fileInfoRes, setFileInfoRes] = useState(null);
  const [file_nameInfoRes, setFileInfoRes_name] = useState('');
  const [address, setAddress] = useState('');
  const [phones, setPhones] = useState(['']);
  const [region, setRegion] = useState(null);
  const [city, setCity] = useState(null);
  const [cities_list, setCities_list] = useState([]);
  const [cityRegion, setCityRegion] = useState(0);
  const [step1Id, setStep1Id] = useState();
  const [experiment_play, setExperiment_play] = useState(1);
  const [filePdfShow, setFilePdfShow] = useState(1);

  // ***step4 states
  const [auditoriums, setAuditoriums] = useState('');
  const [auditoriums_size, setAuditoriums_size] = useState('');
  const [practical, setPractical] = useState('');
  const [practical_size, setPractical_size] = useState('');
  const [computer_room, setComputer_room] = useState('');
  const [computer_size, setComputer_size] = useState('');
  const [foreign_languages, setForeign_languages] = useState('');

  const [fileLabaratoryImg, setFileLabaratoryImg] = useState(null);
  const [file_nameFileLabaratoryImg, setFileLabaratoryImg_name] = useState('');
  const [fileCompRomImg, setFileCompRomImg] = useState(null);
  const [file_nameFileCompRomImg, setFileCompRomImg_name] = useState('');
  const [fileLangImg, setFileLangImg] = useState(null);
  const [file_namefFileLangImg, setFileLangImg_name] = useState('');
  // ***step4 states

  // ***step5 states
  const [note, setNote] = useState(1);
  const [teacher_data, setTeacher_data] = useState(null);
  const [file_nameFileTeacher_data, setFileTeacher_data] = useState('');
  const [note_book, setNote_book] = useState(null);
  const [file_nameFileNote_book, setFileNote_book] = useState('');
  // ***step5 states

  // ***step6 states
  const [bank_name, setBank_name] = useState('');
  const [bank_inn, setBank_inn] = useState('');
  const [mfo, setMfo] = useState('');
  const [account_number, setAccount_number] = useState('');
  // ***step6 states

  // ***step7 states
  const [owner_full_name, SetOwner_full_name] = useState('');
  const [owner_positions, SetOwner_positionse] = useState('');
  // ***step7 states

  // input states

  const [type, setType] = useState({
    label: t('edu_institution.offline'),
    value: 0,
  });

  const [position_types, setPositionTypes] = useState([]);
  const [positions, setPositions] = useState([]);
  const [position_type, setPositionType] = useState([]);
  const [position, setPosition] = useState({});
  const [img_url, setImg_url] = useState('/images/user/upload.png');
  const [offer, setOffer] = useState(false);
  const [address_all, setAddress_all] = useState([]);
  const [inn, setInn] = useState('');
  const [error, setError] = useState({ address_all: {} });

  useEffect(() => {
    getRegionList();
    getPositionType();
    getPositions();
    getEduInstitution();
  }, []);

  const phoneDelete = (id) => {
    let l = [];
    phones.forEach((item, index) => {
      if (index !== id) l.push(item);
    });
    setPhones(l);
  };
  const phoneSet = (id, v) => {
    let l = [];
    phones.forEach((item, index) => {
      if (index !== id) l.push(item);
      else l.push(v);
    });
    setPhones(l);
  };

  const getCitiesList = (id) => {
    if (id !== cityRegion) {
      getInstance()
        .get(`/api/v1/profil/regions?parent_id=${id}`)
        .then((res) => {
          setCities_list(res.data);
          setCityRegion(id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getOptions = (items, v = 'id', l = 'name') => {
    let list = [];
    items.forEach((item) => {
      list.push({ value: _.get(item, v, 0), label: _.get(item, l, 0) });
    });
    return list;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: 'red',
    }),
  };

  const fileChangeApproved = (e) => {
    const fApproved = e.target.files;
    if (fApproved[0]) {
      setFileApproved(fApproved[0]);
      setFile_nameApproved(fApproved[0].name);
    }
  };
  const fileChangeOrg = (e) => {
    const fOrg = e.target.files;
    if (fOrg[0]) {
      setFileOrg(fOrg[0]);
      setFileOrg_name(fOrg[0].name);
    }
  };
  const fileChangeMethod = (e) => {
    const fMethod = e.target.files;
    if (fMethod[0]) {
      setFileMethod(fMethod[0]);
      setFileMethod_name(fMethod[0].name);
    }
  };
  const fileChangePlan = (e) => {
    const fPlan = e.target.files;
    if (fPlan[0]) {
      setFilePlan(fPlan[0]);
      setFilePlan_name(fPlan[0].name);
    }
  };
  const fileChangeProgram = (e) => {
    const fProgram = e.target.files;
    if (fProgram[0]) {
      setFileProgram(fProgram[0]);
      setFileProgram_name(fProgram[0].name);
    }
  };
  const fileChangeProvision = (e) => {
    const fProvision = e.target.files;
    if (fProvision[0]) {
      setFileProvision(fProvision[0]);
      setFileProvision_name(fProvision[0].name);
    }
  };
  const fileChangeInfoRes = (e) => {
    const fInfoRes = e.target.files;
    if (fInfoRes[0]) {
      setFileInfoRes(fInfoRes[0]);
      setFileInfoRes_name(fInfoRes[0].name);
    }
  };
  const filefLabImg = (e) => {
    const fLabImg = e.target.files;
    if (fLabImg[0]) {
      setFileLabaratoryImg(fLabImg[0]);
      setFileLabaratoryImg_name(fLabImg[0].name);
    }
  };
  const fileCompRImg = (e) => {
    const fCompRImg = e.target.files;
    if (fCompRImg[0]) {
      setFileCompRomImg(fCompRImg[0]);
      setFileCompRomImg_name(fCompRImg[0].name);
    }
  };
  const filelangImg = (e) => {
    const filelangIMG = e.target.files;
    if (filelangIMG[0]) {
      setFileLangImg(filelangIMG[0]);
      setFileLangImg_name(filelangIMG[0].name);
    }
  };
  const fileTeacherData = (e) => {
    const fTeacherdata = e.target.files;
    if (fTeacherdata[0]) {
      setTeacher_data(fTeacherdata[0]);
      setFileTeacher_data(fTeacherdata[0].name);
    }
  };
  const fileNoteBook = (e) => {
    const fNotbook = e.target.files;
    if (fNotbook[0]) {
      setNote_book(fNotbook[0]);
      setFileNote_book(fNotbook[0].name);
    }
  };
  const submitTest = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};
    if (!edu.name) {
      err = { ...err, name: true };
      t = false;
    }
    if (!edu.edu_institution_type) {
      err = { ...err, edu_institution_type: true };
      t = false;
    }
    if (!file_nameApproved && isEmpty(edu.certificate)) {
      err = { ...err, file_nameApproved: true };
      t = false;
    }
    if (!file_nameOrg && isEmpty(edu.statutes)) {
      err = { ...err, file_nameOrg: true };
      t = false;
    }
    if (!file_nameMethod && isEmpty(edu.council)) {
      err = { ...err, file_nameMethod: true };
      t = false;
    }
    if (!region) {
      err = { ...err, region: true };
      t = false;
    }
    if (!address) {
      err = { ...err, address: true };
      t = false;
    }
    if (!edu.mail_address) {
      err = { ...err, mail_address: true };
      t = false;
    }
    if (phones.length) {
      phones.forEach((item) => {
        if (item.length !== 12) {
          err = { ...err, phones: true };
          t = false;
        }
      });
    }
    if (!phones.length) {
      err = { ...err, phones: true };
      t = false;
    }

    if (t) {
      const formData = new FormData();
      if (step1Id) formData.append('id', step1Id);
      formData.append('name', _.get(edu, 'name', ''));
      formData.append(
        'edu_institution_type',
        _.get(edu, 'edu_institution_type', '')
      );
      if (file_nameApproved) {
        formData.append('certificate', fileApproved);
      }
      if (file_nameOrg) {
        formData.append('statutes', fileOrg);
      }
      if (file_nameMethod) {
        formData.append('council', fileMethod);
      }
      formData.append('mail_address', _.get(edu, 'mail_address', ''));
      formData.append('region_id', _.get(region, 'value', 0));
      formData.append('city_id', _.get(city, 'value', 0));
      formData.append('address', address);

      if (phones.length) {
        phones.forEach((item) => {
          formData.append('contact[]', item);
        });
      }
      getInstance()
        .post('/api/v1/edu-institution/one', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          const id = _.get(res.data, 'data.id');
          if (status === 1) {
            setList([...list, res.formData]);
            console.log({ list });
            setStep(step + 1);
            setStep1Id(id);
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const submitTest2 = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};

    // if (!edu.url && _.get(type, 'value', 0) === 1) {
    //   err = { ...err, url: true };
    //   t = false;
    // }

    // if (_.get(type, 'value', 0) === 0) {
    let address_allErrors = {};
    address_all.forEach((item, index) => {
      if (!_.get(item, 'region.value', false)) {
        address_allErrors = {
          ...address_allErrors,
          ['region' + index]: true,
        };
        t = false;
      } else {
        if (!_.get(item, 'city.value', false)) {
          address_allErrors = {
            ...address_allErrors,
            ['city' + index]: true,
          };
          t = false;
        } else {
          if (!_.get(item, 'address', false)) {
            address_allErrors = {
              ...address_allErrors,
              ['address' + index]: true,
            };
            t = false;
          }
        }
      }
    });
    err = { ...err, address_all: address_allErrors };
    // }

    if (position_type.length > 0) {
      position_type.forEach((item) => {
        if (!_.get(position, item.value, []).length) {
          err = { ...err, ['position_' + item.value]: true };
          t = false;
        }
      });
    } else {
      t = false;
      err = { ...err, position_type: true };
    }

    const formData = new FormData();
    if (step1Id) formData.append('id', step1Id);
    // formData.append('url', _.get(edu, 'url', ''));
    formData.append(
      'experimental_playground',
      edu?.experimental_playground ? edu?.experimental_playground : 0
    );

    // if (_.get(type, 'value', 0) === 0 || _.get(type, 'value', 0) === 1) {
    formData.append('is_online', _.get(type, 'value', 0));
    console.log(type);
    // }
    if (position_type.length) {
      position_type.forEach((item) => {
        if (_.get(position, item.value, []).length) {
          _.get(position, item.value, []).forEach((p_item) => {
            formData.append('positions[]', _.get(p_item, 'value', 0));
          });
        }
      });
    }

    let address_allList = [];
    // if (_.get(type, 'value', 0) === 0) {
    address_all.forEach((item, _) => {
      address_allList.push({
        ...(item.id ? { id: item.id } : {}),
        region_id: item.region.value,
        city_id: item.city.value,
        address: item.address,
      });
    });
    // }
    buildFormData(formData, address_allList, 'address_all');
    if (t) {
      getInstance()
        .post('/api/v1/edu-institution/two', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          if (status === 1) {
            setList2([...list2, res.formData]);
            setStep(step + 1);
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const submitTest3 = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};

    if (!file_namePlan && isEmpty(edu.plan)) {
      err = { ...err, filePlan: true };
      t = false;
    }

    if (!file_nameProgram && isEmpty(edu.program)) {
      err = { ...err, fileProgram: true };
      t = false;
    }

    if (!file_nameProvision && isEmpty(edu.provision)) {
      err = { ...err, fileProvision: true };
      t = false;
    }

    if (
      !file_nameInfoRes &&
      filePdfShow === 1 &&
      isEmpty(edu.information_resource)
    ) {
      err = { ...err, fileInfoRes: true };
      t = false;
    }

    const formData = new FormData();
    if (step1Id) formData.append('id', step1Id);

    // formData.append('plan_compatibility', _.get(edu, 'plan_compatibility', ''));

    if (filePlan) {
      formData.append('plan', filePlan);
    }

    if (fileProgram) {
      formData.append('program', fileProgram);
    }
    if (fileProvision) {
      formData.append('provision', fileProvision);
    }
    if (filePdfShow === 1 && file_nameInfoRes) {
      formData.append('information_resource', fileInfoRes);
    }

    if (t) {
      getInstance()
        .post('/api/v1/edu-institution/three', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          if (status === 1) {
            setList3([...list3, res.formData]);
            setStep(step + 1);
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const submitTest4 = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};

    if (!edu.auditoriums) {
      err = { ...err, auditoriums: true };
      t = false;
    }
    if (!edu.auditoriums_size) {
      err = { ...err, auditoriums_size: true };
      t = false;
    }
    if (!edu.practical) {
      err = { ...err, practical: true };
      t = false;
    }
    if (!edu.practical_size) {
      err = { ...err, practical_size: true };
      t = false;
    }
    if (
      !file_nameFileLabaratoryImg &&
      _.get(edu, 'laboratory', '') === 1 &&
      isEmpty(edu.laboratory_image)
    ) {
      err = { ...err, fileLabaratoryImg: true };
      t = false;
    }
    if (!edu.computer_room) {
      err = { ...err, computer_room: true };
      t = false;
    }
    if (!file_nameFileCompRomImg && isEmpty(edu.computer_room_image)) {
      err = { ...err, fileCompRomImg: true };
      t = false;
    }
    if (!edu.computer_size) {
      err = { ...err, computer_size: true };
      t = false;
    }
    if (!edu.foreign_languages) {
      err = { ...err, foreign_languages: true };
      t = false;
    }
    if (!file_namefFileLangImg && isEmpty(edu.foreign_languages_image)) {
      err = { ...err, fileLangImg: true };
      t = false;
    }

    const formData = new FormData();
    if (step1Id) formData.append('id', step1Id);
    formData.append('auditoriums', _.get(edu, 'auditoriums', ''));
    formData.append('auditoriums_size', _.get(edu, 'auditoriums_size', ''));
    formData.append('practical', _.get(edu, 'practical', ''));
    formData.append('practical_size', _.get(edu, 'practical_size', ''));
    formData.append('laboratory', _.get(edu, 'laboratory', ''));
    if (_.get(edu, 'laboratory', '') === 1 && fileLabaratoryImg) {
      formData.append('laboratory_image', fileLabaratoryImg);
    }
    formData.append('computer_room', _.get(edu, 'computer_room', ''));
    if (fileCompRomImg) {
      formData.append('computer_room_image', fileCompRomImg);
    }
    formData.append('computer_size', _.get(edu, 'computer_size', ''));
    formData.append('foreign_languages', _.get(edu, 'foreign_languages', ''));
    if (fileLangImg) {
      formData.append('foreign_languages_image', fileLangImg);
    }

    if (t) {
      getInstance()
        .post('/api/v1/edu-institution/four', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          if (status === 1) {
            setList4([...list4, res.formData]);
            setStep(step + 1);
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const submitTest5 = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};

    if (!file_nameFileTeacher_data && isEmpty(edu.teacher_data)) {
      err = { ...err, teacher_data: true };
      t = false;
    }
    if (
      !file_nameFileNote_book &&
      _.get(edu, 'note', '') === 1 &&
      isEmpty(edu.note_book)
    ) {
      err = { ...err, note_book: true };
      t = false;
    }

    const formData = new FormData();
    if (step1Id) formData.append('id', step1Id);

    if (teacher_data) {
      formData.append('teacher_data', teacher_data);
    }
    formData.append('note', _.get(edu, 'note', ''));
    if (_.get(edu, 'note', '') === 1 && note_book) {
      formData.append('note_book', note_book);
    }

    if (t) {
      getInstance()
        .post('/api/v1/edu-institution/five', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          if (status === 1) {
            setList5([...list5, res.formData]);
            setStep(step + 1);
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const submitTest6 = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};

    if (!edu.bank_name) {
      err = { ...err, bank_name: true };
      t = false;
    }
    if (!edu.mfo) {
      err = { ...err, mfo: true };
      t = false;
    }
    if (edu?.bank_inn?.length !== 9) {
      err = { ...err, bank_inn: true };
      t = false;
    }
    if (!edu.account_number) {
      err = { ...err, account_number: true };
      t = false;
    }

    const formData = new FormData();
    if (step1Id) formData.append('id', step1Id);
    formData.append('account_number', _.get(edu, 'account_number', ''));
    formData.append('bank_name', _.get(edu, 'bank_name', ''));
    formData.append('mfo', _.get(edu, 'mfo', ''));
    formData.append('bank_inn', _.get(edu, 'bank_inn', ''));

    if (t) {
      getInstance()
        .post('/api/v1/edu-institution/six', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          if (status === 1) {
            setList6([...list6, res.formData]);
            setStep(step + 1);
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const submitTest7 = (e) => {
    e.preventDefault();
    setLoading(true);

    let t = true,
      err = {};

    if (!edu.owner_full_name) {
      err = { ...err, owner_full_name: true };
      t = false;
    }
    if (!edu.owner_positions) {
      err = { ...err, owner_positions: true };
      t = false;
    }

    const formData = new FormData();
    if (step1Id) formData.append('id', step1Id);
    formData.append('owner_full_name', _.get(edu, 'owner_full_name', ''));
    formData.append('owner_positions', _.get(edu, 'owner_positions', ''));

    if (t) {
      getInstance()
        .post('/api/v1/edu-institution/seven', formData)
        .then((res) => {
          const status = _.get(res, 'data.status');
          if (status === 1) {
            setList7([...list7, res.formData]);
            setEdu({ ...edu, status: 4, id: true });
            setDisabled(false);
            window.location.reload();
            window.scroll(0, 0);
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorEdu(err);
      setLoading(false);
    }
  };

  const getPositionType = () => {
    getInstance()
      .get('/api/v1/profil/position-type')
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            let l = [];
            if (data.length) {
              data.forEach((item) => {
                l = [
                  ...l,
                  {
                    value: item.id,
                    label: item.name,
                    online: item.online,
                    offline: item.offline,
                  },
                ];
              });
            }
            setPositionTypes(l);
          } catch {
            setPositionTypes([]);
          }
        }
      })
      .catch((error) => {
        setPositionTypes([]);
      });
  };
  const getPositions = () => {
    getInstance()
      .get(`/api/v1/profil/user-position`)
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            let l = [];
            if (data.length) {
              data.forEach((item) => {
                l = [
                  ...l,
                  {
                    value: item.id,
                    label: item.name,
                    type_id: item.type_id,
                    online: item.online,
                    offline: item.offline,
                  },
                ];
              });
            }
            setPositions(l);
          } catch {
            setPositions([]);
          }
        }
      })
      .catch((error) => {
        setPositions([]);
      });
  };
  const setPositionTypeSelect = (s) => {
    let all = false;
    s.forEach((item) => {
      if (item.value === 0) all = true;
    });
    if (all)
      setPositionType(
        _.filter(position_types, (i) => {
          return (
            (type.value === 1 && i.online === 1) ||
            (type.value === 0 && i.offline === 1)
          );
        })
      );
    else {
      const sl = _.sortBy(s, (e) => {
        return e.value;
      });
      setPositionType(sl);
    }
    setError({ ...error, position_type: '' });
  };
  const setPositionSelect = (s, type_id) => {
    let all = false;
    s.forEach((item) => {
      if (item.value === 0) all = true;
    });
    if (all)
      setPosition({
        ...position,
        [type_id]: _.filter(positions, (i) => {
          return (
            type_id === i.type_id &&
            ((type.value === 1 && i.online === 1) ||
              (type.value === 0 && i.offline === 1))
          );
        }),
      });
    else {
      const sl = _.sortBy(s, (e) => {
        return e.id;
      });
      setPosition({ ...position, [type_id]: sl });
    }
    setError({ ...error, position: { ...error.position, [type_id]: '' } });
  };

  const imgChange = (evt) => {
    const [file] = evt.target.files;
    let src = '';
    if (file) src = URL.createObjectURL(file);
    setImg_url(src);
  };

  const buildFormData = (formData, data, parentKey) => {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  };
  // const submitHandler = (event) => {
  //   setLoading(true);
  //   event.preventDefault();
  //   let formData = new FormData(event.target);
  //   const name = formData.get('name');
  //   const mail = formData.get('mail');
  //   const desc = formData.get('desc');
  //   const url = formData.get('url');
  //   const address = formData.get('address');
  //   const logo = formData.get('logo');
  //   const file = formData.get('file');
  //   const edu_institution_type = formData.get('edu_institution_type');
  //   const inn = formData.get('inn');
  //   // const mail_address = formData.get('mail_address');
  //   const account_number = formData.get('account_number');
  //   const bank_name = formData.get('bank_name');
  //   const mfo = formData.get('mfo');
  //   const bank_inn = formData.get('bank_inn');
  //   const owner_full_name = formData.get('owner_full_name');
  //   const owner_positions = formData.get('owner_positions');

  //   if (!_.get(edu, 'id', false)) formData.delete('id');
  //   let n = false,
  //     m = false,
  //     d = false,
  //     u = false,
  //     a = false,
  //     r = false,
  //     c = false,
  //     t = false,
  //     /* l=false,*/ f = false,
  //     pt = false,
  //     p = true,
  //     pn = true,
  //     it = false,
  //     i = false,
  //     /*ma=false,*/ an = false,
  //     bn = false,
  //     mf = false,
  //     bi = false,
  //     of = false,
  //     op = false,
  //     o = false,
  //     a_all = true;
  //   let validation = { ...error };
  //   if (name) n = true;
  //   else validation = { ...validation, name: true };
  //   if (edu_institution_type) it = true;
  //   else validation = { ...validation, edu_institution_type: true };
  //   // if (inn.length === 9) i = true;
  //   // else validation = { ...validation, inn: true };
  //   // if(mail_address) ma=true;
  //   // else  validation = {...validation, mail_address: true};
  //   if (account_number) an = true;
  //   else validation = { ...validation, account_number: true };
  //   if (bank_name) bn = true;
  //   else validation = { ...validation, bank_name: true };
  //   // if (mfo.length === 5) mf = true;
  //   // else validation = { ...validation, mfo: true };
  //   // if (bank_inn.length === 9) bi = true;
  //   // else validation = { ...validation, bank_inn: true };
  //   if (owner_full_name) of = true;
  //   else validation = { ...validation, owner_full_name: true };
  //   if (owner_positions) op = true;
  //   else validation = { ...validation, owner_positions: true };
  //   // if(offer) o=true;
  //   // else  validation = {...validation, offer: true};
  //   if (mail) {
  //     m = true;
  //     formData.append('contact[]', mail);
  //   } else validation = { ...validation, mail: true };
  //   d = true;
  //   // if(desc) d=true;
  //   // else validation = {...validation, desc: true};
  //   if (url || _.get(type, 'value', 0) === 0) u = true;
  //   else validation = { ...validation, url: true };
  //   if (address) a = true;
  //   else validation = { ...validation, address: true };
  //   if (_.get(region, 'value', 0)) {
  //     r = true;
  //     formData.append('region_id', _.get(region, 'value', 0));
  //   } else validation = { ...validation, region: true };
  //   if (_.get(city, 'value', 0)) {
  //     c = true;
  //     formData.append('city_id', _.get(city, 'value', 0));
  //   } else validation = { ...validation, city: true };
  //   if (_.get(type, 'value', 0) === 0 || _.get(type, 'value', 0) === 1) {
  //     t = true;
  //     formData.append('is_online', _.get(type, 'value', 0));
  //   } else validation = { ...validation, type: true };
  //   // if(_.get(logo, 'size', 0) || edu.logo) l=true;
  //   // else  validation = {...validation, logo: true};
  //   if (_.get(file, 'size', 0) || edu.file) f = true;
  //   else validation = { ...validation, file: true };
  //   if (position_type.length) {
  //     pt = true;
  //     position_type.forEach((item) => {
  //       if (_.get(position, item.value, []).length) {
  //         _.get(position, item.value, []).forEach((p_item) => {
  //           formData.append('positions[]', _.get(p_item, 'value', 0));
  //         });
  //       } else {
  //         p = false;
  //         validation = { ...validation, ['position_' + item.value]: true };
  //       }
  //     });
  //   } else validation = { ...validation, position_type: true };
  //   if (phones.length) {
  //     phones.forEach((item) => {
  //       if (item.length !== 12) {
  //         validation = { ...validation, phones: true };
  //         pn = false;
  //       } else {
  //         formData.append('contact[]', item);
  //       }
  //     });
  //   } else {
  //     validation = { ...validation, phones: true };
  //     pn = false;
  //   }

  //   let address_allList = [];
  //   if (_.get(type, 'value', 0) === 0) {
  //     let address_allErrors = {};
  //     address_all.forEach((item, index) => {
  //       if (!_.get(item, 'region.value', false)) {
  //         address_allErrors = {
  //           ...address_allErrors,
  //           ['region' + index]: true,
  //         };
  //         a_all = false;
  //       } else {
  //         if (!_.get(item, 'city.value', false)) {
  //           address_allErrors = {
  //             ...address_allErrors,
  //             ['city' + index]: true,
  //           };
  //           a_all = false;
  //         } else {
  //           if (!_.get(item, 'address', false)) {
  //             address_allErrors = {
  //               ...address_allErrors,
  //               ['address' + index]: true,
  //             };
  //             a_all = false;
  //           }
  //         }
  //       }
  //       if (a_all)
  //         address_allList.push({
  //           ...(item.id ? { id: item.id } : {}),
  //           region_id: item.region.value,
  //           city_id: item.city.value,
  //           address: item.address,
  //         });
  //     });
  //     validation = { ...validation, address_all: address_allErrors };
  //   }
  //   buildFormData(formData, address_allList, 'address_all');
  //   // console.log('============>', n , m,  d , u , a, r , c , t ,/* l &&*/ f, pt, p, pn , it , i, /*ma &&*/ an, bn , mf ,bi, of, op, o, a_all);
  //   if (
  //     n &&
  //     m &&
  //     d &&
  //     u &&
  //     a &&
  //     r &&
  //     c &&
  //     t &&
  //     /* l &&*/ f &&
  //     pt &&
  //     p &&
  //     pn &&
  //     it &&
  //     i &&
  //     /*ma &&*/ an &&
  //     bn &&
  //     mf &&
  //     bi &&
  //     of &&
  //     op &&
  //     /*o &&*/ a_all
  //   ) {
  //     getInstance()
  //       .post('/api/v1/edu-institution', formData)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           try {
  //             const { data } = response.data;
  //             setLoading(false);
  //             setDisabled(true);
  //             setEdu({ ...edu, status: 4 });
  //             window.scroll({
  //               top: 0,
  //               left: 0,
  //               behavior: 'smooth',
  //             });
  //           } catch {
  //             validation = { ...validation, main: true };
  //             window.scroll({
  //               top: 0,
  //               left: 0,
  //               behavior: 'smooth',
  //             });
  //             setLoading(false);
  //             setError(validation);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         validation = { ...validation, main: true };
  //         window.scroll({
  //           top: 0,
  //           left: 0,
  //           behavior: 'smooth',
  //         });
  //         setLoading(false);
  //         setError(validation);
  //       });
  //   } else {
  //     validation = { ...validation, main: true };
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //     setLoading(false);
  //     setError(validation);
  //   }
  // };
  const [logs, setLogs] = useState([]);
  const [openLogs, setOpenLogs] = useState(false);
  const getEduInstitution = () => {
    setEduLoading(true);
    getInstance()
      .get('/api/v1/offline-education/edu-institution')
      .then((response) => {
        if (response.status === 200) {
          try {
            if (_.get(response, 'data.data.status', 0) === 1) {
              const token = getToken();
              window.location.href = `${OFFLINE_EDU_URL}/st/${token}`;
            }
            setLogs(_.get(response, 'data.logs', []));
            setEduData(_.get(response, 'data.data', {}));
            if (_.get(response, 'data.data.id', false))
              setStep1Id(_.get(response, 'data.data.id'));
            else {
              setShowDisable(true);
            }
            setEduLoading(false);
          } catch {
            setEduLoading(false);
          }
        }
      })
      .catch((error) => {
        setEduLoading(false);
      });
  };
  const setEduData = (d) => {
    if (_.get(d, 'id', false)) setDisabled(true);
    let c = JSON.parse(_.get(d, 'contact', '[]'));
    const mail = _.get(c, '[0]', '');
    // if (c.length > 1) c.shift();
    // else c = [''];
    setPhones(c);
    setImg_url(FILE_URL + _.get(d, 'logo', ''));
    // setFile_name(FILE_URL + _.get(d, 'file', ''));
    // setType(
    //   _.get(d, 'is_online', 0) === 0
    //     ? { label: t('edu_institution.offline'), value: 0 }
    //     : { label: t('edu_institution.online'), value: 1 }
    // );
    setType({ label: t('edu_institution.offline'), value: 0 });
    const p = _.get(d, 'positions', []);
    let pt_list = [],
      p_types = [];
    p.forEach((item) => {
      pt_list.push(item.type);
    });
    _.unionBy(pt_list, 'id').forEach((item) => {
      p_types.push({ value: item.id, label: item.name });
    });
    const pt = _.sortBy(p_types, [
      function (o) {
        return o.value;
      },
    ]);
    setPositionType(pt);
    let p_data = {};
    pt.forEach((item) => {
      let p_list = [];
      p.forEach((p_child) => {
        if (item.value === p_child.type_id)
          p_list.push({ value: p_child.id, label: p_child.name });
      });
      p_data = { ...p_data, [item.value]: p_list };
      p_list = [];
    });
    setPosition(p_data);
    getCitiesList(_.get(d, 'region.id', 0));
    setRegion({
      value: _.get(d, 'region.id', null),
      label: _.get(d, 'region.name', ''),
    });
    setCity({
      value: _.get(d, 'city.id', null),
      label: _.get(d, 'city.name', ''),
    });
    setAddress(_.get(d, 'address', ''));
    setEdu({ ...d, mail: mail });
    if (_.get(d, 'address_all', []).length) {
      let a_list = [];
      _.get(d, 'address_all', []).forEach((item) => {
        a_list.push({
          id: _.get(item, 'id', null),
          region: {
            value: _.get(item, 'region_id', null),
            label: _.get(item, 'region.name', null),
          },
          city: {
            value: _.get(item, 'city_id', null),
            label: _.get(item, 'city.name', null),
          },
          address: _.get(item, 'address', null),
        });
      });
      setAddress_all(a_list);
    } else {
      setAddress_all([
        {
          region: { value: null, label: null },
          city: { value: null, label: null },
          address: '',
        },
      ]);
    }
    setBank_inn(_.get(d, 'bank_inn', ''));
    setInn(_.get(d, 'inn', ''));
    setMfo(_.get(d, 'mfo', ''));
    setAccount_number(_.get(d, 'account_number', ''));
  };
  const addressAllSetItem = (value, num, key) => {
    let l = [];
    address_all.forEach((item, index) => {
      if (index === num) {
        if (key === 'region')
          l.push({
            ...item,
            region: value,
            city: { value: null, label: null },
            address: '',
          });
        else if (key === 'city') l.push({ ...item, city: value, address: '' });
        else l.push({ ...item, [key]: value });
      } else {
        l.push(item);
      }
    });
    setAddress_all(l);
  };
  const editEdu = () => {
    setDisabled(!disabled);
    setLoading(false);
    setShowDisable(!showDisable);
  };
  const removeAllAddressItem = (num) => {
    let new_list = [];
    address_all.forEach((item, index) => {
      if (num !== index) new_list.push(item);
    });
    setAddress_all(new_list);
  };

  const checkNumber = (v) =>
    v.length === 0 || (/^-?\d+$/.test(v) && parseInt(v) >= 0);

  return (
    <Layout>
      {/* <CheckRole roles={ [ roles.edu_institution] } /> */}
      <main id='edu-institution' className='main dashEduInstMain'>
        <section className='section section-dashboard py-0'>
          <div className='container'>
            <div className='row'>
              <DashSideBar />
              <div className='col-md-12 col-lg-9 col-xl-10'>
                <div className='dashboard-right'>
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t('edu_institution.title')}
                  />
                  <div className='dashboard-title'>
                    <h5>{t('edu_institution.title')}</h5>
                    {edu.id ? (
                      <div className='mb-5'>
                        {edu.step === 7 ? (
                          <span
                            style={
                              edu.status === 2
                                ? { color: 'orange', fontWeight: 500 }
                                : edu.status === 3
                                ? { color: 'red', fontWeight: 500 }
                                : { color: '#26caac', fontWeight: 500 }
                            }
                          >
                            <span class='icon icon-paper-check mr-2'></span>
                            {edu.status === 0
                              ? t('edu_institution.save')
                              : edu.status === 1
                              ? t('edu_institution.success')
                              : edu.status === 2
                              ? t('edu_institution.check')
                              : edu.status === 3
                              ? t('edu_institution.failed')
                              : edu.status === 4
                              ? t('edu_institution.set')
                              : ''}
                          </span>
                        ) : null}

                        {edu.status === 0 ||
                        edu.status === 4 ||
                        edu.status === 3 ? (
                          <button
                            className='btn btn-sm btn-primary'
                            style={{
                              float: 'right',
                              marginBottom: '10px',
                              marginTop: '10px',
                            }}
                            onClick={editEdu}
                          >
                            {disabled ? (
                              <span class='icon icon-edit mr-2'></span>
                            ) : (
                              <span class='icon icon-close mr-2'></span>
                            )}
                            {t('edu_institution.edit')}
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  

                  {edu_loading ? (
                    <div className='card' style={{ width: '100%' }}>
                      <div className='card-body'>
                        <div className='mb-5'>
                          <Loading />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                    {logs.length>0?
                      <div>
                        <div className='card' style={{ width: '100%' }}>
                          <div className='card-body'>
                            <h5 className='flex__target justify-content-between'>
                              {t('edu_list.edu_check_history')}
                              {/* <button className='btn btn-sm btn-primary'><span class="icon icon-eye"></span> {t( 'edu_institution.eye')}</button> */}
                              <button className='btn btn-sm btn-primary' onClick={()=>setOpenLogs(!openLogs)}>
                                <span className={openLogs?"icon icon-arrow-up":"icon icon-arrow-down-1"}></span>
                              </button>
                            </h5>
                            {openLogs?
                            <div>
                              {logs.map((item, index)=>{
                                const log_json = JSON.parse(_.get(item, 'data', '{}'))
                                return(<div key={index}>
                                  <h6>
                                   <Moment format="HH:mm DD-MM-YYYY" className='mr-2'>
                                      { item.created_at  }
                                    </Moment>

                                  {log_json.status === "0" ? (
                                      <div className="edu_status edu_status_new">
                                        {t( 'edu_list.no_check'  )}
                                      </div>
                                    ) : log_json.status === "1" ? (
                                      <div className="edu_status">
                                        {t( 'edu_list.succes'  )}
                                      </div>
                                    ) : log_json.status === "2" ? (
                                      <div className="edu_status edu_status_check">
                                        {t(  'edu_list.check' )}
                                      </div>
                                    ) : log_json.status === "3" ? (
                                      <div className="edu_status edu_status_faild">
                                        {t(  'edu_list.failed'  )}
                                      </div>
                                    ) : ''}
                                    {_.get(log_json, 'file', '')?
                                    <a href={ FILE_URL+_.get(log_json, 'file', '') } className='edu_status btn-info ml-2' 
                                      style={{color: '#fff', borderColor: '#3C8E9D'}} >
                                        {t('edu_list.pratokol')}
                                    </a>
                                    :''}                                    
                                  </h6>
                                  <p>{_.get(log_json, 'desc', '')}</p>
                                </div>)
                              })}
                            </div>:''}
                          </div>
                        </div>
                      </div>:""}
                      {showDisable === true ? (
                        <div className='card' style={{ width: '100%' }}>
                          <div className='card-body'>
                            <>
                              <div className='step_bar_target py-2'>
                                <div className='step_bar'>
                                  <div
                                    style={{ width: step * (100 / 6) + '%' }}
                                  ></div>
                                </div>
                                <div className='step_points'>
                                  <div
                                    style={
                                      step >= 0 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                  <div
                                    style={
                                      step >= 1 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                  <div
                                    style={
                                      step >= 2 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                  <div
                                    style={
                                      step >= 3 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                  <div
                                    style={
                                      step >= 4 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                  <div
                                    style={
                                      step >= 5 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                  <div
                                    style={
                                      step >= 6 ? { background: '#26caac' } : {}
                                    }
                                  ></div>
                                </div>
                              </div>

                              {step === 0 ? (
                                <>
                                  <Step0
                                    edu={edu}
                                    setEdu={setEdu}
                                    phones={phones}
                                    phoneSet={phoneSet}
                                    phoneDelete={phoneDelete}
                                    setRegion={setRegion}
                                    getCitiesList={getCitiesList}
                                    setCity={setCity}
                                    region={region}
                                    getOptions={getOptions}
                                    region_list={region_list}
                                    cityRegion={cityRegion}
                                    cities_list={cities_list}
                                    customStyles={customStyles}
                                    setPhones={setPhones}
                                    disabled={disabled}
                                    city={city}
                                    setStep={setStep}
                                    step={step}
                                    loading={loading}
                                    submitTest={submitTest}
                                    fileChangeApproved={fileChangeApproved}
                                    fileChangeOrg={fileChangeOrg}
                                    fileChangeMethod={fileChangeMethod}
                                    file_nameApproved={file_nameApproved}
                                    file_nameOrg={file_nameOrg}
                                    file_nameMethod={file_nameMethod}
                                    FILE_URL={FILE_URL}
                                    address={address}
                                    setAddress={setAddress}
                                    setErrorEdu={setErrorEdu}
                                    errorEdu={errorEdu}
                                    fileApproved={fileApproved}
                                  />
                                </>
                              ) : step === 1 ? (
                                <>
                                  <Step1
                                    edu={edu}
                                    getCitiesList={getCitiesList}
                                    getOptions={getOptions}
                                    region_list={region_list}
                                    cityRegion={cityRegion}
                                    cities_list={cities_list}
                                    customStyles={customStyles}
                                    disabled={disabled}
                                    setStep={setStep}
                                    step={step}
                                    type={type}
                                    setType={setType}
                                    address_all={address_all}
                                    removeAllAddressItem={removeAllAddressItem}
                                    addressAllSetItem={addressAllSetItem}
                                    setAddress_all={setAddress_all}
                                    position_type={position_type}
                                    position={position}
                                    setPositionSelect={setPositionSelect}
                                    positions={positions}
                                    position_types={position_types}
                                    setPositionTypeSelect={
                                      setPositionTypeSelect
                                    }
                                    submitTest2={submitTest2}
                                    experiment_play={experiment_play}
                                    setExperiment_play={setExperiment_play}
                                    loading={loading}
                                    setErrorEdu={setErrorEdu}
                                    setEdu={setEdu}
                                    errorEdu={errorEdu}
                                  />
                                </>
                              ) : step === 2 ? (
                                <>
                                  <Step2
                                    disabled={disabled}
                                    setStep={setStep}
                                    step={step}
                                    submitTest3={submitTest3}
                                    filePdfShow={filePdfShow}
                                    setFilePdfShow={setFilePdfShow}
                                    file_namePlan={file_namePlan}
                                    file_nameProgram={file_nameProgram}
                                    file_nameProvision={file_nameProvision}
                                    file_nameInfoRes={file_nameInfoRes}
                                    fileChangePlan={fileChangePlan}
                                    fileChangeProgram={fileChangeProgram}
                                    fileChangeProvision={fileChangeProvision}
                                    fileChangeInfoRes={fileChangeInfoRes}
                                    FILE_URL={FILE_URL}
                                    edu={edu}
                                    setFileOrg={setFileOrg}
                                    loading={loading}
                                    setEdu={setEdu}
                                    setErrorEdu={setErrorEdu}
                                    errorEdu={errorEdu}
                                  />
                                </>
                              ) : step === 3 ? (
                                <>
                                  <Step3
                                    disabled={disabled}
                                    setStep={setStep}
                                    step={step}
                                    setAuditoriums={setAuditoriums}
                                    auditoriums={auditoriums}
                                    setAuditoriums_size={setAuditoriums_size}
                                    auditoriums_size={auditoriums_size}
                                    setPractical={setPractical}
                                    practical={practical}
                                    setPractical_size={setPractical_size}
                                    practical_size={practical_size}
                                    setForeign_languages={setForeign_languages}
                                    foreign_languages={foreign_languages}
                                    setComputer_room={setComputer_room}
                                    computer_room={computer_room}
                                    setComputer_size={setComputer_size}
                                    computer_size={computer_size}
                                    edu={edu}
                                    FILE_URL={FILE_URL}
                                    file_nameFileLabaratoryImg={
                                      file_nameFileLabaratoryImg
                                    }
                                    file_nameFileCompRomImg={
                                      file_nameFileCompRomImg
                                    }
                                    file_namefFileLangImg={
                                      file_namefFileLangImg
                                    }
                                    filefLabImg={filefLabImg}
                                    fileCompRImg={fileCompRImg}
                                    filelangImg={filelangImg}
                                    checkNumber={checkNumber}
                                    submitTest4={submitTest4}
                                    loading={loading}
                                    errorEdu={errorEdu}
                                    setErrorEdu={setErrorEdu}
                                    setEdu={setEdu}
                                  />
                                </>
                              ) : step === 4 ? (
                                <>
                                  <Step4
                                    disabled={disabled}
                                    setStep={setStep}
                                    step={step}
                                    FILE_URL={FILE_URL}
                                    edu={edu}
                                    fileTeacherData={fileTeacherData}
                                    file_nameFileTeacher_data={
                                      file_nameFileTeacher_data
                                    }
                                    fileNoteBook={fileNoteBook}
                                    file_nameFileNote_book={
                                      file_nameFileNote_book
                                    }
                                    submitTest5={submitTest5}
                                    note={note}
                                    setNote={setNote}
                                    loading={loading}
                                    errorEdu={errorEdu}
                                    setErrorEdu={setErrorEdu}
                                    setEdu={setEdu}
                                  />
                                </>
                              ) : step === 5 ? (
                                <>
                                  <Step5
                                    edu={edu}
                                    disabled={disabled}
                                    setStep={setStep}
                                    step={step}
                                    bank_inn={bank_inn}
                                    setBank_inn={setBank_inn}
                                    checkNumber={checkNumber}
                                    setMfo={setMfo}
                                    mfo={mfo}
                                    account_number={account_number}
                                    setAccount_number={setAccount_number}
                                    submitTest6={submitTest6}
                                    bank_name={bank_name}
                                    setBank_name={setBank_name}
                                    loading={loading}
                                    errorEdu={errorEdu}
                                    setErrorEdu={setErrorEdu}
                                    setEdu={setEdu}
                                  />
                                </>
                              ) : step === 6 ? (
                                <>
                                  <Step6
                                    edu={edu}
                                    disabled={disabled}
                                    setStep={setStep}
                                    step={step}
                                    loading={loading}
                                    owner_full_name={owner_full_name}
                                    SetOwner_full_name={SetOwner_full_name}
                                    owner_positions={owner_positions}
                                    SetOwner_positionse={SetOwner_positionse}
                                    submitTest7={submitTest7}
                                    errorEdu={errorEdu}
                                    setErrorEdu={setErrorEdu}
                                    setEdu={setEdu}
                                  />
                                </>
                              ) : null}
                            </>
                          </div>
                        </div>
                      ) : (
                        <div className='card' style={{ width: '100%' }}>
                          <div className='card-body'>
                            <ShowDisableEduInstCard
                              edu={edu}
                              FILE_URL={FILE_URL}
                              phones={phones}
                              filePdfShow={filePdfShow}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
export default connect(
  (state) => ({
    region_list: state.region_list,
  }),
  (dispatch) => ({
    getRegionList: () => dispatch(getRegionList()),
  })
)(DashEduInstitutionPage);
