const  Map = {
    name: "Andijon viloyati",
    slug: 'andijan_region',
    viewBox: "0 0 402 303",
    map_data: [
        {
            city_name: "Ulug'nor tumani",
            slug: 'ulugnor-tumani',
            data: "M64.2273 179.152L74.326 197.425C71.838 187.908 67.301 166.59 69.0573 157.454C71.2527 146.034 71.2527 143.75 73.4481 138.04C75.6435 132.33 82.2297 132.33 84.8641 129.475C87.4984 126.619 76.5214 122.623 71.2527 118.055C65.984 113.486 64.2273 106.635 59.8365 98.0696C56.3238 91.2172 53.104 81.5107 51.9331 77.514L43.5905 72.375L17.2457 81.5111L13.7331 87.792L10.6595 90.647L2.31718 100.925L1 103.209L1.43897 106.635L4.51258 111.774L8.46414 122.623L1 132.33L8.90338 147.176L14.1724 160.88L16.8068 166.019L18.1239 166.59L18.5632 159.167L19.8804 157.454H22.5147L29.5402 162.593L33.0527 163.735L35.2481 164.307L36.5653 163.164L39.6389 162.593L43.5905 163.164L56.7629 168.874L59.8365 171.158L62.0319 174.013L64.2273 179.152Z",
            textX: 10,
            textY: 43
        },
        {
            city_name: "Boʻston tumani",
            slug: 'boston-tumani',
            data: "M73.4473 138.04C75.6427 132.33 82.2289 132.331 84.8633 129.475C86.9125 127.572 91.4499 124.336 93.2062 126.62C95.4016 129.475 101.988 135.185 101.988 138.04C101.988 140.895 107.698 146.605 108.574 149.46C109.45 152.315 115.16 149.46 110.769 158.025C106.379 166.59 112.965 172.3 112.965 178.01C112.965 182.578 110.622 197.804 109.45 204.846L97.1562 199.707L90.131 200.278L84.862 201.42L78.7148 200.278L76.5194 199.136L74.3253 197.425C71.8372 187.908 67.3002 166.591 69.0565 157.455C71.2519 146.035 71.2519 143.75 73.4473 138.04Z",
            textX: 22,
            textY: 56
        },
        {
            city_name: "Baliqchi tumani",
            slug: 'baliqchi-tumani',
            data: "M59.8734 98.0696C56.3608 91.2172 53.1367 81.5112 51.9658 77.5145L62.515 71.2334L65.5884 70.0915H71.2966L77.4436 70.6624L84.469 69.5205L117.839 80.3695L122.23 80.9406L126.621 79.7985H141.824C142.116 83.2245 143.393 90.0766 145.501 92.3606C148.136 95.2156 145.935 98.0707 152.521 100.926C159.107 103.781 165.693 100.926 167.889 100.926C170.084 100.926 176.67 103.781 170.084 106.636C163.498 109.491 159.102 112.346 154.716 112.346C150.331 112.346 141.544 112.346 137.153 109.491C132.762 106.636 123.981 103.781 119.59 103.781C115.199 103.781 108.613 103.781 106.418 109.491C104.661 114.059 96.9044 122.814 93.2456 126.62C91.4892 124.336 86.9502 127.571 84.901 129.475C87.5354 126.619 76.5584 122.623 71.2897 118.055C66.0209 113.486 64.2642 106.635 59.8734 98.0696Z",
            textX: 25,
            textY: 31
        },
        {
            city_name: "Izboskan tumani",
            slug: 'izboskan-tumani',
            data: "M145.5 92.361C143.393 90.077 142.116 83.2249 141.823 79.7989L145.456 78.0856L148.09 76.3725L150.286 74.0885L156.433 37.5444L157.75 30.1214L172.24 24.4114L181.899 22.6982C183.948 23.4596 187.782 26.5812 186.729 32.9764C185.414 40.9704 178.827 38.1154 187.609 43.8254C196.391 49.5355 194.195 55.2455 198.586 58.1005C202.977 60.9555 202.978 55.2455 209.563 55.2455C216.148 55.2455 224.931 53.5325 218.345 63.8105C211.758 74.0885 209.563 83.7956 207.368 83.7956C205.611 83.7956 194.927 89.5059 189.804 92.361C188.34 96.1676 185.061 103.781 183.656 103.781H172.915C172.624 102.142 169.321 100.926 167.888 100.926C165.693 100.926 159.107 103.781 152.52 100.926C145.934 98.0711 148.135 95.216 145.5 92.361Z",
            textX: 45,
            textY: 25
        },
        {
            city_name: "Paxtaobod tumani",
            slug: 'paxtaobod-tumani',
            data: "M186.736 32.9765C187.788 26.5813 183.954 23.4598 181.906 22.6984L183.653 20.4141L186.727 8.99401L188.044 6.13899L192.874 1L195.948 3.85501L203.412 18.7011L208.681 23.2691L216.145 26.1241L229.757 27.837L231.074 28.9791L231.513 30.692V32.9762L231.074 35.8312V46.1092L235.904 48.9642L242.051 49.5353L247.32 52.3903L257.858 74.6593C257.127 76.753 254.788 80.9404 251.275 80.9404C246.884 80.9404 247.32 80.9404 244.689 78.0854C242.058 75.2304 235.907 75.2304 229.757 74.6593C225.105 74.2275 214.08 79.8858 208.318 83.2392C207.995 83.6016 207.684 83.7958 207.374 83.7958C207.669 83.619 207.985 83.433 208.318 83.2392C210.278 81.0389 212.696 72.6352 218.351 63.8107C224.937 53.5327 216.154 55.2456 209.569 55.2456C202.985 55.2456 202.983 60.9557 198.592 58.1006C194.202 55.2456 196.397 49.5356 187.615 43.8256C178.834 38.1155 185.42 40.9706 186.736 32.9765Z",
            textX: 52,
            textY: 14
        },
        {
            city_name: "Qo'rg'ontepa tumani",
            slug: 'qorgontepa-tumani',
            data: "M329.43 92.9312L319.77 88.9341L303.305 90.9326L306.157 118.055C301.766 119.007 292.985 121.481 292.985 123.765C292.985 126.62 291.671 135.185 291.23 140.895C290.789 146.605 288.594 146.605 284.203 152.315C279.812 158.025 273.667 158.025 275.424 160.88C277.18 163.736 277.176 172.301 284.203 169.446C289.825 167.162 299.28 164.687 303.305 163.736L307.915 178.011L316.697 172.872L335.138 166.591L357.092 148.889L381.242 137.469L393.097 128.333L401 114.629L399.683 106.064L391.78 103.78L376.851 104.922L371.582 100.354L368.069 93.502L364.117 90.0762L345.237 101.496L333.382 112.345L329.869 112.916L330.747 105.493L329.43 92.9312Z",
            textX: 80,
            textY: 44
        },
        {
            city_name: "Jalolquduq tumani",
            slug: 'jalaquduq-tumani',
            data: "M306.16 118.055L303.308 90.9323L286.845 92.9309L275.868 91.2177L265.77 85.5077L257.866 74.6587C257.136 76.7524 254.791 80.9398 251.278 80.9398C247.619 84.7466 241.179 93.5023 244.692 98.0704C249.083 103.78 255.669 110.389 253.474 115.2C251.278 120.012 251.278 120.91 249.083 129.476C246.887 138.041 249.083 143.751 249.083 146.606C249.083 149.461 249.083 155.171 251.278 158.026C253.474 160.88 264.451 172.3 268.841 178.01C273.232 183.721 284.209 195.141 286.405 197.996C288.161 200.28 296.358 203.325 300.237 204.562L301.774 190.001L307.918 178.01L303.308 163.735C299.283 164.687 289.828 167.161 284.206 169.445C277.179 172.3 277.183 163.735 275.426 160.88C273.67 158.025 279.815 158.025 284.206 152.315C288.597 146.605 290.792 146.605 291.233 140.895C291.674 135.185 292.988 126.62 292.988 123.765C292.988 121.481 301.769 119.007 306.16 118.055Z",
            textX: 67,
            textY: 44
        },
        {
            city_name: "Shaxrixon tumani",
            slug: 'shahrixon-tumani',
            data: "M93.2407 126.62C96.8996 122.814 104.656 114.059 106.413 109.491C108.603 103.796 115.16 103.781 119.551 103.781C119.551 105.684 119.99 110.633 121.747 115.201C123.942 120.911 121.747 123.766 126.137 129.476C130.528 135.186 126.137 135.186 134.919 138.041C143.701 140.896 154.678 135.186 154.678 138.041C154.678 140.896 150.287 152.316 150.287 158.026C150.287 163.736 148.091 169.446 148.091 172.301C148.091 175.156 134.919 186.576 134.919 189.431C134.919 192.286 130.961 195.141 131.842 203.707L129.207 208.274L124.817 208.846L109.449 204.848C110.62 197.807 112.965 182.579 112.965 178.011C112.965 172.301 106.379 166.591 110.77 158.026C115.16 149.461 109.451 152.316 108.574 149.461C107.698 146.606 101.988 140.896 101.988 138.041C101.988 135.186 95.4361 129.475 93.2407 126.62Z",
            textX: 32,
            textY: 55
        },
        {
            city_name: "Oltinko'l tumani",
            slug: 'oltinkol-tumani',
            data: "M172.911 103.781H183.652C184.971 104.733 187.609 107.778 187.609 112.346C187.609 118.056 196.39 120.911 196.39 123.766C196.39 126.621 191.999 140.897 189.804 143.752C187.609 146.607 185.413 140.897 181.022 140.897C176.632 140.897 167.85 138.042 165.655 138.042H154.678C154.678 135.187 143.701 140.897 134.919 138.042C126.137 135.187 130.528 135.187 126.137 129.476C121.747 123.766 123.942 120.911 121.747 115.201C119.99 110.633 119.551 105.685 119.551 103.781H119.586C123.976 103.781 132.758 106.636 137.149 109.491C141.54 112.346 150.326 112.346 154.712 112.346C159.097 112.346 163.494 109.491 170.08 106.636C173.153 105.233 173.248 104.148 172.911 103.781Z",
            textX: 40,
            textY: 42
        },
        {
            city_name: "Marhamat tumani",
            slug: 'marhamat-tumani',
            data: "M165.663 267.659L166.102 269.943L168.297 269.372L172.688 269.943L175.322 272.798L174.005 277.366L170.493 278.508L166.102 279.079L163.028 280.221L161.711 284.218L165.224 285.931L176.64 285.36L180.591 287.073L195.52 298.493L202.545 301.919L208.253 300.206L213.522 291.07L216.596 280.221V269.943L213.961 260.807L202.985 250.529L200.789 246.532V241.964L202.106 234.541L202.545 226.547L196.837 214.556L197.715 207.703L205.18 203.136C206.204 199.519 209.395 191.715 213.961 189.431C219.67 186.576 198.588 192.286 194.198 192.286H176.64C167.864 192.286 165.224 200.852 159.071 204.278C154.149 207.018 158.195 216.84 160.833 221.408L164.345 225.405L164.785 227.689V230.544V235.683L163.906 243.677L163.028 251.671L163.467 257.381L165.663 267.659Z",
            textX: 45,
            textY: 80
        },
        {
            city_name: "Asaka tumani",
            slug: 'asaka-tumani',
            data: "M147.647 216.268L160.819 221.407C158.181 216.839 154.147 207.019 159.069 204.278C165.221 200.852 167.861 192.287 176.638 192.287H194.195C198.589 183.722 196.388 169.446 196.388 166.591C196.388 163.736 194.193 172.301 191.997 178.011C189.802 183.721 187.606 175.156 185.411 169.446C183.216 163.736 185.411 163.736 189.802 158.026C193.314 153.458 185.411 144.703 181.02 140.896C176.629 140.896 167.848 138.041 165.652 138.041H154.678C154.678 140.896 150.285 152.316 150.285 158.026C150.285 163.736 148.089 169.446 148.089 172.301C148.089 175.156 134.917 186.576 134.917 189.431C134.917 192.286 130.959 195.141 131.84 203.706L134.474 200.851L137.987 204.277L142.817 213.984L144.573 215.697L147.647 216.268Z",
            textX: 41,
            textY: 58
        },
        {
            city_name: "Xo'jaobod tumani",
            slug: 'xojaobod-tumani',
            data: "M251.273 158.026C249.078 155.171 249.078 149.461 249.078 146.606C246.151 147.557 239.857 149.461 238.101 149.461H229.319C224.928 149.461 218.342 149.461 216.147 152.316C213.951 155.171 209.561 160.881 211.756 166.591C213.951 172.301 216.149 178.011 218.344 175.156C220.54 172.301 229.321 169.446 227.126 172.301C224.93 175.156 222.735 180.866 224.93 180.866C227.126 180.866 235.907 189.431 240.298 189.431C244.689 189.431 255.666 192.286 255.666 195.141C255.666 197.996 257.861 209.416 257.861 212.271C257.861 215.126 257.861 217.981 253.471 217.981H246.884C244.689 217.981 240.298 220.836 244.689 223.691L257.861 232.256C262.252 235.111 264.45 235.111 266.643 235.111L274.985 231.685L275.425 225.404L277.181 217.981H282.011L291.231 223.691L298.696 219.123L300.232 204.562C296.353 203.325 288.156 200.28 286.4 197.996C284.204 195.141 273.227 183.721 268.836 178.01C264.446 172.3 253.469 160.88 251.273 158.026Z",
            textX: 62,
            textY: 58
        },
        {
            city_name: "Andijon tumani",
            slug: 'andijon-tumani',
            data: "M189.804 158.026C193.316 153.458 185.413 144.703 181.022 140.896C185.413 140.896 187.608 146.606 189.804 143.751C191.999 140.896 196.39 126.621 196.39 123.766C196.39 120.911 187.608 118.056 187.608 112.346C187.608 107.778 184.974 104.733 183.655 103.781C185.06 103.781 188.34 96.1678 189.804 92.3612C194.926 89.5061 205.611 83.7958 207.367 83.7958C212.778 80.56 224.838 74.202 229.758 74.6589C235.909 75.2299 242.059 75.2299 244.69 78.0849C247.321 80.9399 246.886 80.9399 251.277 80.9399C247.618 84.7468 241.179 93.5029 244.692 98.0709C249.083 103.781 255.669 110.389 253.474 115.201C251.278 120.013 251.278 120.911 249.083 129.476C246.888 138.041 249.083 143.751 249.083 146.606C246.156 147.558 239.857 149.461 238.101 149.461H229.319C224.928 149.461 218.342 149.461 216.147 152.316C213.951 155.171 209.56 160.881 211.756 166.591C211.025 165.64 209.123 163.736 207.367 163.736C205.172 163.736 205.172 166.591 198.585 166.591H196.39C196.39 163.736 194.195 172.301 191.999 178.011C189.804 183.721 187.608 175.156 185.413 169.446C183.218 163.736 185.413 163.736 189.804 158.026Z",
            textX: 54,
            textY: 34
        },
        {
            city_name: "Buloqboshi tumani",
            slug: 'buloqboshi-tumani',
            data: "M218.346 175.156C216.151 178.011 213.953 172.301 211.758 166.591C211.027 165.639 209.124 163.736 207.367 163.736C205.172 163.736 205.172 166.591 198.586 166.591H196.39C196.39 169.446 198.588 183.722 194.195 192.287C198.586 192.287 219.667 186.577 213.959 189.432C209.392 191.716 206.202 199.519 205.177 203.136L213.962 212.843L222.304 225.405L229.769 230.544L239.429 232.257L257.431 248.245L267.529 247.674L271.481 243.106L273.677 237.967L274.987 231.685L266.645 235.111C264.451 235.111 262.254 235.111 257.863 232.256L244.691 223.691C240.3 220.836 244.691 217.981 246.886 217.981H253.472C257.863 217.981 257.863 215.126 257.863 212.271C257.863 209.416 255.668 197.996 255.668 195.141C255.668 192.286 244.691 189.431 240.3 189.431C235.909 189.431 227.128 180.866 224.932 180.866C222.737 180.866 224.932 175.156 227.128 172.301C229.323 169.446 220.542 172.301 218.346 175.156Z",
            textX: 58,
            textY: 67
        },
        {
            city_name: "Andijon shahri",
            slug: 'andijon-shahar',
            data: "M22.9539 284.054C22.9539 276.876 32.1536 245.172 36.7535 230.217C38.1218 225.72 57.4529 212.271 64.3528 212.271C71.2526 212.271 71.2526 248.163 71.2526 266.108C71.2526 284.054 50.5531 302 43.6533 302C36.7535 302 22.9539 293.027 22.9539 284.054Z",
            textX: 12,
            textY: 87
        },
        {
            city_name: "Andijon shahri",
            slug: 'andijon-shahar',
            data: "M202.976 134.778C202.976 132.82 205.485 124.173 206.74 120.095C207.113 118.868 212.385 115.2 214.267 115.2C216.149 115.2 216.149 124.989 216.149 129.883C216.149 134.778 210.503 139.672 208.622 139.672C206.74 139.672 202.976 137.225 202.976 134.778Z",
            textX: 12,
            textY: 87
        },
        {
            city_name: "Qorasuv shahri",
            slug: 'qorasuv-shahri',
            data: "M352.945 194.364C352.945 185.43 370.118 190.642 378.705 194.364C380.136 199.947 382.999 212.23 382.999 216.697C382.999 222.28 378.705 239.03 374.412 244.614C370.977 249.08 358.669 246.475 352.945 244.614C351.514 242.753 347.793 236.797 344.358 227.864C340.064 216.697 352.945 205.531 352.945 194.364Z",
            textX: 91,
            textY: 72
        },
        {
            city_name: "Qorasuv shahri",
            slug: 'qorasuv-shahri',
            data: "M311.51 145.395C311.51 142.417 317.234 144.154 320.096 145.395C320.573 147.256 321.528 151.351 321.528 152.839C321.528 154.701 320.096 160.284 318.665 162.145C317.52 163.634 313.418 162.766 311.51 162.145C311.033 161.525 309.792 159.54 308.647 156.562C307.216 152.839 311.51 149.117 311.51 145.395Z",
            textX: 91,
            textY: 72
        },
        {
            city_name: "Xonobod shahri",
            slug: 'xonobod-shahar',
            data: "M339.389 43.8089C336.537 40.099 354.842 26.8051 364.351 20.6219C366.728 15.9845 372.196 6.70972 375.049 6.70972C378.615 6.70972 389.313 20.6219 396.445 25.2593C403.577 29.8967 400.011 39.1715 396.445 43.8089C392.879 48.4463 360.785 53.0837 357.219 53.0837C353.653 53.0837 342.955 48.4463 339.389 43.8089Z",
            textX: 93,
            textY: 12
        },
        {
            city_name: "Xonobod shahri",
            slug: 'xonobod-shahar',
            data: "M347.979 122.74C346.96 121.415 353.498 116.667 356.894 114.459C357.743 112.803 359.696 109.49 360.715 109.49C361.988 109.49 365.809 114.459 368.356 116.115C370.903 117.771 369.63 121.084 368.356 122.74C367.082 124.396 355.62 126.052 354.347 126.052C353.073 126.052 349.253 124.396 347.979 122.74Z",
            textX: 93,
            textY: 12
        }
    ]
};
export default Map;