import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, withHighcharts
} from 'react-jsx-highcharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);

const MatrixLine = (props) => {
    const { t } = useTranslation();
    const {rows, cols, lan, result} = props;
    let percents = [];
    let names = [];
    let total_user = 0;
    rows.forEach(row => {
        cols.forEach(col=>{
            total_user += _.get(result, row.id+'.'+col.id, 0)
        })
    });
    
    rows.forEach(row => {
        let d = [];
        cols.forEach(col=>{
            d = [...d, _.get(result, row.id+'.'+col.id, 0)?_.get(result, row.id+'.'+col.id, 0)/total_user*100:null ];
        });
        percents = [...percents, { name: _.get(row, 'row_'+lan, ''), data: d, type: 'column', } ];

    });
    cols.forEach(col=>{
        names = [...names,  _.get(col, 'col_'+lan, '') ]
    });

    

   

    const yAxis = {
        title: {
            text: null
        }
    };

    const xAxis = {
        categories: names
    };

    const legend = {
        // layout: 'vertical',
        align: 'bottom',
        verticalAlign: 'middle'
    };

    const plotOptions = {
        column: {
            borderRadius: 5,
            dataLabels: {
              enabled: true,
              format: '{y:.1f} %'
            },
        }
    };

    const series = percents;

    const responsive = {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    };
    
    const tooltip = {
        shared: true,
        pointFormat: '<span style="color:{series.color}"> {series.name}: <b>{point.y:.1f} %</b><br/>'
    }
    const chart = {
        backgroundColor: '#F7F7F7',
        borderRadius: 7
    }
return (
      <div style={{width: '100%'}}>
        <HighchartsChart  
          chart={chart}
          series={ series}
          yAxis={yAxis}
          xAxis = {xAxis}
          plotOptions = {plotOptions}
          legend={ legend}
          responsive={responsive}
          tooltip={tooltip}
        >
        </HighchartsChart>
      </div>
    );
}
export default withHighcharts(MatrixLine, Highcharts);