import React, {useEffect, useState} from 'react';
import SignUp from "./sections/SignUp";
import SignIn from "./sections/SignIn";
import ValidateBotSMS from './sections/ValidateBotSMS';
import ValidateSMS from "./sections/ValidateSMS";
import ForgotPassword from "./sections/ForgotPassword";
import ForgotValidateSms from "./sections/ForgotValidateSms";
import ForgotNewPassword from "./sections/ForgotNewPassword";
import ForgotValidateBotSms from './sections/ForgotValidateBotSms';
import ValidNewPassword from './sections/ValidNewPassword';
import Navigation from './sections/Navigation';
import Layout from './Layout';
import { useTranslation } from 'react-i18next';

const  EduInstitutionPage = () => {
    const [act, actState] = useState(1);
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('edu_institution_sign_up.title');
    }, []);
    return (<Layout>
        <main class="main old-token">
            <section className="section section-breadcrumbs" style={{background: '#f5f5f5', paddingBottom: 0}}>
                <div className="container">
                    <Navigation
                        active={t('edu_institution_sign_up.title')}
                    />
                </div>
            </section>
            <section className="section section-dashboard py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card my-5">
                                <div className="card-body">
                                    <h5 style={{fontWeight: 500, padding: '24px 0', fontSize: 28}}>{t('edu_institution_sign_up.title')}</h5>
                                    <p style={{paddingBottom: 20}}>{t('edu_institution_sign_up.discription')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card my-5">
                                <div className="card-body">
                                    {act===1?<SignUp actState={(a)=>actState(a)}  is_edu_institution={1}/>
                                    :act===2?<ValidateSMS actState={(a)=>actState(a)}/>
                                    :act===3?<ForgotPassword actState={(a)=>actState(a)}/>
                                    :act===4?<ForgotValidateSms actState={(a)=>actState(a)}/>
                                    :act===5?<ForgotNewPassword actState={(a)=>actState(a)}/>
                                    :act===6?<ValidateBotSMS actState={(a)=>actState(a)}/>
                                    :act===7?<ForgotValidateBotSms actState={(a)=>actState(a)}/>
                                    :act===8?<ValidNewPassword actState={(a)=>actState(a)}  is_edu_institution={1}/>
                                    :<SignIn actState={(a)=>actState(a)}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
     </Layout>);
}
export default EduInstitutionPage;