const  Map = {
    name: "Andijon viloyati",
    slug: 'andijan_region',
    viewBox: "0 0 402 277",
    map_data: [
        {
            city_name: "Pop tumani",
            slug: 'pop-tumani',
            data: "M131.145 233.928L138.035 234.693L150.667 234.119L161.767 207.537C166.233 206.899 175.93 205.624 178.993 205.624C182.82 205.624 196.218 201.8 200.045 199.887C203.873 197.975 205.787 194.15 203.873 192.238C201.959 190.325 186.648 194.15 182.82 190.325C178.993 186.501 175.165 184.588 175.165 180.763C175.165 176.939 173.251 175.026 167.509 175.026C161.767 175.026 159.854 180.763 154.112 178.851C148.37 176.939 142.628 180.763 136.887 173.114C131.145 165.464 127.317 155.903 127.317 153.99C127.317 152.078 133.059 148.253 127.317 140.604C121.576 132.954 115.834 134.866 121.576 131.042C127.317 127.217 136.887 124.158 138.801 119.95C140.332 116.584 140.715 110.388 140.715 107.711L129.997 105.799L120.427 106.946L115.834 106.564L112.006 102.739L108.944 98.5317L105.116 94.7069L101.288 92.0295L97.0778 90.1172L92.1017 90.4997L87.5083 92.7945L84.0632 93.1769L81.3837 88.9698L81.001 83.9975L84.0632 65.6387L83.6805 59.5191L81.7666 53.3995L69.1349 31.9808L65.3072 27.0087L61.0964 24.3313L53.8238 25.4788L26.2636 41.1603L18.9907 43.8376H17.4597L14.7802 51.1046L12.8663 54.547L1.76561 69.081L1.38292 70.9934L1 72.9058L1.38292 76.348L1.76561 80.9378L2.53121 82.4677L3.29682 83.615L9.4212 84.38L10.1868 87.0574L9.03851 90.8822L8.27291 92.7945L4.06242 101.974L3.6795 103.504V105.799L4.44511 107.711L5.5934 110.006L10.1868 116.125L14.7802 123.775L15.5458 130.277L16.3112 152.078L14.3973 158.963L18.9907 163.553L19.3736 164.7L20.139 165.465L27.0292 171.584L67.6037 217.482L69.5176 220.159L69.1349 225.514L64.5415 223.984L56.886 217.099L52.6752 217.864L50.3787 221.306L51.1443 226.661L54.5891 230.868L74.8766 235.84L83.2976 240.813L81.3837 250.757L81.001 253.052L86.7427 249.61L89.0393 246.55L90.9532 243.872L93.6327 241.195L97.0778 239.283L100.14 238.9L106.264 239.665L109.327 239.283L119.662 229.721H124.255L127.7 231.251L129.614 232.398L131.145 233.928Z",
            textX: 18,
            textY: 55
        },  
        {
            city_name: "Chust tumani",
            slug: 'chust-tumani',
            data: "M182.814 190.325C186.641 194.15 201.953 190.325 203.867 192.237C206.421 190.325 211.529 185.737 211.529 182.676C211.529 178.85 211.529 178.852 213.443 176.939L213.446 176.935C215.361 175.021 219.184 171.199 219.184 167.377V155.903V142.516C219.184 140.986 221.484 133.719 222.634 130.276L207.323 117.272L201.964 124.157L199.667 129.894L196.605 130.276L193.543 127.217L191.629 123.009L188.949 111.918L186.27 108.475L180.911 107.71L176.318 110.388L175.935 114.595L177.083 119.95L177.466 126.069L175.169 131.806L171.724 129.129L165.983 119.95L162.537 116.89L154.116 115.742L150.288 113.448L145.695 110.005L140.708 107.71C140.708 110.388 140.325 116.584 138.794 119.95C136.88 124.157 127.311 127.216 121.569 131.041C115.827 134.866 121.569 132.953 127.311 140.603C133.052 148.252 127.311 152.077 127.311 153.99C127.311 155.902 131.138 165.464 136.88 173.113C142.622 180.763 148.364 176.938 154.105 178.85C159.847 180.763 161.761 175.026 167.503 175.026C173.244 175.026 175.158 176.938 175.158 180.763C175.158 184.588 178.986 186.5 182.814 190.325Z",
            textX: 44,
            textY: 54
        },  
        {
            city_name: "To'raqo'rg'on tumani",
            slug: 'toraqorgon-tumani',
            data: "M211.535 182.676C211.535 185.736 206.427 190.325 203.873 192.237C208.723 194.149 219.264 197.975 222.631 197.975C226.84 197.975 244.065 202.182 245.979 202.182C247.893 202.182 257.462 210.596 253.635 200.27C249.807 189.943 244.065 186.5 245.979 182.676C247.893 178.851 255.548 169.289 257.462 169.289C259.376 169.289 261.29 163.552 259.376 161.64C257.462 159.727 255.548 153.99 251.721 150.165C247.893 146.341 242.151 140.604 242.151 136.779C242.151 132.954 240.237 125.305 238.323 123.392C236.792 121.862 230.158 117.655 227.607 115.743V127.217L222.641 130.276C221.491 133.718 219.191 140.986 219.191 142.516V167.377C219.191 171.199 215.368 175.02 213.453 176.935L213.453 176.935L213.449 176.938C211.535 178.852 211.535 178.85 211.535 182.676Z",
            textX: 59,
            textY: 58
        },  
        {
            city_name: "Mingbuloq tumani",
            slug: 'mingbuloq-tumani',
            data: "M275.45 200.27L278.512 196.063C276.981 194.532 261.287 198.23 253.631 200.27C257.459 210.597 247.89 202.182 245.976 202.182C244.062 202.182 226.837 197.975 222.628 197.975C219.261 197.975 208.72 194.15 203.87 192.237C205.784 194.149 203.868 197.975 200.041 199.887C196.213 201.8 182.816 205.624 178.988 205.624C175.926 205.624 166.228 206.899 161.763 207.537L150.662 234.119L163.296 233.545L149.133 252.669L150.281 254.199L159.085 254.964L160.616 255.729L167.123 262.613L168.655 263.378L170.951 264.143L175.162 264.908L177.458 265.673L182.052 269.498L192.387 275.235L195.449 276L200.425 275.235L214.971 261.466L221.861 250.374L226.454 246.932L245.976 236.222L264.349 230.103L270.857 223.601L267.412 216.334L264.732 212.891L264.349 210.597L265.498 209.067L272.77 202.182L275.45 200.27Z",
            textX: 51,
            textY: 81
        },  
        {
            city_name: "Kosonsoy tumani",
            slug: 'kosonsoy-tumani',
            data: "M251.716 150.165C255.544 153.99 257.458 159.727 259.372 161.64C259.373 157.177 260.142 148.253 263.204 148.253C267.032 148.253 270.86 138.691 270.86 134.866C270.86 131.041 272.774 134.866 274.687 127.217C276.601 119.567 280.429 110.005 278.515 108.093C276.984 106.563 269.201 80.6823 265.501 67.9332L259.376 74.4352L251.721 75.9651L242.151 71.758L231.433 61.8136L228.371 61.0486L226.84 64.8733L228.754 115.743C231.305 117.655 236.788 121.862 238.319 123.392C240.233 125.304 242.147 132.954 242.147 136.779C242.147 140.603 247.888 146.34 251.716 150.165Z",
            textX: 63,
            textY: 40
        },  
        {
            city_name: "Yangiqo'rg'on tumani",
            slug: 'yangiqorgon-tumani',
            data: "M270.86 134.866C270.86 138.691 267.032 148.253 263.204 148.253C264.48 148.89 267.797 150.93 270.86 153.99C274.687 157.815 276.601 159.727 280.429 155.903C284.257 152.078 284.257 148.253 284.257 146.341C284.257 144.428 284.257 142.516 289.999 142.516C295.74 142.516 301.482 144.428 299.568 138.691C297.654 132.954 293.826 132.954 291.912 127.217C289.999 121.48 288.085 115.743 289.999 113.83C291.912 111.918 293.826 113.83 295.74 113.83C297.271 113.83 300.206 106.181 301.482 102.356C302.12 102.356 304.161 100.061 307.224 90.8818C311.051 79.4075 309.138 77.4951 305.31 67.9332C302.247 60.2837 297.654 44.8572 295.74 38.1001L293.444 36.9527L292.295 32.3629L289.999 31.2156L288.85 33.1279L288.085 36.1877L286.554 38.4826L284.64 37.7176L284.257 34.2753L288.468 17.4464V11.3268L287.319 5.58964L284.64 1.76488L279.664 1L274.687 2.91238L272.008 6.35464L267.415 17.4464L269.711 22.4185L276.219 30.8331L276.984 36.1877L275.836 41.9248L272.773 51.8692L265.501 67.9332C269.201 80.6824 276.984 106.563 278.515 108.093C280.429 110.005 276.601 119.567 274.687 127.217C272.773 134.866 270.86 131.042 270.86 134.866Z",
            textX: 72,
            textY: 27
        },  
        {
            city_name: "Chortoq tumani",
            slug: 'chortoq-tumani',
            data: "M289.999 142.516C284.257 142.516 284.257 144.428 284.257 146.341C291.275 149.528 306.075 155.52 309.138 153.99C312.965 152.078 312.965 150.166 320.621 144.428C328.277 138.691 328.277 144.428 328.277 134.866C328.277 127.217 328.785 112.556 329.039 106.181L327.891 93.5593L328.274 81.3201L327.125 71.3757L323.68 62.5787L302.627 31.2158L300.714 29.6859L299.182 28.9209L297.651 30.0683L297.268 36.5704L295.74 38.0999C297.654 44.857 302.248 60.2836 305.31 67.9331C309.138 77.495 311.051 79.4074 307.224 90.8817C304.161 100.061 302.12 102.356 301.482 102.356C300.206 106.181 297.271 113.83 295.74 113.83C293.826 113.83 291.912 111.918 289.999 113.83C288.085 115.743 289.999 121.48 291.912 127.217C293.826 132.954 297.654 132.954 299.568 138.691C301.482 144.428 295.74 142.516 289.999 142.516Z",
            textX: 77,
            textY: 45
        },  
        {
            city_name: "Uychi tumani",
            slug: 'uychi-tumani',
            data: "M309.138 153.99C306.075 155.52 291.275 149.528 284.257 146.34C287.447 153.353 293.826 168.907 293.826 175.026C293.826 182.676 301.482 182.676 312.965 178.851C324.449 175.026 329.039 169.29 329.039 166.612C329.039 163.935 337.846 155.903 343.588 150.166C349.329 144.428 359.659 135.631 361.193 132.954C362.42 130.813 364.256 121.608 365.02 117.273L361.193 115.361L357.748 110.388L352.389 105.799L334.781 108.859L329.039 106.181C328.785 112.555 328.277 127.217 328.277 134.866C328.277 144.428 328.277 138.691 320.621 144.428C312.965 150.165 312.965 152.078 309.138 153.99Z",
            textX: 85,
            textY: 48
        },  
        {
            city_name: "Uchqo'rg'on tumani",
            slug: 'uchqorgon-tumani',
            data: "M343.588 150.166C337.846 155.903 329.039 163.935 329.039 166.613C333.889 168.142 344.353 171.202 347.416 171.202C351.243 171.202 358.899 173.114 365.021 173.114H381.861H398.428L399.852 166.612L401 161.64L394.11 163.935L397.172 156.668L398.703 151.313V145.576L396.79 130.277L396.407 121.863L395.258 113.831L392.962 108.094L387.22 104.651L381.861 105.799L376.502 108.859L371.526 113.448L365.021 117.273C364.256 121.608 362.42 130.813 361.193 132.954C359.659 135.631 349.329 144.428 343.588 150.166Z",
            textX: 92,
            textY: 57
        },  
        {
            city_name: "Norin tumani",
            slug: 'norin-tumani',
            data: "M392.584 192.62L394.498 191.09L398.428 173.114H365.021C358.899 173.114 351.243 171.201 347.416 171.201C344.353 171.201 333.889 168.142 329.039 166.612C329.039 169.289 324.449 175.026 312.965 178.851C301.482 182.676 297.247 181.69 293.826 175.026L301.482 189.943L308.755 193.385L317.942 189.178L320.621 188.413H325.597L330.956 188.795L337.081 188.03L366.172 195.297L370 195.68L373.827 194.915L387.225 194.532L390.287 193.768L392.584 192.62Z",
            textX: 93,
            textY: 67
        },  
        {
            city_name: "Namangan tumani",
            slug: 'namangan-tumani',
            data: "M278.515 196.062L301.482 189.943L293.826 175.027C293.826 168.907 287.447 153.353 284.257 146.341C284.257 148.253 284.257 152.078 280.429 155.903C276.601 159.727 274.687 157.815 270.86 153.99C267.797 150.931 264.477 148.891 263.201 148.253C260.139 148.253 259.377 157.178 259.375 161.64C261.289 163.552 259.375 169.289 257.461 169.289C255.547 169.289 247.892 178.851 245.978 182.676C244.064 186.5 249.805 189.943 253.633 200.268C261.291 198.228 276.984 194.533 278.515 196.062Z",
            textX: 71,
            textY: 60
        },  
        {
            city_name: "Namangan shahri",
            slug: 'namangan-shahri',
            data: "M257.462 182.293C257.462 181.273 260.865 175.918 262.566 173.368C263.587 172.348 269.796 174.643 272.773 175.918C274.049 176.768 276.601 179.233 276.601 182.293C276.601 186.117 275.325 187.392 274.049 189.942C272.773 192.492 261.29 189.942 260.014 188.667C258.738 187.392 257.462 183.568 257.462 182.293Z",
            textX: 80,
            textY: 87
        },  
        {
            city_name: "Namangan shahri",
            slug: 'namangan-shahri',
            data: "M280.429 240.426C280.429 236.347 294.039 214.928 300.844 204.729C304.927 200.649 329.765 209.828 341.674 214.928C346.778 218.328 356.985 228.187 356.985 240.426C356.985 255.726 351.881 260.825 346.778 271.025C341.674 281.224 295.74 271.025 290.637 265.925C285.533 260.825 280.429 245.526 280.429 240.426Z",
            textX: 80,
            textY: 87
        },  
        {
            city_name: "Davlatobod tumani",
            slug: 'davlatobod-tumani',
            data: "M265.118 167.377C265.118 168.907 263.842 171.839 263.204 173.114L274.687 176.939L278.515 173.114C278.515 171.839 278.132 168.907 276.601 167.377C274.687 165.465 265.118 165.465 265.118 167.377Z",
            textX: 41,
            textY: 24
        },  
        {
            city_name: "Davlatobod tumani",
            slug: 'davlatobod-tumani',
            data: "M148.37 55.0248C148.37 59.6145 144.542 68.4114 142.628 72.2362L177.079 83.7105L188.562 72.2362C188.562 68.4114 187.414 59.6145 182.82 55.0248C177.079 49.2876 148.37 49.2876 148.37 55.0248Z",
            textX: 41,
            textY: 24
        },  
        {
            city_name: "Yangi Namangan tumani",
            slug: 'yangi-namangan',
            data: "M284.257 178.851C284.257 177.321 277.877 176.939 274.687 176.939V188.413C275.325 189.05 276.984 190.325 278.515 190.325C280.046 190.325 281.705 189.05 282.343 188.413C282.981 185.863 284.257 180.381 284.257 178.851Z",
            textX: 94,
            textY: 25
        },  
        {
            city_name: "Yangi Namangan tumani",
            slug: 'yangi-namangan-tumani',
            data: "M391.435 52.2516C391.435 47.9679 373.572 46.897 364.641 46.897V79.0249C366.427 80.8098 371.071 84.3796 375.358 84.3796C379.646 84.3796 384.29 80.8098 386.076 79.0249C387.863 71.8854 391.435 56.5354 391.435 52.2516Z",
            textX: 94,
            textY: 25
        }   
    ]
};
export default Map;