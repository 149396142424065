import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import createStore from './helpers/store';
import history from './history';
import './index.css';
import Routes from './routes/Routes';
// import ReactGA from 'react-ga';

// const Analytics = () => {
// 	ReactGA.initialize('G-CNW4B8ZBCF');
// 	ReactGA.pageview(window.location.pathname + window.location.search);
// }

const App = () => {
	// Analytics();
	
	const store = createStore();
	return (
		<Provider store={store}>
			<Router history={history}>
				<Routes />
			</Router>
		</Provider>
	);
};
export default App;
