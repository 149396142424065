import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Loading from '../Loading';

const Step6 = (props) => {
  const {
    edu,
    disabled,
    setStep,
    step,
    loading,
    submitTest7,
    errorEdu,
    setErrorEdu,
    setEdu,
  } = props;

  const { t } = useTranslation();
  return (
    <form onSubmit={submitTest7}>
      <div className='row'>
        <div className='col-12'>
          <h6 className='mb-4'>{t('edu_institution.owner')}</h6>
          {errorEdu.owner_full_name || errorEdu.owner_positions ? (
            <div className='inputError'>{t('edu_institution.main_err')}</div>
          ) : null}
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.owner_full_name')}</label>
            <input
              disabled={disabled}
              style={errorEdu.owner_full_name ? { borderColor: '#f00' } : {}}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  owner_full_name: e.target.value,
                });
                setErrorEdu({ ...errorEdu, owner_full_name: false });
              }}
              type='text'
              name='owner_full_name'
              defaultValue={edu.owner_full_name ? edu.owner_full_name : null}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.owner_full_name')}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label for=''>{t('edu_institution.owner_positions')}</label>
            <input
              disabled={disabled}
              style={errorEdu.owner_positions ? { borderColor: '#f00' } : {}}
              onChange={(e) => {
                setEdu({
                  ...edu,
                  owner_positions: e.target.value,
                });
                setErrorEdu({ ...errorEdu, owner_positions: false });
              }}
              type='text'
              name='owner_positions'
              defaultValue={edu.owner_positions ? edu.owner_positions : null}
              className='form-control edu_institution_input'
              placeholder={t('edu_institution.owner_positions')}
            />
          </div>
        </div>
        {/* <div className="col-12">
                                                <div className="form-group">
                                                    {disabled?'':
                                                    <label for="offer" onClick={()=>{setOffer(!offer); setError({...error, offer: false});}}  className="checkbox-custom" 
                                                    style={{  fontSize: 15, color: '#0D2E69', fontWeight: 500, display: 'inline-block', ...(error.offer?{color: '#f00'}:{color: '#0D2E69'})}}>
                                                        <input type="checkbox"  checked={offer}  />
                                                        {t('edu_institution.offer_check')} 
                                                        <span className="checkmark"></span>
                                                    </label>}
                                                    <i style={{color: '#26CAAC', whiteSpace: 'nowrap', paddingLeft: 24, cursor: 'pointer', fontWeight: 500}}>{t('edu_institution.offer')}.</i> 
                                                </div>
                                            </div> */}
      </div>
      <div
        className=''
        style={{
          color: '#e60000',
          fontSize: 14,
          marginBottom: '20px',
        }}
      >
        <span style={{ fontWeight: 800 }}>ESLATMA.</span> Xalq ta’limi
        xodimlarining malakasini oshirish kurslarini tashkil etish talablari va
        shartlariga rioya qilinmagan, so‘ralgan ma’lumotlar belgilangan tartibda
        rasmiylashtirilmagan taqdirda sizning{' '}
        <span style={{ fontWeight: 800 }}>Arizangiz ko‘rib chiqilmasligi</span>{' '}
        bo‘yicha ogohlantiramiz.
      </div>
      <div className='d-flex'>
        {disabled ? null : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <button
                  className='btn btn-primary btn-sm'
                  style={{ marginRight: '15px' }}
                  onClick={() => {
                    setStep(step - 1);
                    window.scroll(0, 0);
                  }}
                >
                  Oldingi
                </button>
                <button className='btn btn-primary btn-sm' type='submit'>
                  {t('profile.save')}
                </button>
              </>
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default Step6;
