import * as m3u8Parser from 'm3u8-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'videojs-progress-marker/lib';
import 'videojs-progress-marker/lib/style/';
import { API_URL, FILE_URL } from '../../helpers/api';
import { getInstance } from '../../helpers/httpClient';
import { getToken } from '../../helpers/tokenStorge';
import NoData from './NoData';

require('@silvermine/videojs-quality-selector')(videojs);

const usePlayer = ({ src, type, markers, controls, autoplay }) => {
	const options = {
		poster: '/images/courses/course.jpg',
		withCredentials: true,
		fill: true,
		fluid: true,
		preload: 'auto',
		html5: {
			hls: {
				enableLowInitialPlaylist: false,
				overrideNative: true,
				smoothQualityChange: true,
			},
		},
	};
	const videoRef = useRef(null);
	const [player, setPlayer] = useState(null);
	useEffect(() => {
		videojs.Hls.xhr.beforeRequest = options => {
			var headers = options.headers || {};
			headers['Authorization'] = `Bearer  ${getToken()}`;
			options.headers = headers;
			return options;
		};
		let vjsPlayer = videojs(videoRef.current, {
			...options,
			controls,
			controlBar: {
				liveDisplay: true,
				pictureInPictureToggle: false,
			},
			autoplay,
			sources: src,
		});
		vjsPlayer.controlBar.addChild('QualitySelector');
		vjsPlayer.markers({
			markerStyle: {
				'position': 'absolute',
				'width': '9px',
				'height': '9px',
				'border-radius': '40%',
				'background-color': '#26caac',
				'top': '-3px',
				'margin-left': '-4.3px !important',
			},
			markerTip: { display: false },
			breakOverlay: { display: false },
			markers: markers,
		});
		setPlayer(vjsPlayer);
		return () => {
			if (player !== null) player.dispose();
		};
	}, []);
	useEffect(() => {
		if (player !== null) player.src({ src });
	}, [src]);
	return videoRef;
};
const VideoPlayerBody = ({ src, type, controls, autoplay }) => {
	const ref = usePlayer({ src, type, controls, autoplay });
	return (
		<div className="video mb-4">
			<div data-vjs-player>
				<video ref={ref} className="video-js" />
			</div>
		</div>
	);
};
VideoPlayerBody.propTypes = {
	src: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	video_question: PropTypes.array,
	section: PropTypes.string,
	lesson_id: PropTypes.number,
	controls: PropTypes.bool,
	autoplay: PropTypes.bool,
	duration_video: PropTypes.number,
};
VideoPlayerBody.defaultProps = {
	controls: true,
	autoplay: false,
	video_question: [],
	section: '',
	lesson_id: 0,
	duration_video: 0,
};
const VideoPlayer = ({ id, is_enc, file }) => {
	const [src, setSrc] = useState([]);
	useEffect(async () => {
		await setSrc([]);
		if (is_enc === 1) {
			getInstance()
				.get(`${API_URL}/api/v1/lesson/getVideo/${id}/playlist.m3u8`)
				.then(response => {
					if (response.status === 200) {
						const { data } = response;
						let parser = new m3u8Parser.Parser();
						parser.push(data);
						parser.end();
						let parsedManifest = parser.manifest;
						let s = [];
						parsedManifest.playlists.map(item =>
							s.push({
								src: `${API_URL}/api/v1/lesson/getVideo/${id}/${item.uri}`,
								type: 'application/x-mpegURL',
								label: item.attributes.NAME + 'p',
							}),
						);
						setSrc(s);
					} else {
						setSrc([]);
					}
				})
				.catch(() => setSrc([]));
		} else {
			setSrc([
				{
					src: FILE_URL + file,
					type: 'video/mp4',
					label: '720p',
				},
			]);
		}
	}, [id, is_enc, file]);
	return (
		<>
			{src.length > 0 ? (
				<VideoPlayerBody src={src} type={'application/x-mpegURL'} />
			) : (
				<NoData text="Video topilmadi" />
			)}
		</>
	);
};
export default VideoPlayer;
