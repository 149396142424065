import React, {useState, useEffect} from 'react';
import { getTeacherLesson } from "../actions";
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import Loading from './sections/Loading';
import history from "../history";
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import VideoQuestionPlayer from './sections/VideoQuestionPlayer';
import { roles } from "../helpers/roles";
import _ from 'lodash';

const DashTeacherLessonVideoQuestionCreate = (props) => {
    const { t } = useTranslation();
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const { teacher_lessons_status, teacher_lesson_data } = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.getTeacherLesson(course_id, section_id);
    }, []);

    useEffect(() => {        
        if(teacher_lessons_status.status === 'started'){
            setLoading(true);
        }
        if(teacher_lessons_status.status === 'success'){
            setLoading(false);
            if(!teacher_lesson_data.id){
                history.push(`/dashboard/copyrights/course-items/${course_id}/section/${section_id}/lesson`);
            }
        }
        if(teacher_lessons_status.status === 'error'){
            setLoading(false);
        }
    }, [teacher_lessons_status, teacher_lesson_data]);
    
    return (<Layout>
	<CheckRole roles={ [ roles.teacher ] } />
    <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('teacher_lesson.questions_new')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('author_page.course'),
                                        'link': '/dashboard/copyrights'
                                    },
                                    {
                                        'title': t('course_items.items'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id
                                    },
                                    {
                                        'title': t('teacher_lesson.lesson'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
                                    },
                                    {
                                        'title': teacher_lesson_data.name,
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson/video'
                                    },
                                    {
                                        'title': t('teacher_lesson.questions'),
                                        'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson/video/questions'
                                    }
                                ]}
                            />
                            {loading?<Loading/>:<>
                            <div className="dashboard-title">
                                <h5>{t('teacher_lesson.questions_new_title')}</h5>
                            </div>
                            <div className="card card--shadow">
                                <div className="card-body">
                                    <VideoQuestionPlayer time={0} course_lesson={teacher_lesson_data} course={course_id} section={section_id}/>
                                </div>
                            </div>
                             </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>    
  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getTeacherLesson: (_course_id, _section_id) => dispatch(getTeacherLesson(_course_id, _section_id)),
    })
)(DashTeacherLessonVideoQuestionCreate);