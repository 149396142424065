import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import Loading from "./sections/Loading";
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import { getInstance } from '../helpers/httpClient';
import _ from 'lodash';
import RadioOrCheckbox from './sections/survey-result/RadioOrCheckbox';
import Select from './sections/survey-result/Select';
import Rating from './sections/survey-result/Rating';
import Bool from './sections/survey-result/Bool';
import Matrix from './sections/survey-result/Matrix';
import MatrixLine from './sections/survey-result/MatrixLine';
import {getLanguage} from '../helpers/language';
import NoData from './sections/NoData';

const TeacherLessonSurveysResult = (props) => { 
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [surveys, setSurveys] = useState([]);
	const [survey_results, setSurveyResults] = useState({});
	const id = props.match.params.survey_id?props.match.params.survey_id:0;
    const course_id =  props.match.params.course_id;
    const section_id =  props.match.params.section_id;
    const lan = getLanguage();

    useEffect(()=>{
        getSurveys();
    },[id]);

    const getSurveys = () => {
		setLoading(true);
		getInstance()
			.get(`/api/v1/survey/get-survey-result?survey_id=${id}`)
			.then(response => {
				if (response.status === 200) {
					try {
						const { data } = response?.data;
                        const s = JSON.parse( _.get(data, 'survey.settings' , '[]') );
                        const r = JSON.parse( _.get(data, 'survey_result.result' , '[]') );
						setSurveys(s);
                        setSurveyResults(r);
						setLoading(false);
					} catch {
						setLoading(false);
						setSurveys({});
					}
				}
			})
			.catch(error => { setSurveys({});
						setLoading(false);
		} );
	}
	return (
		<Layout>
			<CheckRole roles={ [ roles.teacher ] } />
			<main class="main">
				<section class="section section-dashboard py-0">
					<div class="container">
						<div class="row">
							<DashSideBar />
							<div class="col-md-12 col-lg-9 col-xl-10">
								<div class="dashboard-right">
									<Navigation is_dash={true} is_btn={true} 
                                        active={ t('survey_r.title') } 
                                        navs={[
											{
												'title': t('navigation.account'),
												'link': '/dashboard'
											},
											{
												'title': t('author_page.course'),
												'link': '/dashboard/copyrights'
											},
											{
												'title': t('course_items.items'),
												'link': '/dashboard/copyrights/course-items/'+course_id
											},
											{
												'title': t('teacher_lesson.lesson'),
												'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson'
											},
											{
												'title': t('survey.survey'),
												'link': '/dashboard/copyrights/course-items/'+course_id+'/section/'+section_id+'/lesson/survey'
											}
										]}
                                    />
									<div class="dashboard-title dashboard-title--has-buttons">
										<h5>{ t('survey_r.title') }</h5>
										{/* <div class="buttons butons--target">
											<Link to="/manager/surveys/users"
												className="btn btn-primary btn-sm font-weight-medium"
											>
												Users
											</Link>
										</div> */}
									</div>

									{loading?<Loading/>:<>
                                        {surveys.length?surveys.map((survey, index)=>(
                                            <div className="card" key={index}>
                                                <div className="card-body row p-3">
													<div className="col-12">
                                                        <h5> {index+1}. {_.get(survey, `name_${lan}`, '')} ( { t('survey.' + _.get(survey, 'type', '') )} )</h5>
													</div>
													{['radiogroup', 'checkboxgroup'].includes(survey.type)?<>
                                                    <div className="col-12 col-md-6 p-5">
														{survey.choices.map((item, index) => (
															<div key={index}><b>{index+1}.</b> {_.get(item, `label_${lan}`, '')}: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.'+item.id, 0 )} { t('survey_r.ta') }</span> </div>
														))}
														<div>{ t('survey_r.not_specified') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.no_result', 0 )} { t('survey_r.ta') }</span> </div>
                                                    </div>
													<div className="col-12 col-md-6">
													{_.get( survey_results, survey.id, false)?
													<RadioOrCheckbox
														survey = {survey}
														result = { _.get( survey_results, survey.id, {}) }
														lan = {lan}
													/>
													:null}
													</div></>
													:['select'].includes(survey.type)?<>
                                                    <div className="col-12 col-md-6 p-5">
														{survey.choices.map((item, index) => (
															<div key={index}><b>{index+1}.</b> {_.get(item, `label_${lan}`, '')}: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.'+item.id, 0 )} { t('survey_r.ta') }</span> </div>
														))}
														<div>{ t('survey_r.not_specified') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.no_result', 0 )} { t('survey_r.ta') }</span> </div>
                                                    </div>
													<div className="col-12 col-md-6">
													{_.get( survey_results, survey.id, false)?
													<Select 
														survey = {survey}
														result = { _.get( survey_results, survey.id, {}) }
														lan = {lan}
													/>
													:null}
													</div></>
													:['rating'].includes(survey.type)?<>
                                                    <div className="col-12 col-md-6 p-5">
														{( () => {
															let l = [];
															for (
																let y = 1;
																y <= survey.rateMax;
																y++
															) {
																l.push(
																	<div>{y}-{ t('survey_r.ball') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.'+y, 0 )} { t('survey_r.ta') }</span> </div>
																);
															}
															return l;
														})()}
														<div>{ t('survey_r.not_specified') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.no_result', 0 )} { t('survey_r.ta') }</span> </div>
                                                    </div>
													<div className="col-12 col-md-6">
													{_.get( survey_results, survey.id, false)?
													<Rating 
														survey = {survey}
														result = { _.get( survey_results, survey.id, {}) }
														lan = {lan}
													/>
													:null}
													</div></>
													:['boolean'].includes(survey.type)?<>
                                                    <div className="col-12 col-md-6 p-5">
														<div>{ t('survey_c.on') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.on', 0 )} { t('survey_r.ta') }</span> </div>
														<div>{ t('survey_c.off') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.off', 0 )} { t('survey_r.ta') }</span> </div>
														<div>{ t('survey_r.not_specified') }: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.no_result', 0 )} { t('survey_r.ta') }</span> </div>
                                                    </div>
													<div className="col-12 col-md-6">
													{_.get( survey_results, survey.id, false)?
													<Bool 
														survey = {survey}
														result = { _.get( survey_results, survey.id, {}) }
														lan = {lan}
													/>
													:null}
													</div></>
													:['matrix_radio', 'matrix_checkbox'].includes(survey.type)?<>
                                                    <div className="col-12 col-md-6 p-5">
														{survey.rows.map((row, index_r)=>(<>
															<div key={index_r}  ><b>{index_r+1}. {_.get(row, `row_${lan}`, '')}  </b>
															{survey.cols.map((col, index)=>(
																<div key={index} style={{marginLeft: 15}}>
																	<b>{index+1}.</b> {_.get(col, `col_${lan}`, '')}: <span style={{color: '#26caac'}}>{_.get(survey_results, survey.id+'.'+row.id+'.'+col.id, 0 )} { t('survey_r.ta') }</span> 
																</div>
															))}
															</div>
														</>))}
                                                    </div>
													{_.get( survey_results, survey.id, false)?<>
													<div  className="col-12 col-md-6" >
														<MatrixLine
															rows = {survey.rows}
															cols = {survey.cols}
															result = { _.get( survey_results, survey.id, {}) }
															lan = {lan}
														/>
													</div>
													{survey.rows.map((row, index)=>(
													<div className="col-12 col-md-6" key={index} style={{paddingTop: 30}}>
														<Matrix 
															row = {row}
															cols = {survey.cols}
															result = { _.get( survey_results, survey.id, {}) }
															lan = {lan}
														/>
													</div>
													))}</>:null}
													</>:null} 
                                                    
                                                </div>
                                            </div>
                                        
                                        )):<NoData text={ t('survey_r.no_data') } />}
									</>} 
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			
		</Layout>
	);
};
export default TeacherLessonSurveysResult;
