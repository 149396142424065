import React, {useState} from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { IMG_URL, FILE_URL } from "../../helpers/api";
import { useTranslation } from 'react-i18next';
import Timer from "react-compound-timer";
import {getToken} from '../../helpers/tokenStorge';
import JoditTextShow from "./JoditTextShow";

const LessonWebinar = (props) => {
    const {course_lesson,  lesson_webinar_join,  lesson_webinar_record} = props;
    const { t } = useTranslation();
    const [end_limit, setEndLimit] = useState(false);


    const  startdate = new Date( _.get(course_lesson,'data.startdate') );
    const  duration = parseInt( _.get(course_lesson,'data.duration') );
    const  now = new Date(  );
    const duration_date = new Date(startdate.getTime() + duration*60000);
    let is_duration = true;
    let is_early = true;
    if(duration_date<now){
        is_duration = false;
    }
    if(startdate<now){
        is_early = false;
    }
    const limit = startdate - now;
    return (<>
        <div className="container">
            <h3 className="mb-4">{_.get(course_lesson, 'data.name')}</h3>
            <div className="webinar-details">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-md-5 webinar-col-2 order-md-2">
                                <div className="webinar-details__card">
                                    <img src={IMG_URL+_.get(course_lesson,'data.image')} 
                                        alt="Webinar"
                                        onError={e => {
                                            e.target.onerror = null;
                                            e.target.src = '/images/webinars/webinar.jpeg';
                                        }}
                                    />
                                    <div className="webinar-details__card-caption">
                                        <div>
                                            <p><span className="icon icon-clock"></span>{_.get(course_lesson, 'data.startdate')}</p>
                                            <p><span class="icon icon-video"></span>{ parseInt(_.get(course_lesson, 'data.duration')/60) } {t('course_view.h')} { parseInt(_.get(course_lesson, 'data.duration'))%60 } {t('course_view.m')}</p>
                                        </div>
                                            <button type="button" className="btn btn-secondary-light" onClick={()=>{
                                                var inp =document.createElement('input');
                                                document.body.appendChild(inp);
                                                inp.value = window.location.href;
                                                inp.select();
                                                document.execCommand('copy',false);
                                                inp.remove();
                                            }}>
                                                <span className="icon icon-link"></span>{t('course_view.copy')}
                                            </button>
                                            {limit<=0 || end_limit?
                                            <a href={ ( is_duration?_.get(lesson_webinar_join,'data'):_.get(lesson_webinar_record,'data') )  } target='blank' className="btn btn-primary">
                                                <span className="icon icon-play"></span>{t('course_view.join')}
                                            </a>
                                            :<button className="btn btn-primary">
                                                    <Timer
                                                        initialTime={limit}
                                                        direction="backward"
                                                        checkpoints={[
                                                            {
                                                                time: 0,
                                                                callback: () => setEndLimit(true),
                                                            }]}
                                                    >
                                                        {() => (
                                                            <>
                                                                <Timer.Days /> : <Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds />
                                                            </>
                                                        )}
                                                    </Timer>
                                                </button>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 webinar-col-1 order-md-1">
                                <div className="webinar-details__description">
                                    <JoditTextShow text={ _.get(course_lesson, 'data.description') } />
                                    {_.get(course_lesson,'data.presentation')?
                                        <a href={FILE_URL+_.get(course_lesson,'data.presentation')} className="btn btn-primary ml-auto mt-2" 
                                            target="blank"
                                        >
                                            {t('course_view.presentation')}
                                        </a>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
    })
)(LessonWebinar);