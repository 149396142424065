 
import { useState, useEffect } from "react";
import Layout from "./Layout"
import DashSideBar from "./sections/DashSideBar"
import Navigation from "./sections/Navigation"
import { useTranslation } from 'react-i18next';
import SelectReact from 'react-select';
import { getInstance } from "../helpers/httpClient";
import Loading from "./sections/Loading";
import {get} from 'lodash';
import history from '../history';


const GroupsCreate = () => {
	const { t } = useTranslation();
    const [obj, setObj] = useState({status: 0});
    const [err, setErr] = useState({});
    const [cats, setCats] = useState([]);
    const [sCats, setSCats] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        getCatList();
    }, [])
    const getCatList = () => {
        getInstance().get('/api/v1/manager/categories')
        .then(res=>{
            const data = get(res, 'data.data', []);
            let l = []
            data.forEach(item => {
                l.push({
                    label: get(item, 'name'),
                    value: get(item, 'id')
                })
            });
            setCats(l);
        }).catch(()=>{setCats([])})
    }
    const onSubmit = (event) => {
		event.preventDefault();
        setLoading(true)
        let errr = {}, t = true;
        const cl = sCats.map(i=>i.value);

        if(!obj.name){ errr = {...errr, name: true}; t=false; }
        if(!obj.desc){ errr = {...errr, desc: true}; t=false; }
        if(!obj.start_date){ errr = {...errr, start_date: true}; t=false; }
        if(!obj.end_date){ errr = {...errr, end_date: true}; t=false; }
        if(cl.length===0){  errr = {...errr, categories: true}; t=false; } 
        if(t){
            getInstance().post('/api/v1/manager/groups/create', {...obj, categories: cl}  )
            .then(res=>{
                setObj(get(res, 'data.data'));
                history.push('/dashboard/groups/edit/'+get(res, 'data.data.id', 0))

            }).catch(()=>{}).finally(()=>{setLoading(false)})
        }else{setErr(errr); setLoading(false)}

    }
    return(
      <Layout>
        <main className="main">
          <section className="section section-dashboard py-0">
            <div className="container">
              <div className="row">
                <DashSideBar />
                <div className="col-md-12 col-lg-9 col-xl-10">
                  <div className="dashboard-right">
                    <Navigation
                      is_dash={true}
                      is_btn={true}
                      active={t('groups.add')}
                      navs={[
                        {
                          title: t('navigation.account'),
                          link: '/dashboard',
                        },
                        {
                          title: t('groups.title'),
                          link: '/dashboard/groups',
                        },
                      ]}
                    />
                    <div className="dashboard-title">
                        <h5 className="mb-3">{t('groups.add')}</h5>                      
                    </div>
                    <div className="card card--shadow">
                        <form onSubmit={onSubmit} className="form-group m-0 p-2">
                            <label>* {t('groups.name')}</label>
                            <input type='text' className="form-control" name="name" 
                                value={get(obj, 'name', '')}
                                onChange={e=>{setObj({...obj, name: e.target.value}); setErr({...err, name: false})}}
                                placeholder={t('groups.name_e')} />
                            {err?.name?<div className="input_error">{t('groups.name_e')}</div>:''}
                            
                            <label>* {t('groups.categories')}</label>
                            <div className="mb-2">
                                <SelectReact
                                    value={ sCats }
                                    onChange={(select)=>{ setSCats(select); setErr({...err, categories: false}) }}
                                    options={cats}
                                    isMulti ={true} 
                                    placeholder = {t('groups.categories_e')}
                                    className="custom-react-select"
                                />
                                {err?.categories?<div className="input_error">{t('groups.categories_e')}</div>:''}
                            </div>

                            <div className="setting__buttons form-group my-2" style={{alignItems: 'center', justifyContent: 'left'}}>
                               
                                <label className="switch mx-2">
                                    <input type="checkbox" checked={get(obj, 'status', 0)?true:false}
                                        onChange={(e)=>{ 
                                            setObj({...obj, status: get(obj, 'status', 0)?0:1});  
                                            setErr({...err, status: false}) 
                                        }}
                                    />
                                    {/* {_.get(result, 'value', '')?null:
                                    <style jsx>{`
                                        .switch .slider:before{
                                            left: 15px
                                        }
                                    `}</style>} */}
                                    <span className="slider round"></span>
                                </label>
                                { t('groups.status') }
                            </div>

                            <label>* {t('groups.start_date')}</label>
                            <input type='datetime-local' className="form-control" name="start_date"
                                onChange={e=>{setObj({...obj, start_date: e.target.value}); setErr({...err, start_date: false})}}
                                value={get(obj, 'start_date', '')}  />
                            {err?.start_date?<div className="input_error">{t('groups.start_date_e')}</div>:''}

                            <label>* {t('groups.end_date')}</label>
                            <input type='datetime-local' className="form-control" name="end_date" 
                                onChange={e=>{setObj({...obj, end_date: e.target.value}); setErr({...err, end_date: false})}}
                                value={get(obj, 'end_date', '')} />
                            {err?.end_date?<div className="input_error">{t('groups.end_date_e')}</div>:''}

                            <label>* {t('groups.desc')}</label>
                            <input type='text' className="form-control" name="name" 
                                value={get(obj, 'desc', '')}
                                onChange={e=>{setObj({...obj, desc: e.target.value}); setErr({...err, desc: false})}}
                                placeholder={t('groups.desc_e')} />
                            {err?.desc?<div className="input_error">{t('groups.desc_e')}</div>:''}

                            <div className="mt-2">
                                {loading?<Loading size={5}/>:
                                <input className="btn btn-primary btn-sm" value={t('groups.save')} type='submit' />}
                            </div>
                            
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
}
export default GroupsCreate;