import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { set_message } from '../actions';
// import AboutPage from '../componets/AboutPage';
// import ConfidentialityPage from '../componets/ConfidentialityPage';
// import HelpPage from '../componets/HelpPage';
import AuthOldToken from '../componets/AuthOldToken';
import CertificatesResults from '../componets/CertificatesResults';
import CoursePage from '../componets/CoursePage';
import CourseReviewPage from '../componets/CourseReviewPage';
import CoursesPage from '../componets/CoursesPage';
import DashCertificatesPage from '../componets/DashCertificatesPage';
import DashChat from '../componets/DashChat';
import DashCopyrightCreate from '../componets/DashCopyrightCreate';
import DashCopyrights from '../componets/DashCopyrights';
import DashCopyrightsWebinars from '../componets/DashCopyrightsWebinars';
import DashCopyrightUpdate from '../componets/DashCopyrightUpdate';
import DashCopyrightWebinarCreate from '../componets/DashCopyrightWebinarCreate';
import DashCopyrightWebinarUpdate from '../componets/DashCopyrightWebinarUpdate';
import DashCourseItems from '../componets/DashCourseItems';
import DashCoursePage from '../componets/DashCoursePage';
import DashCourseResults from '../componets/DashCourseResults';
import DashCourseReviewPage from '../componets/DashCourseReviewPage';
import DashCoursesPage from '../componets/DashCoursesPage';
import DashCoursesTargetPage from '../componets/DashCoursesTargetPage';
import DashDExerciseTest from '../componets/DashDExerciseTest';
import DashDiagnosticTest from '../componets/DashDiagnosticTest';
import DashEduInstitutionListPage from '../componets/DashEduInstitutionListPage';
import DashEduInstitutionPage from '../componets/DashEduInstitutionPage';
import DashLessonSurveyPage from '../componets/DashLessonSurveyPage';
import DashLessonTestPage from '../componets/DashLessonTestPage';
import DashLessonTestResult from '../componets/DashLessonTestResult';
import DashLessonTextPage from '../componets/DashLessonTextPage';
import DashLessonVideoPage from '../componets/DashLessonVideoPage';
import DashLessonWebinarPage from '../componets/DashLessonWebinarPage';
import DashNotificationPage from '../componets/DashNotificationPage';
import DashPaymentHistoryPage from '../componets/DashPaymentHistoryPage';
import DashPaymentPage from '../componets/DashPaymentPage';
import DashProfileEditPage from '../componets/DashProfileEditPage';
import DashProfileErp from '../componets/DashProfileErp';
import DashProfilePage from '../componets/DashProfilePage';
import DashQExerciseItemPage from '../componets/DashQExerciseItemPage';
import DashQExerciseLan from '../componets/DashQExerciseLan';
import DashQExercisePage from '../componets/DashQExercisePage';
import DashQualificationItemPage from '../componets/DashQualificationItemPage';
import DashQualificationLan from '../componets/DashQualificationLan';
import DashQualificationPage from '../componets/DashQualificationPage';
import DashRetrainingItemPage from '../componets/DashRetrainingItemPage';
import DashRetrainingPage from '../componets/DashRetrainingPage';
import DashRiyeester from '../componets/DashRiyeester';
import DashSettingsPage from '../componets/DashSettingsPage';
import DashStatisticsPage from '../componets/DashStatisticsPage';
import DashTeacherCourseSurvey from '../componets/DashTeacherCourseSurvey';
import DashTeacherLesson from '../componets/DashTeacherLesson';
import DashTeacherLessonTests from '../componets/DashTeacherLessonTests';
import DashTeacherLessonText from '../componets/DashTeacherLessonText';
import DashTeacherLessonVideo from '../componets/DashTeacherLessonVideo';
import DashTeacherLessonVideoQuestionCreate from '../componets/DashTeacherLessonVideoQuestionCreate';
import DashTeacherLessonVideoQuestions from '../componets/DashTeacherLessonVideoQuestions';
import DashTeacherLessonVideoQuestionUpdate from '../componets/DashTeacherLessonVideoQuestionUpdate';
import DashTeacherLessonWebinar from '../componets/DashTeacherLessonWebinar';
import DashWebinarPage from '../componets/DashWebinarPage';
import DashWebinarsPage from '../componets/DashWebinarsPage';
import EduInstitutionPage from '../componets/EduInstitutionPage';
import EduOfflineCheckPage from '../componets/EduOfflineCheckPage';
import ExamCreate from '../componets/exams/ExamCreate';
import ExamEdite from '../componets/exams/ExamEdite';
import ExamsPage from '../componets/exams/ExamsPage';
import ExamsTests from '../componets/exams/ExamsTests';
import FAQ from '../componets/FAQ';
import GroupAddUser from '../componets/GroupAddUser';
import GroupsCreate from '../componets/GroupCreate'; 
import GroupEdite from '../componets/GroupEdite';
import GroupsPage from '../componets/GroupsPage';
import HomePage from '../componets/HomePage';
import LessonSurveyPage from '../componets/LessonSurveyPage';
import LessonTestPage from '../componets/LessonTestPage';
import LessonTestResult from '../componets/LessonTestResult';
import LessonTextPage from '../componets/LessonTextPage';
import LessonVideoPage from '../componets/LessonVideoPage';
import LessonWebinarPage from '../componets/LessonWebinarPage';
// import ManagerCertificates from '../componets/ManagerCertificates';
import ManagerCourseResults from '../componets/ManagerCourseResults';
import ManagerCourses from '../componets/ManagerCourses';
import ManagerCoursesReview from '../componets/ManagerCoursesReview';
import ManagerCoursesVideos from '../componets/ManagerCoursesVideos';
import ManagerCoursesVideosView from '../componets/ManagerCoursesVideosView';
import ManagerDiagnostics from '../componets/ManagerDiagnostics';
import ManagerDiagnosticsTest from '../componets/ManagerDiagnosticsTest';
import ManagerExercise from '../componets/ManagerExercise';
import ManagerExerciseTest from '../componets/ManagerExerciseTest';
import ManagerLessonSurveysResult from '../componets/ManagerLessonSurveysResult';
import ManagerPupilProfile from '../componets/ManagerPupilProfile';
import ManagerPupils from '../componets/ManagerPupils';
import ManagerPupilsOffline from '../componets/ManagerPupilsOffline';
import ManagerSchools from '../componets/ManagerSchools';
import ManagerStatisticsPage from '../componets/ManagerStatisticsPage';
import ManagerTargetCoursePupils from '../componets/ManagerTargetCoursePupils';
import ManagerTargetCourses from '../componets/ManagerTargetCourses';
import ManagerTeacherLessons from '../componets/ManagerTeacherLessons';
import ManagerTeacherOpenWebinars from '../componets/ManagerTeacherOpenWebinars';
import ManagerTeachers from '../componets/ManagerTeachers';
import MetodisNotificationPage from '../componets/MetodisNotificationPage';
import MetodisPupils from '../componets/MetodisPupils';
import NotFoundPage from '../componets/NotFoundPage';
import ProfessorsPage from '../componets/ProfessorsPage';
import SetTokenComp from '../componets/SetTokenComp';
import SurveyCreator from '../componets/SurveyCreator';
import Surveys from '../componets/Surveys';
import SurveysChange from '../componets/SurveysChange';
import SurveysResult from '../componets/SurveysResult';
import SurveysResultItem from '../componets/SurveysResultItem';
import TeacherLessonSurveysResult from '../componets/TeacherLessonSurveysResult';
import WebinarLivePage from '../componets/WebinarLivePage';
import WebinarPage from '../componets/WebinarPage';
import WebinarsPage from '../componets/WebinarsPage';
import WebinarUsersPage from '../componets/WebinarUsersPage';

const Routes = props => {
	const { t } = useTranslation();
	const { set_message } = props;
	useEffect(() => {
		window.addEventListener('offline', () =>
			set_message({ text: t('connection.error'), type: 'w' })
		);
		window.addEventListener('online', () =>
			set_message({ text: t('connection.success'), type: 's' })
		);
	}, []);
	return (
		<Switch>
			<Redirect exact from="/sign-in" to="/" />
			{/* <Route path="/" exact component={HomePage} /> */}
			<Route path="/" exact component={AuthOldToken} />
			<Route path="/old-token" exact component={AuthOldToken} />

			
			<Route path="/dashboard/exams/create" exact component={ExamCreate} />
			<Route path="/dashboard/exams/edit/:id" exact component={ExamEdite} />
			{/* <Route path="/dashboard/exams/edit/:id/add-users" exact component={GroupAddUser} /> */}
			<Redirect exact from="/dashboard/exams" to="/dashboard/exams/1" />
			<Route path="/dashboard/exams/:page" exact component={ExamsPage} />
			<Route path="/dashboard/exams/:id/tests" exact component={ExamsTests} />


			<Redirect exact from="/courses" to="/courses/1" />
			<Route path="/courses/:page" exact component={CoursesPage} />
			<Route path="/courses/view/:slug" exact component={CoursePage} />
			<Route
				path="/courses/view/:slug/:section/text/:lesson_id"
				exact
				component={LessonTextPage}
			/>
			<Route
				path="/courses/view/:slug/:section/test/:lesson_id"
				exact
				component={LessonTestPage}
			/>
			<Route
				path="/courses/view/:slug/:section/test/:lesson_id/result"
				exact
				component={LessonTestResult}
			/>
			<Route
				path="/courses/view/:slug/:section/webinar/:lesson_id"
				exact
				component={LessonWebinarPage}
			/>
			<Route
				path="/courses/view/:slug/:section/video/:lesson_id"
				exact
				component={LessonVideoPage}
			/>
			<Route
				path="/courses/view/:slug/:section/survey/:lesson_id"
				exact
				component={LessonSurveyPage}
			/>
			<Route
				path="/courses/review/:slug"
				exact
				component={CourseReviewPage}
			/>
			<Redirect exact from="/professors" to="/professors/1" />
			<Route path="/professors/:page" exact component={ProfessorsPage} />
			<Route path="/edu-institution" exact component={EduInstitutionPage} />
			<Redirect exact from="/webinars" to="/webinars/list/1" />
			<Redirect exact from="/webinars/list" to="/webinars/list/1" />
			<Route path="/webinars/list/:page" exact component={WebinarsPage} />
			<Route path="/webinars/view/:slug" exact component={WebinarPage} />
			<Route
				path="/webinars/view/:slug/users"
				exact
				component={WebinarUsersPage}
			/>
			<Route path="/webinars/live/:slug" exact component={WebinarLivePage} />
			{/* <Route path="/dashboard" exact component={DashStatisticsPage} /> */}
			<Redirect exact from="/dashboard" to="/dashboard/profile" />
			<Route
				path="/dashboard/certificates-results"
				exact
				component={CertificatesResults}
			/>
			<Route
				path="/dashboard/qualification"
				exact
				component={DashQualificationPage}
			/>
			<Route
				path="/dashboard/qualification/choice-lan"
				exact
				component={DashQualificationLan}
			/>
			<Route
				path="/dashboard/qualification/diagnostic-test"
				exact
				component={DashDiagnosticTest}
			/>
			<Redirect
				exact
				from="/dashboard/qualification/:slug"
				to="/dashboard/qualification/:slug/1"
			/>
			<Route
				path="/dashboard/qualification/:slug/:page"
				exact
				component={DashQualificationItemPage}
			/>

			<Route
				path="/dashboard/exercise"
				exact
				component={DashQExercisePage}
			/>
			<Route
				path="/dashboard/exercise/choice-lan"
				exact
				component={DashQExerciseLan}
			/>
			<Route
				path="/dashboard/exercise/exercise-test"
				exact
				component={DashDExerciseTest}
			/>
			<Redirect
				exact
				from="/dashboard/exercise/:slug"
				to="/dashboard/exercise/:slug/1"
			/>
			<Route
				path="/dashboard/exercise/:slug/:page"
				exact
				component={DashQExerciseItemPage}
			/>

			<Route
				path="/dashboard/retraining"
				exact
				component={DashRetrainingPage}
			/>
			<Redirect
				exact
				from="/dashboard/retraining/:slug"
				to="/dashboard/retraining/:slug/1"
			/>
			<Route
				path="/dashboard/retraining/:slug/:page"
				exact
				component={DashRetrainingItemPage}
			/>
			<Route path="/dashboard/profile" exact component={DashProfilePage} />
			<Route
				path="/dashboard/profile/erp"
				exact
				component={DashProfileErp}
			/>
			<Route
				path="/dashboard/profile/edit"
				exact
				component={DashProfileEditPage}
			/>
			<Route
				path="/dashboard/profile/:slug/:name"
				exact
				component={DashCourseResults}
			/>
			<Route path="/dashboard/settings" exact component={DashSettingsPage} />
			<Redirect
				exact
				from="/dashboard/course"
				to="/dashboard/courses/list/1"
			/>
			<Redirect
				exact
				from="/dashboard/courses"
				to="/dashboard/courses/list/1"
			/>
			<Redirect
				exact
				from="/dashboard/courses/list"
				to="/dashboard/courses/list/1"
			/>
			<Route
				path="/dashboard/courses/list/:page"
				exact
				component={DashCoursesPage}
			/>

			<Redirect
				exact
				from="/dashboard/target-courses/list"
				to="/dashboard/target-courses/list/1"
			/>
			<Route
				path="/dashboard/target-courses/list/:page"
				exact
				component={DashCoursesTargetPage}
			/>
			<Route
				path="/dashboard/courses/view/:slug"
				exact
				component={DashCoursePage}
			/>
			<Route
				path="/dashboard/courses/view/:slug/:section/text/:lesson_id"
				exact
				component={DashLessonTextPage}
			/>
			<Route
				path="/dashboard/courses/view/:slug/:section/test/:lesson_id"
				exact
				component={DashLessonTestPage}
			/>
			<Route
				path="/dashboard/courses/view/:slug/:section/test/:lesson_id/result"
				exact
				component={DashLessonTestResult}
			/>
			<Route
				path="/dashboard/courses/view/:slug/:section/webinar/:lesson_id"
				exact
				component={DashLessonWebinarPage}
			/>
			<Route
				path="/dashboard/courses/view/:slug/:section/video/:lesson_id"
				exact
				component={DashLessonVideoPage}
			/>
			<Route
				path="/dashboard/courses/view/:slug/:section/survey/:lesson_id"
				exact
				component={DashLessonSurveyPage}
			/>
			{/* <Route
				path="/dashboard/courses/:id/test"
				exact
				component={DashCourseTestPage}
			/> */}
			<Route
				path="/dashboard/courses/review/:slug"
				exact
				component={DashCourseReviewPage}
			/>
			<Redirect
				exact
				from="/dashboard/notification"
				to="/dashboard/notification/1"
			/>
			<Route
				path="/dashboard/notification/:page"
				exact
				component={DashNotificationPage}
			/>
			<Redirect
				exact
				from="/dashboard/webinars"
				to="/dashboard/webinars/list/1"
			/>
			<Redirect
				exact
				from="/dashboard/webinars/list"
				to="/dashboard/webinars/list/1"
			/>
			<Route
				path="/dashboard/webinars/list/:page"
				exact
				component={DashWebinarsPage}
			/>
			<Route
				path="/dashboard/webinars/view/:id"
				exact
				component={DashWebinarPage}
			/>
			<Route
				path="/dashboard/certificates"
				exact
				component={DashCertificatesPage}
			/>
			<Route path="/dashboard/payment" exact component={DashPaymentPage} />
			<Redirect
				exact
				from="/dashboard/payment/history"
				to="/dashboard/payment/history/1"
			/>
			<Route
				path="/dashboard/payment/history/:page"
				exact
				component={DashPaymentHistoryPage}
			/>
			<Route path="/dashboard/chat" exact component={DashChat} />
			<Route path="/dashboard/chat/:id" exact component={DashChat} />
			<Redirect
				exact
				from="/dashboard/copyrights"
				to="/dashboard/copyrights/list/1"
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/list"
				to="/dashboard/copyrights/list/1"
			/>
			<Route
				path="/dashboard/copyrights/list/:page"
				exact
				component={DashCopyrights}
			/>
			<Route
				path="/dashboard/copyrights/course-create"
				exact
				component={DashCopyrightCreate}
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/course-update"
				to="/dashboard/copyrights/list/1?status=1"
			/>
			<Route
				path="/dashboard/copyrights/course-update/:id"
				exact
				component={DashCopyrightUpdate}
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/course-items"
				to="/dashboard/copyrights/list/1?status=1"
			/>
			<Route
				path="/dashboard/copyrights/course-items/:id"
				exact
				component={DashCourseItems}
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/course-items/:course_id/section"
				to="/dashboard/copyrights/course-items/:id"
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/course-items/:course_id/section/:section_id"
				to="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson"
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson"
				exact
				component={DashTeacherLesson}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/text"
				exact
				component={DashTeacherLessonText}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/test"
				exact
				component={DashTeacherLessonTests}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video"
				exact
				component={DashTeacherLessonVideo}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions"
				exact
				component={DashTeacherLessonVideoQuestions}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/create"
				exact
				component={DashTeacherLessonVideoQuestionCreate}
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/update"
				to="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/create"
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/video/questions/update/:time"
				exact
				component={DashTeacherLessonVideoQuestionUpdate}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/webinar"
				exact
				component={DashTeacherLessonWebinar}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/survey"
				exact
				component={DashTeacherCourseSurvey}
			/>
			<Route
				path="/dashboard/copyrights/course-items/:course_id/section/:section_id/lesson/survey/:survey_id/result"
				exact
				component={TeacherLessonSurveysResult}
			/>
			<Redirect
				exact
				from="/dashboard/copyrights/webinars/list"
				to="/dashboard/copyrights/webinars/list/1"
			/>
			<Route
				path="/dashboard/copyrights/webinars/list/:page"
				exact
				component={DashCopyrightsWebinars}
			/>
			<Route
				path="/dashboard/copyrights/webinar-create"
				exact
				component={DashCopyrightWebinarCreate}
			/>
			<Route
				path="/dashboard/copyrights/webinar-update/:id"
				exact
				component={DashCopyrightWebinarUpdate}
			/>
			<Route path="/dashboard/reester" exact component={DashRiyeester} />
			<Redirect exact from="/manager/pupils" to="/manager/pupils/list/1" />
			<Redirect
				exact
				from="/manager/pupils/list/"
				to="/manager/pupils/list/1"
			/>
			<Route
				path="/manager/pupils/list/:page"
				exact
				component={ManagerPupils}
			/>
			<Redirect
				exact
				from="/manager/offline-pupils/list/"
				to="/manager/offline-pupils/list/1"
			/>
			<Route
				path="/manager/offline-pupils/list/:page"
				exact
				component={ManagerPupilsOffline}
			/>
			<Route
				path="/manager/pupils/view/:id"
				exact
				component={ManagerPupilProfile}
			/>
			<Route
				path="/manager/pupils/view/:id/course/:slug/:name"
				exact
				component={ManagerCourseResults}
			/>
			<Redirect exact from="/manager/courses" to="/manager/courses/1" />
			<Route
				path="/manager/courses/:page"
				exact
				component={ManagerCourses}
			/>
			<Redirect
				exact
				from="/manager/target-courses"
				to="/manager/target-courses/1"
			/>
			<Route
				path="/manager/target-courses/:page"
				exact
				component={ManagerTargetCourses}
			/>
			<Route
				path="/manager/target-courses/:slug/pupils/:page"
				exact
				component={ManagerTargetCoursePupils}
			/>
			<Route
				path="/manager/courses/review/:slug/:id"
				exact
				component={ManagerCoursesReview}
			/>
			<Route
				path="/manager/target-courses/review/:slug/:id"
				exact
				component={ManagerCoursesReview}
			/>
			<Route
				path="/manager/courses/review/:slug/:id/videos"
				exact
				component={ManagerCoursesVideos}
			/>
			<Route
				path="/manager/courses/review/:slug/:id/videos/:videoId"
				exact
				component={ManagerCoursesVideosView}
			/>
			<Route
				path="/manager/courses/review/:slug/:id/survey/:survey_id"
				exact
				component={ManagerLessonSurveysResult}
			/>
			{/* <Redirect
				exact
				from='/manager/certificates'
				to='/manager/certificates/1'
			/>
			<Route
				path='/manager/certificates/:page'
				exact
				component={ManagerCertificates}
			/> */}
			<Route
				path="/manager/diagnostics"
				exact
				component={ManagerDiagnostics}
			/>
			<Redirect
				exact
				from="/manager/diagnostics/test"
				to="/manager/diagnostics/test/1"
			/>
			<Route
				path="/manager/diagnostics/test/:page"
				exact
				component={ManagerDiagnosticsTest}
			/>
			<Route path="/manager/exercise" exact component={ManagerExercise} />
			<Redirect
				exact
				from="/manager/exercise/test"
				to="/manager/exercise/test/1"
			/>
			<Route
				path="/manager/exercise/test/:page"
				exact
				component={ManagerExerciseTest}
			/>
			<Redirect exact from="/faq" to="/faq/1" />
			<Route exact path="/faq/:page" component={FAQ} />
			<Redirect exact from="/metodist/pupils" to="/metodist/pupils/list/1" />
			<Redirect
				exact
				from="/metodist/pupils/list/"
				to="/manager/pupils/list/1"
			/>
			<Route
				path="/metodist/pupils/list/:page"
				exact
				component={MetodisPupils}
			/>
			<Redirect
				exact
				from="/metodist/pupils/notifications/"
				to="/manager/pupils/notifications/1"
			/>
			<Route
				path="/metodist/pupils/notifications/:page"
				exact
				component={MetodisNotificationPage}
			/>
			<Route
				path="/metodist/pupils/view/:id"
				exact
				component={ManagerPupilProfile}
			/>
			<Route
				path="/metodist/pupils/view/:id/course/:slug/:name"
				exact
				component={ManagerCourseResults}
			/>
			<Route path="/manager/surveys" exact component={Surveys} />
			<Route
				path="/manager/surveys/create"
				exact
				component={SurveysChange}
			/>
			<Route path="/manager/surveys/:page" exact component={Surveys} />
			<Route
				path="/manager/surveys/edit/:id"
				exact
				component={SurveysChange}
			/>
			<Route
				path="/manager/surveys/creator/:id"
				exact
				component={SurveyCreator}
			/>
			<Route
				path="/manager/surveys/result/:id"
				exact
				component={SurveysResult}
			/>
			<Route
				path="/manager/surveys/result/:id/:surveyId/:type"
				exact
				component={SurveysResultItem}
			/>
			<Route
				path="/manager/statistics"
				exact
				component={ManagerStatisticsPage}
			/>
			<Route path="/manager/teachers" exact component={ManagerTeachers} />
			<Route path="/manager/schools" exact component={ManagerSchools} />
			<Route
				path="/manager/teachers/lessons/:user_id/:user_name"
				exact
				component={ManagerTeacherLessons}
			/>
			<Route
				path="/manager/teachers/open-webinars/:user_id/:user_name"
				exact
				component={ManagerTeacherOpenWebinars}
			/>

			<Route
				path="/dashboard/edu-institution"
				exact
				component={DashEduInstitutionPage}
			/>
			<Redirect
				exact
				from="/dashboard/edu-institutions"
				to="/dashboard/edu-institutions/1"
			/>
			<Route
				path="/dashboard/edu-institutions/:page"
				exact
				component={DashEduInstitutionListPage}
			/>
			<Route
				path="/dashboard/offline-edu"
				exact
				component={EduOfflineCheckPage}
			/>

			{/* <Route exact path="/about" component={AboutPage} />
			<Route exact path="/confidentiality" component={ConfidentialityPage} />
			<Route exact path="/help" component={HelpPage} /> */}

			<Route path="/st/:token" exact component={SetTokenComp} />
			<Route path="/st" exact component={SetTokenComp} />


			<Route path="/dashboard/groups/create" exact component={GroupsCreate} />
			<Route path="/dashboard/groups/edit/:id" exact component={GroupEdite} />
			<Route path="/dashboard/groups/edit/:id/add-users" exact component={GroupAddUser} />
			<Redirect exact from="/dashboard/groups" to="/dashboard/groups/1" />
			<Route path="/dashboard/groups/:page" exact component={GroupsPage} />




			<Route component={NotFoundPage} />
		</Switch>
	);
};
export default connect(
	state => ({}),
	dispatch => ({
		set_message: e => dispatch(set_message(e)),
	})
)(Routes);
