const  Map = {
    name: "Toshkent shahri",
    slug: 'tashkent_city',
    viewBox: "0 0 402 435",
    map_data: [
        {
            city_name: "Olmazor tumani",
            slug: 'olmazor-tumani',
            data: "M155.548 4.02783L61.6073 97.8952H93.9576C96.2111 97.8952 102.408 97.0989 105.225 96.7008L127.76 107.96L124.943 119.219L164.38 147.366L170.014 138.922H186.915C189.169 138.922 202.878 116.404 209.45 105.145L206.633 99.5155L217.901 88.2566L170.014 40.406L155.548 4.02783Z",
            textX: 38,
            textY: 18
        },
        {
            city_name: "Yunusobod tumani",
            slug: 'yunusobod-tumani',
            data: "M155.544 4.02816L213.119 1L255.544 31.2797L310.088 37.3361L362.994 72.5796L350.292 74.1832L339.024 88.2569L327.757 85.4422L313.672 96.7011H296.771C292.814 103.818 285.521 116.135 283.327 117.133C283.96 120.124 284.915 126.367 285.503 130.478L279.869 133.293L277.052 122.034L262.968 124.849L234.799 172.699L215.081 161.44L223.531 136.107L220.715 130.478L226.348 124.849L207.34 108.719C208.097 107.442 208.804 106.241 209.444 105.145L206.627 99.5159L217.894 88.2569L170.007 40.4063L155.544 4.02816Z",
            textX: 65,
            textY: 18
        },
        {
            city_name: "Mirzo Ulugbek tumani",
            slug: 'mirzo-ulugbek-tumani',
            data: "M262.96 124.848L234.791 172.699L257.338 181.143L277.056 155.811L296.774 167.07L313.675 181.143L355.544 164.51L382.817 119.091L373.725 79.7275L362.986 72.5793L350.284 74.1829L339.017 88.2566L327.749 85.4419L313.664 96.7008H296.763C292.806 103.818 285.514 116.135 283.319 117.133C283.952 120.124 284.908 126.367 285.496 130.478L279.862 133.292L277.045 122.033L262.96 124.848Z",
            textX: 80,
            textY: 33
        },
        {
            city_name: "Shayxontohur tumani",
            slug: 'shayxontoxur-tumani',
            data: "M226.347 124.848L207.339 108.719C200.766 119.978 186.916 138.922 186.916 138.922H170.015L164.381 147.366L124.944 119.219L127.761 107.96L105.226 96.7012C102.409 97.0993 96.2121 97.8956 93.9586 97.8956H61.6083L53.6407 136.107L74.2393 133.293L77.0562 141.737L108.042 144.552L110.859 186.773H151.336L161.563 192.402L178.464 170.567H207.339L215.079 161.44L223.53 136.107L220.713 130.477L226.347 124.848Z",
            textX: 36,
            textY: 38
        },
        {
            city_name: "Uchtepa tumani",
            slug: 'uchtepa-tumani',
            data: "M37.3639 273.518L40.3935 300.769L116.151 251.512L110.859 186.773L108.042 144.552L77.0562 141.737L74.2393 133.293L53.6408 136.107L46.4544 170.567L1 285.629L7.06096 288.657L37.3639 273.518Z",
            textX: 17,
            textY: 51
        },
        {
            city_name: "Chilonzor tumani",
            slug: 'chilonzor-tumani',
            data: "M40.3926 300.77L52.5145 328.021L69.3203 347.213L139.028 279.659L175.648 206.476L207.339 170.567H178.464L161.563 192.403L151.336 186.773H110.859L116.15 251.512L40.3926 300.77Z",
            textX: 35,
            textY: 51
        },
        {
            city_name: "Yakkasaroy tumani",
            slug: 'yakkasaroy-tumani',
            data: "M175.647 206.476L207.339 170.567L217.901 178.329L223.535 234.623L212.267 254.326L139.028 279.659L175.647 206.476Z",
            textX: 47,
            textY: 54
        },
        {
            city_name: "Yashnobod tumani",
            slug: 'yashnobod-tumani',
            data: "M257.338 181.143L234.791 172.699L231.985 175.514L268.605 220.55L304.215 310.621L334.333 282.601L401 249.294L382.819 228.098L397.97 206.902L379.788 179.65L355.544 164.51L313.675 181.143L296.774 167.07L277.056 155.811L257.338 181.143Z",
            textX: 80,
            textY: 51
        },
        {
            city_name: "Mirobod tumani",
            slug: 'mirobod-tumani',
            data: "M234.802 172.699L215.084 161.44L207.338 170.567L217.9 178.329L223.534 234.623L212.266 254.327L262.971 265.586V293.733L304.215 310.621L268.605 220.55L231.985 175.514L234.802 172.699Z",
            textX: 61,
            textY: 54
        },
        {
            city_name: "Sergeli tumani",
            slug: 'sirgali-tumani',
            data: "M69.3207 347.213L139.028 279.659L212.267 254.326L262.972 265.585V293.733L304.216 310.621L246.455 364.357L209.45 383.804L178.464 379.497L130.577 341.583L69.3207 347.213Z",
            textX: 50,
            textY: 74
        },
        {
            city_name: "Yangihayot tumani",
            slug: 'yangihayot-tumani',
            data: "M188.875 434L246.452 364.357L209.45 383.805L178.464 379.497L130.577 341.583L69.3207 347.213L94.9368 376.469L99.5913 383.805L108.042 383.805L116.493 395.064L137.36 418.86L188.875 434Z",
            textX: 41,
            textY: 92
        },
        {
            city_name: "Bektemir tumani",
            slug: 'bektemir-tumani',
            data: "M334.331 282.602L400.997 249.295L398.182 262.771L341.844 316.251L246.455 364.357L334.331 282.602Z",
            textX: 84,
            textY: 70
        }
    ]
};
export default Map;