import React, {useState, useEffect} from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import { useTranslation } from 'react-i18next';
import Pagination from './sections/Pagination';
import NoData from './sections/NoData';
import { Link } from 'react-router-dom';
import Loading from './sections/Loading';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";
import _ from 'lodash';
import MetodisNotificationChahge from './sections/MetodisNotificationChahge';
import { connect } from 'react-redux';
import { getPupils } from '../actions';
import { getInstance } from '../helpers/httpClient';
import Moment from 'react-moment';


const MetodisNotificationPage = (props) => {
    const { pupils, getPupils } = props;
    const page = props.match.params.page;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(0);
    const [notifications, setNotifications] = useState({});
    useEffect(()=>{
        getPupils(1, '?');
        getNotifications(page);
    }, [page]);

    const getNotifications = (page=1) => {
        setLoading(true);
        getInstance()
        .get(`/api/v1/notifications-metodist?per_page=10&page=${page}`)
        .then(response => {
            setNotifications(response.data);
            setLoading(false);
        })
        .catch(error => { setLoading(false); } );
    }
    const newNotification = d => {
        setNotifications({...notifications, data: [ d,  ..._.get(notifications, 'data', []) ] });
        setAdd(false);
    }
    const updateNotification = (d) => {
        let l = [];
        notifications.data.forEach(item => {
            if(d.id===item.id){
                l.push({...item, ...d});
            }else{
                l.push(item)
            }
        });
        setNotifications({...notifications, data: l });
        setEdit(0);
    } 
  return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher, roles.manager, roles.admin ] } />
      <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('notifications.title')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('m_pupils.pupils'),
                                        'link': '/metodist/pupils/list/1'
                                    }
                                ]}
                            />
                            <div className="dashboard-title dashboard-title--has-buttons">
                                <h5>
                                    {t('notifications.title')}
                                </h5>
                                <div class="buttons butons--target">
                                    {add?null:<button
                                        onClick={()=>setAdd(true)}
                                        className="btn btn-primary btn-sm font-weight-medium">
                                            <span class="icon icon-plus fs-100 mr-2"></span>
                                            {t('author_page.add')}
                                    </button>}
                                </div>
                            </div>
                            <div className="container p-0">
                                <div className="row">
                                    {add?
                                        <div className="col-12">
                                            <MetodisNotificationChahge 
                                                setClose={()=>setAdd(false)}
                                                getResponse={d=>newNotification(d)}
                                                data={{}}
                                                pupils={pupils}
                                                type="create"
                                            />
                                        </div>
                                    :null}
                                    {loading?
                                        <Loading/>
                                    :<>
                                        {notifications&&notifications.data&&notifications.data.length>0?<>
                                            {notifications.data.map((notification, index)=>(
                                                <div className="col-12" key={index}>
                                                    {edit===notification.id?
                                                    <MetodisNotificationChahge 
                                                        setClose={()=>setEdit(0)}
                                                        getResponse={d=>updateNotification(d)}
                                                        data={notification}
                                                        pupils={pupils}
                                                        type="update"
                                                    />
                                                    :<div className="card card--shadow">
                                                        <div className="card-body">
                                                            <div className="mb-2 d-flex">
                                                                <h4 style={{display: 'inline'}}>
                                                                    {notification.title}
                                                                    <div style={{fontSize: 16}}>
                                                                        <Moment format="HH:mm DD.MM.YYYY">
                                                                            {notification.created_at}
                                                                        </Moment>
                                                                    </div>
                                                                </h4>
                                                                <span className="icon__button" 
                                                                    onClick={()=>setEdit(notification.id)}
                                                                    style={{marginLeft: 'auto'}}
                                                                >
                                                                    <span class="icon icon-edit"></span>
                                                                </span>
                                                            </div>
                                                            <div style={{textAlign: 'justify'}}>{notification.description}</div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                            <div className="col-12">
                                                <Pagination
                                                    count={10}
                                                    total={notifications.total?notifications.total:20}
                                                    active={ parseInt(page) }
                                                    link="/metodist/pupils/notifications/"
                                                />
                                            </div>
                                            </>
                                            :<div className="col-12">
                                                <NoData
                                                    text={t('notifications.no_data')}
                                                />
                                            </div>
                                        }
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </Layout>);
}

export default connect(
	state => ({
		pupils: state.pupils
	}),
	dispatch => ({
		getPupils: (page, search_p) => dispatch(getPupils(page, search_p, 'metodist', 1000)),
	}),
)(MetodisNotificationPage);