import React, {useState, useEffect}  from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import Loading from "./Loading";
import _ from 'lodash';
import Timer from 'react-compound-timer';
import { getCourseSections, getCourseLesson } from '../../actions';
import { getInstance } from '../../helpers/httpClient';

const CourseSideBarLeft = (props) => {
    const {course_sections, course_sections_status, slug,  section, getCourseSections, getCourseLesson}  = props;
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        if(course_sections_status.status === 'started'&&course_sections_status.slug !== slug) setLoading(true);
        if(course_sections_status.status === 'success'&&course_sections_status.slug === slug) setLoading(false);
        if(course_sections_status.status === 'error'&&course_sections_status.slug === slug) setLoading(false);
    },[course_sections_status]);
    const diff_minutes = () => {
        const dt1 = new Date();
        const dt2 = new Date(_.get(course_sections, 'last_section'));
        const diff =(dt2.getTime() - dt1.getTime()) / 60000;
        return Math.abs(diff);
    }
    const isFirstLock = (id) => {
        const secIndex = _.findIndex(_.get(course_sections, 'data' ,[]), o=>{ 
            return _.get(o, 'childs[0].has_permission', 1) === 0;
        });
        const prevLessons = _.get(course_sections, `data[${secIndex-1}].childs`, []);
        const prevPermissin =  _.get(prevLessons, `[${prevLessons.length-1}].has_permission`, 0);
        const is_complate = _.get(prevLessons, `[${prevLessons.length-1}].is_complate`, 0);
        return _.get(course_sections, `data[${secIndex}].childs[0].id`, 0)===id&&prevPermissin===1&&is_complate;
    }
    const openNextLesson = (cSlug, lessonId) => {
        const secIndex = _.findIndex(_.get(course_sections, 'data' ,[]), o=>{ 
            return _.get(o, 'childs[0].has_permission', 1) === 0;
        });
        const prevLessons = _.get(course_sections, `data[${secIndex-1}].childs`, []);
        const prevSlug =  _.get(prevLessons, `[${prevLessons.length-1}].slug`, '');
        const prevLesson = _.get(prevLessons, `[${prevLessons.length-1}].lessons[0].id`, 0);
        getInstance()
        .get(`/api/v1/course/${slug}/sections/${prevSlug}/lessons/${prevLesson}`)
        .then(res=>{
            getCourseSections(slug, section);
            // console.log('========>', parseInt(props?.active_lesson), lessonId, window.location.pathname);
            // if(parseInt(props?.active_lesson)===lessonId) getCourseLesson(slug, cSlug, lessonId);
        }).catch(err=>{getCourseSections(slug, section);});
    }

    return (<>
        <div class="course-single__sidebar left-sidebar-menu">
            <div class="left-sidebar-menu__inner">
                <div class="course-single__menu">
                {loading?<Loading size={7}/>:<>
                    {_.get(course_sections, 'data' ,[]).map((section, index)=>{
                        return(<>
                        <h6 key={index}>{section.name}</h6>
                        <ul>
                            {_.get(section, 'childs', []).map((child, childs_index)=>{
                                const lesson_id = _.has(child, 'lessons[0].id')?'/'+_.get(child, 'lessons[0].id',''):'';
                                const lesson_type = _.has(child, 'lessons[0].lesson_type.type')?'/'+_.get(child, 'lessons[0].lesson_type.type', ''):'';
                                const has_permission = _.get(child, 'has_permission', 0);
                                return(
                                <li key={childs_index}>
                                    {lesson_id&&lesson_type&&has_permission===1?
                                        <NavLink to={"/courses/view/"+slug+'/'+child.slug+lesson_type+lesson_id} 
                                            activeClassName="course-single__menu_active">
                                            <span class="icon icon-play"></span>{child.name}
                                        </NavLink>
                                    :lesson_id&&lesson_type&&has_permission===0&&isFirstLock(_.get(child, 'id',''))&&diff_minutes()<parseInt(_.get(course_sections, 'time', 0))?
                                        <NavLink to={"/courses/view/"+slug+'/'+child.slug+lesson_type+lesson_id} 
                                            activeClassName="course-single__menu_active">
                                            <span>
                                                <span style={{color: 'orange', whiteSpace: 'nowrap', marginRight: 5}}>
                                                    <Timer
                                                        initialTime={(parseInt(_.get(course_sections, 'time', 0))-diff_minutes())*60000}
                                                        direction="backward"
                                                        checkpoints={[ { time: 0, callback: () => {openNextLesson(child.slug, _.get(child, 'lessons[0].id',''));} } ]}
                                                    >
                                                        {() => (<> 
                                                            <span class="icon icon-clock mr-1"></span>
                                                            <Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds /> 
                                                        </>)}
                                                    </Timer>
                                                </span>
                                                {child.name}
                                            </span>
                                        </NavLink>
                                    :has_permission===1?
                                        <strong style={{color: 'red'}}><span class="icon icon-info"></span>{child.name}</strong>
                                    :
                                        <strong><span class="icon icon-lock"></span>{child.name}</strong>
                                    }
                                </li>
                            )})}
                        </ul>
                    </>)})}
                </>}
                </div>
            </div>
        </div>
  </>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getCourseSections: (slug, section) => dispatch(getCourseSections(slug, section)),
		getCourseLesson: (course_slug, section_slug, lesson_acid) =>
            dispatch(getCourseLesson(course_slug, section_slug, lesson_acid)),
    })
)(CourseSideBarLeft);