import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from '../../history';
const Pagination = props => {
	const { total, active, link, count } = props;
	const item_count = count ? count : 6.0;
	const p = total / item_count;
	const pc = p > parseInt(p) ? parseInt(p) + 1 : p;
	useEffect(() => {
		window.scrollTo(0, 0);
		if (pc < props.active || props.active < 1) {
			history.push(props.link + '1');
		}
	}, [props.active]);
	const search_params = props.search_params ? props.search_params : '';
	return (
		<>
			<div className="pagination mt-4">
				<ul>
					<li>
						{active > 1 ? (
							<Link to={link + (active - 1) + search_params}>
								<i className="icon icon-arrow-left"></i>
							</Link>
						) : (
							<span className="passive">
								<i className="icon icon-arrow-left"></i>
							</span>
						)}
					</li>
					{pc === active && active >= 4 ? (
						<li>
							<Link to={link + (active - 3) + search_params}>{active - 3}</Link>
						</li>
					) : null}
					{pc - active < 2 && pc >= 3 && active - 2 > 0 ? (
						<li>
							<Link to={link + (active - 2) + search_params}>{active - 2}</Link>
						</li>
					) : null}
					{active > 1 && pc !== 1 ? (
						<li>
							<Link to={link + (active - 1) + search_params}>{active - 1}</Link>
						</li>
					) : null}
					<li>
						<span>{active}</span>
					</li>
					{pc - active >= 1 && pc >= 2 ? (
						<li>
							<Link to={link + (active + 1) + search_params}>{active + 1}</Link>
						</li>
					) : null}
					{pc - active >= 2 && pc >= 3 ? (
						<li>
							<Link to={link + (active + 2) + search_params}>{active + 2}</Link>
						</li>
					) : null}
					{active === 1 && pc >= 4 ? (
						<li>
							<Link to={link + (active + 3) + search_params}>{active + 3}</Link>
						</li>
					) : null}
					<li>
						{active < pc ? (
							<Link to={link + (active + 1) + search_params}>
								<i className="icon icon-arrow-right"></i>
							</Link>
						) : (
							<span className="passive">
								<i className="icon icon-arrow-right"></i>
							</span>
						)}
					</li>
				</ul>
			</div>
		</>
	);
};
export default Pagination;
