const  Map = {
    name: "Farg'ona viloyati",
    slug: 'fargana_region',
    viewBox: "0 0 402 327",
    map_data: [
        {
            city_name: "Xazorasp tumani",
            slug: 'xazorasp-tumani',
            data: "M393.697 250.391L367.012 220.34L358.304 223.149L356.057 222.306L353.81 220.902L352.967 220.34L333.585 189.166L305.777 158.273L296.226 150.971L288.642 146.478L280.777 143.108L249.598 135.525H240.89L237.519 136.367L234.71 137.772L232.182 139.738L229.373 142.827L222.581 155.746L229.654 161.363L231.901 156.869L235.553 154.903L241.452 155.746L257.463 159.958L271.507 166.137L308.024 193.098L311.114 196.468L323.192 216.689L324.597 220.34L325.158 224.834L325.439 239.999L326.282 243.089L325.158 244.774L326.844 248.987L332.181 267.241L333.866 277.071L335.271 281.565L336.956 284.373L342.293 290.271L344.259 293.36L347.63 306.56L349.877 311.053L356.057 319.198L356.899 321.164L358.304 326.219L358.585 326.5L392.573 284.092L401 265.837L399.034 258.535L393.697 250.391Z",
            textX: 90,
            textY: 82
        },
        {
            city_name: "Tuproqqal'a tumani",
            slug: 'tuproqqala-tumani',
            data: "M227.971 168.946L229.656 161.363L222.584 155.746L220.098 157.712L215.885 157.993L212.233 156.588L211.109 155.746L208.581 154.622L206.615 148.163L204.649 145.074L195.941 135.525L192.851 133.559L183.301 131.874L179.37 130.189L174.593 125.695L168.413 127.381L166.447 128.785V135.525C165.417 137.491 163.357 141.704 163.357 142.827C163.357 144.231 161.11 147.04 159.708 148.444C158.586 149.567 157.368 153.031 156.899 154.622V162.486L153.245 168.103L161.11 176.248L163.357 174.001L166.447 168.665L167.009 169.226L167.571 171.192L168.413 173.158L177.964 171.473H181.054L197.907 183.55L204.368 186.358L210.548 186.077L216.165 183.269L221.505 177.933L227.971 168.946Z",
            textX: 45,
            textY: 47
        },
        {
            city_name: "Bog'ot tumani",
            slug: 'bogot-tumani',
            data: "M156.897 162.486L153.243 168.103L142.006 165.295L109.142 167.261L104.934 162.486C103.997 161.55 102.406 159.397 103.529 158.274C104.934 156.869 104.931 154.061 106.336 152.657C107.74 151.252 109.148 148.444 109.145 147.04C109.142 145.636 111.954 141.423 111.954 140.019C111.954 138.614 117.571 137.21 118.976 137.21C120.38 137.21 124.594 135.806 125.998 135.806C127.403 135.806 127.403 134.402 130.212 132.997C133.021 131.593 133.021 131.593 138.639 131.593C144.257 131.593 147.065 132.997 148.47 130.189C149.874 127.381 152.683 128.785 151.279 124.572C150.155 121.202 149.874 119.61 149.874 119.236L158.301 118.955L160.545 116.147L164.759 116.989L167.006 117.832L168.691 119.236L174.591 125.695L168.411 127.381L166.445 128.785V135.525C165.415 137.491 163.355 141.704 163.355 142.827C163.355 144.231 161.108 147.04 159.706 148.444C158.584 149.567 157.366 153.031 156.897 154.622V162.486Z",
            textX: 34,
            textY: 47
        },
        {
            city_name: "Yangiariq tumani",
            slug: 'yangiariq-tumani',
            data: "M104.934 162.486L109.142 167.261L89.1978 174.001H79.3665L73.7486 172.597L69.2543 169.226L71.2238 162.486L73.7486 148.444L76.8417 144.231C78.2462 140.955 81.3361 134.121 82.4596 132.997L90.8865 124.572C92.0101 123.449 97.9089 121.296 100.718 120.359L114.763 124.572L120.38 127.381L118.976 137.21C117.572 137.21 111.954 138.614 111.954 140.019C111.954 141.423 109.142 145.635 109.145 147.04C109.148 148.444 107.74 151.252 106.336 152.657C104.931 154.061 104.934 156.869 103.529 158.274C102.406 159.397 103.997 161.55 104.934 162.486Z",
            textX: 24,
            textY: 45
        },
        {
            city_name: "Xiva tumani",
            slug: 'xiva-tumani',
            data: "M71.2237 162.486L69.2547 169.225L60.2689 160.24L50.4375 152.938L34.7074 144.794L29.0895 137.21C30.0258 133.934 32.1793 127.1 33.3029 125.976C34.7074 124.572 36.1119 120.36 38.9208 121.764C41.168 122.887 47.5349 125.976 50.4375 127.381C52.6847 125.976 57.179 122.887 57.179 121.764C57.179 120.64 59.2389 117.551 60.2689 116.147C62.9842 116.615 68.9766 117.551 71.2237 117.551C73.4709 117.551 79.6506 115.679 82.4595 114.742L86.673 110.53H100.718V120.359C97.9088 121.296 92.01 123.449 90.8864 124.572L82.4595 132.997C81.336 134.121 78.2461 140.955 76.8416 144.231L73.7485 148.444L71.2237 162.486Z",
            textX: 15,
            textY: 47
        },
        {
            city_name: "Xiva shahar",
            slug: 'xiva-shahri',
            data: "M58.2324 131.593C59.356 128.223 66.1911 123.636 69.4682 121.764C71.809 121.764 77.0523 122.325 79.2995 124.572C82.1085 127.381 76.4906 125.976 76.4906 131.593C76.4906 137.21 59.6369 141.423 58.2324 140.019C56.8279 138.614 56.8279 135.806 58.2324 131.593Z",
            textX: 5,
            textY: 59
        },
        {
            city_name: "Xiva shahar",
            slug: 'xiva-shahri',
            data: "M3.06667 191.601C5.27112 184.989 18.6815 175.989 25.1112 172.316C29.7038 172.316 39.9912 173.418 44.4001 177.826C49.9112 183.336 38.889 180.581 38.889 191.601C38.889 202.622 5.82223 210.887 3.06667 208.132C0.31111 205.377 0.31111 199.867 3.06667 191.601Z",
            textX: 5,
            textY: 59
        },
        {
            city_name: "Qo'shko'pir tumani",
            slug: 'qoshkopir-tumani',
            data: "M29.0895 137.21L30.4939 142.827L16.729 135.526L12.2346 131.032L11.1111 125.977L13.0773 108.284L14.4818 104.633L16.4481 101.544L19.257 99.0159L25.9985 95.6457L28.2457 93.1181L22.0671 83.8494L27.685 79.6367L36.1118 81.0409L45.9432 85.2536C47.8158 86.6579 51.842 89.7472 52.9655 90.8705C54.0891 91.9939 57.179 90.4025 58.5835 89.4663L65.6058 90.8705V96.4875L69.8193 99.2959L72.6282 97.8917L74.0327 103.509L76.8416 106.317L83.864 107.721L86.673 110.53L82.4595 114.742C79.6506 115.679 73.4709 117.551 71.2237 117.551C68.9766 117.551 62.9841 116.615 60.2688 116.147C59.2389 117.551 57.179 120.64 57.179 121.764C57.179 122.887 52.6847 125.976 50.4375 127.38C47.5349 125.976 41.168 122.887 38.9208 121.764C36.1118 120.359 34.7074 124.572 33.3029 125.976C32.1793 127.1 30.0258 133.934 29.0895 137.21Z",
            textX: 12,
            textY: 32
        },
        {
            city_name: "Shovot tumani",
            slug: 'shovot-tumani',
            data: "M58.5835 89.4663L65.6059 90.8706L69.8193 85.2537L76.8417 78.2325L72.6283 74.0198V68.4029C73.0964 67.4668 73.471 65.3136 71.2238 64.1902C68.9766 63.0669 64.6696 58.1052 62.7969 55.7649H58.5835C57.4599 55.7649 55.3064 54.8287 54.3701 54.3606L50.1567 57.1691L44.5388 53.2371L42.5725 50.4286L40.8871 53.2371L38.3591 53.518L34.9883 52.1137L29.3704 48.4628L23.1908 46.4968L18.1346 47.0586L13.6403 50.1479L9.42688 56.0456L11.1123 63.9093L15.8875 73.458L22.0672 83.8494L27.6851 79.6367L36.1119 81.041L45.9432 85.2537C47.8159 86.6579 51.842 89.7472 52.9656 90.8706C54.0892 91.994 57.179 90.4025 58.5835 89.4663Z",
            textX: 10,
            textY: 22
        },
        {
            city_name: "Gurlan tumani",
            slug: 'gurlan-tumani',
            data: "M58.5857 55.7646H62.7991L67.0104 44.2502L74.0339 38.9142L84.4269 39.7567L82.4607 34.1398L78.2473 27.3995L76.8428 23.7484V13.9188L76.281 9.42537L74.0339 7.45942L65.8878 6.61691L57.7419 4.65096L56.0566 3.52759L52.6858 1L49.8769 1.28078L49.0342 1.8425L45.9443 4.65096L37.5175 10.5487L34.1467 11.953L29.6524 12.2338L27.1244 12.7955L26.0008 13.6381L25.439 14.4806L25.7199 15.6039L26.2817 16.4465L27.967 20.3783L28.2479 22.6251L26.5626 27.6803L24.5963 29.6462L26.2817 32.4547L28.8097 34.9823L36.6748 39.7567L38.9219 41.7226L40.8882 44.2502L42.2927 47.3395L42.5747 50.4284L44.541 53.2369L50.1589 57.1689L54.3723 54.3604C55.3086 54.8285 57.4621 55.7646 58.5857 55.7646Z",
            textX: 14,
            textY: 10
        },
        {
            city_name: "Yangibozor tumani",
            slug: 'yangibozor-tumani',
            data: "M72.6282 74.0198L76.8416 78.2325H82.4595C83.5831 78.2325 84.8003 80.1048 85.2685 81.0409L91.1651 82.4451C92.1014 81.0409 94.1992 77.9516 95.0998 76.8282C96.0004 75.7048 97.3477 76.3601 97.9088 76.8282C99.7814 77.7644 103.863 79.6367 105.21 79.6367C106.893 79.6367 108.019 75.424 108.019 74.0198C108.019 72.8964 106.146 71.6794 105.21 71.2113C103.712 68.8709 100.718 63.9093 100.718 62.7859C100.718 61.6626 102.59 63.254 103.527 64.1902L108.019 62.7859L109.704 59.1351L108.019 55.4841L105.21 53.799H97.6257L93.974 52.9565L91.1651 50.9906L88.9179 47.9012L84.4269 39.7566L74.0338 38.9141L67.0103 44.2501L62.7969 55.7648C64.6695 58.1052 68.9766 63.0668 71.2237 64.1902C73.4709 65.3136 73.0964 67.4667 72.6282 68.4029V74.0198Z",
            textX: 21,
            textY: 20
        },
        {
            city_name: "Xonqa tumani",
            slug: 'xonqa-tumani',
            data: "M125.998 135.806C124.594 135.806 120.38 137.21 118.976 137.21L120.38 127.381L114.763 124.572L100.718 120.359V110.53L111.954 109.126H117.571C118.508 108.657 120.38 107.44 120.38 106.317C120.38 104.913 120.38 103.509 118.976 102.104C117.571 100.7 114.763 100.7 116.167 99.296C117.571 97.8917 121.785 97.8917 124.594 97.8917C126.841 97.8917 129.275 96.9556 130.212 96.4875L133.021 90.8706L160.545 116.147L158.301 118.955L149.874 119.236C149.874 119.61 150.155 121.202 151.279 124.572C152.683 128.785 149.874 127.381 148.47 130.189C147.065 132.997 144.257 131.593 138.639 131.593C133.021 131.593 133.021 131.593 130.212 132.997C127.403 134.402 127.403 135.806 125.998 135.806Z",
            textX: 32,
            textY: 37
        },
        {
            city_name: "Urganch tumani",
            slug: 'urganch-tumani',
            data: "M108.019 62.7859L109.704 59.135L112.513 67.5604L116.727 75.1432L122.064 81.041L133.021 90.8707L130.212 96.4876C129.275 96.9557 126.841 97.8918 124.594 97.8918C121.785 97.8918 117.571 97.8918 116.167 99.2961C114.762 100.7 117.571 100.7 118.976 102.105C120.38 103.509 120.38 104.913 120.38 106.317C120.38 107.441 118.508 108.658 117.571 109.126H111.954L100.718 110.53H86.673L83.864 107.721L76.8416 106.317L74.0327 103.509L72.6282 97.8918L69.8193 99.2961L65.6058 96.4876V90.8707L76.8416 78.2324H82.4595C83.5831 78.2324 84.8003 80.1047 85.2685 81.0408L91.1651 82.4451C92.1014 81.0408 94.1992 77.9515 95.0998 76.8282C96.0005 75.7048 97.3477 76.3601 97.9088 76.8282C99.7814 77.7643 103.863 79.6366 105.21 79.6366C106.893 79.6366 108.019 75.4239 108.019 74.0197C108.019 72.8963 106.146 71.6793 105.21 71.2113C103.712 68.8709 100.718 63.9093 100.718 62.7859C100.718 61.6625 102.59 63.254 103.527 64.1901L108.019 62.7859Z",
            textX: 21,
            textY: 29
        },
        {
            city_name: "Urganch shahar",
            slug: 'urganch-shahri',
            data: "M100.718 100.7C96.5044 97.8918 97.9089 95.0834 97.9089 92.2749C97.9089 90.1583 102.895 87.0448 105.821 85.5171C107.397 84.9612 111.111 85.5346 113.358 92.2749C116.167 100.7 104.931 103.509 100.718 100.7Z",
            textX: 37,
            textY: 10
        },
        {
            city_name: "Urganch shahar",
            slug: 'urganch-shahri',
            data: "M137.084 47.2267C127.539 40.8644 130.721 34.502 130.721 28.1397C130.721 23.3448 142.015 16.2914 148.645 12.8305C152.215 11.5712 160.629 12.8701 165.72 28.1397C172.083 47.2267 146.63 53.589 137.084 47.2267Z",
            textX: 37,
            textY: 10
        },
    ]
};
export default Map;