import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../../helpers/httpClient';
import _ from 'lodash';
import Loading from './Loading';

const MetodisNotificationChahge = (props) => {
    const {setClose, getResponse, data, pupils, type} = props;
    const { t } = useTranslation();
    const customStyles = {
		menu: (provided, state) => ({
			...provided,
			borderBottom: '1px dotted pink',
			color: state.selectProps.menuColor,
			margin: 0,
			boxShadow: 'none',
			borderRadius: 8,
		}),
		control: provided => ({
			...provided,
			borderRadius: 8,
			minHeight: 45,
			border: 'none',
			boxShadow: 'none',
			background: 'rgba(137, 141, 166, 0.1)',
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			borderRadius: 8,
		}),
		multiValue: provided => ({
			...provided,
			background: '#898DA6',
			borderRadius: 4,
			color: '#ffffff',
			height: 30,
		}),
		multiValueLabel: provided => ({
			...provided,
			background: '#898DA6',
			borderRadius: 4,
			color: '#ffffff',
		}),
		multiValueRemove: provided => ({
			...provided,
			'background': 'transparent',
			'color': '#ffffff',
			':hover': {
				background: 'transparent',
			},
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return { ...provided, opacity, transition, background: '#898DA6' };
		},
		value: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return { ...provided, opacity, transition, background: '#898DA6' };
		},
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		dropdownIndicator: provided => ({
			...provided,
			'color': '#898DA6',
			':hover': {
				color: '#898DA6',
			},
		}),
	};
    const [notification, setNotification] = useState({});
    const [notificationE, setNotificationE] = useState({});
    const [users, setUsers] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    useEffect(() => {
        setNotification({...notification, ...data});
        let ul = [];
        _.get(data, 'users', []).forEach(item=>{
            ul.push({value: item.id, label: item.firstname+' '+item.lastname+' '+item.middlename})
        });
        setUsers(ul);
    }, []);
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setNotification({...notification, [name]: value});
        setNotificationE({...notificationE, [name]: ''});
    }
    const handleUser = (s) => {
        setNotificationE({...notificationE, users: ''});
        if(_.some(s, { value: 0, label: t('survey.all') } )){
            setUsers(user_list);
        }else{
            setUsers(s);
        }
    }
    let user_list = [];
    _.get(pupils, 'data', []).forEach(item => {
        user_list.push({ value: item.id, label: _.get(item, 'full_name', '') });
    });
    const submitHandler = (event) => {
        event.preventDefault();
        setSaveLoading(true);
        let post_user = [];
        users.forEach(item=>{
            post_user.push(item.value);
        });
        let t = true, d = true, de = true, u = true;
        if(_.get(notification, 'title', '') ===''){
            t = false;
            setNotificationE({...notificationE, title: t('m_notification.title_e') });
        }
        if(_.get(notification, 'description', '') ===''){
            d = false;
            setNotificationE({...notificationE, description: t('m_notification.description_e') });
        }
        if(_.get(notification, 'detail', '') ===''){
            de = false;
            setNotificationE({...notificationE, detail: t('m_notification.detail_e') });
        }
        if(post_user.length===0){
            u = false;
            setNotificationE({...notificationE, users: t('m_notification.users_e') });
        }

        if(t&&d&&de&&u){
            getInstance()
            .post(`/api/v1/notification-update`, {...notification, users: post_user, status: 1, type: 'metodist'})
            .then(response => {
                getResponse( _.get(response, 'data.data', {}));
                setSaveLoading(false);
            })
            .catch(error => { setSaveLoading(false); } );
        }else{
            setSaveLoading(false);
        }

    }
    return(
        <div className="card card--shadow">
            <div className="card-body">
                <div className="mb-2 d-flex">
                    <h4 style={{display: 'inline'}}>{type==='create'?"Yangi E'lon qo'shish":"E'lonni tahrirlash"}</h4>
                    <span className="icon__button" onClick={()=>setClose()}
                        style={{marginLeft: 'auto'}}
                    >
                        <span class="icon icon-close"></span>
                    </span>
                </div>
                <div>
                    <form  onSubmit={submitHandler} >
                        <div className="form-group">
                            <label for=""> {t('m_notification.title') }</label>
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                onChange={e=>handleChange(e)}
                                value={_.get(notification, 'title', '')}
                            />
                            <span style={{ color: 'red' }}>{_.get(notificationE, 'name', '')}</span>
                            <br/>
                            <label for=""> {t('m_notification.users') }</label>
                            <div>
                                <Select
                                    value={users}
                                    onChange={select => handleUser(select)}
                                    options={[
                                        { value: 0, label: t('survey.all') },
                                        ...user_list
                                    ]}
                                    isMulti={true}
                                    styles={customStyles}
                                    // closeMenuOnSelect={false}
                                    placeholder={t('survey.select')}
                                />
                            </div>
                            <span style={{ color: 'red' }}>{_.get(notificationE, 'users', '')}</span>
                            <br/>
                            <label for=""> {t('m_notification.description') }</label>
                            <textarea
                                className="form-control"
                                rows="4"
                                name="description"
                                onChange={e=>handleChange(e)}
                                value={_.get(notification, 'description', '')}
                            >
                            </textarea>
                            <span style={{ color: 'red' }}>{_.get(notificationE, 'description', '')}</span>
                            <br/>
                            <label for=""> {t('m_notification.detail') }</label>
                            <textarea
                                className="form-control"
                                rows="10"
                                name="detail"
                                onChange={e=>handleChange(e)}
                                value={_.get(notification, 'detail', '')}
                            >
                            </textarea>
                            <span style={{ color: 'red' }}>{_.get(notificationE, 'detail', '')}</span>
                        </div>
                        <div>
                            {saveLoading?<span style={{float: 'left'}}><Loading size={5}/></span>
                            :<button className="btn btn-primary btn-sm">{t('m_notification.save') }</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default MetodisNotificationChahge;