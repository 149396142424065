import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddItem from './AddItem';
import InputCreator from './InputCreator';
import CheckboxGroupCreator from './CheckboxGroupCreator';
import RadioGroupCreator from './RadioGroupCreator';
import SelectCreator from './SelectCreator';
import RatingCreator from './RatingCreator';
import SorttableCreator from './SorttableCreator';
import BooleanCreator from './BooleanCreator';
import MatrixRadioCreator from './MatrixRadioCreator';
import MatrixCheckboxCreator from './MatrixCheckboxCreator';
import MultipleInputCreator from './MultipleInputCreator';
import Survey from '../survey-items/Survey';
import _ from 'lodash';
import Loading from '../Loading';

const Creator = props => {
	const { t } = useTranslation();
	const [last_id, setLastId] = useState(0);
	const [action, setAction] = useState(0);
	const [surveys, setSurveys] = useState([]);
	const [survey_test, setSurveyTest] = useState({});
	const { survey_data, setSurveyJson, loading } = props;
	let auto_time = useRef(null);

	useEffect(() => {
		const settings = JSON.parse(_.get(survey_data, 'settings', '[]'));
		const last_data_id = _.get(survey_data, 'last_id', 0);
		setLastId(last_data_id);
		setSurveys(settings);
		return () => {
			clearTimeout(auto_time.current);
			// let p = [];
			// _.get(survey_data, 'positions', []).forEach(item=>{
			//     p = [...p, item.id];
			// })
			// const d = {...survey_data, settings: surveys, position: p, last_id: last_id };
			// setSurveyJson(d, true);
		};
	}, [survey_data]);

	const setSurveyItem = data => {
		let l = [];
		surveys.forEach(item => {
			if (item.id === data.id) {
				l.push(data);
			} else {
				l.push(item);
			}
		});
		setSurveys(l);
		autoSave(l);
		// console.log('data 55==========> ',  l);
	};
	const autoSave = surveys_list_new => {
		clearTimeout(auto_time.current);
		auto_time.current = setTimeout(() => {
			let p = [];
			_.get(survey_data, 'positions', []).forEach(item => {
				p = [...p, item.id];
			});
			const d = { ...survey_data, settings: surveys_list_new, position: p, last_id: last_id };
			setSurveyJson(d, true);
		}, 10000);
	};
	const deleteSurvey = id => {
		let l = [];
		surveys.forEach(item => {
			if (item.id !== id) {
				let logic_l = [];
				item.logic.forEach(logic_item => {
					if (logic_item.survey != id) {
						logic_l.push(logic_item);
					}
				});
				l.push({ ...item, logic: logic_l });
			}
		});
		setSurveys(l);
		autoSave(l);
	};
	const setSurveyBack = () => {
		let p = [];
		_.get(survey_data, 'positions', []).forEach(item => {
			p = [...p, item.id];
		});
		const d = { ...survey_data, settings: surveys, position: p, last_id: last_id };
		setSurveyJson(d);
	};
	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className="row">
						<div className="col-12">
							<button
								className="btn btn-sm mb-2"
								style={{
									background: action ? 'white' : '#26CAAC',
									border: 'none',
									color: action ? '#26CAAC' : 'white',
									borderRadius: '8px 0 0 8px',
								}}
								onClick={() => setAction(0)}>
								<span className="icon icon-question"></span> {t('survey.creator')}
							</button>
							<button
								className="btn btn-sm mb-2"
								style={{
									background: action ? '#26CAAC' : 'white',
									border: 'none',
									color: action ? 'white' : '#26CAAC',
									borderRadius: '0 8px 8px 0',
								}}
								onClick={() => setAction(1)}>
								<span className="icon icon-eye"></span> {t('survey.survey_test')}
							</button>
						</div>
					</div>
					{action === 1 ? (
						<Survey
							data={{
								surveys: surveys,
								last_id: last_id,
							}}
						/>
					) : (
						<>
							{surveys.length
								? surveys.map((survey, index) => (
										<div key={index}>
											{survey.type === 'input' ? (
												<InputCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'radiogroup' ? (
												<RadioGroupCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'checkboxgroup' ? (
												<CheckboxGroupCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'select' ? (
												<SelectCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'rating' ? (
												<RatingCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'sorttable' ? (
												<SorttableCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'boolean' ? (
												<BooleanCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'matrix_radio' ? (
												<MatrixRadioCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'matrix_checkbox' ? (
												<MatrixCheckboxCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : survey.type === 'multiple_input' ? (
												<MultipleInputCreator
													number={index + 1}
													survey={survey}
													surveys={surveys}
													setSurveyItem={data => setSurveyItem(data)}
													deleteSurvey={() => deleteSurvey(survey.id)}
													setSurveyTest={d => setSurveyTest(d)}
												/>
											) : null}
										</div>
								  ))
								: null}
						</>
					)}
				</>
			)}

			<div
				style={
					surveys.length || action === 1 || loading
						? { textAlign: 'center', padding: '10% 0', display: 'none' }
						: { textAlign: 'center', padding: '10% 0' }
				}>
				<div>
					<img style={{ maxWidth: 200, opacity: 0.8 }} src="/images/survey/add.svg" alt="add" />
				</div>
				<a className="btn popout-button right-sidebar-menu-btn btn-sm  mb-3">
					<span className="icon icon-plus mr-2"></span>
					<span>{t('survey.add')}</span>
				</a>
			</div>
			<div className="row" style={!surveys.length || action === 1 || loading ? { display: 'none' } : {}}>
				<div className="col-12">
					<a className="btn right-sidebar-menu-btn btn-sm  mb-3">
						<span className="icon icon-plus mr-2"></span>
						<span>{t('survey.add')}</span>
					</a>
					<button
						onClick={() => setSurveyBack()}
						style={{ float: 'right' }}
						className="btn btn-primary btn-sm  ml-2 mb-3">
						{t('survey.btn')}
					</button>
				</div>
			</div>

			<AddItem
				last_id={last_id}
				surveys={surveys}
				setLastId={id => setLastId(id)}
				setSurveys={data => {
					setSurveys(data);
					autoSave(data);
				}}
			/>

			<div
				className="modal fade"
				id="modalSurveyTest"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modalSurveyTestTitle"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered survey-test-modal" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{t('survey.survey_test')}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span className="icon icon-close"></span>
							</button>
						</div>
						<div className="modal-body">
							<div className="card" style={{ background: '#f5f5f5' }}>
								<div className="card-body">
									<Survey
										data={{
											surveys: [survey_test],
											last_id: survey_test.id,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Creator;
