import React, {useState, useEffect} from 'react';
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import Pagination from './sections/Pagination';
import { getRetrainingCourse } from "../actions";
import { connect } from "react-redux";
import NoData from "./sections/NoData";
import Loading from "./sections/Loading";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";

const  DashRetrainingItemPage = (props) => {
    const {retraining_courses, retraining_courses_status} = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        props.getRetrainingCourse( props.match.params.slug?props.match.params.slug:'', props.match.params.page?props.match.params.page:1 );
    },[props.match.params.page, props.location.slug]);

    useEffect(()=>{
        if(retraining_courses_status.status === 'started'){
            setLoading(true)
        }
        if(retraining_courses_status.status === 'success'){
            setLoading(false)
        }
        if(retraining_courses_status.status === 'error'){
            setLoading(false)
        }
    },[retraining_courses_status]);

  return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
      <main className="main">

        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('retraining_item.courses')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    },
                                    {
                                        'title': t('retraining_item.retraining'),
                                        'link': '/dashboard/qualification'
                                    }
                                ]}
                            />
                            <div className="dashboard-title">
                                <h5>{t('retraining_item.title')}</h5>
                            </div>
                            <div className="row">
                                {loading?<Loading/>:<>
                                <div className="col-12">
                                {retraining_courses.data&&retraining_courses.data.length>0?<>
                                    {retraining_courses.data.map((course,index)=>{
                                        const total_score = course.total_score?course.total_score:1;
                                        const user_score = course.user_score?course.user_score:0;
                                        const percent = parseInt(100*user_score/total_score);
                                        return(
                                            <div className="card mb-5 qualification-time" key={index}>
                                                <div className="card-body" style={{display: 'block'}}>
                                                    <p>{course.name}</p>
                                                    <div class="course__footer-progress">
                                                        <span>{percent}%</span>
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar" style={{width: percent+"%"}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="qualification-time__buttons">
                                                        <div>
                                                            <span className="icon icon-clock"></span>
                                                            {/* { parseInt(course.hours/60) } {t('retraining_item.h')} { parseInt(course.hours)%60 } {t('retraining_item.m')} */}
                                                            { parseInt(course.hours) } {t('create_course.credit')}
                                                        </div>
                                                        <Link to={`/courses/view/${course.slug}`} className="btn btn-primary btn-sm"
                                                            style={{marginLeft: 'auto'}}
                                                        > {t('retraining_item.btn')}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )})}
                                    
                                    <Pagination
                                        total={retraining_courses.total?retraining_courses.total:6}
                                        active={ parseInt(props.match.params.page) }
                                        link={"/dashboard/qualification/"+props.match.params.slug+"/"}/>
                                </>:<NoData text={t('retraining_item.no_data')}/>}
                                </div>
                                {/* <div className="col-md-4 col-lg-4">
                                    <div className="card">
                                        <div className="card-body p-3 pt-5">
                                            <div className="d-flex justify-content-center">
                                                <div className="circle-progress-bar big mb-3">
                                                    <div className="text">2{t('retraining_item.h')} 30{t('retraining_item.m')}</div>
                                                    <svg className="progress-circle-big">
                                                        <circle className="bg" cx="65" cy="65" r="60"></circle>
                                                        <circle className="progress" cx="65" cy="65" r="60" data-percent="10"></circle>
                                                    </svg>
                                                </div>
                                            </div>
                                            <p>{t('retraining_item.text')}</p>
                                            <button type="button" className="btn btn-light btn-sm mt-1 w-100" disabled>{t('retraining_item.confirm')}</button>
                                        </div>
                                    </div>
                                </div> */}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>

  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getRetrainingCourse: (slug, page)=>dispatch(getRetrainingCourse(slug, page))
    })
)(DashRetrainingItemPage);