import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import languageEN from './locate/en/translate.json';
import languageRU from './locate/ru/translate.json';
import languageUZ from './locate/uz/translate.json';
const debug = process.env.NODE_ENV === 'development';
const lng = window.localStorage.getItem('language') || 'uz';
i18n
	.use(XHR)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: languageEN,
			ru: languageRU,
			uz: languageUZ,
		},
		/* default language when load the website in browser */
		// lng: "ru",
		lng,
		/* When react i18next not finding any language to as default in borwser */
		fallbackLng: 'uz',
		// fallbackLng: 'ru',
		/* debugger For Development environment */
		debug,
		ns: ['translations'],
		defaultNS: 'translations',
		keySeparator: '.',
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
		},
		react: {
			wait: true,
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default',
		},
	});
export default i18n;
