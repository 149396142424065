import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getRegionList, getCitiesList } from '../actions';
import Layout from './Layout';
// import DashSideBar from './sections/DashSideBar';
import Navigation from './sections/Navigation';
import ChartLine from './sections/ChartLine';
import ChartLinePassing from './sections/ChartLinePassing';
import ChartColumnS from './sections/ChartColumnS';
import ChartCircle from './sections/ChartCircle';
import { useTranslation } from 'react-i18next';
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import CheckRole from './sections/CheckRole';
import { roles } from '../helpers/roles';
import { getInstance } from '../helpers/httpClient';
import _ from 'lodash';
import RegionMaps from './sections/maps/Maps';
import { Link } from 'react-router-dom';
import { getLanguage } from '../helpers/language';
import Moment from 'react-moment';

const ManagerStatisticsPage = (props) => {
  const { t } = useTranslation();
  const lan = getLanguage();
  let auto_time = useRef(null);
  const { region_list, cities_list, location } = props;
  const query_search = _.get(location, 'search', null);
  const [users, setUsers] = useState({});
  const [userGenders, setUsersGenders] = useState([]);
  const [userPositionR, setUsersPositionR] = useState([]);
  const [register, setRegister] = useState({});
  const [user_counts, setUserCounts] = useState({});
  const [certificat, setCertificat] = useState({});
  const [diagnistics, setDiagnistics] = useState([]);
  const [lesson_passing, setLesson_passing] = useState({});
  const [course_score, setCourse_score] = useState({});
  const [region_active, setRegion_active] = useState({});
  const [region_active_c, setRegion_active_c] = useState([]);
  const [region_score, setRegion_score] = useState({});
  const [region_score_c, setRegion_score_c] = useState([]);
  const [l_user, setL_user] = useState(true);
  const [l_register, setL_register] = useState(true);
  const [l_user_counts, setL_user_counts] = useState(true);
  const [l_certificat, setL_certificat] = useState(true);
  const [l_diagnostic, setL_diagnostic] = useState(true);
  const [l_passing, setL_passing] = useState(true);
  const [l_course, setL_course] = useState(true);
  const [l_region_active, setL_region_active] = useState(true);
  const [l_region_score, setL_region_score] = useState(true);
  const [f_register, setF_register] = useState('day=1&per_page=7');
  const [f_user_counts, setF_user_counts] = useState('day=1&per_page=7');
  const [f_certificat, setF_certificat] = useState('day=1&per_page=7');
  const [s_register, setS_register] = useState(1);
  const [s_user_counts, setS_user_counts] = useState(1);
  const [s_certificat, setS_certificat] = useState(1);
  const [filter, setFilter] = useState({});
  const [filter_query, setFilterQuery] = useState({});
  const [positions, setUserPosition] = useState([]);
  const [position_types, setPositionTypes] = useState([]);
  const [schools, setSchools] = useState([]);
  const [schoolTypes, setSchoolTypes] = useState([]);
  const [pagination, setPagination] = useState({});
  const [userAll, setUserAll] = useState({});
  const [activeUserAll, setActiveUserAll] = useState(null);
  const [filterRegion, setFilterRegion] = useState('day=1');
  const [l_user_all, setL_user_all] = useState(true);
  const [btn_l, setBtn_l] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(query_search);
    const s_region_id = params.get('region_id');
    const s_city_id = params.get('city_id');
    const s_school_id = params.get('school_id');
    const s_position_type_id = params.get('position_type_id');
    const s_school_type = params.get('school_type');
    const s_is_specialized = params.get('is_specialized');
    const s_position_id = params.get('position_id');

    if (s_position_type_id) getPositions(s_position_type_id);
    if (s_region_id) {
      props.getCitiesList(s_region_id);
      if (s_city_id)
        getSchools({
          region_id: s_region_id,
          city_id: s_city_id,
          school_type: s_school_type,
          is_specialized: s_is_specialized,
        });
    }
    const s_filter = {
      region_id: s_region_id,
      city_id: s_city_id,
      school_id: s_school_id,
      position_type_id: s_position_type_id,
      school_type: s_school_type,
      is_specialized: s_is_specialized,
      position_id: s_position_id,
    };
    const str =
      `${s_filter.region_id ? '&region_id=' + s_filter.region_id : ''}` +
      `${s_filter.city_id ? '&city_id=' + s_filter.city_id : ''}` +
      `${s_filter.school_id ? '&school_id=' + s_filter.school_id : ''}` +
      `${
        s_filter.position_type_id
          ? '&position_type_id=' + s_filter.position_type_id
          : ''
      }` +
      `${s_filter.school_type ? '&school_type=' + s_filter.school_type : ''}` +
      `${
        s_filter.is_specialized
          ? '&is_specialized=' + s_filter.is_specialized
          : ''
      }` +
      `${s_filter.position_id ? '&position_id=' + s_filter.position_id : ''}`;
    setFilterQuery(s_filter);
    setFilter(s_filter);

    getResultDiagnostic(str);
    getResultUserAll(str);
    getResultRegionCountActiva(str);
    getResultRegister(str);
    getResultUser(str);
    clearTimeout(auto_time.current);
    auto_time.current = setTimeout(() => {
      getResultUserCount('day=1&per_page=7&step=1', str);
      getResultUserCertificat(str);
      getResultPassing(str);
      getResultCoursePassing(str);
      getResultRegionScore(str);
    }, 10000);
    return () => {
      clearTimeout(auto_time.current);
    };
  }, [lan, query_search]);
  useEffect(() => {
    getPositionType();
    props.getRegionList();
    getSchoolType();
  }, [lan]);
  const convertLink = (is_refresh = false) => {
    const str =
      `${filter.region_id ? '&region_id=' + filter.region_id : ''}` +
      `${filter.city_id ? '&city_id=' + filter.city_id : ''}` +
      `${filter.school_id ? '&school_id=' + filter.school_id : ''}` +
      `${
        filter.position_type_id
          ? '&position_type_id=' + filter.position_type_id
          : ''
      }` +
      `${filter.school_type ? '&school_type=' + filter.school_type : ''}` +
      `${
        filter.is_specialized ? '&is_specialized=' + filter.is_specialized : ''
      }` +
      `${filter.position_id ? '&position_id=' + filter.position_id : ''}` +
      `${is_refresh ? '&refresh=1' : ''}`;
    return str;
  };
  const getSchoolType = () => {
    getInstance()
      .get('/api/v1/school-type')
      .then((response) => {
        const { data } = response.data;
        const schoolTypes = Array.isArray(data) ? data : [];
        setSchoolTypes(schoolTypes);
      })
      .catch((e) => console.log(e));
  };
  const getResultUserAll = (filter = '') => {
    setL_user_all(true);
    getInstance()
      .get(`/api/v1/result-user-all?${filter}`)
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response;
            setL_user_all(false);
            setUserAll(data ? data : {});
          } catch {}
        }
      })
      .catch((error) => {
        setL_user_all(false);
      });
  };
  const getPositionType = () => {
    getInstance()
      .get('/api/v1/profil/position-type?status=1')
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            setPositionTypes(data ? data : []);
          } catch {}
        }
      })
      .catch((error) => {});
  };
  const getPositions = (id) => {
    getInstance()
      .get(`/api/v1/profil/user-position?type_id=${id}`)
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            setUserPosition(data ? data : []);
          } catch {}
        }
      })
      .catch((error) => {});
  };
  const getSchools = ({
    region_id,
    city_id,
    is_specialized,
    school_type_id,
  }) => {
    getInstance()
      .get(
        '/api/v1/school?' +
          (region_id ? '&region_id=' + region_id : '') +
          (city_id ? '&city_id=' + city_id : '') +
          (school_type_id ? '&school_type=' + school_type_id : '') +
          (is_specialized ? '&is_specialized=' + is_specialized : '')
      )
      .then((response) => {
        if (response.status === 200) {
          try {
            const { data } = response.data;
            setSchools(data ? data : []);
          } catch {}
        }
      })
      .catch((error) => {});
  };
  const getResultRegionScore = (filter_query = '', filter = 'day=1') => {
    setL_region_score(true);
    getInstance()
      .get(`/api/v1/result-lesson-passing-region?${filter}${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            setRegion_score(data);
            const d = getScoreRegions(data);
            setRegion_score_c(d);
            setL_region_score(false);
          }
        } else {
          setL_region_score(false);
        }
      })
      .catch((error) => {
        setL_region_score(false);
      });
  };
  const getResultRegionCountActiva = (filter_query = '', filter = 'day=1') => {
    setL_region_active(true);
    setFilterRegion(filter);
    getInstance()
      .get(`/api/v1/result-user-count-region?${filter}${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            setRegion_active(data);
            const d = getActiveRegions(data);
            setRegion_active_c(d);
            setBtn_l(true);
            setL_region_active(false);
          }
        } else {
          setL_region_active(false);
          setBtn_l(true);
        }
      })
      .catch((error) => {
        setL_region_active(false);
      });
  };
  const getResultCoursePassing = (filter = '') => {
    setL_course(true);
    getInstance()
      .get(`/api/v1/result-score-new?${filter}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            setCourse_score(data);
            setL_course(false);
          }
        } else {
          setL_course(false);
        }
      })
      .catch((error) => {
        setL_course(false);
      });
  };
  const getResultPassing = (filter = '') => {
    setL_passing(true);
    getInstance()
      .get(`/api/v1/result-lesson-passing-new?${filter}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            const l = getPassing(data);
            setLesson_passing(l);
            setL_passing(false);
          }
        } else {
          setL_passing(false);
        }
      })
      .catch((error) => {
        setL_passing(false);
      });
  };
  const getResultDiagnostic = (filter_query = '') => {
    setL_diagnostic(true);
    getInstance()
      .get(`/api/v1/result-diagnostic-new?${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            const d = getDiagnostic(data);
            setDiagnistics(d);
          }
          setL_diagnostic(false);
        } else {
          setL_diagnostic(false);
        }
      })
      .catch((error) => {
        setL_diagnostic(false);
      });
  };
  const getResultUserCertificat = (
    filter_query = '',
    filter = 'day=1&per_page=12&step=1'
  ) => {
    setL_certificat(true);
    getInstance()
      .get(`/api/v1/result-certificat?${filter}${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            const c = getCertificat(data);
            setCertificat(c);
            setL_certificat(false);
          }
        } else {
          setL_certificat(false);
        }
      })
      .catch((error) => {
        setL_certificat(false);
      });
  };
  const getResultUserCount = (
    filter = 'day=1&per_page=7&step=1',
    filter_query = ''
  ) => {
    setL_user_counts(true);
    getInstance()
      .get(`/api/v1/result-user-count?${filter}${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            const r = getPositionCount(data);
            setUserCounts(r);
            setL_user_counts(false);
          }
        } else {
          setL_user_counts(false);
        }
      })
      .catch((error) => {
        setL_user_counts(false);
      });
  };
  const getResultRegister = (
    filter_query = '',
    filter = 'day=1&per_page=7&step=1'
  ) => {
    setL_register(true);
    getInstance()
      .get(`/api/v1/result-register?${filter}${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data) {
            const r = getRegister(data);
            setRegister(r);
            setL_register(false);
          }
        } else {
          setL_register(false);
        }
      })
      .catch((error) => {
        setL_register(false);
      });
  };
  const getResultUser = (filter_query = '') => {
    setL_user(true);
    getInstance()
      .get(`/api/v1/result-user-new?${filter_query}`)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response?.data;
          if (data) {
            setUsers(data);
            const g = getGender(data);
            setUsersGenders(g);
            const p = getPosition(data);
            setUsersPositionR(p);
          }
          setL_user(false);
        } else {
          setL_user(false);
        }
      })
      .catch((error) => {
        setL_user(false);
      });
  };
  const getRegister = (register_list) => {
    let cat = [],
      t = {},
      p = [],
      pn = [],
      r = [];
    const d = _.get(register_list, 'data', []);
    d.forEach((item) => {
      if (!cat.includes(item.yearmonth)) cat.push(item.yearmonth);
      if (!p.includes(item.position_type_id)) {
        p.push(item.position_type_id);
        pn.push(item.name);
      }
    });
    p.forEach((pp) => {
      d.forEach((item) => {
        if (pp === item.position_type_id)
          t = { ...t, [pp]: (t[pp] ? t[pp] : 0) + item.count };
      });
    });
    p.forEach((pp, index) => {
      let pd = [];
      cat.forEach((c) => {
        let is_date = true;
        d.forEach((item) => {
          if (c === item.yearmonth && pp === item.position_type_id) {
            pd.push(item.count);
            is_date = false;
          }
        });
        if (is_date) pd.push(0);
      });
      r.push({
        data: pd,
        name: pn[index],
        yAxis: 'y_axis_0',
        type: 'spline',
      });
    });
    return { cat: cat, data: r };
  };
  const getGender = (ddd) => {
    const a = _.get(ddd, 'gender', []);
    let g = [];
    const color = ['#86E1FB', '#3999FB'];
    const total = _.sumBy(a, (o) => {
      return o.count;
    });
    a.forEach((gen, index) => {
      g.push({
        name: t('statistics.' + gen.gender),
        y: (gen.count / total) * 100,
        selected: true,
        color: color[index],
      });
    });
    return g;
  };
  const getPosition = (ddd) => {
    const a = _.get(ddd, 'position', []);
    let g = [];
    const color = ['#86E1FB', '#8FC5FB', '#3999FB'];
    const total = _.sumBy(a, (o) => {
      return o.count;
    });
    a.forEach((gen, index) => {
      g.push({
        name: gen.name,
        y: (gen.count / total) * 100,
        selected: true,
        color: color[index],
      });
    });
    return g;
  };
  const getPositionCount = (user_counts_res) => {
    let cat = [],
      t = {},
      p = [],
      pn = [],
      r = [];
    const d = _.get(user_counts_res, 'data', []);
    d.forEach((item) => {
      if (!cat.includes(item.yearmonth)) cat.push(item.yearmonth);
      if (!p.includes(item.position_id)) {
        p.push(item.position_id);
        pn.push(item.name);
      }
    });
    p.forEach((pp) => {
      d.forEach((item) => {
        if (pp === item.position_id)
          t = { ...t, [pp]: (t[pp] ? t[pp] : 0) + item.count };
      });
    });
    p.forEach((pp, index) => {
      let pd = [];
      cat.forEach((c) => {
        let is_date = true;
        d.forEach((item) => {
          if (c === item.yearmonth && pp === item.position_id) {
            pd.push(item.count);
            is_date = false;
          }
        });
        if (is_date) pd.push(0);
      });
      r.push({
        data: pd,
        name: pn[index],
        yAxis: 'y_axis_0',
        type: 'spline',
      });
    });
    return { cat: cat, data: r };
  };
  const getCertificat = (ddd) => {
    let cat = [],
      t = {},
      p = [],
      pn = [],
      r = [];
    const d = _.get(ddd, 'data', []);
    d.forEach((item) => {
      if (!cat.includes(item.yearmonth)) cat.push(item.yearmonth);
      if (!p.includes(item.position_type_id)) {
        p.push(item.position_type_id);
        pn.push(item.position_name);
      }
    });
    p.forEach((pp) => {
      d.forEach((item) => {
        if (pp === item.position_type_id)
          t = { ...t, [pp]: (t[pp] ? t[pp] : 0) + item.count };
      });
    });
    p.forEach((pp, index) => {
      let pd = [];
      cat.forEach((c) => {
        let is_date = true;
        d.forEach((item) => {
          if (pp === item.position_type_id && c === item.yearmonth) {
            pd.push(item.count);
            // pd.push(item.count/t[pp]*100);
            is_date = false;
          }
        });
        if (is_date) pd.push(0);
      });
      r.push({
        data: pd,
        name: pn[index],
        yAxis: 'y_axis_0',
        type: 'spline',
      });
    });
    // console.log( {cat: cat, data: r} );
    return { cat: cat, data: r };
  };
  const getDiagnostic = (ddd) => {
    const c = _.get(ddd, 'categories', []);
    const p = _.get(ddd, 'position_types', []);
    const user_r = _.get(ddd, 'data', []);
    const r = _.get(ddd, 'result', {});
    let d = [];
    p.forEach((pp) => {
      let rr = [];
      let cat = [];
      let ur = [];
      const p_r = _.filter(user_r, (i) => {
        return pp.id === i.position_id;
      });
      c.forEach((cc) => {
        if (_.get(r, pp.id + '.' + cc.id, false)) {
          cat.push(cc.name);
          rr.push(
            (parseFloat(_.get(r, pp.id + '.' + cc.id + '.source', 0)) /
              parseFloat(_.get(r, pp.id + '.' + cc.id + '.maxsource', 1))) *
              100
          );
          let is_r = true;
          p_r.forEach((item) => {
            if (item.category_id === cc.id) {
              is_r = false;
              ur.push(
                (parseInt(item.score) / parseInt(item.total_score)) * 100
              );
            }
          });
          if (is_r) ur.push(0);
        }
      });
      d.push({ name: pp.name, cat: cat, data: rr, result: ur });
    });
    return d;
  };
  const getPassing = (ddd) => {
    const l = _.sortBy(_.get(ddd, 'data', []), [
      (o) => {
        return parseFloat(o.percent);
      },
    ]);
    let cat = [];
    // let mans = [];
    // let womans = [];
    let cat_l = [];
    let names = [];
    let chart_data = [];
    l.forEach((item) => {
      const p = parseFloat(parseFloat(item.percent) * 100);
      if (!cat.includes(p)) {
        cat.push(p);
      }
      if (!names.includes(item.name)) {
        names.push(item.name);
      }
    });
    names.forEach((nn) => {
      let ddd = [];
      cat.forEach((cc) => {
        l.forEach((item) => {
          if (nn === item.name && cc === parseFloat(item.percent) * 100)
            ddd.push(parseInt(item.count_percent));
        });
      });
      chart_data.push({
        data: ddd,
        name: nn,
        yAxis: 'y_axis_0',
        type: 'spline',
      });
    });
    cat.forEach((cc) => {
      cat_l.push(cc.toFixed(2) + '%');
    });
    // console.log('chart_data', chart_data);
    return {
      cat: cat_l,
      data: chart_data,
    };
  };
  const getActiveRegions = (ddd) => {
    const l = _.get(ddd, 'data', []);
    let d = [];
    l.forEach((item) => {
      d.push({
        slug: _.get(item, 'region.slug', ''),
        city_name: _.get(item, 'region.name', ''),
        value: (item.percent * 100).toFixed(1),
      });
    });
    return d;
  };
  const getScoreRegions = (ddd) => {
    const l = _.get(ddd, 'data', []);
    let d = [];
    l.forEach((item) => {
      d.push({
        slug: _.get(item, 'region.slug', ''),
        city_name: _.get(item, 'region.name', ''),
        value: parseFloat(item.percent) * 100,
      });
    });
    return d;
  };
  const coursePosition = (data) => {
    let d = [];
    data.forEach((item) => {
      if (!d.includes(_.get(item, 'type.name', '')))
        d = [...d, _.get(item, 'type.name', '')];
    });
    return d;
  };
  const UsersAllData = () => {
    const d = _.get(filter_query, 'city_id', false)
      ? _.get(userAll.data, '[0]', {})
      : activeUserAll
      ? _.find(userAll.data, ['region.slug', activeUserAll])
      : _.find(userAll.data, ['region_id', 0]);
    return (
      <>
        {!_.get(filter_query, 'school_id', false) ? (
          <div className='col-lg-4'>
            <div className='card card--shadow '>
              <div className='card-body statistic_info'>
                {l_user_all ? (
                  <Loading />
                ) : (
                  <>
                    <h3
                      style={{
                        fontWeight: 700,
                        color: '#0D2E69',
                        minHeight: 72,
                      }}
                    >
                      {_.get(filter_query, 'city_id', false)
                        ? _.get(
                            _.find(_.get(cities_list, 'data', []), [
                              'id',
                              parseInt(_.get(filter_query, 'city_id', 0)),
                            ]),
                            'name',
                            ''
                          )
                        : _.get(
                            d,
                            'region.name',
                            _.get(
                              _.find(_.get(region_list, 'data', []), [
                                'id',
                                parseInt(_.get(filter_query, 'region_id', 0)),
                              ]),
                              'name',
                              t('statistics.uzb_res')
                            )
                          )}
                    </h3>
                    {filter_query.city_id ? null : (
                      <div className='count_target'>
                        <span className='count_icon'>
                          <img src='/images/statistic/region.svg' alt='i' />
                        </span>
                        <span className='conut_text'>
                          <strong>
                            {t('statistics.city_count')}
                            {/* {filter_query.region
													? t('statistics.city_count')
													: t('statistics.region_count')} */}
                          </strong>
                          <div>{_.get(d, 'region_count', 0)}</div>
                        </span>
                      </div>
                    )}
                    <div className='count_target'>
                      <span className='count_icon'>
                        <img src='/images/statistic/building.svg' alt='i' />
                      </span>
                      <span className='conut_text'>
                        <strong>{t('profile.school')}</strong>
                        <div>{_.get(d, 'school_count', 0)}</div>
                      </span>
                    </div>
                    {_.get(userAll, 'positions', []).map((item, index) => (
                      <div className='count_target' key={index}>
                        <span className='count_icon'>
                          <img
                            src={`/images/statistic/${
                              item.id === 4
                                ? 'leader'
                                : item.id === 6
                                ? 'metodis'
                                : 'teacher'
                            }.svg`}
                            alt='i'
                          />
                        </span>
                        <span className='conut_text'>
                          <strong>{item.name}</strong>
                          <div>
                            {_.get(
                              _.find(_.get(d, 'data', []), [
                                'position_type_id',
                                item.id,
                              ]),
                              'count',
                              0
                            )}
                          </div>
                        </span>
                      </div>
                    ))}
                    <div className='count_target'>
                      <span className='count_icon'>
                        <img src='/images/statistic/gender.svg' alt='i' />
                      </span>
                      <span className='conut_text'>
                        <strong>
                          {t('statistics.man')}/{t('statistics.woman')}
                        </strong>
                        <div>
                          {_.get(d, 'man', 0)}/{_.get(d, 'woman', 0)}
                        </div>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='col-12'>
            <div className='card card--shadow '>
              <div className='card-body'>
                {l_user_all ? (
                  <div>
                    <Loading />
                  </div>
                ) : (
                  <>
                    <div className='count_target count_target_city'>
                      <div
                        className='count_icon'
                        style={{ minHeight: 150, minWidth: 150 }}
                      >
                        <img
                          src={`/images/statistic/building.svg`}
                          alt='i'
                          style={{ width: 120, height: 120 }}
                        />
                      </div>
                      <div className='conut_text_child'>
                        <div>
                          <h3 style={{ fontWeight: 'bold' }}>
                            {_.get(
                              _.find(schools, [
                                'id',
                                parseInt(_.get(filter_query, 'school_id', 0)),
                              ]),
                              'name',
                              ''
                            )}
                          </h3>
                          <div style={{ color: '#898DA6', fontSize: 18 }}>
                            {t('statistics.address')}:{' '}
                            {_.get(
                              _.find(_.get(region_list, 'data', []), [
                                'id',
                                parseInt(_.get(filter_query, 'region_id', 0)),
                              ]),
                              'name',
                              ''
                            ) +
                              ', ' +
                              _.get(
                                _.find(_.get(cities_list, 'data', []), [
                                  'id',
                                  parseInt(_.get(filter_query, 'city_id', 0)),
                                ]),
                                'name',
                                ''
                              )}
                          </div>
                        </div>
                        <div>
                          <div className='count_target count_target_child'>
                            <span className='count_icon'>
                              <img
                                src={`/images/statistic/leader.svg`}
                                alt='i'
                              />
                            </span>
                            <span className='conut_text'>
                              <strong>
                                {_.get(
                                  _.find(_.get(userAll, 'positions', []), [
                                    'id',
                                    4,
                                  ]),
                                  'name',
                                  t('statistics.leader')
                                )}
                              </strong>
                              <div>
                                {_.get(
                                  _.find(_.get(userAll, 'data', []), [
                                    'position_type_id',
                                    4,
                                  ]),
                                  'count',
                                  0
                                )}
                              </div>
                            </span>
                          </div>
                          <div className='count_target count_target_child'>
                            <span className='count_icon'>
                              <img
                                src={`/images/statistic/metodis.svg`}
                                alt='i'
                              />
                            </span>
                            <span className='conut_text'>
                              <strong>
                                {_.get(
                                  _.find(_.get(userAll, 'positions', []), [
                                    'id',
                                    12,
                                  ]),
                                  'name',
                                  t('m_pupils.sub_direktor')
                                )}
                              </strong>
                              <div>
                                {_.get(
                                  _.find(_.get(userAll, 'data', []), [
                                    'position_type_id',
                                    12,
                                  ]),
                                  'count',
                                  0
                                )}
                              </div>
                            </span>
                          </div>
                          <div className='count_target count_target_child'>
                            <span className='count_icon'>
                              <img
                                src={`/images/statistic/teacher.svg`}
                                alt='i'
                              />
                            </span>
                            <span className='conut_text'>
                              <strong>
                                {_.get(
                                  _.find(_.get(userAll, 'positions', []), [
                                    'id',
                                    5,
                                  ]),
                                  'name',
                                  t('statistics.teachers')
                                )}
                              </strong>
                              <div>
                                {_.get(
                                  _.find(_.get(userAll, 'data', []), [
                                    'position_type_id',
                                    5,
                                  ]),
                                  'count',
                                  0
                                )}
                              </div>
                            </span>
                          </div>
                          <div className='count_target count_target_child'>
                            <span className='count_icon'>
                              <img
                                src={`/images/statistic/teacher.svg`}
                                alt='i'
                              />
                            </span>
                            <span className='conut_text'>
                              <strong>{t('m_pupils.others')}</strong>
                              <div>
                                {_.get(userAll, 'man', 0) +
                                  _.get(userAll, 'woman', 0) -
                                  (_.get(
                                    _.find(_.get(userAll, 'data', []), [
                                      'position_type_id',
                                      4,
                                    ]),
                                    'count',
                                    0
                                  ) +
                                    _.get(
                                      _.find(_.get(userAll, 'data', []), [
                                        'position_type_id',
                                        12,
                                      ]),
                                      'count',
                                      0
                                    ) +
                                    _.get(
                                      _.find(_.get(userAll, 'data', []), [
                                        'position_type_id',
                                        5,
                                      ]),
                                      'count',
                                      0
                                    ))}
                              </div>
                            </span>
                          </div>
                          <div className='count_target count_target_child'>
                            <span className='count_icon'>
                              <img
                                src={`/images/statistic/gender.svg`}
                                alt='i'
                              />
                            </span>
                            <span className='conut_text'>
                              <strong>
                                {t('statistics.man')}/{t('statistics.woman')}
                              </strong>
                              <div>
                                {_.get(userAll, 'man', 0)}/
                                {_.get(userAll, 'woman', 0)}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Layout>
      <CheckRole roles={[roles.manager]} />
      <main className='main'>
        <section className='section section-dashboard py-0'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='dashboard-right'>
                  <Navigation
                    is_dash={true}
                    is_btn={true}
                    active={t('statistics.title')}
                    navs={[
                      {
                        title: t('navigation.account'),
                        link: '/dashboard/profile',
                      },
                    ]}
                  />
                  <div className='dashboard-title dashboard-title--has-buttons mb-0 refresh_casheb'>
                    <h5>{t('statistics.title')}</h5>
                    <div className='refresh_cashe'>
                      <span className='mr-2'>
                        {t('statistics.refresh_cashe')}
                      </span>
                      <Moment format='HH:mm DD/MM/YYYY'>
                        {_.get(users, 'cach_time')}
                      </Moment>
                      <span className=' ml-2'>
                        {l_user ? (
                          <span className='refreshBtn'>
                            <Loading size={3} />
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              const s = convertLink(true);
                              getResultUser(s);
                              getResultDiagnostic(s);
                              getResultPassing(s);
                              getResultCoursePassing(s);
                            }}
                          >
                            <span class='icon icon-refresh mr-0 mr-sm-2'></span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='card card--shadow'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='form-group mb-1 col-md-3 col-lg-2'>
                              <label for=''>
                                {' '}
                                {t('profile.position_type')}{' '}
                              </label>
                              <select
                                className='form-control custom-select'
                                name='position_type_id'
                                value={
                                  filter?.position_type_id
                                    ? filter?.position_type_id
                                    : ''
                                }
                                onChange={(e) => {
                                  getPositions(e.target.value);
                                  if (parseInt(e.target.value) === 6)
                                    setFilter({
                                      ...filter,
                                      position_type_id: e.target.value,
                                      position_id: '',
                                      school_id: '',
                                    });
                                  else
                                    setFilter({
                                      ...filter,
                                      position_type_id: e.target.value,
                                      position_id: '',
                                    });
                                }}
                              >
                                <option value=''>{t('m_pupils.all')}</option>
                                {position_types.map((r, index) => (
                                  <option key={index} value={r.id}>
                                    {r.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {_.get(filter, 'position_type_id', false) ? (
                              <div className='form-group mb-1 col-md-3 col-lg-2'>
                                <label for=''> {t('m_pupils.position')}</label>
                                <select
                                  className='form-control custom-select'
                                  name='position_id'
                                  value={
                                    filter?.position_id
                                      ? filter?.position_id
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      position_id: e.target.value,
                                    });
                                  }}
                                >
                                  <option value=''>{t('m_pupils.all')}</option>
                                  {positions.map((r, index) => (
                                    <option key={index} value={r.id}>
                                      {r.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : null}
                            <div className='form-group mb-1 col-md-3 col-lg-2'>
                              <label for=''> {t('statistics.regions')} </label>
                              <select
                                className='form-control custom-select'
                                name='region_id'
                                value={
                                  filter?.region_id ? filter?.region_id : ''
                                }
                                onChange={(e) => {
                                  props.getCitiesList(e.target.value);
                                  setFilter({
                                    ...filter,
                                    region_id: e.target.value,
                                    city_id: '',
                                    school_id: '',
                                    is_specialized: '',
                                    school_type: '',
                                  });
                                  getSchools({
                                    region_id: e.target.value,
                                    city_id: filter.city_id,
                                    is_specialized: filter.is_specialized,
                                    school_type_id: filter.school_type,
                                  });
                                }}
                              >
                                <option value=''>{t('m_pupils.all')}</option>
                                {_.get(region_list, 'data', []).map(
                                  (r, index) => (
                                    <option key={index} value={r.id}>
                                      {r.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            {_.get(filter, 'region_id', false) ? (
                              <div className='form-group mb-1 col-md-3 col-lg-2'>
                                <label for=''> {t('statistics.cites')} </label>
                                <select
                                  className='form-control custom-select'
                                  name='city_id'
                                  value={filter?.city_id ? filter?.city_id : ''}
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      city_id: e.target.value,
                                      school_id: '',
                                      is_specialized: '',
                                      school_type: '',
                                    });
                                    getSchools({
                                      region_id: filter.region_id,
                                      city_id: e.target.value,
                                      is_specialized: filter.is_specialized,
                                      school_type_id: filter.school_type,
                                    });
                                  }}
                                >
                                  <option value=''>{t('m_pupils.all')}</option>
                                  {_.get(cities_list, 'data', []).map(
                                    (r, index) => (
                                      <option key={index} value={r.id}>
                                        {r.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            ) : null}
                            <div className='form-group mb-1 col-md-3 col-lg-2'>
                              <label for=''>{t('m_pupils.school-type')}</label>
                              <select
                                className='form-control custom-select'
                                name='is_specialized'
                                value={
                                  filter?.is_specialized
                                    ? filter.is_specialized
                                    : ''
                                }
                                onChange={(e) => {
                                  setFilter({
                                    ...filter,
                                    is_specialized: e.target.value,
                                    school_type: '',
                                  });
                                  getSchools({
                                    region_id: filter.region_id,
                                    city_id: filter.city_id,
                                    is_specialized: e.target.value,
                                    school_type_id: filter.school_type,
                                  });
                                }}
                              >
                                <option value=''>{t('m_pupils.all')}</option>
                                <option value='0'>
                                  {t('m_pupils.general-school')}
                                </option>
                                <option value='1'>
                                  {t('m_pupils.specialized-school')}
                                </option>
                              </select>
                            </div>
                            {_.get(filter, 'is_specialized', false) === '1' ? (
                              <div className='form-group mb-1 col-md-3 col-lg-2'>
                                <label for=''> {t('m_pupils.direction')}</label>
                                <select
                                  className='form-control custom-select'
                                  name='school_type'
                                  value={
                                    filter?.school_type
                                      ? filter?.school_type
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      school_type: e.target.value,
                                    });
                                    getSchools({
                                      region_id: filter.region_id,
                                      city_id: filter.city_id,
                                      is_specialized: filter.is_specialized,
                                      school_type_id: e.target.value,
                                    });
                                  }}
                                >
                                  <option value=''>{t('m_pupils.all')}</option>
                                  {schoolTypes.map((r, index) => (
                                    <option key={index} value={r.id}>
                                      {r.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : null}
                            {_.get(filter, 'city_id', false) ? (
                              <div className='form-group mb-1 col-md-3 col-lg-2'>
                                <label for=''> {t('profile.school')} </label>
                                <select
                                  className='form-control custom-select'
                                  name='school_id'
                                  value={
                                    filter?.school_id ? filter.school_id : ''
                                  }
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      school_id: e.target.value,
                                    });
                                  }}
                                >
                                  <option value=''>{t('m_pupils.all')}</option>
                                  {schools.map((r, index) => (
                                    <option key={index} value={r.id}>
                                      {r.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : null}
                            <div className='form-group mb-1 col-md-3 col-lg-2'>
                              <label for=''> {t('m_pupils.filter')} </label>
                              {l_region_active && btn_l ? (
                                <Loading size={5} />
                              ) : (
                                <Link
                                  to={'/manager/statistics?' + convertLink()}
                                  className='btn btn-primary btn-sm w-100'
                                  onClick={() => {
                                    setPagination({});
                                    setActiveUserAll(null);
                                  }}
                                >
                                  {t('diagnostics.btn')}
                                </Link>
                              )}
                            </div>
                            <div
                              className='form-group mb-1 col-md-3 col-lg-2'
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                              }}
                            >
                              <label for=''> </label>
                              {l_region_active && btn_l ? (
                                ''
                              ) : (
                                <Link
                                  to={'/manager/statistics'}
                                  style={{ minWidth: '174px' }}
                                  className='btn btn-secondary-light btn-sm w-100'
                                  onClick={() => {
                                    setPagination({});
                                    setActiveUserAll(null);
                                    setFilter({});
                                    setFilterQuery({});
                                  }}
                                >
                                  {t('m_pupils.reset')}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 mb-2'>
                      <h3 style={{ fontWeight: 700 }}>
                        {' '}
                        {t('statistics.all_statistics')}
                      </h3>
                    </div>
                    {!_.get(filter_query, 'school_id', false) ? (
                      <>
                        <div className='col-lg-8'>
                          <div className='card card--shadow'>
                            <div className='card-body statistic_map'>
                              <div className='statistics-graph__info'>
                                <p>{t('statistics.title')}</p>
                                <small>
                                  {filter_query.region_id
                                    ? t('statistics.active_city')
                                    : t('statistics.active_region')}
                                </small>
                              </div>
                              {l_region_active ? (
                                <div
                                  style={{ height: 400, padding: '150px 0px' }}
                                >
                                  <Loading />
                                </div>
                              ) : (
                                <>
                                  <div className='statistics-graph__chart'>
                                    <RegionMaps
                                      exceptEnter={(v) =>
                                        setActiveUserAll(v.slug)
                                      }
                                      exceptLeave={() => setActiveUserAll(null)}
                                      exceptClick={(v) => {}}
                                      map_key='all_statistics'
                                      map_id={parseInt(
                                        _.get(filter_query, 'region_id', 0)
                                      )}
                                      map_select={_.get(
                                        _.find(_.get(cities_list, 'data', []), [
                                          'id',
                                          parseInt(
                                            _.get(filter_query, 'city_id', '')
                                          ),
                                        ]),
                                        'slug',
                                        ''
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <UsersAllData />
                  </div>
                  <div className='row statistics-graph'>
                    <div className='col-12 mb-2'>
                      <h3 style={{ fontWeight: 700 }}>
                        {t('statistics.userss')}
                      </h3>
                    </div>
                    <div className='col-12 col-xl-8'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='statistics-graph__info'>
                            <p>{t('statistics.register')}</p>
                          </div>
                          {l_register ? (
                            <div style={{ height: 400, padding: '150px 0px' }}>
                              <Loading />
                            </div>
                          ) : (
                            <>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className={
                                    f_register === 'day=1&per_page=7'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultRegister(
                                      convertLink(),
                                      'day=1&per_page=7&step=1'
                                    );
                                    setF_register('day=1&per_page=7');
                                    setS_register(1);
                                  }}
                                >
                                  {t('statistics.day')}
                                </button>
                                <button
                                  className={
                                    f_register === 'day=1&per_page=30'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultRegister(
                                      convertLink(),
                                      'day=1&per_page=30&step=1'
                                    );
                                    setF_register('day=1&per_page=30');
                                    setS_register(1);
                                  }}
                                >
                                  {t('statistics.week')}
                                </button>
                                <button
                                  className={
                                    f_register === 'month=1&per_page=12'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultRegister(
                                      convertLink(),
                                      'month=1&per_page=12&step=1'
                                    );
                                    setF_register('month=1&per_page=12');
                                    setS_register(1);
                                  }}
                                >
                                  {t('statistics.month')}
                                </button>
                              </div>
                              <div className='statistics-graph__chart'>
                                {_.get(register, 'data', []).length ? (
                                  <ChartLine data={register} />
                                ) : (
                                  <NoData text={t('statistics.no-data')} />
                                )}
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className={'btn btn-primary btn-xs m-1'}
                                  onClick={() => {
                                    getResultRegister(
                                      convertLink(),
                                      f_register + '&step=' + (s_register + 1)
                                    );
                                    setS_register(s_register + 1);
                                  }}
                                >
                                  {t('statistics.prev')}
                                </button>
                                <button
                                  className={
                                    s_register === 1
                                      ? 'btn btn-lighter btn-xs m-1'
                                      : 'btn btn-primary btn-xs m-1'
                                  }
                                  onClick={() => {
                                    if (s_register !== 1) {
                                      getResultRegister(
                                        convertLink(),
                                        f_register + '&step=' + (s_register - 1)
                                      );
                                      setS_register(s_register - 1);
                                    }
                                  }}
                                >
                                  {t('statistics.next')}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='statistics-graph__info'>
                            <p>{t('statistics.user_counts')}</p>
                          </div>
                          {l_user_counts ? (
                            <div style={{ height: 400, padding: '150px 0px' }}>
                              <Loading />
                            </div>
                          ) : (
                            <>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className={
                                    f_user_counts === 'day=1&per_page=7'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultUserCount(
                                      'day=1&per_page=7&step=1',
                                      convertLink()
                                    );
                                    setF_user_counts('day=1&per_page=7');
                                    setS_user_counts(1);
                                  }}
                                >
                                  {t('statistics.day')}
                                </button>
                                <button
                                  className={
                                    f_user_counts === 'day=1&per_page=30'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultUserCount(
                                      'day=1&per_page=30&step=1',
                                      convertLink()
                                    );
                                    setF_user_counts('day=1&per_page=30');
                                    setS_user_counts(1);
                                  }}
                                >
                                  {t('statistics.week')}
                                </button>
                                <button
                                  className={
                                    f_user_counts === 'month=1&per_page=12'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultUserCount(
                                      'month=1&per_page=12&step=1',
                                      convertLink()
                                    );
                                    setF_user_counts('month=1&per_page=12');
                                    setS_user_counts(1);
                                  }}
                                >
                                  {t('statistics.month')}
                                </button>
                              </div>
                              <div className='statistics-graph__chart'>
                                {_.get(user_counts, 'data', []).length ? (
                                  <ChartLine data={user_counts} />
                                ) : (
                                  <NoData text={t('statistics.no-data')} />
                                )}
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className={'btn btn-primary btn-xs m-1'}
                                  onClick={() => {
                                    getResultUserCount(
                                      f_user_counts +
                                        '&step=' +
                                        (s_user_counts + 1),
                                      convertLink()
                                    );
                                    setS_user_counts(s_user_counts + 1);
                                  }}
                                >
                                  {t('statistics.prev')}
                                </button>
                                <button
                                  className={
                                    s_user_counts === 1
                                      ? 'btn btn-lighter btn-xs m-1'
                                      : 'btn btn-primary btn-xs m-1'
                                  }
                                  onClick={() => {
                                    if (s_user_counts !== 1) {
                                      getResultUserCount(
                                        f_user_counts +
                                          '&step=' +
                                          (s_user_counts - 1),
                                        convertLink()
                                      );
                                      setS_user_counts(s_user_counts - 1);
                                    }
                                  }}
                                >
                                  {t('statistics.next')}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12 col-xl-4 '>
                      <div className='row'>
                        <div className='col-md-6 col-xl-12'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='statistics-graph__info'>
                                <p>{t('statistics.gendar')}</p>
                                <small>{t('statistics.gendar_text')}</small>
                              </div>
                              {l_user ? (
                                <Loading />
                              ) : users.count_certificat ? (
                                <>
                                  <div className='statistics-graph__chart'>
                                    <ChartCircle data={userGenders} />
                                  </div>
                                </>
                              ) : (
                                <NoData text="Malumot yo'q" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 col-xl-12'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='statistics-graph__info'>
                                <p>{t('statistics.position')}</p>
                                <small>{t('statistics.position_text')}</small>
                              </div>
                              {l_user ? (
                                <Loading />
                              ) : users.count_certificat ? (
                                <>
                                  <div className='statistics-graph__chart'>
                                    <ChartCircle data={userPositionR} />
                                  </div>
                                </>
                              ) : (
                                <NoData text="Malumot yo'q" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!_.get(filter_query, 'school_id', false) ? (
                      <>
                        <div className='col-lg-6'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='statistics-graph__info'>
                                <p>{t('statistics.user_counts')}</p>
                                <small>
                                  {filter_query.region_id
                                    ? t('statistics.active_city')
                                    : t('statistics.active_region')}
                                </small>
                                <button
                                  onClick={() =>
                                    getResultRegionCountActiva(
                                      convertLink(),
                                      'year=1'
                                    )
                                  }
                                  className={
                                    filterRegion === 'year=1'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                >
                                  {t('statistics.year')}
                                </button>
                                <button
                                  onClick={() =>
                                    getResultRegionCountActiva(
                                      convertLink(),
                                      'month=1'
                                    )
                                  }
                                  className={
                                    filterRegion === 'month=1'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                >
                                  {t('statistics.month')}
                                </button>
                                <button
                                  onClick={() =>
                                    getResultRegionCountActiva(
                                      convertLink(),
                                      'day=1'
                                    )
                                  }
                                  className={
                                    filterRegion === 'day=1'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                >
                                  {t('statistics.day')}
                                </button>
                              </div>
                              {l_region_active ? (
                                <div
                                  style={{ height: 400, padding: '150px 0px' }}
                                >
                                  <Loading />
                                </div>
                              ) : (
                                <>
                                  <div className='statistics-graph__chart'>
                                    <RegionMaps
                                      map_key='region_active'
                                      map_id={parseInt(
                                        _.get(filter_query, 'region_id', 0)
                                      )}
                                      data={region_active_c}
                                      map_select={_.get(
                                        _.find(_.get(cities_list, 'data', []), [
                                          'id',
                                          parseInt(
                                            _.get(filter_query, 'city_id', '')
                                          ),
                                        ]),
                                        'slug',
                                        ''
                                      )}
                                      wel={' %'}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='card mb-2'>
                            <div className='card-body'>
                              {l_region_active ? (
                                <div
                                  style={{ height: 400, padding: '150px 0px' }}
                                >
                                  <Loading />
                                </div>
                              ) : (
                                <>
                                  <div className='scroll scroll--horizontall m-0'>
                                    <table className='table table-bordered table--style-1 manager-teachers-table m-0  table-striped statistic_table'>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              minWidth: 20,
                                              width: 50,
                                              borderRadius: '10px 0 0 10px',
                                            }}
                                          >
                                            <div className='cell-inner'>#</div>
                                          </th>
                                          <th>
                                            <div className='cell-inner'>
                                              {filter_query.city_id
                                                ? t('profile.school')
                                                : filter_query.region_id
                                                ? t('statistics.cites')
                                                : t('statistics.regions')}
                                            </div>
                                          </th>
                                          <th
                                            style={{
                                              borderRadius: '0 10px 10px 0',
                                            }}
                                          >
                                            <div className='cell-inner'>
                                              {t('statistics.userss')}
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {_.get(region_active, 'data', []).map(
                                          (c, index) => (
                                            <>
                                              {_.get(
                                                pagination,
                                                'region_active',
                                                10
                                              ) > index ? (
                                                <tr key={index}>
                                                  <td
                                                    style={{
                                                      minWidth: 20,
                                                      width: 50,
                                                      borderRadius:
                                                        '10px 0 0 10px',
                                                    }}
                                                  >
                                                    <div className='cell-inner'>
                                                      {index + 1}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='cell-inner'>
                                                      {_.get(
                                                        c,
                                                        'region.name',
                                                        ''
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderRadius:
                                                        '0 10px 10px 0',
                                                    }}
                                                  >
                                                    <div className='cell-inner'>
                                                      {(
                                                        c.percent * 100
                                                      ).toFixed(1)}{' '}
                                                      %
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                            </>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {_.get(region_active, 'data', []).length >
                          _.get(pagination, 'region_active', 10) ? (
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                className='btn btn-primary btn-sm mb-2'
                                onClick={() =>
                                  setPagination({
                                    ...pagination,
                                    region_active:
                                      _.get(pagination, 'region_active', 10) +
                                      10,
                                  })
                                }
                              >
                                {t('statistics.more')}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <div className='col-12'>
                        <div className='card mb-2'>
                          <div className='card-body'>
                            <div className='statistics-graph__info'>
                              <p>{t('statistics.user_counts')}</p>
                              <small>{t('statistics.school_region')}</small>
                            </div>
                            {l_region_active ? (
                              <div
                                style={{ height: 400, padding: '150px 0px' }}
                              >
                                <Loading />
                              </div>
                            ) : (
                              <>
                                <div className='scroll scroll--horizontall m-0'>
                                  <table className='table table-bordered table--style-1 manager-teachers-table m-0  table-striped statistic_table'>
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            minWidth: 20,
                                            width: 50,
                                            borderRadius: '10px 0 0 10px',
                                          }}
                                        >
                                          <div className='cell-inner'>#</div>
                                        </th>
                                        <th>
                                          <div className='cell-inner'>
                                            {t('m_pupils.full_name')}
                                          </div>
                                        </th>
                                        <th>
                                          <div className='cell-inner'>
                                            {t('m_pupils.position')}
                                          </div>
                                        </th>
                                        <th>
                                          <div className='cell-inner'>
                                            {t('m_pupils.phone')}
                                          </div>
                                        </th>
                                        <th
                                          style={{
                                            borderRadius: '0 10px 10px 0',
                                          }}
                                        >
                                          <div className='cell-inner'>
                                            {t('statistics.last_10')}
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {_.get(region_active, 'data', []).map(
                                        (c, index) => (
                                          <>
                                            {_.get(
                                              pagination,
                                              'region_active',
                                              10
                                            ) > index ? (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    minWidth: 20,
                                                    width: 50,
                                                    borderRadius:
                                                      '10px 0 0 10px',
                                                  }}
                                                >
                                                  <div className='cell-inner'>
                                                    {index + 1}
                                                  </div>
                                                </td>
                                                <td>
                                                  <Link
                                                    to={
                                                      '/manager/pupils/view/' +
                                                      _.get(c, 'region.id', '')
                                                    }
                                                    className='cell-inner'
                                                  >
                                                    {_.get(
                                                      c,
                                                      'region.lastname',
                                                      ''
                                                    )}{' '}
                                                    {_.get(
                                                      c,
                                                      'region.firstname',
                                                      ''
                                                    )}{' '}
                                                    {_.get(
                                                      c,
                                                      'region.middlename',
                                                      ''
                                                    )}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <div className='cell-inner'>
                                                    {_.get(
                                                      c,
                                                      'region.position_type.type.name',
                                                      ''
                                                    )}
                                                    <br />
                                                    {_.get(
                                                      c,
                                                      'region.position_type.name',
                                                      ''
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className='cell-inner'>
                                                    {_.get(
                                                      c,
                                                      'region.name',
                                                      ''
                                                    )}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    borderRadius:
                                                      '0 10px 10px 0',
                                                  }}
                                                >
                                                  <div className='cell-inner'>
                                                    {c.count}{' '}
                                                    {t('statistics.once')}
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : null}
                                          </>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {_.get(region_active, 'data', []).length >
                        _.get(pagination, 'region_active', 10) ? (
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              className='btn btn-primary btn-sm mb-2'
                              onClick={() =>
                                setPagination({
                                  ...pagination,
                                  region_active:
                                    _.get(pagination, 'region_active', 10) + 10,
                                })
                              }
                            >
                              {t('statistics.more')}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className='row statistics-top'>
                    <div className='col-12 mb-2'>
                      <h3 style={{ fontWeight: 700 }}>
                        {t('statistics.certificates_stat')}
                      </h3>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <div className='card card--shadow manager-statistics'>
                        <div className='card-body'>
                          <h3>
                            {users.count_certificat} {t('m_pupils.ta')}
                          </h3>
                          <p>{t('statistics.count_certificat')}</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                      <div className='card card--shadow manager-statistics'>
                        <div className='card-body'>
                          <h3>
                            {users.count_week_certificat} {t('m_pupils.ta')}
                          </h3>
                          <p>{t('statistics.count_week_certificat')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row statistics-graph'>
                    <div className='col-12'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='statistics-graph__info'>
                            <p>{t('statistics.user_certificat')}</p>
                          </div>
                          {l_certificat ? (
                            <div style={{ height: 400, padding: '150px 0px' }}>
                              <Loading />
                            </div>
                          ) : (
                            <>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className={
                                    f_certificat === 'day'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultUserCertificat(
                                      convertLink(),
                                      'day=1&per_page=12&step=1'
                                    );
                                    setF_certificat('day');
                                    setS_certificat(1);
                                  }}
                                >
                                  {t('statistics.day')}
                                </button>
                                <button
                                  className={
                                    f_certificat === 'week'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultUserCertificat(
                                      convertLink(),
                                      'week=1&per_page=12&step=1'
                                    );
                                    setF_certificat('week');
                                    setS_certificat(1);
                                  }}
                                >
                                  {t('statistics.week')}
                                </button>
                                <button
                                  className={
                                    f_certificat === 'month'
                                      ? 'btn btn-primary btn-xs m-1'
                                      : 'btn btn-lighter btn-xs m-1'
                                  }
                                  onClick={() => {
                                    getResultUserCertificat(
                                      convertLink(),
                                      'month=1&per_page=12&step=1'
                                    );
                                    setF_certificat('month');
                                    setS_certificat(1);
                                  }}
                                >
                                  {t('statistics.month')}
                                </button>
                              </div>
                              <div className='statistics-graph__chart'>
                                {_.get(certificat, 'data', []).length ? (
                                  <ChartLine data={certificat} />
                                ) : (
                                  <NoData text={t('statistics.no-data')} />
                                )}
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className={'btn btn-primary btn-xs m-1'}
                                  onClick={() => {
                                    getResultUserCertificat(
                                      convertLink(),
                                      f_certificat +
                                        '=1&per_page=12&step=' +
                                        (s_certificat + 1)
                                    );
                                    setS_certificat(s_certificat + 1);
                                  }}
                                >
                                  {t('statistics.prev')}
                                </button>
                                <button
                                  className={
                                    s_certificat === 1
                                      ? 'btn btn-lighter btn-xs m-1'
                                      : 'btn btn-primary btn-xs m-1'
                                  }
                                  onClick={() => {
                                    if (s_certificat !== 1) {
                                      getResultUserCertificat(
                                        convertLink(),
                                        f_certificat +
                                          '=1&per_page=12&step=' +
                                          (s_certificat - 1)
                                      );
                                      setS_certificat(s_certificat - 1);
                                    }
                                  }}
                                >
                                  {t('statistics.next')}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row statistics-graph'>
                    <div className='col-12 mb-2'>
                      <h3 style={{ fontWeight: 700 }}>
                        {t('statistics.diagnostics')}
                      </h3>
                    </div>
                    {l_diagnostic ? (
                      <div className='col-12'>
                        <div className='card'>
                          <div className='card-body'>
                            <div style={{ height: 400, padding: '150px 0px' }}>
                              <Loading />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {diagnistics.map((item, index) => (
                          <>
                            {_.get(pagination, 'diagnostic', 2) > index ? (
                              <div className='col-md-6' key={index}>
                                <div className='card'>
                                  <div className='card-body'>
                                    <div className='statistics-graph__info'>
                                      <p>{item.name}</p>
                                      <small>
                                        {t('statistics.diagnostic')}
                                      </small>
                                    </div>
                                    <div className='statistics-graph__chart'>
                                      <ChartColumnS data={item} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                        {diagnistics.length >
                        _.get(pagination, 'diagnostic', 2) ? (
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              className='btn btn-primary btn-sm'
                              onClick={() =>
                                setPagination({
                                  ...pagination,
                                  diagnostic:
                                    _.get(pagination, 'diagnostic', 2) + 2,
                                })
                              }
                            >
                              {t('statistics.more')}
                            </button>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className='row statistics-graph'>
                    <div className='col-12 mb-2'>
                      <h3 style={{ fontWeight: 700 }}>
                        {t('profile-page.result-level')}
                      </h3>
                    </div>
                    <div className='col-12'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='statistics-graph__info'>
                            <p>{t('statistics.user_passing')}</p>
                            {/* <small>{t('statistics.user_passing_text')}</small> */}
                          </div>
                          {l_passing ? (
                            <div style={{ height: 400, padding: '150px 0px' }}>
                              <Loading />
                            </div>
                          ) : (
                            <>
                              <div className='statistics-graph__chart'>
                                {_.get(lesson_passing, 'data', []).length ? (
                                  <ChartLinePassing data={lesson_passing} />
                                ) : (
                                  <NoData text={t('statistics.no-data')} />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {!_.get(filter_query, 'school_id', false) ? (
                      <>
                        <div className='col-lg-6'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='statistics-graph__info'>
                                <p>{t('statistics.user_passing')}</p>
                                <small>
                                  {filter_query.region_id
                                    ? t('statistics.active_city')
                                    : t('statistics.active_region')}
                                </small>
                              </div>
                              {l_region_score ? (
                                <div
                                  style={{ height: 400, padding: '150px 0px' }}
                                >
                                  <Loading />
                                </div>
                              ) : (
                                <>
                                  <div className='statistics-graph__chart'>
                                    <RegionMaps
                                      map_key='region_score'
                                      map_id={parseInt(
                                        _.get(filter_query, 'region_id', 0)
                                      )}
                                      wel={'%'}
                                      map_select={_.get(
                                        _.find(_.get(cities_list, 'data', []), [
                                          'id',
                                          parseInt(
                                            _.get(filter_query, 'city_id', '')
                                          ),
                                        ]),
                                        'slug',
                                        ''
                                      )}
                                      data={region_score_c}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='card mb-2'>
                            <div className='card-body'>
                              {l_region_score ? (
                                <div
                                  style={{ height: 400, padding: '150px 0px' }}
                                >
                                  <Loading />
                                </div>
                              ) : (
                                <>
                                  <div className='scroll scroll--horizontall m-0'>
                                    <table className='table table-bordered table--style-1 manager-teachers-table m-0  table-striped statistic_table'>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              minWidth: 20,
                                              width: 50,
                                              borderRadius: '10px 0 0 10px',
                                            }}
                                          >
                                            <div className='cell-inner'>#</div>
                                          </th>
                                          <th>
                                            <div className='cell-inner'>
                                              {filter_query.region_id
                                                ? t('statistics.cites')
                                                : t('statistics.regions')}
                                            </div>
                                          </th>
                                          <th
                                            style={{
                                              borderRadius: '0 10px 10px 0',
                                            }}
                                          >
                                            <div className='cell-inner'>
                                              {t('statistics.ball')}
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {_.get(region_score, 'data', []).map(
                                          (c, index) => (
                                            <>
                                              {_.get(
                                                pagination,
                                                'region_score',
                                                10
                                              ) > index ? (
                                                <tr key={index}>
                                                  <td
                                                    style={{
                                                      minWidth: 20,
                                                      width: 50,
                                                      borderRadius:
                                                        '10px 0 0 10px',
                                                    }}
                                                  >
                                                    <div className='cell-inner'>
                                                      {index + 1}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='cell-inner'>
                                                      {_.get(
                                                        c,
                                                        'region.name',
                                                        ''
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderRadius:
                                                        '0 10px 10px 0',
                                                    }}
                                                  >
                                                    <div className='cell-inner'>
                                                      {(
                                                        parseFloat(c.percent) *
                                                        100
                                                      ).toFixed(1)}{' '}
                                                      %
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                            </>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {_.get(region_score, 'data', []).length >
                          _.get(pagination, 'region_score', 10) ? (
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                className='btn btn-primary btn-sm mb-2'
                                onClick={() =>
                                  setPagination({
                                    ...pagination,
                                    region_score:
                                      _.get(pagination, 'region_score', 10) +
                                      10,
                                  })
                                }
                              >
                                {t('statistics.more')}
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ) : (
                      <div className='col-12'>
                        <div className='card mb-2'>
                          <div className='card-body'>
                            <div className='statistics-graph__info'>
                              <p>{t('statistics.user_passing')}</p>
                              <small>{t('statistics.school_region')}</small>
                            </div>
                            {l_region_score ? (
                              <div
                                style={{ height: 400, padding: '150px 0px' }}
                              >
                                <Loading />
                              </div>
                            ) : (
                              <>
                                <div className='scroll scroll--horizontall m-0'>
                                  <table className='table table-bordered table--style-1 manager-teachers-table m-0  table-striped statistic_table'>
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            minWidth: 20,
                                            width: 50,
                                            borderRadius: '10px 0 0 10px',
                                          }}
                                        >
                                          <div className='cell-inner'>#</div>
                                        </th>
                                        <th>
                                          <div className='cell-inner'>
                                            {t('m_pupils.full_name')}
                                          </div>
                                        </th>
                                        <th>
                                          <div className='cell-inner'>
                                            {t('m_pupils.phone')}
                                          </div>
                                        </th>
                                        <th
                                          style={{
                                            borderRadius: '0 10px 10px 0',
                                          }}
                                        >
                                          <div className='cell-inner'>
                                            {t('statistics.assimilation')}
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {_.get(region_score, 'data', []).map(
                                        (c, index) => (
                                          <>
                                            {_.get(
                                              pagination,
                                              'region_score',
                                              10
                                            ) > index ? (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    minWidth: 20,
                                                    width: 50,
                                                    borderRadius:
                                                      '10px 0 0 10px',
                                                  }}
                                                >
                                                  <div className='cell-inner'>
                                                    {index + 1}
                                                  </div>
                                                </td>
                                                <td>
                                                  <Link
                                                    to={
                                                      '/manager/pupils/view/' +
                                                      _.get(c, 'region.id', '')
                                                    }
                                                    className='cell-inner'
                                                  >
                                                    {_.get(
                                                      c,
                                                      'region.lastname',
                                                      ''
                                                    )}{' '}
                                                    {_.get(
                                                      c,
                                                      'region.firstname',
                                                      ''
                                                    )}{' '}
                                                    {_.get(
                                                      c,
                                                      'region.middlename',
                                                      ''
                                                    )}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <div className='cell-inner'>
                                                    {_.get(
                                                      c,
                                                      'region.name',
                                                      ''
                                                    )}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    borderRadius:
                                                      '0 10px 10px 0',
                                                  }}
                                                >
                                                  <div className='cell-inner'>
                                                    {(
                                                      parseFloat(c.percent) *
                                                      100
                                                    ).toFixed(1)}{' '}
                                                    %
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : null}
                                          </>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {_.get(region_score, 'data', []).length >
                        _.get(pagination, 'region_score', 10) ? (
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              className='btn btn-primary btn-sm mb-2'
                              onClick={() =>
                                setPagination({
                                  ...pagination,
                                  region_score:
                                    _.get(pagination, 'region_score', 10) + 10,
                                })
                              }
                            >
                              {t('statistics.more')}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className='row statistics-graph'>
                    <div className='col-12 mb-2'>
                      <h3 style={{ fontWeight: 700 }}>
                        {t('statistics.courses')}
                      </h3>
                    </div>
                    <div className='col-12'>
                      <div className='card mb-2'>
                        <div className='card-body'>
                          <div className='statistics-graph__info'>
                            <p>{t('statistics.course_passing')}</p>
                            <small>{t('statistics.course_passing_text')}</small>
                          </div>
                          {l_course ? (
                            <div style={{ height: 400, padding: '150px 0px' }}>
                              <Loading />
                            </div>
                          ) : (
                            <>
                              <div className='scroll scroll--horizontall m-0'>
                                <table className='table table-bordered table--style-1 manager-teachers-table m-0  table-striped statistic_table'>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          minWidth: 20,
                                          borderRadius: '10px 0 0 10px',
                                        }}
                                      >
                                        <div className='cell-inner'>#</div>
                                      </th>
                                      <th>
                                        <div className='cell-inner'>
                                          {t('statistics.courses')}
                                        </div>
                                      </th>
                                      <th
                                        style={{
                                          borderRadius: '0 10px 10px 0',
                                        }}
                                      >
                                        <div className='cell-inner'>
                                          {t('statistics.assimilation')}
                                        </div>
                                      </th>
                                      <th
                                        style={{
                                          borderRadius: '0 10px 10px 0',
                                        }}
                                      >
                                        <div className='cell-inner'>
                                          {t('statistics.positions_type')}
                                        </div>
                                      </th>
                                      <th
                                        style={{
                                          borderRadius: '0 10px 10px 0',
                                        }}
                                      >
                                        <div className='cell-inner'>
                                          {t('statistics.category')}
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_.get(course_score, 'data', []).map(
                                      (c, index) => (
                                        <>
                                          {_.get(
                                            pagination,
                                            'course_score',
                                            10
                                          ) > index ? (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  minWidth: 20,
                                                  borderRadius: '10px 0 0 10px',
                                                }}
                                              >
                                                <div className='cell-inner'>
                                                  {index + 1}
                                                </div>
                                              </td>
                                              <td>
                                                <div
                                                  className='cell-inner'
                                                  style={{
                                                    minWidth: 200,
                                                  }}
                                                >
                                                  {c.courses_name}
                                                </div>
                                              </td>
                                              <td>
                                                <div className='cell-inner'>
                                                  {(c.percent * 100).toFixed(1)}
                                                  %
                                                </div>
                                              </td>
                                              <td>
                                                <div
                                                  className='cell-inner'
                                                  style={{
                                                    display: 'grid',
                                                  }}
                                                >
                                                  {coursePosition(
                                                    _.get(c, 'position', [])
                                                  ).map((item, index_po) => (
                                                    <span key={index_po}>
                                                      {' '}
                                                      {item}{' '}
                                                    </span>
                                                  ))}
                                                </div>
                                              </td>
                                              <td
                                                style={{
                                                  borderRadius: '0 10px 10px 0',
                                                }}
                                              >
                                                <div
                                                  className='cell-inner'
                                                  style={{
                                                    display: 'grid',
                                                    minWidth: 250,
                                                  }}
                                                >
                                                  {_.get(c, 'position', []).map(
                                                    (p, index_p) => (
                                                      <span key={index_p}>
                                                        {p.name} ,
                                                      </span>
                                                    )
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {_.get(course_score, 'data', []).length &&
                      _.get(course_score, 'data', []).length >
                        _.get(pagination, 'course_score', 10) ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          <button
                            className='btn btn-primary btn-sm mb-2'
                            onClick={() =>
                              setPagination({
                                ...pagination,
                                course_score:
                                  _.get(pagination, 'course_score', 10) + 10,
                              })
                            }
                          >
                            {t('statistics.more')}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        className='modal fade'
        id='StatisticUpdate'
        tabindex='-1'
        role='dialog'
        aria-labelledby='StatisticUpdateLabel'
        aria-hidden='true'
        data-backdrop='false'
        style={{ background: 'rgba(0,0,0, .5)' }}
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Statistika ma'lumotlari yangilanmoqda
              </h5>
            </div>
            <div className='modal-body'>
              <div className={'flex__target'}>
                <div
                  style={{
                    background: '#c9c9c9',
                    width: '80%',
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      width: '60%',
                      height: 7,
                      borderRadius: 10,
                      background: '#26caac',
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>60%</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default connect(
  (state) => ({
    region_list: state.region_list,
    cities_list: state.cities_list,
  }),
  (dispatch) => ({
    getRegionList: () => dispatch(getRegionList()),
    getCitiesList: (id) => dispatch(getCitiesList(id)),
  })
)(ManagerStatisticsPage);
