import React from 'react';
import { Link } from 'react-router-dom';
import { IMG_URL } from '../../helpers/api';
import _ from 'lodash';

const CourseCard = props => {
	const { item, link, user_id } = props;
	const { name, category, image, slug } = item;
	return (
		<>
			<Link to={link ? `/manager/pupils/view/${user_id}/course/${slug}/${name}` : `/dashboard/profile/${slug}/${name}`} style={{ textDecoration: 'none' }}>
				<div
					title={name}
					style={{
						width: 230,
						minWidth: 230,
						height: 270,
						borderRadius: 12,
						overflow: 'hidden',
						boxShadow: '0px 8px 24px rgba(13, 46, 105, 0.08)',
						margin: 15,
					}}>
					<div style={{ position: 'relative' }}>
						<img
							src={IMG_URL + image}
							onError={e => {
								e.target.onerror = null;
								e.target.src = '/images/user/course.png';
							}}
							style={{ height: 190, objectFit: 'cover', width: '100%' }}
							alt=''
						/>
						<span
							style={{
								position: 'absolute',
								left: 12,
								bottom: 10,
								fontWeight: 500,
								fontSize: 14,
								background: 'rgb(242, 153, 74)',
								padding: '3px 8px',
								borderRadius: 5,
								color: 'white',
								marginRight: 10,
							}}>
							{_.get(category, 'name', '')}
						</span>
					</div>
					<h6 style={{ fontSize: 14, padding: 10 }}>{name}</h6>
				</div>
			</Link>
		</>
	);
};
export default CourseCard;
