import { get } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { FILE_URL } from "../../helpers/api";
import { getInstance } from "../../helpers/httpClient";

const ExcelReadyModal = (props) => {
    const { unread_count } = props;
    useEffect(()=>{
        if(unread_count.length) window.$("#modalExcelReady").modal('show');
        else window.$("#modalExcelReady").modal('hide');
    }, [unread_count]);
    const { excel_ready } = useSelector(s=>s);
    const {t} = useTranslation();
    return(<>
        <div
            className="modal fade"
            id="modalExcelReady"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalExcelReadyTitle"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('m_pupils.excel_export')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="icon icon-close"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            {excel_ready.map((item, index)=>(
                                <div key={index} style={{display: 'flex',  paddingBottom: '10px', justifyContent: 'space-between', }}>
                                    <div>
                                        <div>{t('dash_side_bar.m_puplis')}</div>
                                        <div><Moment format="HH:mm DD-MM_YYYY">{item.updated_at}</Moment></div>
                                    </div>
                                    <button className="btn btn-primary btn-sm"
                                        onClick={()=>{
                                            getInstance().get('/api/v1/users/export/check/?id='+get(item, 'id', ''))
                                            window.open(FILE_URL+get(item, 'file', ''), '_blank' );
                                        }}
                                    >
                                        {t('profile.download')}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div>
                            {unread_count.map((item, index)=>(
                                <div key={index} style={{display: 'flex',  paddingBottom: '10px', justifyContent: 'space-between',}}>
                                    <div>
                                        <div>{t('dash_side_bar.m_puplis')}</div>
                                        <div><Moment format="HH:mm DD-MM_YYYY">{item.updated_at}</Moment></div>
                                    </div>
                                    <button className="btn btn-primary btn-sm"
                                        onClick={()=>{
                                            getInstance().get('/api/v1/users/export/check/?id='+get(item, 'id', ''))
                                            window.open(FILE_URL+get(item, 'file', ''), '_blank' );
                                        }}
                                    >
                                        {t('profile.download')}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default ExcelReadyModal;