import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import Layout from './Layout';
import DashSideBar from "./sections/DashSideBar";
import Navigation from "./sections/Navigation";
import CourseCard from "./sections/CourseCard";
import NoData from './sections/NoData';
import Loading from './sections/Loading';
import { getMyCourses } from "../actions";
import Pagination from './sections/Pagination';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckRole from './sections/CheckRole';
import { roles } from "../helpers/roles";


const DashCoursesPage = (props) => {
    const {my_courses} = props;
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        props.getMyCourses( props.match.params.page?props.match.params.page:1 );
    },[props.match.params.page]);

    useEffect(()=>{
        if(props.my_courses_status.status === 'started'){
            setLoading(true);
        }
        if(props.my_courses_status.status === 'success'){
            setLoading(false);
        }
        if(props.my_courses_status.status === 'error'){
            setLoading(false);
        }
    },[props.my_courses_status]);
    return (<Layout>
			<CheckRole roles={ [ roles.user, roles.teacher ] } />
      <main className="main">
        <section className="section section-dashboard py-0">
            <div className="container">
                <div className="row">
                    <DashSideBar/>
                    <div className="col-md-12 col-lg-9 col-xl-10">
                        <div className="dashboard-right">
                            <Navigation
                                is_dash={true}
                                is_btn={true}
                                active={t('my_course.title')}
                                navs={[
                                    {
                                        'title': t('navigation.account'),
                                        'link': '/dashboard'
                                    }
                                ]}
                            />
                            <div className="dashboard-title">
                            <h5>{t('my_course.title')}</h5>
                        </div>
                        <div className="row courses-dashboard">
                        <div className="col-12">
                        {loading?
                                <Loading/>
                            :<>
                                {my_courses.data&&my_courses.data.length>0?<>
                                <div className="row courses-dashboard"> 
                                    {my_courses.data.map((course,index)=>{
                                        // const total_score = course.total_score?course.total_score:1;
                                        // const user_score = course.user_score?course.user_score:0;
                                        // const percent = parseInt(100*user_score/total_score);
                                        return(
                                            <CourseCard
                                                key={index}
                                                course={course}
                                            >
                                                {/* <div class="course__footer-progress">
                                                    <span>{percent}%</span>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style={{width: percent+"%"}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <br/> */}
                                                <div className="price-btn">
                                                    <strong className="__price">
                                                        { course.hours } {t('create_course.credit')}
                                                        {/* { parseInt(course.hours/60) } {t('one_page.h_')} { parseInt(course.hours)%60 } {t('one_page.m_')} */}
                                                    </strong>
                                                    <Link to={'/dashboard/courses/view/'+course.slug} className="btn btn-primary btn-sm">{t('one_page.choose')}</Link>
                                                </div>
                                            </CourseCard>  
                                    )})} 
                                </div>
                                <div className="w-100">
                                    <Pagination
                                        total={my_courses.total?my_courses.total:6}
                                        active={ parseInt(props.match.params.page) }
                                        link="/dashboard/courses/list/"
                                    />
                                </div>
                                </>
                                :
                                    <NoData
                                        text={t('my_course.no_data')}
                                    />
                                }
                            </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>


  </Layout>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getMyCourses: (page) => dispatch(getMyCourses(page)),
    })
)(DashCoursesPage);