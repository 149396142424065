import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import { getChatThreadMore, getChatThreads } from "../../actions";
import Loading from "./Loading";
import { IMG_URL } from "../../helpers/api";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from "react-infinite-scroll-component";

const  ChatBar = (props) => {
    const { t } = useTranslation();
    const {chat_list, chat_list_status, chat_id} = props;
    const [loading, setLoading] = useState(true);
    const threads = _.get(chat_list, 'data', []);
    const next_page_url = _.get(chat_list, 'next_page_url', false);

    useEffect(()=>{
        setLoading(true);
        props.getChatThreads();
        onChat(chat_id);
        var chat = document.getElementById("chat__contacts_target");
        chat.scrollTop = chat.scrollHeight;
    }, []);
    useEffect(()=>{
        if(chat_list_status.status === 'started'){
            setLoading(true);
        }
        if(chat_list_status.status === 'success'){
            setLoading(false);
        }
        if(chat_list_status.status === 'error'){
            setLoading(false);
        }
    },[chat_list_status]);
   
    const  onChat = (id) => {
        props.chat_open(id);
    }
    return (<>
        <div className="chat__contacts scroll-hidden bg-white" id='chat__contacts_target'>
                {/* <li className="active">  */}
                {loading?<ul><li><br/><Loading/></li></ul>:
                    <ul>
                        <InfiniteScroll
                            dataLength={threads.length}
                            next={()=>props.getChatThreadMore(next_page_url)}
                            hasMore={ next_page_url}
                            loader={<div style={{minHeight: 25}}><Loading size={5}/></div>}  
                        >
                        {threads.map((thread, index)=>(
                            <li key={index}>
                                <NavLink to={"/dashboard/chat/"+_.get(thread, 'id')}  activeClassName="chat__contact_active"
                                    onClick={()=>onChat( _.get(thread, 'id') )}
                                    className="chat__contact">
                                    <img src={IMG_URL+ _.get( JSON.parse(_.get(thread, 'data', "{}")), 'image', "") } alt="user"
                                        onError={e => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                '/images/user/user.png';
                                        }}
                                    />
                                    <strong>  { _.get( JSON.parse(_.get(thread, 'data', "{}")), 'title', "Ma'muriyat") }</strong>
                                    <span>{_.get(thread, 'last_message.type')==='text'?_.get(thread, 'last_message.body'):_.get(thread, 'last_message.type')==='file'?t("chat.file"):""}</span>
                                    {_.get(thread, 'unreadCount', 0)?<small>{_.get(thread, 'unreadCount', 0)}</small>:null}
                                </NavLink>
                            </li>
                        ))}
                        </InfiniteScroll>
                    </ul>
                }
                
        </div>
    </>);
}
export default connect(
    (state) => ({
        ...state
    }),
    (dispatch) => ({
        getChatThreads: ()=>dispatch(getChatThreads()),
        chat_open: (chat_id)=>dispatch({type: "OPEN_CHAR", id: chat_id}),
        getChatThreadMore: (l)=>dispatch(getChatThreadMore(l))
    })
)(ChatBar);
