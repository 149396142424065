import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

const PasswordInput = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [pass, setPass] = useState('');
    const [validate_e, setValidateE] = useState('');
    const handleOnBlur = event =>{
        const p = event.target.value;
        props.setPassword(p);
    }
    const handleOnClick = () =>{
        props.onClickInput();
    }
    const handleOnChange = (value) =>{
       
        const re = /^(?:[ A-Za-z0-9~!@#$%^&*()-=+_/|?.,><;: ]{0,20})$/;
        const v = re.test(value);
        if(v){
            if(value.length <= 15){
                setPass(value);
                props.setPassword(value);
            }else{
                setPass(value.slice(0, 15));
                props.setPassword(value.slice(0, 15));
            }
            setValidateE('');
        }else{
            // setPass(value.slice(0, value.length-1));
            setPass(value);
            setValidateE(` ${t('sign_in.password_validate')} (A-Z) (a-z) (0-9) ~!@#$%^&*()-=+_/|?.,><;: `);
        }
    }
    return (<>
        <input type={show?"text":"password"} className={props.className} name={props.name?props.name:"password"}  autocomplete="new-password"
            defaultValue=""
            value={pass}
            onChange={(event)=>handleOnChange(event.target.value)}
            onBlur={handleOnBlur}
            onClick={handleOnClick}
        />   
        <button type="button" className={show?"btn btn-outline-primary show-password":"btn show-password"}
            onClick={()=>setShow(!show)}
        >
            <span className="icon icon-eye"></span>
        </button> 
        <div style={{color: 'red'}}>{validate_e}</div>
    </>);
}
export default PasswordInput;