import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logic from './Logic/Logic';

const MatrixCheckboxCreator = props => {
	const { t } = useTranslation();
	const [language, setLanguage] = useState(false);
	const { survey, surveys, number, setSurveyItem, deleteSurvey } = props;
	const addCol = () => {
		const last_id = survey.cols.length ? survey.cols[survey.cols.length - 1].id : 0;
		setSurveyItem({
			...survey,
			cols: [
				...survey.cols,
				{
					id: last_id + 1,
					col_uz: 'col_uz_' + (last_id + 1),
					col_ru: 'col_ru_' + (last_id + 1),
				},
			],
		});
	};
	const addRow = () => {
		const last_id = survey.rows.length ? survey.rows[survey.rows.length - 1].id : 0;
		setSurveyItem({
			...survey,
			rows: [
				...survey.rows,
				{
					id: last_id + 1,
					row_uz: 'row_uz_' + (last_id + 1),
					row_ru: 'row_ru_' + (last_id + 1),
				},
			],
		});
	};
	const delCol = id => {
		let l = [];
		survey.cols.forEach(item => {
			if (item.id !== id) {
				l.push(item);
			}
		});
		setSurveyItem({ ...survey, cols: l });
	};
	const delRow = id => {
		let l = [];
		survey.rows.forEach(item => {
			if (item.id !== id) {
				l.push(item);
			}
		});
		setSurveyItem({ ...survey, rows: l });
	};
	const changeCol = (id, name, value) => {
		let l = [];
		survey.cols.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, [name]: value });
			} else {
				l.push(item);
			}
		});
		setSurveyItem({ ...survey, cols: l });
	};
	const changeRow = (id, name, value) => {
		let l = [];
		survey.rows.forEach(item => {
			if (item.id === id) {
				l.push({ ...item, [name]: value });
			} else {
				l.push(item);
			}
		});
		setSurveyItem({ ...survey, rows: l });
	};
	return (
		<div className="test__item card mt-3">
			<div className="card-body row">
				<div className="col-12">
					<h5 style={{ display: 'inline' }}>
						{number}-{t('survey_c.question')} | {t('survey_c.id')}: {survey.id}
					</h5>
					<span
						className="button"
						style={{
							color: '#f00',
							margin: 'auto 10px',
							cursor: 'pointer',
							background: 'rgba(137, 141, 166, 0.1)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							float: 'right',
							width: 32,
							height: 32,
							borderRadius: 8,
						}}
						onClick={() => deleteSurvey()}>
						<span className="icon icon-remove"></span>
					</span>
					<span
						className="button"
						style={{
							color: '#898DA6',
							margin: 'auto 10px',
							cursor: 'pointer',
							float: 'right',
							background: 'rgba(137, 141, 166, 0.1)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: 32,
							height: 32,
							borderRadius: 8,
						}}
						onClick={() => props.setSurveyTest(survey)}
						data-toggle="modal"
						data-target="#modalSurveyTest">
						<span className="icon icon-eye"></span>
					</span>
				</div>
				<div className="col-12">
					<h5>
						{t('survey_c.question-type')} {t('survey_c.multiple-matrix')}
					</h5>
				</div>
				<div className="col-12" style={{ margin: '20px 0' }}>
					<div className="setting__buttons " style={{ justifyContent: 'right', flexDirection: 'column' }}>
						<label>{t('survey_c.isRequired')}</label>
						<div style={{ display: 'flex', margin: '5px 0 0 0 ' }}>
							<label className="checkbox-custom" style={{ margin: '0 10px 0 0 ' }}>
								<input
									type="checkbox"
									checked={survey.isRequired}
									name="input"
									onChange={e => {
										setSurveyItem({ ...survey, isRequired: e.target.checked });
									}}
								/>
								<span className="checkmark" style={{ width: 24, height: 24 }}></span>
							</label>
							<span style={{ color: '#0D2E69', fontWeight: 500, fontSize: 14 }}>
								{t('survey_c.required')}
							</span>
						</div>
					</div>
				</div>
				<div className="col-12 ">
					<button
						className="btn btn-sm btn-light"
						onClick={() => setLanguage(false)}
						style={{
							borderRadius: '8px 8px 0 0',
							color: '#0D2E69',
							border: 'none',
							fontWeight: 500,
							background: language ? 'white' : 'rgba(137, 141, 166, 0.08)',
						}}>
						{t('survey_c.uzbek')}
					</button>
					<button
						className="btn btn-sm btn-light"
						onClick={() => setLanguage(true)}
						style={{
							borderRadius: '8px 8px 0 0',
							color: '#0D2E69',
							border: 'none',
							fontWeight: 500,
							background: language ? 'rgba(137, 141, 166, 0.08)' : 'white',
						}}>
						{t('survey_c.russian')}
					</button>
					<div
						className="form-group p-4"
						style={{
							background: 'rgba(137, 141, 166, 0.08)',
							borderRadius: language ? 8 : '0 8px 8px 8px',
						}}>
						<p style={{ fontWeight: 500, fontSize: 12 }}>
							{t('survey_c.question-name')}
							{survey.isRequired ? <b>* </b> : null}
						</p>
						{language ? (
							<input
								type="text"
								value={survey.name_ru}
								style={{
									background: '#FFFFFF',
									height: 40,
									borderRadius: 8,
									width: '100%',
									border: 'none',
									padding: '0 0 0 16px',
								}}
								onChange={e => {
									setSurveyItem({ ...survey, name_ru: e.target.value });
								}}
							/>
						) : (
							<input
								type="text"
								value={survey.name_uz}
								style={{
									background: '#FFFFFF',
									height: 40,
									borderRadius: 8,
									width: '100%',
									border: 'none',
									padding: '0 0 0 16px',
								}}
								onChange={e => {
									setSurveyItem({ ...survey, name_uz: e.target.value });
								}}
							/>
						)}
						<div className="my-2" style={{ overflowY: 'auto' }}>
							<table class="table survey-table">
								<tr>
									<td></td>
									{survey.cols.map((item, index) => (
										<td key={index}>
											<div style={{ display: 'flex', height: 56, alignItems: 'center' }}>
												{language ? (
													<input
														type="text"
														style={{
															width: 120,
															height: 40,
															border: 'none',
															background: 'transparent',
															padding: '0 0 0 16px',
														}}
														value={item.col_ru}
														onChange={e => {
															changeCol(item.id, 'col_ru', e.target.value);
														}}
													/>
												) : (
													<input
														type="text"
														style={{
															width: 120,
															height: 40,
															border: 'none',
															background: 'transparent',
															padding: '0 0 0 16px',
														}}
														value={item.col_uz}
														onChange={e => {
															changeCol(item.id, 'col_uz', e.target.value);
														}}
													/>
												)}
												<span
													className="button"
													style={{
														color: '#f00',
														cursor: 'pointer',
														height: 40,
														width: 40,
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '0 8px 8px 0',
													}}
													onClick={() => delCol(item.id)}>
													<span className="icon icon-remove"></span>
												</span>
											</div>
										</td>
									))}
									<td>
										<div style={{ display: 'flex', height: 56, alignItems: 'center' }}>
											<button
												onClick={addCol}
												style={{
													background: 'transparent',
													fontWeight: 500,
													fontSize: 14,
													width: '100%',
													padding: '10px 0',
												}}
												className="btn">
												<span className="icon icon-plus"></span> {t('survey_c.add_col')}
											</button>
										</div>
									</td>
								</tr>
								{survey.rows.map((row, index) => (
									<tr key={index}>
										<td style={{ display: 'flex' }}>
											<div style={{ display: 'flex', height: 56, alignItems: 'center' }}>
												{language ? (
													<input
														type="text"
														style={{
															width: 120,
															height: 40,
															border: 'none',
															background: 'transparent',
															padding: '0 0 0 16px',
														}}
														value={row.row_ru}
														onChange={e => {
															changeRow(row.id, 'row_ru', e.target.value);
														}}
													/>
												) : (
													<input
														type="text"
														style={{
															width: 120,
															height: 40,
															border: 'none',
															background: 'transparent',
															padding: '0 0 0 16px',
														}}
														value={row.row_uz}
														onChange={e => {
															changeRow(row.id, 'row_uz', e.target.value);
														}}
													/>
												)}
												<span
													className="button"
													style={{
														color: '#f00',
														cursor: 'pointer',
														height: 40,
														width: 40,
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '0 8px 8px 0',
													}}
													onClick={() => delRow(row.id)}>
													<span className="icon icon-remove"></span>
												</span>
											</div>
										</td>
										{survey.cols.map((item, index) => (
											<td key={index}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														height: 56,
														width: '100%',
													}}>
													<label
														className="checkbox-custom"
														style={{ height: 22, width: 22 }}>
														<input type="checkbox" name="checkbox" disabled />
														<span
															className="checkmark"
															style={{ height: 22, width: 22 }}></span>
													</label>
												</div>
											</td>
										))}
										<td></td>
									</tr>
								))}
								<tr>
									<td>
										<button
											onClick={addRow}
											className="btn"
											style={{
												background: 'transparent',
												fontWeight: 500,
												fontSize: 14,
												width: '100%',
												padding: '10px 0',
											}}>
											<span className="icon icon-plus"></span> {t('survey_c.add_row')}
										</button>
									</td>
									{survey.cols.map((item, index) => (
										<td key={index}></td>
									))}
									<td></td>
								</tr>
							</table>
						</div>
						{survey.isRequired ? (
							<>
								<p style={{ fontWeight: 500, fontSize: 12, margin: '10px 0' }}>
									{t('survey_c.text-error')}
								</p>
								{language ? (
									<input
										type="text"
										style={{
											height: 40,
											width: '100%',
											border: 'none',
											borderRadius: 8,
											padding: '0 0 0 16px',
										}}
										value={survey.error_ru}
										onChange={e => {
											setSurveyItem({ ...survey, error_ru: e.target.value });
										}}
									/>
								) : (
									<input
										type="text"
										style={{
											height: 40,
											width: '100%',
											border: 'none',
											borderRadius: 8,
											padding: '0 0 0 16px',
										}}
										value={survey.error_uz}
										onChange={e => {
											setSurveyItem({ ...survey, error_uz: e.target.value });
										}}
									/>
								)}
							</>
						) : null}
					</div>
				</div>
				<style jsx>{`
					td {
						border: none !important;
						padding: 0 !important;
						height: 56px;
					}
					tr:nth-child(odd) {
						border-radius: 8px !important;
						background: white;
					}
				`}</style>
				<div className="col-12">
					<Logic
						survey={survey}
						surveys={surveys}
						setLogicList={logic => setSurveyItem({ ...survey, logic: logic })}
					/>
				</div>
			</div>
		</div>
	);
};
export default MatrixCheckboxCreator;
