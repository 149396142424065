import React from 'react';
import TestResultMultiple from './TestResultMultiple';
import TestResultSingle from './TestResultSingle';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from './ScrollToTopOnMount';
const DiagnosticTestResult = props => {
	const { t } = useTranslation();
	const { test_check_data } = props;
	const lesson = test_check_data.lesson;
	const questions = test_check_data.lesson.vquestions;
	const t_score = lesson.score ? lesson.score : null;
	const u_score = lesson.passings && lesson.passings.score ? lesson.passings.score : 0;

	return (
		<>
			<ScrollToTopOnMount />
			<div className="dashboard-title">
				<h3 style={{fontSize: 20}}>{t('test_result.diagnostics-results')}</h3>
			</div>

			<div className="card">
				<div className="card-body pb-0">
					<div className="row justify-content-xl-between">
						<div className="col-sm-6 col-xl-auto">
							<div className="d-flex mb-5">
								<span className="icon icon-paper mt-1 mr-2"></span>
								<div className="text-secondary text-nowrap">
									<strong className="font-weight-medium">{t('test_result.condition')}</strong>
									<br />
										<small>{t('test_result.completed')}</small>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-auto">
							<div className="d-flex mb-5">
								<span className="icon icon-calendar mt-1 mr-2"></span>
								<div className="text-secondary text-nowrap">
									<strong className="font-weight-medium">
										{t('test_result.date_of_completion')}
									</strong>
									<br />
									<small>
										{lesson.passings && lesson.passings.updated_at ? (
											<Moment format="HH:mm DD.MM.YYYY">{lesson.passings.updated_at}</Moment>
										) : (
											t('test_result.start-attempt')
										)}
									</small>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-auto">
							<div className="d-flex mb-5">
								<span className="icon icon-star mt-1 mr-2"></span>
								<div className="text-secondary text-nowrap">
									<strong className="font-weight-medium">{t('test_result.assessment')}</strong>
									<br />
									<small>
										{u_score} {t('test_result.score')} 
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{questions.map((question, index) => {
				return (
					<>
						{question.type === 'single' ? (
							<TestResultSingle key={index} question={question} number={index} />
						) : (
							<TestResultMultiple key={index} question={question} number={index} />
						)}
						{question.explan ? (
							<div className="card">
								<div className="card-body py-3">
									<div className="font-weight-medium text-secondary">
										{/* <p className="mb-1">К сожалению, Ваш ответ неправильный.</p> */}
										<p className="mb-1">
											<span className="text-primary">{t('test_result.explan')}:</span>
											<p dangerouslySetInnerHTML={{ __html: question.explan }} />
										</p>
									</div>
								</div>
							</div>
						) : null}
					</>
				);
			})}
			<div className="test__buttons">
				<div className="dashboard-buttons">
					<Link to="/dashboard/qualification" className="btn btn-primary ml-2">
						{t('test_result.back-qulification')}
					</Link>
				</div>
			</div>
		</>
	);
};
export default DiagnosticTestResult;
