import React, {useState} from 'react';
import PhoneUpdateBotSms from './PhoneUpdateBotSms';
import PhoneUpdateCheck from './PhoneUpdateCheck';

const  PhoneUpdate = (props) => {
    const [act, actState] = useState(0);
    return (<>
        {props.name?<button  type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#PhoneUpdateModal">{props.name}</button>:null}
        <div className="modal fade" id="PhoneUpdateModal" tabindex="-1" role="dialog" aria-labelledby="PhoneUpdateModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                {act===1?<PhoneUpdateBotSms actState={(a)=>actState(a)}/>
                :<PhoneUpdateCheck actState={(a)=>actState(a)}/>}
            </div>
        </div>
     </>);
}
export default PhoneUpdate;